<template>
  <div :class="`admin-pages-${theme}`">
    <PageTitle icon="fa fa-cogs" main="Administração do Sistema" sub="Usuários e Configurações" />
    <div :class="`admin-pages-tabs-${theme}`">
      <b-card :class="`card-${theme}`">
        <b-tabs class="tabs" pills fill vertical card active-nav-item-class="font-weight-bold">
          <b-tab title="Usuários" active>
            <UserAdmin />
          </b-tab>
          <b-tab title="Perfis de Acesso">
            <AccessProfiles />
          </b-tab>
          <b-tab title="Geral" >
            <GeneralPage />
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle"
import UserAdmin from "./UserAdmin"
import GeneralPage from "./GeneralPage"
import AccessProfiles from './AccessProfiles'

export default {
  name: "AdminPage",
  data: function() {
      return {
        theme: null
      }
  },  
  components: { PageTitle, UserAdmin, GeneralPage, AccessProfiles },
  mounted() {
    this.theme = localStorage.getItem('theme')
  }
};
</script>

<style>
.admin-pages-tabs-green{
  background-color: rgb(196, 235, 220);
}

.admin-pages-tabs-wine {
  background-color: rgb(255, 241, 241);
}

.admin-pages-tabs-violet {
  background-color: #f3eaff;
}

.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}

.card-violet {
  background-color: #f3eaff;
}

</style>