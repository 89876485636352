<template>
    <div class="access-profiles">
        <b-form>
            <input id="access-profile-id" type="hidden" v-model="accessProfile.name" />
            <b-row>
                <b-col md="6" sm="12">
                    <b-form-group label="Nome:" label-for="access-profile-name">
                        <b-form-input size="sm" id="access-profile-name" type="text"
                            v-model="accessProfile.name" required                       
                            placeholder="Informe o Nome do Perfil..." />
                    </b-form-group>
                </b-col>
            </b-row>
            <hr>
            <b-row>
                <Tree :data="treeData" :options="treeOptions" ref="tree"></Tree>
            </b-row>  
            <b-row>
                <b-col xs="12">
                    <b-button size="sm" variant="success" @click="save">SALVAR</b-button>  
                    <b-button size="sm" variant="danger" @click="remove" class="ml-2">REMOVER</b-button>  
                    <b-button size="sm" @click="reset" class="ml-2">CANCELAR</b-button>
                </b-col>
            </b-row>            
        </b-form>
        <hr>
        <b-table ref="selectableTable" selectable striped :select-mode="selectMode" :items="accessProfiles" :fields="fields" @row-selected="onRowSelected" responsive="sm">
            <template v-slot:cell(selected)="{rowSelected}">
                <template v-if="rowSelected">
                   <span aria-hidden="true">&check;</span>
                </template>
                <template v-else>
                   <span aria-hidden="true">&nbsp;</span>
                </template>
            </template>
        </b-table>
    </div>
</template>

<script>
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'
import Tree from "liquor-tree";
import TreeMain from "../default/Tree"

export default {
    name: 'AccessProfiles',
    components: {Tree},
    data: function() {
        return {
            accessProfile: {
                _id: '',
                name: '',
                access: []
            },
            accessProfiles: [],
            fields: [                 
                { key: 'name', label: 'Nome', sortable: true },                
            ],
            selectMode: 'single',           
            treeData: TreeMain.treeMain,       
            treeOptions: {        
                minFetchDelay: 1000,
                checkbox: true,
                propertyNames: { text: "name" },                        
            }
        }        
    },
    methods: {     
        onRowSelected(itemSelected) {           
            let selection = {}   
            selection = this.$refs.tree.findAll({})
            selection.uncheck()
            selection.collapse            

            let listAccess = []

            if (itemSelected.length > 0) {
                itemSelected[0].access.forEach(a => {
                    listAccess.push({
                        name: a.name,
                        state: a.state
                    })
                })           

                this.accessProfile._id = itemSelected[0]._id
                this.accessProfile.name = itemSelected[0].name
                
                this.resultcheckboxes(listAccess)
            }
        },           
        loadAccessProfiles() {
            const url = `${baseApiUrl}/accessprofile/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {this.accessProfiles = res.data}).catch(showError)
        },        
        save() {
            const method = this.accessProfile._id ? 'put' : 'post'
            let id = this.accessProfile._id ? `${this.accessProfile._id}&` : ''    

            if (id) {
                this.accessProfile.access.forEach(a => {
                    let selection = []
                    selection = this.$refs.tree.find(a.name)

                    a.state = selection[0].states["checked"]
                })        
            } else {
                this.accessProfile.access = []

                let selections = []
                selections = this.$refs.tree.findAll({})

                selections.forEach(s => {                
                    let a = {
                        name: s.text,
                        state: s.states["checked"]
                    }
                    
                    this.accessProfile.access.push(a)                        
                })
            }
            
            axios[method](`${baseApiUrl}/accessprofile/${id}${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, this.accessProfile)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                })
                .catch(showError)
        },        
        reset() {           
            let selection = {}   
            selection = this.$refs.tree.findAll({})
            selection.uncheck()
            selection.collapse

            this.accessProfile = {
                _id: '',
                name: '',
                access: []                
            }
            this.loadAccessProfiles()
        },        
        remove() {            
            const id = this.accessProfile._id
                axios.delete(`${baseApiUrl}/accessprofile/${id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`)
                        .then(() => {
                        this.$toasted.global.defaultSuccess()
                        this.reset()
                    })
                    .catch(showError)
        },    
        resultcheckboxes(ap) {
            let finded = false

            this.accessProfile.access = []

            let selections = this.$refs.tree.findAll({})

            selections.forEach(s => {                
                ap.forEach(a => {                    
                    if (s.text === a.name) {                        
                        if (a.state) {
                            s.check()
                        } else {
                            s.uncheck()
                        }

                        this.accessProfile.access.push(a)
                        
                        finded = true                            
                    }                                                    
                })

                if (!finded) {
                    this.accessProfile.access.push({
                        name: s.text,
                        state: s.uncheck()
                    })    
                }                

                finded = false
            })
        },                    
   },                     
    mounted() {
        this.loadAccessProfiles()
    }        
}
</script>

<style>

</style>