<template>
    <Loading v-if="isLoading" />
    <div v-else class="professional-admin">
        <PageTitle icon="fa fa-user-md" main="Cadastro de Profissionais" sub />                  
        <b-form>            
            <input id="professional-id" type="hidden" v-model="professional.id"/>
            <b-row>
                <b-col md="6" sm="6">
                    <b-card :class="`card-${theme}`" bg-variant="default" header="Informações Gerais" header-bg-variant="dark" header-text-variant="white">
                        <b-form-group label="Nome:" label-for="professional-name">
                            <b-form-input size="sm" id="professional-name" type="text" 
                                v-model="professional.name" required                       
                                placeholder="Informe o Nome do Profissional..." />
                        </b-form-group>         
                        <b-row>
                            <b-col md="6" sm="6">
                                <b-form-group label="CPF/CNPJ:" label-for="professional-cpf">
                                    <b-form-input size="sm" id="professional-cpf" type="number" 
                                        v-model="professional.cpf" required
                                        placeholder="Informe o CPF do Profissional..." />
                                </b-form-group> 
                                <b-form-group label="Data Nascimento:" label-for="professional-birthday">
                                    <b-form-input size="sm" id="professional-birthday" type="date"
                                        v-model="professional.birthday" required
                                        placeholder="Informe a Data de Nascimento do Profissional..." />
                                </b-form-group>         
                                <b-form-group label="E-mail:" label-for="professional-email">
                                    <b-form-input size="sm" id="professional-email" type="text"
                                        v-model="professional.email" 
                                        placeholder="Informe o E-mail do Profissional..." />
                                </b-form-group>        
                                <b-form-group label="Dados Conselho:" label-for="professional-data-advice">  
                                  <b-dropdown id="dropdown-form" text="Dados de Registro no Conselho" ref="dropdownAdvice" variant="light">
                                    <b-dropdown-form>
                                        <b-form-group label="Conselho:" label-for="professional-advice">
                                            <b-form-select size="sm" v-model="professional.advice._id" :options="advices"></b-form-select>
                                        </b-form-group>    
                                        <b-form-group label="Número Registro:" label-for="dropdown-form-number"> 
                                            <b-form-input size="sm" id="dropdown-form-number" placeholder="Número Registro..." v-model="professional.advice.number" type="text"></b-form-input> 
                                        </b-form-group> 
                                    </b-dropdown-form>
                                 </b-dropdown>        
                                </b-form-group>                          
                                <b-form-group label="Última Férias:" label-for="professional-lastVacationDate">
                                    <b-form-input size="sm" id="professional-lastVacationDate" type="date"
                                        v-model="professional.lastVacationDate" required
                                        placeholder="Informe a Última Férias do Profissional..." />
                                </b-form-group>         
                                <b-form-group label="Fone 1:" label-for="professional-phone1">
                                    <b-form-input size="sm" id="professional-phone1" type="number"
                                        v-model="professional.phone1" 
                                        placeholder="Informe o Fone do Profissional..." />
                                </b-form-group>                                                              
                            </b-col>
                            <b-col md="6" sm="6">
                                <b-form-group label="RG:" label-for="professional-rg">
                                    <b-form-input size="sm" id="professional-rg" type="number"
                                        v-model="professional.rg" 
                                        placeholder="Confirme o RG do Profissional..." />
                                </b-form-group>
                                <b-form-group label="Sexo:" label-for="professional-gender">
                                    <b-form-select size="sm" v-model="professional.gender" :options="genders"></b-form-select>
                                </b-form-group>                                
                                <b-form-group label="PIS:" label-for="professional-pis">
                                    <b-form-input size="sm" id="professional-pis" type="number"
                                        v-model="professional.pis" 
                                        placeholder="Confirme o PIS do Profissional..." />
                                </b-form-group> 
                                <b-form-group label="Data Contratação:" label-for="professional-hiringDate">
                                    <b-form-input size="sm" id="professional-hiringDate" type="date"
                                        v-model="professional.hiringDate" required
                                        placeholder="Informe a Data de Contratação do Profissional..." />
                                </b-form-group>   
                                <b-form-group label="Convênio Exclusivo:" label-for="professional-covenant">
                                    <b-form-select size="sm" v-model="professional.covenant" :options="covenants"></b-form-select>
                                </b-form-group>                                       
                                <b-form-group label="Fone 2:" label-for="professional-phone2">
                                    <b-form-input size="sm" id="professional-phone2" type="number"
                                        v-model="professional.phone2" 
                                        placeholder="Informe o Fone do Profissional..." />
                                </b-form-group>                                                             
                            </b-col>              
                            <b-col md="4" sm="12">
                                <b-form-checkbox class="mt-2 mb-2" id="professional-requester" v-model="professional.requester" name="checkbox-1" 
                                    :value="true" :unchecked-value="false">
                                    Solicitante? 
                                </b-form-checkbox>     
                            </b-col>
                            <b-col md="8" sm="12">
                                Categoria de Atendimento:  
                                    <b-form-radio-group class="ml-3" buttons button-variant="outline-info" v-model="professional.categoryAttendance" :options="categoriesAttendance" 
                                        name="plain-inline" plain>
                                    </b-form-radio-group>                                
                            </b-col>              
                            <b-col md="12" sm="12">
                                <b-form-group class="mt-3" label="Observações:" label-for="professional-comments">
                                    <b-form-textarea size="sm" id="textarea" v-model="professional.comments" placeholder="Observações..." rows="1" max-rows="6"></b-form-textarea>       
                                </b-form-group>                                                                                 
                            </b-col>       
                        </b-row>               
                    </b-card>
                </b-col>
                <b-col md="6" sm="6">
                    <b-card :class="`card-${theme}`" bg-variant="default" header="Endereço" header-bg-variant="dark" header-text-variant="white">
                        <b-row>    
                            <b-col md="5" sm="12"> 
                                <b-form-input size="sm" type="text" id="nested-cep" placeholder="CEP..." v-model="address.cep"></b-form-input>
                            </b-col>
                            <b-col md="1" sm="12"> 
                                <b-button size="sm" @click="searchCEP"><i class="fa fa-search-plus"></i></b-button>
                            </b-col>                               
                        </b-row>
                        <b-row>
                            <b-col md="10" sm="12">
                                <b-form-group label="Logradouro:" label-for="nested-street" class="mt-3">
                                    <b-form-input size="sm" id="nested-street" placeholder="Logradouro..." v-model="address.street"></b-form-input>
                                </b-form-group>
                            </b-col>                         
                            <b-col md="2" sm="12"> 
                                <b-form-group label="Número:" label-for="nested-number" class="mt-3">
                                    <b-form-input size="sm" id="nested-number" placeholder="Número..." v-model="address.number"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="6" sm="12"> 
                                <b-form-group label="Bairro:" label-for="nested-neighborhood">
                                    <b-form-input size="sm" id="nested-neighborhood" placeholder="Bairro..." v-model="address.neighborhood"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="6" sm="12"> 
                                <b-form-group label="Município:" label-for="nested-city">
                                    <b-form-input size="sm" id="nested-city" placeholder="Município..." v-model="address.city"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="8" sm="12"> 
                                <b-form-group label="Complemento:" label-for="nested-complement">
                                    <b-form-input size="sm" id="nested-complement" placeholder="Complemento..." v-model="address.complement"></b-form-input>
                                </b-form-group>
                            </b-col>           
                            <b-col md="4" sm="12">                    
                                <b-form-group label="UF:" label-for="professional-state">
                                    <b-form-select size="sm" v-model="address.state" :options="states"></b-form-select>
                                </b-form-group>               
                            </b-col>                                              
                        </b-row>       
                    </b-card>   
                    <b-card :class="`card-${theme} mt-3`" bg-variant="default" header="Áreas de Atuação" header-bg-variant="dark" header-text-variant="white">
                        <b-row>                            
                            <b-button size="sm" v-b-modal.modal-prevent-closing variant="success" class="mb-3 ml-3" @click="setModal('speciality')">
                                <i class="fa fa-plus"></i></b-button>
                            <Lookup v-if="modal === 'speciality'" ref="Lookup" title="Áreas de Atuação" :list="allSpecialities" :fields="slfields" 
                                    :invalidFeedback="invalidFeedback" resultTable="speciality"></Lookup>
                            <b-col md="12" sm="12">         
                                <b-table ref="selectableTableSpeciality" head-variant="light" outlined hover :items="resultLookupSpeciality" :fields="sfields" 
                                         responsive="sm" fixed @input="validSperciality">
                                    <template v-slot:cell(actions)="row">
                                        <b-button size="sm" variant="danger" @click="removeSpeciality(row.item)">
                                            <i class="fa fa-trash"></i>
                                        </b-button>
                                    </template>
                                </b-table>                            
                            </b-col>
                        </b-row>       
                    </b-card>                      
                    <b-card :class="`card-${theme} mt-3`" bg-variant="default" header="Qualificações" header-bg-variant="dark" header-text-variant="white">
                        <b-row>    
                            <b-button size="sm" v-b-modal.modal-prevent-closing variant="success" class="mb-3 ml-3" @click="setModal('qualifications')"><i class="fa fa-plus"></i></b-button>  
                            <Lookup v-if="modal === 'qualifications'" ref="Lookup" title="Qualificações" :list="allqualifications" :fields="qlfields" :invalidFeedback="invalidFeedback" 
                                    resultTable="qualifications"></Lookup>
                            <b-col md="12" sm="12"> 
                                <b-table ref="selectableTableQualifications" head-variant="light" outlined hover :items="resultLookupQualification" :fields="qfields" 
                                         responsive="sm" fixed>
                                    <template v-slot:cell(actions)="row">
                                        <b-button size="sm" variant="danger" @click="removeQualification(row.item)">
                                            <i class="fa fa-trash"></i>
                                        </b-button>
                                    </template>
                                </b-table>                            
                           </b-col>
                        </b-row>       
                    </b-card>                       
                </b-col>                     
            </b-row>
        </b-form>

        <b-button size="sm" variant="success" @click="save" class="mt-3">SALVAR</b-button>  
        <b-button size="sm" variant="danger" @click="cancel" class="mt-3 ml-2">CANCELAR</b-button>  
    </div>
</template>

<script>
import PageTitle from "../../template/PageTitle";
import { baseApiUrl, userKey } from '@/global'
import axios from 'axios'
import States from '../../default/states'
import Lookup from '../../default/Lookup'
import { mapState } from "vuex"
import Loading from "../../template/Loading"

export default {
    name: "ProfessionalPage",
    components: { PageTitle, Lookup, Loading },
    data: function() {
      return {
        isLoading: false,
        theme: null,
        professional: {},
        address: {},
        advices: [],
        covenants: [],
        genders: [
            {value: 1, text: "Masculino"},
            {value: 2, text: "Feminino"}
        ],
        allqualifications: [
            {level: 'Pós-Graduação', description: ''},
            {level: 'Mestrado', description: ''},
            {level: 'Doutorado', description: ''}
        ],
        allSpecialities: [],
        qfields: [                 
            { key: 'level', label: 'Nível', sortable: true },
            { key: 'description', label: 'Descrição', sortable: true },
            { key: 'actions', label: 'Ações' }
        ],     
        qlfields: [                 
            { key: 'level', label: 'Nível', sortable: true },
            { key: 'description', label: 'Descrição', sortable: true },
        ],     
        slfields: [
            { key: 'name', label: 'Nome', sortable: true },
        ], 
        sfields: [                 
            { key: 'name', label: 'Nome', sortable: true },
            { key: 'actions', label: 'Ações' }
        ],           
        states: States.states,   
        modal: null,
        categoriesAttendance: [
          { text: 'Ambos', value: 0 },
          { text: 'Adulto', value: 1 },
          { text: 'Pediátrico', value: 2 }            
        ]
      }    
    },
    computed: mapState(["resultLookupSpeciality", "resultLookupQualification"]),
    methods: {           
        save() {
            this.professional.name = this.professional.name.toUpperCase()

            if (this.professional.categoryAttendance == undefined && !this.professional.requester) {
                this.professional.categoryAttendance = 1
            }

            const method = this.professional._id ? 'put' : 'post'
            const id = this.professional._id ? `/${this.professional._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}` : `/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            
            this.professional.specialities = this.resultLookupSpeciality
            this.professional.qualifications = this.resultLookupQualification

            this.professional.address = {
                cep: this.address.cep,
                street: this.address.street,
                number: this.address.number,
                neighborhood: this.address.neighborhood,
                city:  this.address.city,
                complement: this.address.complement,
                state: this.address.state
            }

            this.isLoading = true

            axios[method](`${baseApiUrl}/professional${id}`, this.professional).then(() => {
                this.isLoading = false
                this.$toasted.global.defaultSuccess()
                this.$store.commit('setResultLookupSpeciality', [])
                this.$router.go(-1)
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })                
            })
        },  
        cancel() {
            this.$store.commit('setResultLookupSpeciality', [])
            this.$router.go(-1)
        },
        searchCEP() {     
            this.$viaCep.buscarCep(this.address.cep).then((obj) => {
                this.address = {    
                    cep: obj.cep,
                    street: obj.logradouro,
                    neighborhood: obj.bairro,
                    city: obj.localidade,                    
                }                
            });
        },
        removeSpeciality(itemSelected) {
            let i = 0

            this.resultLookupSpeciality.forEach(s => {
                if (s.name === itemSelected.name) {
                    this.resultLookupSpeciality.splice(i, 1)
                }
                i += 1
            })             
        },
        removeQualification(itemSelected) {
            let i = 0

            this.resultLookupQualification.forEach(q => {
                if (q._id === itemSelected._id) {
                    this.resultLookupQualification.splice(i, 1)
                }
                i += 1
            })             
        },        
        loadAdvices() {
            this.isLoading = true

            const url = `${baseApiUrl}/advice/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                for (let r of res.data) {
                    this.advices.push({ value: r._id, text: r.initials })                        
                }

                this.isLoading = false
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })
            })
        },
        loadCovenants() {
            this.isLoading = true

            const url = `${baseApiUrl}/covenants/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                for (let r of res.data) {
                    this.covenants.push({ value: r, text: r.fantasyname })                        
                }

                this.isLoading = false
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })
            }) 
        },
        loadSpecialities() {
            this.isLoading = true
            
            const url = `${baseApiUrl}/speciality/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                this.isLoading = false
                this.allSpecialities = res.data
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })                
            })                      
        },     
        validSperciality() {
            let lastIndex = this.resultLookupSpeciality.length - 1          
            let i = 0

            if (lastIndex > 0) {
                let lastName = this.resultLookupSpeciality[lastIndex].name

                this.resultLookupSpeciality.forEach(s => {
                    if (s.name === lastName && i != lastIndex) {
                        this.resultLookupSpeciality.pop()
                        this.$toasted.global.defaultError({msg: 'Especialidade já cadastrada'})
                    }
                    i += 1
                })             
            }
        },
        setModal(param) {
            this.modal = param
        },          
    },
    created() {
        this.theme = localStorage.getItem('theme')

        this.loadSpecialities()
        this.loadAdvices()
        this.loadCovenants()

        if (this.$route.params.professionalSelected) {
            this.professional = this.$route.params.professionalSelected            

            this.$store.commit('setResultLookupSpeciality', [])
            this.$store.commit('setResultLookupQualification', [])

            this.professional.specialities.forEach(s => {
                this.resultLookupSpeciality.push({_id: s._id, name: s.name})                
            })

            this.professional.qualifications.forEach(q => {
                this.resultLookupQualification.push({_id: q._id, level: q.level, description: q.description})                
            })                               

            if (this.professional.address) {
                this.address = {
                    cep: this.professional.address.cep,
                    street: this.professional.address.street,
                    number: this.professional.address.number,
                    neighborhood: this.professional.address.neighborhood,
                    city:  this.professional.address.city,
                    complement: this.professional.address.complement,
                    state: this.professional.address.state
                }
            }
        } else {
            this.professional.advice = {
                _id: '',
                number: ''
            }
        }
    }
}     
</script>

<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}

.card-violet {
  background-color: #f3eaff;
}

.card-header {
    height: 38px;
    font-size: 14px;    
}

.card-body {
    font-size: 14px;
}

.calendar-btn {
    font-size: 0.30;
}

.table th {
  padding: 0.45rem;
}

.table td {
  padding: 0.45rem;
  vertical-align: middle;
}
</style>