<template>
    <div class="OperationAnalysis">
      <PageTitle icon="fa fa-file-text" main="Análise de Operação" sub="Relatório" />
          <div class="batchguidesANS-page-tabs">
              <b-row>
                  <b-col md="8" sm="12" class="mt-3">
                      <b-card :class="`card-${theme}`" header="Informações" border-variant="dark" header-border-variant="white" header-bg-variant="dark" header-text-variant="white">
                          <b-row>
                              <b-col md="2" sm="12">
                                  <b-form-group label="Data Inicial:" label-for="bgans-dateInitial">
                                      <b-form-input size="sm" id="bgans-dateInitial" type="date" v-model="dateInitial" required />
                                  </b-form-group>
                              </b-col>
                              <b-col md="2" sm="12">
                                  <b-form-group label="Data Final:" label-for="bgans-dateFinal">
                                      <b-form-input size="sm" id="bgans-dateFinal" type="date" v-model="dateFinal" required/>
                                  </b-form-group>
                              </b-col>
                              <b-col md="4" sm="12">
                                  <b-form-group label="Convênio:" label-for="bgans-covenant">
                                      <b-form-select size="sm" v-model="selectedCovenant" :options="covenants"></b-form-select>
                                  </b-form-group>    
                              </b-col>
                              <b-col md="2" sm="12">
                                  <b-button size="sm" variant="warning" class="mt-4" @click="load">
                                      <div class="d-flex align-items-center">
                                          <b-spinner small v-if="generatingPDF" class="mr-2"></b-spinner>
                                          <span>{{textBtnPrint}}</span>
                                      </div>                                    
                                  </b-button>
                              </b-col>
                          </b-row>
                      </b-card>                    
                  </b-col>                  
              </b-row>
          </div>
    </div>  
  </template>
  
<script>
import PageTitle from "../template/PageTitle"
import Moment from 'moment'
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'

export default {
    name: "operationAnalysis",
    components: { PageTitle },    
    data: function() {
        return {
            theme: null,
            dateInitial: null,
            dateFinal: null,
            covenants: [],
            listResult: [],
            selectedCovenant: null,
            generatingPDF: false,
            totalPatientsAdult: 0,
            totalPatientsPediatric: 0,
            totalAttendances: 0,
            totalAttendancePatientAdult: 0,
            totalAttendancePatientChild: 0,
            totalAttendancePatientAdultDouble: 0,
            totalAttendancePatientChildDouble: 0,
            totalRealizedAdult: 0,
            totalRealizedDoubleAdult: 0,
            totalRealizedPediatric: 0,
            totalRealizedDoublePediatric: 0,
            totalPatientSUS: 0,
            totalAttendancePatientSUS: 0,
            totalAttendancePatientSUSDouble: 0,
            totalRealizedSUS: 0,
            mAdult: 0,
            rAdult: 0,
            rmAdult: 0,
            mAdultDone: 0,
            rAdultDone: 0,
            rmAdultDone: 0,
            mPediatric: 0,
            rPediatric: 0,
            rmPediatric: 0,
            mPediatricDone: 0,
            rPediatricDone: 0,
            rmPediatricDone: 0,
            mSUS: 0,
            rSUS: 0,
            rmSUS: 0,
            mSUSDone: 0,
            rSUSDone: 0,
            rmSUSDone: 0,
            textBtnPrint: 'GERAR',
        }
    },  
    methods: {            
        load() {
            let valid = true

            if (this.dateInitial == null || this.dateFinal == null) {
                this.$toasted.global.defaultError({msg: 'Informe a data inicial e final.'})
                valid = false
            } 

            Moment.locale('pt-br')

            let dtIni = Moment(this.dateInitial)
            let dtFim = Moment(this.dateFinal)  

            let diff = dtFim.diff(dtIni, 'days')

            if (diff < 0) {
                this.$toasted.global.defaultError({msg: 'Data final menor que data inicial.'})
                valid = false
            }

            if (valid) {
                this.generatingPDF = true
                this.textBtnPrint = 'Aguarde...'  
                
                axios.get(`${baseApiUrl}/admission/getNumberAttendances/${this.selectedCovenant}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, {}).then(res => {
                    this.totalAttendances = res.data.result.total
                    this.totalAttendancePatientAdult = res.data.result.totalAdult
                    this.totalAttendancePatientAdultDouble = res.data.result.totalAdultDouble,
                    this.totalAttendancePatientChildDouble = res.data.result.totalPediatricDouble,
                    this.totalAttendancePatientChild = res.data.result.totalChild
                    this.totalPatientsAdult = res.data.result.totalPatientsAdult
                    this.totalPatientsPediatric = res.data.result.totalPatientsPediatric
                    this.totalAttendancePatientSUS = res.data.result.totalSUS
                    this.totalPatientSUS = res.data.result.totalPatientSUS
                    this.totalAttendancePatientSUSDouble = res.data.result.totalSUSDouble
                    this.rAdult = res.data.result.rAdult
                    this.mAdult = res.data.result.mAdult
                    this.rmAdult = res.data.result.rmAdult
                    this.rPediatric = res.data.result.rPediatric
                    this.mPediatric = res.data.result.mPediatric
                    this.rmPediatric = res.data.result.rmPediatric
                    this.rSUS = res.data.result.rSUS
                    this.mSUS = res.data.result.mSUS
                    this.rmSUS = res.data.result.rmSUS

                    if (this.totalAttendances == 0) {
                        this.$toasted.global.defaultError({ msg: 'Nenhuma admissão carregada' }) 

                        this.generatingPDF = false
                        this.textBtnPrint = 'GERAR'                      
                    } else {  
                        axios.get(`${baseApiUrl}/scale/getNumberProfessionalsScale/${this.dateInitial}&${this.dateFinal}&${this.selectedCovenant}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, {}).then(res => {
                            this.totalProfessionalAdult = res.data.result.totalAdult
                            this.totalProfessionalChild = res.data.result.totalChild
                            this.totalRealizedAdult = res.data.result.totalRealizedAdult
                            this.totalRealizedDoubleAdult = res.data.result.totalRealizedDoubleAdult
                            this.totalRealizedPediatric = res.data.result.totalRealizedPediatric
                            this.totalRealizedDoublePediatric = res.data.result.totalRealizedDoublePediatric
                            this.rAdultDone = res.data.result.rAdultDone
                            this.mAdultDone = res.data.result.mAdultDone
                            this.rmAdultDone = res.data.result.rmAdultDone                            
                            this.rPediatricDone = res.data.result.rPediatricDone
                            this.mPediatricDone = res.data.result.mPediatricDone
                            this.rmPediatricDone = res.data.result.rmPediatricDone
                            this.rSUSDone = res.data.result.rSUSDone
                            this.mSUSDone = res.data.result.mSUSDone
                            this.rmSUSDone = res.data.result.rmSUSDone

                            if (this.totalProfessionals == 0) {
                                this.$toasted.global.defaultError({ msg: 'Nenhum profissional na escala do dia' }) 

                                this.generatingPDF = false
                                this.textBtnPrint = 'GERAR'                      
                            } else {
                                this.generatingPDF = false
                                this.textBtnPrint = 'GERAR'

                                this.showReport()
                            }
                        }).catch(showError)                    
                    }
                }).catch(showError)
            }
        },
        async showReport() {
            let list = []
            let listTopics = []

            listTopics.push('Atendimento Adulto')
            listTopics.push('Atendimento Infatil')
            listTopics.push('Atendimento SUS')

            Moment.locale('pt-br')       
            
            list.push({
                totalPatients: this.totalPatientsAdult,
                numberAttendances: this.totalAttendancePatientAdult,
                numberAttendancesDouble: this.totalAttendancePatientAdultDouble,
                numberProfessionals: this.totalProfessionalAdult,
                numberR_P: this.rAdult,
                numberM_P: this.mAdult,
                numberRM_P: this.rmAdult,
                numberR_R: this.rAdultDone,
                numberM_R: this.mAdultDone,
                numberRM_R: this.rmAdultDone,                
                realized: this.totalRealizedAdult,
                key: 'Atendimento Adulto'
            })

            list.push({
                totalPatients: this.totalPatientsPediatric,
                numberAttendances: this.totalAttendancePatientChild,
                numberAttendancesDouble: this.totalAttendancePatientChildDouble,
                numberProfessionals: this.totalProfessionalChild,
                numberR_P: this.rPediatric,
                numberM_P: this.mPediatric,
                numberRM_P: this.rmPediatric,
                numberR_R: this.rPediatricDone,
                numberM_R: this.mPediatricDone,
                numberRM_R: this.rmPediatricDone,                                
                realized: this.totalRealizedPediatric,
                key: 'Atendimento Infatil'
            })

            list.push({
                totalPatients: this.totalPatientSUS,
                numberAttendances: this.totalAttendancePatientSUS,
                numberAttendancesDouble: this.totalAttendancePatientSUSDouble,
                numberProfessionals: this.totalProfessionalSUS,
                numberR_P: this.rSUS,
                numberM_P: this.mSUS,
                numberRM_P: this.rmSUS,
                numberR_R: this.rSUSDone,
                numberM_R: this.mSUSDone,
                numberRM_R: this.rmSUSDone,
                realized: this.totalRealizedSUS,
                key: 'Atendimento SUS'
            })

            let cov = ''

            if (this.selectedCovenant) {
                cov = `- ${this.selectedCovenant}`
            }
            
            let contentPdf = {
                title: `ANÁLISE DE OPERAÇÃO - ${Moment(this.dateInitial).format('L')} a ${Moment(this.dateFinal).format('L')} ${cov}`,
                format: 'A4',
                orientation: 'landscape',
                separate: false,
                content: [
                    {
                        type: 'table',
                        topicFields: listTopics,
                        fields: [ 
                            {
                                original: 'totalPatients',
                                exibition: 'Qtd Pacientes'
                            },                                                                                           
                            {
                                original: 'numberAttendances',
                                exibition: 'Qtd Visitas'
                            }, 
                            {
                                original: 'numberAttendancesDouble',
                                exibition: 'Qtd Procedimentos'
                            },                                                                        
                            {
                                original: 'numberR_P',
                                exibition: 'R - Previsto'
                            },
                            {
                                original: 'numberR_R',
                                exibition: 'R - Realizado'
                            },
                            {
                                original: 'numberM_P',
                                exibition: 'M - Previsto'
                            },
                            {
                                original: 'numberM_R',
                                exibition: 'M - Realizado'
                            },
                            {
                                original: 'numberRM_P',
                                exibition: 'R.M. - Previsto'
                            },
                            {
                                original: 'numberRM_R',
                                exibition: 'R.M. - Realizado'
                            }
                        ],
                        list: list
                    },
                ]
            }

            const url = `${baseApiUrl}/reports/pdfGenerator/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.post(url, contentPdf, {responseType: 'arraybuffer'}).then(res => {  
            var file = new Blob([res.data], {type: 'application/pdf'})
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);

                this.generatingPDF = false
                this.textBtnPrint = 'GERAR'    
            }).catch(showError)                   
        },        
        loadCovenants() {
            const url = `${baseApiUrl}/covenants/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                for (let r of res.data) {
                    this.covenants.push({ value: r.fantasyname,  text: r.fantasyname })
                }
            }).catch(showError)                        
        }
    },
    mounted() {
        this.theme = localStorage.getItem('theme')

        this.loadCovenants()
    }
}
</script>
  
<style>
.card-green {
    background-color: rgb(196, 235, 220);
}

.card-wine {
    background-color: rgb(255, 241, 241);
}
</style>