<template>
    <Loading v-if="isLoading" />
    <div v-else class="advice-admin">
        <PageTitle icon="fa fa-institution" main="Cadastro de Conselhos" sub />                  
        <b-form>            
            <input id="advice-id" type="hidden" v-model="advice.id"/>
            <b-row>
                <b-col md="7" sm="12">
                    <b-card :class="`card-${theme}`" bg-variant="default" header="Informações Gerais" header-bg-variant="dark" header-text-variant="white">
                        <b-row>
                            <b-col md="8" sm="12">
                                <b-form-group label="Nome:" label-for="advice-name" class="ml-3 mr-3">
                                    <b-form-input size="sm" id="advice-name" type="text"
                                        v-model="advice.name" required                       
                                        placeholder="Informe o Nome do Conselho..." />
                                </b-form-group>         
                            </b-col>
                            <b-col md="3" sm="6">        
                                <b-form-group label="Sigla:" label-for="advice-initials">
                                    <b-form-input size="sm" id="advice-initials" type="text"
                                        v-model="advice.initials" required                       
                                        placeholder="Informe a Sigla do Conselho..." />
                                </b-form-group>   
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="4" sm="12">
                                <b-form-group label="Código ANS:" label-for="advice-codeANS" class="ml-3 mr-3">
                                    <b-form-input size="sm" id="advice-codeANS" type="text"
                                        v-model="advice.codeANS" 
                                        placeholder="Informe o Código ANS..." />
                                </b-form-group>         
                            </b-col>
                            <b-col md="4" sm="6">        
                                <b-form-group label="Número ANS:" label-for="advice-numberANS">
                                    <b-form-input size="sm" id="advice-numberANS" type="text"
                                        v-model="advice.numberANS" 
                                        placeholder="Informe o Número ANS..." />
                                </b-form-group>   
                            </b-col>
                            <b-col md="3" sm="6">        
                                <b-form-group label="UF:" label-for="advice-state">
                                    <b-form-select size="sm" v-model="advice.state" :options="states"></b-form-select>
                                </b-form-group>                                     
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </b-form>

        <b-button size="sm" variant="success" @click="save" class="mt-3">SALVAR</b-button>  
        <b-button size="sm" variant="danger" @click="cancel" class="mt-3 ml-2">CANCELAR</b-button>  
    </div>
</template>

<script>
import PageTitle from "../../template/PageTitle"
import { baseApiUrl, userKey } from '@/global'
import axios from 'axios'
import States from '../../default/states'
import Loading from "../../template/Loading"

export default {
    name: "AdvicePage",
    components: { PageTitle, Loading },
    data: function() {
      return { 
        theme: null,
        isLoading: false,
        advice: {},
        states: States.states
      }    
    },
    methods: {           
        save() {
            this.advice.name = this.advice.name.toUpperCase()
            this.advice.initials = this.advice.initials.toUpperCase()            

            const method = this.advice._id ? 'put' : 'post'
            const id = this.advice._id ? `/${this.advice._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}` : `/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`         
            
            this.isLoading = true

            axios[method](`${baseApiUrl}/advice${id}`, this.advice).then(() => {
                this.isLoading = false
                this.$toasted.global.defaultSuccess()
                this.$router.go(-1)
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })
            })
        },  
        cancel() {
            this.$router.go(-1)
        }
    },
    mounted() {
        this.theme = localStorage.getItem('theme')

        if (this.$route.params.adviceSelected) {
            this.advice = this.$route.params.adviceSelected            
        }
    }
}     
</script>

<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}

.card-violet {
  background-color: #f3eaff;
}

.card-header {
    height: 38px;
    font-size: 14px;    
}

.card-body {
    font-size: 14px;
}

.calendar-btn {
    font-size: 0.30;
}

.table th {
  padding: 0.45rem;
}

.table td {
  padding: 0.45rem;
  vertical-align: middle;
}
</style>