<template>
  <div class="SF36">
    <PageTitle icon="fa fa-file-text" main="Versão Brasileira do Questionário de Qualidade de Vida" sub="SF-36" />
  
    <div>
      <b-card :class="`card-${theme}`">
        <b-form-group label="Questões:">        
          <b-button-group>
            <b-button @click="numberQuestion = 1" :variant="selected1 != null ? 'success' : 'secondary'">1</b-button>
            <b-button @click="numberQuestion = 2" :variant="selected2 != null ? 'success' : 'secondary'">2</b-button>
            <b-button @click="numberQuestion = 3" :variant="selected3 != null ? 'success' : 'secondary'">3</b-button>
            <b-button @click="numberQuestion = 4" :variant="selected4 != null ? 'success' : 'secondary'">4</b-button>
            <b-button @click="numberQuestion = 5" :variant="selected5 != null ? 'success' : 'secondary'">5</b-button>
            <b-button @click="numberQuestion = 6" :variant="selected6 != null ? 'success' : 'secondary'">6</b-button>
            <b-button @click="numberQuestion = 7" :variant="selected7 != null ? 'success' : 'secondary'">7</b-button>
            <b-button @click="numberQuestion = 8" :variant="selected8 != null ? 'success' : 'secondary'">8</b-button>
            <b-button @click="numberQuestion = 9" :variant="selected9 != null ? 'success' : 'secondary'">9</b-button>
            <b-button @click="numberQuestion = 10" :variant="selected10 != null ? 'success' : 'secondary'">10</b-button>
            <b-button @click="numberQuestion = 11" :variant="selected11 != null ? 'success' : 'secondary'">11</b-button>
          </b-button-group>
          <b-button class="ml-3" @click="sendQuestions()" variant="info">Enviar</b-button>
        </b-form-group>

        <b-form-group v-show="numberQuestion == 1" label="1 - Em geral você diria que sua saúde é:">
          <b-form-radio @change="numberQuestion = 2" v-model="selected1" name="some-radios" value="1">Excelente</b-form-radio>
          <b-form-radio @change="numberQuestion = 2" v-model="selected1" name="some-radios" value="2">Muito Boa</b-form-radio>
          <b-form-radio @change="numberQuestion = 2" v-model="selected1" name="some-radios" value="3">Boa</b-form-radio>
          <b-form-radio @change="numberQuestion = 2" v-model="selected1" name="some-radios" value="4">Ruim</b-form-radio>
          <b-form-radio @change="numberQuestion = 2" v-model="selected1" name="some-radios" value="5">Muito Ruim</b-form-radio>
        </b-form-group>

        <b-form-group v-show="numberQuestion == 2" label="2 - Comparada há um ano atrás, como você se classificaria sua idade em geral, agora?">
          <b-form-radio @change="numberQuestion = 3" v-model="selected2" name="some-radios" value="1">Muito Melhor</b-form-radio>
          <b-form-radio @change="numberQuestion = 3" v-model="selected2" name="some-radios" value="2">Um Pouco Melhor</b-form-radio>
          <b-form-radio @change="numberQuestion = 3" v-model="selected2" name="some-radios" value="3">Quase a Mesma</b-form-radio>
          <b-form-radio @change="numberQuestion = 3" v-model="selected2" name="some-radios" value="4">Um Pouco Pior</b-form-radio>
          <b-form-radio @change="numberQuestion = 3" v-model="selected2" name="some-radios" value="5">Muito Pior</b-form-radio>
        </b-form-group>        

        <b-form-group v-show="numberQuestion == 3" label="3 - Os seguintes itens são sobre atividades que você poderia fazer atualmente 
                                                          durante um dia comum. Devido à sua saúde, você teria dificuldade para fazer 
                                                          estas atividades? Neste caso, quando?">
          <b-table ref="selectableTable" head-variant="light" outlined hover :items="listQ3" :fields="fieldsQ3" responsive="sm" id="my-table" striped fixed>
              <template v-slot:cell(resposta)="row">
                <b-form-radio @change="onRowSelected(row.item, 1)" :name="row.item.atividade" value="1">Sim, dificulta muito</b-form-radio>
                <b-form-radio @change="onRowSelected(row.item, 2)" :name="row.item.atividade" value="2">Sim, dificulta um pouco</b-form-radio>
                <b-form-radio @change="onRowSelected(row.item, 3)" :name="row.item.atividade" value="3">Não, não dificulta de modo algum</b-form-radio>                
              </template>
          </b-table>                      
        </b-form-group>  

        <b-form-group v-show="numberQuestion == 4" label="4 - Durante as últimas 4 semanas, você teve algum dos seguintes problemas com seu 
                                                          trabalho ou com alguma atividade regular, como consequência de sua saúde física?">
          <b-table ref="selectableTable" head-variant="light" outlined hover :items="listQ4" :fields="fieldsQ4" responsive="sm" id="my-table" striped fixed>
              <template v-slot:cell(resposta)="row">
                <b-form-radio @change="onRowSelected(row.item, 1)" :name="row.item.atividade" value="1">Sim</b-form-radio>
                <b-form-radio @change="onRowSelected(row.item, 2)" :name="row.item.atividade" value="2">Não</b-form-radio>            
              </template>
          </b-table>                      
        </b-form-group>           

        <b-form-group v-show="numberQuestion == 5" label="5 - Durante as últimas 4 semanas, você teve algum dos seguintes problemas com seu 
                                                          trabalho ou outra atividade regular diária, como conseqüência de algum problema 
                                                          emocional (como se sentir deprimido ou ansioso)?">
          <b-table ref="selectableTable" head-variant="light" outlined hover :items="listQ5" :fields="fieldsQ5" responsive="sm" id="my-table" striped fixed>
              <template v-slot:cell(resposta)="row">
                <b-form-radio @change="onRowSelected(row.item, 1)" :name="row.item.atividade" value="1">Sim</b-form-radio>
                <b-form-radio @change="onRowSelected(row.item, 2)" :name="row.item.atividade" value="2">Não</b-form-radio>            
              </template>
          </b-table>                      
        </b-form-group>   

        <b-form-group v-show="numberQuestion == 6" label="6 - Durante as últimas 4 semanas, de que maneira sua saúde física ou problemas 
                                                          emocionais interferiram nas suas atividades sociais normais, em relação à família, amigos ou em grupo? ">
          <b-form-radio @change="numberQuestion = 7" v-model="selected6" name="some-radios" value="1">De forma nenhuma</b-form-radio>
          <b-form-radio @change="numberQuestion = 7" v-model="selected6" name="some-radios" value="2">Ligeiramente</b-form-radio>
          <b-form-radio @change="numberQuestion = 7" v-model="selected6" name="some-radios" value="3">Moderadamente</b-form-radio>
          <b-form-radio @change="numberQuestion = 7" v-model="selected6" name="some-radios" value="4">Bastante</b-form-radio>
          <b-form-radio @change="numberQuestion = 7" v-model="selected6" name="some-radios" value="5">Extremamente</b-form-radio>
        </b-form-group>     

        <b-form-group v-show="numberQuestion == 7" label="7 - Quanta dor no corpo você teve durante as últimas 4 semanas?">
          <b-form-radio @change="numberQuestion = 8" v-model="selected7" name="some-radios" value="1">Nenhuma</b-form-radio>
          <b-form-radio @change="numberQuestion = 8" v-model="selected7" name="some-radios" value="2">Muito leve</b-form-radio>
          <b-form-radio @change="numberQuestion = 8" v-model="selected7" name="some-radios" value="3">Leve</b-form-radio>
          <b-form-radio @change="numberQuestion = 8" v-model="selected7" name="some-radios" value="4">Moderada</b-form-radio>
          <b-form-radio @change="numberQuestion = 8" v-model="selected7" name="some-radios" value="5">Grave</b-form-radio>
          <b-form-radio @change="numberQuestion = 8" v-model="selected7" name="some-radios" value="5">Muito grave</b-form-radio>
        </b-form-group>          

        <b-form-group v-show="numberQuestion == 8" label="8 - Durante as últimas 4 semanas, quanto a dor interferiu com seu trabalho normal (incluindo o trabalho dentro de casa)?">
          <b-form-radio @change="numberQuestion = 9" v-model="selected8" name="some-radios" value="1">De maneira alguma</b-form-radio>
          <b-form-radio @change="numberQuestion = 9" v-model="selected8" name="some-radios" value="2">Um pouco</b-form-radio>
          <b-form-radio @change="numberQuestion = 9" v-model="selected8" name="some-radios" value="3">Moderadamente</b-form-radio>
          <b-form-radio @change="numberQuestion = 9" v-model="selected8" name="some-radios" value="4">Bastante</b-form-radio>
          <b-form-radio @change="numberQuestion = 9" v-model="selected8" name="some-radios" value="5">Extremamente</b-form-radio>
        </b-form-group>       

        <b-form-group v-show="numberQuestion == 9" label="9 - Estas questões são sobre como você se sente e como tudo tem acontecido com você durante as últimas 4 semanas. 
                                                              Para cada questão, por favor dê uma resposta que mais se aproxime de maneira como você se sente, em relação às 
                                                              últimas 4 semanas.">
          <b-table ref="selectableTable" head-variant="light" outlined hover :items="listQ9" :fields="fieldsQ9" responsive="sm" id="my-table" striped fixed>
              <template v-slot:cell(resposta)="row">
                <b-form-radio @change="onRowSelected(row.item, 1)" :name="row.item.atividade" value="1">Todo Tempo</b-form-radio>
                <b-form-radio @change="onRowSelected(row.item, 2)" :name="row.item.atividade" value="2">A maior parte do tempo</b-form-radio>
                <b-form-radio @change="onRowSelected(row.item, 3)" :name="row.item.atividade" value="3">Uma boa parte do tempo</b-form-radio>
                <b-form-radio @change="onRowSelected(row.item, 4)" :name="row.item.atividade" value="4">Alguma parte do tempo</b-form-radio>
                <b-form-radio @change="onRowSelected(row.item, 5)" :name="row.item.atividade" value="5">Uma pequena parte do tempo</b-form-radio>
                <b-form-radio @change="onRowSelected(row.item, 6)" :name="row.item.atividade" value="6">Nunca</b-form-radio>
              </template>
          </b-table>                      
        </b-form-group>   

        <b-form-group v-show="numberQuestion == 10" label="10 - Durante as últimas 4 semanas, quanto de seu tempo a sua saúde física ou problemas emocionais interferiram com as suas atividades sociais (como visitar amigos, parentes, etc)?">
          <b-form-radio @change="numberQuestion = 11" v-model="selected10" name="some-radios" value="1">Todo Tempo</b-form-radio>
          <b-form-radio @change="numberQuestion = 11" v-model="selected10" name="some-radios" value="2">A maior parte do tempo</b-form-radio>
          <b-form-radio @change="numberQuestion = 11" v-model="selected10" name="some-radios" value="3">Alguma parte do tempo</b-form-radio>
          <b-form-radio @change="numberQuestion = 11" v-model="selected10" name="some-radios" value="4">Uma pequena parte do tempo</b-form-radio>
          <b-form-radio @change="numberQuestion = 11" v-model="selected10" name="some-radios" value="5">Nenhuma parte do tempo</b-form-radio>
        </b-form-group>   

        <b-form-group v-show="numberQuestion == 11" label="11 - O quanto verdadeiro ou falso é cada uma das afirmações para você?">
          <b-table ref="selectableTable" head-variant="light" outlined hover :items="listQ11" :fields="fieldsQ11" responsive="sm" id="my-table" striped fixed>
              <template v-slot:cell(resposta)="row">
                <b-form-radio @change="onRowSelected(row.item, 1)" :name="row.item.atividade" value="1">Definitivamente verdadeiro</b-form-radio>
                <b-form-radio @change="onRowSelected(row.item, 2)" :name="row.item.atividade" value="2">A maioria das vezes verdadeiro</b-form-radio>
                <b-form-radio @change="onRowSelected(row.item, 3)" :name="row.item.atividade" value="3">Não sei</b-form-radio>
                <b-form-radio @change="onRowSelected(row.item, 4)" :name="row.item.atividade" value="4">A maioria das vezes falso</b-form-radio>
                <b-form-radio @change="onRowSelected(row.item, 5)" :name="row.item.atividade" value="5">Definitivamente falso</b-form-radio>
              </template>
          </b-table>                      
        </b-form-group>                                              
      </b-card>
    </div> 
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle"

export default {
    name: "SF36",
    components: { PageTitle },
    data() {
      return {
        slide: 1,
        sliding: null,
        theme: null,
        numberQuestion: 1,
        selected1: null,
        selected2: null,
        selected3: null,
        selected4: null,
        selected5: null,
        selected6: null,
        selected7: null,
        selected8: null,
        selected9: null,
        selected10: null,
        selected11: null,
        listQ3: [
          {
            atividade: 'Atividades Rigorosas, que exigem muito esforço, tais como correr, levantar objetos pesados, participar em esportes árduos.',
            resposta: ''
          },
          {
            atividade: 'Atividades moderadas, tais como mover uma mesa, passar aspirador de pó, jogar bola, varrer a casa.',
            resposta: ''
          },
          {
            atividade: 'Levantar ou carregar mantimentos',
            resposta: ''
          },      
          {
            atividade: 'Subir vários  lances de escada',
            resposta: ''
          },   
          {
            atividade: 'Subir um lance de escada',
            resposta: ''
          },   
          {
            atividade: 'Curvar-se, ajoelhar-se ou dobrar-se',
            resposta: ''
          },   
          {
            atividade: 'Andar mais de 1 quilômetro',
            resposta: ''
          },   
          {
            atividade: 'Andar vários quarteirões',
            resposta: ''
          },     
          {
            atividade: 'Andar um quarteirão',
            resposta: ''
          },  
          {
            atividade: 'Tomar banho ou vestir-se',
            resposta: ''
          }                                                                        
        ],
        fieldsQ3: [                 
            { key: 'atividade', label: 'Atividades', sortable: false },
            { key: 'resposta', label: 'Respostas', sortable: false },
        ],   
        listQ4: [
          {
            atividade: 'Você diminui a quantidade de tempo que se dedicava ao seu trabalho ou a outras atividades?',
            resposta: ''
          },
          {
            atividade: 'Realizou menos tarefas do que você gostaria?',
            resposta: ''
          },
          {
            atividade: 'Esteve limitado no seu tipo de trabalho ou a outras atividades.',
            resposta: ''
          },      
          {
            atividade: 'Teve dificuldade de fazer seu trabalho ou outras atividades (p. ex. necessitou de um esforço extra).',
            resposta: ''
          }
        ],
        fieldsQ4: [                 
            { key: 'atividade', label: 'Atividades', sortable: false },
            { key: 'resposta', label: 'Respostas', sortable: false },
        ],         
        listQ5: [
          {
            atividade: 'Você diminui a quantidade de tempo que se dedicava ao seu trabalho ou a outras atividades?',
            resposta: ''
          },
          {
            atividade: 'Realizou menos tarefas do que você gostaria?',
            resposta: ''
          },
          {
            atividade: 'Não realizou ou fez qualquer das atividades com tanto cuidado como geralmente faz.',
            resposta: ''
          }
        ],
        fieldsQ5: [                 
            { key: 'atividade', label: 'Atividades', sortable: false },
            { key: 'resposta', label: 'Respostas', sortable: false },
        ],    
        listQ9: [
          {
            atividade: 'Quanto tempo você tem se sentindo cheio de vigor, de vontade, de força?',
            resposta: ''
          },
          {
            atividade: 'Quanto tempo você tem se sentido uma pessoa muito nervosa?',
            resposta: ''
          },
          {
            atividade: 'Quanto tempo você tem se sentido tão deprimido que nada pode anima-lo?',
            resposta: ''
          },
          {
            atividade: 'Quanto tempo você tem se sentido calmo ou tranquilo?',
            resposta: ''
          },
          {
            atividade: 'Quanto tempo você tem se sentido com muita energia?',
            resposta: ''
          },
          {
            atividade: 'Quanto tempo você tem se sentido desanimado ou abatido?',
            resposta: ''
          },
          {
            atividade: 'Quanto tempo você tem se sentido esgotado?',
            resposta: ''
          },
          {
            atividade: 'Quanto tempo você tem se sentido uma pessoa feliz?',
            resposta: ''
          },
          {
            atividade: 'Quanto tempo você tem se sentido cansado?',
            resposta: ''
          },                                                            
        ],
        fieldsQ9: [                 
            { key: 'atividade', label: 'Atividades', sortable: false },
            { key: 'resposta', label: 'Respostas', sortable: false },
        ],     
        listQ11: [
          {
            atividade: 'Eu costumo obedecer  um pouco mais facilmente que as outras pessoas',
            resposta: ''
          },
          {
            atividade: 'Eu sou tão saudável quanto qualquer pessoa que eu conheço',
            resposta: ''
          },
          {
            atividade: 'Eu acho que a minha saúde vai piorar',
            resposta: ''
          },
          {
            atividade: 'Minha saúde é excelente',
            resposta: ''
          }
        ],
        fieldsQ11: [                 
            { key: 'atividade', label: 'Atividades', sortable: false },
            { key: 'resposta', label: 'Respostas', sortable: false },
        ],                         
      }
    },    
    methods: {
      onRowSelected(itemSelected, item) {
        console.log(itemSelected.atividade)
        console.log(item)
      },
      sendQuestions() {

      }
    },
    mounted() {
      this.theme = localStorage.getItem('theme')
    }
}
</script>

<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}

.card-violet {
  background-color: #f3eaff;
}
</style>