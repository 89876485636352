<template>
  <footer :class="`footer-${theme}`">
    <span>
      Copyright
      <strong>imSOFTWARE</strong> © 2013-2024
    </span>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data: function () {
    return {
      theme: null
    }
  },  
  mounted() {
    this.theme = localStorage.getItem('theme')
  }
}
</script>

<style>
.footer-green {
  grid-area: footer;
  background: linear-gradient(to right, #f7fff9, #f7fff9);
  color: #0d413f;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  font-size: 14px;

  margin: 4px;
  border-radius: 10pt;
  border-top: 1px solid #28A745; 
  border-bottom: 1px solid #28A745;
  border-left: 1px solid #28A745;
  border-right: 1px solid #28A745;     
}

.footer-wine {
  grid-area: footer;
  background: linear-gradient(to right, rgb(248, 201, 201), #7A1120);
  color: rgb(248, 201, 201);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  font-size: 14px;

  margin: 4px;
  border-radius: 10pt;
  border-top: 1px solid #B79472; 
  border-bottom: 1px solid #B79472;
  border-left: 1px solid #B79472;
  border-right: 1px solid #B79472;     
}

.footer-violet {
  grid-area: footer;
  background: linear-gradient(to right, #f3eaff, #6E4DA4);
  color: #f3eaff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  font-size: 14px;

  margin: 4px;
  border-radius: 10pt;
  border-top: 1px solid #f3eaff; 
  border-bottom: 1px solid #f3eaff;
  border-left: 1px solid #f3eaff;
  border-right: 1px solid #f3eaff;     
}
</style>