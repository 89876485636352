<template>
  <div :class="`content-${theme}`">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "Content",
  data: function () {
    return {
      theme: null
    }
  },
  mounted() {
    this.theme = localStorage.getItem('theme')
  }
}
</script>

<style>
.content-green {
  grid-area: content;
  background-color: #f7fff9; 
  padding: 20px;
  margin: 1px;
  border-radius: 10pt;
  border-top: 4px solid #f7fff9; 
  border-bottom: 1px solid #f7fff9;
  border-left: 1px solid #f7fff9;
  border-right: 4px solid #f7fff9;   
}

.content-wine {
  grid-area: content;
  background-color: rgb(255, 241, 241); 
  padding: 20px;
  margin: 1px;
  border-radius: 10pt;
  border-top: 4px solid rgb(255, 241, 241); 
  border-bottom: 1px solid rgb(255, 241, 241);
  border-left: 1px solid rgb(255, 241, 241);
  border-right: 4px solid rgb(255, 241, 241);   
}

.content-violet {
  grid-area: content;
  background-color: #f3eaff; 
  padding: 20px;
  margin: 1px;
  border-radius: 10pt;
  border-top: 4px solid #f3eaff; 
  border-bottom: 1px solid #f3eaff;
  border-left: 1px solid #f3eaff;
  border-right: 4px solid #f3eaff;   
}
</style>