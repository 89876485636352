<template>
  <div class="scaleCalendar">
    <PageTitle icon="fa fa-calendar" :main="`Distribuição - ${dateSelected}`" sub="Agenda" />

    <b-row>
        <b-col md="9" sm="12" v-if="infos != null">
            <div>
                <b-card :class="`card-${theme} mt-3`" header="Informações" header-bg-variant="dark" header-text-variant="white">
                    {{infos}}
                </b-card>
            </div>            
        
            <div v-for="item in list" v-bind:key="`${item.buildingFloor}`">
                <b-card :class="`card-${theme} mt-3`" :header="item.buildingFloor" header-bg-variant="dark" header-text-variant="white">
                    <b-table id="tab1" ref="selectableTable" head-variant="light" outlined hover :items="item.admissions" 
                            :fields="fields" responsive="sm" fixed striped >
                        <template v-slot:cell(name)="row">
                            <b-link :id="`${row.item.apartment}${row.item.name}`" @click="duplicateProfessional(row.item)" v-b-popover.hover.top="'Separar Atendimento'">{{row.item.name}}</b-link>
                        </template>                             
                        <template v-slot:cell(total)="row">
                            <b-form-select size="sm" :id="`${row.item.apartment}${row.item.name}`" v-model="row.item.numberAttendances" @change="getTotals" :options="listNumerAttendances"></b-form-select>
                        </template> 
                        <template v-slot:cell(professional)="row">
                            <b-form-select size="sm" :id="`${row.item.apartment}${row.item.name}`" v-model="row.item.professional" @change="getTotals" :options="listProfessionalsSelect"></b-form-select>
                        </template>                         
                    </b-table>                                        
                </b-card>
            </div>
        </b-col>
        <b-col md="3" sm="12" v-if="infos != null">
            <div class="fixed">
                <b-card :class="`card-${theme} mt-3`" header="Profissionais" header-bg-variant="dark" header-text-variant="white">
                    <b-table ref="selectableTable" head-variant="light" outlined hover :items="professionalsScale"  style="overflow-y: scroll; height: 800px;" 
                            :fields="fieldsProfessionals" responsive="sm" fixed striped>
                    </b-table>                                        
                </b-card>
            </div>
        </b-col>
    </b-row>

    <b-button ref="btnSave" size="sm" variant="success" @click="save" class="mt-3">
        <div class="d-flex align-items-center">
            <b-spinner small v-if="generating" class="mr-2"></b-spinner>
            <span>{{textBtn}}</span>
        </div>        
    </b-button>  

    <b-button size="sm" variant="danger" @click="cancel" class="mt-3 ml-2">CANCELAR</b-button> 

    <b-row>
        <b-col md="9" sm="12" class="mt-3">
            <b-progress id="progress" :max="max">
                <b-progress-bar animated :value="value" :label="`${((value / max) * 100).toFixed(2)}%`"></b-progress-bar>
            </b-progress>
        </b-col>
    </b-row>
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'
import Moment from 'moment'
// import schedule from 'node-schedule'

export default {
    name: "ScaleDistribution",
    components: { PageTitle },
    data: function() {
        return {
            theme: null,
            infos: null,
            generating: false,
            textBtn: 'SALVAR',            
            admissions: null,
            list: [],
            professionalsScale: [],
            scaleBase: {},
            patientsAdmission: [],
            dateSelected: null,
            dateOriginal: null,
            settings: null,
            patientRepeat: [],
            job: null,
            jobID: '',
            jobResult: null,
            max: 100,
            value: 0,
            listNumerAttendances: [
                { value: 0, text: "0" },
                { value: 1, text: "1" },
                { value: 2, text: "2" },
                { value: 3, text: "3" }
            ],
            listProfessionalsSelect: [],
            fields: [
                { key: 'apartment', label: 'Apartamento', sortable: false },
                { key: 'name', label: 'Nome', sortable: false },
                { key: 'covenant', label: 'Convênio', sortable: false },
                { key: 'total', label: 'Nº Atendimentos' },
                { key: 'professional', label: 'Profissional' }
            ],
            fieldsProfessionals: [
                { key: 'name', label: 'Nome', sortable: false },
                { key: 'count', label: 'Quantidade', sortable: false }
            ]
        }
    },
    methods: {
        getAttendances() {
            const url = `${baseApiUrl}/admission/rel/admissionXApartments/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.post(url, {}).then(res => {
                this.admissions = res.data  

                let repeat = ''
                let indexList = 0

                this.list = []

                for (let a of this.admissions) {    
                    if (repeat != `${a.building} ${a.floor}`) {
                        this.list.push({
                            buildingFloor: `${a.building} ${a.floor}`
                        })
                    
                        if (repeat != '') {
                            indexList += 1
                        }

                        this.list[indexList].admissions = []
                    }

                    repeat = `${a.building} ${a.floor}`

                    let numAtt = parseInt(a.r) == 3 || parseInt(a.m) == 3 ? 3 : parseInt(a.r) == 2 || parseInt(a.m) == 2 ? 2 :
                                 parseInt(a.r) + parseInt(a.m) <= 2 ? 1 : parseInt(a.r) + parseInt(a.m) > 2 && parseInt(a.r) + parseInt(a.m) <= 4 ? 2 : 3

                    let diff = 0

                    Moment.locale('pt-br')

                    if (a.birthday.includes('/')) {
                        diff = parseInt(Moment(a.birthday, "DD/MM/YYYY").fromNow({ withoutSuffix: false }).split("anos")[0])
                    } else {
                        diff = parseInt(Moment(a.birthday, "YYYYMMDD").fromNow({ withoutSuffix: false }).split("anos")[0])
                    }

                    let adult = false

                    if (diff > Number(this.settings[0].params[8].value)) {
                        adult = true
                    }
                    
                    this.list[indexList].admissions.push({
                        apartment: a.apartment,
                        buildingFloor: repeat,
                        _id: a._id,
                        name: a.patient,
                        cpf: a.cpf,
                        birthday: Moment(a.birthday).format('L'),
                        covenant: a.covenant,
                        covenantNumber: a.covenantNumber,
                        r: a.r,
                        m: a.m,
                        obs: a.obs,
                        numberAttendances: numAtt,
                        professional: {},
                        done: 0,
                        adult: adult,
                        _cellVariants: {
                            apartment: !adult ? 'warning' : '',
                            name: !adult ? 'warning' : '',
                            covenant: !adult ? 'warning' : '',
                            total: !adult ? 'warning' : '',
                            professional: !adult ? 'warning' : ''
                        }
                    })    
                    
                    if (this.patientRepeat.length > 0) {
                        for (let pr of this.patientRepeat) {
                            if (pr.cpf == a.cpf && pr.name == a.patient) {
                                this.list[indexList].admissions.push({
                                    apartment: a.apartment,
                                    buildingFloor: repeat,
                                    _id: a._id,
                                    name: a.patient,
                                    cpf: a.cpf,
                                    birthday: Moment(a.birthday).format('L'),
                                    covenant: a.covenant,
                                    covenantNumber: a.covenantNumber,
                                    r: a.r,
                                    m: a.m,
                                    obs: a.obs,
                                    numberAttendances: numAtt,
                                    professional: {},
                                    done: 0,
                                    adult: adult,
                                    _cellVariants: {
                                        apartment: !adult ? 'warning' : '',
                                        name: !adult ? 'warning' : '',
                                        covenant: !adult ? 'warning' : '',
                                        total: !adult ? 'warning' : '',
                                        professional: !adult ? 'warning' : ''
                                    }
                                })
                            }
                        }
                    }
                }       
                
                if (this.patientRepeat.length == 0) {
                    this.getInfos()
                }
            }).catch(showError)             
        },
        loadScaleBase() {
            const url = `${baseApiUrl}/scaleBase/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                this.scaleBase = res.data  
            }).catch(showError)                              
        },        
        loadSettings() {
            const url = `${baseApiUrl}/setting/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                if (res.data.length == 0) {
                    this.$toasted.global.defaultError({msg: 'Configurações do sistema não informadas'})
                } else {
                    this.settings = res.data                
                }
            }).catch(showError)                      
        },    
        loadAdmissions() {
            try {
                const url = `${baseApiUrl}/admission/notreleased/0&0&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
                axios.get(url).then(res => {
                    let admissions = res.data   
                    
                    let descriptionAttendance = ''

                    for (let a of admissions) {                    
                        for (let at of a.attendance) {
                            let morning = at.morning ? 'MANHÃ ' : ''
                            let evening = at.evening ? 'TARDE ' : ''
                            let night = at.night ? 'NOITE. ' : ''
                            
                            descriptionAttendance = descriptionAttendance + at.name + ': ' + morning + evening + night
                        }

                        this.patientsAdmission.push(                        
                            {
                                _id: a.patient._id,
                                name: a.patient.name,
                                cpf: a.patient.cpf,
                                rg: a.patient.rg,
                                birthday: a.patient.birthday,
                                covenant: a.patient.covenant.name,
                                covenantNumber: a.patient.covenant.number,
                                accommodation: a.accommodation,
                                accommodationFull: `${a.accommodation.building} -> ${a.accommodation.floor} -> ${a.accommodation.apartment} -> ${a.accommodation.name}`,
                                attendances: a.attendance,
                                admissionCode: a._id,
                                admissionAttendanceNumber: a.attendanceNumber,
                                admissionNumberGuide: a.numberGuide,
                                admissionPassword: a.password,
                                descriptionAttendance: descriptionAttendance,
                                comments: a.comments,
                                cellVariants: {
                                    name: '',
                                    cpf: '',
                                    covenant: '',
                                    descriptionAttendance: '',
                                    accommodationFull: ''
                                }
                            }                        
                        )

                        descriptionAttendance = ''
                    }                           
                }).catch(showError)          
            } catch(e) {
                console.log(e)
            }   
        },     
        cancel() {
            this.$router.go(-1)
        },
        async save() {
            let totalAttendances = 0

            for (let l of this.list) {
                for (let a of l.admissions) {
                    totalAttendances += a.numberAttendances
                }
            }    

            let weekday = new Array(7);
            
            weekday[0] = "sunday";
            weekday[1] = "monday";
            weekday[2] = "tuesday";
            weekday[3] = "wednesday";
            weekday[4] = "thursday";
            weekday[5] = "friday";
            weekday[6] = "saturday";  

            let dateOrigin = Moment(this.settings[0].params[5].value)

            let date = Moment(this.dateOriginal)

            let newDate = new Date(date._d.getTime() + (0 * 24 * 60 * 60 * 1000))

            let day = weekday[newDate.getDay()]

            let week = (Moment(newDate).diff(dateOrigin, 'days') + 1) 
            
            if (week == 7) {
                week = 1
            } else {
                week = Math.ceil(week / 7)                            
            }

            while (week > 4) {
                week = week - 4
            }

            let dayScale = newDate.getDate()

            if (dayScale < 10) {
                dayScale = '0' + dayScale
            }

            let monthScale = newDate.getMonth() + 1

            if (monthScale < 10) {
                monthScale = '0' + monthScale
            }
        
            let newDateScale = `${newDate.getFullYear()}-${monthScale}-${dayScale}`

            let listHibrid = []

            for (let p of this.professionalsScale) {
                let hasAdult = false
                let hasPedriatic = false

                let nameProf = p.name

                for (let l of this.list) {
                    for (let a of l.admissions) {
                        if (a.professional.name == nameProf) {
                            if (a.adult) {
                                hasAdult = true
                            } else {
                                hasPedriatic = true
                            }
                        }

                        let finded = false

                        if (hasAdult && hasPedriatic) {
                            for (let lh of listHibrid) {
                                if (nameProf != undefined) {
                                    if (nameProf == lh) {
                                        finded = true
                                        break
                                    }
                                }  
                            }

                            if (!finded && nameProf != undefined) {
                                listHibrid.push(nameProf)
                            }
                        }                         
                    }
                }
            }

            this.newScale = null

            if (listHibrid.length > 0) {
                    let value = await this.$bvModal.msgBoxConfirm(`O(s) profissional(is) ${listHibrid.toString()}, está(ão) com paciente(s) adultos e pediátricos. Deseja confirmar a distribuição?`, {
                    title: 'Confirmação',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Sim',
                    cancelTitle: 'Não',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })

                if (value) {
                    for (let sb of this.scaleBase) {
                        if (sb.day == day && sb.week == week) {
                            this.generating = true
                            this.textBtn = 'Aguarde...'

                            this.newScale = {
                                date: newDateScale,
                                professionals: this.professionalsScale,
                                totalAttendances: totalAttendances,
                                attendances: this.list,
                                interval: this.settings[0].params[4].value,
                                admissions: this.patientsAdmission
                            }

                            break
                        }
                    }

                    this.$refs.btnSave.disabled = true

                    this.jobResult = null

                    if (this.newScale) {
                        axios['post'](`${baseApiUrl}/scale/distributionScale/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, this.newScale).then(res => {
                            this.value = 0

                            this.jobID = res.data.id

                            this.getResultJob()
                        }).catch(showError)
                    }
                }
            } else {
                for (let sb of this.scaleBase) {
                    if (sb.day == day && sb.week == week) {
                        this.generating = true
                        this.textBtn = 'Aguarde...' 

                        this.newScale = {
                            date: newDateScale,
                            professionals: this.professionalsScale,
                            totalAttendances: totalAttendances,
                            attendances: this.list,
                            interval: this.settings[0].params[4].value,
                            admissions: this.patientsAdmission
                        }

                        break
                    }
                }

                this.$refs.btnSave.disabled = true

                if (this.newScale) {
                    axios['post'](`${baseApiUrl}/scale/distributionScale/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, this.newScale).then(res => {
                        this.value = 0

                        this.jobID = res.data.id

                        this.getResultJob()
                    }).catch(showError)
                }
            }
        },
        async getResultJob() {
            return new Promise((resolve, reject) => {
                try {
                    const urlJob = `${baseApiUrl}/jobs/${this.jobID}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`

                    const startTime = Date.now()
                    const intervalTime = 7000 // Intervalo de 5 segundos
                    const endTime = startTime + 600000 // 10 minutos

                    // Usando setInterval para checar o job periodicamente
                    const intervalId = setInterval(async () => {
                        try {
                            const res = await axios.get(urlJob)
                            this.jobResult = res.data
                            this.value = this.jobResult.progress

                            if (this.jobResult.status > 0 || Date.now() >= endTime) {
                                this.value = 100
                                this.finishDistribution()
                                clearInterval(intervalId) // Para o intervalo quando o job terminar ou o tempo acabar
                                resolve()
                            }
                        } catch (error) {
                            showError(error)
                            clearInterval(intervalId) // Para o intervalo em caso de erro
                            reject(error)
                        }
                    }, intervalTime)
                } catch(e) {
                    reject()
                }
            })
        },
        finishDistribution() {
            this.generating = false
            this.textBtn = 'SALVAR'
            this.$toasted.global.defaultSuccess({msg: 'Distribuição realizada com sucesso.'})
            this.$router.go(-1)
        },
        getInfos() {
            let totalAttendances = 0

            for (let p of this.$route.params.scaleDistribution.professionals) {
                let pat = []
                let attendance = []
                attendance.push({ patients: pat })

                this.professionalsScale.push({
                    name: p.name,
                    cpf: p.cpf,
                    checkin: p.checkin,
                    checkout: p.checkout,
                    count: 0,
                    attendance: attendance
                })            
            }               

            for (let l of this.list) {
                for (let a of l.admissions) {
                    totalAttendances += a.numberAttendances
                }
            } 

            let infoTotal = ''
            let totGeneralAttendancesProfessional = 0             
            
            infoTotal = (totalAttendances / this.professionalsScale.length).toFixed(2).split('.')   
            
            for (let p of this.professionalsScale) {
                this.listProfessionalsSelect.push({
                    value: p, text: p.name
                })

                let splBegin = p.checkin.split(":")
                let splFinal = p.checkout.split(":")

                let beginMin = (Number(splBegin[0] * 60)) + Number(splBegin[1])
                let finalMin = (Number(splFinal[0] * 60)) + Number(splFinal[1])

                totGeneralAttendancesProfessional = totGeneralAttendancesProfessional + ((finalMin - beginMin) / parseInt(this.settings[0].params[4].value))
            }

            let max = parseInt(infoTotal[0])
          
            this.infos = `Total de Atendimentos: ${totalAttendances}     |      Total Por Profissional: ${max}     |      Transbordo: ${totalAttendances - totGeneralAttendancesProfessional < 0 ? 0 : totalAttendances - totGeneralAttendancesProfessional}`
        },
        getTotals() {
            for (let p of this.professionalsScale) {
                p.count = 0
            }

            for (let l of this.list) {
                for (let a of l.admissions) {
                    for (let p of this.professionalsScale) {
                        if (a.professional) {
                            if (a.professional.name == p.name && a.professional.cpf == p.cpf) {
                                p.count += a.numberAttendances
                            }
                        }
                    }
                }
            }
        },
        duplicateProfessional(itemSelected) {
            this.$bvModal.msgBoxConfirm(`Deseja separar os atendimentos do paciente ${itemSelected.name}?`, {
                title: 'Confirmação',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Sim',
                cancelTitle: 'Não',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            }).then(value => {            
                if (value) {
                    this.patientRepeat.push(itemSelected)

                    this.getAttendances()
                }
            })            
        }        
    },
    created() {
        this.theme = localStorage.getItem('theme')
        
        Moment.locale('pt-br')
        this.dateSelected = Moment(this.$route.params.scaleDistribution.date).format('L')
        this.dateOriginal = this.$route.params.scaleDistribution.date

        this.loadSettings()
        this.loadScaleBase()
        this.getAttendances()
        this.loadAdmissions()        
    }
}
</script>

<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}

.card-violet {
  background-color: #f3eaff;
}

.fixed {
  top: 50px;
  bottom: 50px;
  position: sticky;
}

</style>
