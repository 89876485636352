<template>
  <div class="satisfactionSurvey">
    <PageTitle icon="fa fa-file-text" main="Questionário" sub="Pesquisa de Satisfação" />
  
    <div>
      <b-card :class="`card-${theme}`">
        <b-button v-show="question.status == 0" @click="sendQuestions()" variant="info">Enviar</b-button>

        <b-form-group class="mt-3" label="Avaliação Geral da Clínica">
            <b-form-group class="ml-5" label="Facilidade no agendamento das sessões:">
                <b-form-radio v-model="selected1_1" name="some-radios-1_1" value="1">Péssimo</b-form-radio>
                <b-form-radio v-model="selected1_1" name="some-radios-1_1" value="2">Ruim</b-form-radio>
                <b-form-radio v-model="selected1_1" name="some-radios-1_1" value="3">Razoável</b-form-radio>
                <b-form-radio v-model="selected1_1" name="some-radios-1_1" value="4">Bom</b-form-radio>
                <b-form-radio v-model="selected1_1" name="some-radios-1_1" value="5">Ótimo</b-form-radio>
            </b-form-group>

            <b-form-group class="ml-5" label="Clareza nas informações prestadas:">
                <b-form-radio v-model="selected1_2" name="some-radios-1_2" value="1">Péssimo</b-form-radio>
                <b-form-radio v-model="selected1_2" name="some-radios-1_2" value="2">Ruim</b-form-radio>
                <b-form-radio v-model="selected1_2" name="some-radios-1_2" value="3">Razoável</b-form-radio>
                <b-form-radio v-model="selected1_2" name="some-radios-1_2" value="4">Bom</b-form-radio>
                <b-form-radio v-model="selected1_2" name="some-radios-1_2" value="5">Ótimo</b-form-radio>
            </b-form-group>    
            
            <b-form-group class="ml-5" label="Limpeza do local de atendimento:">
                <b-form-radio v-model="selected1_3" name="some-radios-1_3" value="1">Péssimo</b-form-radio>
                <b-form-radio v-model="selected1_3" name="some-radios-1_3" value="2">Ruim</b-form-radio>
                <b-form-radio v-model="selected1_3" name="some-radios-1_3" value="3">Razoável</b-form-radio>
                <b-form-radio v-model="selected1_3" name="some-radios-1_3" value="4">Bom</b-form-radio>
                <b-form-radio v-model="selected1_3" name="some-radios-1_3" value="5">Ótimo</b-form-radio>
            </b-form-group> 
            
            <b-form-group class="ml-5" label="Limpeza banheiros/Recepção:">
                <b-form-radio v-model="selected1_4" name="some-radios-1_4" value="1">Péssimo</b-form-radio>
                <b-form-radio v-model="selected1_4" name="some-radios-1_4" value="2">Ruim</b-form-radio>
                <b-form-radio v-model="selected1_4" name="some-radios-1_4" value="3">Razoável</b-form-radio>
                <b-form-radio v-model="selected1_4" name="some-radios-1_4" value="4">Bom</b-form-radio>
                <b-form-radio v-model="selected1_4" name="some-radios-1_4" value="5">Ótimo</b-form-radio>
            </b-form-group>             
        </b-form-group>

        <b-form-group label="Avaliação da Recepção">
            <b-form-group class="ml-5" label="Cordialidade durante o atendimento:">
                <b-form-radio v-model="selected1_5" name="some-radios-1_5" value="1">Péssimo</b-form-radio>
                <b-form-radio v-model="selected1_5" name="some-radios-1_5" value="2">Ruim</b-form-radio>
                <b-form-radio v-model="selected1_5" name="some-radios-1_5" value="3">Razoável</b-form-radio>
                <b-form-radio v-model="selected1_5" name="some-radios-1_5" value="4">Bom</b-form-radio>
                <b-form-radio v-model="selected1_5" name="some-radios-1_5" value="5">Ótimo</b-form-radio>
            </b-form-group>

            <b-form-group class="ml-5" label="Qualidade do atendimento:">
                <b-form-radio v-model="selected1_6" name="some-radios-1_6" value="1">Péssimo</b-form-radio>
                <b-form-radio v-model="selected1_6" name="some-radios-1_6" value="2">Ruim</b-form-radio>
                <b-form-radio v-model="selected1_6" name="some-radios-1_6" value="3">Razoável</b-form-radio>
                <b-form-radio v-model="selected1_6" name="some-radios-1_6" value="4">Bom</b-form-radio>
                <b-form-radio v-model="selected1_6" name="some-radios-1_6" value="5">Ótimo</b-form-radio>
            </b-form-group>    
            
            <b-form-group class="ml-5" label="Clareza nas informações repassadas:">
                <b-form-radio v-model="selected1_7" name="some-radios-1_7" value="1">Péssimo</b-form-radio>
                <b-form-radio v-model="selected1_7" name="some-radios-1_7" value="2">Ruim</b-form-radio>
                <b-form-radio v-model="selected1_7" name="some-radios-1_7" value="3">Razoável</b-form-radio>
                <b-form-radio v-model="selected1_7" name="some-radios-1_7" value="4">Bom</b-form-radio>
                <b-form-radio v-model="selected1_7" name="some-radios-1_7" value="5">Ótimo</b-form-radio>
            </b-form-group>           
        </b-form-group>   
        
        <b-form-group label="Avaliação Fisioterapeutas">
            <b-form-group class="ml-5" label="Atendimento:">
                <b-form-radio v-model="selected1_8" name="some-radios-1_8" value="1">Péssimo</b-form-radio>
                <b-form-radio v-model="selected1_8" name="some-radios-1_8" value="2">Ruim</b-form-radio>
                <b-form-radio v-model="selected1_8" name="some-radios-1_8" value="3">Razoável</b-form-radio>
                <b-form-radio v-model="selected1_8" name="some-radios-1_8" value="4">Bom</b-form-radio>
                <b-form-radio v-model="selected1_8" name="some-radios-1_8" value="5">Ótimo</b-form-radio>
            </b-form-group>

            <b-form-group class="ml-5" label="Cordialidade:">
                <b-form-radio v-model="selected1_9" name="some-radios-1_9" value="1">Péssimo</b-form-radio>
                <b-form-radio v-model="selected1_9" name="some-radios-1_9" value="2">Ruim</b-form-radio>
                <b-form-radio v-model="selected1_9" name="some-radios-1_9" value="3">Razoável</b-form-radio>
                <b-form-radio v-model="selected1_9" name="some-radios-1_9" value="4">Bom</b-form-radio>
                <b-form-radio v-model="selected1_9" name="some-radios-1_9" value="5">Ótimo</b-form-radio>
            </b-form-group>    
            
            <b-form-group class="ml-5" label="Conhecimento técnico empregado durante as sessões:">
                <b-form-radio v-model="selected1_10" name="some-radios-1_10" value="1">Péssimo</b-form-radio>
                <b-form-radio v-model="selected1_10" name="some-radios-1_10" value="2">Ruim</b-form-radio>
                <b-form-radio v-model="selected1_10" name="some-radios-1_10" value="3">Razoável</b-form-radio>
                <b-form-radio v-model="selected1_10" name="some-radios-1_10" value="4">Bom</b-form-radio>
                <b-form-radio v-model="selected1_10" name="some-radios-1_10" value="5">Ótimo</b-form-radio>
            </b-form-group>   
            
            <b-form-group class="ml-5" label="Clareza nas informações sobre o tratamento:">
                <b-form-radio v-model="selected1_11" name="some-radios-1_11" value="1">Péssimo</b-form-radio>
                <b-form-radio v-model="selected1_11" name="some-radios-1_11" value="2">Ruim</b-form-radio>
                <b-form-radio v-model="selected1_11" name="some-radios-1_11" value="3">Razoável</b-form-radio>
                <b-form-radio v-model="selected1_11" name="some-radios-1_11" value="4">Bom</b-form-radio>
                <b-form-radio v-model="selected1_11" name="some-radios-1_11" value="5">Ótimo</b-form-radio>
            </b-form-group>   
            
            <b-form-group label="Deixe aqui seus comentários sobre o atendimento dos Fisioterapeutas:">
                <b-form-textarea id="textarea" v-model="selected1_12" rows="1" max-rows="6"></b-form-textarea>
            </b-form-group>
        </b-form-group>        
      </b-card>
    </div> 
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'
import Moment from 'moment'

export default {
    name: "SatisfactionSurvey",
    components: { PageTitle },
    data() {
      return {
        patient: null,
        question: null,
        theme: null,
        numberQuestion: 1,
        selected1_1: null,
        selected1_2: null,
        selected1_3: null,
        selected1_4: null,
        selected1_5: null,
        selected1_6: null,
        selected1_7: null,
        selected1_8: null,
        selected1_9: null,
        selected1_10: null,
        selected1_11: null,
        selected1_12: null,
      }
    },    
    methods: {
      sendQuestions() {
          Moment.locale('pt-br')  
          
          axios.get(`${baseApiUrl}/patient/getById/${this.patient._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`).then(res => {
            this.patient = res.data

            let data = {}
            let index = 0

            for (let q of this.patient.questions) {
              if (q.type == 3 && q.name == this.question.name && q.answers.length == 0) {
                q.answers = []

                q.status = 1
                q.answers.push(
                  {
                    'dateAnswers': Moment(new Date()).format('L')
                  }, 
                  {
                    '1_1': this.selected1_1,
                    '1_2': this.selected1_2,
                    '1_3': this.selected1_3,
                    '1_4': this.selected1_4,
                    '1_5': this.selected1_5,
                    '1_6': this.selected1_6,
                    '1_7': this.selected1_7,
                    '1_8': this.selected1_8,
                    '1_9': this.selected1_9,
                    '1_10': this.selected1_10,
                    '1_11': this.selected1_11,
                    '1_12': this.selected1_12
                  }                                                                                                                                                                                                                                                                                                                                                                                                                                                            
                ) 

                data.index = index
                data.answers = q.answers
              }

              index += 1
            }        
            
            data.idPatient = this.patient._id

            axios.post(`${baseApiUrl}/patient/setAnswerQuestion/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, data)
              .then(() => {
                  this.$store.commit('setResultPatient', this.patient)

                  this.$toasted.global.defaultSuccess()
                  this.$router.go(-1)
              })  
              .catch(showError) 
          }).catch((e) => {
            console.log(e)
            showError
          })
        // }
      }
    },
    mounted() {
      this.theme = localStorage.getItem('theme')

      this.patient = this.$route.params.questionSelected.patient
      this.question = this.$route.params.questionSelected

      if (this.$route.params.questionSelected.answers.length > 0) {
        this.selected1_1 = this.$route.params.questionSelected.answers[1]['1_1']
        this.selected1_2 = this.$route.params.questionSelected.answers[1]['1_2']
        this.selected1_3 = this.$route.params.questionSelected.answers[1]['1_3']
        this.selected1_4 = this.$route.params.questionSelected.answers[1]['1_4']
        this.selected1_5 = this.$route.params.questionSelected.answers[1]['1_5']
        this.selected1_6 = this.$route.params.questionSelected.answers[1]['1_6']
        this.selected1_7 = this.$route.params.questionSelected.answers[1]['1_7']
        this.selected1_8 = this.$route.params.questionSelected.answers[1]['1_8']
        this.selected1_9 = this.$route.params.questionSelected.answers[1]['1_9']
        this.selected1_10 = this.$route.params.questionSelected.answers[1]['1_10']
        this.selected1_11 = this.$route.params.questionSelected.answers[1]['1_11']
        this.selected1_12 = this.$route.params.questionSelected.answers[1]['1_12']        
      }
    }
}
</script>

<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}

.card-violet {
  background-color: #f3eaff;
}
</style>