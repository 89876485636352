<template>
  <div name="tsPageMain" class="events-pages">
    <PageTitle icon="fa fa-fire" main="Ocorrências" sub />
    <b-form name="tsForm">
      <div name="tsPage" class="admin-pages-tabs">
          <b-button size="sm" variant="success" @click="newEvent()">
              NOVO
          </b-button>      
          <b-button size="sm" class="ml-3" @click="print" variant="info" v-b-popover.hover.top="'Imprimir Listagem'">
              <div class="d-flex align-items-center">
                  <b-spinner small v-if="generatingPDF" class="mr-2"></b-spinner>
                  <span>{{textBtnPrint}}</span>
              </div>
          </b-button>    
          <hr>      
          <b-row>
            <b-col md="4" sm="12">
              <b-form-input size="sm" placeholder="Filtrar..." v-model="ftext"></b-form-input>
            </b-col> 
          </b-row>             
          <hr>               
          <div id="divTable">       
            <b-table head-variant="light" outlined hover :fields="fields" responsive="sm" class="my-table" id="my-table" :items="events"
                    :per-page="perPage" :current-page="currentPage" striped fixed :filter="ftext" >
                <template v-slot:cell(actions)="row">
                    <b-button size="sm" variant="warning" @click="onRowSelected(row.item)" v-b-popover.hover.top="'Alterar'">
                        <i class="fa fa-pencil"></i>
                    </b-button>
                    <b-button size="sm" variant="danger" @click="removeEvent(row.item)" v-b-popover.hover.top="'Excluir'" class="ml-2">
                        <i class="fa fa-trash"></i>
                    </b-button>                     
                </template>                 
            </b-table>       
          </div>

          <div v-if="events.length == 0" class="d-flex justify-content-center mb-3">    
              <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner" type="grow"></b-spinner>
          </div>           

          <b-pagination size="sm" pills v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table" class="page-item">
          </b-pagination>
      </div>
    </b-form>
  </div>
</template>

<script>
import PageTitle from "../../template/PageTitle";
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'

export default {
    name: 'Events',
    components: { PageTitle },
    data: function() {
      return {
        generatingPDF: false,
        textBtnPrint: 'IMPRIMIR',
        ftext: '',
        events: [],
        currentPage: 1,
        perPage: 10,
        fields: [                 
            { key: 'name', label: 'Nome', sortable: true },
            { key: 'actions', label: 'Ações' }
        ],                    
      }
    },
    computed: {
      rows() {
        return this.events.length
      }
    },
    methods: {
        print() {
            this.generatingPDF = true
            this.textBtnPrint = 'Aguarde...'   

            let contentPdf = {
                title: 'OCORRÊNCIAS',
                format: 'A4',
                orientation: 'portrait',
                separate: false,
                content: [
                    {
                        type: 'table',
                        topicFields: [],
                        fields: [
                          {
                            original: 'name',
                            exibition: 'Nome'
                          }
                        ],
                        list: this.events
                    }
                ]
            }

            const url = `${baseApiUrl}/reports/pdfGenerator/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.post(url, contentPdf, {responseType: 'arraybuffer'}).then(res => {  
              var file = new Blob([res.data], {type: 'application/pdf'})
              var fileURL = URL.createObjectURL(file);
              window.open(fileURL);

                this.generatingPDF = false
                this.textBtnPrint = 'IMPRIMIR'    
            }).catch(showError)       
        },
        newEvent() {
          this.$router.push({name: 'event', params: { },})          
        },
        onRowSelected(itemSelected) {
          let eventSelected = {    
              _id: itemSelected._id,
              name: itemSelected.name,
              type: itemSelected.type
          }              

          this.$router.push({name: 'event', params: { eventSelected },})            
        },               
        loadEvents() {
            const url = `${baseApiUrl}/event/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {  
                this.events = res.data
            }).catch(showError)                      
        },    
        removeEvent(itemSelected) {
          this.$bvModal.msgBoxConfirm('Deseja remover a ocorrência?', {
              title: 'Confirmação',
              buttonSize: 'sm',
              okVariant: 'danger',
              okTitle: 'Sim',
              cancelTitle: 'Não',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true
          }).then(value => {            
              if (value) {                
                const id = itemSelected._id
                axios.delete(`${baseApiUrl}/event/${id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`).then(() => {
                  this.$toasted.global.defaultSuccess()
                  this.loadEvents()
                }).catch(showError)                
              }                
          }).catch() 
        }
    },
    mounted() {
        this.loadEvents()
    }      
};
</script>

<style>
.page-item .page-font {
    color:#44A08D;  
}

.page-item.active .page-link {  
    background-color:#44A08D;  
    border-color:  #44A08D;      
}

.page-item.active .page-link:hover {  
    background-color:#44A08D;  
    border-color:  #44A08D;  
}

.card-header {
    height: 38px;
    font-size: 14px;    
}

.card-body {
    font-size: 14px;
}

.calendar-btn {
    font-size: 0.30;
}

.table th {
  padding: 0.45rem;
}

.table td {
  padding: 0.45rem;
  vertical-align: middle;
}
</style>