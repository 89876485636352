<template>
  <div class="admissionXAccommodation">
    <PageTitle icon="fa fa-file-text" main="Admissão x Local de Internação" sub="Relatório" />
        <div class="admissionXAccommodation-page-tabs">
            <b-row>
                <b-col md="6" sm="12">
                    <b-card :class="`card-${theme}`" header="Informações" header-bg-variant="dark" header-text-variant="white">
                        <b-row>
                            <b-col md="5" sm="12">
                                <b-form-group label="Prédio:" label-for="bgans-building">
                                    <b-form-input size="sm" id="bgans-building" v-model="resultLookupAccommodation.name" disabled />
                                </b-form-group>
                            </b-col>
                            <b-col md="1" sm="12">
                                <b-button size="sm" class="mt-4" v-b-modal.modal-prevent-closing>
                                    <i class="fa fa-search-plus"></i>
                                </b-button>  
                                <Lookup ref="Lookup" title="Locais de Internação" :list="buildings" :fields="fields" :invalidFeedback="invalidFeedback"
                                        resultTable="accommodation" :type="type"></Lookup>                                
                            </b-col>                            
                            <b-col md="3" sm="12">
                                <b-form-group label="Andar (Nível):" label-for="bgans-floor">
                                    <b-form-input size="sm" id="bgans-floor" v-model="level" type="number" />
                                </b-form-group>
                            </b-col>
                            <b-col md="2" sm="12">
                                <b-button size="sm" variant="info" class="mt-4" @click="showReport">
                                    <div class="d-flex align-items-center">
                                        <b-spinner small v-if="generatingPDF" class="mr-2"></b-spinner>
                                        <span>{{textBtnPrint}}</span>
                                    </div>                                    
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card>                    
                </b-col>                  
            </b-row>   
        </div>
  </div>  
</template>

<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'
import Lookup from '../default/Lookup'
import { mapState } from "vuex"
import Moment from 'moment'

export default {
    name: "admissionXAccommodation",
    components: { PageTitle, Lookup },    
    data: function() {
        return {
            theme: null,
            generatingPDF: false,
            textBtnPrint: 'GERAR',
            admissions: [],
            building: '',
            buildings: [],
            level: 0,
            invalidFeedback: '',  
            type: 'Prédio',
            fields: [
                { key: 'name', label: 'Prédio', sortable: true }
            ]        
        }
    },   
    computed: mapState(["resultLookupAccommodation"]),
    methods: {
        async showReport() {
            await this.loadAdmissions()

            if (this.admissions.length == 0) {
               this.$toasted.global.defaultError({ msg: 'Nenhuma admissão carregada' }) 
            } else {
                this.generatingPDF = true
                this.textBtnPrint = 'Aguarde...'  
                
                let list = []

                Moment.locale('pt-br')                

                for (let a of this.admissions) {
                    a.date2 = Moment(a.date).format('L')

                    list.push({
                        date: a.date2,
                        name: a.patient.name,
                        cpf: a.patient.cpf,
                        accommodation: `${a.accommodation.building} -> ${a.accommodation.floor} -> ${a.accommodation.apartment} -> ${a.accommodation.name}`
                    })
                }

                let contentPdf = {
                    title: 'ADMISSÕES X LOCAIS DE ATENDIMENTO',
                    format: 'A4',
                    orientation: 'landscape',
                    separate: false,
                    content: [
                        {
                            type: 'table',
                            topicFields: [],
                            fields: [
                                {
                                    original: 'date',
                                    exibition: 'Data'
                                },                                
                                {
                                    original: 'name',
                                    exibition: 'Nome'
                                },
                                {
                                    original: 'cpf',
                                    exibition: 'CPF'
                                },
                                {
                                    original: 'accommodation',
                                    exibition: 'Local de Atendimento'
                                }
                            ],
                            list: list
                        },
                    ]
                }

                const url = `${baseApiUrl}/reports/pdfGenerator/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
                axios.post(url, contentPdf, {responseType: 'arraybuffer'}).then(res => {  
                var file = new Blob([res.data], {type: 'application/pdf'})
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);

                    this.generatingPDF = false
                    this.textBtnPrint = 'GERAR'    
                }).catch(showError)                   
            }     
        },
        async loadAdmissions() {
            if (this.level == '') {
                this.level = 0
            }

            let data = {
                building: this.resultLookupAccommodation.name,
                floor: this.level
            }

            const url = `${baseApiUrl}/admission/rel/admissionXAccommodation/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            await axios.post(url, data).then(res => {
                this.admissions = res.data                
            }).catch(showError)        
        },
        loadBuildings() {
            const url = `${baseApiUrl}/accommodation/type/Predio&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                this.buildings = res.data                
            }).catch(showError)        
        }
    },
    created() {
        this.$store.commit("setResultLookupAccommodation", {name: ''})
    },
    mounted() {        
        this.theme = localStorage.getItem('theme')
        this.loadBuildings()
    }
}
</script>

<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}
</style>