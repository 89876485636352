<template>
  <div class="admissionNoMoviment">
    <PageTitle icon="fa fa-file-text" main="Admissões Sem Movimentação" sub="Relatório" />
        <div class="admissionNoMoviment-page-tabs">
            <b-row>
                <b-col md="6" sm="12" class="mt-3">
                    <b-card :class="`card-${theme}`" header="Informações" header-bg-variant="dark" header-text-variant="white">
                        <b-row> 
                            <b-col md="3" sm="12">
                                <b-form-group label="Número de Dias:">
                                    <b-input size="sm" v-model="numberDays" type="number"></b-input>
                                </b-form-group>
                            </b-col>                      
                            <b-col md="2" sm="12">
                                <b-button size="sm" variant="info" class="mt-4" @click="loadAdmissionsNoMoviment()">
                                    <div class="d-flex align-items-center">
                                        <b-spinner small v-if="generatingPDF" class="mr-2"></b-spinner>
                                        <span>{{textBtnPrint}}</span>
                                    </div>                                    
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card>                    
                </b-col>                  
            </b-row>   
        </div>
  </div>  
</template>

<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'
import Moment from 'moment'

export default {
    name: "AdmissionNoMoviment",
    components: { PageTitle },    
    data: function() {
        return {
            theme: null,
            generatingPDF: false,
            textBtnPrint: 'GERAR',
            admissionsNoMoviment: [],
            level: 0,
            invalidFeedback: '',
            numberDays: 0  
        }
    },   
    methods: {
        async showReport() {
            let contentPdf = {
                title: `ADMISSÕES SEM MOVIMENTAÇÃO - Últimos ${this.numberDays} dias`,
                format: 'A4',
                orientation: 'portrait',
                separate: false,
                content: [
                    {
                        type: 'table',
                        topicFields: [],
                        fields: [      
                            {
                                original: 'patient',
                                exibition: 'Paciente'
                            },
                            {
                                original: 'building',
                                exibition: 'Prédio'
                            },
                            {
                                original: 'floor',
                                exibition: 'Andar'
                            },                                                                                                                   
                            {
                                original: 'apartment',
                                exibition: 'Apartamento'
                            },                                
                            {
                                original: 'covenant',
                                exibition: 'Convênio'
                            }                                                                               
                        ],
                        list: this.admissionsNoMoviment,
                    },
                ]
            }

            const url = `${baseApiUrl}/reports/pdfGenerator/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.post(url, contentPdf, {responseType: 'arraybuffer'}).then(res => {  
            var file = new Blob([res.data], {type: 'application/pdf'})
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);

                this.generatingPDF = false
                this.textBtnPrint = 'GERAR'    
            }).catch(showError)                   
        },
        async loadAdmissionsNoMoviment() {
            let valid = true

            if (this.numberDays <= 0 || this.numberDays == '') {
                this.$toasted.global.defaultError({msg: 'Informe o número de dias.'})
                valid = false
            } 

            if (valid) {
                Moment.locale('pt-br')  

                let date = Moment(new Date()).subtract(this.numberDays, 'days').format('L')

                let d = date.split('/')                

                this.generatingPDF = true
                this.textBtnPrint = 'Aguarde...' 

                const url = `${baseApiUrl}/admission/rel/admissionNoMoviment/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
                await axios.post(url, { numberDays: this.numberDays, date: `${d[2]}-${d[1]}-${d[0]}` }).then(res => {
                    this.admissionsNoMoviment = res.data     
                    
                    if (this.admissionsNoMoviment.length == 0) {
                        this.$toasted.global.defaultError({ msg: 'Nenhuma admissão carregada' }) 

                        this.generatingPDF = false
                        this.textBtnPrint = 'GERAR'                      
                    } else {  
                        this.showReport()
                    }                               
                }).catch(e => {
                    this.$toasted.global.defaultError({ msg: e })
                    this.generatingPDF = false
                    this.textBtnPrint = 'GERAR'                    
                })
            }
        },
    },
    mounted() {
        this.theme = localStorage.getItem('theme')
    }
}
</script>

<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}
</style>