
<template>
    <div class="ScaleFixedProfessionals">
        <b-modal id="modal-prevent-closing-1" ref="modal" title="Escala Fixa" @show="resetModal" @hidden="resetModal" @ok="handleOk" size="lg">
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-row>                 
                    <b-col md="6" sm="12">
                        <b-form-group label="Dias" label-for="tags-with-dropdown-day">
                            <b-form-tags size="sm" id="tags-with-dropdown-day" v-model="valueDay" no-outer-focus class="mb-2">
                                <template v-slot="{ tags, disabled, addTag, removeTag }">
                                <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                                    <li v-for="tag in tags" :key="tag" class="list-inline-item">
                                        <b-form-tag @remove="removeTag(tag)" :title="tag" :disabled="disabled" variant="info">{{ tag }}</b-form-tag>
                                    </li>
                                </ul>

                                <b-dropdown size="sm" variant="outline-secondary" block menu-class="w-100">
                                    <template #button-content>Dias</template>
                                    <b-dropdown-form @submit.stop.prevent="() => {}"></b-dropdown-form>
                                    <b-dropdown-divider></b-dropdown-divider>
                                    <b-dropdown-item-button v-for="option in availableOptionsDay" :key="option" @click="onOptionClickDay({ option, addTag })">
                                        {{ option }}
                                    </b-dropdown-item-button>
                                    <b-dropdown-text v-if="availableOptionsDay.length === 0">Dias</b-dropdown-text>
                                </b-dropdown>
                                </template>
                            </b-form-tags>
                        </b-form-group>
                    </b-col>    
                    <b-col md="6" sm="12">
                        <b-form-group label="Tipo de Atendimento" label-for="tags-with-dropdown-typeService">
                            <b-form-select size="sm" v-model="selectedTypeService" :options="typeServices"></b-form-select>
                        </b-form-group>
                    </b-col>                
                </b-row>
                <b-row>
                    <b-col md="6" sm="12">
                        <b-form-group label="Horário Inicial" label-for="tags-with-dropdown-hour-initial">
                            <b-form-timepicker size="sm" @input="setCheckin" placeholder="" hide-header required :state="stateCheckin"></b-form-timepicker>           
                        </b-form-group>             
                    </b-col>
                    <b-col md="6" sm="12">
                        <b-form-group label="Horário Final" label-for="tags-with-dropdown-hour-final">
                            <b-form-timepicker size="sm" @input="setCheckout" placeholder="" hide-header required :state="stateCheckout"></b-form-timepicker>    
                        </b-form-group>                    
                    </b-col>
                </b-row>       
                <hr>
                <b-row>
                    <b-button size="sm" variant="success" class="ml-3" v-b-modal.modal-prevent-closing v-b-popover.hover.top="'Adiconar Profissional'">
                        <i class="fa fa-plus"></i>
                    </b-button>     

                    <Lookup ref="Lookup" title="Profissionais" :list="list" :fields="pfields" :invalidFeedback="invalidFeedback" @event="addProfessional"
                            resultTable="professionalScale"></Lookup>
                </b-row>
                <b-row>
                    <b-col md="12" sm="12" class="mt-3">
                        <b-table head-variant="light" outlined hover :fields=fields responsive="sm" id="my-table" :items=listProfessionals ref="tableProfessionals"
                                :per-page="perPage" :current-page="currentPage" fixed :filter="ftext">

                            <template v-slot:cell(actions)="row">
                                <b-button size="sm" variant="danger" @click="removeProfessional(row.item)">
                                    <i class="fa fa-trash"></i>
                                </b-button>     
                            </template>                                                 
                        </b-table>

                        <b-pagination size="sm" pills v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table" class="page-item">
                        </b-pagination>                                
                    </b-col>
                </b-row>         
            </form>
        </b-modal>                                          
    </div>
</template>

<script>
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'
import Lookup from '../default/Lookup'

  export default {
    name: "ScaleFixedProfessionals",
    props: [
        'list',
        'fields'
    ],
    components: { Lookup },
    data() {
      return {
        ftext: '',
        invalidFeedback: 'Selecione o profissional',
        currentPage: 1,
        perPage: 10,            
        result: [],
        scaleFixed: {},
        listProfessionals: [],
        typeServices: [],
        selectedTypeService: null,

        optionsDay: ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo'],
        valueDay: [],

        pfields: [                 
            { key: 'name', label: 'Nome', sortable: true },
            { key: 'cpf', label: 'CPF', sortable: true, },
            { key: 'advice.number', label: 'Registro', sortable: true },         
        ],       

        checkin: null,
        checkout: null,
        stateCheckin: null,
        stateCheckout: null
      }
    },
    computed: {
      rows() {
        return this.listProfessionals.length
      },        
      availableOptionsDay() {
        const criteriaDay = this.criteriaDay
        // Filter out already selected options
        const optionsDay = this.optionsDay.filter(opt => this.valueDay.indexOf(opt) === -1)
        if (criteriaDay) {
          // Show only options that match criteria
          return optionsDay.filter(opt => opt.toLowerCase().indexOf(criteriaDay) > -1);
        }
        // Show all options available

        return optionsDay
      }
    },
    methods: {
        setCheckin(value) {
            this.checkin = value
        },
        setCheckout(value) {
            this.checkout = value
        },          
        onOptionClickDay({ option, addTag }) {
            addTag(option)
        },
        resetModal() {
            this.valueDay = []
            this.listProfessionals = []
        },           
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        }, 
        checkFormValidity() {
            if (this.checkin == null || this.checkout == null || this.valueDay.length == 0 || this.listProfessionals.length == 0 || this.selectedTypeService == null) {
                return false
            } else {
                return true         
            }
        },     
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return 
            }

            this.result = []

            let ts = {}

            this.typeServices.find((item, i) => {
                if (item.value == this.selectedTypeService) {
                    i.delete
                    ts = {
                        _id: this.selectedTypeService,
                        name: item.text
                    }
                }
            })

            for (let d of this.valueDay) {
                this.result.push({
                    checkin: this.checkin,
                    checkout: this.checkout,
                    day: d,
                    typeService: ts,
                    professionals: this.listProfessionals                    
                })                    
            }

            this.insertProfessionalScaleFixed()

            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-1')
            })
        }, 
        addProfessional(payload) {
            let finded = false

            this.listProfessionals.find((item, i) => {
                if (item.cpf == payload.professionalSelected.cpf) {
                    finded = true
                    i.delete
                    this.$toasted.global.defaultError({msg: 'Profissional já adicionado'})                                                       
                }
            })
            
            if (!finded) {
                this.listProfessionals.push(payload.professionalSelected)
            }
        },
        removeProfessional(itemSelected) {
            this.listProfessionals.find((item, i) => {
                if (item.cpf == itemSelected.cpf) {
                    this.listProfessionals.splice(i, 1)
                    i.delete
                }
            })            
        },
        async insertProfessionalScaleFixed() {    
            let daySave = ''

            for (let r of this.result) {
                switch (r.day) {
                    case 'Segunda': daySave = 'monday'                
                    break;
                    case 'Terça': daySave = 'tuesday'                
                    break;          
                    case 'Quarta': daySave = 'wednesday'                
                    break;          
                    case 'Quinta': daySave = 'thursday'                
                    break;
                    case 'Sexta': daySave = 'friday'                
                    break;
                    case 'Sábado': daySave = 'saturday'                
                    break;
                    case 'Domingo': daySave = 'sunday'                
                    break;

                    default:
                    daySave = 'monday'
                }                                 

                const url = `${baseApiUrl}/scaleFixed/day/${daySave}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
                await axios.get(url).then(res => {
                    this.scaleFixed = res.data                                           

                    if (res.data == null) {
                        this.scaleFixed = {
                            day: daySave,
                            professionals: []
                        }                               
                    }    

                    let finded = false
                                                  
                    for (let p of r.professionals) {
                        if (res.data == null) {
                            finded = false
                        } else {
                            for (let prd of res.data.professionals) {
                                if (p.cpf == prd.cpf && r.typeService._id == prd.typeService._id) {
                                    finded = true
                                }
                            }

                        }

                        if (!finded) {
                            this.scaleFixed.professionals.push({
                                name: p.name,
                                cpf: p.cpf,
                                adviceNumber: p.advice.number,
                                checkin: r.checkin,
                                checkout: r.checkout,
                                typeService: r.typeService
                            })
                        }

                        finded = false
                    }
                            
                    const method = this.scaleFixed._id ? 'put' : 'post'
                    const id = this.scaleFixed._id ? `/${this.scaleFixed._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}` : `/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`

                    axios[method](`${baseApiUrl}/scaleFixed${id}`, this.scaleFixed).then(() => {
                        this.$toasted.global.defaultSuccess()                                            
                    }).catch(showError)    
                }).catch(showError)     
            }
        },
        loadTypeServices() {
            const url = `${baseApiUrl}/typeService/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {  
                for (let r of res.data) {
                    this.typeServices.push(
                        {value: r._id, text: r.name},
                    )
                }   
            }).catch(showError)                      
        }          
    },
    mounted() {
        this.valueDay = []  
        this.loadTypeServices()
    }  
}
</script>

<style>

</style>