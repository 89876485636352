<template>
  <div class="satisfactionSurvey">
    <PageTitle icon="fa fa-file-text" main="Questionário" sub="ESCALA DE SONOLÊNCIA DE EPWORTH (ESSE)" />
  
    <div>
      <b-card :class="`card-${theme}`">
        <b-button v-show="question.status == 0" @click="sendQuestions()" variant="info">Enviar</b-button>

        <b-form-group class="mt-3">
                    <b-button-group>
                        <b-button @click="numberQuestion = 1" :variant="(selected1 != null) ? 'success' : 'secondary'">1</b-button>
                        <b-button @click="numberQuestion = 2" :variant="(selected2 != null) ? 'success' : 'secondary'">2</b-button>
                        <b-button @click="numberQuestion = 3" :variant="(selected3 != null) ? 'success' : 'secondary'">3</b-button>
                        <b-button @click="numberQuestion = 4" :variant="(selected4 != null) ? 'success' : 'secondary'">4</b-button>
                        <b-button @click="numberQuestion = 5" :variant="(selected5 != null) ? 'success' : 'secondary'">5</b-button>
                        <b-button @click="numberQuestion = 6" :variant="(selected6 != null) ? 'success' : 'secondary'">6</b-button>
                        <b-button @click="numberQuestion = 7" :variant="(selected7 != null) ? 'success' : 'secondary'">7</b-button>
                        <b-button @click="numberQuestion = 8" :variant="(selected8 != null) ? 'success' : 'secondary'">8</b-button>
                    </b-button-group>
        </b-form-group>

        <b-form-group v-show="numberQuestion == 1" class="mt-3" label="Classificar as situações associadas à sonolência:">
          <b-form-group v-show="numberQuestion == 1" class="ml-5" label="1) Sentar e ler:">
                <b-form-radio v-model="selected1" name="some-radios-1" value="0">Nenhuma chance de cochilar (0 pontos)</b-form-radio>
                <b-form-radio v-model="selected1" name="some-radios-1" value="1">Leve chance de cochilar (1 ponto)</b-form-radio>
                <b-form-radio v-model="selected1" name="some-radios-1" value="2">Chance moderada de cochilar (2 pontos)</b-form-radio>
                <b-form-radio v-model="selected1" name="some-radios-1" value="3">Alta chance de cochilar (3 pontos)</b-form-radio>
          </b-form-group>
        </b-form-group>

        <b-button v-show="numberQuestion == 1" variant="success" @click="numberQuestion = 2">Ok</b-button>
        
        <b-form-group v-show="numberQuestion == 2" class="mt-3" label="">
          <b-form-group v-show="numberQuestion == 2" class="ml-5" label="2) Assistir à TV:">
                <b-form-radio v-model="selected2" name="some-radios-2" value="0">Nenhuma chance de cochilar (0 pontos)</b-form-radio>
                <b-form-radio v-model="selected2" name="some-radios-2" value="1">Leve chance de cochilar (1 ponto)</b-form-radio>
                <b-form-radio v-model="selected2" name="some-radios-2" value="2">Chance moderada de cochilar (2 pontos)</b-form-radio>
                <b-form-radio v-model="selected2" name="some-radios-2" value="3">Alta chance de cochilar (3 pontos)</b-form-radio>
          </b-form-group>    
        </b-form-group>

        <b-button v-show="numberQuestion == 2" variant="success" @click="numberQuestion = 3">Ok</b-button>

        <b-form-group v-show="numberQuestion == 3" class="mt-3" label="">
          <b-form-group v-show="numberQuestion == 3" class="ml-5" label="3) Ficar sentado, sem fazer nada, em um local público:">
                <b-form-radio v-model="selected3" name="some-radios-3" value="0">Nenhuma chance de cochilar (0 pontos)</b-form-radio>
                <b-form-radio v-model="selected3" name="some-radios-3" value="1">Leve chance de cochilar (1 ponto)</b-form-radio>
                <b-form-radio v-model="selected3" name="some-radios-3" value="2">Chance moderada de cochilar (2 pontos)</b-form-radio>
                <b-form-radio v-model="selected3" name="some-radios-3" value="3">Alta chance de cochilar (3 pontos)</b-form-radio>
          </b-form-group> 
        </b-form-group>   
        
        <b-button v-show="numberQuestion == 3" variant="success" @click="numberQuestion = 4">Ok</b-button>

        <b-form-group v-show="numberQuestion == 4" class="mt-3" label="">
          <b-form-group v-show="numberQuestion == 4" class="ml-5" label="4) Ficar sentado, por uma hora, como passageiro em um carro:">
                <b-form-radio v-model="selected4" name="some-radios-4" value="0">Nenhuma chance de cochilar (0 pontos)</b-form-radio>
                <b-form-radio v-model="selected4" name="some-radios-4" value="1">Leve chance de cochilar (1 ponto)</b-form-radio>
                <b-form-radio v-model="selected4" name="some-radios-4" value="2">Chance moderada de cochilar (2 pontos)</b-form-radio>
                <b-form-radio v-model="selected4" name="some-radios-4" value="3">Alta chance de cochilar (3 pontos)</b-form-radio>
          </b-form-group>
        </b-form-group>

        <b-button v-show="numberQuestion == 4" variant="success" @click="numberQuestion = 5">Ok</b-button>
        
        <b-form-group v-show="numberQuestion == 5" class="mt-3" label="">
          <b-form-group v-show="numberQuestion == 5" class="ml-5" label="5) Deitar à tarde para descansar:">
                <b-form-radio v-model="selected5" name="some-radios-5" value="0">Nenhuma chance de cochilar (0 pontos)</b-form-radio>
                <b-form-radio v-model="selected5" name="some-radios-5" value="1">Leve chance de cochilar (1 ponto)</b-form-radio>
                <b-form-radio v-model="selected5" name="some-radios-5" value="2">Chance moderada de cochilar (2 pontos)</b-form-radio>
                <b-form-radio v-model="selected5" name="some-radios-5" value="3">Alta chance de cochilar (3 pontos)</b-form-radio>
          </b-form-group>
        </b-form-group>

        <b-button v-show="numberQuestion == 5" variant="success" @click="numberQuestion = 6">Ok</b-button>

        <b-form-group v-show="numberQuestion == 6" class="mt-3" label="">
          <b-form-group v-show="numberQuestion == 6" class="ml-5" label="6) Sentar e conversar com outra pessoa:">
                <b-form-radio v-model="selected6" name="some-radios-6" value="0">Nenhuma chance de cochilar (0 pontos)</b-form-radio>
                <b-form-radio v-model="selected6" name="some-radios-6" value="1">Leve chance de cochilar (1 ponto)</b-form-radio>
                <b-form-radio v-model="selected6" name="some-radios-6" value="2">Chance moderada de cochilar (2 pontos)</b-form-radio>
                <b-form-radio v-model="selected6" name="some-radios-6" value="3">Alta chance de cochilar (3 pontos)</b-form-radio>
          </b-form-group>
        </b-form-group>
            
        <b-button v-show="numberQuestion == 6" variant="success" @click="numberQuestion = 7">Ok</b-button>    
                         
        <b-form-group v-show="numberQuestion == 7" class="mt-3" label="">
          <b-form-group v-show="numberQuestion == 7" class="ml-5" label="7) Sentar, em silêncio, depois do almoço (sem ingestão de álcool):">
                <b-form-radio v-model="selected7" name="some-radios-7" value="0">Nenhuma chance de cochilar (0 pontos)</b-form-radio>
                <b-form-radio v-model="selected7" name="some-radios-7" value="1">Leve chance de cochilar (1 ponto)</b-form-radio>
                <b-form-radio v-model="selected7" name="some-radios-7" value="2">Chance moderada de cochilar (2 pontos)</b-form-radio>
                <b-form-radio v-model="selected7" name="some-radios-7" value="3">Alta chance de cochilar (3 pontos)</b-form-radio>
          </b-form-group>  
        </b-form-group>

        <b-button v-show="numberQuestion == 7" variant="success" @click="numberQuestion = 8">Ok</b-button> 

        <b-form-group v-show="numberQuestion == 8" class="mt-3" label="">
          <b-form-group v-show="numberQuestion == 8" class="ml-5" label="8) Sentado em um carro, parado por alguns minutos por causa de trânsito:">
                <b-form-radio v-model="selected8" name="some-radios-8" value="0">Nenhuma chance de cochilar (0 pontos)</b-form-radio>
                <b-form-radio v-model="selected8" name="some-radios-8" value="1">Leve chance de cochilar (1 ponto)</b-form-radio>
                <b-form-radio v-model="selected8" name="some-radios-8" value="2">Chance moderada de cochilar (2 pontos)</b-form-radio>
                <b-form-radio v-model="selected8" name="some-radios-8" value="3">Alta chance de cochilar (3 pontos)</b-form-radio>
          </b-form-group>
        </b-form-group>

      </b-card>
    </div> 
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'
import Moment from 'moment'

export default {
    name: "SatisfactionSurvey",
    components: { PageTitle },
    data() {
      return {
        patient: null,
        question: null,
        theme: null,
        numberQuestion: 1,
        selected1: null,
        selected2: null,
        selected3: null,
        selected4: null,
        selected5: null,
        selected6: null,
        selected7: null,
        selected8: null
      }
    },    
    methods: {
      sendQuestions() {
          Moment.locale('pt-br')  
          
          axios.get(`${baseApiUrl}/patient/getById/${this.patient._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`).then(res => {
            this.patient = res.data

            let data = {}
            let index = 0

            for (let q of this.patient.questions) {
              if (q.type == 2 && q.name == this.question.name && q.answers.length == 0) {
                q.answers = []

                q.status = 1
                q.answers.push(
                  {
                    'dateAnswers': Moment(new Date()).format('L')
                  }, 
                  {
                    '1': this.selected1,
                    '2': this.selected2,
                    '3': this.selected3,
                    '4': this.selected4,
                    '5': this.selected5,
                    '6': this.selected6,
                    '7': this.selected7,
                    '8': this.selected8
                  }                                                                                                                                                                                                                                                                                                                                                                                                                                                            
                ) 

                data.index = index
                data.answers = q.answers
              }

              index += 1
            }        
            
            data.idPatient = this.patient._id

            axios.post(`${baseApiUrl}/patient/setTestQuestion/${this.patient._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, data)
              .then(() => {
                  this.$store.commit('setResultPatient', this.patient)

                  this.$toasted.global.defaultSuccess()
                  this.$router.go(-1)
              })  
              .catch(showError) 
          }).catch((e) => {
            console.log(e)
            showError
          })
        // }
      }
    },
    created() {
      this.theme = localStorage.getItem('theme')

      this.patient = this.$route.params.questionSelected.patient
      this.question = this.$route.params.questionSelected

      if (this.$route.params.questionSelected.answers.length > 0) {
        this.selected1 = this.$route.params.questionSelected.answers[1]['1']
        this.selected2 = this.$route.params.questionSelected.answers[1]['2']
        this.selected3 = this.$route.params.questionSelected.answers[1]['3']
        this.selected4 = this.$route.params.questionSelected.answers[1]['4']
        this.selected5 = this.$route.params.questionSelected.answers[1]['5']
        this.selected6 = this.$route.params.questionSelected.answers[1]['6']
        this.selected7 = this.$route.params.questionSelected.answers[1]['7']
        this.selected8 = this.$route.params.questionSelected.answers[1]['8']       
      }
    }
}
</script>

<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}

.card-violet {
  background-color: #f3eaff;
}
</style>