<template>
    <Loading v-if="isLoading" />
    <div v-else class="covenant-admin">
        <PageTitle icon="fa fa-window-restore" main="Cadastro de Convênios" sub />                  
        <b-form>            
            <input id="covenant-id" type="hidden" v-model="covenant.id"/>
            <b-row>
                <b-col md="12" sm="12">
                    <b-card :class="`card-${theme}`" bg-variant="default" header="Informações Gerais" header-bg-variant="dark" header-text-variant="white">
                        <b-form-group label="Nome Fantasia:" label-for="covenant-fantasyname">
                            <b-form-input size="sm" id="covenant-fantasyname" type="text" 
                                v-model="covenant.fantasyname" required                       
                                placeholder="Informe o Nome Fantasia do Covênio..." />
                        </b-form-group>         
                        <b-form-group label="Razão Social:" label-for="covenant-companyname">
                            <b-form-input size="sm"  id="covenant-companyname" type="text" 
                                v-model="covenant.companyname" required                       
                                placeholder="Informe a Razão Social do Covênio..." />
                        </b-form-group>                               
                        <b-row>
                            <b-col md="6" sm="6">
                                <b-form-group label="CNPJ:" label-for="covenant-cnpj">
                                    <b-form-input size="sm"  id="covenant-cnpj" type="number" 
                                        v-model="covenant.cnpj" required
                                        placeholder="Informe o CNPJ do Convênio..." />
                                </b-form-group> 
                                <b-form-group label="Inscrição:" label-for="covenant-subscription">
                                    <b-form-input size="sm"  id="covenant-subscription" type="text"
                                        v-model="covenant.subscription" required
                                        placeholder="Informe a Inscrição do Convênio..." />
                                </b-form-group>         
                                <b-form-group label="E-mail:" label-for="covenant-email">
                                    <b-form-input size="sm"  id="covenant-email" type="text"
                                        v-model="covenant.email" 
                                        placeholder="Informe o E-mail do Convênio..." />
                                </b-form-group>        
                                <b-form-group label="Fone 1:" label-for="covenant-phone1">
                                    <b-form-input size="sm"  id="covenant-phone1" type="number"
                                        v-model="covenant.phone1" 
                                        placeholder="Informe o Fone do Convênio..." />
                                </b-form-group>                              
                            </b-col>
                            <b-col md="6" sm="6">  
                                <b-form-group label="Registro ANS:" label-for="covenant-registerANS">
                                    <b-form-input size="sm"  id="covenant-registerANS" type="text"
                                        v-model="covenant.registerANS" 
                                        placeholder="Confirme o Registro ANS do Convênio..." />
                                </b-form-group>
                                <b-form-group label="Nome Contato:" label-for="covenant-nameContact">
                                    <b-form-input size="sm"  id="covenant-nameContact" type="text"
                                        v-model="covenant.nameContact" 
                                        placeholder="Confirme o Nome Contato do Convênio..." />
                                </b-form-group>                              
                                 <b-form-group label="Site:" label-for="covenant-site">
                                    <b-form-input size="sm"  id="covenant-site" type="text"
                                        v-model="covenant.site" 
                                        placeholder="Informe o Site do Convênio..." />
                                </b-form-group>
                                <b-form-group label="Fone 2:" label-for="covenant-phone2">
                                    <b-form-input size="sm"  id="covenant-phone2" type="number"
                                        v-model="covenant.phone2" 
                                        placeholder="Informe o Fone do Convênio..." />
                                </b-form-group>
                            </b-col>
                            <b-col md="6" sm="12">
                                <b-form-group label="Observações:" label-for="covenant-comments">
                                    <b-form-textarea size="sm" id="textarea" v-model="covenant.comments" placeholder="Observações..." rows="1" max-rows="6"></b-form-textarea>       
                                </b-form-group>                                                                                 
                            </b-col>
                            <b-col md="2" sm="12">
                                <b-form-group label="Valor Diária:" label-for="covenant-valueDaily">
                                    <b-form-input size="sm" v-model="covenant.valueDaily" placeholder="Valor Diária..."></b-form-input>       
                                </b-form-group>                                                                                 
                            </b-col>
                            <b-col md="2" sm="12">
                                <b-form-group label="Logomarca:" label-for="covenant-logo">
                                <b-form-file v-model="file" @input="selectFile()" accept=".jpg, .png, .jpeg, .pdf" id="covenant-logo" size="sm"
                                    :placeholder="`${covenant.logo == null ? 'Selecione a Logomarca' : covenant.logo.name}`" browse-text="Adicionar"></b-form-file>                  
                                </b-form-group>
                            </b-col>
                            <b-col md="1" sm="12">
                                <b-button size="sm" v-show="file != null" class="mt-4" variant="danger" @click="deleteFile()" v-b-popover.hover.top="'Remover Logo'">
                                    <i class="fa fa-trash"></i>
                                </b-button>
                                <b-button size="sm" v-show="file != null" class="mt-4 ml-2" variant="info" @click="openFile()" v-b-popover.hover.top="'Visualizar Logo'">
                                    <i class="fa fa-eye"></i>
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="6" sm="12">
                    <b-card :class="`card-${theme} mt-2`" bg-variant="default" header="Endereço" header-bg-variant="dark" header-text-variant="white">
                        <b-row>    
                            <b-col md="5" sm="12"> 
                                <b-form-input size="sm"  type="number" id="nested-cep" placeholder="CEP..." v-model="address.cep"></b-form-input>
                            </b-col>
                            <b-col md="1" sm="12"> 
                                <b-button   size="sm" @click="searchCEP"><i class="fa fa-search-plus"></i></b-button>
                            </b-col>                            
                        </b-row>
                        <b-row>
                            <b-col md="10" sm="12"> 
                                <b-form-group label="Logradouro:" label-for="nested-street" class="mt-3">
                                    <b-form-input size="sm"  id="nested-street" placeholder="Logradouro..." v-model="address.street"></b-form-input>
                                </b-form-group>
                            </b-col>                         
                            <b-col md="2" sm="12"> 
                                <b-form-group label="Número:" label-for="nested-number" class="mt-3">
                                    <b-form-input size="sm"  id="nested-number" placeholder="Número..." v-model="address.number"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="6" sm="12"> 
                                <b-form-group label="Bairro:" label-for="nested-neighborhood">
                                    <b-form-input size="sm"  id="nested-neighborhood" placeholder="Bairro..." v-model="address.neighborhood"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="6" sm="12"> 
                                <b-form-group label="Município:" label-for="nested-city">
                                    <b-form-input size="sm"  id="nested-city" placeholder="Município..." v-model="address.city"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="8" sm="12"> 
                                <b-form-group label="Complemento:" label-for="nested-complement">
                                    <b-form-input size="sm"  id="nested-complement" placeholder="Complemento..." v-model="address.complement"></b-form-input>
                                </b-form-group>
                            </b-col>           
                            <b-col md="4" sm="12">                    
                                <b-form-group label="UF:" label-for="covenant-state">
                                    <b-form-select size="sm" v-model="address.state" :options="states"></b-form-select>
                                </b-form-group>               
                            </b-col>                                              
                        </b-row>       
                    </b-card>                            
                </b-col>  
                <b-col md="6" sm="12">
                    <b-card :class="`card-${theme} mt-2`" bg-variant="default" header="Dados XML ANS" header-bg-variant="dark" header-text-variant="white">
                        <b-row>
                            <b-col md="4" sm="12">
                                <b-form-group label="Código Prestador Operadora:" label-for="covenant-codeOperatorProvider">
                                    <b-form-input size="sm"  id="covenant-codeOperatorProvider" type="number" v-model="covenant.codeOperatorProvider" placeholder="Código Prestador Operadora..." />
                                </b-form-group>
                            </b-col>
                            <b-col md="3" sm="12">
                                <b-form-group label="Versão:" label-for="covenant-version">
                                    <b-form-input size="sm"  id="covenant-version" type="text" v-model="covenant.versionXml" placeholder="Versão..." />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="4" sm="12">
                                <b-form-group label="Quantidade Atendimentos Por Guia:" label-for="covenant-attendancesPerGuide">
                                    <b-form-input size="sm"  id="covenant-attendancesPerGuide" type="number" v-model="covenant.attendancesPerGuide" placeholder="Qtd Atendimentos..." />
                                </b-form-group>
                            </b-col>
                            <b-col md="3" sm="12">
                                <b-form-group label="Registro ANS:" label-for="covenant-registerANS">
                                    <b-form-input size="sm"  id="covenant-registerANS" type="number" v-model="covenant.registerANS" placeholder="Registro ANS..." />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12" sm="12">
                    <b-card :class="`card-${theme}`" bg-variant="default" header="Tabela de Preços de Tipos de Atendimento" header-bg-variant="dark" header-text-variant="white" class="mt-2">
                        <b-row>    
                            <b-button   size="sm" v-b-modal.modal-prevent-closing variant="success" class="mb-3 ml-3">
                                <i class="fa fa-plus"></i></b-button>
                            <Lookup ref="Lookup" title="Tipo de Atendimento" :list="allTypeServices" :fields="tsFields" 
                                    :invalidFeedback="invalidFeedback" resultTable="priceTypeService" @event="addTypeService"></Lookup>
                            <b-col md="12" sm="12">         
                                <b-table ref="selectableTypeService" head-variant="light" outlined hover :items="typeServices" :fields="fields" 
                                         responsive="sm" fixed>
                                    <template v-slot:cell(actions)="row">
                                        <b-button size="sm" variant="danger" @click="removeTypeService(row.item)">
                                            <i class="fa fa-trash"></i>
                                        </b-button>
                                    </template>
                                </b-table>                            
                            </b-col>
                        </b-row>
                    </b-card>   
                </b-col>
            </b-row>
        </b-form>

        <b-button size="sm" variant="success" @click="save" class="mt-3">SALVAR</b-button>  
        <b-button size="sm" variant="danger" @click="cancel" class="mt-3 ml-2">CANCELAR</b-button>  
    </div>
</template>

<script>
import PageTitle from "../../template/PageTitle";
import { baseApiUrl, userKey } from '@/global'
import axios from 'axios'
import States from '../../default/states'
import Lookup from '../../default/Lookup'
import Loading from "../../template/Loading"
import util from '../../default/util'

const convertToBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})

export default {
    name: "CovenantPage",
    components: { PageTitle, Lookup, Loading },
    data: function() {
      return { 
        theme: null,
        isLoading: false,
        covenant: {},
        covenants: [],
        states: States.states,           
        address: {},
        fields: [                 
            { key: 'name', label: 'Tipo Atendimento', sortable: true },            
            { key: 'codeTA', label: 'Código', sortable: false },
            { key: 'tableTypeTA', label: 'Tipo Tabela', sortable: false },
            { key: 'descriptionTA', label: 'Descrição', sortable: true },
            { key: 'valueTA2', label: 'Valor', sortable: true },
            { key: 'actions', label: 'Ações' }
        ],        
        tsFields: [                 
            { key: 'name', label: 'Tipo de Atendimento', sortable: true },
            { key: 'codeTA', label: 'Código', sortable: false },
            { key: 'tableTypeTA', label: 'Tipo Tabela', sortable: false },
            { key: 'descriptionTA', label: 'Descrição', sortable: true },
            { key: 'valueTA', label: 'Valor (R$)', sortable: true },
        ],          
        typeServices: [],
        allTypeServices: null,
        invalidFeedback: ''
      }      
    },
    methods: {        
        cancel() {
            this.$router.go(-1)
        },           
        save() {
            this.covenant.fantasyname = this.covenant.fantasyname.toUpperCase()
            this.covenant.companyname = this.covenant.companyname.toUpperCase()

            const method = this.covenant._id ? 'put' : 'post'
            const id = this.covenant._id ? `/${this.covenant._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}` : `/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`

            this.covenant.address = {
                cep: this.address.cep,
                street: this.address.street,
                number: this.address.number,
                neighborhood: this.address.neighborhood,
                city:  this.address.city,
                complement: this.address.complement,
                state: this.address.state,                
            }            

            this.covenant.priceTypeService = this.typeServices

            this.isLoading = true

            axios[method](`${baseApiUrl}/covenants${id}`, this.covenant).then(() => {
                    this.isLoading = false
                    this.$toasted.global.defaultSuccess()
                    this.$router.go(-1)
                }).catch(e => {
                    this.isLoading = false
                    this.$toasted.global.defaultError({ msg: e.response.data })
                })
        },     
        searchCEP() {     
            this.$viaCep.buscarCep(this.address.cep).then((obj) => {
                this.address = {
                    cep: obj.cep,
                    street: obj.logradouro,
                    neighborhood: obj.bairro,
                    city: obj.localidade,                    
                }                
            });
        },           
        loadTypeServices() {
            this.isLoading = true

            const url = `${baseApiUrl}/typeService/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {  
                this.isLoading = false
                this.allTypeServices = res.data
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })
            })                      
        },
        addTypeService(payload) {
            let finded = false

            if (this.typeServices) {
                if (this.typeServices.length > 0 && this.typeServices[0] != undefined) {
                    this.typeServices.find((item, i) => {
                        if (item.name == payload.typeServiceSelected.name) {
                            finded = true
                            i.delete
                            this.$toasted.global.defaultError({msg: 'Tipo de Atendimento já adicionado'})                                                       
                        }
                    })
                }
            }
            
            if (!finded) {
                let ts = {
                    name: payload.typeServiceSelected.name,
                    codeTA: payload.typeServiceSelected.codeTA,
                    tableTypeTA: payload.typeServiceSelected.tableTypeTA,
                    descriptionTA: payload.typeServiceSelected.descriptionTA,
                    valueTA: parseFloat(payload.typeServiceSelected.valueTA).toFixed(2),
                    valueTA2: parseFloat(payload.typeServiceSelected.valueTA).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                }

                this.typeServices.push(ts)
            }
        },
        removeTypeService(itemSelected) {
            let i = 0

            this.typeServices.forEach(ts => {
                if (ts.name === itemSelected.name) {
                    this.typeServices.splice(i, 1)
                }
                i += 1
            })             
        },
        async selectFile() {
          if (this.file) {
            const convertedFile = await convertToBase64(this.file)
          
            if (convertedFile) {
                this.covenant.logo = {
                    name: this.file.name,
                    base64: convertedFile
                }        
            }
          }
        },
        openFile() {
            try {
                let extension = this.covenant.logo.name.split('.')
                
                util.imprimirBase64(this.covenant.logo.base64, extension[1])
            } catch (e) {
                this.$toasted.global.defaultError({ msg: 'Erro ao abrir o arquivo' })
            }
        },
        deleteFile() {
            this.file = null
            this.covenant.logo = null
        }                                
    },
    mounted() {
        this.theme = localStorage.getItem('theme')

        if (this.$route.params.covenantSelected) {
            this.covenant = this.$route.params.covenantSelected

            if (this.address.cep) {
                this.address = {
                    cep: this.covenant.address.cep,
                    street: this.covenant.address.street,
                    number: this.covenant.address.number,
                    neighborhood: this.covenant.address.neighborhood,
                    city:  this.covenant.address.city,
                    complement: this.covenant.address.complement,
                    state: this.covenant.address.state
                }
            }

            if (this.covenant.logo) {
                this.file = this.covenant.logo.name
            }            

            for (let ts of this.covenant.priceTypeService) {
                ts.valueTA2 = parseFloat(ts.valueTA).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                this.typeServices.push(ts) 
            }
        }

        this.loadTypeServices()
    }     
}     
</script>
<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}

.card-violet {
  background-color: #f3eaff;
}

.card-header {
    height: 38px;
    font-size: 14px;    
}

.card-body {
    font-size: 14px;
}

.calendar-btn {
    font-size: 0.30;
}

.table th {
  padding: 0.45rem;
}

.table td {
  padding: 0.45rem;
  vertical-align: middle;
}
</style>