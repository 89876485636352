export default {
  obterCampo(field) {
    let campo = field

    switch (field) {
      case 'fantasyname': campo = 'Nome Fantasia'
        break;
      case '_id': campo = 'Id'
        break;
      case 'name': campo = 'Nome'
        break;
      case 'email': campo = 'E-mail'
        break;
      case 'admin': campo = 'Administrador'
        break;
      case 'cpf': campo = 'CPF'
        break;
      case 'passwordMobile': campo = 'Senha Mobile'
        break;
      case 'password': campo = 'Senha'
        break;
      case 'companyname': campo = 'Nome da Empresa'
        break;
      case 'cnpj': campo = 'CNPJ'
        break;
      case 'subscription': campo = 'Inscrição'
        break;
      case 'accessProfile': campo = 'Acessar Perfil'
        break;
      case 'site': campo = 'Local'
        break;
      case 'registerANS': campo = 'Registro ANS'
        break;
      case 'nameContact': campo = 'Nome do Contato'
        break;
      case 'level': campo = 'Nível'
        break;
      case 'type': campo = 'Tipo'
        break;
      case 'contained': campo = 'Contido Em'
        break;
      case 'building': campo = 'Prédio'
        break;
      case 'floor': campo = 'Piso'
        break;
      case 'apartment': campo = 'Apartamento'
        break;
      case 'bed': campo = 'Cama'
        break;
      case 'lavel': campo = 'Lavabo'
        break;
      case 'rg': campo = 'RG'
        break;
      case 'categoryAttendance': campo = 'Categoria de Atendimento'
        break;
      case 'birthday': campo = 'Data de Nascimento'
        break;
      case 'comments': campo = 'Comentários'
        break;
      case 'covenant': campo = 'Pacto'
        break;
      case 'gender': campo = 'Gênero'
        break;
      case 'hiringDate': campo = 'Data da Contratação'
        break;
      case 'lastVacationDate': campo = 'Data da Última Férias'
        break;
      case 'phone1': campo = 'Telefone 1'
        break;
      case 'phone2': campo = 'Telefone 2'
        break;
      case 'pis': campo = 'PIS'
        break;
      case 'requester': campo = 'Solicitante'
        break;
      case 'initials': campo = 'Sigla'
        break;
      case 'codeANS': campo = 'Código ANS'
        break;
      case 'numberANS': campo = 'Número ANS'
        break;
      case 'state': campo = 'Estado'
        break;
      case 'codeOperatorProvider': campo = 'Código Prestador Operadora'
        break;
      case 'versionXml': campo = 'Versão XML'
        break;
      case 'deletedAt': campo = 'Deletado Em'
        break;
      case 'socialName': campo = 'Nome Social'
        break;
      case 'attendancesPerGuide': campo = 'Atendimentos Por Guia'
        break;
      case 'patient': campo = 'Paciente'
        break;
      case 'professionalRequester': campo = 'Profissional Solicitante'
        break;
      case 'attendanceNumber': campo = 'Nº Atendimento'
        break;
      case 'numberGuide': campo = 'Nª Guia '
        break;
      case 'accommodation': campo = 'Local de Internação'
        break;
    }

    return `${campo}`
  },
  imprimirBase64(strBase64, extension) {
    const base64ImageData = strBase64

    let contentType = ''

    switch (extension) {
      case 'pdf': contentType = 'application/pdf'
        break
      case 'jpg': contentType = 'image/jpeg'
        break
      case 'jpeg': contentType = 'image/jpeg'
        break
      case 'png': contentType = 'image/png'
        break

      default: contentType = 'application/pdf'
    }

    const byteCharacters = atob(base64ImageData.substr(`data:${contentType};base64,`.length))

    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024)
      const byteNumbers = new Array(slice.length)

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }

    const blob = new Blob(byteArrays, { type: contentType })
    const blobUrl = URL.createObjectURL(blob)
    window.open(blobUrl, '_blank')
  },
  hourIsValide(hour) {
    const regex = /^([01]\d|2[0-3]):([0-5]\d)$/

    if (regex.test(hour)) {
      return true
    }

    return false
  }
}