<template>
    <Loading v-if="isLoading" />
    <div v-else class="accommodation-admin">
        <PageTitle icon="fa fa-hospital-o" main="Cadastro de Local de Internação" sub />                  
        <b-form>            
            <input id="accommodation-id" type="hidden" v-model="accommodation.id"/>
            <b-row>
                <b-col md="6" sm="12">
                    <b-card :class="`card-${theme}`" bg-variant="default" header="Informações Gerais" header-bg-variant="dark" header-text-variant="white">
                        <b-row>
                            <b-col md="5" sm="12" class="ml-3">    
                                <b-form-group label="Nome:" label-for="accommodation-name">
                                    <b-form-input size="sm" id="accommodation-name" type="text"
                                        v-model="accommodation.name" required                       
                                        placeholder="Informe o Nome do Local de Internação..." />
                                </b-form-group>         
                            </b-col>
                            <b-col md="2" sm="12">
                                <b-form-group label="Nível:" label-for="accommodation-level">
                                    <b-form-input size="sm" id="accommodation-level" type="number"
                                        v-model="accommodation.level"                       
                                        placeholder="Informe o Número.." />
                                </b-form-group>         
                            </b-col>
                            <b-col md="3" sm="12">
                                <b-form-checkbox class="mt-4" v-model="accommodation.onlyOffice" :value="true" :unchecked-value="false">Apenas Consultório</b-form-checkbox>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="5" sm="12" class="ml-3 mt-3">        
                                <b-form-group label-for="accommodation-type">
                                    <b-form-group label="Tipo:" label-for="accommodation-type">
                                        <b-form-select size="sm" @change="getAccommodations" v-model="selectedType" :options="types"></b-form-select>
                                    </b-form-group>   
                                </b-form-group>                 
                            </b-col>
                            <b-col md="5" sm="12" class="mt-3">        
                                <b-form-group label-for="accommodation-contained">
                                    <b-form-group label="Contido Em:" label-for="accommodation-contained">
                                        <b-form-input size="sm" v-model="resultLookupContainedAccommodation" disabled></b-form-input>
                                    </b-form-group>   
                                </b-form-group>                 
                            </b-col>
                            <b-col md="1" sm="12" class="mt-3" align-self="center">
                                <b-button size="sm" v-if="selectedType && selectedType != 'Prédio'" v-b-modal.modal-prevent-closing><i class="fa fa-search"></i></b-button>
                                <Lookup ref="Lookup" title="Locais de Internação" :list="resultAccommodations" :fields="fields" :invalidFeedback="invalidFeedback"
                                        resultTable="accommodation" :type="selectedType"></Lookup>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>      
            </b-row>
        </b-form>

        <b-button size="sm" variant="success" @click="save" class="mt-3">SALVAR</b-button>  
        <b-button size="sm" variant="danger" @click="cancel" class="mt-3 ml-2">CANCELAR</b-button>  
    </div>
</template>

<script>
import PageTitle from "../../template/PageTitle"
import { baseApiUrl, userKey } from '@/global'
import axios from 'axios'
import Lookup from '../../default/Lookup'
import { mapState } from "vuex"
import Loading from "../../template/Loading"

export default {
    name: "AccommodationPage",
    components: { PageTitle, Lookup, Loading },
    data: function() {
      return { 
        theme: null,
        isLoading: false,
        accommodation: {},
        types: [
            {value: 'Prédio', text: 'PRÉDIO'},
            {value: 'Andar', text: 'ANDAR'},
            {value: 'Apartamento', text: 'APARTAMENTO'},          
            {value: 'Leito', text: 'LEITO'}          
        ],
        fields: [],                         
        invalidFeedback: 'Selecione a Local de Internação Pai',       
        selectedType: null,
        selectedContained: null,
        accommodations: [],
        resultAccommodations: []
      }    
    },     
    computed: mapState(["resultLookupAccommodation", "resultLookupContainedAccommodation"]),
    methods: {           
        save() {
            const method = this.accommodation._id ? 'put' : 'post'
            const id = this.accommodation._id ? `/${this.accommodation._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}` : `/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            
            this.accommodation.type = this.selectedType
            this.accommodation.contained = this.resultLookupContainedAccommodation

            let valid = true

            if (this.selectedType === 'Prédio') {
                this.accommodation.building = null
                this.accommodation.floor = null
                this.accommodation.apartment = null
                this.accommodation.lavel = 0
            } else { 
                if (!this.resultLookupAccommodation) {
                   valid = false                    
                   this.$toasted.global.defaultError({msg: 'Local de Atendimento Pai, não informado'}) 
                } else {
                    if (this.selectedType === 'Andar') {
                        this.accommodation.building = this.resultLookupAccommodation.name
                        this.accommodation.floor = null
                        this.accommodation.apartment = null
                    } else if (this.selectedType === 'Apartamento') {
                        this.accommodation.building = this.resultLookupAccommodation.contained
                        this.accommodation.floor = this.resultLookupAccommodation.name
                        this.accommodation.apartment = null
                    } else {
                        this.accommodation.building = this.resultLookupAccommodation.building
                        this.accommodation.floor = this.resultLookupAccommodation.floor
                        this.accommodation.apartment = this.resultLookupAccommodation.name
                    }
                }
            }

            if (valid) {
                this.accommodation.name = this.accommodation.name.toUpperCase()

                this.isLoading = true
                        
                axios[method](`${baseApiUrl}/accommodation${id}`, this.accommodation).then(() => {
                    this.isLoading = false
                    this.$toasted.global.defaultSuccess()                    
                    this.$router.go(-1)
                }).catch(e => {
                    this.isLoading = false
                    this.$toasted.global.defaultError({ msg: e.response.data })
                })
            }
        },  
        cancel() {        
            this.$router.go(-1)
        },
        async loadAccommodations() {
            this.isLoading = true

            const url = `${baseApiUrl}/accommodation/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            await axios.get(url).then(res => {  
                this.isLoading = false

                this.accommodations = res.data

                if (this.selectedType) {
                    this.getAccommodations()
                }
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })
            })  
        },          
        getAccommodations() {
            if (this.selectedType !== 'Prédio') {
                this.resultAccommodations = []    

                let ac = 
                    this.accommodations.filter(a => {                
                    if (this.selectedType === 'Andar') {          
                        return a.type === 'Prédio' 
                    } else if (this.selectedType === 'Apartamento') {
                        return a.type === 'Andar'
                    } else {
                        return a.type === 'Apartamento'
                    }
                })  

                if (this.selectedType === 'Andar') {
                    this.fields = [{ key: 'name', label: 'Prédio', sortable: true }]                        
                } else if (this.selectedType === 'Apartamento') {
                    this.fields = [
                        { key: 'name', label: 'Andar', sortable: true },
                        { key: 'building', label: 'Prédio', sortable: true }
                    ]                        
                } else {
                    this.fields = [
                        { key: 'name', label: 'Apartamento', sortable: true },
                        { key: 'floor', label: 'Andar', sortable: true },
                        { key: 'building', label: 'Prédio', sortable: true }
                    ]       
                }
                
                for (let a of ac) {
                    this.resultAccommodations.push({
                        _id: a._id,
                        name: a.name,
                        contained: a.contained,
                        building: a.building,
                        floor: a.floor,
                        apartment: a.apartment
                    })
                }
            }                                             
        }                      
    },
    mounted() {        
        this.theme = localStorage.getItem('theme')

        if (this.$route.params.accommodationSelected) {
            this.accommodation = this.$route.params.accommodationSelected    
            
            this.$store.commit('setResultLookupAccommodation', this.accommodation)                                                
        }        

        if (this.accommodation.type) {
            this.selectedType = this.accommodation.type            
        }

        if (this.accommodation.contained) {
            this.$store.commit('setResultLookupContainedAccommodation', this.accommodation.contained)
        } else {
            this.$store.commit('setResultLookupContainedAccommodation', '')                        
        }

        this.loadAccommodations() 
    },
}     
</script>

<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}

.card-violet {
  background-color: #f3eaff;
}

.card-header {
    height: 38px;
    font-size: 14px;    
}

.card-body {
    font-size: 14px;
}

.calendar-btn {
    font-size: 0.30;
}

.table th {
  padding: 0.45rem;
}

.table td {
  padding: 0.45rem;
  vertical-align: middle;
}
</style>