<template>
    <div class="repAppointments">
    <PageTitle icon="fa fa-file-text" main="Apontamentos" sub="Relatório" />
        <div class="repAppointments-page-tabs">
            <b-row>
                <b-col md="8" sm="12">
                    <b-card :class="`card-${theme}`" header="Informações" header-bg-variant="dark" header-text-variant="white">
                        <b-row>
                            <b-col md="4" sm="12">
                                <b-form-group label="Profissional:" label-for="professional-name">
                                    <b-form-input size="sm" id="professional-name" type="text" v-model="professional.name" disabled/>
                                </b-form-group>
                            </b-col>   
                            <b-col md="1" sm="6" class="align-self-center mt-3">
                                <b-button size="sm" v-b-modal.modal-prevent-closing v-b-popover.hover.top="'Adiconar Profissional'">
                                    <i class="fa fa-search"></i>
                                </b-button>

                                <Lookup ref="Lookup" title="Profissionais" :list="professionals" :fields="fields"
                                    @event="addProfessional" resultTable="relAttendancesPerCovenant"></Lookup>
                            </b-col>                                                     
                            <b-col md="3" sm="12">
                                <b-form-group label="Data:" label-for="repAppointments-day">
                                    <b-form-input size="sm" id="repAppointments-day" type="date" v-model="day" required />
                                </b-form-group>
                            </b-col>
                            <b-col md="3" sm="12">
                                <b-button size="sm" variant="success" class="mt-4" @click="getRepAppointments()">Processar</b-button>
                            </b-col>
                        </b-row>
                    </b-card>                    
                </b-col>                  
            </b-row>   
        </div>
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'
import Lookup from "../default/Lookup"

export default {
    name: "RepAppointments",
    components: { PageTitle, Lookup },    
    data: function() {
        return {
            theme: null,
            professional: {},
            professionals: [],
            day: null,
            repAppointments: null,
            generatingPDF: false,
            textBtnPrint: 'GERAR',
            fields: [
                { key: "name", label: "Nome", sortable: true }
            ]              
        }
    },
    methods: {
        async getRepAppointments() {
            try {
                let valid = true

                if (this.day == null) {
                    this.$toasted.global.defaultError({msg: 'Informe a data.'})
                    valid = false
                } 

                if (valid) {
                    const url = `${baseApiUrl}/scale/date/${this.day}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
                    axios.get(url).then(res => {
                        this.scale = res.data 

                        if (this.scale != null) {
                            let dataPDF = {
                                title: 'RELATÓRIO DE APONTAMENTOS',
                                data: this.scale,
                                professional: this.professional
                            }    

                            this.isLoading = true
                            const url2 = `${baseApiUrl}/repAppointments/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
                            axios.post(url2, dataPDF, {responseType: 'arraybuffer'}).then(res => {  
                                var file = new Blob([res.data], {type: 'application/pdf'})
                                var fileURL = URL.createObjectURL(file);
                                window.open(fileURL)
                                this.isLoading = false
                            }).catch(showError)
                        } else {
                            this.$toasted.global.defaultError({msg: 'Não Existe(m) Apontamento(s) nesta Data.'})
                        }                        
                    }).catch(e => {
                        this.isLoading = false
                        console.log(e)
                        this.$toasted.global.defaultError({ msg: e })
                    })  
                }
            } catch (e) {
                console.log(e)
            }
        },
        loadProfessionals() {
            const url = `${baseApiUrl}/professional/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                this.professionals = res.data            
            }).catch(showError)                      
        },
        addProfessional(payload) {
            this.professional = {
                _id: payload.covenantSelected._id,
                name: payload.covenantSelected.name,
                cpf: payload.covenantSelected.cpf
            };
        },                
    },
    mounted() {
        this.theme = localStorage.getItem('theme')

        this.loadProfessionals()
    }    
}
</script>

<style>

</style>