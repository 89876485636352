<template>
    <div class="patient-admin">
        <PageTitle icon="fa fa-user" main="Cadastro" sub />     
        <Loading v-if="isLoading" />             
        <b-form v-else>            
            <input id="patient-id" type="hidden" v-model="patient.id"/>
            <b-row class="patient-row">
                <b-col md="4" sm="6">
                    <b-card :class="`card-${theme} ml-1`" bg-variant="default" header="Informações Gerais" header-bg-variant="dark" header-text-variant="white">
                        <b-form-group label="Nome:" label-for="patient-name" class="mt-3">
                            <b-form-input id="patient-name" type="text" 
                                v-model="patient.name" required                       
                                placeholder="Informe seu nome..." />
                        </b-form-group>         
                        <b-row>
                            <b-col md="6" sm="6">
                                <b-form-group label="CPF:" label-for="patient-cpf">
                                    <b-form-input id="patient-cpf" type="number" 
                                        v-model="patient.cpf" required
                                        placeholder="Informe seu CPF..." />
                                </b-form-group> 
                                <b-form-group label="Data Nascimento:" label-for="patient-birthday">
                                    <b-form-input id="patient-birthday" type="date"
                                        v-model="patient.birthday" required
                                        placeholder="Informe sua Data de Nascimento..." />
                                </b-form-group>         
                                <b-form-group label="E-mail:" label-for="patient-email">
                                    <b-form-input id="patient-email" type="text"
                                        v-model="patient.email" 
                                        placeholder="Informe seu E-mail..." />
                                </b-form-group>        
                                <b-form-group label="Fone 1:" label-for="patient-phone1">
                                    <b-form-input id="patient-phone1" type="number"
                                        v-model="patient.phone1" 
                                        placeholder="Informe seu Fone..." />
                                </b-form-group>                              
                            </b-col>
                            <b-col md="6" sm="6">
                                <b-form-group label="RG:" label-for="patient-rg">
                                    <b-form-input id="patient-rg" type="number"
                                        v-model="patient.rg" 
                                        placeholder="Confirme seu RG..." />
                                </b-form-group>
                                <b-form-group label="Sexo:" label-for="patient-gender">
                                    <b-form-select v-model="patient.gender" :options="genders"></b-form-select>
                                </b-form-group>        
                                <b-form-group label="Dados Convênio:" label-for="patient-covenant">  
                                  <b-dropdown id="dropdown-form-covenant" text="Dados do Convênio do Cliente" ref="dropdownCovenant" variant="light">
                                    <b-dropdown-form>
                                        <b-form-group label="Convênio:" label-for="patient-covenant">
                                            <b-form-select v-model="selectedCovenant" :options="covenants"></b-form-select>
                                        </b-form-group>    
                                        <b-form-group label="Número Carteira:" label-for="dropdown-form-number"> 
                                            <b-form-input id="dropdown-form-number" placeholder="Número Carteira..." v-model="covenant.number" type="text"></b-form-input> 
                                        </b-form-group> 
                                    </b-dropdown-form>
                                 </b-dropdown>        
                                </b-form-group>                          
                                <b-form-group label="Fone 2:" label-for="patient-phone2">
                                    <b-form-input id="patient-phone2" type="number"
                                        v-model="patient.phone2" 
                                        placeholder="Informe seu Fone..." />
                                </b-form-group>                                                           
                            </b-col>              
                        </b-row>               
                    </b-card>
                </b-col>
                <b-col md="4" sm="6">
                    <b-row>
                        <b-col md="12" sm="12">
                            <b-card :class="`card-${theme}`" bg-variant="default" header="Endereço" header-bg-variant="dark" header-text-variant="white">
                                <b-row>    
                                    <b-col md="5" sm="12"> 
                                        <b-form-input type="text" id="nested-cep" placeholder="CEP..." v-model="address.cep"></b-form-input>
                                    </b-col>
                                    <b-col md="1" sm="12"> 
                                        <b-button @click="searchCEP"><i class="fa fa-search-plus"></i></b-button>
                                    </b-col>                            
                                </b-row>
                                <b-row>
                                    <b-col md="10" sm="12"> 
                                        <b-form-group label="Logradouro:" label-for="nested-street" class="mt-3">
                                            <b-form-input id="nested-street" placeholder="Logradouro..." v-model="address.street"></b-form-input>
                                        </b-form-group>
                                    </b-col>                         
                                    <b-col md="2" sm="12"> 
                                        <b-form-group label="Número:" label-for="nested-number" class="mt-3">
                                            <b-form-input id="nested-number" placeholder="Número..." v-model="address.number"></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col md="6" sm="12"> 
                                        <b-form-group label="Bairro:" label-for="nested-neighborhood">
                                            <b-form-input id="nested-neighborhood" placeholder="Bairro..." v-model="address.neighborhood"></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col md="6" sm="12"> 
                                        <b-form-group label="Município:" label-for="nested-city">
                                            <b-form-input id="nested-city" placeholder="Município..." v-model="address.city"></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col md="8" sm="12"> 
                                        <b-form-group label="Complemento:" label-for="nested-complement">
                                            <b-form-input id="nested-complement" placeholder="Complemento..." v-model="address.complement"></b-form-input>
                                        </b-form-group>
                                    </b-col>           
                                    <b-col md="4" sm="12">                    
                                        <b-form-group label="UF:" label-for="patient-state">
                                            <b-form-select v-model="address.state" :options="states"></b-form-select>
                                        </b-form-group>               
                                    </b-col>                                              
                                </b-row>       
                            </b-card>                           
                        </b-col>         
                    </b-row>  
                    <b-row class="mt-2"> 
                        <b-col md="12" sm="12">
                            <b-card :class="`card-${theme}`" bg-variant="default" header="Senha" header-bg-variant="dark" header-text-variant="white">
                                <b-row>
                                    <b-col md="6" sm="12"> 
                                        <b-form-input id="patient-password" type="password" placeholder="Senha..." v-model="patient.password"></b-form-input>
                                    </b-col>                                
                                    <b-col md="6" sm="12"> 
                                        <b-form-input id="patient-confirmPassword" type="password" placeholder="Confirme a Senha..." v-model="patient.confirmPassword"></b-form-input>
                                    </b-col>                                
                                </b-row>
                            </b-card>
                        </b-col>                             
                    </b-row>                    
                </b-col>
            </b-row>
            <b-row class="patient-row ml-1">
                <!-- <b-col> -->
                    <b-button variant="success" @click="save" class="mt-3">SALVAR</b-button>  
                    <b-button variant="danger" @click="cancel" class="mt-3 ml-2">CANCELAR</b-button>                      
                <!-- </b-col> -->
            </b-row>
        </b-form>        
    </div>
</template>

<script>
import PageTitle from "../../template/PageTitle";
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'
import States from '../../default/states'
import Loading from "../../template/Loading"

export default {
    name: "PatientPage",
    components: { PageTitle, Loading },
    data: function() {
      return { 
        theme: null,
        isLoading: false,
        patient: {},
        address: {},
        genders: [
            {value: 1, text: "Masculino"},
            {value: 2, text: "Feminino"}
        ],
        selectedCovenant: null,
        covenant: {},
        covenants: [],        
        states: States.states,    
      }    
    },
    methods: {           
        save() {
            if (this.patient.name) {
                this.patient.name = this.patient.name.toUpperCase()
            }

            this.patient.external = true

            const method = this.patient._id ? 'put' : 'post'
            const id = this.patient._id ? `/${this.patient._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}` : `/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`

            this.patient.address = {
                cep: this.address.cep,
                street: this.address.street,
                number: this.address.number,
                neighborhood: this.address.neighborhood,
                city:  this.address.city,
                complement: this.address.complement,
                state: this.address.state
            }

            this.patient.responsible = this.responsible            

            this.covenants.forEach(c => {
                if (c.value == this.selectedCovenant) {
                    this.patient.covenant = {
                        _id: c.value,
                        name: c.text,
                        number: this.covenant.number
                    }                        
                }
            })            

            axios[method](`${baseApiUrl}/patient${id}`, this.patient)
                .then(res => {
                    this.isLoading = true

                    this.$toasted.global.defaultSuccess()

                    let u = document.URL.split('/')

                    let dataEmail = {
                        name: 'Cadastro-Paciente-Externo',
                        namePatient: this.patient.name,
                        cpfPatient: this.patient.cpf,
                        recipient: this.patient.email,
                        url: u[0] + '//' + u[2],
                        strValidated: res.data.strValidated,
                        external: true                            
                    }

                    axios['post'](`${baseApiUrl}/email/${localStorage.getItem('client')}&null`, dataEmail)
                        .then(() => {    
                            this.$toasted.global.defaultSuccess({ msg: 'E-mail enviado com sucesso.' })
                            this.isLoading = false                                                            
                            this.$router.push({name: 'authPatient', params: { },})
                        }).catch(() => {
                            this.isLoading = false
                            this.$toasted.global.defaultError({msg: 'Problema ao enviar e-mail'})
                            this.$router.push({name: 'authPatient', params: { },})
                        })                       
                })  
                .catch(showError)
        },  
        searchCEP() {     
            this.$viaCep.buscarCep(this.address.cep).then((obj) => {
                this.address = {
                    cep: obj.cep,
                    street: obj.logradouro,
                    neighborhood: obj.bairro,
                    city: obj.localidade,                    
                }                
            });
        },        
        cancel() {            
            this.$router.push({name: 'authPatient', params: { },})
        },
        loadCovenants() {
            const url = `${baseApiUrl}/covenants/getCovenantsExternal/${localStorage.getItem('client')}`
            axios.get(url).then(res => {
                for (let r of res.data) {
                    this.covenants.push({ value: r._id,  text: r.fantasyname })                        
                }
            }).catch(showError)                        
        },    
    },
    mounted() {
        this.theme = localStorage.getItem('theme')

        this.loadCovenants()
    }
}     
</script>

<style>
.patient-row {
   justify-content: left;
}

.card-green {
  background-color: #f7fff9;
}

.card-wine {
  background-color: rgb(255, 241, 241);
}

.card-violet {
  background-color: #f3eaff;
}
</style>