import 'font-awesome/css/font-awesome.css'
import Vue from 'vue'

import App from './App'

import './config/bootstrap'
import './config/msgs'
import './config/axios'
import './config/mq'
import store from './config/store'
import router from './config/router'
import ViaCep from 'vue-viacep'
import axios from "axios"
import { baseApiUrl } from './global'
import VueTheMask from 'vue-the-mask'

Vue.config.productionTip = false
Vue.use(ViaCep)
Vue.use(VueTheMask)

const getParams = {
  isAuthenticated: () => new Promise((r) => {
    let u = document.URL.split('=')

    if (u[1]) {
      if (u[1].toLowerCase() == '') {
        console.log('cliente não encontrado!')
      } else {
        localStorage.removeItem('client')

        if (u[0].includes('activation')) {
          let u2 = u[1].split('&')
          localStorage.setItem('client', u2[0].toLowerCase())
        } else {
          localStorage.setItem('client', u[1].toLowerCase())
        }
      }
    }

    axios.get(`${baseApiUrl}/oracle/theme/${localStorage.getItem('client')}`).then((res) => {
      localStorage.removeItem('name')
      localStorage.setItem('name', res.data.VUE_APP_NAME)

      localStorage.removeItem('theme')
      localStorage.setItem('theme', res.data.theme)

      localStorage.removeItem('logo')
      localStorage.setItem('logo', res.data.VUE_APP_LOGO)

      localStorage.removeItem('active')
      localStorage.setItem('active', res.data.active)

      if (res.data.theme != undefined) {
        setTimeout(() => r(true), 3000)
      } else {
        console.warn
        setTimeout(() => r(false), 3000)
      }
    }).catch(() => {
      console.warn
      setTimeout(() => r(false), 3000)
    })
  })
};

(async () => {
  const isAuthenticated = await getParams.isAuthenticated()

  if (isAuthenticated) {
    new Vue({
      data: {
        isAuthenticated
      },
      store,
      router,
      render: h => h(App)
    }).$mount('#app')
  }
})().catch(console.warn);