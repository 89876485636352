<template>
  <aside id="menu" :class="`menu-${theme}`" v-show="isMenuVisible">
    <div id="navs">
    <h1 :class="`title-system-${theme}`">Connect</h1><hr/>

      <b-navbar v-if="!user.isPatient" toggleable type="dark" :class="`navCad-${theme}`"> 
          <b-navbar-brand href="#"><h5 :class="`menuText-${theme}`"><i :class="`fa fa-file-text icon-cadastre-${theme}`"></i> CADASTROS</h5></b-navbar-brand>

          <b-navbar-toggle target="navbar-toggle-collapse-cadastre" @click="toggleCadastre">
            <template>
              <i v-if="expandedCadastre" :class="`iconMenu-${theme} fa fa-chevron-circle-up`"></i>
              <i v-else :class="`iconMenu-${theme} fa fa-chevron-circle-down`"></i>
            </template>
          </b-navbar-toggle>

          <b-collapse id="navbar-toggle-collapse-cadastre" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item @click="onNodeSelect(8, 'c')" ref="speciality"><h6 :class="`navItens-${theme}`">Área de Atuação</h6></b-nav-item>
              <b-nav-item @click="onNodeSelect(9, 'c')" ref="advice"><h6 :class="`navItens-${theme}`">Conselhos</h6></b-nav-item>
              <b-nav-item @click="onNodeSelect(7, 'c')" ref="covenant"><h6 :class="`navItens-${theme}`">Convênios</h6></b-nav-item>
              <b-nav-item @click="onNodeSelect(10, 'c')" ref="accommodation"><h6 :class="`navItens-${theme}`">Locais de Internação</h6></b-nav-item>
              <b-nav-item @click="onNodeSelect(18, 'c')" ref="event"><h6 :class="`navItens-${theme}`">Ocorrências</h6></b-nav-item>
              <b-nav-item @click="onNodeSelect(3, 'c')" ref="patient"><h6 :class="`navItens-${theme}`">Pacientes</h6></b-nav-item>
              <b-nav-item @click="onNodeSelect(4, 'c')" ref="professional"><h6 :class="`navItens-${theme}`">Profissionais</h6></b-nav-item>
              <b-nav-item @click="onNodeSelect(11, 'c')" ref="typeService"><h6 :class="`navItens-${theme}`">Tipos de Atendimento</h6></b-nav-item>
            </b-navbar-nav>
          </b-collapse>
          
      </b-navbar>    

      <b-navbar v-if="!user.isPatient" toggleable type="dark" :class="`navSchedules-${theme}`"> 
          <b-navbar-brand href="#"><h5 :class="`menuText-${theme}`"><i :class="`fa fa-clock-o icon-schedules-${theme}`"></i> AGENDA</h5></b-navbar-brand>

          <b-navbar-toggle target="navbar-toggle-collapse-schedules" @click="toggleSchedules">
            <template>
              <i v-if="expandedSchedules" :class="`iconMenu-${theme} fa fa-chevron-circle-up`"></i>
              <i v-else :class="`iconMenu-${theme} fa fa-chevron-circle-down`"></i>
            </template>
          </b-navbar-toggle>

          <b-collapse id="navbar-toggle-collapse-schedules" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item @click="onNodeSelect(15, 's')"><h6 :class="`navItens-${theme}`">Semanal</h6></b-nav-item>
              <b-nav-item @click="onNodeSelect(16, 's')"><h6 :class="`navItens-${theme}`">Processar Escala Semanal</h6></b-nav-item>
              <b-nav-item @click="onNodeSelect(24, 's')"><h6 :class="`navItens-${theme}`">Fixa</h6></b-nav-item>
            </b-navbar-nav>
          </b-collapse>
          
      </b-navbar>    

      <b-navbar v-if="!user.isPatient" toggleable type="dark" :class="`navScale-${theme}`"> 
          <b-navbar-brand href="#"><h5 :class="`menuText-${theme}`"><i :class="`fa fa-arrows-alt icon-scales-${theme}`"></i> MOVIMENTAÇÃO</h5></b-navbar-brand>

          <b-navbar-toggle target="navbar-toggle-collapse-scales" @click="toggleScales">
            <template>
              <i v-if="expandedScales" :class="`iconMenu-${theme} fa fa-chevron-circle-up`"></i>
              <i v-else :class="`iconMenu-${theme} fa fa-chevron-circle-down`"></i>
            </template>
          </b-navbar-toggle>

          <b-collapse id="navbar-toggle-collapse-scales" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item @click="onNodeSelect(5, 'm')"><h6 :class="`navItens-${theme}`">Admissão</h6></b-nav-item>
              <b-nav-item @click="onNodeSelect(12, 'm')"><h6 :class="`navItens-${theme}`">Agenda</h6></b-nav-item>
              <b-nav-item @click="onNodeSelect(14, 'm')"><h6 :class="`navItens-${theme}`">Apontamentos</h6></b-nav-item>
              <b-nav-item @click="onNodeSelect(25, 'm')"><h6 :class="`navItens-${theme}`">Agendamento</h6></b-nav-item>
            </b-navbar-nav>
          </b-collapse>
          
      </b-navbar>            

      <b-navbar v-if="!user.isPatient" toggleable type="dark" :class="`navFin-${theme}`"> 
          <b-navbar-brand href="#"><h5 :class="`menuText-${theme}`"><i :class="`fa fa-money icon-financial-${theme}`"></i> FINANCEIRO</h5></b-navbar-brand>

          <b-navbar-toggle target="navbar-toggle-collapse-financial" @click="toggleFinancial">
            <template>
              <i v-if="expandedFinancial" :class="`iconMenu-${theme} fa fa-chevron-circle-up`"></i>
              <i v-else :class="`iconMenu-${theme} fa fa-chevron-circle-down`"></i>
            </template>
          </b-navbar-toggle>

          <b-collapse id="navbar-toggle-collapse-financial" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item @click="onNodeSelect(23, 'f')"><h6 :class="`navItens-${theme}`">Contas a Receber</h6></b-nav-item>
              <b-nav-item @click="onNodeSelect(17, 'f')"><h6 :class="`navItens-${theme}`">Geração de Guias</h6></b-nav-item>
              <b-nav-item @click="onNodeSelect(37, 'f')"><h6 :class="`navItens-${theme}`">Lotes de Guias</h6></b-nav-item>
            </b-navbar-nav>
          </b-collapse>
          
      </b-navbar>   

      <b-navbar v-if="!user.isPatient" toggleable type="dark" :class="`navReport-${theme}`"> 
          <b-navbar-brand href="#"><h5 :class="`menuText-${theme}`"><i :class="`fa fa-bar-chart icon-reports-${theme}`"></i> RELATÓRIOS</h5></b-navbar-brand>

          <b-navbar-toggle target="navbar-toggle-collapse-reports" @click="toggleReports">
            <template>
              <i v-if="expandedReports" :class="`iconMenu-${theme} fa fa-chevron-circle-up`"></i>
              <i v-else :class="`iconMenu-${theme} fa fa-chevron-circle-down`"></i>
            </template>
          </b-navbar-toggle>

          <b-collapse id="navbar-toggle-collapse-reports" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item @click="onNodeSelect(21, 'r')"><h6 :class="`navItens-${theme}`">Admissão x Local de Internação</h6></b-nav-item>
              <b-nav-item @click="onNodeSelect(22, 'r')"><h6 :class="`navItens-${theme}`">Admissão x Apartamentos</h6></b-nav-item>
              <b-nav-item @click="onNodeSelect(28, 'r')"><h6 :class="`navItens-${theme}`">Capacidade de Atendimentos</h6></b-nav-item>
              <b-nav-item @click="onNodeSelect(29, 'r')"><h6 :class="`navItens-${theme}`">Pesquisa de Satisfação</h6></b-nav-item>
              <b-nav-item @click="onNodeSelect(30, 'r')"><h6 :class="`navItens-${theme}`">Admissões Sem Movimentação</h6></b-nav-item>
              <b-nav-item @click="onNodeSelect(31, 'r')"><h6 :class="`navItens-${theme}`">Produtividade</h6></b-nav-item>
              <b-nav-item @click="onNodeSelect(32, 'r')"><h6 :class="`navItens-${theme}`">Atendimentos Por Convênio</h6></b-nav-item>
              <b-nav-item @click="onNodeSelect(33, 'r')"><h6 :class="`navItens-${theme}`">Admissões Por Convênio</h6></b-nav-item>
              <b-nav-item @click="onNodeSelect(34, 'r')"><h6 :class="`navItens-${theme}`">Análise de Operação</h6></b-nav-item>
              <b-nav-item @click="onNodeSelect(35, 'r')"><h6 :class="`navItens-${theme}`">Altas</h6></b-nav-item>
              <b-nav-item @click="onNodeSelect(36, 'r')"><h6 :class="`navItens-${theme}`">Apontamentos</h6></b-nav-item>
            </b-navbar-nav>
          </b-collapse>
          
      </b-navbar>       

      <b-navbar v-if="user.isPatient" toggleable type="dark" :class="`navReport-${theme}`"> 
          <b-navbar-brand href="#"><h5 :class="`menuText-${theme}`"><i :class="`fa fa-bars icon-reports-${theme}`"></i> MENU</h5></b-navbar-brand>

          <b-navbar-toggle target="navbar-toggle-collapse-menu" @click="toggleMenuPatient">
            <template>
              <i v-if="expandedMenu" :class="`iconMenu-${theme} fa fa-chevron-circle-up`"></i>
              <i v-else :class="`iconMenu-${theme} fa fa-chevron-circle-down`"></i>
            </template>
          </b-navbar-toggle>

          <b-collapse id="navbar-toggle-collapse-menu" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item @click="onNodeSelect(26, 'p')"><h6 :class="`navItens-${theme}`">Meu Cadastro</h6></b-nav-item>
              <b-nav-item @click="onNodeSelect(27, 'p')"><h6 :class="`navItens-${theme}`">Questionários</h6></b-nav-item>
            </b-navbar-nav>
          </b-collapse>
          
      </b-navbar>
    </div>         

    <div data-spy="scroll" data-target="#menu" data-offset="0">
    </div>
  </aside>
</template>

<script>
import { mapState } from "vuex";
import TreeMain from "../default/Tree"

export default {
  name: "Menu",
  computed: mapState(["isMenuVisible", "user"]),
  data: function () {
    return {
      theme: null,
      treeFilter: "",
      treeData: TreeMain.treeMain,
      treeOptions: {        
        propertyNames: { text: "name" },
        filter: { emptyText: "Categoria não encontrada" },        
      },
      accessProfile: null,
      expandedCadastre: false,
      expandedScales: false,
      expandedSchedules: false,
      expandedFinancial: false,
      expandedReports: false,
      expandedMenu: false
    };
  },
  methods: {   
    toggleCadastre() {
      this.expandedCadastre = !this.expandedCadastre
    },
    toggleScales() {
      this.expandedScales = !this.expandedScales
    },   
    toggleSchedules() {
      this.expandedSchedules = !this.expandedSchedules
    },            
    toggleFinancial() {
      this.expandedFinancial = !this.expandedFinancial
    },    
    toggleReports() {
      this.expandedReports = !this.expandedReports   
    },       
    toggleMenuPatient() {
      this.expandedMenu = !this.expandedMenu   
    },           
    onNodeSelect(node, type) {
      let way = '';
      switch (node) {
        case 3: way = 'patients'                
          break;
        case 4: way = 'professionals'
          break;          
        case 5: way = 'admissions'                
          break;          
        case 7: way = 'covenants'
          break;
        case 8: way = 'specialities'
          break;        
        case 9: way = 'advices'
          break;   
        case 10: way = 'accommodations'
          break;              
        case 11: way = 'typeServices'
          break;               
        case 12: way = 'scaleCalendar'
          break;      
        case 14: way = 'notation'
          break;            
        case 15: way = 'scaleBase'
          break;
        case 16: way = 'scaleBaseProcessing'
          break;             
        case 17: way = 'batchGuidesANS'
          break;      
        case 18: way = 'events'
          break;                   
        case 21: way = 'admissionXAccommodation'
          break;       
        case 22: way = 'admissionXApartments'
          break;   
        // case 23: way = 'admissionXApartments'
        //   break;   
        case 24: way = 'scaleFixed'
          break;   
        case 25: way = 'scheduling'
          break;     
        case 27: way = 'questions'
          break;
        case 28: way = 'serviceCapacity'
          break;
        case 29: way = 'satisfactionSurveyResume'
          break;
        case 30: way = 'admissionNoMoviment'
          break;
        case 31: way = 'repAttendancesDone'
          break;
        case 32: way = 'attendancesDonePerCovenant'
          break;
        case 33: way = 'admissionsPerCovenant'
          break;
        case 34: way = 'operationAnalysis'
          break;
        case 35: way = 'repReleased'
          break;
        case 36: way = 'repAppointments'
          break;
        case 37: way = 'batchs'
          break;          

        default:
          way = '';
      }
      
      if (type == 'c') {
        this.toggleCadastre()
      } else if (type == 'm') {
        this.toggleScales()
      } else if (type == 's') {
        this.toggleSchedules()
      } else if (type == 'f') {
        this.toggleFinancial() 
      } else if (type == 'r') {
        this.toggleReports()
      } else {
        this.toggleMenuPatient()
      }
      
      if (way !== '') { 
        this.$router.push({name: way, params: { },})
        if (this.$mq === "xs" || this.$mq === "sm") {          
          this.$store.commit("toggleMenu", false);
        }
      }
    },
  },
  mounted() {    
    this.theme = localStorage.getItem('theme')
  },
};
</script>

<style>
.menu-green {
  grid-area: menu;
  background: linear-gradient(to bottom, #f7fff9,rgb(196, 235, 220));
  
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border-radius: 10pt;

  margin: 5px;
  border-top: 1px solid #28A745;
  border-bottom: 1px solid #28A745;
  border-left: 1px solid #28A745;
  border-right: 1px solid #28A745;    
}

.menu-wine {
  grid-area: menu;
  background: linear-gradient(to bottom, rgb(255, 241, 241),rgb(252, 191, 191));
  
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border-radius: 10pt;

  margin: 5px;
  border-top: 1px solid #7A1120;
  border-bottom: 1px solid #7A1120;
  border-left: 1px solid #7A1120;
  border-right: 1px solid #7A1120;    
}

.menu-violet {
  grid-area: menu;
  background: linear-gradient(to bottom, #f3eaff, #9f94b3);
  
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border-radius: 10pt;

  margin: 5px;
  border-top: 1px solid #6E4DA4;
  border-bottom: 1px solid #6E4DA4;
  border-left: 1px solid #6E4DA4;
  border-right: 1px solid #6E4DA4;    
}

.menu-green span,
.menu-green span:hover {
  margin: 5px;
  color: rgb(0, 56, 39);
  font-weight: bold;
}

.menu-wine span,
.menu-wine span:hover {
  margin: 5px;
  color: #7A1120;
  font-weight: bold;
}

.menu-violet span,
.menu-violet span:hover {
  margin: 5px;
  color: #6E4DA4;
  font-weight: bold;
}

.menu-green .tree {  
  font-size: 14px;
  font-weight: bold;
}

.menu-wine .tree {  
  font-size: 14px;
  font-weight: bold;
}

.menu-violet .tree {  
  font-size: 14px;
  font-weight: bold;
}

.menu-green .tree-node.selected > .tree-content,
.menu-green .tree-node .tree-content:hover {
  background-color:#f7fff9;
}

.menu-wine .tree-node.selected > .tree-content,
.menu-wine .tree-node .tree-content:hover {
  background-color:#f7fff9;
}

.menu-violet .tree-node.selected > .tree-content,
.menu-violet .tree-node .tree-content:hover {
  background-color:#f7fff9;
}

.menu .tree-node:not(.selected) > .tree-content:hover {
  background:#f7fff9;
}

.tree-arrow.has-child {
  filter: black;
}

.menu-green .title-system-green {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f7fff9;
  background-color: rgb(34, 138, 103);
  border-top: 1px solid rgb(34, 138, 103);
  border-right: 1px solid rgb(34, 138, 103);
  border-left: 1px solid rgb(34, 138, 103); 
  border-radius: 7pt;
}

.menu-wine .title-system-wine {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 241, 241);
  background-color: #7A1120;
  border-top: 1px solid #7A1120;
  border-right: 1px solid #7A1120;
  border-left: 1px solid #7A1120; 
  border-radius: 10pt;
}

.menu-violet .title-system-violet {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f3eaff;
  background-color: #6E4DA4;
  border-top: 1px solid #6E4DA4;
  border-right: 1px solid #6E4DA4;
  border-left: 1px solid #6E4DA4; 
  border-radius: 7pt;
}

.menu-green .title-system-green i {
  color: #f7fff9;
  margin-right: 10px;
}

.menu-wine .title-system-wine i {
  color: rgb(255, 241, 241);
  margin-right: 10px;
}

.menu-violet .title-system-violet i {
  color: #f3eaff;
  margin-right: 10px;
}

.menu-green .closeMenu {
  background-color: tomato;
}

.menu-wine .closeMenu {
  background-color: tomato;
}

.menu-violet .closeMenu {
  background-color: tomato;
}

.menu-green input {
  color: #f7fff9;
  font-size: 1.3rem;
  border: 0;
  outline: 0;
  width: 100%;
  background: transparent;
}

.menu-wine input {
  color: rgb(255, 241, 241);
  font-size: 1.3rem;
  border: 0;
  outline: 0;
  width: 100%;
  background: transparent;
}

.menu-violet input {
  color: #f3eaff;
  font-size: 1.3rem;
  border: 0;
  outline: 0;
  width: 100%;
  background: transparent;
}

.tree-filter-empty {
  color: #f7fff9;
  font-size: 1.3rem;
  margin-left: 20px;
}

.icon-cadastre-green {
  color: rgb(0, 56, 39);
}
.icon-scales-green {
  color: rgb(0, 56, 39);
}
.icon-schedules-green {
  color: rgb(0, 56, 39);
}
.icon-financial-green {
  color: rgb(0, 56, 39);
}
.icon-reports-green {
  color: rgb(0, 56, 39);
}

.icon-cadastre-wine {
  color: #7A1120;
}
.icon-scales-wine {
  color: #7A1120;
}
.icon-schedules-wine {
  color: #7A1120;
}
.icon-financial-wine {
  color: #7A1120;
}
.icon-reports-wine {
  color: #7A1120;
}

.icon-cadastre-violet {
  color: #6E4DA4;
}
.icon-scales-violet {
  color: #6E4DA4;
}
.icon-schedules-violet {
  color: #6E4DA4;
}
.icon-financial-violet {
  color: #6E4DA4;
}
.icon-reports-violet {
  color: #6E4DA4;
}

.menuText-green {
  margin: 2px;
  color: rgb(0, 56, 39);
  font-weight: bold;  
  font-size: 14px;
}

.menuText-wine {
  margin: 2px;
  color: #7A1120;
  font-weight: bold;  
  font-size: 14px;
}

.menuText-violet {
  margin: 2px;
  color: #6E4DA4;
  font-weight: bold;  
  font-size: 14px;
}

.navCad-green {
  margin: 7px;
  border-top: 1px solid rgb(0, 88, 62);
  border-bottom: 1px solid rgb(0, 88, 62);
  border-right: 1px solid rgb(0, 88, 62);
  border-left: 1px solid rgb(0, 88, 62);
  border-radius: 10pt;
  box-shadow: 0 4px 8px #0d413f;
}

.navCad-wine {
  margin: 7px;
  border-top: 1px solid #7A1120;
  border-bottom: 1px solid #7A1120;
  border-right: 1px solid #7A1120;
  border-left: 1px solid #7A1120;
  border-radius: 10pt;
  box-shadow: 0 4px 8px #7A1120;
}

.navCad-violet {
  margin: 7px;
  border-top: 1px solid #6E4DA4;
  border-bottom: 1px solid #6E4DA4;
  border-right: 1px solid #6E4DA4;
  border-left: 1px solid #6E4DA4;
  border-radius: 10pt;
  box-shadow: 0 4px 8px #532b94;
}

.navScale-green {
  margin: 7px;
  border-top: 1px solid rgb(0, 88, 62);
  border-bottom: 1px solid rgb(0, 88, 62);
  border-right: 1px solid rgb(0, 88, 62);
  border-left: 1px solid rgb(0, 88, 62);
  border-radius: 10pt;
  box-shadow: 0 4px 8px #0d413f;
}

.navScale-wine {
  margin: 7px;
  border-top: 1px solid #7A1120;
  border-bottom: 1px solid #7A1120;
  border-right: 1px solid #7A1120;
  border-left: 1px solid #7A1120;
  border-radius: 10pt;
  box-shadow: 0 4px 8px #7A1120;
}

.navScale-violet {
  margin: 7px;
  border-top: 1px solid #6E4DA4;
  border-bottom: 1px solid #6E4DA4;
  border-right: 1px solid #6E4DA4;
  border-left: 1px solid #6E4DA4;
  border-radius: 10pt;
  box-shadow: 0 4px 8px #532b94;
}

.navSchedules-green {
  margin: 7px;
  border-top: 1px solid rgb(0, 88, 62);
  border-bottom: 1px solid rgb(0, 88, 62);
  border-right: 1px solid rgb(0, 88, 62);
  border-left: 1px solid rgb(0, 88, 62);
  border-radius: 10pt;
  box-shadow: 0 4px 8px #0d413f;
}

.navSchedules-wine {
  margin: 7px;
  border-top: 1px solid #7A1120;
  border-bottom: 1px solid #7A1120;
  border-right: 1px solid #7A1120;
  border-left: 1px solid #7A1120;
  border-radius: 10pt;
  box-shadow: 0 4px 8px #7A1120;
}

.navSchedules-violet {
  margin: 7px;
  border-top: 1px solid #6E4DA4;
  border-bottom: 1px solid #6E4DA4;
  border-right: 1px solid #6E4DA4;
  border-left: 1px solid #6E4DA4;
  border-radius: 10pt;
  box-shadow: 0 4px 8px #532b94;
}

.navFin-green {
  margin: 7px;
  border-top: 1px solid rgb(0, 88, 62);
  border-bottom: 1px solid rgb(0, 88, 62);
  border-right: 1px solid rgb(0, 88, 62);
  border-left: 1px solid rgb(0, 88, 62);
  border-radius: 10pt;
  box-shadow: 0 4px 8px #0d413f;
}

.navFin-wine {
  margin: 7px;
  border-top: 1px solid #7A1120;
  border-bottom: 1px solid #7A1120;
  border-right: 1px solid #7A1120;
  border-left: 1px solid #7A1120;
  border-radius: 10pt;
  box-shadow: 0 4px 8px #7A1120;
}

.navFin-violet {
  margin: 7px;
  border-top: 1px solid #6E4DA4;
  border-bottom: 1px solid #6E4DA4;
  border-right: 1px solid #6E4DA4;
  border-left: 1px solid #6E4DA4;
  border-radius: 10pt;
  box-shadow: 0 4px 8px #532b94;
}

.navReport-green {
  margin: 7px;
  border-top: 1px solid rgb(0, 88, 62);
  border-bottom: 1px solid rgb(0, 88, 62);
  border-right: 1px solid rgb(0, 88, 62);
  border-left: 1px solid rgb(0, 88, 62);
  border-radius: 10pt;
  box-shadow: 0 4px 8px #0d413f;
}

.navReport-wine {
  margin: 7px;
  border-top: 1px solid #7A1120;
  border-bottom: 1px solid #7A1120;
  border-right: 1px solid #7A1120;
  border-left: 1px solid #7A1120;
  border-radius: 10pt;
  box-shadow: 0 4px 8px #7A1120;
}

.navReport-violet {
  margin: 7px;
  border-top: 1px solid #6E4DA4;
  border-bottom: 1px solid #6E4DA4;
  border-right: 1px solid #6E4DA4;
  border-left: 1px solid #6E4DA4;
  border-radius: 10pt;
  box-shadow: 0 4px 8px #532b94;
}

.navItens-green {
  color: rgb(0, 0, 0);
  font-weight: bold;
}

.navItens-wine {
  color: #7A1120;
  font-weight: bold;
}

.navItens-violet {
  color: #6E4DA4;
  font-weight: bold;
}

.navItens-green:hover {
  color: #16a35c;
  text-decoration: none;
  font-weight: bold;
}

.navItens-wine:hover {
  color: rgb(247, 158, 158);
  text-decoration: none;
  font-weight: bold;
}

.navItens-violet:hover {
  color: #c5b7dd;
  text-decoration: none;
  font-weight: bold;
}

.iconMenu-green { 
  color: rgb(0, 88, 62); 
}

.iconMenu-wine { 
  color: #7A1120; 
}

.iconMenu-violet { 
  color: #6E4DA4; 
}
</style>