<template>
  <Loading v-if="isLoading" />  
  <div v-else class="schedulingPage">
    <PageTitle icon="fa fa-clock-o" main="Agendamento" sub="Calendário" />
    <Loading v-if="isLoading" />
    <div v-else class="scheduling-page-tabs">
      <b-row>
        <b-col md="9" sm="12">             
            <b-row>
                <b-col md="12" sm="12"> 
                    <b-card :class="`card-${theme}`" header="Paciente" header-bg-variant="dark" header-text-variant="white">
                        <b-row>
                            <b-col md="3" sm="12">
                                <b-form-group class="mt-4" label="Nome:" label-for="patient">
                                    <b-input size="sm" disabled v-model="selectedPatient.name"></b-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="2" sm="12">
                                <b-form-group class="mt-4" label="CPF:" label-for="patient">
                                    <b-input size="sm" disabled v-model="selectedPatient.cpf"></b-input>
                                </b-form-group>
                            </b-col>      
                            <b-col md="3" sm="12">
                                <b-form-group class="mt-4" label="Convênio:" label-for="patient">
                                    <b-form-select size="sm" @change="setCovenant()" :options="covenants" v-model="covenantSelected"></b-form-select>
                                </b-form-group>
                            </b-col>          
                            <b-col md="3" sm="12">
                                <b-form-group class="mt-4" label="Nº Carteira:" label-for="patient">
                                    <b-input size="sm" v-model="selectedPatient.covenant.number"></b-input>
                                </b-form-group>
                            </b-col>                                         
                            <b-col md="1" sm="12">
                                <b-button size="sm" class="mt-5" v-b-modal.modal-prevent-closing v-b-popover.hover.top="'Pesquisar Paciente'">
                                    <i class="fa fa-search"></i>
                                </b-button>   

                                <Lookup ref="Lookup" title="Pacientes" :list="patients" :fields="pfields" :invalidFeedback="invalidFeedback" @event="getPatient"
                                    resultTable="patientScheduling"></Lookup>
                            </b-col>     
                        </b-row>  
                    </b-card>       
                </b-col>
            </b-row>
        </b-col>
      </b-row>         
      <b-row>
        <b-col md="9" sm="12">
            <b-card :class="`card-${theme} mt-3`" header="Dados do Agendamento" header-bg-variant="dark" header-text-variant="white">
                <b-row>
                    <b-col md="6" sm="12">                        
                        <b-calendar :min="min" :max="max" block locale="pt-BR" hide-header today-variant="success" label-help="" selected-variant="secondary" v-model="dateSelected">
                        </b-calendar>     
                    </b-col>
                    <b-col md="4" sm="12">
                        <b-col md="10" sm="12">
                            <b-form-group label="Tipo de Atendimento:" label-for="typeService-scheduling" class="mt-3">
                                <b-form-select size="sm" v-model="selectedTypeService" :options="typeServices"></b-form-select>
                            </b-form-group>                            
                        </b-col>   
                        <b-row>
                            <b-col md="6" sm="12" class="ml-3">
                                <b-form-group  label="Horários Disponíveis:" label-for="hour-scheduling" class="mt-3">
                                    <b-form-select size="sm" v-model="selectedTime" :options="availableTimes"></b-form-select>
                                </b-form-group>                                                        
                            </b-col>   
                            <b-col md="2" sm="12" class="mt-5">
                                <b-form-checkbox size="sm" id="checkbox-courtesy" v-model="courtesy" name="checkbox-courtesy" :value="true" :unchecked-value="false">Cortesia</b-form-checkbox>
                            </b-col>   
                        </b-row>
                        <b-col md="1" sm="12">
                            <b-button size="sm" variant="warning" class="mt-3" @click="filterProfessionals">Filtrar</b-button>
                        </b-col>                                                   
                    </b-col>
                </b-row>
                <b-row>
                <b-col md="12" sm="12">
                    <b-card :class="`card-${theme} mt-3 mb-3`" header="Agendamentos" header-bg-variant="dark" header-text-variant="white">  
                        <b-table class="mt-3" ref="selectableTable" head-variant="light" outlined hover :items="listScheduling" :fields="afields" responsive="sm" id="my-table"
                                :per-page="perPage" :current-page="currentPage" striped fixed>
                            <template v-slot:cell(actions)="row">
                                <b-button size="sm" variant="danger" @click="deleteScheduling(row.item)" v-b-popover.hover.top="'Excluir'"> 
                                    <i class="fa fa-minus-circle"></i>
                                </b-button>
                            </template>
                        </b-table> 
                    </b-card>
                </b-col>
            </b-row>
            </b-card>    
        </b-col>    
      </b-row>
      <b-row>
        <b-col md="9" sm="12">
            <b-card :class="`card-${theme} mt-3 mb-3`" header="Profissionais" header-bg-variant="dark" header-text-variant="white">  
                <b-table class="mt-3" ref="selectableTable" head-variant="light" outlined hover :items="availableProfessionals" :fields="fields" responsive="sm" id="my-table"
                        :per-page="perPage" :current-page="currentPage" striped fixed>
                    <template v-slot:cell(actions)="row">
                        <b-button size="sm" variant="success" @click="onRowSelectedList(row.item)" v-b-popover.hover.top="'Agendar'"> 
                            <i class="fa fa-clock-o"></i>
                        </b-button>
                    </template>
                </b-table> 
            </b-card>
        </b-col>
      </b-row>

      <b-pagination size="sm" pills v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table" class="page-item">
      </b-pagination>        

      <b-button size="sm" variant="success" @click="save" class="mt-3">SALVAR</b-button>  
      <b-button size="sm" variant="danger" @click="cancel" class="mt-3 ml-2">CANCELAR</b-button>  
    </div>
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, userKey } from '@/global'
import axios from 'axios'
import Moment from 'moment'
import Lookup from '../default/Lookup'
import Loading from "../template/Loading"

export default {
    name: "SchedulingPage",
    components: { PageTitle, Lookup, Loading },
    data: function() {
        return {
           theme: null,
           isLoading: false,
           min: null,
           max: null,
           availableProfessionals: [],
           dateSelected: null,
           selectedTime: null,
           availableTimes: [],
           selectedTypeService: null,
           typeServices: [],
           listScheduling: [],
           settings: null,
           courtesy: false,
           selectedPatient: {
               covenant: {}
           },
           patients: [],
           covenants: [],
           covenantSelected: null,
           invalidFeedback: '',
           fields: [                 
                { key: 'name', label: 'Nome', sortable: true },
                { key: 'adviceNumber', label: 'Registro', sortable: true },
                { key: 'actions', label: 'Ações' }
           ],
           afields: [                 
                { key: 'date2', label: 'Data', sortable: true },
                { key: 'typeService.name', label: 'Tipo', sortable: true },
                { key: 'hour', label: 'Horário', sortable: true },
                { key: 'professional.name', label: 'Profissional', sortable: true },
                { key: 'actions', label: 'Ações' }
           ],
           pfields: [
               { key: 'name', label: 'Nome', sortable: true },
               { key: 'cpf', label: 'CPF', sortable: true },
           ],
           weekday: ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"],
           currentPage: 1,
           perPage: 10,                      
        }        
    },
    computed: {
      rows() {
        return this.availableProfessionals.length
      }
    },    
    methods: {
        loadTypeServices() {
            this.isLoading = true

            const url = `${baseApiUrl}/typeService/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {  
                for (let r of res.data) {
                    this.typeServices.push(
                        {value: r, text: r.name},
                    )
                }   

                this.isLoading = false
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })
            })                      
        },   
        loadSettings() {
            this.isLoading = true

            const url = `${baseApiUrl}/setting/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                this.settings = res.data   
                
                const max = Number(this.settings[0].params[15].value)
                const now = new Date()
                const today   = new Date(now.getFullYear(), now.getMonth(), now.getDate())
                const maxDate = new Date(today)
                maxDate.setDate(maxDate.getDate() + max)

                this.max = maxDate
                
                this.loadHours()

                this.isLoading = false
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })
            })  
        },
        loadHours() {
            Moment.locale('pt-br')

            let interval = this.settings[0].params[4].value

            let hourInitial = Moment(this.settings[0].params[11].value, 'hh:mm:ss').format('LTS')
            let hourFinal = Moment(this.settings[0].params[12].value, 'hh:mm:ss').format('LTS')

            this.availableTimes.push(
                {value: hourInitial, text: hourInitial},
            )               

            while (hourInitial < hourFinal) {
                hourInitial = Moment(hourInitial, 'hh:mm:ss').add(interval, 'minutes').format('LTS')

                this.availableTimes.push(
                    {value: hourInitial, text: hourInitial},
                )                
            }            
        },
        filterProfessionals() {            
            let valid = true

            Moment.locale('pt-br')        

            if (this.selectedPatient._id == null) {
                this.$toasted.global.defaultError({msg: 'Selecione o paciente.'})
                valid = false                
            }             

            if (this.dateSelected == null) {
                this.$toasted.global.defaultError({msg: 'Informe a data.'})
                valid = false                
            }            

            let today = new Date()
            today = Moment(`${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`)

            if (this.dateSelected < today) {
                this.$toasted.global.defaultError({msg: 'O período informado já foi executado.'})
                valid = false
            }               

            if (this.selectedTypeService == null) {
                this.$toasted.global.defaultError({msg: 'Informe o tipo de atendimento.'})
                valid = false                
            }

            if (this.selectedTime == null) {
                this.$toasted.global.defaultError({msg: 'Informe o horário.'})
                valid = false                
            }            

            if (valid) {
                let day = this.weekday[new Date(this.dateSelected).getDay() + 1]

                this.isLoading = true

                const url = `${baseApiUrl}/scaleFixed/filter/${this.dateSelected}&${day}&${this.selectedTime}&${this.selectedTypeService._id}&${this.selectedTypeService.name.replace(/\//gi, "-")}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
                axios.get(url).then(res => {
                    if (res.data.length == 0) {
                        this.$toasted.global.defaultError({msg: 'Nenhum profissional disponível'})    
                        this.availableProfessionals = []
                    } else {
                        this.availableProfessionals = res.data     
                    }
                    
                    this.isLoading = false                
                }).catch(() => {
                    this.isLoading = false
                    this.$toasted.global.defaultError({msg: 'Nenhum profissional disponível'})
                })                  
            }
        },
        onRowSelectedList(itemSelected) {
            Moment.locale('pt-br')

            this.$bvModal.msgBoxConfirm(`Confirma o agendamento para o dia ${Moment(this.dateSelected).format('L')} as ${this.selectedTime}?`, {
                title: 'Confirmação',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Sim',
                cancelTitle: 'Não',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            }).then(value => {            
                if (value) {
                    let valid = true
                    for (let ls of this.listScheduling) {
                        if(ls.date == this.dateSelected && ls.hour == this.selectedTime && ls.typeService == this.selectedTypeService && ls.patient.name == this.selectedPatient.name) {
                            valid = false
                        }
                    }
                    if(valid) {
                        Moment.locale('pt-br')
                        let interval = this.settings[0].params[4].value
                        let hourInitial = Moment(this.selectedTime, 'hh:mm:ss').format('LTS')
                        let hourFinal = Moment(hourInitial, 'hh:mm:ss').add(interval, 'minutes').format('LTS')

                        this.listScheduling.push({
                            date: this.dateSelected,
                            date2: Moment(this.dateSelected).format('L'),
                            hour: this.selectedTime,
                            professional: {
                                name: itemSelected.name,
                                cpf: itemSelected.cpf,
                                adviceNumber: itemSelected.adviceNumber,
                                checkin: itemSelected.checkin,
                                checkout: itemSelected.checkout
                            },
                            patient: {
                                _id: this.selectedPatient._id,
                                name: this.selectedPatient.name,
                                cpf: this.selectedPatient.cpf,
                                email: this.selectedPatient.email,
                                covenantNumber: this.selectedPatient.covenant.number,
                                covenantName: this.selectedPatient.covenant.name,
                                accommodation: {},
                                admissionCode: '',
                                admissionAttendanceNumber: '',
                                attendances: [
                                    {
                                        name: this.selectedTypeService.name,
                                        morning: true,
                                        evening: false,
                                        night: false
                                    }
                                ],
                                start: this.selectedTime,
                                startDone: null,
                                finish: hourFinal,
                                finishDone: null,
                                released: false,
                                questions: this.selectedPatient.questions,
                                courtesy: this.courtesy
                            }, 
                            typeService: this.selectedTypeService,                        
                        });    
                        this.availableProfessionals = []     
                    } else {
                        this.$toasted.global.defaultError({msg: 'Dados já cadastrados!'})
                    }
                }
            }).catch(e => {
                this.$toasted.global.defaultError({msg: e})
            })     
        },
        deleteScheduling(itemSelected) {
            const idx = this.listScheduling.indexOf(itemSelected)
            this.listScheduling.splice(idx, 1)
        },
        save() {
            let valid = true

            if(this.listScheduling.length == 0) {
                this.$toasted.global.defaultError({msg: 'Informe o(s) agendamento(s)!'})
                valid = false
            }

            if (this.selectedPatient._id == null) {
                this.$toasted.global.defaultError({msg: 'Selecione o paciente.'})
                valid = false                
            }   

            if(valid) {

                let listSchedulings = {
                    scheduling: this.listScheduling
                }
            
                axios['post'](`${baseApiUrl}/scale/scheduling/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, listSchedulings)
                    .then(() => {                            
                        this.$toasted.global.defaultSuccess({ msg: 'Agendamento realizado com sucesso.' })

                        // let dataEmail = {
                        //     name: 'Agendamento',
                        //     namePatient: listSchedulings.patient.name,
                        //     dateScheduling: Moment(listSchedulings.date).format('L'),
                        //     hourScheduling: this.selectedTime,
                        //     nameProfessional: listSchedulings.professional.name,
                        //     recipient: listSchedulings.patient.email                             
                        // }

                        // if (listSchedulings.patient.email) {
                        //     axios['post'](`${baseApiUrl}/email/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, dataEmail).then().catch()
                        // }
                            
                        this.isLoading = false                                                            
                        this.$router.go(-1)                                               
                    }).catch(() => {
                        this.isLoading = false
                        this.$toasted.global.defaultError({msg: 'Problema ao realizar agendamento'})
                    })
            }   
        },
        cancel() {
            this.$router.go(-1)
        },
        getPatient(payload) {
            this.selectedPatient = payload.patientSelected
            
            this.covenantSelected = this.selectedPatient.covenant._id
        },
        setCovenant() {
            this.selectedPatient.covenant.name = this.covenants.find(el => el.value == this.covenantSelected).text
        },
        loadCovenants() {
            this.isLoading = true

            const url = `${baseApiUrl}/covenants/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                this.covenants = []

                for (let r of res.data) {
                    this.covenants.push({ 
                        value: r._id,
                        text: r.fantasyname
                    })
                }

                this.isLoading = false
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })
            })                      
        },
    },    
    mounted() {
        this.theme = localStorage.getItem('theme')

        const now = new Date()
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
        const minDate = new Date(today)
        minDate.setDate(minDate.getDate())
        this.min = minDate

        this.loadSettings()
        this.loadTypeServices()
        this.loadCovenants()   
    }      
}
</script>

<style>
.schedulingPage {
    height: 100%;
    position: relative;
}

.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}

.card-violet {
  background-color: #f3eaff;
}
</style>