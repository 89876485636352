<template>
  <div class="satisfactionSurvey">
    <PageTitle icon="fa fa-file-text" main="Questionário" sub="ESCALA MOTOR INFANTIL ALBERTA - EMIA" />
  
    <div>
      <b-card :class="`card-${theme}`">
        <b-button v-show="question.status == 0" @click="sendQuestions()" variant="info">Enviar</b-button>

        <b-form-group class="mt-3">
          <b-button-group>
            <b-button @click="numberQuestion = 1" :variant="(selected1_1 != null ||
                                                             selected1_2 != null ||
                                                             selected1_3 != null ||
                                                             selected1_4 != null ||
                                                             selected1_5 != null ||
                                                             selected1_6 != null ||
                                                             selected1_7 != null ||
                                                             selected1_8 != null ) ? 'success' : 'secondary'">1</b-button>
            <b-button @click="numberQuestion = 2" :variant="(selected2_1 != false ||
                                                             selected2_2 != false || 
                                                             selected2_3 != false ||
                                                             selected2_4 != false ||
                                                             selected2_5 != false ||
                                                             selected2_6 != false ||
                                                             selected2_7 != false ||
                                                             selected2_8 != false ||
                                                             selected2_9 != false ||
                                                             selected2_10 != false ||
                                                             selected2_11 != false ||
                                                             selected2_12 != false ||
                                                             selected2_13 != false ||
                                                             selected2_14 != false ||
                                                             selected2_15 != false ||
                                                             selected2_16 != false ||
                                                             selected2_17 != false ||
                                                             selected2_18 != false ||
                                                             selected2_19 != false ||
                                                             selected2_20 != false ||
                                                             selected2_21 != false) ? 'success' : 'secondary'">2</b-button>
            <b-button @click="numberQuestion = 3" :variant="(selected3_1 != false ||
                                                             selected3_2 != false ||
                                                             selected3_3 != false ||
                                                             selected3_4 != false ||
                                                             selected3_5 != false ||
                                                             selected3_6 != false ||
                                                             selected3_7 != false ||
                                                             selected3_8 != false ||
                                                             selected3_9 != false ) ? 'success' : 'secondary'">3</b-button>
            <b-button @click="numberQuestion = 4" :variant="(selected4_1 != false ||
                                                             selected4_2 != false ||
                                                             selected4_3 != false ||
                                                             selected4_4 != false ||
                                                             selected4_5 != false ||
                                                             selected4_6 != false ||
                                                             selected4_7 != false ||
                                                             selected4_8 != false ||
                                                             selected4_9 != false ||
                                                             selected4_10 != false ||
                                                             selected4_11 != false ||
                                                             selected4_12 != false ) ? 'success' : 'secondary'">4</b-button>
            <b-button @click="numberQuestion = 5" :variant="(selected5_1 != false ||
                                                             selected5_2 != false ||
                                                             selected5_3 != false ||
                                                             selected5_4 != false ||
                                                             selected5_5 != false ||
                                                             selected5_6 != false ||
                                                             selected5_7 != false ||
                                                             selected5_8 != false ||
                                                             selected5_8 != false ||
                                                             selected5_9 != false ||
                                                             selected5_10 != false ||
                                                             selected5_11 != false ||
                                                             selected5_12 != false ||
                                                             selected5_13 != false ||
                                                             selected5_14 != false ||
                                                             selected5_15 != false || 
                                                             selected5_16 != false ) ? 'success' : 'secondary'">5</b-button>
            <b-button @click="numberQuestion = 6" :variant="(selected6 != null ) ? 'success' : 'secondary'">6</b-button>
          </b-button-group>
        </b-form-group>

        <b-form-group v-show="numberQuestion == 1" class="mt-3" label="FOLHA DE REGISTRO:">
          <b-row class="mt-4"> 
            <b-col md="8" sm="12">   
                <b-form-group v-show="numberQuestion == 1" label="Nome da Criança:">
                    <b-form-input type="text" v-model="selected1_1" />                    
                </b-form-group>                
            </b-col>
            <b-col md="3" sm="12">   
                <b-form-group v-show="numberQuestion == 1" label="Data da Avaliação:">
                    <b-form-input type="date" v-model="selected1_2" />                    
                </b-form-group>                
            </b-col>
          </b-row>

          <b-row class="mt-4"> 
            <b-col md="8" sm="12">   
                <b-form-group v-show="numberQuestion == 1" label="Registro da Criança:">
                    <b-form-input type="text" v-model="selected1_3" />                    
                </b-form-group>                
            </b-col>
            <b-col md="3" sm="12">   
                <b-form-group v-show="numberQuestion == 1" label="Data de Nascimento:">
                    <b-form-input type="date" v-model="selected1_4" />                    
                </b-form-group>                
            </b-col>
          </b-row>

          <b-row class="mt-4"> 
            <b-col md="8" sm="12">   
                <b-form-group v-show="numberQuestion == 1" label="Avaliador(a):">
                    <b-form-input type="text" v-model="selected1_5" />                    
                </b-form-group>                
            </b-col>
            <b-col md="2" sm="12">   
                <b-form-group v-show="numberQuestion == 1" label="Idade Cronológica:">
                    <b-form-input type="text" v-model="selected1_6" />                   
                </b-form-group>                
            </b-col>
          </b-row>

          <b-row class="mt-4"> 
            <b-col md="8" sm="12">   
                <b-form-group v-show="numberQuestion == 1" label="Local da Avaliação:">
                    <b-form-input type="text" v-model="selected1_7" />                    
                </b-form-group>                
            </b-col>
            <b-col md="2" sm="12">   
                <b-form-group v-show="numberQuestion == 1" label="Idade Corrida:">
                    <b-form-input type="text" v-model="selected1_8" />                   
                </b-form-group>                
            </b-col>
          </b-row>
        </b-form-group>

        <b-button v-show="numberQuestion == 1" variant="success" @click="numberQuestion = 2">Ok</b-button>

        <b-form-group v-show="numberQuestion == 2" class="mt-3" label="PRONO:">
          <b-container fluid class="p-4 bg-dark">
          <b-row>
            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Prone/DeitadoProno1.png" alt="Image 1" id="mainProps"></b-img> 
              <b-popover target="mainProps" triggers="hover" placement="righttop">
                <template #title>Deitado em Prono (1):</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre as bochechas, mãos, antebraços e tronco superior.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Cabeça rodada para um lado
                                   Flexão fisiológica
                                   Braços próximos ao corpo; cotovelos fletidos.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Cabeça roda para liberar as vias aéreas superiores da superfície de apoio.</p>
                <b>100% das crianças aos 0 meses ????</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected2_1" @change="checkPositions('2_1')" id="selected2_1" name="selected2_1">Deitado em Prono (1)</b-form-checkbox>  </font>
            </b-col>
            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Prone/DeitadoProno2.png" alt="Image 2" id="popover-target-2"></b-img>
              <b-popover target="popover-target-2" triggers="hover" placement="righttop">
                <template #title>Deitado em prono (2):</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre as mãos, antebraços e tronco.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Cotovelos atrás dos ombros e próximos do corpo
                                   Quadris e joelhos fletidos.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Eleva a cabeça assimetricamente a 45°
                                   Não pode manter a cabeça na linha média</p>
                <b>50% creditado com 15 dias; <br>
                   90% creditado com 2 meses</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected2_2" @change="checkPositions('2_2')" id="selected2_2" name="selected2_2">Deitado em Prono (2)</b-form-checkbox>  </font>
            </b-col>
            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Prone/PronoSuporte.png" alt="Image 3" id="popover-target-3"></b-img>
              <b-popover target="popover-target-3" triggers="hover" placement="righttop">
                <template #title>Prono com suporte:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre as mãos, antebraços e tronco.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Ombros ligeiramente abduzidos
                                   Cotovelos atrás dos ombros
                                   Quadril e joelhos flexionados.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Cabeça eleva-se a 45°
                                   Roda a cabeça</p>
                   <p align="justify">A criança é capaz de elevar a cabeça à 45° na linha média; não consegue manter-se nesta
                   posição indefinidamete (por muito tempo).</p>
                   <b>50% creditada com 1 mês e 10 dias <br>
                   90% creditada com 2 meses e 25 dias </b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected2_3" @change="checkPositions('2_3')" id="selected2_3" name="selected2_3">Prono Com Suporte</b-form-checkbox>  </font>
            </b-col>
            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Prone/MobilidadePromo.png" alt="Image 4" id="popover-target-4"></b-img>
              <b-popover target="popover-target-4" triggers="hover" placement="lefttop">
                <template #title>Suporte de Antebraço (1):</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso está distribuído simetricamente sobre os antebraços e tronco.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Ombros abduzidos
                                   Cotovelos alinhados com os ombros
                                   Coxofemorais abduzidas e rodadas externamente
                                   Joelhos flexionados. </p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Empurra-se contra a superfície para elevar a cabeça
                                   Eleva e mantém a cabeça acima de 45o
                                   Tronco elevado.</p>
                   <p align="justify">Para passar neste item os cotovelos não devem estar atrás dos ombros (podendo estar à frente
                   dos ombros). A criança pode brincar com os pés (um com o outro) nesta posição. A cabeça não
                   precisa ser mantida a 90°. A semiflexão ativa do pescoço (chin tuck) não está presente.</p>
                   <b>50% creditado com 2 meses e 15 dias<br>
                      90% creditada aos 3 meses e 20 dias</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected2_4" @change="checkPositions('2_4')" id="selected2_4" name="selected2_4">Suporte de Antebraço (1)</b-form-checkbox>  </font>
            </b-col>
          </b-row>

          <br>
          
          <b-row>
            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Prone/MobilidadePromo.png" alt="Image 5" id="popover-target-5"></b-img> 
              <b-popover target="popover-target-5" triggers="hover" placement="righttop">
                <template #title>Mobilidade em Prono:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre os antebraços, abdome e coxas.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Cabeça a 90°
                                   Suporte no antebraço ou suporte imaturo com extensão de braços
                                   Coxofemorais abduzidas.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Deslocamento de peso de um braço para outro; podendo haver ou
                                   não algum deslocamento de tronco (deslocamento látero-lateral).</p>
                <p align="justify">Este item representa as tentativas mais precoces para o deslocamento do peso em prono.
                                   Dica: Podem ser colocados brinquedos apropriadamente para a observação de movimentos
                                   antigravitacionais</p>                                   
                <b>50% creditada com 3 meses
                   90% creditada com 4 meses</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected2_5" @change="checkPositions('2_5')" id="selected2_5" name="selected2_5">Mobilidade em Prono</b-form-checkbox>  </font>
            </b-col>
            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Prone/ApoioAnteBracos2.png" alt="Image 6" id="popover-target-6"></b-img>
              <b-popover target="popover-target-6" triggers="hover" placement="righttop">
                <template #title>Apoio de Antebraço (2):</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre os antebraços, mãos e abdome.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Cotovelos à frente dos ombros
                                   Coxofemurais abduzidas e rodadas externamente.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Eleva e mantém a cabeça na linha média
                                   Semiflexão ativa do pescoço (chin tuck) e alongamento do pescoço
                                   Peito elevado.</p>
                <p align="justify">Os cotovelos devem estar à frente dos ombros para passar neste item. Os ombros podem estar
                                   tanto abduzidos como numa posição mais neutra. A criança irá freqüentemente estender e
                                   flexionar ativamente os joelhos nesta posição. Este item representa um controle de cabeça mais
                                   maduro do que o item anterior de suporte de antebraço.</p>                                   
                <b>50% creditado com 3 meses e 20 dias
                   90% creditado com 5 meses </b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected2_6" @change="checkPositions('2_6')" id="selected2_6" name="selected2_6">Apoio de Antebraço (2)</b-form-checkbox>  </font>
            </b-col>
            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Prone/SuporteBracosEstendidos.png" alt="Image 7" id="popover-target-7"></b-img>
              <b-popover target="popover-target-7" triggers="hover" placement="righttop">
                <template #title>Suporte com os braços estendidos:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre as mãos, abdômen inferior e coxas.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Braços estendidos
                                   Cotovelos à frente dos ombros
                                   Pernas aproximando-se da posição neutra. </p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Semiflexão ativa de pescoço (chin tuck) e peito elevado
                                   Flexão e extensão de joelhos; pode brincar com os pés um com o outro
                                   Deslocamento do peso lateralmente .</p>
                   <p align="justify">A criança também pode empurrar-se para trás nesta posição.</p>
                   <b>50% creditado com 4 meses e 10 dias<br>
                      90% creditado com 6 meses</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected2_7" @change="checkPositions('2_7')" id="selected2_7" name="selected2_7">Supor. Braços Estendidos</b-form-checkbox>  </font>
            </b-col>
            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Prone/RolarSemRotacao.png" alt="Image 8" id="popover-target-8"></b-img>
              <b-popover target="popover-target-8" triggers="hover" placement="lefttop">
                <template #title>Rolar de prono para supino sem rotação:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre um lado do corpo.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Ombro alinhado com a pelve
                                   Extensão de tronco.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Movimento inicia-se pela cabeça
                                   Rola de prono para supino sem rotação de tronco.</p>                                 
                <b>50% creditado com 6 meses
                   90% creditado com 8 meses e 15 dias</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected2_8" @change="checkPositions('2_8')" id="selected2_8" name="selected2_8">Rolar Prono Sem Rotação</b-form-checkbox>  </font>
            </b-col>
          </b-row>

          <br>

          <b-row>
            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Prone/NatacaoBorrao.png" alt="Image 9" id="popover-target-9"></b-img> 
              <b-popover target="popover-target-9" triggers="hover" placement="righttop">
                <template #title>Natação ou mata-borrão:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre o abdômen.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Simétrica
                                   Escápulas aduzidas
                                   Braços abduzidos, rodados externamente
                                   Pernas abduzidas e estendidas
                                   Coluna lombar estendida.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Eleva cabeça e braços ou pernas, ou ambos, da superfície
                                   Padrão extensor ativo.</p>
                <p align="justify">A criança pode se balançar para frente, para trás ou de um lado para o outro. Não há movimento
                                   do corpo para frente e algumas vezes a atividade extensora é vista apenas nos braços ou nas
                                   pernas. A extensão ativa do tronco deve sempre ser observada para o item ser creditado.</p>                                   
                <b>50% creditada aos 5 meses
                   90% creditada aos 8 meses </b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected2_9" @change="checkPositions('2_9')" id="selected2_9" name="selected2_9">Natação ou Mata-Borrão</b-form-checkbox>  </font>
            </b-col>
            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Prone/AlcanceAntebraco.png" alt="Image 10" id="popover-target-10"></b-img>
              <b-popover target="popover-target-10" triggers="hover" placement="righttop">
                <template #title>Alcance com apoio de antebraço:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre um antebraço, mão e abdômen.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Suporte de antebraço
                                   Pernas na posição neutra (joelhos na linha média).</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Deslocamento ativo de peso para um lado
                                   Alcance controlado com o braço livre.</p>
                   <p align="justify">Este item representa um alcance com controle; a criança não perde seu equilíbrio quando realiza o
                                      alcance com a mão e braços elevado e estendido.
                                      Dica: Colocar um objeto na linha média ou lateralmente para serem observados os movimentos
                                      antigravitacionais.</p>
                   <b>50% creditado com 5 meses<br>
                      90% creditado com 7 meses</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected2_10" @change="checkPositions('2_10')" id="selected2_10" name="selected2_10">Alcance Apoio Antebraço</b-form-checkbox>  </font>
            </b-col>
            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Prone/Pivoteio.png" alt="Image 11" id="popover-target-11"></b-img>
              <b-popover target="popover-target-11" triggers="hover" placement="righttop">
                <template #title>Pivoteio:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre o tronco, braços e mãos.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Cabeça elevada a 90o
                                   Pernas abduzidas e rodadas externamente. </p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Pivoteio
                                   Movimentos nos braços e pernas
                                   Flexão lateral de tronco.</p>
                   <p align="justify">Para passar neste item, a criança deve usar ambos os braços e pernas para pivotear
                                      Dica: coloque um brinquedo lateralmente para que a criança inicie o movimento.</p>
                   <b>50% creditado com 5 meses e 20 dias<br>
                      90% creditados com 8 meses e 5 dias</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected2_11" @change="checkPositions('2_11')" id="selected2_11" name="selected2_11">Pivoteio</b-form-checkbox>  </font>
            </b-col>
            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Prone/RolandoComRotacao.png" alt="Image 12" id="popover-target-12"></b-img>
              <b-popover target="popover-target-12" triggers="hover" placement="lefttop">
                <template #title>Rolando de prono para supino com rotação:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre um lado do corpo.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Os ombros não estão alinhados com a pelve
                                   Rotação do tronco. </p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Movimento iniciado pelo ombro, pelve ou cabeça
                                   Rotação de tronco.</p>
                   <b>50% creditado com 7 meses <br>
                      90% creditado com 9 meses e 10 dias</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected2_12" @change="checkPositions('2_12')" id="selected2_12" name="selected2_12">Rolando Prono Rotação</b-form-checkbox>  </font>
            </b-col>
          </b-row>

          <br>

          <b-row>
            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Prone/Ajoelhado4Apoios.png" alt="Image 13" id="popover-target-13"></b-img> 
              <b-popover target="popover-target-13" triggers="hover" placement="righttop">
                <template #title>Ajoelhado em quatro apoios (1):</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre mãos e joelhos.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Pernas flexionadas, abduzidas e rodadas externamente
                                   Lordose lombar.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Mantém-se na posição
                                   Pode balançar-se para trás e para frente ou diagonalmente
                                   Quando tenta impulsionar-se para frente, ele desaba.</p>
                <p align="justify">Este item é caracterizado pela postura imatura de abdução de quadril. Os ombros podem estar
                                   internamente rodados ou numa posição neutra. Não é necessário que o bebê seja observado
                                   balançando-se para passar neste item.</p>                                   
                <b>50% creditado com 7 meses e 10 dias
                   90% creditado com 9 meses </b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected2_13" @change="checkPositions('2_13')" id="selected2_13" name="selected2_13">Ajoelhado Quatro Apoios</b-form-checkbox>  </font>
            </b-col>
            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Prone/DeitadoComApoio.png" alt="Image 14" id="popover-target-14"></b-img>
              <b-popover target="popover-target-14" triggers="hover" placement="righttop">
                <template #title>Deitado de lado com apoio:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre cotovelo, antebraço, perna e um lado do tronco.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Endireitamento lateral de cabeça
                                   Flexão lateral do tronco
                                   Perna superior flexionada e aduzida ou abduzida.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Dissociação de pernas
                                   Estabilidade do ombro
                                   Utiliza o braço superior para realizar alcance
                                   Rotação sobre o eixo corporal.</p>
                   <p align="justify">A postura da perna que está acima pode ser alterada de abdução do quadril para adução; as
                                      características importantes são estabilidade de ombro e pelo menos uma dissociação parcial de
                                      uma perna em relação à outra. A criança pode ficar nesta posição apenas momentaneamente e
                                      mover-se nesta posição ou sair dela frequentemente.</p>
                   <b>50% creditado com 7 meses e 5 dias<br>
                      90% creditado com 9 meses e 5 dias</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected2_14" @change="checkPositions('2_14')" id="selected2_14" name="selected2_14">Deitado Lado Com Apoio</b-form-checkbox>  </font>
            </b-col>
            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Prone/ArrastarReciproco.png" alt="Image 15" id="popover-target-15"></b-img>
              <b-popover target="popover-target-15" triggers="hover" placement="righttop">
                <template #title>Arrastar recíproco:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre braço e perna opostos.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Flexão de uma articulação coxofemoral, extensão do lado oposto
                                   Flexão de braço
                                   Cabeça a 90o
                                   Rotação de tronco.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Movimentos recíprocos de braços e pernas com rotação de tronco.</p>
                <p align="justify">Movimentos de braços e pernas devem ser observados.</p>
                <b>50% creditado com 7 meses e 15 dias<br>
                   90% creditado com 9 meses e 5 dias</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected2_15" @change="checkPositions('2_15')" id="selected2_15" name="selected2_15">Arrastar Recíproco</b-form-checkbox>  </font>
            </b-col>
            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Prone/JoelhoSemiSentado.png" alt="Image 16" id="popover-target-16"></b-img>
              <b-popover target="popover-target-16" triggers="hover" placement="lefttop">
                <template #title>De ajoelhado em quatro pontos para sentado ou semi-sentado:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre as mãos, perna e pé de um lado do corpo e peso no pé do lado oposto.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Perna que sustenta o peso está flexionada e rodada externamente Braços abduzidos.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Delocamento do peso com alongamento do tronco do lado que
                                   suporta o peso
                                   Brinca de ficar e sair da posição
                                   Pode passar para sentado.</p>
                <p align="justify">A criança não precisa alcançar a posição sentada para passar neste item; a posição intermediária
                                   pode ser seguida do ajoelhado em 4 pontos. O movimento controlado da pelve está presente </p>
                <b>50% creditado com 7 meses e 25 dias<br>
                   90% creditado com 9 meses e 25 dias</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected2_16" @change="checkPositions('2_16')" id="selected2_16" name="selected2_16">Ajoelhado Quatro Pontos</b-form-checkbox>  </font>
            </b-col>
          </b-row>

          <br>

          <b-row>
            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Prone/EngatinharReciproco1.png" alt="Image 17" id="popover-target-17"></b-img> 
              <b-popover target="popover-target-17" triggers="hover" placement="righttop">
                <template #title>Engatinhar recíproco (1):</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre a mão e joelho opostos.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Braços abduzidos
                                   Pernas abduzidas e rodadas externamente
                                   Lordose lombar.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Deslocamento de peso de um lado para o outro com flexão lateral de tronco
                                   Movimentos recíprocos de braços e pernas.</p>
                <p align="justify">Este é um padrão de engatinhar inicial caracterizado por uma postura imatura das pernas e pela
                                   falta de rotação do tronco. A criança deve mover-se para frente para passar neste item.</p>                                   
                <b>50% creditado com 8 meses e meio
                   90% creditado com 13 meses </b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected2_17" @change="checkPositions('2_17')" id="selected2_17" name="selected2_17">Engatinhar Recíproco (1)</b-form-checkbox>  </font>
            </b-col>
            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Prone/AlcanceExtensao.png" alt="Image 18" id="popover-target-18"></b-img>
              <b-popover target="popover-target-18" triggers="hover" placement="righttop">
                <template #title>Alcance na postura de gatas com suporte de braço em extensão:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre joelhos e uma das mãos.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Ajoelhado em quatro pontos modificado com uma mão fora da
                                   superfície de apoio
                                   Descarga de peso com o braço em extensão.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Alcance com o braço estendido
                                   Rotação da cabeça, ombros e tronco
                                   O braço que suporta o peso pode apresentar uma pequena flexão.</p>
                   <p align="justify">Dica: Podem ser colocados brinquedos apropriados para a observação de movimentos
                                      antigravitacionais.</p>
                   <b>50% creditado com 8 meses e 5 dias<br>
                      90% creditado com 10 meses</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected2_18" @change="checkPositions('2_18')" id="selected2_18" name="selected2_18">Alcance Postura Gatas</b-form-checkbox>  </font>
            </b-col>
            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Prone/Ajoelhado4Pontos.png" alt="Image 19" id="popover-target-19"></b-img>
              <b-popover target="popover-target-19" triggers="hover" placement="righttop">
                <template #title>Ajoelhado em quatro pontos (2):</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre mãos e joelhos.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Pernas flexionadas, coxofemorais alinhadas sob a pelve
                                   Retificação da coluna lombar.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Músculos abdominais ativos
                                   Balança-se para trás, para frente e diagonalmente
                                   Pode impulsionar-se para frente.</p>
                <p align="justify">Este item é caracterizado pela postura madura das coxofemorais alinhadas sob a pelve. Para a
                                   criança passar neste item deve balançar-se ou engatinhar para frente.</p>
                <b>50% creditado com 8 meses e 10 dias <br>
                   90% creditado com 11 meses</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected2_19" @change="checkPositions('2_19')" id="selected2_19" name="selected2_19">Ajoelhado Quatro Pontos</b-form-checkbox>  </font>
            </b-col>
            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Prone/AjoelhadoModificado.png" alt="Image 20" id="popover-target-20"></b-img>
              <b-popover target="popover-target-20" triggers="hover" placement="lefttop">
                <template #title>Ajoelhado em quatro apoios modificado:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre as mãos, um joelho e pé oposto ao joelho que faz o apoio.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Posição quadrúpede modificada; uma perna flexionada na
                                   articulação coxofemoral de forma que a planta do pé apoie-se na superfície.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Brinca na posição
                                   Pode mover-se para frente.</p>
                <p align="justify">A articulação coxofemoral pode estar alinhada sob a pelve ou rodada externamente. Este não é
                                   movimento transitório para a postura em pé independente. Ela representa uma variação da
                                   posição ajoelhado em quatro apoios</p>
                <b>50% creditado com 8 meses e 20 dias<br>
                   90% creditado com 11 meses e 20 dias</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected2_20" @change="checkPositions('2_20')" id="selected2_20" name="selected2_20">Ajoelhado Quatro Apoios</b-form-checkbox>  </font>
            </b-col>
          </b-row>

          <br>

         <b-row>
          <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Prone/EngatinlharReciproco2.png" alt="Image 21" id="popover-target-21"></b-img>
              <b-popover target="popover-target-21" triggers="hover" placement="righttop">
                <template #title>Engatinhar Recíproco (2):</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre a mão e joelho opostos.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Cotovelos e joelhos alinhados sob os ombros e quadris (?)
                                   Retificação da coluna lombar.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Movimentos recíprocos de braços e pernas com rotação de tronco.</p>
                <p align="justify">Este é um padrão maduro de engatinhar caraterizado pela postura madura das pernas e pela
                                   rotação de tronco. A lordose lombar não está presente</p>
                <b>50% creditado com 8 meses e 20 dias<br>
                   90% creditado com 11 meses e 5 dias</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected2_21" @change="checkPositions('2_21')" id="selected2_21" name="selected2_21">Engatinhar Recíproco (2)</b-form-checkbox>  </font>
           </b-col>
           <b-col></b-col>
           <b-col></b-col>
           <b-col></b-col>
         </b-row>
        </b-container>
        </b-form-group>

        <b-button v-show="numberQuestion == 2" variant="success" @click="numberQuestion = 3">Ok</b-button>

        <b-form-group v-show="numberQuestion == 3" class="mt-3" label="SUPINO:">
          <b-container fluid class="p-4 bg-dark">
          <b-row>
            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Supina/DeitadoSupino1.png" alt="Image 22" id="popover-target-22"></b-img> 
              <b-popover target="popover-target-22" triggers="hover" placement="righttop">
                <template #title>Deitado em supino (1):</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre a face, lado da cabeça e tronco.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Cabeça rodada para um lado
                                   Flexão fisiológica.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Rotação de cabeça
                                   Mão na boca
                                   Movimentos randômicos de braços e pernas (espreguiçamento).</p>
                <p align="justify">A criança pode mover-se fora da postura em flexão mas retorna à flexão como uma postura de
                                  repouso</p>
                <b>100% creditado com 0 meses</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected3_1" @change="checkPositions('3_1')" id="selected3_1" name="selected3_1">Deitado em Supino (1)</b-form-checkbox>  </font>
            </b-col>

            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Supina/DeitadoSupino2.png" alt="Image 23" id="popover-target-23"></b-img> 
              <b-popover target="popover-target-23" triggers="hover" placement="righttop">
                <template #title>Deitado em supino (2):</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobe o lado da cabeça, tronco e nádegas.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Diminuição da flexão fisiológica
                                   Cabeça rodada para um lado
                                   Coxofemorais abduzidas e rodadas externamente
                                   Mãos abertas ou fechadas.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Rotação da cabeça em direção à linha média
                                   Movimentos randômicos de braços e pernas
                                   Não é obrigatória a presença do reflexo tônico cervical assimétrico.</p>
                <p align="justify">A criança pode mover a cabeça em direção à linha média mas não pode mantê-la nesta posição
                                   Dica: Podem ser usados estímulos visuais para a rotação da cabeça</p>
                <b>90% creditado com 20 dias</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected3_2" @change="checkPositions('3_2')" id="selected3_2" name="selected3_2">Deitado em Supino (2)</b-form-checkbox>  </font>
            </b-col>

            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Supina/DeitadoSupino3.png" alt="Image 24" id="popover-target-24"></b-img> 
              <b-popover target="popover-target-24" triggers="hover" placement="righttop">
                <template #title>Deitado em supino (3):</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso distribuído simetricamente sobre a cabeça, tronco e nádegas.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Cabeça na linha média
                                   Braços flexionados e abduzidos ou posicionados ao lado do corpo
                                   Pernas flexionadas ou estendidas.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Chute bilateral ou recíproco
                                   Move os braços mas é incapaz de trazer as mãos à linha média</p>
                <p align="justify">A postura das pernas pode variar entre flexão e extensão. A criança ainda move os braços ao
                                   lado do corpo mais do que brinca com eles na linha média. </p>
                <b>50% creditado com 25 dias<br>
                   90% creditado com 1 mês e 25 dias</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected3_3" @change="checkPositions('3_3')" id="selected3_3" name="selected3_3">Deitado em Supino (3)</b-form-checkbox>  </font>
            </b-col>

            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Supina/DeitadoSupino4.png" alt="Image 25" id="popover-target-25"></b-img> 
              <b-popover target="popover-target-25" triggers="hover" placement="lefttop">
                <template #title>Deitado em supino (4):</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso simetricamente distribuído sobre a cabeça, tronco e nádegas.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Cabeça na linha média com semi-flexão ativa do pescoço (chintuck)
                                   Braços em repouso sobre o peito
                                   Pernas flexionadas ou estendidas.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Flexores do pescoço ativos – “chin tuck”
                                   Traz mãos na linha média
                                   Chutes bilaterais ou recíprocos.</p>
                <p align="justify">A criança facilmente é capaz de trazer as mãos juntas na linha média mas não precisa fazer
                                   preensão com sucesso de um brinquedo para passar neste item</p>
                <p align="justify">Dica: Pode ser usado um brinquedo para observar a progressão das mãos na linha média</p>
                <b>50% creditado com 2 meses e 15 dias<br>
                   90% creditado com 3 meses e 25 dias</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected3_4" @change="checkPositions('3_4')" id="selected3_4" name="selected3_4">Deitado em Supino (4)</b-form-checkbox>  </font>
            </b-col>
          </b-row>

          <br>

          <b-row>
            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Supina/MaosJoelhos.png" alt="Image 26" id="popover-target-26"></b-img> 
              <b-popover target="popover-target-26" triggers="hover" placement="righttop">
                <template #title>Mãos nos joelhos:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso simetricamente distribuído sobre a cabeça, tronco e pelve.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Coxofemorais abduzidas e rodadas externamente
                                   Joelhos flexionados.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Cabeça roda facilmente de um lado para o outro
                                   Semi-flexão ativa do pescoço
                                   Leva uma mão ou ambas aos joelhos
                                   Músculos abdominais estão ativos
                                   Pode desequilibrar-se lateralmente pelo deslocamento das pernas.</p>
                <p align="justify">É importante observar a atividade dos músculos abdominais. Se as pernas estão muito abduzidas
                                   e em repouso sobre o abdômen passivamente, a criança não passará neste item. Crianças
                                   hipotônicas freqüentemente exibem esta posição passiva</p>
                <b>50% creditado com 3 meses e 15 dias<br>
                   90% creditado com 5 meses</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected3_5" @change="checkPositions('3_5')" id="selected3_5" name="selected3_5">Mãos nos Joelhos</b-form-checkbox>  </font>
            </b-col>

            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Supina/ExtensaoAtiva.png" alt="Image 27" id="popover-target-27"></b-img> 
              <b-popover target="popover-target-27" triggers="hover" placement="righttop">
                <template #title>Extensão ativa:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre um lado do corpo.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Hiper-extensão de pescoço e coluna.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Ombros protraídos
                                   Empurra-se em extensão com uma ou ambas as pernas
                                   Pode rolar para o lado acidentalmente.</p>
                <p align="justify">Durante este movimento, uma nádega usualmente permanece sobre a superfície de suporte.Este
                                   é um movimento que a criança gosta de realizar, distinguindo-se do “arqueamento” das crianças hipertônicas</p>
                <b>50% creditado com 3 meses e 20 dias<br>
                   90% creditado com 5 meses e 15 dias</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected3_6" @change="checkPositions('3_6')" id="selected3_6" name="selected3_6">Extensão Ativa</b-form-checkbox>  </font>
            </b-col>

            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Supina/MaosPes.png" alt="Image 28" id="popover-target-28"></b-img> 
              <b-popover target="popover-target-28" triggers="hover" placement="righttop">
                <template #title>Mãos nos pés:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre cabeça e tronco.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Mãos em contato com um ou ambos os pés
                                   Coxofemorais flexionadas mais que 90°
                                   Joelhos semi-fletidos ou estendidos.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Semi-flexão do pescoço “Chin tuck”
                                   Eleva as pernas e traz os pés até as mãos
                                   Pode manter as coxas sobre o abdômen com os joelhos em semiflexão
                                   Mobilidade pélvica presente
                                   Balança de um lado para o outro; pode rolar para o lado.</p>
                <b>50% creditado com 4 meses e 15 dias<br>
                   90% creditado com 5 meses e 25 dias</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected3_7" @change="checkPositions('3_7')" id="selected3_7" name="selected3_7">Mãos nos Pés</b-form-checkbox>  </font>
            </b-col>

            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Supina/RolandoSemProtecao.png" alt="Image 29" id="popover-target-29"></b-img> 
              <b-popover target="popover-target-29" triggers="hover" placement="lefttop">
                <template #title>Rolando de supino para prono sem rotação:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre um lado do corpo.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Cabeça elevada
                                   Tronco alongado no lado de suporte de peso
                                   Ombro alinhado com a pelve.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Endireitamento lateral de cabeça
                                   Rolar inicia-se a partir da cabeça, ombro ou quadril
                                   Tronco move-se como um todo.</p>
                <b>50% creditado com 5 meses e 15 dias<br>
                   90% creditado com 8 meses e 25 dias</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected3_8" @change="checkPositions('3_8')" id="selected3_8" name="selected3_8">Supino Sem Rotação</b-form-checkbox>  </font>
            </b-col>
          </b-row>

          <br>

          <b-row>
           <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Supina/RolandoComRotacao.png" alt="Image 30" id="popover-target-30"></b-img> 
              <b-popover target="popover-target-30" triggers="hover" placement="righttop">
                <template #title>Rolando de supino para prono com rotação:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre um lado do corpo.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Cabeça elevada
                                   Tronco alongado sobre o lado do suporte de peso
                                   Ombro e pelve não alinhados.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Endireitamento lateral de cabeça
                                   Movimentos dissociados nas pernas
                                   Rolar inicia-se pela cabeça, ombro ou quadril
                                   Rotação de tronco.</p>
                <b>50% creditado com 6 meses e 20 dias<br>
                   90% creditado com 8 meses e 25 dias</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected3_9" @change="checkPositions('3_9')" id="selected3_9" name="selected3_9">Supino Com Rotação</b-form-checkbox>  </font>
            </b-col>
            <b-col></b-col><b-col></b-col><b-col></b-col>
          </b-row>

          </b-container>
        </b-form-group>

        <b-button v-show="numberQuestion == 3" variant="success" @click="numberQuestion = 4">Ok</b-button>

        <b-form-group v-show="numberQuestion == 4" class="mt-3" label="SENTADA:">
          <b-container fluid class="p-4 bg-dark">
          <b-row>
            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Sentada/SentadoComApoio.png" alt="Image 31" id="popover-target-31"></b-img> 
              <b-popover target="popover-target-31" triggers="hover" placement="righttop">
                <template #title>Sentado com apoio:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre nádegas e pernas.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Flexão de quadril
                                   Flexão de tronco.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Eleva e mantém brevemente a cabeça na linha média brevemente
                                   Extensão da coluna cervical superior.</p>
                <p align="justify">Para passar neste item a criança deve manter a cabeça na linha média brevemente. Deve haver
                                   mais controle de cabeça do que oscilação, mas a cabeça não precisa ser mantida na linha média indefinidamente
                                   Dica: A criança é mantida pelo examinador pelo tronco superior</p>
                <b>90% creditado com 25 dias</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected4_1" @change="checkPositions('4_1')" id="selected4_1" name="selected4_1">Sentado com Apoio</b-form-checkbox>  </font>
            </b-col>

            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Sentada/SentadoComApoioBracos.png" alt="Image 32" id="popover-target-32"></b-img> 
              <b-popover target="popover-target-32" triggers="hover" placement="righttop">
                <template #title>Sentado com apoio de braços:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre nádegas, pernas e mãos.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Cabeça elevada; ombros elevados
                                   Quadris flexionados, rodados externamente e abduzidos
                                   Joelhos flexionados
                                   Coluna lombar e torácica cifótica.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Mantém cabeça na linha média
                                   Suporta o peso sobre os braços brevemente.</p>
                <p align="justify">Dica: O examinador coloca a criança na posição sentada. Para passar este item, a criança deve
                                   manter a posição independentemente, isto é, sem o auxílio do examinador</p>
                <b>50% creditado com 2 meses e 15 dias<br>
                   90% creditado com 4 meses e meio</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected4_2" @change="checkPositions('4_2')" id="selected4_2" name="selected4_2">Sentado Apoio Braços</b-form-checkbox>  </font>
            </b-col>

            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Sentada/TracionadoSemSentar.png" alt="Image 33" id="popover-target-33"></b-img> 
              <b-popover target="popover-target-33" triggers="hover" placement="righttop">
                <template #title>Tracionado para sentar:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre as nádegas e coluna lombar.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Braços flexionados
                                   Quadril e joelhos flexionados
                                   Pés podem estar fora da superfície.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Semiflexão do pescoço (chin tucked); cabeça na linha média ou à
                                   frente do corpo
                                   O movimento pode ser auxiliado com os músculos abdominais e
                                   flexão de braços.</p>
                <p align="justify">Ao início do movimento pode haver um ligeiro atraso de cabeça, que a criança rapidamente
                                   supera. Podem haver graus variados de flexão nas pernas a medida que a criança realiza o movimento.
                                   Dica: O examinador puxa a criança para sentar segurando-a pelos punhos </p>
                <b>50% creditado com 3 meses e 10 dias<br>
                   90% creditado com 4 meses e 25 dias</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected4_3" @change="checkPositions('4_3')" id="selected4_3" name="selected4_3">Tracionado para Sentar</b-form-checkbox>  </font>
            </b-col>

            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Sentada/SentadoSemApoio.png" alt="Image 34" id="popover-target-34"></b-img> 
              <b-popover target="popover-target-34" triggers="hover" placement="lefttop">
                <template #title>Sentado sem apoio:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre as nádegas e pernas.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Cabeça na linha média
                                   Ombros à frente dos quadris
                                   Coluna torácica estendida
                                   Flexão lombar
                                   Quadris flexionados e rodados externamente.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Extensão de cabeça
                                   Adução escapular e extensão de úmero
                                   Não pode manter a posição por muito tempo</p>
                <p align="justify">Dica: O examinador coloca a criança na posição sentada. Para passar neste item, a criança deve
                                   manter a posição brevemente e não cair imediatamente</p>
                <b>50% creditado com 4 meses e 5 dias<br>
                   90% creditado com 6 meses</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected4_4" @change="checkPositions('4_4')" id="selected4_4" name="selected4_4">Sentado sem Apoio</b-form-checkbox>  </font>
            </b-col>
          </b-row>

          <br>

          <b-row>
            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Sentada/SentadoApoioBraco.png" alt="Image 35" id="popover-target-35"></b-img> 
              <b-popover target="popover-target-35" triggers="hover" placement="righttop">
                <template #title>Sentado com apoio de braço:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre as nádegas, pernas e mãos.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Cabeça elevada
                                   Coluna lombar cifótica, coluna torácica estendida
                                   Apoio sobre os braços estendidos
                                   Quadris flexionados, rodados externamente e abduzidos
                                   Joelhos flexionados.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Cabeça movimenta-se dissociada do tronco
                                   Apoio sobre os braços em extensão
                                   Não pode mover-se dentro e fora da posição.</p>
                <p align="justify">Dica: O examinador coloca a criança na posição sentada</p>
                <b>50% creditado com 4 meses e 15 dias<br>
                   90% creditado com 6 meses</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected4_5" @change="checkPositions('4_5')" id="selected4_5" name="selected4_5">Sentado com Apoio Braço</b-form-checkbox>  </font>
            </b-col>

            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Sentada/SentadoSemSuporteBraco.png" alt="Image 36" id="popover-target-36"></b-img> 
              <b-popover target="popover-target-36" triggers="hover" placement="righttop">
                <template #title>Sentado sem apoio e sem suporte de braço:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre nádegas e pernas.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Cotovelos flexionados
                                   Coluna torácica estendida
                                   Coxofemorais flexionadas, rodadas externamente e abduzidas com
                                   base de suporte alargada
                                   Joelhos flexionados</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Não pode se manter sozinho na posição sentada por muito tempo</p>
                <p align="justify">Para passar este item, a criança deve ser capaz de manter-se sentada sozinha por um breve
                                   período mas ainda pode necessitar de supervisão
                                   Dica: O examinador coloca a criança na posição sentada</p>
                <b>50% creditado com 5 meses e 5 dias<br>
                   90% creditado com 6 meses e 25 dias</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected4_6" @change="checkPositions('4_6')" id="selected4_6" name="selected4_6">Sentado Apoio Suporte</b-form-checkbox>  </font>
            </b-col>

            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Sentada/TransferenciaSemApoio.png" alt="Image 37" id="popover-target-37"></b-img> 
              <b-popover target="popover-target-37" triggers="hover" placement="righttop">
                <template #title>Transferência de peso na posição sentada sem apoio:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre nádegas e pernas.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Coxofemorais flexionadas, abduzidas e rodadas externamente
                                   Braços livres.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Transferência de peso para frente, para trás ou para os lados
                                   Início da retificação da coluna
                                   Não pode ser deixado sozinho na posição sentada.</p>
                <p align="justify">Este item representa um estágio do sentar no qual a criança perde o equilíbrio facilmente,
                                   especialmente quando experimenta a transferência de peso.
                                   Dica: O examinador coloca a criança na posição sentada. Pode usar brinquedos para eliciar a
                                   transferência de peso.</p>
                <b>50% creditado com 5 meses e 15 dias<br>
                   90% creditado com 7 meses e 20 dias</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected4_7" @change="checkPositions('4_7')" id="selected4_7" name="selected4_7">Transferência de Peso</b-form-checkbox>  </font>
            </b-col>

            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Sentada/SentadoTronco.png" alt="Image 38" id="popover-target-38"></b-img> 
              <b-popover target="popover-target-38" triggers="hover" placement="lefttop">
                <template #title>Sentado sem apoio de braços (1):</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre as nádegas e pernas.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Ombros alinhados com o quadril
                                   Braços livres
                                   Base de suporte alargada.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Braços movem-se longe do corpo
                                   Pode brincar com um brinquedo
                                   Pode ser deixado sozinho na posição sentada.</p>
                <p align="justify">Para passar este item, a criança deve ser capaz de manter-se bem sentada. O cuidador mantémse tranqüilo ao deixar a criança na posição sentada. Não é necessário a presença de rotação no
                                   eixo do tronco para a criança passar neste item</p>
                <b>50% creditado com 5 meses e 10 dias<br>
                   90% creditado com 7 meses e 25 dias</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected4_8" @change="checkPositions('4_8')" id="selected4_8" name="selected4_8">Sentado S/ Apoio Braços</b-form-checkbox>  </font>
            </b-col>
          </b-row>

          <br>

          <b-row>
           <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Sentada/SentadoTronco.png" alt="Image 39" id="popover-target-39"></b-img> 
              <b-popover target="popover-target-39" triggers="hover" placement="righttop">
                <template #title>Sentado, realiza alcance com rotação de tronco:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre as nádegas e pernas.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Tronco rodado
                                   Alongamento do tronco no lado do alcance.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Senta independentemente
                                   Alcança um brinquedo com rotação de tronco.</p>
                <p align="justify">Para passar este item, a criança deve ser capaz de alcançar facilmente um brinquedo e deve ser
                                   observada a rotação do tronco. A criança pode alcançar em qualquer direção na qual a rotação
                                   de tronco é observada.
                                   Dica: O examinador coloca a criança na posição sentada. Pode usar brinquedos para encorajar o alcance</p>
                <b>50% creditado aos 7 meses e 25 dias<br>
                   90% creditado aos 8 meses e 5 dias</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected4_9" @change="checkPositions('4_9')" id="selected4_9" name="selected4_9">Sentado, Realiza Alcance</b-form-checkbox>  </font>
            </b-col>

            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Sentada/PassarProno.png" alt="Image 40" id="popover-target-40"></b-img> 
              <b-popover target="popover-target-40" triggers="hover" placement="righttop">
                <template #title>Passar de sentado para prono:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre as mãos, antebraços e tronco.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Tronco flexionado anteriormente ou lateralmente sobre os membros inferiores
                                   Pernas flexionadas, abduzidas e rodadas externamente.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Move-se da posição sentada para alcançar a posição de prono
                                   Puxa-se com os braços, pernas inativas</p>
                <p align="justify">Para passar este item, a criança deve ser capaz de manter-se sentada com ou sem suporte de
                                   braço. A criança pode ou não usar a rotação de tronco para alcançar a posição prona. Este item é
                                   freqüentemente observado assim que a criança tenta sair da posição sentada. O item pode ser
                                   creditado mesmo que ele seja realizado de modo imaturo
                                   Dica: O examinador pode colocar a criança na posição sentada</p>
                <b>50% creditado com 8 meses e 5 dias<br>
                   90% creditado com 12 meses e 5 dias</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected4_10" @change="checkPositions('4_10')" id="selected4_10" name="selected4_10">Passar de Sentado</b-form-checkbox>  </font>
            </b-col>

            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Sentada/SentadoApoios.png" alt="Image 41" id="popover-target-41"></b-img> 
              <b-popover target="popover-target-41" triggers="hover" placement="righttop">
                <template #title>Sentado para ajoelhado em quatro apoios:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre ambas as mãos e um pé.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Move-se de sentado sem apoio para ajoelhado em quatro apoios.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Elevação ativa da pelve, nádegas e perna sem o suporte de peso
                                   para assumir a postura de ajoelhado em quatro apoios).</p>
                <p align="justify">Para passar este item a criança deve ser capaz de manter-se sentada sem apoio de braços.
                                   Podem ser observadas inúmeras maneiras da criança assumir a postura de quatro apoios; o
                                   elemento crítico é a ocorrência de um movimento controlado e a elevação da pelve – a criança
                                   não pode “desabar” para a posição prona. </p>
                <b>50% creditado com 7 meses e 25 dias<br>
                   90% creditado com 9 meses e 20 dias</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected4_11" @change="checkPositions('4_11')" id="selected4_11" name="selected4_11">Sentado para Ajoelhado</b-form-checkbox>  </font>
            </b-col>

            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Sentada/SentadoBracos2.png" alt="Image 42" id="popover-target-42"></b-img> 
              <b-popover target="popover-target-42" triggers="hover" placement="lefttop">
                <template #title>Sentado sem apoio de braços (2):</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre as nádegas.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Variedade de posturas com dissociação de pernas
                                   Base de suporte estreita.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">A posição das pernas varia
                                   A criança move-se dentro e fora da posição facilmente.</p>
                <p align="justify">Este item pode ser creditado se observarmos uma variedade de posturas sentadas; estas
                                   incluem a postura em “W” e o sentado de lado. É importante estar certo de que a criança tem
                                   mais do que uma postura sentada em seu repertório de movimento. A criança deve assumir a
                                   posição independentemente.</p>
                <b>50% creditado com 8 meses e 20 dias<br>
                   90% creditado com 11 meses e 5 dias</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected4_12" @change="checkPositions('4_12')" id="selected4_12" name="selected4_12">Sentado S/ Apoio Braços</b-form-checkbox>  </font>
            </b-col>
          </b-row>

          </b-container>
        </b-form-group>

        <b-button v-show="numberQuestion == 4" variant="success" @click="numberQuestion = 5">Ok</b-button>

        <b-form-group v-show="numberQuestion == 5" class="mt-3" label="ORTOSTÁTICA:">
          <b-container fluid class="p-4 bg-dark">
          <b-row>
            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Ortostatica/PeApoio1.png" alt="Image 43" id="popover-target-43"></b-img> 
              <b-popover target="popover-target-43" triggers="hover" placement="righttop">
                <template #title>Em pé com apoio (1):</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Sustenta o peso intermitentemente.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Cabeça flexionada para frente
                                   Quadril atrás dos ombros
                                   Quadril e joelhos flexionados
                                   Pés podem estar próximos
                                   Criança não escorrega entre as mãos do examinador.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Pode haver flexão intermitente de joelhos e quadril.</p>
                <p align="justify">Dica: Segurar a criança pelas axilas</p>
                <b>90% creditado com 1 mês</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected5_1" @change="checkPositions('5_1')" id="selected5_1" name="selected5_1">Em Pé com Apoio (1)</b-form-checkbox>  </font>
            </b-col>

            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Ortostatica/PeApoio2.png" alt="Image 44" id="popover-target-44"></b-img> 
              <b-popover target="popover-target-44" triggers="hover" placement="righttop">
                <template #title>Em pé com apoio (2):</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre os pés ou artelhos.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Cabeça alinhada com o corpo
                                   Quadril atrás dos ombros
                                   Quadril flexionado e abduzido.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Movimentos variados das pernas
                                   Os joelhos podem fletir-se (desabar) e estender-se
                                   Pode hiper estender os joelhos
                                   Pode bater com um pé na superfície .</p>
                <p align="justify">Os movimentos antigravitacionais observados nas pernas são extremamente variáveis. Alguma
                                   flexão de pernas é observada na postura em repouso
                                   Dica: A criança é apoiada pelo examinador sob as axilas</p>
                <b>50% creditado com 1 mês<br>
                   90% creditado com 3 meses</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected5_2" @change="checkPositions('5_2')" id="selected5_2" name="selected5_2">Em Pé com Apoio (2)</b-form-checkbox>  </font>
            </b-col>

            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Ortostatica/PeApoio3.png" alt="Image 45" id="popover-target-45"></b-img> 
              <b-popover target="popover-target-45" triggers="hover" placement="righttop">
                <template #title>Em pé com apoio (3):</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre os pés.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Cabeça na linha média
                                   Quadril alinhado com os ombros
                                   Quadril abduzido e rodado externamente.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Controle ativo de tronco
                                   Movimentos variáveis das pernas; pode impulsionar-se para cima e
                                   para baixo, deslocar uma perna ou hiperestender os joelhos.</p>
                <p align="justify">Os movimentos antigravitacionais são extremamente variáveis. Para passar este item, a criança
                                   deve apoiar os calcanhares em algum momento da observação e demonstrar movimentos
                                   espontâneos das pernas
                                   Dica: O examinador apóia a criança pelo peito</p>
                <b>50% creditado com 4 meses e meio<br>
                   90% creditado com 7 meses e meio</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected5_3" @change="checkPositions('5_3')" id="selected5_3" name="selected5_3">Em Pé com Apoio (3)</b-form-checkbox>  </font>
            </b-col>

            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Ortostatica/TracionarPeApoio.png" alt="Image 46" id="popover-target-46"></b-img> 
              <b-popover target="popover-target-46" triggers="hover" placement="lefttop">
                <template #title>Tracionar-se para se manter em pé com apoio:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre os braços e pés.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Braços sobre a superfície de apoio
                                   Coxofemorais abduzidas e rodadas externamente
                                   Inclina-se sobre o suporte
                                   Lordose lombar.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Empurra-se para baixo com os braços e estende os joelhos para
                                   alcançar a postura em pé. </p>
                <p align="justify">As pernas não têm que estar completamente simétricas durante esta manobra e a criança pode
                                   empurrar com as pernas para assumir a posição. A postura dos pés é variável; o suporte de peso
                                   pode ser observado sobre os artelhos ou na borda medial dos pés
                                   Dica: Podem ser usados brinquedos para encorajar a criança a alcançar a postura em pé. Não
                                   colocar na postura em pé.</p>
                <b>50% creditado com 8 meses<br>
                   90% creditado com 9 meses e 3 semanas</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected5_4" @change="checkPositions('5_4')" id="selected5_4" name="selected5_4">Tracionar Pé com Apoio</b-form-checkbox>  </font>
            </b-col>
          </b-row>

          <br>

          <b-row>
            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Ortostatica/TracionarPe.png" alt="Image 47" id="popover-target-47"></b-img> 
              <b-popover target="popover-target-47" triggers="hover" placement="righttop">
                <template #title>Tracionar-se para ficar em pé/ Em pé:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre os pés
                                   Algum apoio de braços.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Quadril flexionado, abduzido e rodado externamente
                                   Lordose lombar
                                   Base alargada.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Puxa-se para em pé
                                   Transfere peso de um lado para outro
                                   Pode levantar uma perna, retirando-a da superfície de apoio
                                   Não há rotação de tronco.</p>
                <p align="justify">O examinador deve observar a criança assumir independentemente a postura em pé. A criança
                                   pode tracionar-se para de pé através de posturas diferentes da postura semi ajoelhado
                                   Dica: Podem ser utilizados brinquedos para encorajar a criança </p>
                <b>50% creditado com 8 meses e 1 semana <br>
                   90% creditado com 10 meses</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected5_5" @change="checkPositions('5_5')" id="selected5_5" name="selected5_5">Tracionar-se em Pé</b-form-checkbox>  </font>
            </b-col>

            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Ortostatica/PeRotacao.png" alt="Image 48" id="popover-target-48"></b-img> 
              <b-popover target="popover-target-48" triggers="hover" placement="righttop">
                <template #title>Em Pé com Apoio e com Rotação:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre os pés
                                   Apoio de um braço.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Coxofemorais abduzidas
                                   Tronco rodado</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Capaz de liberar uma mão e realizar alcance com rotação de tronco e pelve</p>
                <p align="justify">Se a criança não for vista tracionando-se para em pé independentemente ela não deveria passar
                                   neste item. A base de suporte da criança ainda pode estar alargada
                                   Dica: Podem ser usados brinquedos para eliciar a rotação de tronco</p>
                <b>50% creditado com 8 meses e 5 dias<br>
                   90% creditado com 9 meses e 25 dias</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected5_6" @change="checkPositions('5_6')" id="selected5_6" name="selected5_6">Apoio com Rotação</b-form-checkbox>  </font>
            </b-col>

            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Ortostatica/AndandoSemRotacao.png" alt="Image 49" id="popover-target-49"></b-img> 
              <b-popover target="popover-target-49" triggers="hover" placement="righttop">
                <template #title>Andando de lado sem rotação:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre os pés
                                   Algum suporte de braço.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Pernas abduzidas e rodadas externamente
                                   Base de suporte alargada.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Anda para os lados sem rotação.</p>
                <p align="justify">Se a criança não for observada tracionando-se independentemente para ficar em pé, não deve
                                   passar neste item. A criança pode elevar-se sobre os artelhos na posição em pé. mas deve ser
                                   observada apoiando a planta dos pés em algum momento.</p>
                <b>50% creditado com 9 meses e 5 dias<br>
                   90% creditado com 13 meses</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected5_7" @change="checkPositions('5_7')" id="selected5_7" name="selected5_7">Andando Lado S/ Rotação</b-form-checkbox>  </font>
            </b-col>

            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Ortostatica/SemiAjoelhado.png" alt="Image 50" id="popover-target-50"></b-img> 
              <b-popover target="popover-target-50" triggers="hover" placement="lefttop">
                <template #title>Semi-Ajoelhado:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre um joelho flexionado e o pé oposto; com braço apoiado.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Postura de semi-ajoelhado.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Pode assumir a posição em pé ou brincar na posição.</p>
                <b>50% creditado com 8 meses e meio<br>
                   90% creditado com 11 meses</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected5_8" @change="checkPositions('5_8')" id="selected5_8" name="selected5_8">Semi-Ajoelhado</b-form-checkbox>  </font>
            </b-col>
          </b-row>

          <br>

          <b-row>
           <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Ortostatica/AgachamentoPe.png" alt="Image 51" id="popover-target-51"></b-img> 
              <b-popover target="popover-target-51" triggers="hover" placement="righttop">
                <template #title>Agachamento controlado a partir da posição em pé:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre os pés
                                   Apoio sobre um braço.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Segura-se pelo suporte com uma mão.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Agachamento controlado a partir da posição em pé.</p>
                <p align="justify">Para passar neste item a criança deve assumir a postura de pé sozinha. Pode ser observada
                                   grande variedade de posturas das pernas; as pernas podem mover-se simetricamente ou
                                   assimetricamente. O movimento deve ser controlado e a criança não deve cair acidentalmente da
                                   posição em pé. A criança não tem que retornar para a postura em pé
                                   Dica: Usar brinquedos para provocar movimentos antigravitacionais.</p>
                <b>50% creditado com 9 meses e 5 dias<br>
                   90% creditado com 11 meses e 5 dias</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected5_9" @change="checkPositions('5_9')" id="selected5_9" name="selected5_9">Agachamento Controlado</b-form-checkbox>  </font>
            </b-col>

            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Ortostatica/LadoRotacao.png" alt="Image 52" id="popover-target-52"></b-img> 
              <b-popover target="popover-target-52" triggers="hover" placement="righttop">
                <template #title>Andando de lado com rotação:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre os pés
                                   Algum apoio de braço.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Semi-rodada na direção do movimento.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Anda de lado com rotação.</p>
                <p align="justify">Se a criança não for observada passando para a posição em pé independetemente ela não deve
                                   passar neste item
                                   Dica: Pode-se usar brinquedos para encorajar a criança a andar de lado.</p>
                <b>50% creditado com 9 meses e 5 dias90% creditado com 11 meses e 25 dias</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected5_10" @change="checkPositions('5_10')" id="selected5_10" name="selected5_10">Andando Lado C/ Rotação</b-form-checkbox>  </font>
            </b-col>

            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Ortostatica/PeSozinho.png" alt="Image 53" id="popover-target-53"></b-img> 
              <b-popover target="popover-target-53" triggers="hover" placement="righttop">
                <template #title>Em Pé Sozinho:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso nos pés.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Adução escapular
                                   Lordose lombar
                                   Coxofemorais abduzidas e rodadas externamente</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Mantém-se em pé momentaneamente sozinho
                                   Reações de equilíbrio em pé.</p>
                <p align="justify">A posição dos braços pode variar de bem abduzidos para braços menos abduzidos. Nas reações
                                   de equilíbrio em pé podem ser observados movimentos de dorsiflexão ou de flexão intermitente
                                   de artelhos. </p>
                <b>50% creditado com 10 meses e 15 dias<br>
                   90% creditado com 13 meses</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected5_11" @change="checkPositions('5_11')" id="selected5_11" name="selected5_11">Em Pé Sozinho</b-form-checkbox>  </font>
            </b-col>

            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Ortostatica/PrimeirosPassos.png" alt="Image 54" id="popover-target-54"></b-img> 
              <b-popover target="popover-target-54" triggers="hover" placement="lefttop">
                <template #title>Primeiros Passos:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre os pés.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Adução escapular
                                   Lordose lombar
                                   Pernas abduzidas e rodadas externamente.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Caminha independentemente
                                   Move-se rapidamene com passos curtos.</p>
                <p align="justify">A criança deve dar 5 passos independentes para passar neste item. A posição dos braços pode
                                   variar de ampla abdução para abdução média . Este item representa as primeiras tentativas para
                                   o caminhar independente e a criança pode ainda cair com freqüência.</p>
                <b>50% creditado com 11 meses e 5 dias<br>
                   90% creditado com 13 meses e 15 dias</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected5_12" @change="checkPositions('5_12')" id="selected5_12" name="selected5_12">Primeiros Passos</b-form-checkbox>  </font>
            </b-col>
          </b-row>

          <br>

          <b-row>
           <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Ortostatica/PeCocoras.png" alt="Image 55" id="popover-target-55"></b-img> 
              <b-popover target="popover-target-55" triggers="hover" placement="righttop">
                <template #title>Em pé para a posição de cócoras:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre os pés.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Posição de cócoras.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Move-se da posição em pé para cócoras e de volta para em pé com
                                   flexão e extensão controlada de quadril e joelhos.</p>
                <p align="justify">Para passar este item a criança não precisa manter a posição de cócoras; ela pode rapidamente
                                   mover-se da posição de cócoras para a posição em pé novamente</p>
                <b>50% creditado com 11 meses e meio<br>
                   90% creditado com 14 meses e 5 dias</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected5_13" @change="checkPositions('5_13')" id="selected5_13" name="selected5_13">Em Pé Posição Cócoras</b-form-checkbox>  </font>
            </b-col>

            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Ortostatica/LevantandoQuadrupede.png" alt="Image 56" id="popover-target-56"></b-img> 
              <b-popover target="popover-target-56" triggers="hover" placement="righttop">
                <template #title>Levantando da posição quadrúpede:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre mãos e pés.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Mãos e pés.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Assume a posição em pé sozinha
                                   Empurra-se rapidamente com as mãos para a postura em pé sem
                                   usar qualquer apoio</p>
                <p align="justify">Dica: Para eliciar este item o examinador pode posicionar a criança na posição supina e observar
                                   a resposta</p>
                <b>50% creditado com 11 meses e 20 dias<br>
                   90% creditado com 14 meses e 25 dias</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected5_14" @change="checkPositions('5_14')" id="selected5_14" name="selected5_14">Levantando da Posição</b-form-checkbox>  </font>
            </b-col>

            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Ortostatica/AndaSozinho.png" alt="Image 57" id="popover-target-57"></b-img> 
              <b-popover target="popover-target-57" triggers="hover" placement="righttop">
                <template #title>Anda Sozinho:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre os pés.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Braços podem variar de abdução média para os braços
                                   posicionados ao longo do corpo
                                   Lordose lombar
                                   Pernas em posição neutra ou ligeiramente abduzidas.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Caminha independentemente.</p>
                <p align="justify">Para passar neste item a criança usa o andar como método de locomoção e o padrão de
                                   caminhar pode ainda ser imaturo.</p>
                <b>50% creditado com 11 meses e 20 dias<br>
                   90% creditado com 14 meses e 5 dias</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected5_15" @change="checkPositions('5_15')" id="selected5_15" name="selected5_15">Anda Sozinho</b-form-checkbox>  </font>
            </b-col>

            <b-col>
              <b-img thumbnail fluid width="180" height="180" src="/EMIA/Ortostatica/Cocoras.png" alt="Image 58" id="popover-target-58"></b-img> 
              <b-popover target="popover-target-58" triggers="hover" placement="lefttop">
                <template #title>Cócoras:</template>
                <b><u>Suporte de peso:</u></b><br>
                <p align="justify">Peso sobre os pés.</p>
                <b><u>Postura:</u></b><br>
                <p align="justify">Postura de cócoras; tronco para frente.</p>
                <b><u>Movimento antigravitacional:</u></b>
                <p align="justify">Mantém a posição pelas reações de equilíbrio dos pés e posição do tronco.</p>
                <p align="justify">A criança é capaz de brincar nesta posição.</p>
                <b>50% creditado com 12 meses e 5 dias<br>
                   90% creditado com 14 meses e 25 dias</b>
              </b-popover>
              <font color="FFFFFF"> <b-form-checkbox :value="true" :unchecked-value="false" v-model="selected5_16" @change="checkPositions('5_16')" id="selected5_16" name="selected5_16">Cócoras</b-form-checkbox>  </font>
            </b-col>
          </b-row>

          </b-container>
        </b-form-group>

        <b-button v-show="numberQuestion == 5" variant="success" @click="numberQuestion = 6">Ok</b-button>

        <b-form-group v-show="numberQuestion == 6" class="mt-3">
          <b-form-group v-show="numberQuestion == 6" label="Observações / Recomendações:" class="mt-3">
            <b-form-textarea id="textarea" v-model="selected6" rows="1" max-rows="6"></b-form-textarea>
          </b-form-group>                                                                                                                                                  
        </b-form-group>   
                   
      </b-card>
    </div> 
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'
import Moment from 'moment'

export default {
    name: "SatisfactionSurvey",
    components: { PageTitle },
    data() {
      return {
        patient: null,
        question: null,
        theme: null,
        numberQuestion: 1,
        selected1_1: null,
        selected1_2: null,
        selected1_3: null,
        selected1_4: null,
        selected1_5: null,
        selected1_6: null,
        selected1_7: null,
        selected1_8: null,
        selected2_1: false,
        selected2_2: false,
        selected2_3: false,
        selected2_4: false,
        selected2_5: false,
        selected2_6: false,
        selected2_7: false,
        selected2_8: false,
        selected2_9: false,
        selected2_10: false,
        selected2_11: false,
        selected2_12: false,
        selected2_13: false,
        selected2_14: false,
        selected2_15: false,
        selected2_16: false,
        selected2_17: false,
        selected2_18: false,
        selected2_19: false,
        selected2_20: false,
        selected2_21: false,
        selected3_1: false,
        selected3_2: false,
        selected3_3: false,
        selected3_4: false,
        selected3_5: false,
        selected3_6: false,
        selected3_7: false,
        selected3_8: false,
        selected3_9: false,
        selected4_1: false,
        selected4_2: false,
        selected4_3: false,
        selected4_4: false,
        selected4_5: false,
        selected4_6: false,
        selected4_7: false,
        selected4_8: false,
        selected4_9: false,
        selected4_10: false,
        selected4_11: false,
        selected4_12: false,
        selected5_1: false,
        selected5_2: false,
        selected5_3: false,
        selected5_4: false,
        selected5_5: false,
        selected5_6: false,
        selected5_7: false,
        selected5_8: false,
        selected5_9: false,
        selected5_10: false,
        selected5_11: false,
        selected5_12: false,
        selected5_13: false,
        selected5_14: false,
        selected5_15: false,
        selected5_16: false,
        selected6: null,
        firstMarked2: null,
        firstMarked3: null,
        firstMarked4: null,
        firstMarked5: null
      }
    },    
    methods: {
      sendQuestions() {
          Moment.locale('pt-br')  
          
          axios.get(`${baseApiUrl}/patient/getById/${this.patient._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`).then(res => {
            this.patient = res.data

            let data = {}
            let index = 0

            for (let q of this.patient.questions) {
              if (q.type == 2 && q.name == this.question.name && q.answers.length == 0) {
                q.answers = []

                q.status = 1
                q.answers.push(
                  {
                    'dateAnswers': Moment(new Date()).format('L')
                  }, 
                  {
                    '1_1': this.selected1_1,
                    '1_2': this.selected1_2,
                    '1_3': this.selected1_3,
                    '1_4': this.selected1_4,
                    '1_5': this.selected1_5,
                    '1_6': this.selected1_6,
                    '1_7': this.selected1_7,
                    '1_8': this.selected1_8,
                    '2_1': this.selected2_1,
                    '2_2': this.selected2_2,
                    '2_3': this.selected2_3,
                    '2_4': this.selected2_4,
                    '2_5': this.selected2_5,
                    '2_6': this.selected2_6,
                    '2_7': this.selected2_7,
                    '2_8': this.selected2_8,
                    '2_9': this.selected2_9,
                    '2_10': this.selected2_10,
                    '2_11': this.selected2_11,
                    '2_12': this.selected2_12,
                    '2_13': this.selected2_13,
                    '2_14': this.selected2_14,
                    '2_15': this.selected2_15,
                    '2_16': this.selected2_16,
                    '2_17': this.selected2_17,
                    '2_18': this.selected2_18,
                    '2_19': this.selected2_19,
                    '2_20': this.selected2_20,
                    '2_21': this.selected2_21,
                    '3_1': this.selected3_1,
                    '3_2': this.selected3_2,
                    '3_3': this.selected3_3,
                    '3_4': this.selected3_4,
                    '3_5': this.selected3_5,
                    '3_6': this.selected3_6,
                    '3_7': this.selected3_7,
                    '3_8': this.selected3_8,
                    '3_9': this.selected3_9,
                    '4_1': this.selected4_1,
                    '4_2': this.selected4_2,
                    '4_3': this.selected4_3,
                    '4_4': this.selected4_4,
                    '4_5': this.selected4_5,
                    '4_6': this.selected4_6,
                    '4_7': this.selected4_7,
                    '4_8': this.selected4_8,
                    '4_9': this.selected4_9,
                    '4_10': this.selected4_10,
                    '4_11': this.selected4_11,
                    '4_12': this.selected4_12,
                    '5_1': this.selected5_1,
                    '5_2': this.selected5_2,
                    '5_3': this.selected5_3,
                    '5_4': this.selected5_4,
                    '5_5': this.selected5_5,
                    '5_6': this.selected5_6,
                    '5_7': this.selected5_7,
                    '5_8': this.selected5_8,
                    '5_9': this.selected5_9,
                    '5_10': this.selected5_10,
                    '5_11': this.selected5_11,
                    '5_12': this.selected5_12,
                    '5_13': this.selected5_13,
                    '5_14': this.selected5_14,
                    '5_15': this.selected5_15,
                    '5_16': this.selected5_16,
                    '6': this.selected6,
                  }                                                                                                                                                                                                                                                                                                                                                                                                                                                            
                ) 

                data.index = index
                data.answers = q.answers
              }

              index += 1
            }        
            
            data.idPatient = this.patient._id

            axios.post(`${baseApiUrl}/patient/setTestQuestion/${this.patient._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, data)
              .then(() => {
                  this.$store.commit('setResultPatient', this.patient)

                  this.$toasted.global.defaultSuccess()
                  this.$router.go(-1)
              })  
              .catch(showError) 
          }).catch((e) => {
            console.log(e)
            showError
          })
      },
      checkPositions(codImage) {
        if (document.getElementById(`selected${codImage}`).checked) {
          let max = parseInt(codImage.substring(2,3))

          if (codImage.length > 3) {
            max = parseInt(codImage.substring(2))
          } 

          if (codImage.substring(0,1) == '2') {
            if (!this.firstMarked2) {
              while (max >= 1) {
                this[`selected2_${max}`] = true

                max -= 1
              }

              this.firstMarked2 = true
            }
          } else if (codImage.substring(0,1) == '3') {
            if (!this.firstMarked3) {
              while (max >= 1) {
                this[`selected3_${max}`] = true

                max -= 1
              }

              this.firstMarked3 = true
            }
          } else if (codImage.substring(0,1) == '4') {
            if (!this.firstMarked4) {
              while (max >= 1) {
                this[`selected4_${max}`] = true

                max -= 1
              }

              this.firstMarked4 = true
            }
          } else if (codImage.substring(0,1) == '5') {
            if (!this.firstMarked5) {
              while (max >= 1) {
                this[`selected5_${max}`] = true

                max -= 1
              }

              this.firstMarked5 = true
            }
          }
        }
      }
    },
    created() {
      this.theme = localStorage.getItem('theme')

      Moment.locale('pt-br')      

      this.patient = this.$route.params.questionSelected.patient
      this.question = this.$route.params.questionSelected

      let dtIni = Moment(new Date())
      let dtFim = Moment(this.patient.birthday)
      
      let age = dtIni.diff(dtFim, 'years')
      let month = dtIni.diff(dtFim, 'months')

      let now = new Date()

      let m = now.getMonth() + 1
      let d = now.getDate()

      if (d < 10) {
        d = '0' + d
      }       

      if (m < 10) {
        m = '0' + m
      } 

      let today = now.getFullYear() + '-' + m + '-' + d

      this.selected1_1 = this.patient.name
      this.selected1_2 = today
      this.selected1_3 = this.patient.rg
      this.selected1_4 = this.patient.birthday
      this.selected1_6 = month
      this.selected1_8 = age

      if (this.$route.params.questionSelected.answers.length > 0) {
        this.selected1_1 = this.$route.params.questionSelected.answers[1]['1_1']   
        this.selected1_2 = this.$route.params.questionSelected.answers[1]['1_2']
        this.selected1_3 = this.$route.params.questionSelected.answers[1]['1_3']
        this.selected1_4 = this.$route.params.questionSelected.answers[1]['1_4']
        this.selected1_5 = this.$route.params.questionSelected.answers[1]['1_5']
        this.selected1_6 = this.$route.params.questionSelected.answers[1]['1_6']
        this.selected1_7 = this.$route.params.questionSelected.answers[1]['1_7']
        this.selected1_8 = this.$route.params.questionSelected.answers[1]['1_8']
        this.selected2_1 = this.$route.params.questionSelected.answers[1]['2_1']
        this.selected2_2 = this.$route.params.questionSelected.answers[1]['2_2']
        this.selected2_3 = this.$route.params.questionSelected.answers[1]['2_3']
        this.selected2_4 = this.$route.params.questionSelected.answers[1]['2_4']
        this.selected2_5 = this.$route.params.questionSelected.answers[1]['2_5']
        this.selected2_6 = this.$route.params.questionSelected.answers[1]['2_6']
        this.selected2_7 = this.$route.params.questionSelected.answers[1]['2_7']
        this.selected2_8 = this.$route.params.questionSelected.answers[1]['2_8']
        this.selected2_9 = this.$route.params.questionSelected.answers[1]['2_9']
        this.selected2_10 = this.$route.params.questionSelected.answers[1]['2_10']
        this.selected2_11 = this.$route.params.questionSelected.answers[1]['2_11']
        this.selected2_12 = this.$route.params.questionSelected.answers[1]['2_12']
        this.selected2_13 = this.$route.params.questionSelected.answers[1]['2_13']
        this.selected2_14 = this.$route.params.questionSelected.answers[1]['2_14']
        this.selected2_15 = this.$route.params.questionSelected.answers[1]['2_15']
        this.selected2_16 = this.$route.params.questionSelected.answers[1]['2_16']
        this.selected2_17 = this.$route.params.questionSelected.answers[1]['2_17']
        this.selected2_18 = this.$route.params.questionSelected.answers[1]['2_18']
        this.selected2_19 = this.$route.params.questionSelected.answers[1]['2_19']
        this.selected2_20 = this.$route.params.questionSelected.answers[1]['2_20']
        this.selected2_21 = this.$route.params.questionSelected.answers[1]['2_21']
        this.selected3_1 = this.$route.params.questionSelected.answers[1]['3_1']
        this.selected3_2 = this.$route.params.questionSelected.answers[1]['3_2']
        this.selected3_3 = this.$route.params.questionSelected.answers[1]['3_3']
        this.selected3_4 = this.$route.params.questionSelected.answers[1]['3_4']
        this.selected3_5 = this.$route.params.questionSelected.answers[1]['3_5']
        this.selected3_6 = this.$route.params.questionSelected.answers[1]['3_6']
        this.selected3_7 = this.$route.params.questionSelected.answers[1]['3_7']
        this.selected3_8 = this.$route.params.questionSelected.answers[1]['3_8']
        this.selected3_9 = this.$route.params.questionSelected.answers[1]['3_9']
        this.selected4_1 = this.$route.params.questionSelected.answers[1]['4_1']
        this.selected4_2 = this.$route.params.questionSelected.answers[1]['4_2']
        this.selected4_3 = this.$route.params.questionSelected.answers[1]['4_3']
        this.selected4_4 = this.$route.params.questionSelected.answers[1]['4_4']
        this.selected4_5 = this.$route.params.questionSelected.answers[1]['4_5']
        this.selected4_6 = this.$route.params.questionSelected.answers[1]['4_6']
        this.selected4_7 = this.$route.params.questionSelected.answers[1]['4_7']
        this.selected4_8 = this.$route.params.questionSelected.answers[1]['4_8']
        this.selected4_9 = this.$route.params.questionSelected.answers[1]['4_9']
        this.selected4_10 = this.$route.params.questionSelected.answers[1]['4_10']
        this.selected4_11 = this.$route.params.questionSelected.answers[1]['4_11']
        this.selected4_12 = this.$route.params.questionSelected.answers[1]['4_12']
        this.selected5_1 = this.$route.params.questionSelected.answers[1]['5_1']
        this.selected5_2 = this.$route.params.questionSelected.answers[1]['5_2']
        this.selected5_3 = this.$route.params.questionSelected.answers[1]['5_3']
        this.selected5_4 = this.$route.params.questionSelected.answers[1]['5_4']
        this.selected5_5 = this.$route.params.questionSelected.answers[1]['5_5']
        this.selected5_6 = this.$route.params.questionSelected.answers[1]['5_6']
        this.selected5_7 = this.$route.params.questionSelected.answers[1]['5_7']
        this.selected5_8 = this.$route.params.questionSelected.answers[1]['5_8']
        this.selected5_9 = this.$route.params.questionSelected.answers[1]['5_9']
        this.selected5_10 = this.$route.params.questionSelected.answers[1]['5_10']
        this.selected5_11 = this.$route.params.questionSelected.answers[1]['5_11']
        this.selected5_12 = this.$route.params.questionSelected.answers[1]['5_12']
        this.selected5_13 = this.$route.params.questionSelected.answers[1]['5_13']
        this.selected5_14 = this.$route.params.questionSelected.answers[1]['5_14']
        this.selected5_15 = this.$route.params.questionSelected.answers[1]['5_15']
        this.selected5_16 = this.$route.params.questionSelected.answers[1]['5_16']
        this.selected6 = this.$route.params.questionSelected.answers[1]['6']
      }
    }
}
</script>

<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}

.card-violet {
  background-color: #f3eaff;
}
</style>