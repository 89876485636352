<template>
  <div class="testEscalaBarthel">
    <PageTitle icon="fa fa-file-text" main="Questionário" sub="Escala de PSE de Borg" />
  
    <div>
      <b-card :class="`card-${theme}`">
        <b-button v-show="question.status == 0" @click="sendQuestions()" variant="info">Enviar</b-button>

          <b-form-group class="mt-3" label="Classificação Numérica  -  Classificação Verbal  (Exemplo)">
            <b-form-group class="ml-5" label="">
                <b-form-radio v-model="selected1_1" name="some-radios-1_1" value="0">0 - Nenhuma (Absolutamente nenhum esforço; sentado, sem fazer nada)</b-form-radio>
                <b-form-radio v-model="selected1_1" name="some-radios-1_1" value="0.5">0,5 - Muito, muito leve (Esforço quase imperceptível)</b-form-radio>
                <b-form-radio v-model="selected1_1" name="some-radios-1_1" value="1">1 - Muito leve (Caminhando devagar no seu próprio ritmo)</b-form-radio>
                <b-form-radio v-model="selected1_1" name="some-radios-1_1" value="2">2 - Leve (Esforço leve)</b-form-radio>
                <b-form-radio v-model="selected1_1" name="some-radios-1_1" value="3">3 - Moderada (Sensação de que você ainda tem energia suficiente para continuar se exercitando)</b-form-radio>
                <b-form-radio v-model="selected1_1" name="some-radios-1_1" value="4">4 - Pouco intensa (Necessidade de fazer esforço pesado)</b-form-radio>
                <b-form-radio v-model="selected1_1" name="some-radios-1_1" value="5">5 - Intensa (Necessidade de fazer esforço muito pesado)</b-form-radio>
                <b-form-radio v-model="selected1_1" name="some-radios-1_1" value="6">6 </b-form-radio>
                <b-form-radio v-model="selected1_1" name="some-radios-1_1" value="7">7 - Muito intensa (Você consegue continuar, mas realmente precisa se esforçar; o esforço é muito pesado e está muito cansado)</b-form-radio>
                <b-form-radio v-model="selected1_1" name="some-radios-1_1" value="8">8</b-form-radio>
                <b-form-radio v-model="selected1_1" name="some-radios-1_1" value="9">9 - Muito, muito intensa (Para a maioria das pessoas, este é o exercício mais extenuante já feito, quase o esforço máximo)</b-form-radio>
                <b-form-radio v-model="selected1_1" name="some-radios-1_1" value="10">10 - Máxima (Esforço máximo absoluto (o mais alto possível), exaustão)</b-form-radio>
            </b-form-group>
          </b-form-group>  
      </b-card>
    </div> 
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'
import Moment from 'moment'

export default {
    name: "SatisfactionSurvey",
    components: { PageTitle },
    data() {
      return {
        patient: null,
        question: null,
        theme: null,
        numberQuestion: 1,
        selected1_1: null,
      }
    },    
    methods: {
      sendQuestions() {
          Moment.locale('pt-br')  
          
          axios.get(`${baseApiUrl}/patient/getById/${this.patient._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`).then(res => {
            this.patient = res.data

            let data = {}
            let index = 0

            for (let q of this.patient.questions) {
              if (q.type == 2 && q.name == this.question.name && q.answers.length == 0) {
                q.answers = []

                q.status = 1
                q.answers.push(
                  {
                    'dateAnswers': Moment(new Date()).format('L')
                  }, 
                  {
                    '1_1': this.selected1_1
                  }                                                                                                                                                                                                                                                                                                                                                                                                                                                            
                ) 

                data.index = index
                data.answers = q.answers
              }

              index += 1
            }        
            
            data.idPatient = this.patient._id

            axios.post(`${baseApiUrl}/patient/setTestQuestion/${this.patient._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, data)
              .then(() => {
                  this.$store.commit('setResultPatient', this.patient)

                  this.$toasted.global.defaultSuccess()
                  this.$router.go(-1)
              })  
              .catch(showError) 
          }).catch((e) => {
            console.log(e)
            showError
          })
        // }
      }
    },
    created() {
      this.theme = localStorage.getItem('theme')

      this.patient = this.$route.params.questionSelected.patient
      this.question = this.$route.params.questionSelected

      if (this.$route.params.questionSelected.answers.length > 0) {
        this.selected1_1 = this.$route.params.questionSelected.answers[1]['1_1']      
      }
    }
}
</script>

<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}

.card-violet {
  background-color: #f3eaff;
}
</style>