<template>
  <header :class="`header-${theme}`">
    <b-button :id="`bsidebar-${theme}`" class="ml-2 toggle" size="sm" @click="toggleMenu" v-if="!hideToggle" v-b-toggle.sidebar-1> 
      <i class="fa fa-bars" :class="icon"></i>
    </b-button>        
    
    <h1 :class="`title-${theme}`">
      <router-link to="/">{{title}}</router-link>
    </h1>

    <UserDropdown v-if="!hideUserDropdown" />
  </header>
</template>

<script>
import UserDropdown from "./UseDropdown"

export default {
  name: "Header",
  components: { UserDropdown },
  props: {  
    hideToggle: Boolean,
    hideUserDropdown: Boolean,
  },
  data: function () {
    return {
      theme: null,
      title: null
    }
  },  
  computed: {
    icon: function () {
      return this.$store.state.isMenuVisible     
    }
  },
  methods: {
    toggleMenu() {
      this.$store.commit("toggleMenu");
    },
  },  
  mounted() { 
    this.theme = localStorage.getItem('theme')
    this.title = localStorage.getItem('name')
  }
}
</script>

<style>
.header-wine {
  grid-area: header;
  background: linear-gradient(to right, rgb(248, 201, 201), #7A1120);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10pt;
  margin: 6px;
  border-top: 1px solid #B79472;
  border-bottom: 1px solid #B79472;
  border-left: 1px solid #B79472;
  border-right: 1px solid #B79472;   
}

.header-violet {
  grid-area: header;
  background: linear-gradient(to right, #f3eaff, #6E4DA4);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10pt;
  margin: 6px;
  border-top: 1px solid #f3eaff;
  border-bottom: 1px solid #f3eaff;
  border-left: 1px solid #f3eaff;
  border-right: 1px solid #f3eaff;   
}

.header-green {
  grid-area: header;
  background: linear-gradient(to right, #f7fff9, rgb(196, 235, 220));
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10pt;
  margin: 6px;
  border-top: 1px solid #28A745;
  border-bottom: 1px solid #28A745;
  border-left: 1px solid #28A745;
  border-right: 1px solid #28A745;    
}

#bsidebar-green {
  color: rgb(13, 99, 66);
  background: rgb(196, 235, 220);
}

#bsidebar-wine {
  color: #7A1120;
  background: rgb(248, 201, 201);
}

#bsidebar-violet {
  color: #6E4DA4;
  background: #f3eaff;
}

.title-green {
  font-size: 1.4rem;
  color: rgb(199, 179, 179);
  font-weight: 100;
  flex-grow: 1;
  text-align: center;
}

.title-wine {
  font-size: 1.4rem;
  color: rgb(199, 179, 179);
  font-weight: 100;
  flex-grow: 1;
  text-align: center;
}

.title-violet {
  font-size: 1.4rem;
  color: #f3eaff;
  font-weight: 100;
  flex-grow: 1;
  text-align: center;
}

.title-wine a {
  color: #7A1120;
  text-decoration: none;
  font-weight: bold;
}

.title-green a {
  color: rgb(0, 88, 62);
  text-decoration: none;
  font-weight: bold;
}

.title-violet a {
  color: #6E4DA4;
  text-decoration: none;
  font-weight: bold;
}

.title-green a:hover {
  color: #a1e4b2;
  text-decoration: none;
  font-weight: bold;
}

.title-wine a:hover {
  color: #B79472;
  text-decoration: none;
  font-weight: bold;
}

.title-violet a:hover {
  color: #f3eaff;
  text-decoration: none;
  font-weight: bold;
}

header.header > a.toggle {
  width: 60px;
  height: 100%;
  color: #7A1120;
  justify-self: flex-start;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

header.header > a.toggle:hover {
  color: #7A1120;
  background-color: rgba(0, 0, 0, 0.2);
}

</style>