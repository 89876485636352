
<template>
    <div class="ScaleBaseProfessionals">
        <b-modal id="modal-prevent-closing-1" ref="modal" title="Escala Semanal" @show="resetModal" @hidden="resetModal" @ok="handleOk" size="lg">
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-row>        
                    <b-col md="6" sm="12">
                        <b-form-group label="Semanas" label-for="tags-with-dropdown-week">
                            <b-form-tags id="tags-with-dropdown-week" v-model="value" no-outer-focus class="mb-2">
                                <template v-slot="{ tags, disabled, addTag, removeTag }">
                                <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                                    <li v-for="tag in tags" :key="tag" class="list-inline-item">
                                    <b-form-tag
                                        @remove="removeTag(tag)"
                                        :title="tag"
                                        :disabled="disabled"
                                        variant="info">{{ tag }}
                                    </b-form-tag>
                                    </li>
                                </ul>

                                <b-dropdown size="sm" variant="outline-secondary" block menu-class="w-100">
                                    <template #button-content>
                                    Semanas
                                    </template>
                                    <b-dropdown-form @submit.stop.prevent="() => {}">
                                    </b-dropdown-form>
                                    <b-dropdown-divider></b-dropdown-divider>
                                    <b-dropdown-item-button
                                    v-for="option in availableOptions"
                                    :key="option"
                                    @click="onOptionClick({ option, addTag })"
                                    >
                                    {{ option }}
                                    </b-dropdown-item-button>
                                    <b-dropdown-text v-if="availableOptions.length === 0">
                                        Semanas
                                    </b-dropdown-text>
                                </b-dropdown>
                                </template>
                            </b-form-tags>
                        </b-form-group>
                    </b-col>

                    <b-col md="6" sm="12">
                        <b-form-group label="Dias" label-for="tags-with-dropdown-day">
                            <b-form-tags id="tags-with-dropdown-day" v-model="valueDay" no-outer-focus class="mb-2">
                                <template v-slot="{ tags, disabled, addTag, removeTag }">
                                <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                                    <li v-for="tag in tags" :key="tag" class="list-inline-item">
                                    <b-form-tag
                                        @remove="removeTag(tag)"
                                        :title="tag"
                                        :disabled="disabled"
                                        variant="info">{{ tag }}
                                    </b-form-tag>
                                    </li>
                                </ul>

                                <b-dropdown size="sm" variant="outline-secondary" block menu-class="w-100">
                                    <template #button-content>
                                    Dias
                                    </template>
                                    <b-dropdown-form @submit.stop.prevent="() => {}">
                                    </b-dropdown-form>
                                    <b-dropdown-divider></b-dropdown-divider>
                                    <b-dropdown-item-button
                                    v-for="option in availableOptionsDay"
                                    :key="option"
                                    @click="onOptionClickDay({ option, addTag })"
                                    >
                                    {{ option }}
                                    </b-dropdown-item-button>
                                    <b-dropdown-text v-if="availableOptionsDay.length === 0">
                                        Dias
                                    </b-dropdown-text>
                                </b-dropdown>
                                </template>
                            </b-form-tags>
                        </b-form-group>
                    </b-col>                    
                </b-row>
                <b-row>
                    <b-col md="6" sm="12">
                        <b-form-timepicker size="sm" @input="setCheckin" placeholder="" hide-header required :state="stateCheckin"></b-form-timepicker>                        
                    </b-col>
                    <b-col md="6" sm="12">
                        <b-form-timepicker size="sm" @input="setCheckout" placeholder="" hide-header required :state="stateCheckout"></b-form-timepicker>                        
                    </b-col>
                </b-row>       
                <hr>
                <b-row>
                    <b-button size="sm" variant="success" class="ml-3" v-b-modal.modal-prevent-closing v-b-popover.hover.top="'Adiconar Profissional'">
                        <i class="fa fa-plus"></i>
                    </b-button>     

                    <Lookup ref="Lookup" title="Profissionais" :list="list" :fields="pfields" :invalidFeedback="invalidFeedback" @event="addProfessional"
                            resultTable="professionalScale"></Lookup>
                </b-row>
                <b-row>
                    <b-col md="12" sm="12" class="mt-3">
                        <b-table head-variant="light" outlined hover :fields=fields responsive="sm" id="my-table" :items=listProfessionals ref="tableProfessionals"
                                :per-page="perPage" :current-page="currentPage" fixed :filter="ftext">

                            <template v-slot:cell(actions)="row">
                                <b-button size="sm" variant="danger" @click="removeProfessional(row.item)">
                                    <i class="fa fa-trash"></i>
                                </b-button>     
                            </template>                                                 
                        </b-table>

                        <b-pagination size="sm" pills v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table" class="page-item">
                        </b-pagination>                                
                    </b-col>
                </b-row>         
            </form>
        </b-modal>                                          
    </div>
</template>

<script>
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'
import Lookup from '../default/Lookup'

  export default {
    name: "ScaleBaseProfessionals",
    props: [
        'list',
        'fields'
    ],
    components: { Lookup },
    data() {
      return {
        ftext: '',
        invalidFeedback: 'Selecione o profissional',
        currentPage: 1,
        perPage: 10,            
        result: [],
        scaleBase: {},
        listProfessionals: [],

        options: ['Semana 1', 'Semana 2', 'Semana 3', 'Semana 4'],
        value: [],

        optionsDay: ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo'],
        valueDay: [],

        pfields: [                 
            { key: 'name', label: 'Nome', sortable: true },
            { key: 'cpf', label: 'CPF', sortable: true, },
            { key: 'advice.number', label: 'Registro', sortable: true },         
        ],       

        checkin: null,
        checkout: null,
        stateCheckin: null,
        stateCheckout: null
      }
    },
    computed: {
      rows() {
        return this.listProfessionals.length
      },        
      availableOptions() {
        const criteria = this.criteria
        // Filter out already selected options
        const options = this.options.filter(opt => this.value.indexOf(opt) === -1)
        if (criteria) {
          // Show only options that match criteria
          return options.filter(opt => opt.toLowerCase().indexOf(criteria) > -1);
        }
        // Show all options available
        return options
      },
      availableOptionsDay() {
        const criteriaDay = this.criteriaDay
        // Filter out already selected options
        const optionsDay = this.optionsDay.filter(opt => this.valueDay.indexOf(opt) === -1)
        if (criteriaDay) {
          // Show only options that match criteria
          return optionsDay.filter(opt => opt.toLowerCase().indexOf(criteriaDay) > -1);
        }
        // Show all options available

        return optionsDay
      }
    },
    methods: {
        setCheckin(value) {
            this.checkin = value
        },
        setCheckout(value) {
            this.checkout = value
        },          
        onOptionClick({ option, addTag }) {
            addTag(option)
        },
        onOptionClickDay({ option, addTag }) {
            addTag(option)
        },
        resetModal() {
            this.value = []
            this.valueDay = []
            this.listProfessionals = []
        },           
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        }, 
        checkFormValidity() {
            if (this.checkin == null || this.checkout == null || this.value.length == 0 || this.valueDay.length == 0 || this.listProfessionals.length == 0) {
                return false
            } else {
                return true         
            }
        },     
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return 
            }

            this.result = []

            for (let w of this.value) {
                for (let d of this.valueDay) {
                    this.result.push({
                        checkin: this.checkin,
                        checkout: this.checkout,
                        week: w,
                        day: d,
                        professionals: this.listProfessionals
                    })                    
                }
            }

            this.insertProfessionalScaleBase()

            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing-1')
            })
        }, 
        addProfessional(payload) {
            let finded = false

            this.listProfessionals.find((item, i) => {
                if (item.cpf == payload.professionalSelected.cpf) {
                    finded = true
                    i.delete
                    this.$toasted.global.defaultError({msg: 'Profissional já adicionado'})                                                       
                }
            })
            
            if (!finded) {
                this.listProfessionals.push(payload.professionalSelected)
            }
        },
        removeProfessional(itemSelected) {
            this.listProfessionals.find((item, i) => {
                if (item.cpf == itemSelected.cpf) {
                    this.listProfessionals.splice(i, 1)
                    i.delete
                }
            })            
        },
        async insertProfessionalScaleBase() {    
            let weekSave = ''
            let daySave = ''

            for (let r of this.result) {
                switch (r.week) {
                    case 'Semana 1': weekSave = '1'                
                    break;
                    case 'Semana 2': weekSave = '2'                
                    break;          
                    case 'Semana 3': weekSave = '3'                
                    break;          
                    case 'Semana 4': weekSave = '4'                
                    break;

                    default:
                    weekSave = '0'
                }          

                switch (r.day) {
                    case 'Segunda': daySave = 'monday'                
                    break;
                    case 'Terça': daySave = 'tuesday'                
                    break;          
                    case 'Quarta': daySave = 'wednesday'                
                    break;          
                    case 'Quinta': daySave = 'thursday'                
                    break;
                    case 'Sexta': daySave = 'friday'                
                    break;
                    case 'Sábado': daySave = 'saturday'                
                    break;
                    case 'Domingo': daySave = 'sunday'                
                    break;

                    default:
                    daySave = 'monday'
                }                                 

                const url = `${baseApiUrl}/scaleBase/weekAndDay/${weekSave}&${daySave}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
                await axios.get(url).then(res => {
                    this.scaleBase = res.data                                           

                    if (res.data == null) {
                        this.scaleBase = {
                            week: weekSave,
                            day: daySave,
                            professionals: []
                        }                               
                    }    

                    for (let p of r.professionals) {
                        this.scaleBase.professionals.push({
                            name: p.name,
                            cpf: p.cpf,
                            adviceNumber: p.advice.number,
                            checkin: r.checkin,
                            checkout: r.checkout,
                            attendance: [{
                                patients: []
                            }]
                        })
                    }         

                    const method = this.scaleBase._id ? 'put' : 'post'
                    const id = this.scaleBase._id ? `/${this.scaleBase._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}` : `/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`

                    axios[method](`${baseApiUrl}/scaleBase${id}`, this.scaleBase).then(() => {
                        this.$toasted.global.defaultSuccess()                                            
                    }).catch(showError)    
                }).catch(showError)     
            }
        },
    },
    mounted() {
        this.value = []
        this.valueDay = []  
    }  
}
</script>

<style>

</style>