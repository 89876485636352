<template>
  <div class="assessmentTrauma">
    <PageTitle icon="fa fa-file-text" main="Ficha de Avaliação" sub="Fisioterapia Traumato Funcional" />
  
    <div>
      <b-card :class="`card-${theme}`">
        <b-button @click="sendQuestions()" variant="info">Enviar</b-button>
        <b-form-group class="mt-3" label="ANAMNESE:">                  
          <b-button-group>
            <b-button @click="numberQuestion = 1" :variant="selected1 != null ? 'success' : 'secondary'">1</b-button>
            <b-button @click="numberQuestion = 2" :variant="selected2 != null ? 'success' : 'secondary'">2</b-button>
            <b-button @click="numberQuestion = 3" :variant="selected3 != null ? 'success' : 'secondary'">3</b-button>
            <b-button @click="numberQuestion = 4" :variant="selected4 != null ? 'success' : 'secondary'">4</b-button>
            <b-button @click="numberQuestion = 5" :variant="selected5 != null ? 'success' : 'secondary'">5</b-button>
            <b-button @click="numberQuestion = 6" :variant="selected6 != null ? 'success' : 'secondary'">6</b-button>
            <b-button @click="numberQuestion = 7" :variant="(selected7_1 != false || 
                                                             selected7_2 != false ||
                                                             selected7_3 != false ||
                                                             selected7_4 != false ||
                                                             selected7_5 != false ||
                                                             selected7_6 != false ||
                                                             selected7_7 != false ||
                                                             selected7_8 != false ||
                                                             selected7_9 != false ||
                                                             selected7_10 != false) ? 'success' : 'secondary'">7</b-button>
            <b-button @click="numberQuestion = 8" :variant="selected8 != null ? 'success' : 'secondary'">8</b-button>
            <b-button @click="numberQuestion = 9" :variant="selected9 != null ? 'success' : 'secondary'">9</b-button>
            <b-button @click="numberQuestion = 10" :variant="selected10 != null ? 'success' : 'secondary'">10</b-button>
            <b-button @click="numberQuestion = 11" :variant="selected11 != null ? 'success' : 'secondary'">11</b-button>
            <b-button @click="numberQuestion = 12" :variant="selected12 != null ? 'success' : 'secondary'">12</b-button>
            <b-button @click="numberQuestion = 13" :variant="selected13_1 != null || selected13_2 != null ? 'success' : 'secondary'">13</b-button>
            <b-button @click="numberQuestion = 14" :variant="selected14_1 != null || selected14_2 != null ? 'success' : 'secondary'">14</b-button>
            <b-button @click="numberQuestion = 15" :variant="selected15_1 != null || selected15_2 != null ? 'success' : 'secondary'">15</b-button>
            <b-button @click="numberQuestion = 16" :variant="selected16 != null ? 'success' : 'secondary'">16</b-button>
            <b-button @click="numberQuestion = 17" :variant="selected17_1 != null || selected17_2 != null ? 'success' : 'secondary'">17</b-button>
            <b-button @click="numberQuestion = 18" :variant="selected18 != null ? 'success' : 'secondary'">18</b-button>
            <b-button @click="numberQuestion = 19" :variant="selected19_1 != null || selected19_2 != null ? 'success' : 'secondary'">19</b-button>
            <b-button @click="numberQuestion = 20" :variant="selected20_1 != null || selected20_2 != null ? 'success' : 'secondary'">20</b-button>
            <b-button @click="numberQuestion = 21" :variant="selected21_1 != null || selected21_2 != null ? 'success' : 'secondary'">21</b-button>
            <b-button @click="numberQuestion = 22" :variant="selected22_1 != null || selected22_2 != null ? 'success' : 'secondary'">22</b-button>
            <b-button @click="numberQuestion = 23" :variant="selected23_1 != null || selected23_2 != null ?'success' : 'secondary'">23</b-button>
            <b-button @click="numberQuestion = 24" :variant="selected24_1 != null || selected24_2 != null ? 'success' : 'secondary'">24</b-button>
            <b-button @click="numberQuestion = 25" :variant="selected25_1 != null || selected25_2 != null ? 'success' : 'secondary'">25</b-button>
            <b-button @click="numberQuestion = 26" :variant="selected26_1 != null || selected26_2 != null ? 'success' : 'secondary'">26</b-button>
            <b-button @click="numberQuestion = 27" :variant="selected27_1 != null || selected27_2 != null ? 'success' : 'secondary'">27</b-button>
            <b-button @click="numberQuestion = 28" :variant="selected28 != null ? 'success' : 'secondary'">28</b-button>
            <b-button @click="numberQuestion = 29" :variant="selected29 != null ? 'success' : 'secondary'">29</b-button>
            <b-button class="ml-2" @click="numberQuestion = 30" variant="primary">Questionários</b-button>
          </b-button-group>          
        </b-form-group>

        <b-form-group v-show="numberQuestion == 1" label="1 - Queixa Principal (QP):">
            <b-form-input type="text" v-model="selected1" />
            <b-button class="mt-2" variant="success" @click="numberQuestion = 2">Ok</b-button>
        </b-form-group>

        <b-form-group v-show="numberQuestion == 2" label="2 - Queixa Funcional (QF):">
            <b-form-input type="text" v-model="selected2" />
            <b-button class="mt-2" variant="success" @click="numberQuestion = 3">Ok</b-button>
        </b-form-group>      

        <b-form-group v-show="numberQuestion == 3" label="3 - Diagnóstico Clínico:">
            <b-form-input type="text" v-model="selected3" />
            <b-button class="mt-2" variant="success" @click="numberQuestion = 4">Ok</b-button>
        </b-form-group>   

        <b-form-group v-show="numberQuestion == 4" label="4 - Membro Lesionado:">
          <b-form-radio @change="numberQuestion = 5" v-model="selected4" name="some-radios-4" value="1">Direito</b-form-radio>
          <b-form-radio @change="numberQuestion = 5" v-model="selected4" name="some-radios-4" value="2">Esquerdo</b-form-radio>
          <b-form-radio @change="numberQuestion = 5" v-model="selected4" name="some-radios-4" value="3">Ambos</b-form-radio>
        </b-form-group>           

        <b-form-group v-show="numberQuestion == 5" label="5 - Dominância Motora:">
          <b-form-radio @change="numberQuestion = 6" v-model="selected5" name="some-radios-5" value="1">Direita</b-form-radio>
          <b-form-radio @change="numberQuestion = 6" v-model="selected5" name="some-radios-5" value="2">Esquerda</b-form-radio>
        </b-form-group>   

        <b-form-group v-show="numberQuestion == 6" label="6 - Como e Quando Começou:">
            <b-form-textarea id="textarea" v-model="selected6" rows="1" max-rows="6"></b-form-textarea>       
            <b-button class="mt-2" variant="success" @click="numberQuestion = 7">Ok</b-button>            
        </b-form-group>              

        <b-form-group v-show="numberQuestion == 7" label="7 - Quanta dor no corpo você teve durante as últimas 4 semanas?">
          <b-form-checkbox id="checkbox-7_1" v-model="selected7_1" name="checkbox-7_1" :value="true" :unchecked-value="false">Dor</b-form-checkbox>
          <b-form-checkbox id="checkbox-7_2" v-model="selected7_2" name="checkbox-7_2" :value="true" :unchecked-value="false">Instabilidade</b-form-checkbox>
          <b-form-checkbox id="checkbox-7_3" v-model="selected7_3" name="checkbox-7_3" :value="true" :unchecked-value="false">Falseio</b-form-checkbox>
          <b-form-checkbox id="checkbox-7_4" v-model="selected7_4" name="checkbox-7_4" :value="true" :unchecked-value="false">Bloqueio/Travamento</b-form-checkbox>
          <b-form-checkbox id="checkbox-7_5" v-model="selected7_5" name="checkbox-7_5" :value="true" :unchecked-value="false">Rigidez</b-form-checkbox>
          <b-form-checkbox id="checkbox-7_6" v-model="selected7_6" name="checkbox-7_6" :value="true" :unchecked-value="false">Fraqueza</b-form-checkbox>
          <b-form-checkbox id="checkbox-7_7" v-model="selected7_7" name="checkbox-7_7" :value="true" :unchecked-value="false">Subluxação/Luxação</b-form-checkbox>
          <b-form-checkbox id="checkbox-7_8" v-model="selected7_8" name="checkbox-7_8" :value="true" :unchecked-value="false">Crepitação/Estalidos</b-form-checkbox>
          <b-form-checkbox id="checkbox-7_9" v-model="selected7_9" name="checkbox-7_9" :value="true" :unchecked-value="false">Formigamento</b-form-checkbox>
          <b-form-checkbox id="checkbox-7_10" v-model="selected7_10" name="checkbox-7_10" :value="true" :unchecked-value="false">Outros</b-form-checkbox>

          <b-button class="mt-2" variant="success" @click="numberQuestion = 8">Ok</b-button>
        </b-form-group>          

        <b-form-group v-show="numberQuestion == 8" label="8 - Intensidade da Dor E.V.A.:">
          <b-form-radio @change="numberQuestion = 9" v-model="selected8" name="some-radios-8" value="0">0</b-form-radio>
          <b-form-radio @change="numberQuestion = 9" v-model="selected8" name="some-radios-8" value="1">1</b-form-radio>
          <b-form-radio @change="numberQuestion = 9" v-model="selected8" name="some-radios-8" value="2">2</b-form-radio>
          <b-form-radio @change="numberQuestion = 9" v-model="selected8" name="some-radios-8" value="3">3</b-form-radio>
          <b-form-radio @change="numberQuestion = 9" v-model="selected8" name="some-radios-8" value="4">4</b-form-radio>
          <b-form-radio @change="numberQuestion = 9" v-model="selected8" name="some-radios-8" value="5">5</b-form-radio>
          <b-form-radio @change="numberQuestion = 9" v-model="selected8" name="some-radios-8" value="6">6</b-form-radio>
          <b-form-radio @change="numberQuestion = 9" v-model="selected8" name="some-radios-8" value="7">7</b-form-radio>
          <b-form-radio @change="numberQuestion = 9" v-model="selected8" name="some-radios-8" value="8">8</b-form-radio>
          <b-form-radio @change="numberQuestion = 9" v-model="selected8" name="some-radios-8" value="9">9</b-form-radio>
          <b-form-radio @change="numberQuestion = 9" v-model="selected8" name="some-radios-8" value="10">10</b-form-radio>
        </b-form-group>       

        <b-form-group v-show="numberQuestion == 9" label="9 - Localização e Característica da Dor:">
          <b-form-radio @change="numberQuestion = 10" v-model="selected9" name="some-radios-9" value="1">Pontual</b-form-radio>
          <b-form-radio @change="numberQuestion = 10" v-model="selected9" name="some-radios-9" value="2">Difusa</b-form-radio>
          <b-form-radio @change="numberQuestion = 10" v-model="selected9" name="some-radios-9" value="3">Irradiada</b-form-radio>
          <b-form-radio @change="numberQuestion = 10" v-model="selected9" name="some-radios-9" value="4">Outro</b-form-radio>
        </b-form-group>   

        <b-form-group v-show="numberQuestion == 10" label="10 - Mecanismo de Lesão:">
          <b-form-radio @change="numberQuestion = 11" v-model="selected10" name="some-radios-10" value="1">Traumática Direta</b-form-radio>
          <b-form-radio @change="numberQuestion = 11" v-model="selected10" name="some-radios-10" value="2">Traumática Indireta</b-form-radio>
          <b-form-radio @change="numberQuestion = 11" v-model="selected10" name="some-radios-10" value="3">Degenerativa</b-form-radio>
          <b-form-radio @change="numberQuestion = 11" v-model="selected10" name="some-radios-10" value="4">Pós Operatório</b-form-radio>
        </b-form-group>   

        <b-form-group v-show="numberQuestion == 11" label="11 - O Que Melhora e o Que Piora a Dor:">
            <b-form-textarea id="textarea" v-model="selected11" rows="1" max-rows="6"></b-form-textarea>       
            <b-button class="mt-2" variant="success" @click="numberQuestion = 12">Ok</b-button>            
        </b-form-group>    

        <b-form-group v-show="numberQuestion == 12" label="12 - Já Teve Outros Episódios da Queixa?">
          <b-form-radio @change="numberQuestion = 13" v-model="selected12" name="some-radios-12" value="1">Sim</b-form-radio>
          <b-form-radio @change="numberQuestion = 13" v-model="selected12" name="some-radios-12" value="2">Não</b-form-radio>
        </b-form-group>    

        <b-form-group v-show="numberQuestion == 13" label="13 - Já Teve Doença ou Cirurgia Importante (Comorbidades)?">
          <b-form-radio v-model="selected13_1" name="some-radios-13" value="1">Sim</b-form-radio>
          <b-form-radio v-model="selected13_1" name="some-radios-13" value="2">Não</b-form-radio>
          <b-form-textarea id="textarea" v-model="selected13_2" placeholder="Se sim, Qual?" rows="1" max-rows="6"></b-form-textarea>
          <b-button class="mt-2" variant="success" @click="numberQuestion = 14">Ok</b-button>
        </b-form-group>     

        <b-form-group v-show="numberQuestion == 14" label="14 - Tratamentos Anteriores:">
          <b-form-checkbox id="checkbox-14_1" v-model="selected14_1" name="checkbox-14_1" :value="true" :unchecked-value="false">Sem Intervenção</b-form-checkbox>
          <b-form-textarea id="textarea" v-model="selected14_2" rows="1" max-rows="6"></b-form-textarea>
          <b-button class="mt-2" variant="success" @click="numberQuestion = 15">Ok</b-button>
        </b-form-group>     

        <b-form-group v-show="numberQuestion == 15" label="15 - Medicações em Uso:">
          <b-form-checkbox id="checkbox-15_1" v-model="selected15_1" name="checkbox-15_1" :value="true" :unchecked-value="false">Sem Medicação</b-form-checkbox>
          <b-form-textarea id="textarea" v-model="selected15_2" rows="1" max-rows="6"></b-form-textarea>
          <b-button class="mt-2" variant="success" @click="numberQuestion = 16">Ok</b-button>
        </b-form-group>                                                                    

        <b-form-group v-show="numberQuestion == 16" label="16 - Sono">
          <b-form-radio @change="numberQuestion = 17" v-model="selected16" name="some-radios-16" value="1">Acorda com Dor</b-form-radio>
          <b-form-radio @change="numberQuestion = 17" v-model="selected16" name="some-radios-16" value="2">Dorme com Dor</b-form-radio>
          <b-form-radio @change="numberQuestion = 17" v-model="selected16" name="some-radios-16" value="3">Dorme Bem</b-form-radio>
        </b-form-group>                                                                 

        <b-form-group v-show="numberQuestion == 17" label="17 - Pratica Alguma Atividade Física:">
          <b-form-radio v-model="selected17_1" name="some-radios-17" value="1">Sim</b-form-radio>
          <b-form-radio v-model="selected17_1" name="some-radios-17" value="2">Não</b-form-radio>
          <b-form-textarea id="textarea" v-model="selected17_2" placeholder="Se sim, Qual Modalidade/Frquência/Tempo de Prática:" rows="1" max-rows="6"></b-form-textarea>
          <b-button class="mt-2" variant="success" @click="numberQuestion = 18">Ok</b-button>
        </b-form-group>   

        <b-form-group v-show="numberQuestion == 18" label="18 - Questionário (Carga Intrínseca Função/Dor):">
            <b-form-textarea id="textarea" v-model="selected18" rows="1" max-rows="6"></b-form-textarea>       
            <b-button class="mt-2" variant="success" @click="numberQuestion = 19">Ok</b-button>            
        </b-form-group>   

        <b-form-group v-show="numberQuestion == 19" label="Inspeção / Exame Físico">
          <b-form-group v-show="numberQuestion == 19" label="19 - Presença de Sinais Flogísticos:">
            <b-form-radio v-model="selected19_1" name="some-radios-19" value="1">Nenhum</b-form-radio>
            <b-form-radio v-model="selected19_1" name="some-radios-19" value="2">Dor</b-form-radio>
            <b-form-radio v-model="selected19_1" name="some-radios-19" value="3">Calor</b-form-radio>
            <b-form-radio v-model="selected19_1" name="some-radios-19" value="4">Edema</b-form-radio>
            <b-form-radio v-model="selected19_1" name="some-radios-19" value="5">Rubor</b-form-radio>
            <b-form-radio v-model="selected19_1" name="some-radios-19" value="6">Perda de Função</b-form-radio>
            <b-form-textarea id="textarea" v-model="selected19_2" rows="1" max-rows="6"></b-form-textarea>
            <b-button class="mt-2" variant="success" @click="numberQuestion = 20">Ok</b-button>
          </b-form-group>
        </b-form-group>                    

        <b-form-group v-show="numberQuestion == 20" label="20 - Inspeção Dinâmica:">
            <b-form-checkbox id="checkbox-20_1" v-model="selected20_1" name="checkbox-20_1" :value="true" :unchecked-value="false">Sem Alteração</b-form-checkbox>
            <b-form-textarea id="textarea" v-model="selected20_2" rows="1" max-rows="6"></b-form-textarea>       
            <b-button class="mt-2" variant="success" @click="numberQuestion = 21">Ok</b-button>            
        </b-form-group> 

        <b-form-group v-show="numberQuestion == 21" label="21 - Palpação:">
            <b-form-checkbox id="checkbox-21_1" v-model="selected21_1" name="checkbox-21_1" :value="true" :unchecked-value="false">Sem Alteração</b-form-checkbox>
            <b-form-textarea id="textarea" v-model="selected21_2" rows="1" max-rows="6"></b-form-textarea>       
            <b-button class="mt-2" variant="success" @click="numberQuestion = 22">Ok</b-button>            
        </b-form-group> 

        <b-form-group v-show="numberQuestion == 22" label="22 - Testes Ortopédicos:">
            <b-form-checkbox id="checkbox-22_1" v-model="selected22_1" name="checkbox-22_1" :value="true" :unchecked-value="false">Sem Alteração</b-form-checkbox>
            <b-form-textarea id="textarea" v-model="selected22_2" rows="1" max-rows="6"></b-form-textarea>       
            <b-button class="mt-2" variant="success" @click="numberQuestion = 23">Ok</b-button>            
        </b-form-group>       

        <b-form-group v-show="numberQuestion == 23" label="23 - Perimetria:">
            <b-form-checkbox id="checkbox-23_1" v-model="selected23_1" name="checkbox-23_1" :value="true" :unchecked-value="false">Simétrica e Normal</b-form-checkbox>  
            <b-form-textarea id="textarea" v-model="selected23_2" rows="1" max-rows="6"></b-form-textarea>       
            <b-button class="mt-2" variant="success" @click="numberQuestion = 24">Ok</b-button>            
        </b-form-group>     

        <b-form-group v-show="numberQuestion == 24" label="24 - Amplitude de Movimento (Ativa e Passiva):">
            <b-form-checkbox id="checkbox-24_1" v-model="selected24_1" name="checkbox-24_1" :value="true" :unchecked-value="false">Simétrica e Normal</b-form-checkbox>  
            <b-form-textarea id="textarea" v-model="selected24_2" rows="1" max-rows="6"></b-form-textarea>       
            <b-button class="mt-2" variant="success" @click="numberQuestion = 25">Ok</b-button>            
        </b-form-group>    

        <b-form-group v-show="numberQuestion == 25" label="25 - Testes de Força:">
            <b-form-checkbox id="checkbox-25_1" v-model="selected25_1" name="checkbox-25_1" :value="true" :unchecked-value="false">Simétrica e Normal</b-form-checkbox>  
            <b-form-textarea id="textarea" v-model="selected25_2" rows="1" max-rows="6"></b-form-textarea>       
            <b-button class="mt-2" variant="success" @click="numberQuestion = 26">Ok</b-button>            
        </b-form-group>    

        <b-form-group v-show="numberQuestion == 26" label="26 - Testes Funcionais:">
            <b-form-checkbox id="checkbox-26_1" v-model="selected26_1" name="checkbox-26_1" :value="true" :unchecked-value="false">Simétrica e Normal</b-form-checkbox>  
            <b-form-textarea id="textarea" v-model="selected26_2" rows="1" max-rows="6"></b-form-textarea>       
            <b-button class="mt-2" variant="success" @click="numberQuestion = 27">Ok</b-button>            
        </b-form-group>        

        <b-form-group v-show="numberQuestion == 27" label="27 - Exames Complementares:">
            <b-form-checkbox id="checkbox-27_1" v-model="selected27_1" name="checkbox-27_1" :value="true" :unchecked-value="false">Não Trouxe</b-form-checkbox>  
            <b-form-textarea id="textarea" v-model="selected27_2" rows="1" max-rows="6"></b-form-textarea>       
            <b-button class="mt-2" variant="success" @click="numberQuestion = 28">Ok</b-button>            
        </b-form-group>     

        <b-form-group v-show="numberQuestion == 28" label="Diagnóstico Fisioterapêutico:">
            <b-form-textarea id="textarea" v-model="selected28" rows="1" max-rows="6"></b-form-textarea>       
            <b-button class="mt-2" variant="success" @click="numberQuestion = 29">Ok</b-button>            
        </b-form-group>                                    

        <b-form-group v-show="numberQuestion == 29" label="Programa/Objetivos de Tratamento:">
            <b-form-textarea id="textarea" v-model="selected29" rows="1" max-rows="6"></b-form-textarea>                 
        </b-form-group>                                    
      </b-card>
    </div> 
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'
import Moment from 'moment'

export default {
    name: "AssessmentTrauma",
    components: { PageTitle },
    data() {
      return {
        patient: null,
        assessment: null,
        theme: null,
        numberQuestion: 1,
        selected1: null,
        selected2: null,
        selected3: null,
        selected4: null,
        selected5: null,
        selected6: null,
        selected7_1: false,
        selected7_2: false,
        selected7_3: false,
        selected7_4: false,
        selected7_5: false,
        selected7_6: false,
        selected7_7: false,
        selected7_8: false,
        selected7_9: false,
        selected7_10: false,
        selected8: null,
        selected9: null,
        selected10: null,
        selected11: null,
        selected12: null,
        selected13_1: null,
        selected13_2: null,
        selected14_1: null,
        selected14_2: null,
        selected15_1: null,
        selected15_2: null,
        selected16: null,
        selected17_1: null,
        selected17_2: null,
        selected18: null,
        selected19_1: null,
        selected19_2: null,
        selected20_1: null,
        selected20_2: null,
        selected21_1: null,
        selected21_2: null,
        selected22_1: null,
        selected22_2: null,
        selected23_1: null,
        selected23_2: null,
        selected24_1: null,
        selected24_2: null,
        selected25_1: null,
        selected25_2: null,
        selected26_1: null,
        selected26_2: null,
        selected27_1: null,
        selected27_2: null,
        selected28: null,
        selected29: null                         
      }
    },    
    methods: {
      sendQuestions() {
        // if (this.selected1 == null || this.selected2 == null ||
        //     this.selected3 == null || this.selected4 == null ||
        //     this.selected5 == null || this.selected6 == null ||
        //     (this.selected7_1 == null && this.selected7_2 == null && this.selected7_3 == null &&
        //      this.selected7_4 == null && this.selected7_5 == null && this.selected7_6 == null &&
        //      this.selected7_7 == null && this.selected7_8 == null && this.selected7_9 == null &&
        //      this.selected7_10 == null) ||
        //      this.selected8 == null || this.selected9 == null ||
        //      this.selected10 == null || this.selected11 == null ||
        //      this.selected12 == null ||
        //      (this.selected13_1 == null && this.selected13_2 == null) ||
        //      (this.selected14_1 == null && this.selected14_2 == null) ||
        //      (this.selected15_1 == null && this.selected15_2 == null) ||
        //      this.selected16 == null ||
        //      (this.selected17_1 == null && this.selected17_2 == null) ||
        //      this.selected18 == null ||
        //      (this.selected19_1 == null && this.selected19_2 == null) ||
        //      (this.selected20_1 == null && this.selected20_2 == null) ||
        //      (this.selected21_1 == null && this.selected21_2 == null) ||
        //      (this.selected22_1 == null && this.selected22_2 == null) ||
        //      (this.selected23_1 == null && this.selected23_2 == null) ||
        //      (this.selected24_1 == null && this.selected24_2 == null) ||
        //      (this.selected25_1 == null && this.selected25_2 == null) ||
        //      (this.selected26_1 == null && this.selected26_2 == null) ||
        //      (this.selected27_1 == null && this.selected27_2 == null) ||
        //      this.selected28 == null || this.selected29 == null) {
        //   this.$toasted.global.defaultError({ msg: 'Preencha todos os itens da avaliação' })
        // } else {     
          Moment.locale('pt-br')  
          
          axios.get(`${baseApiUrl}/patient/getById/${this.patient._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`).then(res => {
            this.patient = res.data

            for (let q of this.patient.questions) {
              if (q.type == 1 && q.name == this.assessment.name) {
                q.answers = []

                q.status = 1
                q.answers.push(
                  {
                    'dateAnswers': Moment(new Date()).format('L')
                  }, 
                  {
                    '1': this.selected1 
                  },
                  {
                    '2': this.selected2 
                  },
                  {
                    '3': this.selected3 
                  },
                  {
                    '4': this.selected4 
                  },
                  {
                    '5': this.selected5 
                  },
                  {
                    '6': this.selected6 
                  },                  
                  {
                    '7_1': this.selected7_1, 
                    '7_2': this.selected7_2, 
                    '7_3': this.selected7_3, 
                    '7_4': this.selected7_4, 
                    '7_5': this.selected7_5, 
                    '7_6': this.selected7_6, 
                    '7_7': this.selected7_7, 
                    '7_8': this.selected7_8, 
                    '7_9': this.selected7_9, 
                    '7_10': this.selected7_10, 
                  },
                  {
                    '8': this.selected8 
                  },
                  {
                    '9': this.selected9 
                  },
                  {
                    '10': this.selected10 
                  },
                  {
                    '11': this.selected11 
                  },
                  {
                    '12': this.selected12 
                  },
                  {
                    '13_1': this.selected13_1,
                    '13_2': this.selected13_2  
                  },
                  {
                    '14_1': this.selected14_1,
                    '14_2': this.selected14_2  
                  },
                  {
                    '15_1': this.selected15_1,
                    '15_2': this.selected15_2  
                  },
                  {
                    '16': this.selected16 
                  },
                  {
                    '17_1': this.selected17_1,
                    '17_2': this.selected17_2 
                  },
                  {
                    '18': this.selected18 
                  },
                  {
                    '19_1': this.selected19_1,
                    '19_2': this.selected19_2 
                  },
                  {
                    '20_1': this.selected20_1,
                    '20_2': this.selected20_2 
                  },
                  {
                    '21_1': this.selected21_1,
                    '21_2': this.selected21_2 
                  },
                  {
                    '22_1': this.selected22_1,
                    '22_2': this.selected22_2 
                  },
                  {
                    '23_1': this.selected23_1,
                    '23_2': this.selected23_2 
                  },
                  {
                    '24_1': this.selected24_1,
                    '24_2': this.selected24_2 
                  },
                  {
                    '25_1': this.selected25_1,
                    '25_2': this.selected25_2 
                  },
                  {
                    '26_1': this.selected26_1,
                    '26_2': this.selected26_2 
                  },
                  {
                    '27_1': this.selected27_1,
                    '27_2': this.selected27_2 
                  },
                  {
                    '28': this.selected28
                  },
                  {
                    '29': this.selected29 
                  }                                                                                                                                                                                                                                                                                                                                                                                                                                                               
                ) 
              }
            }            

            const method = 'put'            
            const id = `/${this.patient._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`

            axios[method](`${baseApiUrl}/patient${id}`, this.patient)
              .then(() => {
                  this.$toasted.global.defaultSuccess()
                  this.$router.push({name: 'notation', params: { },})
              })  
              .catch(showError) 
          }).catch((e) => {
            console.log(e)
            showError
          })
        // }
      }
    },
    mounted() {
      this.theme = localStorage.getItem('theme')

      this.patient = this.$route.params.questionSelected.patient
      this.assessment = this.$route.params.questionSelected

      if (this.$route.params.questionSelected.answers.length > 0) {
        this.selected1 = this.$route.params.questionSelected.answers[1]['1']

        this.selected2 = this.$route.params.questionSelected.answers[2]['2']

        this.selected3 = this.$route.params.questionSelected.answers[3]['3']

        this.selected4 = this.$route.params.questionSelected.answers[4]['4']

        this.selected5 = this.$route.params.questionSelected.answers[5]['5']

        this.selected6 = this.$route.params.questionSelected.answers[6]['6']

        this.selected7_1 = this.$route.params.questionSelected.answers[7]['7_1']
        this.selected7_2 = this.$route.params.questionSelected.answers[7]['7_2']
        this.selected7_3 = this.$route.params.questionSelected.answers[7]['7_3']
        this.selected7_4 = this.$route.params.questionSelected.answers[7]['7_4']
        this.selected7_5 = this.$route.params.questionSelected.answers[7]['7_5']
        this.selected7_6 = this.$route.params.questionSelected.answers[7]['7_6']
        this.selected7_7 = this.$route.params.questionSelected.answers[7]['7_7']  
        this.selected7_8 = this.$route.params.questionSelected.answers[7]['7_8']
        this.selected7_9 = this.$route.params.questionSelected.answers[7]['7_9']
        this.selected7_10 = this.$route.params.questionSelected.answers[7]['7_10']  
        
        this.selected8 = this.$route.params.questionSelected.answers[8]['8']

        this.selected9 = this.$route.params.questionSelected.answers[9]['9']

        this.selected10 = this.$route.params.questionSelected.answers[10]['10']

        this.selected11 = this.$route.params.questionSelected.answers[11]['11']

        this.selected12 = this.$route.params.questionSelected.answers[12]['12']

        this.selected13_1 = this.$route.params.questionSelected.answers[13]['13_1']
        this.selected13_2 = this.$route.params.questionSelected.answers[13]['13_2']
        
        this.selected14_1 = this.$route.params.questionSelected.answers[14]['14_1']
        this.selected14_2 = this.$route.params.questionSelected.answers[14]['14_2']
        
        this.selected15_1 = this.$route.params.questionSelected.answers[15]['15_1']
        this.selected15_2 = this.$route.params.questionSelected.answers[15]['15_2']
        
        this.selected16 = this.$route.params.questionSelected.answers[16]['16']    
        
        this.selected17_1 = this.$route.params.questionSelected.answers[17]['17_1']
        this.selected17_2 = this.$route.params.questionSelected.answers[17]['17_2']
        
        this.selected18 = this.$route.params.questionSelected.answers[18]['18']
        
        this.selected19_1 = this.$route.params.questionSelected.answers[19]['19_1']
        this.selected19_2 = this.$route.params.questionSelected.answers[19]['19_2']
        
        this.selected20_1 = this.$route.params.questionSelected.answers[20]['20_1']
        this.selected20_2 = this.$route.params.questionSelected.answers[20]['20_2']
        
        this.selected21_1 = this.$route.params.questionSelected.answers[21]['21_1']
        this.selected21_2 = this.$route.params.questionSelected.answers[21]['21_2']
        
        this.selected22_1 = this.$route.params.questionSelected.answers[22]['22_1']
        this.selected22_2 = this.$route.params.questionSelected.answers[22]['22_2']
        
        this.selected23_1 = this.$route.params.questionSelected.answers[23]['23_1']
        this.selected23_2 = this.$route.params.questionSelected.answers[23]['23_2']
        
        this.selected24_1 = this.$route.params.questionSelected.answers[24]['24_1']
        this.selected24_2 = this.$route.params.questionSelected.answers[24]['24_2']
        
        this.selected25_1 = this.$route.params.questionSelected.answers[25]['25_1']
        this.selected25_2 = this.$route.params.questionSelected.answers[25]['25_2']

        this.selected26_1 = this.$route.params.questionSelected.answers[26]['26_1']
        this.selected26_2 = this.$route.params.questionSelected.answers[26]['26_2']

        this.selected27_1 = this.$route.params.questionSelected.answers[27]['27_1']
        this.selected27_2 = this.$route.params.questionSelected.answers[27]['27_2']
        
        this.selected28 = this.$route.params.questionSelected.answers[28]['28']

        this.selected29 = this.$route.params.questionSelected.answers[29]['29']        
      }
    }
}
</script>

<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}

.card-violet {
  background-color: #f3eaff;
}
</style>