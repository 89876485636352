export default {
    treeMain: [
        {
            id: 2, name: "Cadastros", state: { checked: false }, children: [
                { id: 8, name: "Área de Atuação", state: { checked: false } },
                { id: 9, name: "Conselhos", state: { checked: false } },
                { id: 7, name: "Convênios", state: { checked: false } },
                { id: 10, name: "Locais de Internação", state: { checked: false } },
                { id: 18, name: "Ocorrências", state: { checked: false } },
                { id: 3, name: "Pacientes", state: { checked: false } },
                { id: 4, name: "Profissionais", state: { checked: false } },
                { id: 11, name: "Tipos de Atendimento", state: { checked: false } }
            ]
        },
        {
            id: 1, name: "Movimentação", state: { checked: false }, children: [
                { id: 5, name: "Admissão", state: { checked: false } },
                { id: 12, name: "Agenda", state: { checked: false } },
                { id: 14, name: "Apontamentos", state: { checked: false } },
                {
                    id: 13, name: "Escala", state: { checked: false }, children: [
                        { id: 15, name: "Montagem", state: { checked: false } },
                        { id: 16, name: "Processamento", state: { checked: false } }
                    ]
                }
            ]
        },
        {
            id: 19, name: "Financeiro", state: { checked: false }, children: [
                {
                    id: 20, name: "Faturamento", state: { checked: false }, children: [
                        { id: 23, name: "Contas a Receber", state: { checked: false } },
                        { id: 17, name: "Geração de Guias", state: { checked: false } },
                        { id: 37, name: "Lotes de Guias", state: { checked: false } }
                    ]
                }
            ]
        },
        {
            id: 6, name: "Relatórios", state: { checked: false }, children: [
                { id: 21, name: "Admissão x Local de Internação", state: { checked: false }, children: [] },
                { id: 22, name: "Admissão x Apartamentos", state: { checked: false }, children: [] }
            ]
        }
    ]
}