<template>
    <Loading v-if="isLoading" />
    <div v-else class="speciality-admin">
        <PageTitle icon="fa fa-object-group" main="Cadastro de Área de Atuação" sub />                  
        <b-form>            
            <input id="speciality-id" type="hidden" v-model="speciality.id"/>
            <b-row>
                <b-col md="6" sm="12">
                    <b-card :class="`card-${theme}`" bg-variant="default" header="Informações Gerais" header-bg-variant="dark" header-text-variant="white">
                        <b-form-group label="Nome:" label-for="speciality-name" class="ml-3 mr-3">
                            <b-form-input size="sm" id="speciality-name" type="text"
                                v-model="speciality.name" required                       
                                placeholder="Informe o Nome da Área de Atuação..." />
                        </b-form-group>         
                        <b-col md="10" sm="12">        
                            <b-form-group label-for="cbo-name">
                                <b-form-group label="CBO:" label-for="cbo-name">
                                    <b-form-select size="sm" v-model="selectedCBO" :options="cbo"></b-form-select>
                                </b-form-group>   
                            </b-form-group>                 
                        </b-col>
                    </b-card>
                </b-col>      
            </b-row>
        </b-form>

        <b-button size="sm" variant="success" @click="save" class="mt-3">SALVAR</b-button>  
        <b-button size="sm" variant="danger" @click="cancel" class="mt-3 ml-2">CANCELAR</b-button>  
    </div>
</template>

<script>
import PageTitle from "../../template/PageTitle";
import { baseApiUrl, userKey } from '@/global'
import axios from 'axios'
import Allcbo from '../../../json/cbo.json'
import Loading from "../../template/Loading"

export default {
    name: "SpecialityPage",
    components: { PageTitle, Loading },
    data: function() {
      return { 
        theme: null,
        isLoading: false,
        speciality: {},
        cbo: [],
        selectedCBO: null,
      }    
    },
    methods: {           
        save() {
            this.speciality.name = this.speciality.name.toUpperCase()

            const method = this.speciality._id ? 'put' : 'post'
            const id = this.speciality._id ? `/${this.speciality._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}` : `/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`         
            
            Allcbo.data.forEach(c => {
                if (c.CODIGO == this.selectedCBO) {
                    this.speciality.cbo = {
                        number: c.CODIGO,
                        name: c.TITULO
                    }
                }
            })

            this.isLoading = true

            axios[method](`${baseApiUrl}/speciality${id}`, this.speciality).then(() => {
                this.isLoading = false
                this.$toasted.global.defaultSuccess()
                this.$router.go(-1)
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })
            })
        },
        cancel() {
            this.$router.go(-1)
        }
    },
    mounted() {
        this.theme = localStorage.getItem('theme')

        Allcbo.data.forEach(c => {
            this.cbo.push({ value: c.CODIGO,  text: c.CODIGO + ' - ' + c.TITULO })     
            this.selectedCBO = c.CODIGO         
        })        

        if (this.$route.params.specialitySelected) {
            this.speciality = this.$route.params.specialitySelected
            this.selectedCBO = this.speciality.cbo.number
        }
    }
}     
</script>

<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}

.card-violet {
  background-color: #f3eaff;
}

.card-header {
    height: 38px;
    font-size: 14px;    
}

.card-body {
    font-size: 14px;
}

.calendar-btn {
    font-size: 0.30;
}

.table th {
  padding: 0.45rem;
}

.table td {
  padding: 0.45rem;
  vertical-align: middle;
}
</style>