<template>
  <div class="scaleBaseProcessing">
    <PageTitle icon="fa fa-calendar" main="Escala" sub="Processamento" />
        <div class="scale-page-tabs">
            <b-row>
                <b-col md="12" sm="12" class="mt-3">
                    <b-card :class="`card-${theme}`" header="Período de Processamento" header-bg-variant="dark" header-text-variant="white">
                        <b-row>
                            <b-col md="2" sm="12">
                                <b-form-group label="Data Inicial:" label-for="scaleBase-dateInitial" class="mt-4">
                                    <b-form-input size="sm" id="scaleBase-dateInitial" type="date" v-model="dateInitial" required />
                                </b-form-group>
                            </b-col>
                            <b-col md="2" sm="12">
                                <b-form-group label="Data Final:" label-for="scaleBase-dateFinal" class="mt-4">
                                    <b-form-input size="sm" id="scaleBase-dateFinal" type="date" v-model="dateFinal" required/>
                                </b-form-group>
                            </b-col>
                            <b-col md="3" sm="12">
                                <b-button size="sm" variant="success" class="mt-5" @click="ScaleProcessing">Processar</b-button>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>                  
            </b-row>
            <b-row>
                <b-col md="12" sm="12" class="mt-2">    
                    <b-progress class="w-100" :max="maxLoadingTime" height="1.5rem">
                        <b-progress-bar :value="loadingTime" :label="`${((loadingTime / maxLoadingTime) * 100).toFixed(2)}%`"></b-progress-bar>
                    </b-progress>        
                </b-col>
            </b-row>    
        </div>
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle"
import Moment from 'moment'
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'
import { mapState } from "vuex"

export default {
    name: "ScaleBaseProcessing",
    components: { PageTitle },    
    data: function() {
        return {
            theme: null,
            dateInitial: null,
            dateFinal: null,
            loading: false,
            loadingTime: 0,
            maxLoadingTime: 0,
            scaleBase: {},
            settings: null,
            newScale: {}
        }
    },
    watch: {
      loading(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.clearLoadingTimeInterval()

          if (newValue) {
            this.$_loadingTimeInterval = setInterval(() => {
              this.loadingTime++
            }, 1000)
          }
        }
      },
      loadingTime(newValue, oldValue) {
        if (newValue !== oldValue) {
          if (newValue === this.maxLoadingTime) {
            this.loading = false
          }
        }
      }
    },    
    computed: mapState(["user"]),
    methods: {
        ScaleProcessing() {
            let valid = true

            if (this.dateInitial == null || this.dateFinal == null) {
                this.$toasted.global.defaultError({msg: 'Informe a data inicial e final.'})
                valid = false
            } 

            Moment.locale('pt-br')

            let dtIni = Moment(this.dateInitial)
            let dtFim = Moment(this.dateFinal)  

            let diff = dtFim.diff(dtIni, 'days')

            if (diff < 0) {
                this.$toasted.global.defaultError({msg: 'Data final menor que data inicial.'})
                valid = false
            } 

            let today = new Date()
            today = Moment(`${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`)

            if (dtIni < today) {
                this.$toasted.global.defaultError({msg: 'O período informado já foi executado.'})
                valid = false
            }

            if (valid) {         
                this.$bvModal.msgBoxConfirm('Deseja processar o período informado?', {
                    title: 'Confirmação',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Sim',
                    cancelTitle: 'Não',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                }).then(value => {            
                    if (value) {
                        this.$bvModal.msgBoxConfirm('A operação irá reverter as escalas processadas nesse período. Deseja continuar?', {
                            title: 'Confirmação',
                            buttonSize: 'sm',
                            okVariant: 'danger',
                            okTitle: 'Sim',
                            cancelTitle: 'Não',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true
                        }).then(value => {            
                            if (value) {                            
                                this.maxLoadingTime = diff + 1       
                                
                                let weekday = new Array(7);
                                
                                weekday[0] = "sunday";
                                weekday[1] = "monday";
                                weekday[2] = "tuesday";
                                weekday[3] = "wednesday";
                                weekday[4] = "thursday";
                                weekday[5] = "friday";
                                weekday[6] = "saturday";     
                                
                                let dateOrigin = Moment(this.settings[0].params[5].value) 

                                let i = 0                                

                                for (i = 0; i <= this.maxLoadingTime - 1; i++) {                                                                
                                    let newDate = new Date(dtIni._d.getTime() + (i * 24 * 60 * 60 * 1000))    

                                    let day = weekday[newDate.getDay()]

                                    let week = (Moment(newDate).diff(dateOrigin, 'days') + 1) 

                                    if (week == 7) {
                                        week = 1
                                    } else {
                                        week = Math.ceil(week / 7)                            
                                    }

                                    while (week > 4) {
                                        week = week - 4
                                    }

                                    let dayScale = newDate.getDate()

                                    if (dayScale < 10) {
                                        dayScale = '0' + dayScale
                                    }

                                    let monthScale = newDate.getMonth() + 1

                                    if (monthScale < 10) {
                                        monthScale = '0' + monthScale
                                    }
                                
                                    let newDateScale = `${newDate.getFullYear()}-${monthScale}-${dayScale}`                                        
       
                                    axios.delete(`${baseApiUrl}/scale/date/${newDateScale}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`).then(() => {
                                        let y = 1
                                        
                                        for (let sb of this.scaleBase) {
                                            for (let p of sb.professionals) {
                                                let splBegin = p.checkin.split(":")
                                                let splFinal = p.checkout.split(":")

                                                let beginMin = (Number(splBegin[0] * 60)) + Number(splBegin[1])
                                                let finalMin = (Number(splFinal[0] * 60)) + Number(splFinal[1])      

                                                if ((finalMin - beginMin) / 60 == 12) { // Ordenar por carga horária
                                                    p.level = 100000
                                                } else {
                                                    p.level = y
                                                }

                                                y += 1
                                            }

                                            sb.professionals.sort(function (a,b) {
                                                if (a.level > b.level) return 1
                                                if (a.level < b.level) return -1
                                                return 0
                                            })                                                                                   
                                        }                                    

                                        for (let sb of this.scaleBase) {
                                            if (sb.day == day && sb.week == week) {
                                                this.newScale = {
                                                    date: newDateScale,
                                                    professionals: sb.professionals
                                                }

                                                axios['post'](`${baseApiUrl}/scale/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, this.newScale)
                                                    .then(() => {}).catch(showError)
                                            }
                                        } 
                                    }).catch(showError)                                                                    
                                }                                                               
                                
                                this.$toasted.global.defaultSuccess({msg: 'Escala processada com sucesso'})
                            }                            
                        }).catch(showError)                                            
                    }
                }).catch(showError)                            
            }
        },
        async removeScaleDate(date) {
            const url = `${baseApiUrl}/scale/date/${date}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.delete(url).catch(showError)                      
        },
        loadSettings() {
            if (!this.user.admin) {
                this.$toasted.global.defaultError({msg: 'Usuário sem permissão.'})
                this.$router.go(-1)
            }

            const url = `${baseApiUrl}/setting/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                if (res.data.length == 0) {
                    this.$toasted.global.defaultError({msg: 'Configurações do sistema não informadas'})
                } else {
                    this.settings = res.data                
                }
            }).catch(showError)                      
        },          
        loadScaleBase() {
            const url = `${baseApiUrl}/scaleBase/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                this.scaleBase = res.data  
            }).catch(showError)                              
        },
        clearLoadingTimeInterval() {
            clearInterval(this.$_loadingTimeInterval)
            this.$_loadingTimeInterval = null
        },
        startLoading() {
            this.loading = true
            this.loadingTime = 0
        },
    },
    created() {
      this.$_loadingTimeInterval = null
    },
    mounted() {
        this.theme = localStorage.getItem('theme')
        this.loadSettings()
        this.loadScaleBase()
    }
}
</script>

<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}

.card-violet {
  background-color: #f3eaff;
}
</style>