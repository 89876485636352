<template>
  <div :id="`app-${theme}`" :class="{'hide-menu': !isMenuVisible || !user}">
    <Header :hideToggle="!user" :hideUserDropdown="!user" />
    <Menu v-if="user" />
    <Loading v-if="validatingToken" />
    <Content v-else />
    <Footer /> 
  </div> 
</template>

<script>
import axios from "axios";
import { baseApiUrl, userKey } from "./global";
import { mapState } from "vuex";
import Header from "./components/template/Header";
import Menu from "./components/template/Menu";
import Content from "./components/template/Content";
import Footer from "./components/template/Footer";
import Loading from "./components/template/Loading";

export default {
  name: "App",
  components: { Header, Content, Footer, Loading, Menu },
  computed: mapState(["isMenuVisible", "user"]),
  data: function () {
    return {
      theme: null,
      title: null,
      validatingToken: true,
    };
  },
  methods: {
    async validateToken() {      
      let u = document.URL.split('=')

      if (u[1]) {
        if (u[1].toLowerCase() == '') {
          console.log('cliente não encontrado!')
        } else {
          localStorage.removeItem('client')

          if (u[0].includes('activation')) {
            let u2 = u[1].split('&') 
            localStorage.setItem('client', u2[0].toLowerCase())
          } else {
            localStorage.setItem('client', u[1].toLowerCase())
          }
        }
      }    

      this.validatingToken = true
    
      const json = localStorage.getItem(userKey)
      
      let userData = null

      if (json != '') {
        userData = JSON.parse(json);
      }

      this.$store.commit("setUser", null)
      
      if (localStorage.getItem('active') == 'false') {
        this.validatingToken = false;

        this.$router.push({ name: 'maintenance' })

        return;
      }        

      if (!userData) {
        this.validatingToken = false;

        if (!u[0].includes('authPatient') && !u[0].includes('activation')) {              
          this.$router.push({ name: 'auth' })

          return;
        } else if (u[0].includes('activation')) {
          let urlActivation = document.URL.split('&')          
        
          axios.post(`${baseApiUrl}/patient/activation/${localStorage.getItem('client')}&${urlActivation[1]}`, {}).then(() => {
              this.$toasted.global.defaultSuccess({ msg: 'Ativação realizada com sucesso.' })
              this.$router.push({name: 'authPatient', params: { },})

              return;
          }).catch(() => { 
            this.$toasted.global.defaultError({ msg: 'Não foi possível realizar a ativação.' })
            this.$router.push({name: 'authPatient', params: { },})
          })          
        } else {
          this.$router.push({ name: 'authPatient' })

          return;
        }                    
      } else {
        try {
          axios.post(`${baseApiUrl}/validateToken/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, userData).then(res => {
            if (res.status == 500) {
              localStorage.removeItem(userKey)

              this.validatingToken = false

              this.$router.push({ name: 'auth' })              
            }

            if (res.data) {
              this.$store.commit("setUser", userData)

              if (this.$mq === "xs" || this.$mq === "sm") {
                this.$store.commit("toggleMenu", false)
              }      
            } else {
              localStorage.removeItem(userKey)

              this.$router.push({ name: 'auth' })
            }    
            
            this.validatingToken = false              
          }).catch(e => {
            localStorage.removeItem(userKey)

            this.$router.push({ name: 'auth' })     
            
            this.validatingToken = false
             
            console.log(e)
          })
        } catch(e) {
          localStorage.removeItem(userKey)

          this.$router.push({ name: 'auth' })

          this.validatingToken = false

          console.log(e)
        }
      }      
    },
  },
  created() {
    this.theme = localStorage.getItem('theme')      
    this.title = localStorage.getItem('title') 
    this.validateToken()
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhai+2:wght@500&display=swap');

* {
  font-family: 'Baloo Bhai 2', cursive;
}

.card-header {
    height: 38px;
    font-size: 14px;    
}

.card-body {
    font-size: 14px;
}

.calendar-btn {
    font-size: 0.30;
}

.table th {
  padding: 0.45rem;
}

.table td {
  padding: 0.45rem;
  vertical-align: middle;
}

.card-green .b-calendar-grid-body {
  background-color: rgb(196, 235, 220);
}

.card-wine .b-calendar-grid-body {
  background-color: rgb(255, 241, 241);
}

.card-violet .b-calendar-grid-body {
  background-color: #f3eaff;
}

#app-green {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  display: grid;
  grid-template-rows: 60px 1fr 40px;
  grid-template-columns: 300px 1fr;
  grid-template-areas:
    "menu header"
    "menu content"
    "menu footer";
}

#app-wine {
  background-color: rgb(255, 241, 241);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  display: grid;
  grid-template-rows: 60px 1fr 40px;
  grid-template-columns: 300px 1fr;
  grid-template-areas:
    "menu header"
    "menu content"
    "menu footer";
}

#app-violet {
  background-color: #f3eaff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  display: grid;
  grid-template-rows: 60px 1fr 40px;
  grid-template-columns: 300px 1fr;
  grid-template-areas:
    "menu header"
    "menu content"
    "menu footer";
}

#app-green.hide-menu {
  grid-template-areas:
    "header header"
    "content content"
    "footer footer";
}

#app-wine.hide-menu {
  grid-template-areas:
    "header header"
    "content content"
    "footer footer";
}

#app-violet.hide-menu {
  grid-template-areas:
    "header header"
    "content content"
    "footer footer";
}

</style>