<template>
  <div :class="`auth-content-${theme}`">
    <div :class="`auth-modal-${theme}`">
      <img :src="logo" width="100 " alt="Logo"/>
      <hr />
      <div class="auth-title"><b>CONNECT - Área do Paciente</b></div>

      <input class="mt-3" v-model="user.email" type="text" placeholder="E-mail" />
      <input v-model="user.password" type="password" placeholder="Senha" />
      
      <button class="buttonSend" @click="signin">Enviar</button>
      <a class="linkCadastre" @click="cadastrePatient">Cadastre-se</a>
    </div>
  </div>
</template>

<script>
import { baseApiUrl, showError, userKey } from "../../global"
import axios from "axios"

export default {
  name: "AuthPatient",
  data: function () {
    return {
      theme: null,
      user: {},
      logo: ''
    };
  },
  methods: {
    signin() {            
      axios.post(`${baseApiUrl}/signinPatient/${localStorage.getItem('client')}`, this.user)
           .then((res) => {
              this.$store.commit("setUser", res.data);
              localStorage.setItem(userKey, JSON.stringify(res.data))
              this.$router.push({ path: "/" })
           }).catch(showError);
    },
    cadastrePatient() {
      this.$router.push({name: 'patientPageExternal', params: { },})
    }
  },
  mounted() {  
      this.theme = localStorage.getItem('theme')
      this.logo = localStorage.getItem('logo')    
  }
};
</script>

<style>
.auth-content-green {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7fff9;
}

.auth-content-wine {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 241, 241);
}

.auth-content-violet {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3eaff;
}

.auth-modal-green {
  border: 2px solid rgb(13, 99, 66);
  background-color: rgb(221, 241, 234);
  width: 350px;
  padding: 35px;
  box-shadow: 0 25px 30px #0d413f;  

  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth-modal-wine {
  border: 2px solid rgb(99, 13, 13);
  background-color: rgb(255, 241, 241);
  width: 350px;
  padding: 35px;
  box-shadow: 0 25px 30px rgb(99, 13, 13);  

  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth-modal-violet {
  border: 2px solid #6E4DA4;
  background-color: #f3eaff;
  width: 350px;
  padding: 35px;
  box-shadow: 0 25px 30px #6E4DA4;  

  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth-title-green {
  font-size: 1.3rem;
  font-weight: 100;
  margin-top: 10px;
  margin-bottom: 15px;
  color: #0d413f;
}

.auth-title-wine {
  font-size: 1.3rem;
  font-weight: 100;
  margin-top: 10px;
  margin-bottom: 15px;
  color: #7A1120;
}

.auth-title-violet {
  font-size: 1.3rem;
  font-weight: 100;
  margin-top: 10px;
  margin-bottom: 15px;
  color: #6E4DA4;
}

.auth-modal-green input {
  border: 1px solid #bbb;
  width: 100%;
  margin-bottom: 15px;
  padding: 3px 8px;
}

.auth-modal-wine input {
  border: 1px solid #7A1120;
  width: 100%;
  margin-bottom: 15px;
  padding: 3px 8px;
}

.auth-modal-violet input {
  border: 1px solid #6E4DA4;
  width: 100%;
  margin-bottom: 15px;
  padding: 3px 8px;
}

.auth-modal-green button {
  align-self: flex-end;
  background-color: #0d413f;
  color: #fff;
  padding: 5px 15px;
}

.auth-modal-wine button {
  align-self: flex-end;
  background-color: #7A1120;
  color: #fff;
  padding: 5px 15px;
}

.auth-modal-violet button {
  align-self: flex-end;
  background-color: #6E4DA4;
  color: #fff;
  padding: 5px 15px;
}

.auth-modal-green hr {
  border: 0;
  width: 100%;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(120, 120, 120, 0),
    rgba(120, 120, 120, 0.75),
    rgba(120, 120, 120, 0)
  );
}

.auth-modal-wine hr {
  border: 0;
  width: 100%;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(120, 120, 120, 0),
    rgba(120, 120, 120, 0.75),
    rgba(120, 120, 120, 0)
  );
}

.auth-modal-violet hr {
  border: 0;
  width: 100%;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(120, 120, 120, 0),
    rgba(120, 120, 120, 0.75),
    rgba(120, 120, 120, 0)
  );
}

.auth-modal-green a {
    align-self: flex-end;
}
</style>
