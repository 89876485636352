<template>
    <div :class="`user-admin-${theme}`">
        <b-form>
            <input id="user-id" type="hidden" v-model="user.email" />
            <b-row>
                <b-col md="3" sm="12">
                    <b-form-group label="Nome:" label-for="user-name">
                        <b-form-input size="sm" id="user-name" type="text"
                            v-model="user.name" required                       
                            placeholder="Informe o Nome do Usuário..." />
                    </b-form-group>
                </b-col>
                <b-col md="3" sm="12">
                    <b-form-group label="E-mail:" label-for="user-email">
                        <b-form-input size="sm" id="user-email" type="text"
                            v-model="user.email" required
                            placeholder="Informe o E-mail do Usuário..." />
                    </b-form-group>
                </b-col>
                <b-col md="2" sm="12">
                    <b-form-group label="CPF:" 
                        label-for="user-cpf">
                        <b-form-input size="sm" id="user-cpf" type="number"
                            v-model="user.cpf" required
                            placeholder="Confirme o CPF do Usuário..." />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="3" sm="12">
                    <b-form-group label="Senha:" label-for="user-password">
                        <b-form-input size="sm" id="user-password" type="password"
                            v-model="user.password" required
                            placeholder="Informe a Senha do Usuário..." />
                    </b-form-group>
                </b-col>
                <b-col md="3" sm="12">
                    <b-form-group label="Confirmação de Senha:" 
                        label-for="user-confirm-password">
                        <b-form-input size="sm" id="user-confirm-password" type="password"
                            v-model="user.confirmPassword" required
                            placeholder="Confirme a Senha do Usuário..." />
                    </b-form-group>
                </b-col>
                <b-col md="2" sm="12">
                    <b-form-group label="Senha App Mobile:" 
                        label-for="user-password-mobile">
                        <b-form-input size="sm" id="user-password-mobile" type="password"
                            v-model="user.passwordMobile" 
                            placeholder="Informe a Senha do App Mobile..." />
                    </b-form-group>
                </b-col>                
            </b-row>
            <b-row>
                <b-col md="3" sm="12">
                    <b-form-group label="Perfil de Acesso:" label-for="user-perfil-acesso">
                        <b-form-select size="sm" v-model="selectedAccessProfile" :options="accessProfiles"></b-form-select>
                    </b-form-group>  
                </b-col>
                <b-col md="2" sm="12" class="mt-2">
                    <b-form-checkbox id="user-admin" v-model="user.admin" class="mt-4">
                        Administrador?
                    </b-form-checkbox>                
                </b-col>    
            </b-row>
            <hr>
            <b-row>
                <b-col xs="12">
                    <b-button size="sm" variant="success" @click="save">SALVAR</b-button>  
                    <b-button size="sm" variant="danger" @click="remove" class="ml-2">REMOVER</b-button>  
                    <b-button size="sm" @click="reset" class="ml-2">CANCELAR</b-button>
                </b-col>
            </b-row>
        </b-form>
        <hr>
        <b-table ref="selectableTable" selectable striped :select-mode="selectMode" :items="users" :fields="fields" @row-selected="onRowSelected" responsive="sm">
            <template v-slot:cell(selected)="{rowSelected}">
                <template v-if="rowSelected">
                <span aria-hidden="true">&check;</span>
                </template>
                <template v-else>
                <span aria-hidden="true">&nbsp;</span>
                </template>
            </template>
        </b-table>
    </div>
</template>

<script>
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'

export default {
    name: 'UserAdmin',
    data: function() {
        return {
            theme: null,
            user: {},
            users: [],
            accessProfiles: [],
            selectedAccessProfile: null,          
            fields: [                 
                { key: 'name', label: 'Nome', sortable: true },
                { key: 'cpf', label: 'CPF', sortable: true },
                { key: 'email', label: 'E-mail', sortable: true },
                { key: 'admin', label: 'Administrador', sortable: true, formatter: value => value ? 'Sim' : 'Não' },
            ],
            selectMode: 'single',
        }
    },
    methods: {
        onRowSelected(itemSelected) {
            if (itemSelected.length > 0) {
                let userSelected = {    
                    _id: itemSelected[0]._id,
                    name: itemSelected[0].name,
                    email: itemSelected[0].email,
                    cpf: itemSelected[0].cpf,
                    admin: itemSelected[0].admin,            
                    accessprofile: itemSelected[0].accessProfile,
                    passwordMobile: itemSelected[0].passwordMobile
                }

                this.selectedAccessProfile = null

                this.accessProfiles.forEach(a => {
                    if (a.value === userSelected.accessprofile) {
                        this.selectedAccessProfile = a.value
                    }
                })            

                this.user = userSelected
            }
        },        
        loadUsers() {
            const url = `${baseApiUrl}/users/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                this.users = res.data
            })

            this.accessProfiles = []

            const url2 = `${baseApiUrl}/accessprofile/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url2)
                 .then(res => 
                    {
                        for (let r of res.data) {  
                            this.accessProfiles.push({ value: r._id,  text: r.name }) 
                        }
                    })
                 .catch(showError)                        
        },      
        reset() {
            this.user = {}
            this.loadUsers()        
            this.selectedAccessProfile = null
        },
        save() {
            this.user.name = this.user.name.toUpperCase()    

            const method = this.user._id ? 'put' : 'post'
            const id = this.user._id ? `${this.user._id}&` : ''

            this.user.accessProfile = this.selectedAccessProfile

            axios[method](`${baseApiUrl}/users/${id}${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, this.user)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                })
                .catch(showError)
        },
        remove() {            
            const id = this.user._id
            axios.delete(`${baseApiUrl}/users/${id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`).then(() => {
                this.$toasted.global.defaultSuccess()
                this.reset()
            }).catch(showError)
        },
    },
    mounted() {        
        this.loadUsers()
        this.theme = localStorage.getItem('theme')
    }
}
</script>

<style>
.user-admin-wine {
    background-color: rgb(255, 241, 241);
}

.user-admin-violet {
  background-color: #f3eaff;
}
</style>
