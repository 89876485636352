<template>
  <div class="satisfactionSurvey">
    <PageTitle icon="fa fa-file-text" main="Questionário" sub="MODIFIED MEDICAL RESEARCH COUNCIL (mMRC)" />
  
    <div>
      <b-card :class="`card-${theme}`">
        <b-button v-show="question.status == 0" @click="sendQuestions()" variant="info">Enviar</b-button>

        <b-form-group class="mt-3" label="">
          <b-form-group class="ml-5" label="SINTOMA:">
                <b-form-radio v-model="selected1" name="some-radios-1" value="0">0 - Tenho falta de ar ao realizar exercício intenso</b-form-radio>
                <b-form-radio v-model="selected1" name="some-radios-1" value="1">1 - Tenho falta de ar quando apresso o meu passo, ou subo escadas ou ladeira</b-form-radio>
                <b-form-radio v-model="selected1" name="some-radios-1" value="2">2 - Preciso parar algumas vezes quando ando no meu passo, ou ando mais devagar que outras pessoas de minha idade</b-form-radio>
                <b-form-radio v-model="selected1" name="some-radios-1" value="3">3 - Preciso parar muitas vezes devido à falta de ar quando ando perto de 100 metros, ou poucos minutos de caminhada no plano</b-form-radio>
                <b-form-radio v-model="selected1" name="some-radios-1" value="4">4 - Sinto tanta falta de ar que não saio de casa, ou preciso de ajuda para me vestir ou tomar banho sozinho</b-form-radio>
            </b-form-group>
        </b-form-group>       
                   
      </b-card>
    </div> 
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'
import Moment from 'moment'

export default {
    name: "SatisfactionSurvey",
    components: { PageTitle },
    data() {
      return {
        patient: null,
        question: null,
        theme: null,
        numberQuestion: 1,
        selected1: null,
      }
    },    
    methods: {
      sendQuestions() {
          Moment.locale('pt-br')  
          
          axios.get(`${baseApiUrl}/patient/getById/${this.patient._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`).then(res => {
            this.patient = res.data

            let data = {}
            let index = 0

            for (let q of this.patient.questions) {
              if (q.type == 2 && q.name == this.question.name && q.answers.length == 0) {
                q.answers = []

                q.status = 1
                q.answers.push(
                  {
                    'dateAnswers': Moment(new Date()).format('L')
                  }, 
                  {
                    '1': this.selected1
                  }                                                                                                                                                                                                                                                                                                                                                                                                                                                            
                ) 

                data.index = index
                data.answers = q.answers
              }

              index += 1
            }        
            
            data.idPatient = this.patient._id

            axios.post(`${baseApiUrl}/patient/setTestQuestion/${this.patient._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, data)
              .then(() => {
                  this.$store.commit('setResultPatient', this.patient)

                  this.$toasted.global.defaultSuccess()
                  this.$router.go(-1)
              })  
              .catch(showError) 
          }).catch((e) => {
            console.log(e)
            showError
          })
        // }
      }
    },
    created() {
      this.theme = localStorage.getItem('theme')

      this.patient = this.$route.params.questionSelected.patient
      this.question = this.$route.params.questionSelected

      if (this.$route.params.questionSelected.answers.length > 0) {
        this.selected1 = this.$route.params.questionSelected.answers[1]['1']      
      }
    }
}
</script>

<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}

.card-violet {
  background-color: #f3eaff;
}
</style>