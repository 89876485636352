<template>
  <Loading v-if="isLoading" />
  <div v-else class="admissions-pages">
    <PageTitle icon="fa fa-sign-in" main="Admissões" sub />
    <div class="admin-pages-tabs">
        <b-row>            
            <b-col md="2" sm="12">
                <b-form-group label="Tipo Filtro:" label-for="filter-type" class="mt-3">
                    <b-form-select size="sm" :options="filters" v-model="filterSelected"></b-form-select>
                </b-form-group>
            </b-col>            
            <b-col md="4" sm="12">
                <b-form-group label="Informação:" label-for="filter-information" class="mt-3">
                    <b-input size="sm" v-model="information"></b-input>                
                </b-form-group>
            </b-col>      
            <b-col md="2" sm="12">
                <b-button size="sm" variant="info" @click="filterAdmissions()" class="mt-5">
                    CONSULTAR
                </b-button>                      
            </b-col>                                
        </b-row>
        <hr>
        <b-button size="sm" variant="success" @click="newAdmission()">
            NOVO
        </b-button>      
        <hr>                
        <b-row>            
            <b-col md="4" sm="12">
                <b-form-input size="sm" placeholder="Filtrar..." v-model="ftext"></b-form-input>
            </b-col>
        </b-row>
        <b-table head-variant="light" outlined hover :fields="fields" responsive="sm" id="my-table" :items="admissions" class="mt-3"
                 :per-page="perPage" :current-page="currentPage" fixed :filter="ftext">
            <template v-slot:cell(actions)="row">
                <b-button size="sm" variant="warning" @click="updateAdmission(row.item)" v-b-popover.hover.top="'Alterar'" class="ml-2">
                    <i class="fa fa-pencil"></i>
                </b-button>      
                <b-button size="sm" variant="secondary" @click="setRelease(row.item)" v-b-popover.hover.top="'Alta'" class="ml-2" v-show="!row.item.released">
                    <i class="fa fa-sign-out"></i>
                </b-button>                                
            </template>                 
        </b-table>        

        <b-row class="ml-1">
          <b-pagination size="sm" pills v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table" class="page-item mt-2">
          </b-pagination>         
            <h5 class="badge1"><b-badge class="mt-2 ml-3" pill variant="success">Recebeu Alta</b-badge></h5>            
        </b-row>    
    </div>
  </div>  
</template>

<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, userKey } from '@/global'
import axios from 'axios'
import Moment from 'moment'
import Loading from "../template/Loading"

export default {
    name: 'Admissions',
    components: { PageTitle, Loading },
    data: function() {
      return {
        isLoading: false,
        ftext: '',
        filters: [
            "Número Atendimento",
            "CPF",
            "Nome",
            "Convênio",
            "Prédio"                        
        ],
        filterSelected: null,
        information: null,
        admissions: [],
        admission: null,
        dateInitial: null,
        dateFinal: null,
        accommodation: null,
        namePatient: null,
        cpfPatient: null,
        covenant: null,
        nameProfessional: null,
        currentPage: 1,
        perPage: 10,
        fields: [                 
            { key: 'attendanceNumber', label: 'Nº Atendimento', sortable: true },
            { key: 'patient.name', label: 'Paciente', sortable: true },
            { key: 'patient.cpf', label: 'CPF', sortable: true },
            { key: 'accommodationFull', label: 'Local de Internação', sortable: true },
            { key: 'date2', label: 'Data', sortable: true },
            { key: 'actions', label: 'Ações' }
        ],          
      }
    },    
    computed: {
      rows() {
        return this.admissions.length
      }
    },    
    methods: {
        newAdmission() {
          this.$store.commit("setResultAdmission", {})
          this.$router.push({name: 'admission', params: { },})          
        },     
        filterAdmissions() {
            if (this.filterSelected == null) {
                this.$toasted.global.defaultError({msg: "Selecione o tipo de filtro"})
            } else {
                if (this.information == null || this.information == '') {
                    this.$toasted.global.defaultError({msg: "Digite alguma informação para consultar"}) 
                } else {
                    this.information = this.information.toUpperCase()

                    this.admissions = []

                    this.admission = null

                    this.isLoading = true

                    const url = `${baseApiUrl}/admission/notreleased/${this.filterSelected}&${this.information}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
                    axios.get(url).then(res => {
                        this.admissions = res.data   
                        
                        for (let a of this.admissions) {
                            Moment.locale('pt-br')

                            a.date2 = Moment(a.date).format('L')
                            
                            a.accommodationFull = `${a.accommodation.building} -> ${a.accommodation.floor} -> ${a.accommodation.apartment} -> ${a.accommodation.name}`

                            a._cellVariants = {
                                attendanceNumber: a.released ? 'success' : '',
                                'patient.name': a.released ? 'success' : '',
                                'patient.cpf': a.released ? 'success' : '',
                                accommodationFull: a.released ? 'success' : '',
                                date2: a.released ? 'success' : '',
                                actions: a.released ? 'success' : ''
                            }                            
                        }

                        this.isLoading = false
                    }).catch(e => {
                        this.isLoading = false
                        this.$toasted.global.defaultError({ msg: e.response.data })
                    })    
                }
            }
        },
        setRelease(itemSelected) {
            this.$bvModal.msgBoxConfirm('Deseja efetuar a alta do paciente?', {
                title: 'Confirmação',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Sim',
                cancelTitle: 'Não',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            }).then(value => {            
                if (value) {                
                    const method = 'post'

                    this.isLoading = true
        
                    axios[method](`${baseApiUrl}/admission/patient/${itemSelected.patient._id}&${itemSelected._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`)
                        .then(() => {
                            this.isLoading = false
                            this.$toasted.global.defaultSuccess()
                            this.filterAdmissions()
                        }).catch(e => {
                            this.isLoading = false
                            this.$toasted.global.defaultError({ msg: e.response.data })
                        })                    
                }                
            }).catch()  
        },
        updateAdmission(itemSelected) {
          let admissionSelected = {    
              _id: itemSelected._id,
              patient: itemSelected.patient,
              accommodation: itemSelected.accommodation,
              professional: itemSelected.professional,
              attendance: itemSelected.attendance,
              released: itemSelected.released,
              dateReleased: itemSelected.dateReleased,
              hourReleased: itemSelected.hourReleased,
              date: itemSelected.date,
              hour: itemSelected.hour,
              attendanceNumber: itemSelected.attendanceNumber,
              numberGuide: itemSelected.numberGuide,
              password: itemSelected.password,
              categoryAttendance: itemSelected.categoryAttendance,
              comments: itemSelected.comments,
              medicalReport: itemSelected.medicalReport
          }              

          this.$router.push({name: 'admission', params: { admissionSelected },})
        }   
    },
    mounted() {
        this.admission = null
        this.dateInitial = Date.now() + 1
        this.dateFinal = Date.now() + 1
    }
}
</script>

<style>
.page-item .page-font {
    color:#44A08D;  
}

.page-item.active .page-link {  
    background-color:#44A08D;  
    border-color:  #44A08D;      
}

.page-item.active .page-link:hover {  
    background-color:#44A08D;  
    border-color:  #44A08D;  
}
</style>