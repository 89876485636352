import axios from 'axios'

const success = res => res
const error = err => {
    if (err.response.status === 401) {
        window.location = '/'
    } else if (err.response.status > 401) {
        localStorage.removeItem('__connect_user')
        window.location = '/'
    } else if (err.response.status >= 300 && err.response.status < 400) {
        localStorage.removeItem('__connect_user')
        window.location = '/'
    } else {
        return Promise.reject(err)
    }
}

axios.interceptors.response.use(success, error) 