<template>
    <div class="assessmentPelvic">
        <PageTitle icon="fa fa-file-text" main="Ficha de Avaliação" sub="Pélvica" />

        <div>
            <b-card :class="`card-${theme}`">
                <b-button @click="sendQuestions()" variant="info">Enviar</b-button>
                <b-form-group class="mt-3" :label="numberQuestion <= 16 ? 'ANAMNESE:' : 'EXAME FÍSICO'">
                    <b-button-group>
                        <b-button @click="numberQuestion = 1" :variant="selected1_1 != null ? 'success' : 'secondary'">1</b-button>
                        <b-button @click="numberQuestion = 2" :variant="(selected2_1 != false ||
                                                                         selected2_2 != false ||
                                                                         selected2_3 != false ||
                                                                         selected2_4 != false ||
                                                                         selected2_5 != false ||
                                                                         selected2_6 != false) ? 'success' : 'secondary'">2</b-button>
                        <b-button @click="numberQuestion = 3" :variant="(selected3_1 != null &&
                                                                         selected3_2 != null &&
                                                                         selected3_3 != null) ? 'success' : 'secondary'">3</b-button>
                        <b-button @click="numberQuestion = 4" :variant="selected4 != null ? 'success' : 'secondary'">4</b-button>
                        <b-button @click="numberQuestion = 5" :variant="(selected5_1 != false ||
                                                                         selected5_2 != false ||
                                                                         selected5_3 != false ||
                                                                         selected5_4 != false ||
                                                                         selected5_5 != false ||
                                                                         selected5_6 != false ||
                                                                         selected5_7 != false ||
                                                                         selected5_8 != false ||
                                                                         selected5_9 != false ||
                                                                         selected5_10 != false) ? 'success' : 'secondary'">5</b-button>
                        <b-button @click="numberQuestion = 6" :variant="(selected6_1 != false ||
                                                                         selected6_2 != false ||
                                                                         selected6_3 != false ||
                                                                         selected6_4 != false ||
                                                                         selected6_5 != false ||
                                                                         selected6_6 != false ||
                                                                         selected6_7 != false ||
                                                                         selected6_8 != false) ? 'success' : 'secondary'">6</b-button>
                        <b-button @click="numberQuestion = 7" :variant="(selected7_1 != false ||
                                                                         selected7_2 != false ||
                                                                         selected7_3 != false ||
                                                                         selected7_4 != false ||
                                                                         selected7_5 != false ||
                                                                         selected7_6 != false ||
                                                                         selected7_7 != false) ? 'success' : 'secondary'">7</b-button>
                        <b-button @click="numberQuestion = 8" :variant="selected8 != null ? 'success' : 'secondary'">8</b-button>
                        <b-button @click="numberQuestion = 9" :variant="(selected9_1 != null ||
                                                                         selected9_2 != null ||
                                                                         selected9_3 != null) ? 'success' : 'secondary'">9</b-button>
                        <b-button @click="numberQuestion = 10" :variant="(selected10_1 != null ||
                                                                          selected10_2 != null ||
                                                                          selected10_3 != null ) ? 'success' : 'secondary'">10</b-button>
                        <b-button @click="numberQuestion = 11" :variant="(selected11_1 != null ||
                                                                          selected11_2 != null ||
                                                                          selected11_3 != null ||
                                                                          selected11_4 != null ||
                                                                          selected11_5 != null ||
                                                                          selected11_6 != null ||
                                                                          selected11_7 != null ||
                                                                          selected11_8 != null) ? 'success' : 'secondary'">11</b-button>                 
                        <b-button @click="numberQuestion = 12" :variant="(selected12_1 != null ||
                                                                          selected12_2 != null ||
                                                                          selected12_3 != null ||
                                                                          selected12_4 != null ||
                                                                          selected12_5 != null ||
                                                                          selected12_6 != null ||
                                                                          selected12_7 != null ||
                                                                          selected12_8 != null ||
                                                                          selected12_9 != null) ? 'success' : 'secondary'">12</b-button>                 
                        <b-button @click="numberQuestion = 13" :variant="(selected13_1 != false ||
                                                                          selected13_2 != false ||
                                                                          selected13_3 != false ||
                                                                          selected13_4 != false ||
                                                                          selected13_5 != null ||
                                                                          selected13_6 != false ||
                                                                          selected13_7 != false ||
                                                                          selected13_8 != false ||
                                                                          selected13_9 != false ||
                                                                          selected13_10 != null ||
                                                                          selected13_11 != false ||
                                                                          selected13_12 != null) ? 'success' : 'secondary'">13</b-button>                 
                        <b-button @click="numberQuestion = 14" :variant="(selected14_1 != false ||
                                                                          selected14_2 != null ||
                                                                          selected14_3 != null ||
                                                                          selected14_4 != false ||
                                                                          selected14_5 != null ||
                                                                          selected14_6 != false ||
                                                                          selected14_7 != null ||
                                                                          selected14_8 != null) ? 'success' : 'secondary'">14</b-button>                 
                        <b-button @click="numberQuestion = 15" :variant="selected15 != null ? 'success' : 'secondary'">15</b-button>  
                        <b-button @click="numberQuestion = 16" :variant="(selected16_1 != null ||
                                                                          selected16_2 != null ||
                                                                          selected16_3 != null ||
                                                                          selected16_4 != null) ? 'success' : 'secondary'">16</b-button> 
                        <b-button @click="numberQuestion = 17" :variant="(selected17_1 != null ||
                                                                          selected17_2 != null ||
                                                                          selected17_3 != null ||
                                                                          selected17_4 != null ||
                                                                          selected17_5 != null) ? 'success' : 'secondary'">17</b-button> 
                        <b-button @click="numberQuestion = 18" :variant="(selected18_1 != null ||
                                                                          selected18_2 != null ||
                                                                          selected18_3 != null ||
                                                                          selected18_4 != null ||
                                                                          selected18_5 != null ||
                                                                          selected18_6 != null ||
                                                                          selected18_7 != null ||
                                                                          selected18_8 != null ||
                                                                          selected18_9 != null ||
                                                                          selected18_10 != null ||
                                                                          selected18_11 != null ||
                                                                          selected18_12 != null ||
                                                                          selected18_13 != null ||
                                                                          selected18_14 != null ||
                                                                          selected18_15 != null) ? 'success' : 'secondary'">18</b-button> 
                        <b-button @click="numberQuestion = 19" :variant="(selected19_1 != null ||
                                                                          selected19_2 != null ||
                                                                          selected19_3 != null ||
                                                                          selected19_4 != null ||
                                                                          selected19_5 != null ||
                                                                          selected19_6 != null ||
                                                                          selected19_7 != null) ? 'success' : 'secondary'">19</b-button> 
                        <b-button @click="numberQuestion = 20" :variant="(selected20_1 != null ||
                                                                          selected20_2 != null ||
                                                                          selected20_3 != null ||
                                                                          selected20_4 != null) ? 'success' : 'secondary'">20</b-button>   
                        <b-button @click="numberQuestion = 21" :variant="(selected21_1 != null ||
                                                                          selected21_2 != null ||
                                                                          selected21_3 != null ||
                                                                          selected21_4 != null) ? 'success' : 'secondary'">21</b-button>
                        <b-button @click="numberQuestion = 22" :variant="(selected22_1 != null ||
                                                                          selected22_2 != null ||
                                                                          selected22_3 != null ||
                                                                          selected22_4 != null ||
                                                                          selected22_5 != null) ? 'success' : 'secondary'">22</b-button>
                        <b-button @click="numberQuestion = 23" :variant="(selected23_1 != null ||
                                                                          selected23_2 != null ||
                                                                          selected23_3 != null ||
                                                                          selected23_4 != null ||
                                                                          selected23_5 != null) ? 'success' : 'secondary'">23</b-button>
                        <b-button @click="numberQuestion = 24" :variant="(selected24_1 != null ||
                                                                          selected24_2 != null) ? 'success' : 'secondary'">24</b-button>
                        <b-button @click="numberQuestion = 25" :variant="(selected25_1 != null ||
                                                                          selected25_2 != null) ? 'success' : 'secondary'">25</b-button>
                        <b-button class="ml-2" @click="numberQuestion = 26" variant="primary">Questionários</b-button>
                    </b-button-group>
                </b-form-group>

                <b-form-group v-show="numberQuestion == 1" class="mt-3">
                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="Nome:">
                                <b-form-input type="text" v-model="selected1_1" />                    
                            </b-form-group>                
                        </b-col>
                    </b-row>

                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="Endereço:">
                                <b-form-input type="text" v-model="selected1_2" />                    
                            </b-form-group>                
                        </b-col>
                    </b-row>                    

                    <b-row> 
                        <b-col md="6" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="E-mail:">
                                <b-form-input type="text" v-model="selected1_3" />                    
                            </b-form-group>                
                        </b-col>                                     
                        <b-col md="6" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="Telefone:">
                                <b-form-input type="text" v-model="selected1_4" />                    
                            </b-form-group>                
                        </b-col>                       
                    </b-row>   

                    <b-row> 
                        <b-col md="6" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="ID:">
                                <b-form-input type="text" v-model="selected1_5" />                    
                            </b-form-group>                
                        </b-col>                                     
                        <b-col md="6" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="CPF:">
                                <b-form-input type="text" v-model="selected1_6" />                    
                            </b-form-group>                
                        </b-col>                       
                    </b-row>        

                    <b-row> 
                        <b-col md="4" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="Data Nascimento:">
                                <b-form-input type="date" v-model="selected1_7" />                    
                            </b-form-group>                
                        </b-col>                                     
                        <b-col md="4" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="Idade:">
                                <b-form-input type="text" v-model="selected1_8" />                    
                            </b-form-group>                
                        </b-col>       
                        <b-col md="4" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="Profissão:">
                                <b-form-input type="text" v-model="selected1_9" />                    
                            </b-form-group>                
                        </b-col>                         
                    </b-row>                                            

                    <b-row> 
                        <b-col md="6" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="Indicado Por:">
                                <b-form-input type="text" v-model="selected1_10" />                    
                            </b-form-group>                
                        </b-col>                                     
                        <b-col md="6" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="Fisioterapeuta Responsável:">
                                <b-form-input type="text" v-model="selected1_11" />                    
                            </b-form-group>                
                        </b-col>                       
                    </b-row>     

                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="Diagnóstico Médico:">
                                <b-form-textarea id="textarea" v-model="selected1_12" placeholder="" rows="1" max-rows="6"></b-form-textarea>                     
                            </b-form-group>                
                        </b-col>  
                    </b-row>       

                    <b-row> 
                        <b-col md="2" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="Data da Avaliação:">
                                <b-form-input type="date" v-model="selected1_13" />                    
                            </b-form-group>                
                        </b-col>  
                    </b-row>                                 
                </b-form-group>            

                <b-button v-show="numberQuestion == 1" variant="success" @click="numberQuestion = 2">Ok</b-button>


                <b-form-group v-show="numberQuestion == 2" class="mt-3">
                    <b-row> 
                        <b-col>
                            <b-form-checkbox id="checkbox-2_1" v-model="selected2_1" name="checkbox-2_1" :value="true" :unchecked-value="false">Gestante</b-form-checkbox>
                            <b-form-checkbox id="checkbox-2_2" v-model="selected2_2" name="checkbox-2_2" :value="true" :unchecked-value="false">Incontinência</b-form-checkbox>
                            <b-form-checkbox id="checkbox-2_3" v-model="selected2_3" name="checkbox-2_3" :value="true" :unchecked-value="false">Urinária/Fecal</b-form-checkbox>
                            <b-form-checkbox id="checkbox-2_4" v-model="selected2_4" name="checkbox-2_4" :value="true" :unchecked-value="false">Disf. Sexual</b-form-checkbox>
                            <b-form-checkbox id="checkbox-2_5" v-model="selected2_5" name="checkbox-2_5" :value="true" :unchecked-value="false">Oncologia</b-form-checkbox>
                            <b-form-checkbox id="checkbox-2_6" v-model="selected2_6" name="checkbox-2_6" :value="true" :unchecked-value="false">Outros</b-form-checkbox>                                                                                                 
                        </b-col>
                    </b-row>
                </b-form-group>

                <b-button v-show="numberQuestion == 2" variant="success" @click="numberQuestion = 3">Ok</b-button>

                <b-form-group v-show="numberQuestion == 3" class="mt-3">
                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 3" label="Queixa Principal:">
                                <b-form-textarea id="textarea" v-model="selected3_1" placeholder="" rows="1" max-rows="6"></b-form-textarea>                   
                            </b-form-group>                
                        </b-col>                                                                       
                    </b-row>  

                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 3" label="HDA:">
                                <b-form-textarea id="textarea" v-model="selected3_2" placeholder="" rows="1" max-rows="6"></b-form-textarea>                   
                            </b-form-group>                
                        </b-col>                                                                       
                    </b-row>           

                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 3" label="HPP:">
                                <b-form-textarea id="textarea" v-model="selected3_3" placeholder="" rows="1" max-rows="6"></b-form-textarea>                   
                            </b-form-group>                
                        </b-col>                                                                       
                    </b-row>                                
                </b-form-group>               

                <b-button v-show="numberQuestion == 3" variant="success" @click="numberQuestion = 4">Ok</b-button>  


                <b-form-group v-show="numberQuestion == 4" class="mt-3">
                    <b-row> 
                        <b-col md="4" sm="12">   
                            <b-form-group v-show="numberQuestion == 4" label="Quanto Tempo Depois do Aparecimento dos Sintomas Procurou Ajuda Médica?">
                                <b-form-radio @change="numberQuestion = 5" v-model="selected4" name="some-radios-4" value="0">Até 3 Meses</b-form-radio>
                                <b-form-radio @change="numberQuestion = 5" v-model="selected4" name="some-radios-4" value="1">3 a 6 Meses</b-form-radio>
                                <b-form-radio @change="numberQuestion = 5" v-model="selected4" name="some-radios-4" value="2">> 6 Meses</b-form-radio>
                                <b-form-radio @change="numberQuestion = 5" v-model="selected4" name="some-radios-4" value="3">> 1 Ano</b-form-radio>
                            </b-form-group>               
                        </b-col>                                             
                    </b-row>
                </b-form-group>             


                <b-form-group v-show="numberQuestion == 5" class="mt-3" label="Sintomas Urinários:">
                   <b-row> 
                        <b-col>
                            <b-form-checkbox id="checkbox-5_1" v-model="selected5_1" name="checkbox-5_1" :value="true" :unchecked-value="false">Perda de Urina aos Esforços</b-form-checkbox>
                            <b-form-checkbox id="checkbox-5_2" v-model="selected5_2" name="checkbox-5_2" :value="true" :unchecked-value="false">Frequência (Vezes)</b-form-checkbox>
                            <b-form-checkbox id="checkbox-5_3" v-model="selected5_3" name="checkbox-5_3" :value="true" :unchecked-value="false">Urgência</b-form-checkbox>
                            <b-form-checkbox id="checkbox-5_4" v-model="selected5_4" name="checkbox-5_4" :value="true" :unchecked-value="false">Urge – Incontinência</b-form-checkbox>
                            <b-form-checkbox id="checkbox-5_5" v-model="selected5_5" name="checkbox-5_5" :value="true" :unchecked-value="false">Noctúria (Vezes)</b-form-checkbox>
                            <b-form-checkbox id="checkbox-5_6" v-model="selected5_6" name="checkbox-5_6" :value="true" :unchecked-value="false">Hesitação</b-form-checkbox>   
                            <b-form-checkbox id="checkbox-5_7" v-model="selected5_7" name="checkbox-5_7" :value="true" :unchecked-value="false">Disúria</b-form-checkbox>
                            <b-form-checkbox id="checkbox-5_8" v-model="selected5_8" name="checkbox-5_8" :value="true" :unchecked-value="false">Esforço ao Urinar</b-form-checkbox>
                            <b-form-checkbox id="checkbox-5_9" v-model="selected5_9" name="checkbox-5_9" :value="true" :unchecked-value="false">SEVI</b-form-checkbox>
                            <b-form-checkbox id="checkbox-5_10" v-model="selected5_10" name="checkbox-5_10" :value="true" :unchecked-value="false">Gotejamento Pós Miccção</b-form-checkbox>                                                                                              
                        </b-col>
                    </b-row>
                </b-form-group>

                <b-button v-show="numberQuestion == 5" variant="success" @click="numberQuestion = 6">Ok</b-button>      


                <b-form-group v-show="numberQuestion == 6" class="mt-3" label="Tipo de Perda:">
                   <b-row> 
                        <b-col>
                            <b-form-checkbox id="checkbox-6_1" v-model="selected6_1" name="checkbox-6_1" :value="true" :unchecked-value="false">Tosse</b-form-checkbox>
                            <b-form-checkbox id="checkbox-6_2" v-model="selected6_2" name="checkbox-6_2" :value="true" :unchecked-value="false">Espirro</b-form-checkbox>
                            <b-form-checkbox id="checkbox-6_3" v-model="selected6_3" name="checkbox-6_3" :value="true" :unchecked-value="false">Agachar</b-form-checkbox>
                            <b-form-checkbox id="checkbox-6_4" v-model="selected6_4" name="checkbox-6_4" :value="true" :unchecked-value="false">Erguer Peso</b-form-checkbox>
                            <b-form-checkbox id="checkbox-6_5" v-model="selected6_5" name="checkbox-6_5" :value="true" :unchecked-value="false">Riso</b-form-checkbox>
                            <b-form-checkbox id="checkbox-6_6" v-model="selected6_6" name="checkbox-6_6" :value="true" :unchecked-value="false">Caminhando</b-form-checkbox>   
                            <b-form-checkbox id="checkbox-6_7" v-model="selected6_7" name="checkbox-6_7" :value="true" :unchecked-value="false">Mudança de Posição</b-form-checkbox>
                            <b-form-checkbox id="checkbox-6_8" v-model="selected6_8" name="checkbox-6_8" :value="true" :unchecked-value="false">Em Contato Com Água</b-form-checkbox>
                        </b-col>
                    </b-row>
                </b-form-group>

                <b-button v-show="numberQuestion == 6" variant="success" @click="numberQuestion = 7">Ok</b-button>    


                <b-form-group v-show="numberQuestion == 7" class="mt-3" label="Hábitos Alimentares:">
                   <b-row> 
                        <b-col>
                            <b-form-checkbox id="checkbox-7_1" v-model="selected7_1" name="checkbox-7_1" :value="true" :unchecked-value="false">Cafés/Chás Cafeínado</b-form-checkbox>
                            <b-form-checkbox id="checkbox-7_2" v-model="selected7_2" name="checkbox-7_2" :value="true" :unchecked-value="false">Adoçante</b-form-checkbox>
                            <b-form-checkbox id="checkbox-7_3" v-model="selected7_3" name="checkbox-7_3" :value="true" :unchecked-value="false">Frutas / Sucos Ácidos</b-form-checkbox>
                            <b-form-checkbox id="checkbox-7_4" v-model="selected7_4" name="checkbox-7_4" :value="true" :unchecked-value="false">Refrigerantes</b-form-checkbox>
                            <b-form-checkbox id="checkbox-7_5" v-model="selected7_5" name="checkbox-7_5" :value="true" :unchecked-value="false">Comidas Apimentadas</b-form-checkbox>
                            <b-form-checkbox id="checkbox-7_6" v-model="selected7_6" name="checkbox-7_6" :value="true" :unchecked-value="false">Chocolates</b-form-checkbox>   
                            <b-form-checkbox id="checkbox-7_7" v-model="selected7_7" name="checkbox-7_7" :value="true" :unchecked-value="false">Evita Consumo de Líquidos?</b-form-checkbox>
                            <b-row class="mt-2">
                                <b-col md="2" sm="12"> 
                                    <b-form-input type="text" v-model="selected7_8" placeholder="Ingesta de líquido/Dia" />                    
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-form-group>

                <b-button v-show="numberQuestion == 7" variant="success" @click="numberQuestion = 8">Ok</b-button>                                                                                                                           


                <b-form-group v-show="numberQuestion == 8" class="mt-3">
                    <b-row> 
                        <b-col md="4" sm="12">   
                            <b-form-group v-show="numberQuestion == 8" label="Jato Urinário:">
                                <b-form-radio @change="numberQuestion = 9" v-model="selected8" name="some-radios-8" value="0">Forte</b-form-radio>
                                <b-form-radio @change="numberQuestion = 9" v-model="selected8" name="some-radios-8" value="1">Fraco</b-form-radio>
                                <b-form-radio @change="numberQuestion = 9" v-model="selected8" name="some-radios-8" value="2">Normal</b-form-radio>
                                <b-form-radio @change="numberQuestion = 9" v-model="selected8" name="some-radios-8" value="3">Partido</b-form-radio>
                            </b-form-group>               
                        </b-col>                                             
                    </b-row>
                </b-form-group>        


                <b-form-group v-show="numberQuestion == 9" class="mt-3">
                    <b-row> 
                        <b-col md="4" sm="12">   
                            <b-form-group v-show="numberQuestion == 9" label="Função Intestinal:">
                                <b-form-radio v-model="selected9_1" name="some-radios-9_1" value="0">Incontinência Anal</b-form-radio>
                                <b-form-radio v-model="selected9_1" name="some-radios-9_1" value="1">Hemorroidas</b-form-radio>
                                <b-form-radio v-model="selected9_1" name="some-radios-9_1" value="2">Normal</b-form-radio>
                                <b-form-radio v-model="selected9_1" name="some-radios-9_1" value="3">Outro</b-form-radio>

                                <b-row class="mt-2">
                                    <b-col md="12" sm="12"> 
                                        <b-form-input type="text" v-model="selected9_2" placeholder="Qual" />                    
                                    </b-col>
                                    <b-col md="12" sm="12"> 
                                        <b-form-input type="text" v-model="selected9_3" placeholder="Cirurgias" />                    
                                    </b-col>
                                </b-row>                                   
                            </b-form-group>               
                        </b-col>                                             
                    </b-row>
                </b-form-group>     

                <b-button v-show="numberQuestion == 9" variant="success" @click="numberQuestion = 10">Ok</b-button>          


                <b-form-group v-show="numberQuestion == 10" class="mt-3">
                    <b-row> 
                        <b-col md="4" sm="12">   
                            <b-form-group v-show="numberQuestion == 10" label="Proteções:">
                                <b-form-radio v-model="selected10_1" name="some-radios-10_1" value="0">Sim</b-form-radio>
                                <b-form-radio v-model="selected10_1" name="some-radios-10_1" value="1">Não</b-form-radio>

                                <b-row class="mt-2">
                                    <b-col md="12" sm="12"> 
                                        <b-form-input type="text" v-model="selected10_2" placeholder="N° de Trocas/Dia" />                    
                                    </b-col>
                                    <b-col md="12" sm="12"> 
                                        <b-form-input type="text" v-model="selected10_3" placeholder="Tipo de Proteção" />                    
                                    </b-col>
                                </b-row>                                   
                            </b-form-group>               
                        </b-col>                                             
                    </b-row>
                </b-form-group>     

                <b-button v-show="numberQuestion == 10" variant="success" @click="numberQuestion = 11">Ok</b-button>   


                <b-form-group v-show="numberQuestion == 11" class="mt-3">
                    <b-row> 
                        <b-col md="4" sm="12">   
                            <b-form-group v-show="numberQuestion == 11" label="Antecedentes Obstétricos:">
                                <b-row class="mt-2">
                                    <b-col md="12" sm="12"> 
                                        <b-form-input type="text" v-model="selected11_1" placeholder="Gesta" />                    
                                    </b-col>
                                    <b-col md="12" sm="12"> 
                                        <b-form-input type="text" v-model="selected11_2" placeholder="Para" />                    
                                    </b-col>
                                    <b-col md="12" sm="12"> 
                                        <b-form-input type="text" v-model="selected11_3" placeholder="Abortos" />                    
                                    </b-col>        
                                    <b-col md="12" sm="12"> 
                                        <b-form-input type="text" v-model="selected11_4" placeholder="Tipo de Parto" />                    
                                    </b-col>      
                                    <b-col md="12" sm="12"> 
                                        <b-form-input type="text" v-model="selected11_5" placeholder="DUM" />                    
                                    </b-col>      
                                    <b-col md="12" sm="12"> 
                                        <b-form-input type="text" v-model="selected11_6" placeholder="DPP" />                    
                                    </b-col>      
                                    <b-col md="6" sm="12"> 
                                        <b-form-input type="text" v-model="selected11_7" placeholder="IG (Semanas)" />                    
                                    </b-col>   
                                </b-row>                                   

                                <b-form-group class="mt-2" v-show="numberQuestion == 11" label="Preparação Para Parto Normal:">    
                                    <b-form-radio v-model="selected11_8" name="some-radios-11_8" value="0">Sim</b-form-radio>
                                    <b-form-radio v-model="selected11_8" name="some-radios-11_8" value="1">Não</b-form-radio>
                                </b-form-group>
                            </b-form-group>               
                        </b-col>                                             
                    </b-row>
                </b-form-group>     

                <b-button v-show="numberQuestion == 11" variant="success" @click="numberQuestion = 12">Ok</b-button>      


                <b-form-group v-show="numberQuestion == 12" class="mt-3">
                    <b-row> 
                        <b-col md="4" sm="12">   
                            <b-form-group v-show="numberQuestion == 12" label="Antecedentes Ginecológicos:">
                                <b-form-group class="mt-2" v-show="numberQuestion == 12" label="TRH:">    
                                    <b-form-radio v-model="selected12_1" name="some-radios-12_1" value="0">Sim</b-form-radio>
                                    <b-form-radio v-model="selected12_1" name="some-radios-12_1" value="1">Não</b-form-radio>
                                </b-form-group>  

                                <b-row class="mt-2">     
                                    <b-col md="12" sm="12"> 
                                        <b-form-input type="text" v-model="selected12_2" placeholder="Tempo" />                    
                                    </b-col>
                                    <b-col md="12" sm="12"> 
                                        <b-form-input type="text" v-model="selected12_3" placeholder="Menarca (Anos)" />                    
                                    </b-col>
                                    <b-col md="12" sm="12"> 
                                        <b-form-input type="text" v-model="selected12_4" placeholder="Menopausa (Anos)" />                    
                                    </b-col>        
                                    <b-col md="12" sm="12"> 
                                        <b-form-input type="text" v-model="selected12_5" placeholder="Ciclo Menstrual (Dias)" />                    
                                    </b-col>                                                                                                                                                                                                                 
                                </b-row>      

                                <b-form-group class="mt-2" v-show="numberQuestion == 12" label="Fluxo:">    
                                    <b-form-radio v-model="selected12_6" name="some-radios-12_6" value="0">Leve</b-form-radio>
                                    <b-form-radio v-model="selected12_6" name="some-radios-12_6" value="1">Moderado</b-form-radio>
                                    <b-form-radio v-model="selected12_6" name="some-radios-12_6" value="1">Intenso</b-form-radio>
                                </b-form-group>      

                                <b-form-group class="mt-2" v-show="numberQuestion == 12" label="Cólicas:">    
                                    <b-form-radio v-model="selected12_7" name="some-radios-12_7" value="0">Sim</b-form-radio>
                                    <b-form-radio v-model="selected12_7" name="some-radios-12_7" value="1">Não</b-form-radio>
                                </b-form-group>  

                                <b-form-group class="mt-2" v-show="numberQuestion == 12" label="Intensidade (0 a 10):">
                                    <b-form-input type="number" v-model="selected12_8" />
                                </b-form-group>                              

                                <b-form-group class="mt-2" v-show="numberQuestion == 12" label="Sexualmente Ativa:">    
                                    <b-form-radio v-model="selected12_9" name="some-radios-12_9" value="0">Sim</b-form-radio>
                                    <b-form-radio v-model="selected12_9" name="some-radios-12_9" value="1">Não</b-form-radio>
                                </b-form-group>                                                                                                                           
                            </b-form-group>               
                        </b-col>                                             
                    </b-row>
                </b-form-group>     

                <b-button v-show="numberQuestion == 12" variant="success" @click="numberQuestion = 13">Ok</b-button>  


                <b-form-group v-show="numberQuestion == 13" class="mt-3">
                    <b-row> 
                        <b-col md="4" sm="12">   
                            <b-form-group v-show="numberQuestion == 13" label="Antecedentes Pessoais:">
                                <b-form-group class="mt-2" v-show="numberQuestion == 13" label="TRH:">    
                                    <b-form-checkbox id="checkbox-13_1" v-model="selected13_1" name="checkbox-13_1" :value="true" :unchecked-value="false">DM</b-form-checkbox>
                                    <b-form-checkbox id="checkbox-13_2" v-model="selected13_2" name="checkbox-13_2" :value="true" :unchecked-value="false">HAS</b-form-checkbox>
                                    <b-form-checkbox id="checkbox-13_3" v-model="selected13_3" name="checkbox-13_3" :value="true" :unchecked-value="false">Tireoidopatia</b-form-checkbox>
                                    <b-form-checkbox id="checkbox-13_4" v-model="selected13_4" name="checkbox-13_4" :value="true" :unchecked-value="false">Neuropatia</b-form-checkbox>
                                    <b-row md="12" sm="12">
                                        <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 13" label="Preparação Para Parto Normal:">    
                                            <b-form-radio v-model="selected13_5" name="some-radios-13_5" value="0">Sim</b-form-radio>
                                            <b-form-radio v-model="selected13_5" name="some-radios-13_5" value="1">Não</b-form-radio>
                                        </b-form-group>                                          
                                    </b-row>
                                    <b-form-checkbox id="checkbox-13_6" v-model="selected13_6" name="checkbox-13_6" :value="true" :unchecked-value="false">Pneumopatia</b-form-checkbox>
                                    <b-form-checkbox id="checkbox-13_7" v-model="selected13_7" name="checkbox-13_7" :value="true" :unchecked-value="false">Obesidade</b-form-checkbox>
                                    <b-form-checkbox id="checkbox-13_8" v-model="selected13_8" name="checkbox-13_8" :value="true" :unchecked-value="false">Desordem Psicológica</b-form-checkbox>
                                    <b-form-checkbox id="checkbox-13_9" v-model="selected13_9" name="checkbox-13_9" :value="true" :unchecked-value="false">Câncer</b-form-checkbox>
                                    <b-form-input type="text" v-model="selected13_10" placeholder="Tratamento" /> 
                                    <b-form-checkbox id="checkbox-13_11" v-model="selected13_11" name="checkbox-13_11" :value="true" :unchecked-value="false">DSTs</b-form-checkbox>
                                    <b-form-input type="text" v-model="selected13_12" placeholder="Tipo" /> 
                                </b-form-group>                                                                                                                                                                                  
                            </b-form-group>               
                        </b-col>                                             
                    </b-row>
                </b-form-group>     

                <b-button v-show="numberQuestion == 13" variant="success" @click="numberQuestion = 14">Ok</b-button>        


                <b-form-group v-show="numberQuestion == 14" class="mt-3">
                    <b-row> 
                        <b-col md="4" sm="12">   
                            <b-form-group v-show="numberQuestion == 14" label="Antecedentes Pessoais:">
                                <b-form-group class="mt-2" v-show="numberQuestion == 14" label="TRH:">    
                                    <b-form-checkbox id="checkbox-14_1" v-model="selected14_1" name="checkbox-14_1" :value="true" :unchecked-value="false">Tabagismo</b-form-checkbox>
                                    <b-form-input type="text" v-model="selected14_2" placeholder="Tratamento" />
                                    <b-form-input type="text" v-model="selected14_3" placeholder="N° Cigarros/Dia" />
                                    <b-form-checkbox id="checkbox-14_4" v-model="selected14_4" name="checkbox-14_4" :value="true" :unchecked-value="false">Etilismo</b-form-checkbox>
                                    <b-form-input type="text" v-model="selected14_5" placeholder="Frequência" />
                                    <b-form-checkbox id="checkbox-14_6" v-model="selected14_6" name="checkbox-14_6" :value="true" :unchecked-value="false">Ativ. Física Regular</b-form-checkbox>
                                    <b-form-input type="text" v-model="selected14_7" placeholder="Tipo" />
                                    <b-form-input type="text" v-model="selected14_8" placeholder="Tipo" />
                                </b-form-group>                                                                                                                                                                                  
                            </b-form-group>               
                        </b-col>                                             
                    </b-row>
                </b-form-group>     

                <b-button v-show="numberQuestion == 14" variant="success" @click="numberQuestion = 15">Ok</b-button>      


                <b-form-group v-show="numberQuestion == 15" class="mt-3">
                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 15" label="Medicações em Uso:">
                                <b-form-textarea id="textarea" v-model="selected15" placeholder="" rows="1" max-rows="6"></b-form-textarea>                   
                            </b-form-group>                
                        </b-col>                                                                       
                    </b-row>   
                </b-form-group>     

                <b-button v-show="numberQuestion == 15" variant="success" @click="numberQuestion = 16">Ok</b-button>          


                <b-form-group v-show="numberQuestion == 16" class="mt-3" label="Exames Complementares:">
                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 16" label="Urocultura:">
                                <b-form-textarea id="textarea" v-model="selected16_1" placeholder="" rows="1" max-rows="6"></b-form-textarea>                   
                            </b-form-group>                
                        </b-col>                                                                       
                    </b-row>   
                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 16" label="Ultrassom:">
                                <b-form-textarea id="textarea" v-model="selected16_2" placeholder="" rows="1" max-rows="6"></b-form-textarea>                   
                            </b-form-group>                
                        </b-col>                                                                       
                    </b-row>   
                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 16" label="Urodinâmico:">
                                <b-form-textarea id="textarea" v-model="selected16_3" placeholder="" rows="1" max-rows="6"></b-form-textarea>                   
                            </b-form-group>                
                        </b-col>                                                                       
                    </b-row>   
                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 16" label="Outros:">
                                <b-form-textarea id="textarea" v-model="selected16_4" placeholder="" rows="1" max-rows="6"></b-form-textarea>                   
                            </b-form-group>                
                        </b-col>                                                                       
                    </b-row>                                                               
                </b-form-group>     

                <b-button v-show="numberQuestion == 16" variant="success" @click="numberQuestion = 17">Ok</b-button>    


                <b-form-group v-show="numberQuestion == 17" class="mt-3" label="Inspeção:">
                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 17" label="Pressão Arterial:">
                                <b-form-input type="text" v-model="selected17_1" />
                            </b-form-group>                
                        </b-col>                                                                       
                    </b-row>   
                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 17" label="Peso:">
                                <b-form-input type="text" v-model="selected17_2" />
                            </b-form-group>                
                        </b-col>                                                                       
                    </b-row>   
                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 17" label="Altura:">
                                <b-form-input type="text" v-model="selected17_3" />
                            </b-form-group>                
                        </b-col>                                                                       
                    </b-row>   
                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 17" label="IMC:">
                                <b-form-input type="text" v-model="selected17_4" />
                            </b-form-group>                
                        </b-col>                                                                       
                    </b-row>   
                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 17" label="Postura:">
                                <b-form-input type="text" v-model="selected17_5" />
                            </b-form-group>                
                        </b-col>                                                                       
                    </b-row>                                                                                                       
                </b-form-group>     

                <b-button v-show="numberQuestion == 17" variant="success" @click="numberQuestion = 18">Ok</b-button>     

                
                <b-form-group v-show="numberQuestion == 18" class="mt-3">
                    <b-row md="12" sm="12">
                        <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 18" label="Cicatrizes:">    
                            <b-form-radio v-model="selected18_1" name="some-radios-18_1" value="0">Sim</b-form-radio>
                            <b-form-radio v-model="selected18_1" name="some-radios-18_1" value="1">Não</b-form-radio>
                            <b-form-input type="text" v-model="selected18_2" placeholder="Local" />
                        </b-form-group>                                          
                    </b-row>
                    <b-row md="12" sm="12">
                        <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 18" label="Consciência Perineal:">    
                            <b-form-radio v-model="selected18_2" name="some-radios-18_2" value="0">Presente 1ª Vez</b-form-radio>
                            <b-form-radio v-model="selected18_2" name="some-radios-18_2" value="1">Presente 2ª Vez</b-form-radio>
                            <b-form-radio v-model="selected18_2" name="some-radios-18_2" value="2">Ausente</b-form-radio>
                            <b-form-radio v-model="selected18_2" name="some-radios-18_2" value="3">Caudal</b-form-radio>
                        </b-form-group>                                          
                    </b-row>
                    <b-row md="12" sm="12">
                        <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 18" label="MM. Superficiais – Ativ. Voluntária:">  
                            <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 18" label="•	Bulbocavernoso:">   
                                <b-form-radio v-model="selected18_3" name="some-radios-18_3" value="0">Presente</b-form-radio>
                                <b-form-radio v-model="selected18_3" name="some-radios-18_3" value="1">Ausente</b-form-radio>
                                <b-form-radio v-model="selected18_3" name="some-radios-18_3" value="2">Assimetria</b-form-radio>
                            </b-form-group>
                            <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 18" label="•	Isquiocavernoso:">   
                                <b-form-radio v-model="selected18_4" name="some-radios-18_4" value="0">Presente</b-form-radio>
                                <b-form-radio v-model="selected18_4" name="some-radios-18_4" value="1">Ausente</b-form-radio>
                                <b-form-radio v-model="selected18_4" name="some-radios-18_4" value="2">Assimetria</b-form-radio>
                            </b-form-group>         
                            <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 18" label="•	Transversos Perineais:">   
                                <b-form-radio v-model="selected18_5" name="some-radios-18_5" value="0">Presente</b-form-radio>
                                <b-form-radio v-model="selected18_5" name="some-radios-18_5" value="1">Ausente</b-form-radio>
                                <b-form-radio v-model="selected18_5" name="some-radios-18_5" value="2">Assimetria</b-form-radio>
                            </b-form-group>   
                            <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 18" label="•	Esfíncter Anal Externo:">   
                                <b-form-radio v-model="selected18_6" name="some-radios-18_6" value="0">Presente</b-form-radio>
                                <b-form-radio v-model="selected18_6" name="some-radios-18_6" value="1">Ausente</b-form-radio>
                                <b-form-radio v-model="selected18_6" name="some-radios-18_6" value="2">Assimetria</b-form-radio>
                            </b-form-group>                                                                              
                        </b-form-group>                                          
                    </b-row>  
                    <b-row md="12" sm="12">
                        <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 18" label="MM. Levantadores do Ânus – Ativ. Voluntária:">  
                            <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 18" label="Contração:">
                                <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 18" label="Movimento Cranial:">   
                                    <b-form-radio v-model="selected18_7" name="some-radios-18_7" value="0">Presente</b-form-radio>
                                    <b-form-radio v-model="selected18_7" name="some-radios-18_7" value="1">Ausente</b-form-radio>
                                    <b-form-radio v-model="selected18_7" name="some-radios-18_7" value="2">Caudal</b-form-radio>
                                </b-form-group>
                                <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 18" label="Co – contração:">   
                                    <b-form-radio v-model="selected18_8" name="some-radios-18_8" value="0">Respiratórios</b-form-radio>
                                    <b-form-radio v-model="selected18_8" name="some-radios-18_8" value="1">Abdominais</b-form-radio>
                                    <b-form-radio v-model="selected18_8" name="some-radios-18_8" value="2">Adutores</b-form-radio>
                                    <b-form-radio v-model="selected18_8" name="some-radios-18_8" value="3">Glúteos</b-form-radio>
                                    <b-form-radio v-model="selected18_8" name="some-radios-18_8" value="4">Ausente</b-form-radio>
                                </b-form-group>         
                            </b-form-group>
                            <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 18" label="Relaxamento:">
                                <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 18" label="Movimento Caudal:">   
                                    <b-form-radio v-model="selected18_9" name="some-radios-18_9" value="0">Presente</b-form-radio>
                                    <b-form-radio v-model="selected18_9" name="some-radios-18_9" value="1">Atrasado</b-form-radio>
                                    <b-form-radio v-model="selected18_9" name="some-radios-18_9" value="2">Ausente</b-form-radio>
                                </b-form-group>     
                            </b-form-group>    
                        </b-form-group>                                     
                    </b-row>    
                    <b-row md="12" sm="12">
                        <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 18" label="MM. Levantadores do Ânus – Ativ. Involuntária:">  
                            <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 18" label="Tosse:">
                                <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 18" label="Movimento Cranial:">   
                                    <b-form-radio v-model="selected18_10" name="some-radios-18_10" value="0">Antes</b-form-radio>
                                    <b-form-radio v-model="selected18_10" name="some-radios-18_10" value="1">Durante</b-form-radio>
                                    <b-form-radio v-model="selected18_10" name="some-radios-18_10" value="2">Depois</b-form-radio>
                                    <b-form-radio v-model="selected18_10" name="some-radios-18_10" value="3">Ausente</b-form-radio>
                                    <b-form-radio v-model="selected18_10" name="some-radios-18_10" value="4">Caudal</b-form-radio>
                                </b-form-group>
                            </b-form-group>
                            <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 18" label="Valsalva:">
                                <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 18" label="Movimento Cranial:">   
                                    <b-form-radio v-model="selected18_11" name="some-radios-18_11" value="0">Respiratórios</b-form-radio>
                                    <b-form-radio v-model="selected18_11" name="some-radios-18_11" value="1">Abdominais</b-form-radio>
                                    <b-form-radio v-model="selected18_11" name="some-radios-18_11" value="2">Adutores</b-form-radio>
                                    <b-form-radio v-model="selected18_11" name="some-radios-18_11" value="3">Glúteos</b-form-radio>
                                    <b-form-radio v-model="selected18_11" name="some-radios-18_11" value="4">Ausente</b-form-radio>
                                </b-form-group>         
                            </b-form-group>
                            <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 18" label="Abertura:">
                                <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 18" label="Movimento Caudal:">   
                                    <b-form-radio v-model="selected18_12" name="some-radios-18_12" value="0">Presente</b-form-radio>
                                    <b-form-radio v-model="selected18_12" name="some-radios-18_12" value="1">Ausente</b-form-radio>
                                    <b-form-radio v-model="selected18_12" name="some-radios-18_12" value="2">Cranial</b-form-radio>
                                </b-form-group>     
                            </b-form-group>  
                            <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 18" label="Perda:">
                                <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 18" label="Tosse:">   
                                    <b-form-radio v-model="selected18_13" name="some-radios-18_13" value="0">Urinária</b-form-radio>
                                    <b-form-radio v-model="selected18_13" name="some-radios-18_13" value="1">Anorretal</b-form-radio>
                                </b-form-group>     
                                <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 18" label="Valsalva:">   
                                    <b-form-radio v-model="selected18_14" name="some-radios-18_14" value="0">Urinária</b-form-radio>
                                    <b-form-radio v-model="selected18_14" name="some-radios-18_14" value="1">Anorretal</b-form-radio>
                                </b-form-group>     
                                <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 18" label="Abertura:">   
                                    <b-form-radio v-model="selected18_15" name="some-radios-18_15" value="0">Urinária</b-form-radio>
                                    <b-form-radio v-model="selected18_15" name="some-radios-18_15" value="1">Anorretal</b-form-radio>
                                </b-form-group>                                                                     
                            </b-form-group>                                
                        </b-form-group>                                     
                    </b-row>                   
                </b-form-group>     

                <b-button v-show="numberQuestion == 18" variant="success" @click="numberQuestion = 19">Ok</b-button>          


                <b-form-group v-show="numberQuestion == 19" class="mt-3" label="PALPAÇÃO">
                    <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 19" label="Sensibilidade:">
                        <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 19" label="Quadríceps:">   
                            <b-form-radio v-model="selected19_1" name="some-radios-19_1" value="0">D > E</b-form-radio>
                            <b-form-radio v-model="selected19_1" name="some-radios-19_1" value="1">N</b-form-radio>
                            <b-form-radio v-model="selected19_1" name="some-radios-19_1" value="2">E > D</b-form-radio>
                        </b-form-group>     
                        <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 19" label="Adutores:">   
                            <b-form-radio v-model="selected19_2" name="some-radios-19_2" value="0">D > E</b-form-radio>
                            <b-form-radio v-model="selected19_2" name="some-radios-19_2" value="1">N</b-form-radio>
                            <b-form-radio v-model="selected19_2" name="some-radios-19_2" value="2">E > D</b-form-radio>
                        </b-form-group>     
                        <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 19" label="Isquiotibiais:">   
                            <b-form-radio v-model="selected19_3" name="some-radios-19_3" value="0">D > E</b-form-radio>
                            <b-form-radio v-model="selected19_3" name="some-radios-19_3" value="1">N</b-form-radio>
                            <b-form-radio v-model="selected19_3" name="some-radios-19_3" value="2">E > D</b-form-radio>
                        </b-form-group>   
                        <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 19" label="Pudendo:">   
                            <b-form-radio v-model="selected19_4" name="some-radios-19_4" value="0">D > E</b-form-radio>
                            <b-form-radio v-model="selected19_4" name="some-radios-19_4" value="1">N</b-form-radio>
                            <b-form-radio v-model="selected19_4" name="some-radios-19_4" value="2">E > D</b-form-radio>
                        </b-form-group>   
                        <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 19" label="Cutâneo Femoral:">   
                            <b-form-radio v-model="selected19_5" name="some-radios-19_5" value="0">D > E</b-form-radio>
                            <b-form-radio v-model="selected19_5" name="some-radios-19_5" value="1">N</b-form-radio>
                            <b-form-radio v-model="selected19_5" name="some-radios-19_5" value="2">E > D</b-form-radio>
                        </b-form-group>   
                        <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 19" label="Ilioinguinal:">   
                            <b-form-radio v-model="selected19_6" name="some-radios-19_6" value="0">D > E</b-form-radio>
                            <b-form-radio v-model="selected19_6" name="some-radios-19_6" value="1">N</b-form-radio>
                            <b-form-radio v-model="selected19_6" name="some-radios-19_6" value="2">E > D</b-form-radio>
                        </b-form-group>   
                        <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 19" label="Iliohipogátrico:">   
                            <b-form-radio v-model="selected19_7" name="some-radios-19_7" value="0">D > E</b-form-radio>
                            <b-form-radio v-model="selected19_7" name="some-radios-19_7" value="1">N</b-form-radio>
                            <b-form-radio v-model="selected19_7" name="some-radios-19_7" value="2">E > D</b-form-radio>
                        </b-form-group>                                                                                                                           
                    </b-form-group>                                
                </b-form-group>    

                <b-button v-show="numberQuestion == 19" variant="success" @click="numberQuestion = 20">Ok</b-button>      


                <b-form-group v-show="numberQuestion == 20" class="mt-3" label="PALPAÇÃO">
                    <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 20" label="Atividade Reflexa:">
                        <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 20" label="Isquiocavernoso:">   
                            <b-form-radio v-model="selected20_1" name="some-radios-20_1" value="0">Aumentado</b-form-radio>
                            <b-form-radio v-model="selected20_1" name="some-radios-20_1" value="1">Normal</b-form-radio>
                            <b-form-radio v-model="selected20_1" name="some-radios-20_1" value="2">Diminuído</b-form-radio>
                        </b-form-group>     
                        <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 20" label="Cutaneoanal:">   
                            <b-form-radio v-model="selected20_2" name="some-radios-20_2" value="0">Aumentado</b-form-radio>
                            <b-form-radio v-model="selected20_2" name="some-radios-20_2" value="1">Normal</b-form-radio>
                            <b-form-radio v-model="selected20_2" name="some-radios-20_2" value="2">Diminuído</b-form-radio>
                        </b-form-group>     
                        <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 20" label="Cremastérico:">   
                            <b-form-radio v-model="selected20_3" name="some-radios-20_3" value="0">Aumentado</b-form-radio>
                            <b-form-radio v-model="selected20_3" name="some-radios-20_3" value="1">Normal</b-form-radio>
                            <b-form-radio v-model="selected20_3" name="some-radios-20_3" value="2">Diminuído</b-form-radio>
                        </b-form-group>   
                        <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 20" label="Levantadores:">   
                            <b-form-radio v-model="selected20_4" name="some-radios-20_4" value="0">Aumentado</b-form-radio>
                            <b-form-radio v-model="selected20_4" name="some-radios-20_4" value="1">Normal</b-form-radio>
                            <b-form-radio v-model="selected20_4" name="some-radios-20_4" value="2">Diminuído</b-form-radio>
                        </b-form-group>   
                    </b-form-group>                                
                </b-form-group>    

                <b-button v-show="numberQuestion == 20" variant="success" @click="numberQuestion = 21">Ok</b-button>     


                <b-form-group v-show="numberQuestion == 21" class="mt-3" label="PALPAÇÃO">
                    <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 21" label="Tônus Muscular:">
                        <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 21" label="Corpo Perineal:">   
                            <b-form-radio v-model="selected21_1" name="some-radios-21_1" value="0">Normal</b-form-radio>
                            <b-form-radio v-model="selected21_1" name="some-radios-21_1" value="1">Hipertônico</b-form-radio>
                            <b-form-radio v-model="selected21_1" name="some-radios-21_1" value="2">Hipotônico</b-form-radio>
                        </b-form-group>     
                        <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 21" label="Isquiocavernoso:">   
                            <b-form-radio v-model="selected21_2" name="some-radios-21_2" value="0">Normal</b-form-radio>
                            <b-form-radio v-model="selected21_2" name="some-radios-21_2" value="1">Hipertônico</b-form-radio>
                            <b-form-radio v-model="selected21_2" name="some-radios-21_2" value="2">Hipotônico</b-form-radio>
                        </b-form-group>     
                        <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 21" label="Bulboesponjoso:">   
                            <b-form-radio v-model="selected21_3" name="some-radios-21_3" value="0">Normal</b-form-radio>
                            <b-form-radio v-model="selected21_3" name="some-radios-21_3" value="1">Hipertônico</b-form-radio>
                            <b-form-radio v-model="selected21_3" name="some-radios-21_3" value="2">Hipotônico</b-form-radio>
                        </b-form-group>   
                        <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 21" label="Dor à Palpação:">   
                            <b-form-radio v-model="selected21_4" name="some-radios-21_4" value="0">Sim</b-form-radio>
                            <b-form-radio v-model="selected21_4" name="some-radios-21_4" value="1">Não</b-form-radio>
                        </b-form-group>                           
                    </b-form-group>                                
                </b-form-group>    

                <b-button v-show="numberQuestion == 21" variant="success" @click="numberQuestion = 22">Ok</b-button>                


                <b-form-group v-show="numberQuestion == 22" class="mt-3" label="Palpação Dinâmica">
                    <b-form-input type="text" v-model="selected22_1" placeholder="P"/>
                    <b-form-input type="text" v-model="selected22_2" placeholder="E"/>
                    <b-form-input type="text" v-model="selected22_3" placeholder="R"/>
                    <b-form-input type="text" v-model="selected22_4" placeholder="F"/>
                    <b-form-input type="text" v-model="selected22_5" placeholder="ECT"/>
                </b-form-group>    

                <b-button v-show="numberQuestion == 22" variant="success" @click="numberQuestion = 23">Ok</b-button>   


                <b-form-group v-show="numberQuestion == 23" class="mt-3" label="Atividade Involuntária">
                    <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 23" label="Tosse:">
                        <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 23" label="Movimento Cranial:">   
                            <b-form-radio v-model="selected23_1" name="some-radios-23_1" value="0">Antes</b-form-radio>
                            <b-form-radio v-model="selected23_1" name="some-radios-23_1" value="1">Durante</b-form-radio>
                            <b-form-radio v-model="selected23_1" name="some-radios-23_1" value="2">Depois</b-form-radio>
                            <b-form-radio v-model="selected23_1" name="some-radios-23_1" value="3">Ausente</b-form-radio>
                        </b-form-group>     
                        <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 23" label="Movimento Caudal:">   
                            <b-form-radio v-model="selected23_2" name="some-radios-23_2" value="0">Antes</b-form-radio>
                            <b-form-radio v-model="selected23_2" name="some-radios-23_2" value="1">Durante</b-form-radio>
                            <b-form-radio v-model="selected23_2" name="some-radios-23_2" value="2">Depois</b-form-radio>
                            <b-form-radio v-model="selected23_2" name="some-radios-23_2" value="3">Ausente</b-form-radio>
                        </b-form-group>  
                    </b-form-group>   
                    <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 23" label="Valsalva:">
                        <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 23" label="Movimento Cranial:">   
                            <b-form-radio v-model="selected23_3" name="some-radios-23_3" value="0">Antes</b-form-radio>
                            <b-form-radio v-model="selected23_3" name="some-radios-23_3" value="1">Durante</b-form-radio>
                            <b-form-radio v-model="selected23_3" name="some-radios-23_3" value="2">Depois</b-form-radio>
                            <b-form-radio v-model="selected23_3" name="some-radios-23_3" value="3">Ausente</b-form-radio>
                        </b-form-group>     
                        <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 23" label="Movimento Caudal:">   
                            <b-form-radio v-model="selected23_4" name="some-radios-23_4" value="0">Antes</b-form-radio>
                            <b-form-radio v-model="selected23_4" name="some-radios-23_4" value="1">Leve</b-form-radio>
                            <b-form-radio v-model="selected23_4" name="some-radios-23_4" value="2">Moderado</b-form-radio>
                            <b-form-radio v-model="selected23_4" name="some-radios-23_4" value="3">Severo</b-form-radio>
                        </b-form-group>                          
                    </b-form-group>    
                    <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 23" label="Abertura:">
                        <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 23" label="Relaxamento Mov. Caudal:">   
                            <b-form-radio v-model="selected23_5" name="some-radios-23_5" value="0">Presente</b-form-radio>
                            <b-form-radio v-model="selected23_5" name="some-radios-23_5" value="1">Ausente</b-form-radio>
                            <b-form-radio v-model="selected23_5" name="some-radios-23_3" value="2">Cranial</b-form-radio>
                        </b-form-group> 
                    </b-form-group>                                                
                </b-form-group>    

                <b-button v-show="numberQuestion == 23" variant="success" @click="numberQuestion = 24">Ok</b-button>    


                <b-form-group v-show="numberQuestion == 24" class="mt-3" label="Diagnóstico Funcional">
                    <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 24" label="Superficiais:">
                        <b-form-radio v-model="selected24_1" name="some-radios-24_1" value="0">Hiperativo</b-form-radio>
                        <b-form-radio v-model="selected24_1" name="some-radios-24_1" value="1">Normal</b-form-radio>
                        <b-form-radio v-model="selected24_1" name="some-radios-24_1" value="2">Hipoativo</b-form-radio>
                        <b-form-radio v-model="selected24_1" name="some-radios-24_1" value="4">Não Funcional</b-form-radio>
                        <b-form-radio v-model="selected24_1" name="some-radios-24_1" value="5">Ausente</b-form-radio>
                    </b-form-group>      
                    <b-form-group class="mt-2 ml-3" v-show="numberQuestion == 24" label="Levantadores:">
                        <b-form-radio v-model="selected24_2" name="some-radios-24_2" value="0">Hiperativo</b-form-radio>
                        <b-form-radio v-model="selected24_2" name="some-radios-24_2" value="1">Normal</b-form-radio>
                        <b-form-radio v-model="selected24_2" name="some-radios-24_2" value="2">Hipoativo</b-form-radio>
                        <b-form-radio v-model="selected24_2" name="some-radios-24_2" value="3">Incoordenado</b-form-radio>
                        <b-form-radio v-model="selected24_2" name="some-radios-24_2" value="4">Desprogramado</b-form-radio>
                        <b-form-radio v-model="selected24_2" name="some-radios-24_2" value="5">Não Funcional</b-form-radio>
                    </b-form-group>                                                         
                </b-form-group>    

                <b-button v-show="numberQuestion == 24" variant="success" @click="numberQuestion = 25">Ok</b-button>  


                <b-form-group v-show="numberQuestion == 25" class="mt-3" label="Plano de Tratamento">
                    <b-form-group class="mt-2" v-show="numberQuestion == 25" label="Objetivos:">
                        <b-form-textarea id="textarea" v-model="selected25_1" placeholder="" rows="1" max-rows="6"></b-form-textarea>
                    </b-form-group>      
                    <b-form-group class="mt-2" v-show="numberQuestion == 25" label="Condutas:">
                        <b-form-textarea id="textarea" v-model="selected25_2" placeholder="" rows="1" max-rows="6"></b-form-textarea>
                    </b-form-group>                                                       
                </b-form-group>                                            
            </b-card>
        </div>
    </div>
</template>

<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'
import Moment from 'moment'

export default {
    name: "AssessmentPelvic",
    components: { PageTitle },
    data() {
      return {
        theme: null,
        patient: null,
        assessment: null,
        numberQuestion: 1,
        selected1_1: null,
        selected1_2: null,
        selected1_3: null,
        selected1_4: null,
        selected1_5: null,
        selected1_6: null,
        selected1_7: null,      
        selected1_8: null, 
        selected1_9: null, 
        selected1_10: null, 
        selected1_11: null, 
        selected1_12: null, 
        selected1_13: null,
        selected2_1: false,
        selected2_2: false,
        selected2_3: false,
        selected2_4: false,
        selected2_5: false,
        selected2_6: false,
        selected3_1: null,
        selected3_2: null,
        selected3_3: null,
        selected4: null,
        selected5_1: false,
        selected5_2: false,
        selected5_3: false,
        selected5_4: false,
        selected5_5: false,
        selected5_6: false,
        selected5_7: false,      
        selected5_8: false, 
        selected5_9: false, 
        selected5_10: false,    
        selected6_1: false,
        selected6_2: false,
        selected6_3: false,
        selected6_4: false,
        selected6_5: false,
        selected6_6: false,
        selected6_7: false,      
        selected6_8: false,
        selected7_1: false,
        selected7_2: false,
        selected7_3: false,
        selected7_4: false,
        selected7_5: false,
        selected7_6: false,
        selected7_7: false,
        selected8: null,    
        selected9_1: null,
        selected9_2: null,
        selected9_3: null,     
        selected10_1: null,
        selected10_2: null,
        selected10_3: null,
        selected11_1: null,
        selected11_2: null,
        selected11_3: null,
        selected11_4: null,
        selected11_5: null,
        selected11_6: null,
        selected11_7: null,
        selected11_8: null,    
        selected12_1: null,
        selected12_2: null,
        selected12_3: null,
        selected12_4: null,
        selected12_5: null,
        selected12_6: null,
        selected12_7: null,
        selected12_8: null,
        selected12_9: null,
        selected13_1: false,
        selected13_2: false,
        selected13_3: false,
        selected13_4: false,
        selected13_5: null,
        selected13_6: false,
        selected13_7: false,
        selected13_8: false,
        selected13_9: false,
        selected13_10: null,
        selected13_11: false,
        selected13_12: null,
        selected14_1: false,
        selected14_2: null,
        selected14_3: null,
        selected14_4: false,
        selected14_5: null,
        selected14_6: false,
        selected14_7: null,
        selected14_8: null,
        selected15: null,
        selected16_1: null,
        selected16_2: null,
        selected16_3: null,
        selected16_4: null,
        selected17_1: null,
        selected17_2: null,
        selected17_3: null,
        selected17_4: null,
        selected17_5: null,
        selected18_1: null,
        selected18_2: null,
        selected18_3: null,
        selected18_4: null,
        selected18_5: null,
        selected18_6: null,
        selected18_7: null,
        selected18_8: null,
        selected18_9: null,
        selected18_10: null,
        selected18_11: null,
        selected18_12: null,
        selected18_13: null,
        selected18_14: null,
        selected18_15: null,
        selected19_1: null,
        selected19_2: null,
        selected19_3: null,
        selected19_4: null,
        selected19_5: null,
        selected19_6: null,
        selected19_7: null,     
        selected20_1: null,
        selected20_2: null,
        selected20_3: null,
        selected20_4: null,   
        selected21_1: null,
        selected21_2: null,
        selected21_3: null,
        selected21_4: null,
        selected22_1: null,
        selected22_2: null,
        selected22_3: null,
        selected22_4: null,
        selected22_5: null,  
        selected23_1: null,
        selected23_2: null,
        selected23_3: null,
        selected23_4: null,
        selected23_5: null,  
        selected24_1: null,
        selected24_2: null,
        selected25_1: null,
        selected25_2: null                                                 
      }
    },
    methods: {
        sendQuestions() {
            // if (this.selected1_1 == null ||
            //    (this.selected2_1 == false && this.selected2_2 == false && this.selected2_3 == false &&
            //     this.selected2_4 == false && this.selected2_5 == false && this.selected2_6 == false) ||
            //    (this.selected3_1 == null && this.selected3_2 == null && this.selected3_3 == null) ||
            //     this.selected4 == null ||
            //    (this.selected5_1 == false && this.selected5_2 == false && this.selected5_3 == false &&
            //     this.selected5_4 == false && this.selected5_5 == false && this.selected5_6 == false &&
            //     this.selected5_7 == false && this.selected5_8 == false && this.selected5_9 == false &&
            //     this.selected5_10 == false) ||
            //    (this.selected6_1 == false && this.selected6_2 == false && this.selected6_3 == false &&
            //     this.selected6_4 == false && this.selected6_5 == false && this.selected6_6 == false &&
            //     this.selected6_7 == false && this.selected6_8 == false) ||
            //    (this.selected7_1 == false && this.selected7_2 == false && this.selected7_3 == false &&
            //     this.selected7_4 == false && this.selected7_5 == false && this.selected7_6 == false &&
            //     this.selected7_7 == false) || this.selected8 == null ||
            //    (this.selected9_1 == null && this.selected9_2 == null && this.selected9_3 == null) ||
            //    (this.selected10_1 == null && this.selected10_2 == null && this.selected10_3 == null) ||
            //    (this.selected11_1 == null && this.selected11_2 == null && this.selected11_3 == null &&
            //     this.selected11_4 == null && this.selected11_5 == null && this.selected11_6 == null &&
            //     this.selected11_7 == null && this.selected11_8 == null) ||
            //    (this.selected12_1 == null && this.selected12_2 == null && this.selected12_3 == null &&
            //     this.selected12_4 == null && this.selected12_5 == null && this.selected12_6 == null &&
            //     this.selected12_7 == null && this.selected12_8 == null && this.selected12_9 == null) ||
            //    (this.selected13_1 == false && this.selected13_2 == false && this.selected13_3 == false &&
            //     this.selected13_4 == false && this.selected13_5 == null && this.selected13_6 == false &&
            //     this.selected13_7 == false && this.selected13_8 == false && this.selected13_9 == false &&
            //     this.selected13_10 == null && this.selected13_11 == false && this.selected13_12 == null) ||
            //    (this.selected14_1 == false && this.selected14_2 == null && this.selected14_3 == null &&
            //     this.selected14_4 == false && this.selected14_5 == null && this.selected14_6 == false &&
            //     this.selected14_7 == null && this.selected14_8 == null) || this.selected15 == null ||  
            //    (this.selected16_1 == null && this.selected16_2 == null && this.selected16_3 == null &&
            //     this.selected16_4 == null) ||
            //    (this.selected17_1 == null && this.selected17_2 == null && this.selected17_3 == null &&
            //     this.selected17_4 == null && this.selected17_5 == null) ||
            //    (this.selected18_1 == null && this.selected18_2 == null && this.selected18_3 == null &&
            //     this.selected18_4 == null && this.selected18_5 == null && this.selected18_6 == null &&
            //     this.selected18_7 == null && this.selected18_8 == null && this.selected18_9 == null &&
            //     this.selected18_10 == null && this.selected18_11 == null && this.selected18_12 == null &&
            //     this.selected18_13 == null && this.selected18_14 == null && this.selected18_15 == null) ||
            //    (this.selected19_1 == null && this.selected19_2 == null && this.selected19_3 == null &&
            //     this.selected19_4 == null && this.selected19_5 == null && this.selected19_6 == null &&
            //     this.selected19_7 == null) ||
            //    (this.selected20_1 == null && this.selected20_2 == null && this.selected20_3 == null &&
            //     this.selected20_4 == null) ||
            //    (this.selected21_1 == null && this.selected21_2 == null && this.selected21_3 == null &&
            //     this.selected21_4 == null) ||
            //    (this.selected22_1 == null && this.selected22_2 == null && this.selected22_3 == null &&
            //     this.selected22_4 == null && this.selected22_5 == null) ||
            //    (this.selected23_1 == null && this.selected23_2 == null && this.selected23_3 == null &&
            //     this.selected23_4 == null && this.selected23_5 == null) || 
            //    (this.selected24_1 == null && this.selected24_2 == null) ||
            //    (this.selected25_1 == null && this.selected25_2 == null)) {
            //   this.$toasted.global.defaultError({ msg: 'Preencha todos os itens da avaliação' })
            // } else {
                Moment.locale('pt-br')

                axios.get(`${baseApiUrl}/patient/getById/${this.patient._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`).then(res => {
                this.patient = res.data

                for (let q of this.patient.questions) {
                    if (q.type == 1 && q.name == this.assessment.name) {
                        q.answers = []

                        q.status = 1
                        q.answers.push(
                            {
                            'dateAnswers': Moment(new Date()).format('L')
                            }, 
                            {
                            '1_1': this.selected1_1,
                            '1_2': this.selected1_2,
                            '1_3': this.selected1_3,
                            '1_4': this.selected1_4,
                            '1_5': this.selected1_5,
                            '1_6': this.selected1_6,
                            '1_7': this.selected1_7,
                            '1_8': this.selected1_8,
                            '1_9': this.selected1_9,
                            '1_10': this.selected1_10,
                            '1_11': this.selected1_11,
                            '1_12': this.selected1_12,
                            '1_13': this.selected1_13
                            },
                            {
                            '2_1': this.selected2_1,
                            '2_2': this.selected2_2,
                            '2_3': this.selected2_3,
                            '2_4': this.selected2_4,
                            '2_5': this.selected2_5,
                            '2_6': this.selected2_6,
                            },
                            {
                            '3_1': this.selected3_1,
                            '3_2': this.selected3_2,
                            '3_3': this.selected3_3,
                            },
                            {
                            '4': this.selected4 
                            },
                            {
                            '5_1': this.selected5_1,
                            '5_2': this.selected5_2,
                            '5_3': this.selected5_3,
                            '5_4': this.selected5_4,
                            '5_5': this.selected5_5,
                            '5_6': this.selected5_6,
                            '5_7': this.selected5_7,
                            '5_8': this.selected5_8,
                            '5_9': this.selected5_9,
                            '5_10': this.selected5_10
                            },
                            {
                            '6_1': this.selected6_1,
                            '6_2': this.selected6_2,
                            '6_3': this.selected6_3,
                            '6_4': this.selected6_4,
                            '6_5': this.selected6_5,
                            '6_6': this.selected6_6,
                            '6_7': this.selected6_7,
                            '6_8': this.selected6_8
                            },                        
                            {
                            '7_1': this.selected7_1, 
                            '7_2': this.selected7_2, 
                            '7_3': this.selected7_3, 
                            '7_4': this.selected7_4, 
                            '7_5': this.selected7_5, 
                            '7_6': this.selected7_6, 
                            '7_7': this.selected7_7, 
                            '7_8': this.selected7_8, 
                            '7_9': this.selected7_9, 
                            '7_10': this.selected7_10, 
                            },
                            {
                            '8': this.selected8 
                            },
                            {
                            '9_1': this.selected9_1, 
                            '9_2': this.selected9_2, 
                            '9_3': this.selected9_3 
                            },
                            {
                            '10_1': this.selected10_1, 
                            '10_2': this.selected10_2, 
                            '10_3': this.selected10_3 
                            },
                            {
                            '11_1': this.selected11_1, 
                            '11_2': this.selected11_2, 
                            '11_3': this.selected11_3,
                            '11_4': this.selected11_4, 
                            '11_5': this.selected11_5, 
                            '11_6': this.selected11_6,
                            '11_7': this.selected11_7, 
                            '11_8': this.selected11_8                                                   
                            },
                            {
                            '12_1': this.selected12_1, 
                            '12_2': this.selected12_2, 
                            '12_3': this.selected12_3,
                            '12_4': this.selected12_4, 
                            '12_5': this.selected12_5, 
                            '12_6': this.selected12_6,
                            '12_7': this.selected12_7, 
                            '12_8': this.selected12_8,
                            '12_9': this.selected12_9
                            },
                            {
                            '13_1': this.selected13_1, 
                            '13_2': this.selected13_2, 
                            '13_3': this.selected13_3,
                            '13_4': this.selected13_4, 
                            '13_5': this.selected13_5, 
                            '13_6': this.selected13_6,
                            '13_7': this.selected13_7,
                            '13_8': this.selected13_8,
                            '13_9': this.selected13_9,
                            '13_10': this.selected13_10,
                            '13_11': this.selected13_11,
                            '13_12': this.selected13_12,
                            },
                            {
                            '14_1': this.selected14_1,
                            '14_2': this.selected14_2,
                            '14_3': this.selected14_3,
                            '14_4': this.selected14_4,
                            '14_5': this.selected14_5,
                            '14_6': this.selected14_6,
                            '14_7': this.selected14_7,
                            '14_8': this.selected14_8,  
                            },
                            {
                            '15': this.selected15
                            },
                            {
                            '16_1': this.selected16_1,
                            '16_2': this.selected16_2,
                            '16_3': this.selected16_3,
                            '16_4': this.selected16_4
                            },
                            {
                            '17_1': this.selected17_1,
                            '17_2': this.selected17_2,
                            '17_3': this.selected17_3,
                            '17_4': this.selected17_4,
                            '17_5': this.selected17_5
                            },
                            {
                            '18_1': this.selected18_1,
                            '18_2': this.selected18_2,
                            '18_3': this.selected18_3,
                            '18_4': this.selected18_4,
                            '18_5': this.selected18_5,
                            '18_6': this.selected18_6,
                            '18_7': this.selected18_7,
                            '18_8': this.selected18_8,
                            '18_9': this.selected18_9,
                            '18_10': this.selected18_10,
                            '18_11': this.selected18_11,
                            '18_12': this.selected18_12,
                            '18_13': this.selected18_13,
                            '18_14': this.selected18_14,
                            '18_15': this.selected18_15
                            },
                            {
                            '19_1': this.selected19_1,
                            '19_2': this.selected19_2,
                            '19_3': this.selected19_3,
                            '19_4': this.selected19_4,
                            '19_5': this.selected19_5,
                            '19_6': this.selected19_6,
                            '19_7': this.selected19_7
                            },
                            {
                            '20_1': this.selected20_1,
                            '20_2': this.selected20_2,
                            '20_3': this.selected20_3,
                            '20_4': this.selected20_4
                            },
                            {
                            '21_1': this.selected21_1,
                            '21_2': this.selected21_2,
                            '21_3': this.selected21_3,
                            '21_4': this.selected21_4
                            },
                            {
                            '22_1': this.selected22_1,
                            '22_2': this.selected22_2,
                            '22_3': this.selected22_3,
                            '22_4': this.selected22_4,
                            '22_5': this.selected22_5
                            },
                            {
                            '23_1': this.selected23_1,
                            '23_2': this.selected23_2,
                            '23_3': this.selected23_3,
                            '23_4': this.selected23_4,
                            '23_5': this.selected23_5
                            },
                            {
                            '24_1': this.selected24_1,
                            '24_2': this.selected24_2 
                            },
                            {
                            '25_1': this.selected25_1,
                            '25_2': this.selected25_2 
                            }                                                                                                                                                                                                                                                                                                                                                                                                                                                             
                        ) 
                    }
                }            

                const method = 'put'            
                const id = `/${this.patient._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`

                axios[method](`${baseApiUrl}/patient${id}`, this.patient)
                    .then(() => {
                        this.$toasted.global.defaultSuccess()
                        this.$router.push({name: 'notation', params: { },})
                    })  
                    .catch(showError) 
                }).catch((e) => {
                  console.log(e)
                  showError
                })
            }             
        // }
    },
    mounted() {
      Moment.locale('pt-br') 

      this.theme = localStorage.getItem('theme')

      this.patient = this.$route.params.questionSelected.patient
      this.assessment = this.$route.params.questionSelected        

      if (this.$route.params.questionSelected.answers.length == 0) {
        this.selected1_1 = this.patient.name
        this.selected1_2 = this.patient.address.street + ', ' + this.patient.address.number + ' - ' + this.patient.address.neighborhood + ' - ' + this.patient.address.city  
        this.selected1_3 = this.patient.email
        this.selected1_4 = this.patient.phone1
        this.selected1_6 = this.patient.cpf
        this.selected1_7 = this.patient.birthday

        let dtIni = Moment(new Date())
        let dtFim = Moment(this.patient.birthday)  

        let age = dtIni.diff(dtFim, 'years')
        
        this.selected1_8 = age
      } else {
        this.selected1_1 = this.$route.params.questionSelected.answers[1]['1_1']
        this.selected1_2 = this.$route.params.questionSelected.answers[1]['1_2']
        this.selected1_3 = this.$route.params.questionSelected.answers[1]['1_3']
        this.selected1_4 = this.$route.params.questionSelected.answers[1]['1_4']
        this.selected1_5 = this.$route.params.questionSelected.answers[1]['1_5']
        this.selected1_6 = this.$route.params.questionSelected.answers[1]['1_6']
        this.selected1_7 = this.$route.params.questionSelected.answers[1]['1_7']  
        this.selected1_8 = this.$route.params.questionSelected.answers[1]['1_8']
        this.selected1_9 = this.$route.params.questionSelected.answers[1]['1_9']
        this.selected1_10 = this.$route.params.questionSelected.answers[1]['1_10']    
        this.selected1_11 = this.$route.params.questionSelected.answers[1]['1_11']
        this.selected1_12 = this.$route.params.questionSelected.answers[1]['1_12']
        this.selected1_13 = this.$route.params.questionSelected.answers[1]['1_13']

        this.selected2_1 = this.$route.params.questionSelected.answers[2]['2_1']
        this.selected2_2 = this.$route.params.questionSelected.answers[2]['2_2']
        this.selected2_3 = this.$route.params.questionSelected.answers[2]['2_3']
        this.selected2_4 = this.$route.params.questionSelected.answers[2]['2_4']
        this.selected2_5 = this.$route.params.questionSelected.answers[2]['2_5']
        this.selected2_6 = this.$route.params.questionSelected.answers[2]['2_6']
        
        this.selected3_1 = this.$route.params.questionSelected.answers[3]['3_1']
        this.selected3_2 = this.$route.params.questionSelected.answers[3]['3_2']
        this.selected3_3 = this.$route.params.questionSelected.answers[3]['3_3']
        
        this.selected4 = this.$route.params.questionSelected.answers[4]['4']

        this.selected5_1 = this.$route.params.questionSelected.answers[5]['5_1']
        this.selected5_2 = this.$route.params.questionSelected.answers[5]['5_2']
        this.selected5_3 = this.$route.params.questionSelected.answers[5]['5_3']
        this.selected5_4 = this.$route.params.questionSelected.answers[5]['5_4']
        this.selected5_5 = this.$route.params.questionSelected.answers[5]['5_5']
        this.selected5_6 = this.$route.params.questionSelected.answers[5]['5_6']
        this.selected5_7 = this.$route.params.questionSelected.answers[5]['5_7']  
        this.selected5_8 = this.$route.params.questionSelected.answers[5]['5_8']
        this.selected5_9 = this.$route.params.questionSelected.answers[5]['5_9']
        this.selected5_10 = this.$route.params.questionSelected.answers[5]['5_10']
        
        this.selected6_1 = this.$route.params.questionSelected.answers[6]['6_1']
        this.selected6_2 = this.$route.params.questionSelected.answers[6]['6_2']
        this.selected6_3 = this.$route.params.questionSelected.answers[6]['6_3']
        this.selected6_4 = this.$route.params.questionSelected.answers[6]['6_4']
        this.selected6_5 = this.$route.params.questionSelected.answers[6]['6_5']
        this.selected6_6 = this.$route.params.questionSelected.answers[6]['6_6']
        this.selected6_7 = this.$route.params.questionSelected.answers[6]['6_7']  
        this.selected6_8 = this.$route.params.questionSelected.answers[6]['6_8']
        
        this.selected7_1 = this.$route.params.questionSelected.answers[7]['7_1']
        this.selected7_2 = this.$route.params.questionSelected.answers[7]['7_2']
        this.selected7_3 = this.$route.params.questionSelected.answers[7]['7_3']
        this.selected7_4 = this.$route.params.questionSelected.answers[7]['7_4']
        this.selected7_5 = this.$route.params.questionSelected.answers[7]['7_5']
        this.selected7_6 = this.$route.params.questionSelected.answers[7]['7_6']
        this.selected7_7 = this.$route.params.questionSelected.answers[7]['7_7']  
        this.selected7_8 = this.$route.params.questionSelected.answers[7]['7_8']
        this.selected7_9 = this.$route.params.questionSelected.answers[7]['7_9']
        this.selected7_10 = this.$route.params.questionSelected.answers[7]['7_10']   
        
        this.selected8 = this.$route.params.questionSelected.answers[8]['8']

        this.selected9_1 = this.$route.params.questionSelected.answers[9]['9_1']
        this.selected9_2 = this.$route.params.questionSelected.answers[9]['9_2']
        this.selected9_3 = this.$route.params.questionSelected.answers[9]['9_3']
        
        this.selected10_1 = this.$route.params.questionSelected.answers[10]['10_1']
        this.selected10_2 = this.$route.params.questionSelected.answers[10]['10_2']
        this.selected10_3 = this.$route.params.questionSelected.answers[10]['10_3']
        
        this.selected11_1 = this.$route.params.questionSelected.answers[11]['11_1']
        this.selected11_2 = this.$route.params.questionSelected.answers[11]['11_2']
        this.selected11_3 = this.$route.params.questionSelected.answers[11]['11_3']
        this.selected11_4 = this.$route.params.questionSelected.answers[11]['11_4']
        this.selected11_5 = this.$route.params.questionSelected.answers[11]['11_5']
        this.selected11_6 = this.$route.params.questionSelected.answers[11]['11_6']
        this.selected11_7 = this.$route.params.questionSelected.answers[11]['11_7']  
        this.selected11_8 = this.$route.params.questionSelected.answers[11]['11_8']
        
        this.selected12_1 = this.$route.params.questionSelected.answers[12]['12_1']
        this.selected12_2 = this.$route.params.questionSelected.answers[12]['12_2']
        this.selected12_3 = this.$route.params.questionSelected.answers[12]['12_3']
        this.selected12_4 = this.$route.params.questionSelected.answers[12]['12_4']
        this.selected12_5 = this.$route.params.questionSelected.answers[12]['12_5']
        this.selected12_6 = this.$route.params.questionSelected.answers[12]['12_6']
        this.selected12_7 = this.$route.params.questionSelected.answers[12]['12_7']  
        this.selected12_8 = this.$route.params.questionSelected.answers[12]['12_8']
        this.selected12_9 = this.$route.params.questionSelected.answers[12]['12_9']
        
        this.selected13_1 = this.$route.params.questionSelected.answers[13]['13_1']
        this.selected13_2 = this.$route.params.questionSelected.answers[13]['13_2']
        this.selected13_3 = this.$route.params.questionSelected.answers[13]['13_3']
        this.selected13_4 = this.$route.params.questionSelected.answers[13]['13_4']
        this.selected13_5 = this.$route.params.questionSelected.answers[13]['13_5']
        this.selected13_6 = this.$route.params.questionSelected.answers[13]['13_6']
        this.selected13_7 = this.$route.params.questionSelected.answers[13]['13_7']  
        this.selected13_8 = this.$route.params.questionSelected.answers[13]['13_8']
        this.selected13_9 = this.$route.params.questionSelected.answers[13]['13_9']        
        this.selected13_10 = this.$route.params.questionSelected.answers[13]['13_10']
        this.selected13_11 = this.$route.params.questionSelected.answers[13]['13_11']
        this.selected13_12 = this.$route.params.questionSelected.answers[13]['13_12']

        this.selected14_1 = this.$route.params.questionSelected.answers[14]['14_1']
        this.selected14_2 = this.$route.params.questionSelected.answers[14]['14_2']
        this.selected14_3 = this.$route.params.questionSelected.answers[14]['14_3']
        this.selected14_4 = this.$route.params.questionSelected.answers[14]['14_4']
        this.selected14_5 = this.$route.params.questionSelected.answers[14]['14_5']
        this.selected14_6 = this.$route.params.questionSelected.answers[14]['14_6']
        this.selected14_7 = this.$route.params.questionSelected.answers[14]['14_7']  
        this.selected14_8 = this.$route.params.questionSelected.answers[14]['14_8']   
        
        this.selected15 = this.$route.params.questionSelected.answers[15]['15']

        this.selected16_1 = this.$route.params.questionSelected.answers[16]['16_1']
        this.selected16_2 = this.$route.params.questionSelected.answers[16]['16_2']
        this.selected16_3 = this.$route.params.questionSelected.answers[16]['16_3']
        this.selected16_4 = this.$route.params.questionSelected.answers[16]['16_4']
        
        this.selected17_1 = this.$route.params.questionSelected.answers[17]['17_1']
        this.selected17_2 = this.$route.params.questionSelected.answers[17]['17_2']
        this.selected17_3 = this.$route.params.questionSelected.answers[17]['17_3']
        this.selected17_4 = this.$route.params.questionSelected.answers[17]['17_4']
        this.selected17_5 = this.$route.params.questionSelected.answers[17]['17_5']
        
        this.selected18_1 = this.$route.params.questionSelected.answers[18]['18_1']
        this.selected18_2 = this.$route.params.questionSelected.answers[18]['18_2']
        this.selected18_3 = this.$route.params.questionSelected.answers[18]['18_3']
        this.selected18_4 = this.$route.params.questionSelected.answers[18]['18_4']
        this.selected18_5 = this.$route.params.questionSelected.answers[18]['18_5']
        this.selected18_6 = this.$route.params.questionSelected.answers[18]['18_6']
        this.selected18_7 = this.$route.params.questionSelected.answers[18]['18_7']  
        this.selected18_8 = this.$route.params.questionSelected.answers[18]['18_8']
        this.selected18_9 = this.$route.params.questionSelected.answers[18]['18_9']
        this.selected18_10 = this.$route.params.questionSelected.answers[18]['18_10']    
        this.selected18_11 = this.$route.params.questionSelected.answers[18]['18_11']
        this.selected18_12 = this.$route.params.questionSelected.answers[18]['18_12']
        this.selected18_13 = this.$route.params.questionSelected.answers[18]['18_13']        
        this.selected18_14 = this.$route.params.questionSelected.answers[18]['18_14']
        this.selected18_15 = this.$route.params.questionSelected.answers[18]['18_15']

        this.selected19_1 = this.$route.params.questionSelected.answers[19]['19_1']
        this.selected19_2 = this.$route.params.questionSelected.answers[19]['19_2']
        this.selected19_3 = this.$route.params.questionSelected.answers[19]['19_3']
        this.selected19_4 = this.$route.params.questionSelected.answers[19]['19_4']
        this.selected19_5 = this.$route.params.questionSelected.answers[19]['19_5']
        this.selected19_6 = this.$route.params.questionSelected.answers[19]['19_6']
        this.selected19_7 = this.$route.params.questionSelected.answers[19]['19_7'] 
        
        this.selected20_1 = this.$route.params.questionSelected.answers[20]['20_1']
        this.selected20_2 = this.$route.params.questionSelected.answers[20]['20_2']
        this.selected20_3 = this.$route.params.questionSelected.answers[20]['20_3']
        this.selected20_4 = this.$route.params.questionSelected.answers[20]['20_4']
        
        this.selected21_1 = this.$route.params.questionSelected.answers[21]['21_1']
        this.selected21_2 = this.$route.params.questionSelected.answers[21]['21_2']
        this.selected21_3 = this.$route.params.questionSelected.answers[21]['21_3']
        this.selected21_4 = this.$route.params.questionSelected.answers[21]['21_4']
        
        this.selected22_1 = this.$route.params.questionSelected.answers[22]['22_1']
        this.selected22_2 = this.$route.params.questionSelected.answers[22]['22_2']
        this.selected22_3 = this.$route.params.questionSelected.answers[22]['22_3']
        this.selected22_4 = this.$route.params.questionSelected.answers[22]['22_4']  
        this.selected22_5 = this.$route.params.questionSelected.answers[22]['22_5']  
        
        this.selected23_1 = this.$route.params.questionSelected.answers[23]['23_1']
        this.selected23_2 = this.$route.params.questionSelected.answers[23]['23_2']
        this.selected23_3 = this.$route.params.questionSelected.answers[23]['23_3']
        this.selected23_4 = this.$route.params.questionSelected.answers[23]['23_4']  
        this.selected23_5 = this.$route.params.questionSelected.answers[23]['23_5'] 
        
        this.selected24_1 = this.$route.params.questionSelected.answers[24]['24_1']
        this.selected24_2 = this.$route.params.questionSelected.answers[24]['24_2']
        
        this.selected25_1 = this.$route.params.questionSelected.answers[25]['25_1']
        this.selected25_2 = this.$route.params.questionSelected.answers[25]['25_2']        
      }
    }
}
</script>

<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}

.card-violet {
  background-color: #f3eaff;
}
</style>