<template>
  <Loading v-if="isLoading" />
  <div v-else class="admission-admin">
    <PageTitle icon="fa fa-sign-in" main="Admissão de Paciente" sub />
    <b-form>
      <input id="admission-id" type="hidden" v-model="admission.id" />
      <b-row>
        <b-col md="12" sm="12">
          <b-card :class="`card-${theme}`" bg-variant="default" header="Dados do Paciente" header-bg-variant="dark" header-text-variant="white">
            <b-row class="mt-4">
              <b-col md="3" sm="12">
                <b-form-group label="Paciente:" label-for="patient-name">
                  <b-form-input size="sm"
                    id="patient-name"
                    type="text"
                    v-model="resultLookupPatient.name"
                    disabled/>
                </b-form-group>
              </b-col>
              <b-col md="2" sm="12">
                <b-form-group label="CPF:" label-for="patient-cpf">
                  <b-form-input size="sm"
                    id="patient-cpf"
                    type="number"
                    v-model="resultLookupPatient.cpf"
                    disabled/>
                </b-form-group>
              </b-col>
              <b-col md="2" sm="12">
                <b-form-group label="Convênio:" label-for="patient-covenant">
                  <b-form-input size="sm"
                    id="patient-covenant"
                    type="text"
                    v-model="resultLookupPatient.covenant.name"
                    disabled/>
                </b-form-group>
              </b-col>
              <b-col md="2" sm="12">
                <b-form-group label="Data Nascimento:" label-for="patient-birthday">
                  <b-form-input id="patient-birthday" v-model="resultLookupPatient.birthday" disabled size="sm"/>
                </b-form-group>
              </b-col>
              <b-col md="1" sm="6" class="align-self-center mt-2">
                <b-button size="sm" v-if="!admission._id" v-b-modal.modal-prevent-closing @click="setModal('patient')"><i class="fa fa-search"></i></b-button>
                <Lookup v-if="modal === 'patient'" ref="LookupP" title="Pacientes" :list="resultPatients" :fields="fieldsP"
                  :invalidFeedback="invalidFeedback" resultTable="patient">
                </Lookup>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col md="5" sm="12">
                <b-form-group label="Observações:" label-for="admission-comments">
                  <b-textarea size="sm" id="admission-comments" v-model="admission.comments" />
                </b-form-group>
              </b-col>            
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" sm="12" class="mt-3">
          <b-card
            :class="`card-${theme}`" 
            bg-variant="default"
            header="Dados da Internação"
            header-bg-variant="dark"
            header-text-variant="white">
            <b-row>
              <b-col md="2" sm="12">
                <b-form-group
                  label="Data Admissão:"
                  label-for="admission-date">
                  <b-form-input size="sm"
                    id="admission-date"
                    type="date"
                    v-model="admission.date"/>
                </b-form-group>
              </b-col>
              <b-col md="2" sm="12">
                <b-form-group size="sm"
                  label="Nº Atendimento:"
                  label-for="admission-number">
                  <b-form-input size="sm"
                    id="admission-number"
                    type="number"
                    v-model="admission.attendanceNumber"/>
                </b-form-group>
              </b-col>
              <b-col md="2" sm="12">
                <b-form-group
                  label="Nº Guia:"
                  label-for="admission-numberGuide">
                  <b-form-input size="sm"
                    id="admission-numberGuide"
                    type="text"
                    v-model="admission.numberGuide"/>
                </b-form-group>
              </b-col>
              <b-col md="2" sm="12">
                <b-form-group size="sm"
                  label="Senha Operadora:"
                  label-for="admission-password">
                  <b-form-input size="sm"
                    id="admission-password"
                    type="text"
                    v-model="admission.password"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="2" sm="12">
                <b-form-group
                  label="Prédio:"
                  label-for="accommodation-building"
                >
                  <b-form-input size="sm"
                    id="accommodation-building"
                    type="text"
                    v-model="resultLookupAccommodation.building"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col md="2" sm="12">
                <b-form-group label="Andar:" label-for="accommodation-floor">
                  <b-form-input size="sm"
                    id="accommodation-floor"
                    type="text"
                    v-model="resultLookupAccommodation.floor"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col md="2" sm="12">
                <b-form-group label="Apartamento:" label-for="accommodation-apartment">
                  <b-form-input size="sm" id="accommodation-apartment" type="text" v-model="resultLookupAccommodation.apartment" disabled />
                </b-form-group>
              </b-col>
              <b-col md="2" sm="12">
                <b-form-group label="Leito:" label-for="accommodation-bed">
                  <b-form-input size="sm"
                    id="accommodation-bed"
                    type="text"
                    v-model="resultLookupAccommodation.name"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col md="1" sm="6" class="align-self-center">
                <b-button size="sm" class="mt-2" v-b-modal.modal-prevent-closing @click="setModal('accommodation')"><i class="fa fa-search"></i></b-button>
                <Lookup v-if="modal === 'accommodation'" ref="LookupA" title="Acomodações" :list="resultAccommodations"
                  :fields="fieldsA" :invalidFeedback="invalidFeedback" resultTable="accommodation"></Lookup>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" sm="12" class="mt-3">
          <b-card
            :class="`card-${theme}`"
            bg-variant="default"
            header="Profissional Solicitante"
            header-bg-variant="dark"
            header-text-variant="white">
            <b-row>
              <b-col md="3" sm="12">
                <b-form-group label="Nome:" label-for="professional-name">
                  <b-form-input size="sm"
                    id="professional-name"
                    type="text"
                    v-model="professional.name"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col md="2" sm="12">
                <b-form-group label="CPF:" label-for="professional-cpf">
                  <b-form-input size="sm"
                    id="professional-cpf"
                    type="text"
                    v-model="professional.cpf"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col md="2" sm="12">
                <b-form-group
                  label="Registro:"
                  label-for="professional-adviceNumber"
                >
                  <b-form-input size="sm"
                    id="professional-adviceNumber"
                    type="text"
                    v-model="professional.adviceNumber"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col md="1" sm="12" class="align-self-center">
                <b-button class="mt-2" size="sm" @click="setModal('professionals')" v-b-modal.modal-prevent-closing v-b-popover.hover.top="'Adiconar Profissional'">
                  <i class="fa fa-search"></i>
                </b-button>

                <Lookup v-if="modal === 'professionals'" ref="Lookup" title="Profissionais" :list="resultProfessionals" :fields="fieldsPr"
                  :invalidFeedback="invalidFeedback" @event="addProfessional" resultTable="professionalAdmission"></Lookup>
              </b-col>
              <b-col md="2" sm="12">
                <b-form-group label="Laudo:" label-for="admission-medicalReport">
                  <b-form-file v-model="file" @input="selectFile()" accept=".jpg, .png, .jpeg, .pdf" id="admission-medicalReport" size="sm"
                    :placeholder="`${admission.medicalReport == null ? 'Selecione o Laudo' : admission.medicalReport.name}`" browse-text="Adicionar"></b-form-file>                  
                </b-form-group>
              </b-col>    
              <b-col md="2" sm="12">
                <b-button size="sm" v-show="file != null" class="mt-4" variant="danger" @click="deleteFile()" v-b-popover.hover.top="'Remover Laudo'">
                  <i class="fa fa-trash"></i>
                </b-button>
                <b-button size="sm" v-show="file != null" class="mt-4 ml-2" variant="info" @click="openFile()" v-b-popover.hover.top="'Visualizar Laudo'">
                  <i class="fa fa-eye"></i>
                </b-button>
              </b-col>            
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" sm="12" class="mt-3">
          <b-card :class="`card-${theme}`" bg-variant="default" header="Dados de Atendimento" header-bg-variant="dark" header-text-variant="white">
            <b-row>
              <b-button size="sm" v-b-modal.modal-prevent-closing variant="success" class="mb-3 ml-3" @click="setModal('typeService')"><i 
                class="fa fa-plus" v-b-popover.hover.top="'Incluir Tipo de Atendimento'"></i>
              </b-button>

              <Lookup v-if="modal === 'typeService'" ref="LookupT" title="Tipos de Atendimento" :list="resultTypeServices"
                      :fields="fieldsTLookup" :invalidFeedback="invalidFeedback" resultTable="typeService"></Lookup>
              <b-col md="12" sm="12">
                <b-table ref="selectTypeService" head-variant="light" outlined hover :items="resultLookupTypeService"
                        :fields="fieldsT" responsive="sm">
                  <template v-slot:cell(morning)="rowm">
                    <b-checkbox :checked="admission.attendance[rowm.index].morning" size="sm" class="cbMorning"></b-checkbox>
                  </template>
                  <template v-slot:cell(evening)="rowe">
                    <b-checkbox :checked="admission.attendance[rowe.index].evening" size="sm" class="cbEvening"></b-checkbox>
                  </template>
                  <template v-slot:cell(night)="rown">
                    <b-checkbox :checked="admission.attendance[rown.index].night" size="sm" class="cbNight"></b-checkbox>
                  </template>
                  <template v-slot:cell(actions)="row">
                    <b-button size="sm" variant="danger" @click="removeTypeService(row.item)">
                      <i class="fa fa-trash"></i>
                    </b-button>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-form>

    <b-button size="sm" variant="success" @click="save" class="mt-3">SALVAR</b-button>
    <b-button size="sm" variant="danger" @click="cancel" class="mt-3 ml-2">CANCELAR</b-button>
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, userKey } from '@/global'
import axios from "axios"
import Lookup from "../default/Lookup"
import { mapState } from "vuex"
import util from '../default/util'
import Loading from "../template/Loading"

const convertToBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})

export default {
  name: "AdmissionPage",
  components: { PageTitle, Lookup, Loading },
  data: function () {
    return {
      theme: null,
      isLoading: false,
      admission: {},
      fieldsP: [
        { key: "name", label: "Nome", sortable: true },
        { key: "cpf", label: "CPF", sortable: true },
        { key: "birthday", label: "Data Nascimento", sortable: true },
      ],
      fieldsPr: [
        { key: "name", label: "Nome", sortable: true },
        { key: "cpf", label: "CPF", sortable: true },
        { key: "advice.number", label: "Registro", sortable: true },
      ],
      fieldsA: [
        { key: "building", label: "Prédio", sortable: true },
        { key: "floor", label: "Andar", sortable: true },
        { key: "apartment", label: "Apartamento", sortable: true },
        { key: "name", label: "Leito", sortable: true },
      ],
      fieldsT: [
        { key: "name", label: "Nome", sortable: true },
        { key: "morning", label: "Manhã", sortable: true },
        { key: "evening", label: "Tarde", sortable: true },
        { key: "night", label: "Noite", sortable: true },
        { key: "actions", label: "Ações" },
      ],
      fieldsTLookup: [
        { key: "name", label: "Nome", sortable: true },
      ],
      file: null,
      invalidFeedback: "",
      resultPatients: [],
      resultAccommodations: [],
      resultProfessionals: [],
      professional: {},
      resultTypeServices: [],
      modal: null,
      settings: null
    }
  },
  computed: mapState(["resultLookupPatient", "resultLookupAccommodation", "resultLookupTypeService", "resultAdmission"]),
  methods: {
    async save() {
      const method = this.admission._id ? "put" : "post"
      const id = this.admission._id ? `/${this.admission._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}` : `/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`

      this.admission.patient = this.resultLookupPatient
      this.admission.accommodation = this.resultLookupAccommodation
      this.admission.professional = this.professional 

      let cbm = document.getElementsByClassName("cbMorning")
      let cbe = document.getElementsByClassName("cbEvening")
      let cbn = document.getElementsByClassName("cbNight")

      for (let i = 0; i <= cbm.length - 1; i++) {
        this.resultLookupTypeService[i].morning = cbm[i].childNodes[0].checked;
      }

      for (let i = 0; i <= cbe.length - 1; i++) {
        this.resultLookupTypeService[i].evening = cbe[i].childNodes[0].checked;
      }

      for (let i = 0; i <= cbn.length - 1; i++) {
        this.resultLookupTypeService[i].night = cbn[i].childNodes[0].checked;
      }

      this.admission.attendance = this.resultLookupTypeService

      if (!this.admission._id) {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1;
        var hr = today.getHours();
        var mi = today.getMinutes();
        var se = today.getSeconds();

        if (dd < 10) {
          dd = "0" + dd;
        }

        if (mm < 10) {
          mm = "0" + mm;
        }

        if (hr < 10) {
          hr = "0" + hr;
        }

        if (mi < 10) {
          mi = "0" + mi;
        }

        if (se < 10) {
          se = "0" + se;
        }

        this.admission.hour = `${hr}:${mi}:${se}`

        this.admission.released = false
      }   

      this.isLoading = true
    
      axios[method](`${baseApiUrl}/admission${id}`, this.admission).then(() => {
        this.isLoading = false

        this.$store.commit("setResultAdmission", {})
        this.$toasted.global.defaultSuccess()
        this.$router.go(-1);
      }).catch(e => {
        this.isLoading = false
        this.$toasted.global.defaultError({ msg: e.response.data })
      })
    },
    cancel() {
      this.$store.commit("setResultAdmission", {})
      this.$router.go(-1);
    },
    loadAccommodations() {
      this.isLoading = true

      const url = `${baseApiUrl}/accommodation/type/Leito&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
      axios.get(url).then((res) => {
        this.isLoading = false
        this.resultAccommodations = res.data;
      }).catch(e => {
        this.isLoading = false
        this.$toasted.global.defaultError({ msg: e.response.data })
      })
    },
    loadProfessionals() {      
      const url = `${baseApiUrl}/professional/enabled/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`

      this.isLoading = true

      axios.get(url).then((res) => {
        for (let d of res.data) {
          this.resultProfessionals.push({
            name: d.name,
            cpf: d.cpf,
            adviceNumber: d.advice.number,
          })
        }

        this.isLoading = false
      }).catch(e => {
        this.isLoading = false
        this.$toasted.global.defaultError({ msg: e.response.data })
      })
    },
    loadTypeServices() {          
        this.resultTypeServices = [
          { name: 'CONSULTA/AVALIAÇÃO' }, 
          { name: 'DINAMOMETRIA' }, 
          { name: 'ELETROESTIMULAÇÃO' }, 
          { name: 'MOTORA' }, 
          { name: 'RESPIRATÓRIA' },
          { name: 'RESPIRATÓRIA E MOTORA (PACOTE)' }
        ]
    },    
    setModal(param) {
      this.admission.patient = this.resultLookupPatient
      this.admission.accommodation = this.resultLookupAccommodation
      this.admission.professional = this.professional

      let cbm = document.getElementsByClassName("cbMorning")
      let cbe = document.getElementsByClassName("cbEvening")
      let cbn = document.getElementsByClassName("cbNight")

      for (let i = 0; i <= cbm.length - 1; i++) {
        this.resultLookupTypeService[i].morning = cbm[i].childNodes[0].checked;
      }

      for (let i = 0; i <= cbe.length - 1; i++) {
        this.resultLookupTypeService[i].evening = cbe[i].childNodes[0].checked;
      }

      for (let i = 0; i <= cbn.length - 1; i++) {
        this.resultLookupTypeService[i].night = cbn[i].childNodes[0].checked;
      }

      this.admission.attendance = this.resultLookupTypeService          

      if (param == 'typeService') {
        this.resultTypeServices = []

        if (this.admission.patient.covenant._id) {
          this.loadTypeServices(this.admission.patient.covenant._id)        
        }
      }

      this.$store.commit("setResultAdmission", this.admission)

      this.modal = param
    },
    removeTypeService(itemSelected) {
      let i = 0;

      this.resultLookupTypeService.forEach((t) => {
        if (t.name === itemSelected.name) {
          this.resultLookupTypeService.splice(i, 1);
        }

        i += 1;
      });
    },
    addProfessional(payload) {
      this.professional = {
        name: payload.professionalSelected.name,
        cpf: payload.professionalSelected.cpf,
        adviceNumber: payload.professionalSelected.adviceNumber,
      };
    },
    loadSettings() {
      this.isLoading = true

      const url = `${baseApiUrl}/setting/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
      axios.get(url).then(res => {
        this.isLoading = false
        this.settings = res.data                
      }).catch(e => {
        this.isLoading = false
        this.$toasted.global.defaultError({ msg: e.response.data })
      })                      
    },
    async selectFile() {
      if (this.file) {
        const convertedFile = await convertToBase64(this.file)
       
        if (convertedFile) {
          this.admission.medicalReport = {
            name: this.file.name,
            base64: convertedFile
          }        
        }
      }
    },
    openFile() {
      try {
        let extension = this.admission.medicalReport.name.split('.')
        
        util.imprimirBase64(this.admission.medicalReport.base64, extension[1])
      } catch (e) {
        this.$toasted.global.defaultError({ msg: 'Erro ao abrir o arquivo' })
      }
    },
    deleteFile() {
      this.file = null
      this.admission.medicalReport = null
    }
  },
  mounted() {
    this.theme = localStorage.getItem('theme')

    if (this.$route.params.admissionSelected) {
        this.admission = this.$route.params.admissionSelected    
        
        let patientLoad = {
          _id: this.admission.patient._id,
          name: this.admission.patient.name,
          birthday: this.admission.patient.birthday,
          cpf: this.admission.patient.cpf,
          rg: this.admission.patient.rg,
          email: this.admission.patient.email,
          covenant: this.admission.patient.covenant,
        }

        let accommodationLoad = {
          _id: this.admission.accommodation._id,
          name: this.admission.accommodation.name,
          building: this.admission.accommodation.building,
          floor: this.admission.accommodation.floor,
          apartment: this.admission.accommodation.apartment
        }

        if (this.admission.medicalReport) {
          this.file = this.admission.medicalReport.name
        }

        for (let a of this.admission.attendance) {
          this.$store.commit("setResultLookupTypeService", {
            name: a.name,
            morning: a.morning,
            evening: a.evening,
            night: a.night,
            launchOnceAdmission: a.launchOnceAdmission
          })      
        }

        this.admission.attendance = this.resultLookupTypeService

        this.professional = this.admission.professional

        this.$store.commit("setResultLookupPatient", patientLoad)
        this.$store.commit("setResultLookupAccommodation", accommodationLoad)        
    } else if (this.resultAdmission.patient) {
      this.admission = this.resultAdmission

      this.$store.commit("setResultLookupPatient", this.admission.patient)
      this.$store.commit("setResultLookupAccommodation", this.admission.accommodation)    

      for (let a of this.admission.attendance) {
        this.$store.commit("setResultLookupTypeService", {
          name: a.name,
          morning: a.morning,
          evening: a.evening,
          night: a.night                      
        })      
      }   
      
      if (this.admission.medicalReport) {
        this.file = this.admission.medicalReport.name
      }      

      this.professional = this.admission.professional

      this.$store.commit("setResultAdmission", {})
    }
  },
  created() {
    this.loadAccommodations()
    this.loadProfessionals()
    this.loadSettings()

    let patientLoad = {
      name: "",
      covenant: {},
    };

    let accommodationLoad = {
      name: "",
    };

    this.$store.commit("setResultLookupPatient", patientLoad)
    this.$store.commit("setResultLookupAccommodation", accommodationLoad)
    this.$store.commit("setResultLookupTypeService", [])
  }
}
</script>

<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}

.card-violet {
  background-color: #f3eaff;
}
</style>