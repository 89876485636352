<template>
  <div :class="`user-admin-${theme}`">
    <b-row>
      <b-col md="4" sm="12">
        <b-card :class="`card-${theme}`" header="E-mail" header-bg-variant="dark" header-text-variant="white">
          <b-row>
            <b-col md="12" sm="12">
              <b-form-group label="Remetente">
                <b-input size="sm" v-model="emailSender"></b-input>
              </b-form-group>
            </b-col>
          </b-row>
         <b-row>
            <b-col md="12" sm="12">
              <b-form-group label="Senha">
                <b-input size="sm" type="password" v-model="emailPassword"></b-input>
              </b-form-group>
            </b-col>
          </b-row>
         <b-row>
            <b-col md="12" sm="12">
              <b-form-group label="SMTP">
                <b-input size="sm" v-model="emailSMTP"></b-input>
              </b-form-group>
            </b-col>
          </b-row>
         <b-row>
            <b-col md="12" sm="12">
              <b-form-group label="Porta">
                <b-input size="sm" type="number" v-model="emailPort"></b-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="8" sm="12">
        <b-row>
          <b-col md="12" sm="12">
            <b-card :class="`card-${theme}`" header="Agenda" header-bg-variant="dark" header-text-variant="white">
              <b-row>
                <b-col md="3" sm="12" class="mt-1">
                  <b-form-group label-align="center" label="Tempo Entre Atendimentos (min)">
                  </b-form-group>
                </b-col>
                <b-col md="2" sm="12">
                  <b-input size="sm" type="number" v-model="scaleTimeBetween"></b-input>
                </b-col>
                <b-col md="3" sm="12" class="mt-1">
                  <b-form-group label-align="center" label="Data Inicial - Semana 1">                    
                  </b-form-group>
                </b-col>
                <b-col md="2" sm="12" class="mt-1">
                  <b-input size="sm" type="date" v-model="scaleDateInitial"></b-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3" sm="12">
                  <b-form-group label-align="center" label="Idade Máxima Atendimento Pediátrico">                    
                  </b-form-group>
                </b-col>    
                <b-col md="2" sm="12" class="mt-1">
                  <b-input size="sm" type="number" v-model="minAgePediatric"></b-input>
                </b-col>
                <b-col md="3" sm="12">
                  <b-form-group label-align="center" label="Quantidade Dias Próxima Avaliação">                    
                  </b-form-group>
                </b-col>                       
                <b-col md="2" sm="12" class="mt-1">
                  <b-input size="sm" type="number" v-model="numberDaysQuestion"></b-input>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3" sm="12">
                  <b-form-group label-align="center" label="Horário Inicial Atendimento" class="mt-1">                    
                  </b-form-group>
                </b-col>
                <b-col md="2" sm="12" class="mt-1">
                  <b-form-timepicker size="sm" placeholder="" v-model="hourInitialScheduling" id="hour-initial-input" hide-header></b-form-timepicker>
                </b-col>
                <b-col md="3" sm="12" class="mt-2">
                  <b-form-group label-align="center" label="Horário Final Atendimento">
                  </b-form-group>
                </b-col>    
                <b-col md="2" sm="12">
                  <b-form-timepicker size="sm" placeholder="" v-model="hourFinalScheduling" id="hour-final-input" hide-header></b-form-timepicker>
                </b-col>
                <b-col md="3" sm="12" class="mt-2">
                  <b-form-group label-align="center" label="Limite Máximo Dias Agendamentos">
                  </b-form-group>
                </b-col> 
                <b-col md="2" sm="12" class="mt-1">
                  <b-input size="sm" type="number" v-model="maxDaysScheduling"></b-input>
                </b-col>
              </b-row> 
              <b-row>
                <b-col md="4" sm="12" class="mt-3">
                  <b-form-checkbox size="sm" id="checkbox-dateActualNotation" v-model="dateActualNotation" name="checkbox-dateActualNotation" :value="true" :unchecked-value="false">Utilizar Data Atual Nos Apontamentos</b-form-checkbox>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4" sm="12" class="mt-2">
        <b-card :class="`card-${theme}`" header="ANS" header-bg-variant="dark" header-text-variant="white">
          <b-row>
            <b-col md="12" sm="12">              
              <b-form-group label="Número Lote">
                <b-input size="sm" type="number" v-model="numberBatch"></b-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" sm="12">
              <b-form-group label="Número Guia Prestador">
                <b-input size="sm" type="number" v-model="numberBatchProvider"></b-input>
              </b-form-group>  
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" sm="12">        
              <b-form-group label="CNES">
                <b-input size="sm" type="number" v-model="cnes"></b-input>
              </b-form-group>          
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-button size="sm" variant="success" @click="updateSettings" class="mt-3">SALVAR</b-button>
  </div>
</template>

<script>
import { baseApiUrl, showError, userKey } from '@/global'
import axios from "axios";

export default {
  name: "GeneralAdmin",
  data: function () {
    return {
      theme: null,
      settings: null,
      emailSender: null,
      emailPassword: null,
      emailSMTP: null,
      emailPort: null,
      scaleTimeBetween: null,
      scaleDateInitial: null,
      minAgePediatric: null,
      numberBatch: null,
      numberBatchProvider: null,
      registerANS: null,
      hourInitialScheduling: null,
      hourFinalScheduling: null,
      dateActualNotation: false,
      numberDaysQuestion: null,
      maxDaysScheduling: null,
      cnes: null
    };
  },
  methods: {
    loadSettings() {
      const url = `${baseApiUrl}/setting/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`;
      axios.get(url).then((res) => {         
          if (res.data.length != 0) {
            this.settings = res.data

            let index = 0

            this.settings[0].params.find((item, i) => {
              if (item.name == "emailRemetente") {
                index = i;
              }
            });

            this.emailSender = this.settings[0].params[index].value;

            this.settings[0].params.find((item, i) => {
              if (item.name == "emailSenha") {
                index = i;
              }
            });

            this.emailPassword = this.settings[0].params[index].value;

            this.settings[0].params.find((item, i) => {
              if (item.name == "emailSMTP") {
                index = i;
              }
            });

            this.emailSMTP = this.settings[0].params[index].value;

            this.settings[0].params.find((item, i) => {
              if (item.name == "emailPorta") {
                index = i;
              }
            });

            this.emailPort = this.settings[0].params[index].value;

            this.settings[0].params.find((item, i) => {
              if (item.name == "atendimentoTempoEntre") {
                index = i;
              }
            });

            this.scaleTimeBetween = this.settings[0].params[index].value;

            this.settings[0].params.find((item, i) => {
              if (item.name == "dataInicialEscala") {
                index = i;
              }
            });

            this.scaleDateInitial = this.settings[0].params[index].value;

            this.settings[0].params.find((item, i) => {
              if (item.name == "numberBatch") {
                index = i;
              }
            });

            this.numberBatch = this.settings[0].params[index].value;

            this.settings[0].params.find((item, i) => {
              if (item.name == "numberBatchProvider") {
                index = i;
              }
            });
            
            this.numberBatchProvider = this.settings[0].params[index].value; 

            this.settings[0].params.find((item, i) => {
              if (item.name == "registerANS") {
                index = i;
              }
            });

            this.registerANS = this.settings[0].params[index].value;

            this.settings[0].params.find((item, i) => {
              if (item.name == "minAgePediatric") {
                index = i;
              }
            });

            this.minAgePediatric = this.settings[0].params[index].value;

            this.settings[0].params.find((item, i) => {
              if (item.name == "CNES") {
                index = i;
              }
            });

            this.cnes = this.settings[0].params[index].value;      
            
            this.settings[0].params.find((item, i) => {
              if (item.name == "hourInitialScheduling") {
                index = i;
              }
            });

            this.hourInitialScheduling = this.settings[0].params[index].value; 
            
            this.settings[0].params.find((item, i) => {
              if (item.name == "hourFinalScheduling") {
                index = i;
              }
            });

            this.hourFinalScheduling = this.settings[0].params[index].value;      
            
            this.settings[0].params.find((item, i) => {
              if (item.name == "dateActualNotation") {
                index = i;
              }
            });

            this.dateActualNotation = this.settings[0].params[index].value;  
            
            this.settings[0].params.find((item, i) => {
              if (item.name == "numberDaysQuestion") {
                index = i;
              }
            });

            this.numberDaysQuestion = this.settings[0].params[index].value;   
            
            this.settings[0].params.find((item, i) => {
              if (item.name == "maxDaysScheduling") {
                index = i;
              }
            });

            this.maxDaysScheduling = this.settings[0].params[index].value;    
          } else {
            this.settings = []
            this.settings.push({
              params: []
            }) 
          }
        })
        .catch(showError);
    },
    updateSettings() {
      let params = [];

      params.push({
        name: "emailRemetente",
        type: "String",
        value: this.emailSender,
        description: "Conta do E-mail para envio de e-mails pelo sistema.",
      });

      params.push({
        name: "emailSenha",
        type: "String",
        value: this.emailPassword,
        description: "Senha do E-mail para envio de e-mails pelo sistema.",
      });

      params.push({
        name: "emailSMTP",
        type: "String",
        value: this.emailSMTP,
        description: "SMTP do serviço para envio de e-mails pelo sistema.",
      });

      params.push({
        name: "emailPorta",
        type: "int",
        value: this.emailPort,
        description: "Porta do serviço para envio de e-mails pelo sistema.",
      });

      params.push({
        name: "atendimentoTempoEntre",
        type: "int",
        value: this.scaleTimeBetween,
        description:
          "Tempo utilizado na distribuição de atendimentos dos profissionais.",
      });

      params.push({
        name: "dataInicialEscala",
        type: "date",
        value: this.scaleDateInitial,
        description:
          "Data inicial das escalas. Base para preenchimento de profissionais na agenda.",
      });

      params.push({
        name: "numberBatch",
        type: "number",
        value: this.numberBatch,
        description: "Identificação do número do lote.",
      });

      params.push({
        name: "registerANS",
        type: "number",
        value: this.registerANS,
        description: "Registro na ANS.",
      });

      params.push({
        name: "minAgePediatric",
        type: "number",
        value: this.minAgePediatric,
        description: "Idade mínima atendimento pediátrico.",
      });         
      
      params.push({
        name: "CNES",
        type: "number",
        value: this.cnes,
        description: "CNES",
      });       

      params.push({
        name: "numberBatchProvider",
        type: "number",
        value: this.numberBatchProvider,
        description: "Identificação do número da guia do prestador.",
      });      
      
      params.push({
        name: "hourInitialScheduling",
        type: "String",
        value: this.hourInitialScheduling,
        description: "Horário inicial de atendimento para agendamentos.",
      });   
      
      params.push({
        name: "hourFinalScheduling",
        type: "String",
        value: this.hourFinalScheduling,
        description: "Horário final de atendimento para agendamentos.",
      });        
      
      params.push({
        name: "dateActualNotation",
        type: "Boolean",
        value: this.dateActualNotation,
        description: "Utilizar data atual nos apontamentos.",
      });     
      
      params.push({
        name: "numberDaysQuestion",
        type: "number",
        value: this.numberDaysQuestion,
        description: "Número de dias para lançar uma nova avaliação automaticamente no agendamento.",
      });      

      params.push({
        name: "maxDaysScheduling",
        type: "number",
        value: this.maxDaysScheduling,
        description: "Limite máximo de dias do agendamento."
      }); 
      
      this.settings[0].params = params;

      const method = this.settings[0]._id ? "put" : "post";
      const id = this.settings[0]._id ? `${this.settings[0]._id}&` : "";

      axios[method](`${baseApiUrl}/setting/${id}${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, this.settings[0])
        .then(() => {
          this.$toasted.global.defaultSuccess();
          this.loadSettings();
        })
        .catch(showError);
    },
  },
  mounted() {
    this.theme = localStorage.getItem('theme')
    this.loadSettings();
  },
};
</script>

<style>
.user-admin-green {
  background-color: rgb(196, 235, 220);
}

.user-admin-wine {
  background-color: rgb(255, 241, 241);
}

.user-admin-violet {
  background-color: #f3eaff;
}

.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}

.card-violet {
  background-color: #f3eaff;
}
</style>