<template>
  <div class="container">
    <b-row>
      <b-col md="12" sm="12">
        <div :class="`div-${theme}`">
          <aside :class="`loading-${theme}`" />
        </div>        
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "Loading",
  data: function() {
    return {
      theme: null  
    }
  },
  mounted() {  
    this.theme = localStorage.getItem('theme')
  }       
};
</script>

<style>
.div-wine {
  position: relative;
  height: 100%;
}

.loading-green {
  animation: is-rotating 1s infinite;
  width: 60px;
  height: 60px;
  background-color: #f7fff9;
  border: 6px solid #28A745;
  border-top-color: rgb(196, 235, 220);
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; 
}

.loading-wine {
  animation: is-rotating 1s infinite;
  width: 60px;
  height: 60px;
  background-color: rgb(255, 241, 241);
  border: 6px solid #7A1120;
  border-top-color: #ff9595;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; 
}

.loading-violet {
  animation: is-rotating 1s infinite;
  width: 60px;
  height: 60px;
  background-color: #f3eaff;
  border: 6px solid #6E4DA4;
  border-top-color: #d8c6f0;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; 
}

@keyframes is-rotating {
  to {
    transform: rotate(1turn);
  }
}

.container {
  width: 100vw;
  height: 100vh;
  background: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

body {
  margin: 0px;
}
</style>