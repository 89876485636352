<template>
  <Loading v-if="isLoading" />
  <div v-else class="patientResume-pages">
    <PageTitle icon="fa fa-file-text" main="Resumo do Paciente" :sub="`${patient.name} - ${patient.cpf}`" />

    <div class="admin-pages-tabs">
      <b-card :class="`card-${theme}`" header="Avaliações" header-bg-variant="dark" header-text-variant="white">
        <b-table ref="selectableTable" head-variant="light" outlined hover :items="assessments" :fields="fields" responsive="sm" id="my-table" striped fixed :filter="ftext">
            <template v-slot:cell(actions)="row">
                <b-button :variant="row.item.status == 0 ? 'warning' : 'success'" size="sm" @click="answerAssessment(row.item)" 
                  v-b-popover.hover.top="row.item.status == 0 ? 'Responder' : 'Visualizar'">
                    <i :class="row.item.status == 0 ? 'fa fa-pencil' : 'fa fa-eye'"></i>
                </b-button>

                <b-button class="ml-2" v-show="row.item.status == 1" size="sm" variant="info" @click="printQuestion(row.item)" v-b-popover.hover.top="'Imprimir'">
                    <i class="fa fa-print"></i>
                </b-button>
            </template>
        </b-table>              
      </b-card>

      <b-card :class="`card-${theme} mt-3`" header="Monitorização" header-bg-variant="dark" header-text-variant="white">
        <b-row>
          <b-col md="1" sm="12">
            <b-button size="sm" v-b-modal.modal-prevent-closing variant="success" @click="setModal('questionnaires')">NOVO</b-button>            
          </b-col> 
          <b-col md="3" sm="12">
            <b-form-input size="sm" placeholder="Filtrar..." v-model="ftext"></b-form-input>
          </b-col> 
        </b-row> 
        <b-table class="mt-3" ref="selectableTable" head-variant="light" outlined hover :items="questionnairesPatient" :fields="fieldsQT" responsive="sm" id="my-table" striped fixed :filter="ftext">
            <template v-slot:cell(actions)="row">
                <b-button :variant="row.item.status == 0 ? 'warning' : 'success'" size="sm" @click="answerTests(row.item)" 
                  v-b-popover.hover.top="row.item.status == 0 ? 'Responder' : 'Visualizar'">
                    <i :class="row.item.status == 0 ? 'fa fa-pencil' : 'fa fa-eye'"></i>
                </b-button>
                <b-button class="ml-2" v-show="row.item.status == 1" size="sm" variant="info" @click="printTest(row.item)" v-b-popover.hover.top="'Imprimir'">
                    <i class="fa fa-print"></i>
                </b-button>
            </template>            
        </b-table>              
      </b-card>

      <b-card :class="`card-${theme} mt-3`" header="Documentos" header-bg-variant="dark" header-text-variant="white">
        <b-button size="sm" v-b-modal.modal-prevent-docs variant="success" @click="lookupResumeDocuments($event.target)">NOVO</b-button>
        <b-table class="mt-3" ref="selectableTable" head-variant="light" outlined hover :items="patient.documents" :fields="fieldsD" responsive="sm" id="my-table" striped fixed>
            <template v-slot:cell(actions)="row">
              <b-button :variant="row.item.status == 0 ? 'warning' : 'success'" size="sm" @click="openFile(row.item)" 
                  v-b-popover.hover.top="row.item.status == 0 ? 'Responder' : 'Visualizar'">
                    <i :class="row.item.status == 0 ? 'fa fa-pencil' : 'fa fa-eye'"></i>
                </b-button>
            </template>            
        </b-table>              
      </b-card>   
      
      <b-modal :id="infoModalDocuments.id" :title="'Documentos'" @hidden="resetModal" @ok="insertDocument()" size="md" ok-only>
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group label="Arquivo:" label-for="document-name">
                <b-form-file v-model="file" @input="selectFile()" accept=".jpg, .png, .jpeg, .pdf" id="documents-file" size="sm"
                            :placeholder="`${documents.file == null ? 'Selecione um Arquivo' : documents.file}`" browse-text="Adicionar"></b-form-file>                  
                </b-form-group>
                <hr>
                <b-form-group label="Nome do Arquivo:" label-for="documents-name">
                  <b-form-input size="sm" id="documents-name" type="text" v-model="documents.name" placeholder="Informe o Nome do Arquivo..." />
                </b-form-group>
            </form>
      </b-modal> 
      
      <Lookup ref="Lookup" title="Monitorização" :list="questionnaires" :fields="fieldsT" :invalidFeedback="invalidFeedback" @event="setTest"
          resultTable="questionnaires" v-if="modal === 'questionnaires'"></Lookup>

      <b-card :class="`card-${theme} mt-3`" header="Questionários" header-bg-variant="dark" header-text-variant="white">
        <b-button size="sm" v-b-modal.modal-prevent-closing variant="success" @click="setModal('questions')">NOVO</b-button>
        <b-table class="mt-3" ref="selectableTable" head-variant="light" outlined hover :items="questionsPatient" :fields="fieldsQP" responsive="sm" id="my-table" striped fixed>
            <template v-slot:cell(actions)="row">
                <b-button :variant="row.item.status == 0 ? 'warning' : 'success'" size="sm" @click="answerQuestion(row.item)" 
                  v-b-popover.hover.top="row.item.status == 0 ? 'Responder' : 'Visualizar'">
                    <i :class="row.item.status == 0 ? 'fa fa-pencil' : 'fa fa-eye'"></i>
                </b-button>
            </template>            
        </b-table>              
      </b-card>       

      <Lookup ref="Lookup" title="Questionários" :list="questions" :fields="fieldsQ" :invalidFeedback="invalidFeedback" @event="setQuestion"
          resultTable="questions" v-if="modal === 'questions'"></Lookup>      

      <b-card :class="`card-${theme} mt-3`" header="Evoluções" header-bg-variant="dark" header-text-variant="white">
        <b-table ref="selectableTable" head-variant="light" outlined hover :items="evolutions" :fields="fieldsEv" responsive="sm" id="my-table" striped fixed>
            <template v-slot:cell(actions)="row">
                <b-button size="sm" variant="success" @click="lookupResumeEvolution(row.item, $event.target)" v-b-popover.hover.top="'Visualizar'">
                    <i class="fa fa-eye"></i>
                </b-button>
            </template>            
        </b-table>              
      </b-card>  

      <b-modal :id="infoModalEvolution.id" :title="infoModalEvolution.title" @hidden="resetModal" @ok="handleResumeOk" size="lg" ok-only>
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group :label="`Paciente: ${patient.name}`" label-for="resume-input"></b-form-group>
                <b-form-group :label="`CPF: ${patient.cpf}`" label-for="resume-input"></b-form-group>
                <hr>
                <b-form-group label="Descrição" label-for="resume-input">
                    <b-form-textarea id="textarea" v-model="evolutionSelected" rows="1" max-rows="10"></b-form-textarea> 
                </b-form-group>
            </form>
      </b-modal>             
    </div>
  </div>
</template>

<script>
import PageTitle from "../../template/PageTitle"
import Moment from 'moment'
import { baseApiUrl, userKey } from '@/global'
import axios from 'axios'
import Lookup from '../../default/Lookup'
import { mapState } from "vuex"
import Loading from "../../template/Loading"
import util from '../../default/util'

const convertToBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})

export default {
    name: 'PatientResume',
    components: { PageTitle, Lookup, Loading },
    data: function() {
      return {
        theme: null,
        isLoading: false,
        ftext: '',
        patient: {},
        assessments: [],
        evolutions: [],
        questionsPatient: [],
        questions: [],
        questionnairesPatient: [],
        questionnaires: [],
        documentsPatient: [],
        documents: {},
        invalidFeedback: '',
        file: [],
        fields: [                 
            { key: 'date2', label: 'Data Solicitação', sortable: true },
            { key: 'name', label: 'Nome', sortable: true },            
            { key: 'actions', label: 'Ações' }
        ],          
        fieldsEv: [                 
            { key: 'date', label: 'Data', sortable: true },
            { key: 'professional.name', label: 'Profissional', sortable: false },
            { key: 'professional.adviceNumber', label: 'Registro', sortable: false },
            { key: 'actions', label: 'Ações' }
        ], 
        fieldsQP: [                 
            { key: 'date2', label: 'Data', sortable: true },
            { key: 'name', label: 'Nome', sortable: false },
            { key: 'actions', label: 'Ações' }
        ],   
        fieldsQ: [                 
            { key: 'name', label: 'Nome', sortable: false },
        ],
        fieldsD: [                 
            { key: 'name', label: 'Nome', sortable: false },
            { key: 'actions', label: 'Ações' }
        ],
        fieldsQT: [                 
            { key: 'date2', label: 'Data Solicitação', sortable: true },
            { key: 'name', label: 'Nome', sortable: true },            
            { key: 'actions', label: 'Ações' }
        ],    
        fieldsT: [                 
            { key: 'name', label: 'Nome', sortable: false },
        ],             
        infoModalEvolution: {
            id: 'info-modal-evolution',
            title: '',
            content: ''
        },  
        infoModalQuestion: {
            id: 'info-modal-question',
            title: '',
            content: ''
        },         
        infoModalTests: {
            id: 'info-modal-tests',
            title: '',
            content: ''
        },
        infoModalDocuments: {
            id: 'info-modal-documents',
            title: '',
            content: ''
        },
        evolutionSelected: null,
        modal: null,
      }
    },
    computed: mapState(["resultPatient"]),
    methods: {
        printQuestion(itemSelected) {
          let contentPdf = {
              title: itemSelected.name,
              question: itemSelected
            }            

            this.isLoading = true

            const url = `${baseApiUrl}/reports/pdfGeneratorAssessment/${itemSelected.name}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.post(url, contentPdf, {responseType: 'arraybuffer'}).then(res => {  
              this.isLoading = false
              var file = new Blob([res.data], {type: 'application/pdf'})
              var fileURL = URL.createObjectURL(file);
              window.open(fileURL);

                this.generatingPDF = false
                this.textBtnPrint = 'IMPRIMIR'
            }).catch(e => {
              this.isLoading = false
              this.$toasted.global.defaultError({ msg: e.response.data })              
            })
        },
        printTest(itemSelected) {
          let contentPdf = {
              title: itemSelected.name,
              question: itemSelected,
              patient: this.patient
          }            

          const url = `${baseApiUrl}/reports/pdfGeneratorAssessment/${itemSelected.name}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
          
          this.isLoading = true

          axios.post(url, contentPdf, {responseType: 'arraybuffer'}).then(res => {
            this.isLoading = false

            var file = new Blob([res.data], {type: 'application/pdf'})
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);

            this.generatingPDF = false
            this.textBtnPrint = 'IMPRIMIR'
          }).catch(e => {
            this.isLoading = false
            this.$toasted.global.defaultError({ msg: e.response.data })
          })             
        },
        answerAssessment(itemSelected) {
          let questionSelected = {    
            _id: itemSelected._id,
            name: itemSelected.name,
            patient: this.patient,
            answers: itemSelected.answers
          }              
 
          if (itemSelected.name == 'TRAUMATO FUNCIONAL') {
            this.$router.push({name: 'assessmentTrauma', params: { questionSelected }})
          } else if (itemSelected.name == 'PEDIÁTRICA') {
            this.$router.push({name: 'assessmentPediatric', params: { questionSelected }})
          } else if (itemSelected.name == 'PÉLVICA') {
            this.$router.push({name: 'assessmentPelvic', params: { questionSelected }})
          } else if (itemSelected.name == 'OSTEOPATIA') {
            this.$router.push({name: 'assessmentOsteopathy', params: { questionSelected }})
          } else if (itemSelected.name == 'PNEUMOFUNCIONAL E REABILITAÇÃO CARDIOVASCULAR') {
            this.$router.push({name: 'assessmentRespiratory', params: { questionSelected }})
          } else if (itemSelected.name == 'OBSTÉTRICA') {
            this.$router.push({name: 'assessmentObstetrics', params: { questionSelected }})
          }                
        },
        answerQuestion(itemSelected) {
          let questionSelected = {    
            _id: itemSelected._id,
            name: itemSelected.name,
            status: itemSelected.status,
            patient: this.patient,
            answers: itemSelected.answers
          } 

          if (itemSelected.name == 'PESQUISA SATISFAÇÃO') {
            this.$router.push({name: 'satisfactionSurvey', params: { questionSelected }})
          }          
        },
        answerTests(itemSelected) {
          let questionSelected = {    
            _id: itemSelected._id,
            name: itemSelected.name,
            status: itemSelected.status,
            patient: this.patient,
            answers: itemSelected.answers
          } 

          if (itemSelected.name == 'ESCALA DE BARTHEL') {
            this.$router.push({name: 'testEscalaBarthel', params: { questionSelected }})
          } else if(itemSelected.name == 'DASH – QUESTIONNAIRE DISABILITIES OF THE ARM, SHOULDER AND HAND') {
            this.$router.push({name: 'testDASH', params: { questionSelected }})
          } else if(itemSelected.name == 'ESCALA DE BORG') {
            this.$router.push({name: 'testEscalaBorg', params: { questionSelected }})
          } else if(itemSelected.name == 'ESCALA DE SONOLÊNCIA DE EPWORTH (ESE)') {
            this.$router.push({name: 'testESE', params: { questionSelected }})
          } else if(itemSelected.name == 'ESCALA VISUAL ANALÓGICA - EVA') {
            this.$router.push({name: 'testEva', params: { questionSelected }})
          } else if(itemSelected.name == 'QUESTIONARIO LOWER EXTREMITY FUNCTIOAL SCALE – LEFS') {
            this.$router.push({name: 'testLEFS', params: { questionSelected }})
          } else if(itemSelected.name == 'MODIFIED MEDICAL RESEARCH COUNCIL (mMRC)') {
            this.$router.push({name: 'testMMRC', params: { questionSelected }})
          } else if(itemSelected.name == 'SF-36') {
            this.$router.push({name: 'testSF36', params: { questionSelected }})
          } else if(itemSelected.name == 'EMIA') {
            this.$router.push({name: 'testEMIA', params: { questionSelected }})
          }     
        },
        getEvolutions() {
          let filter = ''

          filter = this.patient._id

          this.isLoading = true

          const url = `${baseApiUrl}/scale/evolutions/${filter}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
          axios.get(url).then(res => {  
              this.isLoading = false
              this.evolutions = res.data
          }).catch(e => {
            this.isLoading = false
            this.$toasted.global.defaultError({ msg: e.response.data })            
          })
        },
        lookupResumeEvolution(item, button) {
            this.infoModalEvolution.title = 'Evolução'
            this.infoModalEvolution.content = JSON.stringify(item, null)

            this.selectEvolution(item.evolution)
            
            this.$root.$emit('bv::show::modal', this.infoModalEvolution.id, button)
        },   
        lookupQuestion(item, button) {
            this.infoModalQuestion.title = 'Questionários'
            this.infoModalQuestion.content = JSON.stringify(item, null)
            this.$root.$emit('bv::show::modal', this.infoModalQuestion.id, button)
        },  
        lookupTestes(item, button) {
            this.infoModalTestes.title = 'Monitorização'
            this.infoModalTestes.content = JSON.stringify(item, null)
            this.$root.$emit('bv::show::modal', this.infoModalTestes.id, button)
        },
        lookupResumeDocuments() {
            this.$bvModal.show(this.infoModalDocuments.id)
            this.$nextTick(() => {
                this.$bvModal.hide(this.infoModalEvolution.id)
                this.$bvModal.hide(this.infoModalQuestion.id)
            })  
            
        },
        insertDocument() {
          let valid = true

          const method = this.patient._id ? 'put' : 'post'
          const id = this.patient._id ? `/${this.patient._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}` : `/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`

          if (this.documents.file == undefined) {
            this.$toasted.global.defaultError({msg: 'Selecione o Arquivo!'})
            return
          }

          if (this.documents.name == undefined) {
            this.$toasted.global.defaultError({msg: 'Informe o nome do Documento!'})  
            return    
          }

          if (this.documents.file.includes('/pdf;') || this.documents.file.includes('/png;') || this.documents.file.includes('/jpg;') || this.documents.file.includes('/jpeg;')) { 
            valid = true  
          } else {
            this.$toasted.global.defaultError({msg: 'Não é suportado este tipo de Arquivo!'})  
            return  
          }
          
          if (valid) {
            for (let dc of this.patient.documents) {
                if (dc.name == this.documents.name) {
                  this.$toasted.global.defaultError({msg: 'Este Documento já foi cadastrado!'})  
                  return 
                }
            }

            let extension = this.file.name.split('.')

            this.patient.documents.push({
                file: this.documents.file,
                name: this.documents.name.toUpperCase(),
                extension: extension[extension.length-1]
              })

            this.isLoading = true 

            axios[method](`${baseApiUrl}/patient${id}`, this.patient).then(() => {
                this.isLoading = false
                this.$toasted.global.defaultSuccess()
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })
            })
          }
        },
        selectEvolution(itemSelected) {
            this.evolutionSelected = itemSelected
        },
        resetModal() {
            this.evolutionSelected = null
            this.questionSelected = null
        },
        handleResumeOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()

            this.$nextTick(() => {
                this.$bvModal.hide(this.infoModalEvolution.id)
            })            
        },
        async selectFile() {
          if (this.file) {
            const convertedFile = await convertToBase64(this.file)
          
            if (convertedFile) {
                this.documents = {
                    file: convertedFile
                }        
            }
          }
        },
        openFile(itemSelected) {
            try {
               for (let dc of this.patient.documents) {
                  if (dc.name == itemSelected.name) {
                 
                    util.imprimirBase64(dc.file, dc.extension)

                    break
                  }
               } 
            } catch (e) {
                this.$toasted.global.defaultError({ msg: 'Erro ao abrir o arquivo' })
            }
        },
        getQuestions() {
          this.modal = null

          this.isLoading = true

          const url = `${baseApiUrl}/questions/3&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
          axios.get(url).then(res => {
            this.isLoading = false
            this.questions = res.data
          }).catch(e => {
            this.isLoading = false
            this.$toasted.global.defaultError({ msg: e.response.data })            
          })
        },
        getTests() {
          this.modal = null

          this.isLoading = true

          const url = `${baseApiUrl}/questions/2&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
          axios.get(url).then(res => {
            this.isLoading = false
            this.questionnaires = res.data
          }).catch(e => {
            this.isLoading = false
            this.$toasted.global.defaultError({ msg: e.response.data })            
          })
        },
        setModal(param) {
          this.modal = param
        }, 
        setTest(payload) {
          let today = new Date()

          let year = today.getFullYear()
          let month = today.getMonth() + 1
          let day = today.getDate()

          if (day < 10) {
            day = '0' + day
          }

          if (month < 10) {
            month = '0' + month
          }

          let question = {
            date: year + '-' + month + '-' + day, 
            id: payload.questionnaireSelected._id,
            name: payload.questionnaireSelected.name,
            status: 0,
            type: 2,
            answers: []
          }

          this.isLoading = true

          const url = `${baseApiUrl}/patient/setQuestion/${this.patient._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
          axios.post(url, question).then(() => {  
            this.isLoading = false

            question.date2 = day + '/' + month + '/' + year

            this.questionnairesPatient.push(question)

            this.$toasted.global.defaultSuccess({msg: 'Teste adicionado com sucesso.'})
          }).catch(e => {
            this.isLoading = false
            this.$toasted.global.defaultError({ msg: e.response.data })            
          })
        },  
        setQuestion(payload) {
          let today = new Date()

          let year = today.getFullYear()
          let month = today.getMonth() + 1
          let day = today.getDate()

          if (day < 10) {
            day = '0' + day
          }

          if (month < 10) {
            month = '0' + month
          }

          let question = {
            date: year + '-' + month + '-' + day, 
            id: payload.questionSelected._id,
            name: payload.questionSelected.name,
            status: 0,
            type: 3,
            answers: []
          }

          this.isLoading = true

          const url = `${baseApiUrl}/patient/setQuestion/${this.patient._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
          axios.post(url, question).then(() => {
            this.isLoading = false
            
            question.date2 = day + '/' + month + '/' + year

            this.questionsPatient.push(question)

            this.$toasted.global.defaultSuccess({msg: 'Questionário adicionado com sucesso.'})
          }).catch(e => {
            this.isLoading = false
            this.$toasted.global.defaultError({ msg: e.response.data })            
          })
        },          
    },
    mounted() {
      this.theme = localStorage.getItem('theme')

      Moment.locale('pt-br')

      this.patient = this.$route.params.patient

      if (this.resultPatient) {
        this.patient = this.resultPatient
      }

      if (this.patient.documents) {
          for (let dc of this.patient.documents) {
          this.file = dc.documents
        }
      } else {
        this.file = []
      }

      this.getEvolutions()
      this.getQuestions()
      this.getTests()

      if (this.patient.questions) {
        if (this.patient.questions.length > 0) {
          for (let q of this.patient.questions) {
            q.date2 = Moment(q.date).format('L')

            if (q.type == 1) {
              this.assessments.push(q)
            } else if (q.type == 2) {
                this.questionnairesPatient.push(q)
            } else if (q.type == 3) {
              this.questionsPatient.push(q)
            }
          }
        }
      }
    }    
};
</script>

<style>
.page-item .page-font {
    color:#44A08D;  
}

.page-item.active .page-link {  
    background-color:#44A08D;  
    border-color:  #44A08D;      
}

.page-item.active .page-link:hover {  
    background-color:#44A08D;  
    border-color:  #44A08D;  
}

.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}

.card-violet {
  background-color: #f3eaff;
}

.card-header {
    height: 38px;
    font-size: 14px;    
}

.card-body {
    font-size: 14px;
}

.calendar-btn {
    font-size: 0.30;
}

.table th {
  padding: 0.45rem;
}

.table td {
  padding: 0.45rem;
  vertical-align: middle;
}
</style>