<template>
  <div class="satisfactionSurvey">
    <PageTitle icon="fa fa-file-text" main="Pesquisa de Satisfação" sub="Relatório" />
        <div class="satisfactionSurvey-page-tabs">
            <b-row>
                <b-col md="6" sm="12" class="mt-3">
                    <b-card :class="`card-${theme}`" header="Informações" header-bg-variant="dark" header-text-variant="white">
                        <b-row>
                            <b-col md="3" sm="12" class="mt-4">
                                <b-form-group label="Data Inicial:" label-for="satisfactionSurvey-dateInitial">
                                    <b-form-input size="sm" id="satisfactionSurvey-dateInitial" type="date" v-model="dateInitial" required />
                                </b-form-group>
                            </b-col>
                            <b-col md="3" sm="12" class="mt-4">
                                <b-form-group label="Data Final:" label-for="satisfactionSurvey-dateFinal">
                                    <b-form-input size="sm" id="satisfactionSurvey-dateFinal" type="date" v-model="dateFinal" required/>
                                </b-form-group>
                            </b-col>
                            <b-col md="3" sm="12">
                                <b-button size="sm" variant="success" class="mt-5" @click="getSatisfactionSurvey()">Processar</b-button>
                            </b-col>
                        </b-row>
                    </b-card>                    
                </b-col>                  
            </b-row>   
        </div>
  </div>  
</template>

<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'
import Moment from 'moment'

export default {
    name: "SatisfactionSurvey",
    components: { PageTitle },    
    data: function() {
        return {
            theme: null,
            dateInitial: null,
            dateFinal: null,
            satisfactionSurveyResume: null,
            generatingPDF: false,
            textBtnPrint: 'GERAR'  
        }
    },   
    methods: {
        async showReport() {
            let list = []
            let listTopics = []

            listTopics.push(`Total de pesquisas realizadas no período: ${this.satisfactionSurveyResume.numberTotal}`)

            listTopics.push('AVALIAÇÃO GERAL DA CLÍNICA')
            listTopics.push('Facilidade no agendamento das sessões:')
            listTopics.push('Clareza nas informações prestadas:')
            listTopics.push('Limpeza do local de atendimento:')
            listTopics.push('Limpeza banheiros/Recepção:')

            listTopics.push('AVALIAÇÃO DA RECEPÇÃO')
            listTopics.push('Cordialidade durante o atendimento:')
            listTopics.push('Qualidade do atendimento:')
            listTopics.push('Clareza nas informações repassadas:')

            listTopics.push('AVALIAÇÃO FISIOTERAPEUTAS')
            listTopics.push('Atendimento:')
            listTopics.push('Cordialidade:')
            listTopics.push('Conhecimento técnico empregado durante as sessões:')
            listTopics.push('Clareza nas informações sobre o tratamento:')                      

            list.push({
                'Péssimo': this.satisfactionSurveyResume['1_1'][0]['Péssimo'],
                'Ruim': this.satisfactionSurveyResume['1_1'][0]['Ruim'],
                'Razoável': this.satisfactionSurveyResume['1_1'][0]['Razoável'],
                'Bom': this.satisfactionSurveyResume['1_1'][0]['Bom'],
                'Ótimo': this.satisfactionSurveyResume['1_1'][0]['Ótimo'],
                key: 'Facilidade no agendamento das sessões:'
            })

            list.push({
                'Péssimo': this.satisfactionSurveyResume['1_2'][0]['Péssimo'],
                'Ruim': this.satisfactionSurveyResume['1_2'][0]['Ruim'],
                'Razoável': this.satisfactionSurveyResume['1_2'][0]['Razoável'],
                'Bom': this.satisfactionSurveyResume['1_2'][0]['Bom'],
                'Ótimo': this.satisfactionSurveyResume['1_2'][0]['Ótimo'],
                key: 'Clareza nas informações prestadas:'
            })       
            
            list.push({
                'Péssimo': this.satisfactionSurveyResume['1_3'][0]['Péssimo'],
                'Ruim': this.satisfactionSurveyResume['1_3'][0]['Ruim'],
                'Razoável': this.satisfactionSurveyResume['1_3'][0]['Razoável'],
                'Bom': this.satisfactionSurveyResume['1_3'][0]['Bom'],
                'Ótimo': this.satisfactionSurveyResume['1_3'][0]['Ótimo'],
                key: 'Limpeza do local de atendimento:'
            })     
            
            list.push({
                'Péssimo': this.satisfactionSurveyResume['1_4'][0]['Péssimo'],
                'Ruim': this.satisfactionSurveyResume['1_4'][0]['Ruim'],
                'Razoável': this.satisfactionSurveyResume['1_4'][0]['Razoável'],
                'Bom': this.satisfactionSurveyResume['1_4'][0]['Bom'],
                'Ótimo': this.satisfactionSurveyResume['1_4'][0]['Ótimo'],
                key: 'Limpeza banheiros/Recepção:'
            })   
            
            list.push({
                'Péssimo': this.satisfactionSurveyResume['1_5'][0]['Péssimo'],
                'Ruim': this.satisfactionSurveyResume['1_5'][0]['Ruim'],
                'Razoável': this.satisfactionSurveyResume['1_5'][0]['Razoável'],
                'Bom': this.satisfactionSurveyResume['1_5'][0]['Bom'],
                'Ótimo': this.satisfactionSurveyResume['1_5'][0]['Ótimo'],
                key: 'Cordialidade durante o atendimento:'
            }) 
            
            list.push({
                'Péssimo': this.satisfactionSurveyResume['1_6'][0]['Péssimo'],
                'Ruim': this.satisfactionSurveyResume['1_6'][0]['Ruim'],
                'Razoável': this.satisfactionSurveyResume['1_6'][0]['Razoável'],
                'Bom': this.satisfactionSurveyResume['1_6'][0]['Bom'],
                'Ótimo': this.satisfactionSurveyResume['1_6'][0]['Ótimo'],
                key: 'Qualidade do atendimento:'
            })   
            
            list.push({
                'Péssimo': this.satisfactionSurveyResume['1_7'][0]['Péssimo'],
                'Ruim': this.satisfactionSurveyResume['1_7'][0]['Ruim'],
                'Razoável': this.satisfactionSurveyResume['1_7'][0]['Razoável'],
                'Bom': this.satisfactionSurveyResume['1_7'][0]['Bom'],
                'Ótimo': this.satisfactionSurveyResume['1_7'][0]['Ótimo'],
                key: 'Clareza nas informações repassadas:'
            })  
            
            list.push({
                'Péssimo': this.satisfactionSurveyResume['1_8'][0]['Péssimo'],
                'Ruim': this.satisfactionSurveyResume['1_8'][0]['Ruim'],
                'Razoável': this.satisfactionSurveyResume['1_8'][0]['Razoável'],
                'Bom': this.satisfactionSurveyResume['1_8'][0]['Bom'],
                'Ótimo': this.satisfactionSurveyResume['1_8'][0]['Ótimo'],
                key: 'Atendimento:'
            })            
            
            list.push({
                'Péssimo': this.satisfactionSurveyResume['1_9'][0]['Péssimo'],
                'Ruim': this.satisfactionSurveyResume['1_9'][0]['Ruim'],
                'Razoável': this.satisfactionSurveyResume['1_9'][0]['Razoável'],
                'Bom': this.satisfactionSurveyResume['1_9'][0]['Bom'],
                'Ótimo': this.satisfactionSurveyResume['1_9'][0]['Ótimo'],
                key: 'Cordialidade:'
            })   
            
            list.push({
                'Péssimo': this.satisfactionSurveyResume['1_10'][0]['Péssimo'],
                'Ruim': this.satisfactionSurveyResume['1_10'][0]['Ruim'],
                'Razoável': this.satisfactionSurveyResume['1_10'][0]['Razoável'],
                'Bom': this.satisfactionSurveyResume['1_10'][0]['Bom'],
                'Ótimo': this.satisfactionSurveyResume['1_10'][0]['Ótimo'],
                key: 'Conhecimento técnico empregado durante as sessões:'
            }) 
            
            list.push({
                'Péssimo': this.satisfactionSurveyResume['1_11'][0]['Péssimo'],
                'Ruim': this.satisfactionSurveyResume['1_11'][0]['Ruim'],
                'Razoável': this.satisfactionSurveyResume['1_11'][0]['Razoável'],
                'Bom': this.satisfactionSurveyResume['1_11'][0]['Bom'],
                'Ótimo': this.satisfactionSurveyResume['1_11'][0]['Ótimo'],
                key: 'Clareza nas informações sobre o tratamento:'
            })            

            let contentPdf = {
                title: `PESQUISA DE SATISFAÇÃO - ${Moment(this.dateInitial).format('L')} a ${Moment(this.dateFinal).format('L')}`,
                format: 'A4',
                orientation: 'portrait',
                separate: false,
                content: [
                    {
                        type: 'table',
                        topicFields: listTopics,
                        fields: [                                                                
                            {
                                original: 'Péssimo',
                                exibition: 'Péssimo',
                                notDisplay: [`Total de pesquisas realizadas no período: ${this.satisfactionSurveyResume.numberTotal}`, 'AVALIAÇÃO GERAL DA CLÍNICA', 'AVALIAÇÃO DA RECEPÇÃO', 'AVALIAÇÃO FISIOTERAPEUTAS']
                            },                                
                            {
                                original: 'Ruim',
                                exibition: 'Ruim',
                                notDisplay: [`Total de pesquisas realizadas no período: ${this.satisfactionSurveyResume.numberTotal}`, 'AVALIAÇÃO GERAL DA CLÍNICA', 'AVALIAÇÃO DA RECEPÇÃO', 'AVALIAÇÃO FISIOTERAPEUTAS']
                            },
                            {
                                original: 'Razoável',
                                exibition: 'Razoável',
                                notDisplay: [`Total de pesquisas realizadas no período: ${this.satisfactionSurveyResume.numberTotal}`, 'AVALIAÇÃO GERAL DA CLÍNICA', 'AVALIAÇÃO DA RECEPÇÃO', 'AVALIAÇÃO FISIOTERAPEUTAS']
                            },
                            {
                                original: 'Bom',
                                exibition: 'Bom',
                                notDisplay: [`Total de pesquisas realizadas no período: ${this.satisfactionSurveyResume.numberTotal}`, 'AVALIAÇÃO GERAL DA CLÍNICA', 'AVALIAÇÃO DA RECEPÇÃO', 'AVALIAÇÃO FISIOTERAPEUTAS']
                            },
                            {
                                original: 'Ótimo',
                                exibition: 'Ótimo',
                                notDisplay: [`Total de pesquisas realizadas no período: ${this.satisfactionSurveyResume.numberTotal}`, 'AVALIAÇÃO GERAL DA CLÍNICA', 'AVALIAÇÃO DA RECEPÇÃO', 'AVALIAÇÃO FISIOTERAPEUTAS']
                            }                                                                                                                                                                  
                        ],
                        list: list
                    },
                ]
            }

            const url = `${baseApiUrl}/reports/pdfGenerator/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.post(url, contentPdf, {responseType: 'arraybuffer'}).then(res => {  
            var file = new Blob([res.data], {type: 'application/pdf'})
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);

                this.generatingPDF = false
                this.textBtnPrint = 'GERAR'    
            }).catch(showError)                   
        },
        async getSatisfactionSurvey() {
            let valid = true

            if (this.dateInitial == null || this.dateFinal == null) {
                this.$toasted.global.defaultError({msg: 'Informe a data inicial e final.'})
                valid = false
            } 

            Moment.locale('pt-br')

            let dtInitial = Moment(this.dateInitial)
            let dtFinal = Moment(this.dateFinal)  

            let diff = dtFinal.diff(dtInitial, 'days')

            if (diff < 0) {
                this.$toasted.global.defaultError({msg: 'Data final menor que data inicial.'})
                valid = false
            } 

            if (valid) {         
                this.$bvModal.msgBoxConfirm('Deseja processar o período informado?', {
                    title: 'Confirmação',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Sim',
                    cancelTitle: 'Não',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                }).then(value => {            
                    if (value) {
                        this.generatingPDF = true
                        this.textBtnPrint = 'Aguarde...' 

                        let data = {
                            dateInitial: this.dateInitial,
                            dateFinal: this.dateFinal
                        }

                        const url = `${baseApiUrl}/patient/getSatisfactionSurvey/${dtInitial}&${dtFinal}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
                        axios.post(url, data).then(res => {
                            this.satisfactionSurveyResume = res.data  

                            this.showReport()
                        }).catch(showError)        
                    }
                })
            }      
        }
    },
    mounted() {
        this.theme = localStorage.getItem('theme')
    }
}
</script>

<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}
</style>