export default {
    states: [
        {value: 12, text: "ACRE"},
        {value: 27, text: "ALAGOAS"},
        {value: 16, text: "AMAPÁ"},
        {value: 13, text: "AMAZONAS"},
        {value: 29, text: "BAHIA"},
        {value: 23, text: "CEARÁ"},
        {value: 53, text: "DISTRITO FEDERAL"},
        {value: 32, text: "ESPÍRITO SANTO"},
        {value: 52, text: "GOIÁS"},
        {value: 21, text: "MARANHÃO"},
        {value: 51, text: "MATO GROSSO"},
        {value: 50, text: "MATO GROSSO DO SUL"},
        {value: 31, text: "MINAS GERAIS"},
        {value: 15, text: "PARÁ"},
        {value: 25, text: "PARAÍBA"},
        {value: 41, text: "PARANÁ"},
        {value: 26, text: "PERNAMBUCO"},
        {value: 22, text: "PIAUÍ"},
        {value: 33, text: "RIO DE JANEIRO"},
        {value: 24, text: "RIO GRANDE DO NORTE"},
        {value: 43, text: "RIO GRANDE DO SUL"},
        {value: 11, text: "RONDÔNIA"},
        {value: 14, text: "RORAIMA"},
        {value: 42, text: "SANTA CATARINA"},
        {value: 35, text: "SÃO PAULO"},
        {value: 28, text: "SERGIPE"},
        {value: 17, text: "TOCANTINS"},
    ],  
}