<template>
    <div class="assessmentTrauma">
        <PageTitle icon="fa fa-file-text" main="Ficha de Avaliação" sub="Pediátrica" />

        <div>
            <b-card :class="`card-${theme}`">
                <b-button @click="sendQuestions()" variant="info">Enviar</b-button>
                
                <b-form-group class="mt-3">
                    <b-button-group>
                        <b-button @click="numberQuestion = 1" :variant="(selected1_1 != null ||
                                                                         selected1_2 != null ||
                                                                         selected1_3 != null ||
                                                                         selected1_4 != null ||
                                                                         selected1_5 != null ||
                                                                         selected1_6 != null ||
                                                                         selected1_7 != null ||
                                                                         selected1_8 != null ||
                                                                         selected1_9 != null ||
                                                                         selected1_10 != false ||
                                                                         selected1_11 != false ||
                                                                         selected1_12 != false ||
                                                                         selected1_13 != null ||
                                                                         selected1_14 != null) ? 'success' : 'secondary'">1</b-button>
                        <b-button @click="numberQuestion = 2" :variant="(selected2_1 != null ||
                                                                         selected2_2 != null ||
                                                                         selected2_3 != null ||
                                                                         selected2_4 != null ||
                                                                         selected2_5 != null ||
                                                                         selected2_6 != null ||
                                                                         selected2_7 != null ||
                                                                         selected2_8 != false ||
                                                                         selected2_9 != false ||
                                                                         selected2_10 != false ||
                                                                         selected2_11 != null ||
                                                                         selected2_12 != null ||
                                                                         selected2_13 != null ||
                                                                         selected2_14 != null ||
                                                                         selected2_15 != null ||
                                                                         selected2_16 != null ||
                                                                         selected2_17 != null ||
                                                                         selected2_18 != null) ? 'success' : 'secondary'">2</b-button>
                        <b-button @click="numberQuestion = 3" :variant="(selected3_1 != null ||
                                                                         selected3_2 != null ||
                                                                         selected3_3 != null ||
                                                                         selected3_4 != false ||
                                                                         selected3_5 != false ||
                                                                         selected3_6 != false ||
                                                                         selected3_7 != false ||
                                                                         selected3_8 != null ||
                                                                         selected3_9 != null ||
                                                                         selected3_10 != null) ? 'success' : 'secondary'">3</b-button>
                        <b-button @click="numberQuestion = 4" :variant="selected4 != null ? 'success' : 'secondary'">4</b-button>
                        <b-button @click="numberQuestion = 5" :variant="(selected5_1 != false ||
                                                                         selected5_2 != false ||
                                                                         selected5_3 != false ||
                                                                         selected5_4 != false ||
                                                                         selected5_5 != false ||
                                                                         selected5_6 != false ||
                                                                         selected5_7 != false ||
                                                                         selected5_8 != null ||
                                                                         selected5_9 != null ||
                                                                         selected5_10 != false ||
                                                                         selected5_11 != false ||
                                                                         selected5_12 != false ||
                                                                         selected5_13 != null) ? 'success' : 'secondary'">5</b-button>
                        <b-button @click="numberQuestion = 6" :variant="(selected6_1 != null ||
                                                                         selected6_2 != null ||
                                                                         selected6_3 != null ||
                                                                         selected6_4 != null ||
                                                                         selected6_5 != null ||
                                                                         selected6_6 != null ||
                                                                         selected6_7 != null ||
                                                                         selected6_8 != null ||
                                                                         selected6_9 != null ||
                                                                         selected6_10 != null ||
                                                                         selected6_11 != null ||
                                                                         selected6_12 != null ||
                                                                         selected6_13 != null ||
                                                                         selected6_14 != null ||
                                                                         selected6_15 != null ||
                                                                         selected6_16 != null ||
                                                                         selected6_17 != null ||
                                                                         selected6_18 != null ||
                                                                         selected6_19 != null ||
                                                                         selected6_20 != null ||
                                                                         selected6_21 != null ||
                                                                         selected6_22 != null ||
                                                                         selected6_23 != null ||
                                                                         selected6_24 != null ||
                                                                         selected6_25 != null) ? 'success' : 'secondary'">6</b-button> 
                        <b-button @click="numberQuestion = 7" :variant="(selected7_1 != null ||
                                                                         selected7_2 != null ||
                                                                         selected7_3 != null ||
                                                                         selected7_4 != null ||
                                                                         selected7_5 != null ||
                                                                         selected7_6 != null ||
                                                                         selected7_7 != null ||
                                                                         selected7_8 != null ||
                                                                         selected7_9 != null ||
                                                                         selected7_10 != null ||
                                                                         selected7_11 != null ||
                                                                         selected7_12 != null ||
                                                                         selected7_13 != null ||
                                                                         selected7_14 != null ||
                                                                         selected7_15 != null) ? 'success' : 'secondary'">7</b-button>
                        <b-button @click="numberQuestion = 8" :variant="(selected8_1 != false ||
                                                                         selected8_2 != false ||
                                                                         selected8_3 != false ||
                                                                         selected8_4 != false ||
                                                                         selected8_5 != false ||
                                                                         selected8_6 != false ||
                                                                         selected8_7 != false ||
                                                                         selected8_8 != null) ? 'success' : 'secondary'">8</b-button>
                        <b-button @click="numberQuestion = 9" :variant="(selected9_1 != null ||
                                                                         selected9_2 != null ||
                                                                         selected9_3 != null ||
                                                                         selected9_4 != null) ? 'success' : 'secondary'">9</b-button> 
                        <b-button @click="numberQuestion = 10" :variant="(selected10_1 != null ||
                                                                         selected10_2 != null ||
                                                                         selected10_3 != null ||
                                                                         selected10_4 != null ||
                                                                         selected10_5 != null ||
                                                                         selected10_6 != null ||
                                                                         selected10_7 != null ||
                                                                         selected10_8 != null ||
                                                                         selected10_9 != null ||
                                                                         selected10_10 != null) ? 'success' : 'secondary'">10</b-button>
                        <b-button @click="numberQuestion = 11" :variant="(selected11_1 != null ||
                                                                         selected11_2 != null ||
                                                                         selected11_3 != null ||
                                                                         selected11_4 != null ||
                                                                         selected11_5 != null ||
                                                                         selected11_6 != null) ? 'success' : 'secondary'">11</b-button>
                        <b-button @click="numberQuestion = 12" :variant="(selected12_1 != null ||
                                                                         selected12_2 != null ||
                                                                         selected12_3 != null ||
                                                                         selected12_4 != null ||
                                                                         selected12_5 != null) ? 'success' : 'secondary'">12</b-button>
                        <b-button class="ml-2" @click="numberQuestion = 13" variant="primary">Questionários</b-button>
                    </b-button-group>
                </b-form-group>

                <b-form-group v-show="numberQuestion == 1" class="mt-3" label="INFORMAÇÕES GERAIS:">
                    <b-row class="mt-4"> 
                        <b-col md="10" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="Nome da Criança:">
                                <b-form-input type="text" v-model="selected1_1" />                    
                            </b-form-group>                
                        </b-col>
                        <b-col md="2" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="Data da Avaliação:">
                                <b-form-input type="date" v-model="selected1_14" />                    
                            </b-form-group>                
                        </b-col>
                    </b-row>

                    <b-row> 
                        <b-col md="5" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="Nome da Mãe:">
                                <b-form-input type="text" v-model="selected1_2" />                    
                            </b-form-group>                
                        </b-col>                                     
                        <b-col md="5" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="Nome da Pai:">
                                <b-form-input type="text" v-model="selected1_3" />                    
                            </b-form-group>                
                        </b-col>                       
                        <b-col md="2" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="Contatos:">
                                <b-form-input type="text" v-model="selected1_4" />                    
                            </b-form-group>                
                        </b-col>                                      
                    </b-row>      

                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="Diagnóstico Clínico:">
                                <b-form-textarea id="textarea" v-model="selected1_5" placeholder="" rows="1" max-rows="6"></b-form-textarea>                   
                            </b-form-group>                
                        </b-col>                                                                       
                    </b-row>  

                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="Pediatra:">
                                <b-form-textarea id="textarea" v-model="selected1_6" placeholder="" rows="1" max-rows="6"></b-form-textarea>                   
                            </b-form-group>                
                        </b-col>                                                                       
                    </b-row>                           

                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="Neurologista:">
                                <b-form-textarea id="textarea" v-model="selected1_7" placeholder="" rows="1" max-rows="6"></b-form-textarea>                   
                            </b-form-group>                
                        </b-col>                                                                       
                    </b-row>    

                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="Ortopedista:">
                                <b-form-textarea id="textarea" v-model="selected1_8" placeholder="" rows="1" max-rows="6"></b-form-textarea>                   
                            </b-form-group>                
                        </b-col>                                                                       
                    </b-row>    

                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="Outros:">
                                <b-form-textarea id="textarea" v-model="selected1_9" placeholder="" rows="1" max-rows="6"></b-form-textarea>                   
                            </b-form-group>                
                        </b-col>                                                                       
                    </b-row>   

                    <b-form-group v-show="numberQuestion == 1" label="Terapias que Realiza:">
                        <b-form-checkbox id="checkbox-1_10" v-model="selected1_10" name="checkbox-1_10" :value="true" :unchecked-value="false">Fisioterapia</b-form-checkbox>
                        <b-form-checkbox id="checkbox-1_11" v-model="selected1_11" name="checkbox-1_11" :value="true" :unchecked-value="false">Terapia Ocupacional</b-form-checkbox>
                        <b-form-checkbox id="checkbox-1_12" v-model="selected1_12" name="checkbox-1_12" :value="true" :unchecked-value="false">Fonoterapia</b-form-checkbox>
                        <b-form-textarea id="textarea" placeholder="Outras" v-model="selected1_13" rows="1" max-rows="6"></b-form-textarea>
                    </b-form-group>                                                                                  
                </b-form-group>

                <b-button v-show="numberQuestion == 1" variant="success" @click="numberQuestion = 2">Ok</b-button>


                <b-form-group v-show="numberQuestion == 2" class="mt-3" label="HISTÓRIA CLÍNICA:">
                    <b-row class="mt-4"> 
                        <b-col md="3" sm="12">   
                            <b-form-group v-show="numberQuestion == 2" label="Data de Nascimento:">
                                <b-form-input type="date" v-model="selected2_1" />                    
                            </b-form-group>                
                        </b-col>
                        <b-col md="3" sm="12">   
                            <b-form-group v-show="numberQuestion == 2" label="Idade Cronológica:">
                                <b-form-input type="text" v-model="selected2_2" />                    
                            </b-form-group>                
                        </b-col>       
                        <b-col md="3" sm="12">   
                            <b-form-group v-show="numberQuestion == 2" label="Idade Gestacional (Semanas):">
                                <b-form-input type="text" v-model="selected2_3" />                    
                            </b-form-group>                
                        </b-col>  
                        <b-col md="3" sm="12">   
                            <b-form-group v-show="numberQuestion == 2" label="Idade Corrigida:">
                                <b-form-input type="text" v-model="selected2_4" />                    
                            </b-form-group>                
                        </b-col>                                                                                                   
                    </b-row>

                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 2" label="Complicações na Gestação:">
                                <b-form-textarea id="textarea" v-model="selected2_5" placeholder="" rows="1" max-rows="6"></b-form-textarea>                   
                            </b-form-group>                
                        </b-col>                                                                       
                    </b-row>  

                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 2" label="Complicações no Parto:">
                                <b-form-textarea id="textarea" v-model="selected2_6" placeholder="" rows="1" max-rows="6"></b-form-textarea>                   
                            </b-form-group>                
                        </b-col>                                                                       
                    </b-row>                           

                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 2" label="HPP:">
                                <b-form-textarea id="textarea" v-model="selected2_7" placeholder="" rows="1" max-rows="6"></b-form-textarea>                   
                            </b-form-group>                
                        </b-col>                                                                       
                    </b-row>    

                    <b-form-group v-show="numberQuestion == 2" label="Exames Complementares Anexados:">
                        <b-form-checkbox id="checkbox-2_8" v-model="selected2_8" name="checkbox-2_8" :value="true" :unchecked-value="false">Raio-X</b-form-checkbox>
                        <b-form-checkbox id="checkbox-2_9" v-model="selected2_9" name="checkbox-2_9" :value="true" :unchecked-value="false">TC</b-form-checkbox>
                        <b-form-checkbox id="checkbox-2_10" v-model="selected2_10" name="checkbox-2_10" :value="true" :unchecked-value="false">RM</b-form-checkbox>
                        <b-form-textarea id="textarea" placeholder="Outros" v-model="selected2_11" rows="1" max-rows="6"></b-form-textarea>
                    </b-form-group>  

                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 2" label="Medicações:">
                                <b-form-textarea id="textarea" v-model="selected2_12" placeholder="" rows="1" max-rows="6"></b-form-textarea>                   
                            </b-form-group>                
                        </b-col>                                                                       
                    </b-row>    

                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 2" label="Cirurgias e Procedimentos:">
                                <b-form-textarea id="textarea" v-model="selected2_13" placeholder="" rows="1" max-rows="6"></b-form-textarea>                   
                            </b-form-group>                
                        </b-col>                                                                       
                    </b-row>   

                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 2" label="Aplicação da toxina botulínica:">
                                <b-form-textarea id="textarea" v-model="selected2_14" placeholder="Onde?" rows="1" max-rows="6"></b-form-textarea>
                                <b-form-textarea class="mt-1" id="textarea" v-model="selected2_15" placeholder="Quando?" rows="1" max-rows="6"></b-form-textarea>                   
                            </b-form-group>                
                        </b-col>                                                                       
                    </b-row>

                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 2" label="Atividades Preferidas da Criança:">
                                <b-form-textarea id="textarea" v-model="selected2_16" placeholder="" rows="1" max-rows="6"></b-form-textarea>                   
                            </b-form-group>                
                        </b-col>                                                                       
                    </b-row>         

                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 2" label="Dificuldades Encontradas Para Execução das Atividades:">
                                <b-form-textarea id="textarea" v-model="selected2_17" placeholder="" rows="1" max-rows="6"></b-form-textarea>                   
                            </b-form-group>                
                        </b-col>                                                                       
                    </b-row>  

                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 2" label="O Que Você Gostarias/Criança Gostaria de Melhorar na Terapia:">
                                <b-form-textarea id="textarea" v-model="selected2_18" placeholder="" rows="1" max-rows="6"></b-form-textarea>                   
                            </b-form-group>                
                        </b-col>                                                                       
                    </b-row>                                                                                                                                                                     
                </b-form-group>

                <b-button v-show="numberQuestion == 2" variant="success" @click="numberQuestion = 3">Ok</b-button>  


                <b-form-group v-show="numberQuestion == 3" class="mt-3" label="CLASSIFICAÇÕES:">
                    <b-row class="mt-4"> 
                        <b-col md="4" sm="12">   
                            <b-form-group v-show="numberQuestion == 3" label="GMFCS:">
                                <b-form-input type="text" v-model="selected3_1" />                    
                            </b-form-group>                
                        </b-col>
                        <b-col md="4" sm="12">   
                            <b-form-group v-show="numberQuestion == 3" label="Nível da Lesão Medular:">
                                <b-form-input type="text" v-model="selected3_2" />                    
                            </b-form-group>                
                        </b-col>     
                        <b-col md="4" sm="12">   
                            <b-form-group v-show="numberQuestion == 3" label="Outras:">
                                <b-form-input type="text" v-model="selected3_3" />                    
                            </b-form-group>                
                        </b-col>                                             
                    </b-row>

                    <b-form-group v-show="numberQuestion == 3" label="Topografia do Comprometimento Motor:">
                        <b-form-checkbox id="checkbox-3_4" v-model="selected3_4" name="checkbox-3_4" :value="true" :unchecked-value="false">Hemiparesia</b-form-checkbox>
                        <b-form-checkbox id="checkbox-3_5" v-model="selected3_5" name="checkbox-3_5" :value="true" :unchecked-value="false">Diparesia</b-form-checkbox>
                        <b-form-checkbox id="checkbox-3_6" v-model="selected3_6" name="checkbox-3_6" :value="true" :unchecked-value="false">Tetraparesia</b-form-checkbox>
                        <b-form-checkbox id="checkbox-3_7" v-model="selected3_7" name="checkbox-3_7" :value="true" :unchecked-value="false">Monoparesia</b-form-checkbox>
                    </b-form-group>  

                    <h6 class="mt-3"><b>Estruturas e Funções Corporais</b></h6>

                    <b-form-group v-show="numberQuestion == 3" label="Extensão da Deficiência (b e s):">                        
                        <b-form-radio v-model="selected3_8" name="some-radios-3_8" value="0">Nenhuma Deficiencia (0-4%)</b-form-radio>
                        <b-form-radio v-model="selected3_8" name="some-radios-3_8" value="1">Deficiencia Leve (5-24%)</b-form-radio>
                        <b-form-radio v-model="selected3_8" name="some-radios-3_8" value="2">Deficiência Moderada (25-49%)</b-form-radio>
                        <b-form-radio v-model="selected3_8" name="some-radios-3_8" value="3">Deficiencia Grave (50-95%)</b-form-radio>
                        <b-form-radio v-model="selected3_8" name="some-radios-3_8" value="4">Deficiência Completa (96-100%)</b-form-radio>
                        <b-form-radio v-model="selected3_8" name="some-radios-3_8" value="8">Não Especificada</b-form-radio>
                        <b-form-radio v-model="selected3_8" name="some-radios-3_8" value="9">Não Aplicável</b-form-radio>
                    </b-form-group>

                    <b-form-group v-show="numberQuestion == 3" label="Mudança na estrutura(s):">
                        <b-form-radio v-model="selected3_9" name="some-radios-3_9" value="0">Nenhuma mudança na estrutura</b-form-radio>
                        <b-form-radio v-model="selected3_9" name="some-radios-3_9" value="1">Ausência total</b-form-radio>
                        <b-form-radio v-model="selected3_9" name="some-radios-3_9" value="2">Ausência parcial</b-form-radio>
                        <b-form-radio v-model="selected3_9" name="some-radios-3_9" value="3">Parte adicional</b-form-radio>
                        <b-form-radio v-model="selected3_9" name="some-radios-3_9" value="4">Dimensões aberrantes</b-form-radio>
                        <b-form-radio v-model="selected3_9" name="some-radios-3_9" value="5">Descontinuidade</b-form-radio>
                        <b-form-radio v-model="selected3_9" name="some-radios-3_9" value="6">Posição desviada</b-form-radio>
                        <b-form-radio v-model="selected3_9" name="some-radios-3_9" value="7">Mudanças qualitativas na estrutura</b-form-radio>
                        <b-form-radio v-model="selected3_9" name="some-radios-3_9" value="8">Não Especificada</b-form-radio>
                        <b-form-radio v-model="selected3_9" name="some-radios-3_9" value="9">Não Aplicável</b-form-radio>
                    </b-form-group>

                    <b-form-group v-show="numberQuestion == 3" label="Local da Deficiência(s):">
                        <b-form-radio v-model="selected3_10" name="some-radios-3_10" value="0">Mais de Uma Região</b-form-radio>
                        <b-form-radio v-model="selected3_10" name="some-radios-3_10" value="1">Direita</b-form-radio>
                        <b-form-radio v-model="selected3_10" name="some-radios-3_10" value="2">Esquerda</b-form-radio>
                        <b-form-radio v-model="selected3_10" name="some-radios-3_10" value="3">Ambos os Lados</b-form-radio>
                        <b-form-radio v-model="selected3_10" name="some-radios-3_10" value="4">Parte Anterior</b-form-radio>
                        <b-form-radio v-model="selected3_10" name="some-radios-3_10" value="5">Parte Posterior</b-form-radio>
                        <b-form-radio v-model="selected3_10" name="some-radios-3_10" value="6">Proximal</b-form-radio>
                        <b-form-radio v-model="selected3_10" name="some-radios-3_10" value="7">Distal</b-form-radio>
                        <b-form-radio v-model="selected3_10" name="some-radios-3_10" value="8">Não Especificada</b-form-radio>
                        <b-form-radio v-model="selected3_10" name="some-radios-3_10" value="9">Não Aplicável</b-form-radio>
                    </b-form-group>                            
                </b-form-group>

                <b-button v-show="numberQuestion == 3" variant="success" @click="numberQuestion = 4">Ok</b-button>
                

                <b-form-group v-show="numberQuestion == 4" class="mt-3" label="SISTEMAS CORPORAIS:">
                    <b-row class="mt-4"> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 4" label="Inspeção Geral:">
                                <b-form-textarea id="textarea" v-model="selected4" placeholder="" rows="1" max-rows="6"></b-form-textarea>                   
                            </b-form-group>                
                        </b-col>                                                                                                               
                    </b-row>
                </b-form-group>

                <b-button v-show="numberQuestion == 4" variant="success" @click="numberQuestion = 5">Ok</b-button>


                <b-form-group v-show="numberQuestion == 5" class="mt-3" label="Sistemas Sensorial:">
                    <b-row class="mt-4"> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 5" label="Inspeção Geral:">
                                <b-form-checkbox id="checkbox-5_1" v-model="selected5_1" name="checkbox-5_1" :value="true" :unchecked-value="false">Estrabismo</b-form-checkbox>
                                <b-form-checkbox id="checkbox-5_2" v-model="selected5_2" name="checkbox-5_2" :value="true" :unchecked-value="false">Nistagmo</b-form-checkbox>
                                <b-form-checkbox id="checkbox-5_3" v-model="selected5_3" name="checkbox-5_3" :value="true" :unchecked-value="false">Fotossensibilidade</b-form-checkbox>
                                <b-form-checkbox id="checkbox-5_4" v-model="selected5_4" name="checkbox-5_4" :value="true" :unchecked-value="false">Déficit Visual Cerebral</b-form-checkbox>               
                            </b-form-group>                
                        </b-col>                                                                                                               
                    </b-row>

                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 5" label="Audição:">
                                <b-form-checkbox id="checkbox-5_5" v-model="selected5_5" name="checkbox-5_5" :value="true" :unchecked-value="false">Hiperacusia</b-form-checkbox>
                                <b-form-checkbox id="checkbox-5_6" v-model="selected5_6" name="checkbox-5_6" :value="true" :unchecked-value="false">Hipoacusia</b-form-checkbox>
                                <b-form-checkbox id="checkbox-5_7" v-model="selected5_7" name="checkbox-5_7" :value="true" :unchecked-value="false">Surdez</b-form-checkbox>
                            </b-form-group>                
                        </b-col>                                                                                                               
                    </b-row>   

                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 5" label="Vestibulares:">
                                <b-form-textarea id="textarea" v-model="selected5_8" placeholder="Postura Mais Alta" rows="1" max-rows="6"></b-form-textarea> 
                            </b-form-group>                
                        </b-col>                                                                                                               
                    </b-row>                             

                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 5" label="Proprioceptiva:">
                                <b-form-textarea id="textarea" v-model="selected5_9" placeholder="Atividade avaliada" rows="1" max-rows="6"></b-form-textarea> 
                            </b-form-group>                
                        </b-col>                                                                                                               
                    </b-row>    

                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 5" label="Tátil:">
                                <b-form-checkbox id="checkbox-5_10" v-model="selected5_10" name="checkbox-5_10" :value="true" :unchecked-value="false">Normal</b-form-checkbox>
                                <b-form-checkbox id="checkbox-5_11" v-model="selected5_11" name="checkbox-5_11" :value="true" :unchecked-value="false">Hipo</b-form-checkbox>
                                <b-form-checkbox id="checkbox-5_12" v-model="selected5_12" name="checkbox-5_12" :value="true" :unchecked-value="false">Hiper</b-form-checkbox>
                                <b-form-textarea id="textarea" v-model="selected5_13" placeholder="Anestesia, Local" rows="1" max-rows="6"></b-form-textarea>
                            </b-form-group>                
                        </b-col>                                                                                                               
                    </b-row>        
                </b-form-group>      

                <b-button v-show="numberQuestion == 5" variant="success" @click="numberQuestion = 6">Ok</b-button>        


                <b-form-group v-show="numberQuestion == 6" class="mt-3" label="Funções Neuromusculoesqueléticas:">
                    <b-form-group v-show="numberQuestion == 6" label="Articulações/Ossos" class="mt-3">
                        <b-row> 
                            <b-col md="5" sm="12">   
                                <b-form-group v-show="numberQuestion == 6" label="ADM/Articulação:" class="mt-3">
                                    <b-form-input type="text" v-model="selected6_1" />
                                </b-form-group>                                   
                            </b-col>      
                            <b-col md="1" sm="12" class="mt-3">   
                                <b-form-group v-show="numberQuestion == 6" label="Passiva - D:">
                                    <b-form-input type="text" v-model="selected6_2" />
                                </b-form-group>                                   
                            </b-col>    
                            <b-col md="1" sm="12" class="mt-3">   
                                <b-form-group v-show="numberQuestion == 6" label="Passiva - E:">
                                    <b-form-input type="text" v-model="selected6_3" />
                                </b-form-group>                                   
                            </b-col>      
                            <b-col md="1" sm="12" class="mt-3">   
                                <b-form-group v-show="numberQuestion == 6" label="Ativa - D:">
                                    <b-form-input type="text" v-model="selected6_4" />
                                </b-form-group>                                   
                            </b-col>    
                            <b-col md="1" sm="12" class="mt-3">   
                                <b-form-group v-show="numberQuestion == 6" label="Ativa - E:">
                                    <b-form-input type="text" v-model="selected6_5" />
                                </b-form-group>                                   
                            </b-col>                                                                                                                                                                                           
                        </b-row>        

                        <b-row> 
                            <b-col md="5" sm="12">   
                                <b-form-input type="text" v-model="selected6_6" />
                            </b-col>      
                            <b-col md="1" sm="12">   
                                <b-form-input type="text" v-model="selected6_7" />
                            </b-col>    
                            <b-col md="1" sm="12">   
                                <b-form-input type="text" v-model="selected6_8" />
                            </b-col>      
                            <b-col md="1" sm="12">   
                                <b-form-input type="text" v-model="selected6_9" />
                            </b-col>    
                            <b-col md="1" sm="12">   
                                <b-form-input type="text" v-model="selected6_10" />
                            </b-col>                                                                                                                                                                                           
                        </b-row>    

                        <b-row class="mt-3"> 
                            <b-col md="5" sm="12">   
                                <b-form-input type="text" v-model="selected6_11" />
                            </b-col>      
                            <b-col md="1" sm="12">   
                                <b-form-input type="text" v-model="selected6_12" />
                            </b-col>    
                            <b-col md="1" sm="12">   
                                <b-form-input type="text" v-model="selected6_13" />
                            </b-col>      
                            <b-col md="1" sm="12">   
                                <b-form-input type="text" v-model="selected6_14" />
                            </b-col>    
                            <b-col md="1" sm="12">   
                                <b-form-input type="text" v-model="selected6_15" />
                            </b-col>                                                                                                                                                                                           
                        </b-row> 

                        <b-row class="mt-3"> 
                            <b-col md="5" sm="12">   
                                <b-form-input type="text" v-model="selected6_16" />
                            </b-col>      
                            <b-col md="1" sm="12">   
                                <b-form-input type="text" v-model="selected6_17" />
                            </b-col>    
                            <b-col md="1" sm="12">   
                                <b-form-input type="text" v-model="selected6_18" />
                            </b-col>      
                            <b-col md="1" sm="12">   
                                <b-form-input type="text" v-model="selected6_19" />
                            </b-col>    
                            <b-col md="1" sm="12">   
                                <b-form-input type="text" v-model="selected6_20" />
                            </b-col>                                                                                                                                                                                           
                        </b-row> 

                        <b-row class="mt-3"> 
                            <b-col md="5" sm="12">   
                                <b-form-input type="text" v-model="selected6_21" />
                            </b-col>      
                            <b-col md="1" sm="12">   
                                <b-form-input type="text" v-model="selected6_22" />
                            </b-col>    
                            <b-col md="1" sm="12">   
                                <b-form-input type="text" v-model="selected6_23" />
                            </b-col>      
                            <b-col md="1" sm="12">   
                                <b-form-input type="text" v-model="selected6_24" />
                            </b-col>    
                            <b-col md="1" sm="12">   
                                <b-form-input type="text" v-model="selected6_25" />
                            </b-col>                                                                                                                                                                                           
                        </b-row>                                                                                               
                    </b-form-group>                
                </b-form-group>

                <b-button v-show="numberQuestion == 6" variant="success" @click="numberQuestion = 7">Ok</b-button>   


                <b-form-group v-show="numberQuestion == 7" class="mt-3" label="Funções Neuromusculoesqueléticas:">
                    <b-form-group v-show="numberQuestion == 7" label="Força(b730): Grau de Força de Acordo com a MRC." class="mt-3">
                        <b-row> 
                            <b-col md="5" sm="12">   
                                <b-form-group v-show="numberQuestion == 7" label="Grupo Muscular:" class="mt-3">
                                    <b-form-input type="text" v-model="selected7_1" />
                                </b-form-group>                                   
                            </b-col>      
                            <b-col md="1" sm="12" class="mt-3">   
                                <b-form-group v-show="numberQuestion == 7" label="D:">
                                    <b-form-input type="text" v-model="selected7_2" />
                                </b-form-group>                                   
                            </b-col>    
                            <b-col md="1" sm="12" class="mt-3">   
                                <b-form-group v-show="numberQuestion == 7" label="E:">
                                    <b-form-input type="text" v-model="selected7_3" />
                                </b-form-group>                                   
                            </b-col>      
                        </b-row>        

                        <b-row> 
                            <b-col md="5" sm="12">   
                                <b-form-input type="text" v-model="selected7_4" />
                            </b-col>      
                            <b-col md="1" sm="12">   
                                <b-form-input type="text" v-model="selected7_5" />
                            </b-col>    
                            <b-col md="1" sm="12">   
                                <b-form-input type="text" v-model="selected7_6" />
                            </b-col>      
                        </b-row>    

                        <b-row class="mt-3"> 
                            <b-col md="5" sm="12">   
                                <b-form-input type="text" v-model="selected7_7" />
                            </b-col>      
                            <b-col md="1" sm="12">   
                                <b-form-input type="text" v-model="selected7_8" />
                            </b-col>    
                            <b-col md="1" sm="12">   
                                <b-form-input type="text" v-model="selected7_9" />
                            </b-col>      
                        </b-row> 

                        <b-row class="mt-3"> 
                            <b-col md="5" sm="12">   
                                <b-form-input type="text" v-model="selected7_10" />
                            </b-col>      
                            <b-col md="1" sm="12">   
                                <b-form-input type="text" v-model="selected7_11" />
                            </b-col>    
                            <b-col md="1" sm="12">   
                                <b-form-input type="text" v-model="selected7_12" />
                            </b-col>                                
                        </b-row> 

                        <b-row class="mt-3"> 
                            <b-col md="5" sm="12">   
                                <b-form-input type="text" v-model="selected7_13" />
                            </b-col>      
                            <b-col md="1" sm="12">   
                                <b-form-input type="text" v-model="selected7_14" />
                            </b-col>    
                            <b-col md="1" sm="12">   
                                <b-form-input type="text" v-model="selected7_15" />
                            </b-col>                                
                        </b-row>                                                                                               
                    </b-form-group>                

                    <b-button v-show="numberQuestion == 7" variant="success" @click="numberQuestion = 8">Ok</b-button>                                                                                                        
                </b-form-group>


                <b-form-group v-show="numberQuestion == 8" class="mt-3" label="Funções Neuromusculoesqueléticas">
                    <b-form-group v-show="numberQuestion == 8" label="Movimentos Involuntários (b7650):" class="mt-3">
                        <b-form-checkbox id="checkbox-8_1" v-model="selected8_1" name="checkbox-8_1" :value="true" :unchecked-value="false">Clônus</b-form-checkbox>
                        <b-form-checkbox id="checkbox-8_2" v-model="selected8_2" name="checkbox-8_2" :value="true" :unchecked-value="false">Espamos</b-form-checkbox>
                        <b-form-checkbox id="checkbox-8_3" v-model="selected8_3" name="checkbox-8_3" :value="true" :unchecked-value="false">Tremores</b-form-checkbox>
                    </b-form-group>

                    <b-form-group v-show="numberQuestion == 8" label="Alterações de Tônus:" class="mt-3">
                        <b-form-checkbox id="checkbox-8_4" v-model="selected8_4" name="checkbox-8_4" :value="true" :unchecked-value="false">Hipotonia</b-form-checkbox>
                        <b-form-checkbox id="checkbox-8_5" v-model="selected8_5" name="checkbox-8_5" :value="true" :unchecked-value="false">Hipertonia</b-form-checkbox>
                        <b-form-checkbox id="checkbox-8_6" v-model="selected8_6" name="checkbox-8_6" :value="true" :unchecked-value="false">Repouso</b-form-checkbox>
                        <b-form-checkbox id="checkbox-8_7" v-model="selected8_7" name="checkbox-8_7" :value="true" :unchecked-value="false">Movimento</b-form-checkbox>
                    </b-form-group>     

                    <b-form-group v-show="numberQuestion == 8" label="Local/Grau de Espasticidade:" class="mt-3">
                        <b-form-input type="text" v-model="selected8_8" />
                    </b-form-group>                                    

                    <b-button v-show="numberQuestion == 8" variant="success" @click="numberQuestion = 9">Ok</b-button>
                </b-form-group>    


                <b-form-group v-show="numberQuestion == 9" class="mt-3" label="Atividade e Participação:">
                    <b-col md="12" sm="12">   
                        <b-form-group v-show="numberQuestion == 9" label="Atividades que Realiza (Capacidades Funcionais):" class="mt-3">
                            <b-form-input type="text" v-model="selected9_1" />
                        </b-form-group>                                   
                    </b-col>      

                    <b-col md="12" sm="12" class="mt-3">   
                        <b-form-group v-show="numberQuestion == 9" label="Qualificadores (Desempenho.Capacidade)">
                            <b-form-radio v-model="selected9_2" name="some-radios-9_2" value="0">Nenhuma Dificuldade (0-4%)</b-form-radio>
                            <b-form-radio v-model="selected9_2" name="some-radios-9_2" value="1">Dificuldade Leve/Ligeira (5-24%)</b-form-radio>
                            <b-form-radio v-model="selected9_2" name="some-radios-9_2" value="2">Dificuldade Moderada (25-49%)</b-form-radio>
                            <b-form-radio v-model="selected9_2" name="some-radios-9_2" value="3">Dificuldade Grave/Extrema (50-95%)</b-form-radio>
                            <b-form-radio v-model="selected9_2" name="some-radios-9_2" value="4">Dificuldade Completa/Total (96-100%)</b-form-radio>
                            <b-form-radio v-model="selected9_2" name="some-radios-9_2" value="8">Não Específica</b-form-radio>
                            <b-form-radio v-model="selected9_2" name="some-radios-9_2" value="9">Não Aplicável</b-form-radio>
                        </b-form-group>                                   
                    </b-col>        

                    <b-col md="12" sm="12" class="mt-3">   
                        <b-form-group v-show="numberQuestion == 9" label="Análise de Marcha(b770):">
                            <b-form-radio v-model="selected9_3" name="some-radios-9_3" value="0">Atáxica</b-form-radio>
                            <b-form-radio v-model="selected9_3" name="some-radios-9_3" value="1">Anserina</b-form-radio>
                            <b-form-radio v-model="selected9_3" name="some-radios-9_3" value="2">Ceifante</b-form-radio>
                            <b-form-radio v-model="selected9_3" name="some-radios-9_3" value="3">Parética</b-form-radio>
                            <b-form-radio v-model="selected9_3" name="some-radios-9_3" value="4">Em Pontas</b-form-radio>
                            <b-form-radio v-model="selected9_3" name="some-radios-9_3" value="5">Em Crouch</b-form-radio>
                        </b-form-group>                                   
                    </b-col>   

                    <b-col md="12" sm="12">   
                        <b-form-group v-show="numberQuestion == 9" label="Teste de Marcha:" class="mt-3">
                            <b-form-input type="text" v-model="selected9_4" />
                        </b-form-group>                                   
                    </b-col>                                                         

                    <b-button v-show="numberQuestion == 9" variant="success" @click="numberQuestion = 10">Ok</b-button>
                </b-form-group>         


                <b-form-group v-show="numberQuestion == 10" class="mt-3" label="Avaliações Específicas:">
                    <b-col md="12" sm="12">   
                        <b-form-group v-show="numberQuestion == 10" label="AIMS:" class="mt-3">
                            <b-form-input type="text" v-model="selected10_1" />
                        </b-form-group>                                   
                    </b-col>      

                    <b-col md="12" sm="12">   
                        <b-form-group v-show="numberQuestion == 10" label="TIMP:" class="mt-3">
                            <b-form-input type="text" v-model="selected10_2" />
                        </b-form-group>                                   
                    </b-col>                                       

                    <b-col md="12" sm="12">   
                        <b-form-group v-show="numberQuestion == 10" label="GMFM:" class="mt-3">
                            <b-form-input type="text" v-model="selected10_3" />
                        </b-form-group>                                   
                    </b-col>  

                    <b-col md="12" sm="12">   
                        <b-form-group v-show="numberQuestion == 10" label="PEDI:" class="mt-3">
                            <b-form-input type="text" v-model="selected10_4" />
                        </b-form-group>                                   
                    </b-col>  

                    <b-col md="12" sm="12">   
                        <b-form-group v-show="numberQuestion == 10" label="MIF:" class="mt-3">
                            <b-form-input type="text" v-model="selected10_5" />
                        </b-form-group>                                   
                    </b-col>  

                    <b-col md="12" sm="12">   
                        <b-form-group v-show="numberQuestion == 10" label="Outras:" class="mt-3">
                            <b-form-input type="text" v-model="selected10_6" />
                        </b-form-group>                                   
                    </b-col>  

                    <b-col md="12" sm="12">   
                        <b-form-group v-show="numberQuestion == 10" label="Aspectos Positivos:" class="mt-3">
                            <b-form-input type="text" v-model="selected10_7" />
                        </b-form-group>                                   
                    </b-col>  

                    <b-col md="12" sm="12">   
                        <b-form-group v-show="numberQuestion == 10" label="Diagnóstico Terapêutico:" class="mt-3">
                            <b-form-textarea id="textarea" v-model="selected10_8" rows="1" max-rows="6"></b-form-textarea>
                        </b-form-group>                                   
                    </b-col>                                                                                                                                         

                    <b-button v-show="numberQuestion == 10" variant="success" @click="numberQuestion = 11">Ok</b-button>
                </b-form-group>       


                <b-form-group v-show="numberQuestion == 11" class="mt-3" label="Objetivos:">
                    <b-form-group v-show="numberQuestion == 11" label="Objetivos Funcionais:" class="mt-3">
                        <b-row> 
                            <b-col md="4" sm="12">   
                                <b-form-group v-show="numberQuestion == 11" label="Curto Prazo (3 Meses):">
                                    <b-form-input type="text" v-model="selected11_1" />
                                </b-form-group>                                   
                            </b-col>      
                            <b-col md="4" sm="12">   
                                <b-form-group v-show="numberQuestion == 11" label="Médio Prazo (6 Meses):">
                                    <b-form-input type="text" v-model="selected11_2" />
                                </b-form-group>                                   
                            </b-col>    
                            <b-col md="4" sm="12">   
                                <b-form-group v-show="numberQuestion == 11" label="Longo Prazo (12 Meses):">
                                    <b-form-input type="text" v-model="selected11_3" />
                                </b-form-group>                                   
                            </b-col>      
                        </b-row> 
                    </b-form-group>         

                    <b-form-group v-show="numberQuestion == 11" label="Objetivos Estruturais:" class="mt-3">
                        <b-row> 
                            <b-col md="4" sm="12">   
                                <b-form-group v-show="numberQuestion == 11" label="Curto Prazo (3 Meses):">
                                    <b-form-input type="text" v-model="selected11_4" />
                                </b-form-group>                                   
                            </b-col>      
                            <b-col md="4" sm="12">   
                                <b-form-group v-show="numberQuestion == 11" label="Médio Prazo (6 Meses):">
                                    <b-form-input type="text" v-model="selected11_5" />
                                </b-form-group>                                   
                            </b-col>    
                            <b-col md="4" sm="12">   
                                <b-form-group v-show="numberQuestion == 11" label="Longo Prazo (12 Meses):">
                                    <b-form-input type="text" v-model="selected11_6" />
                                </b-form-group>                                   
                            </b-col>      
                        </b-row> 
                    </b-form-group>                                                                                                                                                  

                    <b-button v-show="numberQuestion == 11" variant="success" @click="numberQuestion = 12">Ok</b-button>
                </b-form-group>      
                
                <b-form-group v-show="numberQuestion == 12" class="mt-3">
                    <b-form-group v-show="numberQuestion == 12" label="Plano de Tratamento (Frequência, Dispositivos Auxiliares, Protocolos Domiciliares):" class="mt-3">
                        <b-form-textarea id="textarea" v-model="selected12_1" rows="1" max-rows="6"></b-form-textarea>
                    </b-form-group>                                                                                                                                                  
                    <b-form-group v-show="numberQuestion == 12" label="Abordagem Técnica:" class="mt-3">
                        <b-form-textarea id="textarea" v-model="selected12_2" rows="1" max-rows="6"></b-form-textarea>
                    </b-form-group>      
                    <b-form-group v-show="numberQuestion == 12" label="Terapias Associadas:" class="mt-3">
                        <b-form-textarea id="textarea" v-model="selected12_3" rows="1" max-rows="6"></b-form-textarea>
                    </b-form-group>                                                                                                                                                                 
                    <b-form-group v-show="numberQuestion == 12" label="Obs:" class="mt-3">
                        <b-form-textarea id="textarea" v-model="selected12_4" rows="1" max-rows="6"></b-form-textarea>
                    </b-form-group>                                                                                                                                                                 
                    <b-form-group v-show="numberQuestion == 12" label="Tempo Previsto Para Reavaliação:" class="mt-3">
                        <b-form-textarea id="textarea" v-model="selected12_5" rows="1" max-rows="6"></b-form-textarea>
                    </b-form-group>                                                                                                                                                                 

                </b-form-group>                                                          
            </b-card>
        </div>
    </div>
</template>

<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'
import Moment from 'moment'

export default {
    name: "AssessmentPediatric",
    components: { PageTitle },
    data() {
      return {
        theme: null,
        patient: null,
        assessment: null,
        numberQuestion: 1,
        selected1_1: null,
        selected1_2: null,
        selected1_3: null,
        selected1_4: null,
        selected1_5: null,
        selected1_6: null,
        selected1_7: null,      
        selected1_8: null, 
        selected1_9: null, 
        selected1_10: false, 
        selected1_11: false, 
        selected1_12: false, 
        selected1_13: null,
        selected1_14: null,
        selected2_1: null,
        selected2_2: null,
        selected2_3: null,
        selected2_4: null,
        selected2_5: null,
        selected2_6: null,
        selected2_7: null,
        selected2_8: false,
        selected2_9: false,
        selected2_10: false,
        selected2_11: null,
        selected2_12: null,
        selected2_13: null,
        selected2_14: null,
        selected2_15: null,
        selected2_16: null,
        selected2_17: null,
        selected2_18: null,
        selected3_1: null,
        selected3_2: null,
        selected3_3: null,
        selected3_4: false,
        selected3_5: false,
        selected3_6: false,
        selected3_7: null,
        selected3_8: null,
        selected3_9: null,
        selected3_10: null,        
        selected4: null,
        selected5_1: false,
        selected5_2: false,
        selected5_3: false,
        selected5_4: false,
        selected5_5: false,
        selected5_6: false,
        selected5_7: false,
        selected5_8: null,
        selected5_9: null,
        selected5_10: false,
        selected5_11: false,
        selected5_12: false,   
        selected5_13: null,    
        selected6_1: null,
        selected6_2: null,
        selected6_3: null,
        selected6_4: null,
        selected6_5: null,
        selected6_6: null,
        selected6_7: null,
        selected6_8: null,
        selected6_9: null,
        selected6_10: null,
        selected6_11: null,
        selected6_12: null,   
        selected6_13: null, 
        selected6_14: null,
        selected6_15: null,
        selected6_16: null,
        selected6_17: null,
        selected6_18: null,
        selected6_19: null,
        selected6_20: null,
        selected6_21: null,
        selected6_22: null,
        selected6_23: null,
        selected6_24: null,
        selected6_25: null,
        selected7_1: null,
        selected7_2: null,
        selected7_3: null,
        selected7_4: null,
        selected7_5: null,
        selected7_6: null,
        selected7_7: null,
        selected7_8: null,
        selected7_9: null,
        selected7_10: null,
        selected7_11: null,
        selected7_12: null,   
        selected7_13: null, 
        selected7_14: null,
        selected7_15: null,  
        selected8_1: false,
        selected8_2: false,
        selected8_3: false,
        selected8_4: false,
        selected8_5: false,
        selected8_6: false,
        selected8_7: false,
        selected8_8: null,
        selected9_1: null,
        selected9_2: null,
        selected9_3: null,
        selected9_4: null,    
        selected10_1: null,
        selected10_2: null,
        selected10_3: null,
        selected10_4: null,
        selected10_5: null,
        selected10_6: null,
        selected10_7: null,
        selected10_8: null,
        selected10_9: null,
        selected10_10: null,    
        selected11_1: null,
        selected11_2: null,
        selected11_3: null,
        selected11_4: null,
        selected11_5: null,
        selected11_6: null,                
        selected12_1: null,
        selected12_2: null,
        selected12_3: null,
        selected12_4: null,
        selected12_5: null
      }
    },
    methods: {
        sendQuestions() {
                Moment.locale('pt-br')

                axios.get(`${baseApiUrl}/patient/getById/${this.patient._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`).then(res => {
                this.patient = res.data

                for (let q of this.patient.questions) {
                    if (q.type == 1 && q.name == this.assessment.name) {
                        q.answers = []

                        q.status = 1
                        q.answers.push(
                            {
                            'dateAnswers': Moment(new Date()).format('L')
                            }, 
                            {
                            '1_1': this.selected1_1,
                            '1_2': this.selected1_2,
                            '1_3': this.selected1_3,
                            '1_4': this.selected1_4,
                            '1_5': this.selected1_5,
                            '1_6': this.selected1_6,
                            '1_7': this.selected1_7,
                            '1_8': this.selected1_8,
                            '1_9': this.selected1_9,
                            '1_10': this.selected1_10,
                            '1_11': this.selected1_11,
                            '1_12': this.selected1_12,
                            '1_13': this.selected1_13,
                            '1_14': this.selected1_14
                            },
                            {
                            '2_1': this.selected2_1,
                            '2_2': this.selected2_2,
                            '2_3': this.selected2_3,
                            '2_4': this.selected2_4,
                            '2_5': this.selected2_5,
                            '2_6': this.selected2_6,
                            '2_7': this.selected2_7,
                            '2_8': this.selected2_8,
                            '2_9': this.selected2_9,
                            '2_10': this.selected2_10,
                            '2_11': this.selected2_11,
                            '2_12': this.selected2_12,
                            '2_13': this.selected2_13,
                            '2_14': this.selected2_14,
                            '2_15': this.selected2_15,
                            '2_16': this.selected2_16,
                            '2_17': this.selected2_17,
                            '2_18': this.selected2_18
                            },
                            {
                            '3_1': this.selected3_1,
                            '3_2': this.selected3_2,
                            '3_3': this.selected3_3,
                            '3_4': this.selected3_4,
                            '3_5': this.selected3_5,
                            '3_6': this.selected3_6,
                            '3_7': this.selected3_7,
                            '3_8': this.selected3_8,
                            '3_9': this.selected3_9,
                            '3_10': this.selected3_10
                            },
                            {
                            '4': this.selected4 
                            },
                            {
                            '5_1': this.selected5_1,
                            '5_2': this.selected5_2,
                            '5_3': this.selected5_3,
                            '5_4': this.selected5_4,
                            '5_5': this.selected5_5,
                            '5_6': this.selected5_6,
                            '5_7': this.selected5_7,
                            '5_8': this.selected5_8,
                            '5_9': this.selected5_9,
                            '5_10': this.selected5_10,
                            '5_11': this.selected5_11,
                            '5_12': this.selected5_12,
                            '5_13': this.selected5_13
                            },
                            {
                            '6_1': this.selected6_1,
                            '6_2': this.selected6_2,
                            '6_3': this.selected6_3,
                            '6_4': this.selected6_4,
                            '6_5': this.selected6_5,
                            '6_6': this.selected6_6,
                            '6_7': this.selected6_7,
                            '6_8': this.selected6_8,
                            '6_9': this.selected6_9,
                            '6_10': this.selected6_10,
                            '6_11': this.selected6_11,
                            '6_12': this.selected6_12,
                            '6_13': this.selected6_13,
                            '6_14': this.selected6_14,
                            '6_15': this.selected6_15,
                            '6_16': this.selected6_16,
                            '6_17': this.selected6_17,
                            '6_18': this.selected6_18,
                            '6_19': this.selected6_19,
                            '6_20': this.selected6_20,
                            '6_21': this.selected6_21,
                            '6_22': this.selected6_22,
                            '6_23': this.selected6_23,
                            '6_24': this.selected6_24,
                            '6_25': this.selected6_25
                            },                        
                            {
                            '7_1': this.selected7_1,
                            '7_2': this.selected7_2,
                            '7_3': this.selected7_3,
                            '7_4': this.selected7_4,
                            '7_5': this.selected7_5,
                            '7_6': this.selected7_6,
                            '7_7': this.selected7_7,
                            '7_8': this.selected7_8,
                            '7_9': this.selected7_9,
                            '7_10': this.selected7_10,
                            '7_11': this.selected7_11,
                            '7_12': this.selected7_12,
                            '7_13': this.selected7_13,
                            '7_14': this.selected7_14,
                            '7_15': this.selected7_15
                            },
                            {
                            '8_1': this.selected8_1,
                            '8_2': this.selected8_2,
                            '8_3': this.selected8_3,
                            '8_4': this.selected8_4,
                            '8_5': this.selected8_5,
                            '8_6': this.selected8_6,
                            '8_7': this.selected8_7,
                            '8_8': this.selected8_8
                            },
                            {
                            '9_1': this.selected9_1, 
                            '9_2': this.selected9_2, 
                            '9_3': this.selected9_3,
                            '9_4': this.selected9_4 
                            },                            
                            {
                            '10_1': this.selected10_1,
                            '10_2': this.selected10_2,
                            '10_3': this.selected10_3,
                            '10_4': this.selected10_4,
                            '10_5': this.selected10_5,
                            '10_6': this.selected10_6,
                            '10_7': this.selected10_7,
                            '10_8': this.selected10_8,
                            '10_9': this.selected10_9,
                            '10_10': this.selected10_10
                            },
                            {
                            '11_1': this.selected11_1, 
                            '11_2': this.selected11_2, 
                            '11_3': this.selected11_3,
                            '11_4': this.selected11_4, 
                            '11_5': this.selected11_5, 
                            '11_6': this.selected11_6                                                
                            },
                            {
                            '12_1': this.selected12_1, 
                            '12_2': this.selected12_2, 
                            '12_3': this.selected12_3,
                            '12_4': this.selected12_4, 
                            '12_5': this.selected12_5
                            }                                                                                                                                                                                                                                                                                                                                                                                                                                                         
                        ) 
                    }
                }            

                const method = 'put'            
                const id = `/${this.patient._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`

                axios[method](`${baseApiUrl}/patient${id}`, this.patient)
                    .then(() => {
                        this.$toasted.global.defaultSuccess()
                        this.$router.push({name: 'notation', params: { },})
                    })  
                    .catch(showError) 
                }).catch((e) => {
                  console.log(e)
                  showError
                })
            // }             
        }                
    },
    mounted() {
      this.theme = localStorage.getItem('theme')
  
      this.patient = this.$route.params.questionSelected.patient
      this.assessment = this.$route.params.questionSelected   
      
      if (this.$route.params.questionSelected.answers.length == 0) {
        this.selected1_1 = this.patient.name
        this.selected1_4 = this.patient.phone1
        this.selected2_1 = this.patient.birthday
      } else {
        this.selected1_1 = this.$route.params.questionSelected.answers[1]['1_1']
        this.selected1_2 = this.$route.params.questionSelected.answers[1]['1_2']
        this.selected1_3 = this.$route.params.questionSelected.answers[1]['1_3']
        this.selected1_4 = this.$route.params.questionSelected.answers[1]['1_4']
        this.selected1_5 = this.$route.params.questionSelected.answers[1]['1_5']
        this.selected1_6 = this.$route.params.questionSelected.answers[1]['1_6']
        this.selected1_7 = this.$route.params.questionSelected.answers[1]['1_7']  
        this.selected1_8 = this.$route.params.questionSelected.answers[1]['1_8']
        this.selected1_9 = this.$route.params.questionSelected.answers[1]['1_9']
        this.selected1_10 = this.$route.params.questionSelected.answers[1]['1_10']    
        this.selected1_11 = this.$route.params.questionSelected.answers[1]['1_11']
        this.selected1_12 = this.$route.params.questionSelected.answers[1]['1_12']
        this.selected1_13 = this.$route.params.questionSelected.answers[1]['1_13']
        this.selected1_14 = this.$route.params.questionSelected.answers[1]['1_14']

        this.selected2_1 = this.$route.params.questionSelected.answers[2]['2_1']
        this.selected2_2 = this.$route.params.questionSelected.answers[2]['2_2']
        this.selected2_3 = this.$route.params.questionSelected.answers[2]['2_3']
        this.selected2_4 = this.$route.params.questionSelected.answers[2]['2_4']
        this.selected2_5 = this.$route.params.questionSelected.answers[2]['2_5']
        this.selected2_6 = this.$route.params.questionSelected.answers[2]['2_6']
        this.selected2_7 = this.$route.params.questionSelected.answers[2]['2_7']  
        this.selected2_8 = this.$route.params.questionSelected.answers[2]['2_8']
        this.selected2_9 = this.$route.params.questionSelected.answers[2]['2_9']
        this.selected2_10 = this.$route.params.questionSelected.answers[2]['2_10']    
        this.selected2_11 = this.$route.params.questionSelected.answers[2]['2_11']
        this.selected2_12 = this.$route.params.questionSelected.answers[2]['2_12']
        this.selected2_13 = this.$route.params.questionSelected.answers[2]['2_13']
        this.selected2_14 = this.$route.params.questionSelected.answers[2]['2_14']
        this.selected2_15 = this.$route.params.questionSelected.answers[2]['2_15']
        this.selected2_16 = this.$route.params.questionSelected.answers[2]['2_16']
        this.selected2_17 = this.$route.params.questionSelected.answers[2]['2_17']
        this.selected2_18 = this.$route.params.questionSelected.answers[2]['2_18']
        
        this.selected3_1 = this.$route.params.questionSelected.answers[3]['3_1']
        this.selected3_2 = this.$route.params.questionSelected.answers[3]['3_2']
        this.selected3_3 = this.$route.params.questionSelected.answers[3]['3_3']
        this.selected3_4 = this.$route.params.questionSelected.answers[3]['3_4']
        this.selected3_5 = this.$route.params.questionSelected.answers[3]['3_5']
        this.selected3_6 = this.$route.params.questionSelected.answers[3]['3_6']
        this.selected3_7 = this.$route.params.questionSelected.answers[3]['3_7']  
        this.selected3_8 = this.$route.params.questionSelected.answers[3]['3_8']
        this.selected3_9 = this.$route.params.questionSelected.answers[3]['3_9']
        this.selected3_10 = this.$route.params.questionSelected.answers[3]['3_10']

        this.selected4 = this.$route.params.questionSelected.answers[4]['4']
        
        this.selected5_1 = this.$route.params.questionSelected.answers[5]['5_1']
        this.selected5_2 = this.$route.params.questionSelected.answers[5]['5_2']
        this.selected5_3 = this.$route.params.questionSelected.answers[5]['5_3']
        this.selected5_4 = this.$route.params.questionSelected.answers[5]['5_4']
        this.selected5_5 = this.$route.params.questionSelected.answers[5]['5_5']
        this.selected5_6 = this.$route.params.questionSelected.answers[5]['5_6']
        this.selected5_7 = this.$route.params.questionSelected.answers[5]['5_7']  
        this.selected5_8 = this.$route.params.questionSelected.answers[5]['5_8']
        this.selected5_9 = this.$route.params.questionSelected.answers[5]['5_9']
        this.selected5_10 = this.$route.params.questionSelected.answers[5]['5_10']    
        this.selected5_11 = this.$route.params.questionSelected.answers[5]['5_11']
        this.selected5_12 = this.$route.params.questionSelected.answers[5]['5_12']
        this.selected5_13 = this.$route.params.questionSelected.answers[5]['5_13']
        
        this.selected6_1 = this.$route.params.questionSelected.answers[6]['6_1']
        this.selected6_2 = this.$route.params.questionSelected.answers[6]['6_2']
        this.selected6_3 = this.$route.params.questionSelected.answers[6]['6_3']
        this.selected6_4 = this.$route.params.questionSelected.answers[6]['6_4']
        this.selected6_5 = this.$route.params.questionSelected.answers[6]['6_5']
        this.selected6_6 = this.$route.params.questionSelected.answers[6]['6_6']
        this.selected6_7 = this.$route.params.questionSelected.answers[6]['6_7']  
        this.selected6_8 = this.$route.params.questionSelected.answers[6]['6_8']
        this.selected6_9 = this.$route.params.questionSelected.answers[6]['6_9']
        this.selected6_10 = this.$route.params.questionSelected.answers[6]['6_10']    
        this.selected6_11 = this.$route.params.questionSelected.answers[6]['6_11']
        this.selected6_12 = this.$route.params.questionSelected.answers[6]['6_12']
        this.selected6_13 = this.$route.params.questionSelected.answers[6]['6_13']
        this.selected6_14 = this.$route.params.questionSelected.answers[6]['6_14']
        this.selected6_15 = this.$route.params.questionSelected.answers[6]['6_15']
        this.selected6_16 = this.$route.params.questionSelected.answers[6]['6_16']
        this.selected6_17 = this.$route.params.questionSelected.answers[6]['6_17']
        this.selected6_18 = this.$route.params.questionSelected.answers[6]['6_18']
        this.selected6_19 = this.$route.params.questionSelected.answers[6]['6_19']
        this.selected6_20 = this.$route.params.questionSelected.answers[6]['6_20']       
        this.selected6_21 = this.$route.params.questionSelected.answers[6]['6_21']    
        this.selected6_22 = this.$route.params.questionSelected.answers[6]['6_22']
        this.selected6_23 = this.$route.params.questionSelected.answers[6]['6_23']
        this.selected6_24 = this.$route.params.questionSelected.answers[6]['6_24']
        this.selected6_25 = this.$route.params.questionSelected.answers[6]['6_25']
        
        this.selected7_1 = this.$route.params.questionSelected.answers[7]['7_1']
        this.selected7_2 = this.$route.params.questionSelected.answers[7]['7_2']
        this.selected7_3 = this.$route.params.questionSelected.answers[7]['7_3']
        this.selected7_4 = this.$route.params.questionSelected.answers[7]['7_4']
        this.selected7_5 = this.$route.params.questionSelected.answers[7]['7_5']
        this.selected7_6 = this.$route.params.questionSelected.answers[7]['7_6']
        this.selected7_7 = this.$route.params.questionSelected.answers[7]['7_7']  
        this.selected7_8 = this.$route.params.questionSelected.answers[7]['7_8']
        this.selected7_9 = this.$route.params.questionSelected.answers[7]['7_9']
        this.selected7_10 = this.$route.params.questionSelected.answers[7]['7_10']    
        this.selected7_11 = this.$route.params.questionSelected.answers[7]['7_11']
        this.selected7_12 = this.$route.params.questionSelected.answers[7]['7_12']
        this.selected7_13 = this.$route.params.questionSelected.answers[7]['7_13']
        this.selected7_14 = this.$route.params.questionSelected.answers[7]['7_14']
        this.selected7_15 = this.$route.params.questionSelected.answers[7]['7_15']
        
        this.selected8_1 = this.$route.params.questionSelected.answers[8]['8_1']
        this.selected8_2 = this.$route.params.questionSelected.answers[8]['8_2']
        this.selected8_3 = this.$route.params.questionSelected.answers[8]['8_3']
        this.selected8_4 = this.$route.params.questionSelected.answers[8]['8_4']
        this.selected8_5 = this.$route.params.questionSelected.answers[8]['8_5']
        this.selected8_6 = this.$route.params.questionSelected.answers[8]['8_6']
        this.selected8_7 = this.$route.params.questionSelected.answers[8]['8_7']  
        this.selected8_8 = this.$route.params.questionSelected.answers[8]['8_8']
        
        this.selected9_1 = this.$route.params.questionSelected.answers[9]['9_1']
        this.selected9_2 = this.$route.params.questionSelected.answers[9]['9_2']
        this.selected9_3 = this.$route.params.questionSelected.answers[9]['9_3']
        this.selected9_4 = this.$route.params.questionSelected.answers[9]['9_4']
        
        this.selected10_1 = this.$route.params.questionSelected.answers[10]['10_1']
        this.selected10_2 = this.$route.params.questionSelected.answers[10]['10_2']
        this.selected10_3 = this.$route.params.questionSelected.answers[10]['10_3']
        this.selected10_4 = this.$route.params.questionSelected.answers[10]['10_4']
        this.selected10_5 = this.$route.params.questionSelected.answers[10]['10_5']
        this.selected10_6 = this.$route.params.questionSelected.answers[10]['10_6']
        this.selected10_7 = this.$route.params.questionSelected.answers[10]['10_7']  
        this.selected10_8 = this.$route.params.questionSelected.answers[10]['10_8']
        this.selected10_9 = this.$route.params.questionSelected.answers[10]['10_9']
        this.selected10_10 = this.$route.params.questionSelected.answers[10]['10_10']
        
        this.selected11_1 = this.$route.params.questionSelected.answers[11]['11_1']
        this.selected11_2 = this.$route.params.questionSelected.answers[11]['11_2']
        this.selected11_3 = this.$route.params.questionSelected.answers[11]['11_3']
        this.selected11_4 = this.$route.params.questionSelected.answers[11]['11_4']
        this.selected11_5 = this.$route.params.questionSelected.answers[11]['11_5']
        this.selected11_6 = this.$route.params.questionSelected.answers[11]['11_6']
        
        this.selected12_1 = this.$route.params.questionSelected.answers[12]['12_1']
        this.selected12_2 = this.$route.params.questionSelected.answers[12]['12_2']
        this.selected12_3 = this.$route.params.questionSelected.answers[12]['12_3']
        this.selected12_4 = this.$route.params.questionSelected.answers[12]['12_4']
        this.selected12_5 = this.$route.params.questionSelected.answers[12]['12_5']        
      }
    }
}
</script>

<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}

.card-violet {
  background-color: #f3eaff;
}
</style>