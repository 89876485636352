<template>
  <Loading v-if="isLoading" />
  <div v-else class="scaleBase">
    <PageTitle icon="fa fa-calendar" main="Escala" sub="Semanal" />
    <div class="scale-page-tabs">
      <b-row>
            <b-button size="sm" @click="setModal('professionals')" v-b-modal.modal-prevent-closing-1 variant="success" class="ml-3" 
                      v-b-popover.hover.top="'Adicionar Profissionais'">Adicionar
            </b-button>               
            
            <ScaleBaseProfessionals :list="professionals" :fields="pfields"></ScaleBaseProfessionals>              
      </b-row>  
      <b-row>
        <b-col md="6" sm="12" class="mt-3">
            <b-card :class="`card-${theme} text-center`" header="Semana 1" :header-bg-variant='`${week == 1 ? "warning" : "dark"}`' header-text-variant="white"> 
                <b-button-group size="md">
                    <b-button size="sm" variant="outline-dark" @click="getProfessionalsWeekAndDay('1', 'monday')">Segunda</b-button>
                    <b-button size="sm" variant="outline-dark" @click="getProfessionalsWeekAndDay('1', 'tuesday')">Terça</b-button>
                    <b-button size="sm" variant="outline-dark" @click="getProfessionalsWeekAndDay('1', 'wednesday')">Quarta</b-button>
                    <b-button size="sm" variant="outline-dark" @click="getProfessionalsWeekAndDay('1', 'thursday')">Quinta</b-button>
                    <b-button size="sm" variant="outline-dark" @click="getProfessionalsWeekAndDay('1', 'friday')">Sexta</b-button>
                    <b-button size="sm" variant="outline-dark" @click="getProfessionalsWeekAndDay('1', 'saturday')">Sábado</b-button>
                    <b-button size="sm" variant="outline-dark" @click="getProfessionalsWeekAndDay('1', 'sunday')">Domingo</b-button>
                </b-button-group>
            </b-card>                                 
        </b-col>        
        <b-col md="6" sm="12" class="mt-3">
            <b-card :class="`card-${theme} text-center`" header="Semana 2" :header-bg-variant='`${week == 2 ? "warning" : "dark"}`' header-text-variant="white"> 
                <b-button-group size="md">
                    <b-button size="sm" variant="outline-dark" @click="getProfessionalsWeekAndDay('2', 'monday')">Segunda</b-button>
                    <b-button size="sm" variant="outline-dark" @click="getProfessionalsWeekAndDay('2', 'tuesday')">Terça</b-button>
                    <b-button size="sm" variant="outline-dark" @click="getProfessionalsWeekAndDay('2', 'wednesday')">Quarta</b-button>
                    <b-button size="sm" variant="outline-dark" @click="getProfessionalsWeekAndDay('2', 'thursday')">Quinta</b-button>
                    <b-button size="sm" variant="outline-dark" @click="getProfessionalsWeekAndDay('2', 'friday')">Sexta</b-button>
                    <b-button size="sm" variant="outline-dark" @click="getProfessionalsWeekAndDay('2', 'saturday')">Sábado</b-button>
                    <b-button size="sm" variant="outline-dark" @click="getProfessionalsWeekAndDay('2', 'sunday')">Domingo</b-button>
                </b-button-group>
            </b-card>                                 
        </b-col>        
      </b-row>
      <b-row>
        <b-col md="6" sm="12" class="mt-2">
            <b-card :class="`card-${theme} text-center`" header="Semana 3" :header-bg-variant='`${week == 3 ? "warning" : "dark"}`' header-text-variant="white"> 
                <b-button-group size="md">
                    <b-button size="sm" variant="outline-dark" @click="getProfessionalsWeekAndDay('3', 'monday')">Segunda</b-button>
                    <b-button size="sm" variant="outline-dark" @click="getProfessionalsWeekAndDay('3', 'tuesday')">Terça</b-button>
                    <b-button size="sm" variant="outline-dark" @click="getProfessionalsWeekAndDay('3', 'wednesday')">Quarta</b-button>
                    <b-button size="sm" variant="outline-dark" @click="getProfessionalsWeekAndDay('3', 'thursday')">Quinta</b-button>
                    <b-button size="sm" variant="outline-dark" @click="getProfessionalsWeekAndDay('3', 'friday')">Sexta</b-button>
                    <b-button size="sm" variant="outline-dark" @click="getProfessionalsWeekAndDay('3', 'saturday')">Sábado</b-button>
                    <b-button size="sm" variant="outline-dark" @click="getProfessionalsWeekAndDay('3', 'sunday')">Domingo</b-button>
                </b-button-group>
            </b-card>                                 
        </b-col>        
        <b-col md="6" sm="12" class="mt-2">
            <b-card :class="`card-${theme} text-center`" header="Semana 4" :header-bg-variant='`${week == 4 ? "warning" : "dark"}`' header-text-variant="white"> 
                <b-button-group size="md">
                    <b-button size="sm" variant="outline-dark" @click="getProfessionalsWeekAndDay('4', 'monday')">Segunda</b-button>
                    <b-button size="sm" variant="outline-dark" @click="getProfessionalsWeekAndDay('4', 'tuesday')">Terça</b-button>
                    <b-button size="sm" variant="outline-dark" @click="getProfessionalsWeekAndDay('4', 'wednesday')">Quarta</b-button>
                    <b-button size="sm" variant="outline-dark" @click="getProfessionalsWeekAndDay('4', 'thursday')">Quinta</b-button>
                    <b-button size="sm" variant="outline-dark" @click="getProfessionalsWeekAndDay('4', 'friday')">Sexta</b-button>
                    <b-button size="sm" variant="outline-dark" @click="getProfessionalsWeekAndDay('4', 'saturday')">Sábado</b-button>
                    <b-button size="sm" variant="outline-dark" @click="getProfessionalsWeekAndDay('4', 'sunday')">Domingo</b-button>
                </b-button-group>
            </b-card>                                 
        </b-col>        
      </b-row>
      <b-row>
          <b-col class="mt-3">
            <b-card :class="`card-${theme} text-center`" :header='`${weekLabel} - ${dayLabel}`' header-bg-variant="dark" header-text-variant="white">
                <b-table ref="selectableTableProfessionals" head-variant="light" outlined hover :items="resultLookupProfessional" 
                        :fields="fields" responsive="sm" striped fixed @input="insertProfessionalScaleBase" :select-mode="selectMode">
                    <template v-slot:cell(ordination)="row">
                        <b-button size="sm" variant="primary" @click="lookupNewPositionProfessional(row.item, $event.target)" v-b-popover.hover.top="'Alterar Ordem'">
                            {{row.item.ordination}}
                        </b-button>
                    </template>                        
                    <template v-slot:cell(actions)="row">
                        <b-button size="sm" variant="danger" @click="removeProfessional(row.item)" v-b-popover.hover.top="'Remover'">
                            <i class="fa fa-trash"></i>
                        </b-button>
                    </template>
                </b-table>               
            </b-card>
          </b-col>
      </b-row>

      <b-modal :id="infoModal.id" :title="infoModal.title" @hidden="resetModal" @ok="handleOk" size="sm">
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group label="Nova Posição" label-for="newPosition-input" invalid-feedback="Informe a nova posição">
                <b-input id="input" type="number" v-model="to" required></b-input>                                                      
            </b-form-group>
        </form>
        </b-modal>         
    </div>
  </div>  
</template>

<script>
import PageTitle from "../template/PageTitle"
import { mapState } from "vuex"
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'
import ScaleBaseProfessionals from '../scale/ScaleBaseProfessionals'
import Loading from "../template/Loading"
import Moment from 'moment'

export default {
    name: "ScaleBase",
    components: { PageTitle, ScaleBaseProfessionals, Loading },    
    data: function() {
        return {
            theme: null,
            isLoading: false,
            weekSelected: null, 
            daySelected: null,
            weekLabel: 'Semana',
            dayLabel: 'Dia',
            professionals: [],
            selectMode: 'single',
            invalidFeedback: 'Informe todos os campos',
            modal: null,
            settings: null,
            from: 0,
            week: 0,
            to: 0,
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },            
            scaleBase: {},
            fields: [                 
                { key: 'name', label: 'Nome', sortable: false },
                { key: 'cpf', label: 'CPF', sortable: false },
                { key: 'adviceNumber', label: 'Registro', sortable: false },
                { key: 'checkin', label: 'Horário Entrada', sortable: false },
                { key: 'checkout', label: 'Horário Saída', sortable: false },
                { key: 'ordination', label: 'Ordem', sortable: false },
                { key: 'actions', label: 'Ações' }
            ],
            pfields: [                 
                { key: 'name', label: 'Nome', sortable: true },
                { key: 'cpf', label: 'CPF', sortable: true, },       
                { key: 'advice.number', label: 'Registro', sortable: true },  
                { key: 'actions', label: 'Ações' },    
            ]       
        }
    },
    computed: mapState(["resultLookupProfessional", "resultLookupScaleBaseProfessionals", "user"]),
    methods: {
        resetModal() {
            this.from = 0
            this.to = 0
        },       
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        },  
        handleSubmit() {
            this.$nextTick(() => {
                this.$bvModal.hide(this.infoModal.id)
                this.changeOrdProfessionalScale()
            })
        },           
        lookupNewPositionProfessional(item, button) {
            this.infoModal.title = 'Ordenação'
            this.infoModal.content = JSON.stringify(item, null)

            this.from = item.ordination
            
            this.$root.$emit('bv::show::modal', this.infoModal.id, button)
        },
        loadProfessionals() {
            if (!this.user.admin) {
                this.$toasted.global.defaultError({msg: 'Usuário sem permissão.'})
                this.$router.go(-1)
            }

            this.isLoading = true
            
            this.$store.state.resultLookupProfessional = []
            this.$store.state.resultLookupScaleBaseProfessionals = []    

            const url = `${baseApiUrl}/professional/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                this.isLoading = false
                this.professionals = res.data            
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })
            })                      
        }, 
        getProfessionalsWeekAndDay(week, day) {            
            this.$store.state.resultLookupProfessional = []    
            this.modal = null       
            
            this.weekSelected = week
            this.daySelected = day

            switch (week) {
                case 'week1': this.weekLabel = 'Semana 1'                
                break;
                case 'week2': this.weekLabel = 'Semana 2'                
                break;          
                case 'week3': this.weekLabel = 'Semana 3'                
                break;          
                case 'week4': this.weekLabel = 'Semana 4'                
                break;

                default:
                this.weekLabel = 'Semana'
            }          

            switch (day) {
                case 'monday': this.dayLabel = 'Segunda'                
                break;
                case 'tuesday': this.dayLabel = 'Terça'                
                break;          
                case 'wednesday': this.dayLabel = 'Quarta'                
                break;          
                case 'thursday': this.dayLabel = 'Quinta'                
                break;
                case 'friday': this.dayLabel = 'Sexta'                
                break;
                case 'saturday': this.dayLabel = 'Sábado'                
                break;
                case 'sunday': this.dayLabel = 'Domingo'                
                break;

                default:
                this.dayLabel = 'Dia'
            }
            
            this.isLoading = true

            const url = `${baseApiUrl}/scaleBase/weekAndDay/${this.weekSelected}&${this.daySelected}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                this.scaleBase = res.data                               
                
                if (!this.scaleBase) {
                    this.scaleBase = {
                      week: '',
                      day: '',
                      professionals: []
                    }                                        
                } else {
                    let ord = 0

                    for (let p of this.scaleBase.professionals) {
                        p.ordination = ord + 1
                        ord += 1 
                    }

                    this.$store.commit("setResultLookupProfessionalArray", this.scaleBase.professionals)
                }

                this.isLoading = false
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })
            })
        },
        changeOrdProfessionalScale() {
            let data = {
                from: parseInt(this.from),
                to: parseInt(this.to),
                scaleBase: this.scaleBase
            }

            this.isLoading = true

            axios.post(`${baseApiUrl}/scaleBase/changeOrdinationProfessionalScale/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, data).then(() => {
                this.$toasted.global.defaultSuccess()                                            
                this.getProfessionalsWeekAndDay(this.weekSelected, this.daySelected)

                this.isLoading = false
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })
            })
        },
        setModal(param) {
            this.modal = param
        },           
        insertProfessionalScaleBase() {
            if (this.weekSelected && this.daySelected && this.$store.state.resultLookupProfessional.length > 0 && this.modal != null) {    
                let index = this.$store.state.resultLookupProfessional.length - 1
                let finded = false                  
                
                for (let [i, p] of this.scaleBase.professionals.entries()) {
                    if (p.cpf == this.$store.state.resultLookupProfessional[index].cpf && i != index) {      
                        if (this.$store.state.resultLookupProfessional[index].checkin >= p.checkin && this.$store.state.resultLookupProfessional[index].checkin <= p.checkout) {
                            this.modal = null

                            finded = true

                            this.$store.state.resultLookupProfessional.splice(index, 1)
                            this.$toasted.global.defaultError({msg: 'Profissional já adicionado no dia e horário informados.'})                                                       
                        }                                        
                    }

                    if (finded) {
                        break
                    }                    
                }

                if (!finded && this.modal != null) {       
                    this.modal = null
                    
                    let prof = {
                        name: this.$store.state.resultLookupProfessional[index].name,
                        cpf: this.$store.state.resultLookupProfessional[index].cpf,
                        checkin: this.$store.state.resultLookupProfessional[index].checkin,
                        checkout: this.$store.state.resultLookupProfessional[index].checkout,
                        attendance: [{
                            patients: []
                        }]
                    }    

                    this.scaleBase.week = this.weekSelected
                    this.scaleBase.day = this.daySelected

                    this.scaleBase.professionals.splice(index, 1)    
                    this.scaleBase.professionals.push(prof)

                    const method = this.scaleBase._id ? 'put' : 'post'
                    const id = this.scaleBase._id ? `/${this.scaleBase._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}` : `/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`

                    this.isLoading = true

                    axios[method](`${baseApiUrl}/scaleBase${id}`, this.scaleBase).then(() => {
                        this.$toasted.global.defaultSuccess()                                            
                        this.getProfessionalsWeekAndDay(this.weekSelected, this.daySelected)

                        this.isLoading = false
                    }).catch(e => {
                        this.isLoading = false
                        this.$toasted.global.defaultError({ msg: e.response.data })
                    })    
                }                                    
            }
        },
        removeProfessional(itemSelected) {
            this.$bvModal.msgBoxConfirm('Deseja remover o profissional da escala?', {
                title: 'Confirmação',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Sim',
                cancelTitle: 'Não',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            }).then(value => {            
                if (value) {
                    let i = 0

                    for (let p of this.scaleBase.professionals) {
                        if (p.cpf == itemSelected.cpf && p.checkin == itemSelected.checkin) {
                            this.scaleBase.professionals.splice(i, 1)
                        }

                        i += 1
                    }                    

                    const method = 'put'
                    const id = this.scaleBase._id ? `/${this.scaleBase._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}` : `/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
                    
                    this.isLoading = true

                    axios[method](`${baseApiUrl}/scaleBase${id}`, this.scaleBase).then(() => {
                        this.isLoading = false
                        this.$toasted.global.defaultSuccess({msg: 'Profissional removido com sucesso!'})
                    }).catch(e => {
                        this.isLoading = false
                        this.$toasted.global.defaultError({ msg: e.response.data })
                    })                    
                }                
            }).catch()
        },
        async setActualWeek() {
            let w = 1
                                
            let weekday = new Array(7)
            
            weekday[0] = "sunday"
            weekday[1] = "monday"
            weekday[2] = "tuesday"
            weekday[3] = "wednesday"
            weekday[4] = "thursday"
            weekday[5] = "friday"
            weekday[6] = "saturday"

            const url = `${baseApiUrl}/setting/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                if (res.data.length == 0) {
                    this.$toasted.global.defaultError({msg: 'Configurações do sistema não informadas'})
                } else {
                    this.settings = res.data

                    let dateOrigin = Moment(this.settings[0].params[5].value)

                    let i = 0

                    for (i = 0; i <= w - 1; i++) {
                        let newDate = new Date().getTime() + (i * 24 * 60 * 60 * 1000)

                        this.week = (Moment(newDate).diff(dateOrigin, 'days') + 1)

                        if (this.week == 7) {
                            this.week = 1
                        } else {
                            this.week = Math.ceil(this.week / 7)
                        }

                        while (this.week > 4) {
                            this.week = this.week - 4
                        }
                    }
                }
            }).catch(showError)
        }
    },
    mounted() {
        this.theme = localStorage.getItem('theme')
        this.loadProfessionals()
        this.setActualWeek()
    },
}
</script>

<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}

.card-violet {
  background-color: #f3eaff;
}
</style>