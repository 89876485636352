<template>
  <Loading v-if="isLoading" />  
  <div v-else class="batchGuidesANSProcessing">
    <PageTitle icon="fa fa-hashtag" main="ANS" sub="Consulta de Guias" />
        <div class="batchguidesANS-page-tabs">
            <b-row>
                <b-button class="ml-3" size="sm" variant="success" @click="newGuideANS()">
                    GERAR GUIA
                </b-button> 
            </b-row>
            <b-row>
                <b-col md="12" sm="12" class="mt-3">
                    <b-card :class="`card-${theme}`" header="Guias" border-variant="dark" header-border-variant="white" header-bg-variant="dark" header-text-variant="white">
                        <b-row>
                            <b-col md="2" sm="12">
                                <b-form-group label="Data Inicial:" label-for="bgans-date-initial">
                                    <b-form-input size="sm" id="bgans-date-initial" type="date" v-model="dateInitial" required />
                                </b-form-group>
                            </b-col>
                            <b-col md="2" sm="12">
                                <b-form-group label="Data Final:" label-for="bgans-date-final">
                                    <b-form-input size="sm" id="bgans-date-final" type="date" v-model="dateFinal" required />
                                </b-form-group>
                            </b-col>
                            <b-col md="2" sm="12">
                                <b-form-group label="Convênio:" label-for="bgans-covenant">
                                    <b-form-select size="sm" v-model="selectedCovenant" :options="covenants"></b-form-select>
                                </b-form-group>    
                            </b-col>
                            <b-col md="2" sm="12">
                                <b-form-group label="Número Guia:" label-for="bgans-numberGuide">
                                    <b-form-input size="sm" id="bgans-date-numberGuide" type="number" v-model="numberGuideFilter" />
                                </b-form-group>    
                            </b-col>
                            <b-col md="2" sm="12">
                                <b-button size="sm" variant="warning" class="mt-4" @click="searchBatchGuides">
                                    <div class="d-flex align-items-center">
                                        <b-spinner small v-if="generating" class="mr-2"></b-spinner>
                                        <span>{{textBtnPrint}}</span>
                                    </div>                                    
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card>                                          
                    <b-table class="mt-3" ref="selectableTable" head-variant="light" outlined hover :items="guides" :fields="fields" responsive="sm" id="my-table"
                            :per-page="perPage" :current-page="currentPage" striped fixed>
                        <template v-slot:cell(actions)="row">
                            <b-button v-if="!row.item.nullified" class="mt-2" size="sm" variant="warning" @click="onRowSelected(row.item)" v-b-popover.hover.top="'Alterar'">
                                <i class="fa fa-pencil"></i>
                            </b-button>

                            <b-button v-if="!row.item.nullified" class="ml-2 mt-2" size="sm" variant="primary" @click="showModalPrint(row.item, $event.target, 'guideSADT')" 
                                v-b-popover.hover.top="'Guias / Demonstrativo'">
                                <i class="fa fa-print"></i>
                            </b-button>
                            
                            <b-button v-if="!row.item.nullified" class="ml-2 mt-2" size="sm" variant="danger" @click="deleteGuide(row.item)" v-b-popover.hover.top="'Excluir'">
                                <i class="fa fa-trash"></i>
                            </b-button>                            
                        </template>
                    </b-table>
                    
                    <b-modal :id="infoModal.id" :title="infoModal.title" @ok="handleOk" size="lg">
                        <form ref="form" @submit.stop.prevent="handleSubmit">
                            <b-row class="mt-3 ml-2 mb-3">
                                <b-form-checkbox id="checkbox-guideSADT" v-model="printGuide" name="checkbox-guideSADT" :value="true" :unchecked-value="false">
                                    Guia SADT
                                </b-form-checkbox>
                                <b-form-checkbox class="ml-3" id="checkbox-demonstrative" v-model="printDemonstrative" name="checkbox-demonstrative" :value="true" :unchecked-value="false">
                                    Demonstrativo
                                </b-form-checkbox>
                            </b-row>
                            <b-row>
                                <b-table class="ml-3 mr-3" ref="selectableTableGuides" head-variant="light" outlined hover :items="listAllGuides" :fields="fieldsGuides" responsive="sm" 
                                    id="my-table" striped fixed >
                                </b-table>
                            </b-row>
                            <b-row>
                                <b-textarea class="ml-3 mr-3" :id='`textarea-problem`' v-model="problems" placeholder="Problemas..." rows="1" max-rows="6"></b-textarea>
                            </b-row>
                        </form>
                    </b-modal>

                    <b-row>
                        <b-pagination size="sm" class="ml-3 mt-2 page-item" pills v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table">
                        </b-pagination>
                        <h5 class="badge2"><b-badge class="mt-2 ml-3" pill variant="danger">Cancelados</b-badge></h5>
                    </b-row>
                </b-col>
            </b-row>
        </div>
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, showError, userKey } from "@/global"
import axios from "axios"
import Moment from "moment"
import Loading from "../template/Loading"

export default {
    name: "batchGuidesANSProcessing",
    components: { PageTitle, Loading },    
    data: function() {
        return {
            isLoading: false,
            theme: null,        
            guidesANS: null,   
            dateInitial: null,
            dateFinal: null,
            loading: false,
            settings: null,
            selectedCovenant: null,
            covenants: [],      
            guides: [],
            originModal: '',
            listAllGuides: [],
            listGuidesSelected: [],
            listDates: [],
            numberGuideFilter: null,
            printGuide: true,
            printDemonstrative: true,
            problems: '',
            idGuidePrint: null,
            fields: [                 
                { key: 'patientName', label: 'Paciente', sortable: true },
                { key: 'covenant.fantasyname', label: 'Convênio', sortable: true },
                { key: 'numberGuide', label: 'Número da Guia', sortable: true },
                { key: 'batch', label: 'Número Lote', sortable: true },
                { key: 'valueTotal2', label: 'Total (R$)', sortable: true },
                { key: 'actions', label: 'Ações' }
            ],       
            fieldsGuides: [
                { key: 'numeroGuiaPrestador', label: 'Número Guia', sortable: false },
                { key: 'nomeProfissional', label: 'Solicitante', sortable: false },
                { key: 'nomeBeneficiario', label: 'Beneficiário', sortable: false },
            ],       
            generating: false,  
            textBtnPrint: 'Filtrar',
            currentPage: 1,
            perPage: 10, 
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },   
            selectMode: 'multi',
            job: null,
            jobID: '',
            jobResult: null            
        }
    },
    computed: {    
        rows() {
            return this.guides.length
        },     
    },    
    methods: {
        searchBatchGuides() {
            let valid = true

            if (this.dateInitial == '' || this.dateInitial == null) {
                this.$toasted.global.defaultError({msg: 'Informe a data Inicial.'})
                valid = false
            }

            if (this.dateFinal == '' || this.dateFinal == null) {
                this.$toasted.global.defaultError({msg: 'Informe a data Final.'})
                valid = false
            }

            Moment.locale('pt-br')

            let dtInitial = Moment(this.dateInitial)
            let dtFinal = Moment(this.dateFinal)  

            let diff = dtFinal.diff(dtInitial, 'days')

            if (diff < 0) {
                this.$toasted.global.defaultError({msg: 'Data final menor que data inicial.'})
                valid = false
            }             

            if (!this.selectedCovenant) {
                this.$toasted.global.defaultError({msg: 'Informe o convênio.'})
                valid = false
            }

            if (valid) {
                this.generating = true
                this.textBtnPrint = 'Aguarde...'

                this.$store.commit("setCacheBatchGuidesANS", { dateInitial: this.dateInitial, dateFinal: this.dateFinal, selectedCovenant: this.selectedCovenant, numberGuideFilter: this.numberGuideFilter })

                const url = `${baseApiUrl}/guidesANS/filter/${this.dateInitial}&${this.dateFinal}&${encodeURIComponent(this.selectedCovenant.fantasyname)}&${this.numberGuideFilter ? this.numberGuideFilter : 'null'}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
                axios.get(url).then(res => {
                    this.guides = res.data

                    Moment.locale('pt-br')

                    for (let g of this.guides) {
                        if (!g.batch) {
                            g.batch = ''        
                        }

                        g.date2 = Moment(g.date).format('L')

                        g.valueTotal2 = parseFloat(g.valueTotal).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})                        

                        let guideANS = JSON.parse(g.xmlToJson)

                        g.patientName = guideANS.guides[0].nomeBeneficiario

                        g.numberGuide = guideANS.guides[0].numeroGuiaPrestador
                        
                        g._cellVariants = {
                            date2: g.nullified ? 'danger' : '',
                            'covenant.fantasyname': g.nullified ? 'danger' : '',
                            numberBatch: g.nullified ? 'danger' : '',
                            numberGuide: g.numeroGuiaPrestador ? 'danger' : '',
                            numberTotalGuides: g.nullified ? 'danger' : '',
                            valueTotal2: g.nullified ? 'danger' : '',
                            'actions': g.nullified ? 'danger' : ''
                        }
                    }

                    this.generating = false
                    this.textBtnPrint = 'Filtrar'                    
                }).catch(showError)              
            }
        },
        newGuideANS() {
            this.$router.push({name: 'batchGuidesANSProcessing', params: { },})  
        },
        loadSettings() {
            if (!JSON.parse(localStorage.getItem('__connect_user')).admin) {
                this.$toasted.global.defaultError({msg: 'Usuário sem permissão.'})
                this.$router.go(-1)
            }

            const url = `${baseApiUrl}/setting/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                this.settings = res.data                
            }).catch(showError)                      
        },          
        loadCovenants() {
            const url = `${baseApiUrl}/covenants/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                for (let r of res.data) {
                    this.covenants.push({ value: r,  text: r.fantasyname })
                }
            }).catch(showError)
        },
        async sendGuideSADT(data) {
            try {
                this.isLoading = true

                const url = `${baseApiUrl}/guideSADT/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
                axios.post(url, data).then(res => {  
                    this.jobID = res.data.id

                    this.getResultJob()                
                })
            } catch (e) {
                this.isLoading = false
            }
        },
        async getResultJob() {
            return new Promise((resolve, reject) => {
                try {
                    const urlJob = `${baseApiUrl}/jobs/${this.jobID}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`

                    const startTime = Date.now();
                    const intervalTime = 7000; // Intervalo de 5 segundos
                    const endTime = startTime + 600000; // 10 minutos

                    // Usando setInterval para checar o job periodicamente
                    const intervalId = setInterval(async () => {
                        try {
                            const res = await axios.get(urlJob, {responseType: 'arraybuffer'});

                            if (res.data instanceof ArrayBuffer || Date.now() >= endTime) {
                                this.jobResult = res.data
                                this.finishPrint()
                                clearInterval(intervalId)
                                resolve()
                            }
                        } catch (error) {
                            showError(error)
                            clearInterval(intervalId) // Para o intervalo em caso de erro
                            reject(error)
                        }
                    }, intervalTime)
                } catch(e) {
                    reject()
                }
            })
        },
        finishPrint() {
            Moment.locale('pt-br')

            var file = new Blob([this.jobResult], {type: 'application/pdf'})
            var fileURL = URL.createObjectURL(file)
            window.open(fileURL)
  
            this.isLoading = false
            this.$toasted.global.defaultSuccess({msg: 'Guia gerada com sucesso.'})
        },                
        async showModalPrint(itemSelected, button, origin) {
            let json = JSON.parse(itemSelected.xmlToJson)

            this.listAllGuides = json.guides

            this.idGuidePrint = itemSelected._id

            this.listDates = itemSelected.listDates

            this.problems = itemSelected.problem

            this.originModal = origin

            this.infoModal.title = 'Guias Geradas'
            this.infoModal.content = json
            
            this.$root.$emit('bv::show::modal', this.infoModal.id, button)
        },
        async billingStatement(data) {
            try {
                let dataPDF = {
                    title: 'DEMONSTRATIVO DE COBRANÇA',
                    data: data,
                    problems: this.problems,
                    idGuide: this.idGuidePrint
                }

                const url = `${baseApiUrl}/repBillingStatement/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
                axios.post(url, dataPDF, {responseType: 'arraybuffer'}).then(res => {  
                    var file = new Blob([res.data], {type: 'application/pdf'})
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL)
                }).catch(showError)
            } catch (e) {
                console.log(e)
            }
        },     
        onRowSelected(itemSelected) {
          let guideSelected = itemSelected

          this.$router.push({name: 'batchGuidesANSEdit', params: { guideSelected },}) 
        },
        deleteGuide(itemSelected) {
            this.$bvModal.msgBoxConfirm(`Deseja excluir a guia ${itemSelected.numberBatch}?`, {
                title: 'Confirmação',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Sim',
                cancelTitle: 'Não',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            }).then(value => {            
                if (value) {                
                    const method = 'post'

                    this.isLoading = true
        
                    axios[method](`${baseApiUrl}/guidesANS/removeBatchGuidesANS/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, itemSelected)
                        .then(() => {
                            this.isLoading = false
                            this.$toasted.global.defaultSuccess()
                            this.searchBatchGuides()
                        }).catch(e => {
                            this.isLoading = false
                            this.$toasted.global.defaultError({ msg: e.response.data })
                        })                    
                }                
            }).catch()
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()

            // Trigger submit handler
            this.handleSubmit()
        },
        async handleSubmit() {
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide(this.infoModal.id)
            })   
            
            for (let lgs of this.listAllGuides) {
                let json = this.infoModal.content

                let promises = [];

                let data = {
                    header: json.header,
                    guide: lgs,
                    listDates: this.listDates,
                    covenant: this.selectedCovenant
                }
                
                if (this.printGuide) {
                    promises.push(await this.sendGuideSADT(data))
                } 
                
                if (this.printDemonstrative) {
                    promises.push(await this.billingStatement(data))
                }

                Promise.all(promises).then(() => this.$toasted.global.defaultSuccess({ msg: 'Guias geradas com sucesso' }))

                this.printGuide = true
                this.printDemonstrative = true
                this.problems = ''
                this.idGuidePrint = null
            }

            this.listGuidesSelected = []
        },
        selectAllRows() {
            this.listGuidesSelected = this.guides
            this.$refs.selectableTableGuides.selectAllRows()
        },
        clearSelected() {
            this.listGuidesSelected = []
            this.$refs.selectableTableGuides.clearSelected()
        }
    },
    mounted() {
        this.theme = localStorage.getItem('theme')

        if (this.$store.state.cacheBatchGuidesANS) {
            this.dateInitial = this.$store.state.cacheBatchGuidesANS.dateInitial,
            this.dateFinal = this.$store.state.cacheBatchGuidesANS.dateFinal,
            this.numberGuideFilter = this.$store.state.cacheBatchGuidesANS.numberGuideFilter,
            this.selectedCovenant = this.$store.state.cacheBatchGuidesANS.selectedCovenant
            this.searchBatchGuides()
        }

        this.loadSettings()
        this.loadCovenants()
    }
}
</script>

<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}
</style>