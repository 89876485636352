import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isMenuVisible: false,
        isTitleVisible: false,
        user: null,
        resultLookup: null,
        resultLookupPatient: null,
        resultLookupAccommodation: null,
        resultLookupContainedAccommodation: '',
        resultLookupTypeService: [],
        resultLookupSpeciality: [],
        resultLookupQualification: [],
        resultLookupProfessional: [],
        resultLookupPatients: [],
        resultLookupScaleBaseProfessionals: {},
        resultAdmission: {},
        resultFilter: '',
        resultPatient: null,
        cacheBatchGuidesANS: null,
        cacheBatchsANS: null
    },
    mutations: {
        toggleTitle(state, isVisible) {
            if (!state.user) {
                state.isTitleVisible = false
                return
            }

            if (isVisible === undefined) {
                state.isTitleVisible = !state.isTitleVisible
            } else {
                state.isTitleVisible = isVisible
            }
        },
        toggleMenu(state, isVisible) {
            if (!state.user) {
                state.isMenuVisible = false
                return
            }

            if (isVisible === undefined) {
                state.isMenuVisible = !state.isMenuVisible
            } else {
                state.isMenuVisible = isVisible
            }
        },
        setUser(state, user) {
            state.user = user

            if (user) {
                axios.defaults.headers.common['Authorization'] = `bearer ${user.token}`
                state.isMenuVisible = true
                state.isTitleVisible = true
            } else {
                delete axios.defaults.headers.common['Authorization']
                state.isMenuVisible = false
                state.isTitleVisible = false
            }
        },
        setResultLookup(state, resultLookup) {
            state.resultLookup = resultLookup
        },
        setResultLookupPatient(state, resultLookupPatient) {
            state.resultLookupPatient = resultLookupPatient
        },
        setResultLookupAccommodation(state, resultLookupAccommodation) {
            state.resultLookupAccommodation = resultLookupAccommodation
        },
        setResultLookupContainedAccommodation(state, resultLookupContainedAccommodation) {
            state.resultLookupContainedAccommodation = resultLookupContainedAccommodation
        },
        setResultLookupTypeService(state, resultLookupTypeService) {
            if (resultLookupTypeService.length == 0) {
                state.resultLookupTypeService = []
            } else {
                state.resultLookupTypeService.push(resultLookupTypeService)
            }
        },
        setResultLookupSpeciality(state, resultLookupSpeciality) {
            if (resultLookupSpeciality.length == 0) {
                state.resultLookupSpeciality = []
            } else {
                state.resultLookupSpeciality.push(resultLookupSpeciality)
            }
        },
        setResultLookupQualification(state, resultLookupQualification) {
            if (resultLookupQualification.length == 0) {
                state.resultLookupQualification = []
            } else {
                state.resultLookupQualification.push(resultLookupQualification)
            }
        },
        setResultLookupProfessional(state, resultLookupProfessional) {
            if (resultLookupProfessional.length == 0) {
                state.resultLookupProfessional = []
            } else {
                state.resultLookupProfessional.push(resultLookupProfessional)
            }
        },
        setResultLookupProfessionalArray(state, resultLookupProfessional) {
            if (resultLookupProfessional.length == 0) {
                state.resultLookupProfessional = []
            } else {
                state.resultLookupProfessional = resultLookupProfessional
            }
        },
        setResultLookupPatientArray(state, resultLookupPatients) {
            if (resultLookupPatients.length == 0) {
                state.resultLookupPatients = []
            } else {
                state.resultLookupPatients.push(resultLookupPatients)
            }
        },
        setResultLookupScaleBaseProfessionals(state, resultLookupScaleBaseProfessionals) {
            state.resultLookupScaleBaseProfessionals = resultLookupScaleBaseProfessionals
        },
        setResultAdmission(state, resultAdmission) {
            state.resultAdmission = resultAdmission
        },
        setResultFilter(state, resultFilter) {
            state.resultFilter = resultFilter
        },
        setResultPatient(state, resultPatient) {
            state.resultPatient = resultPatient
        },
        setCacheBatchGuidesANS(state, cacheBatchGuidesANS) {
            state.cacheBatchGuidesANS = cacheBatchGuidesANS
        },
        setCacheBatchsANS(state, cacheBatchsANS) {
            state.cacheBatchsANS = cacheBatchsANS
        }
    }
})