<template>
  <div class="accommodation-pages">
    <PageTitle icon="fa fa-hospital-o" main="Locais de Internação" sub />
    <div class="admin-pages-tabs">
        <b-button size="sm" variant="success" @click="newAccommodation()">
            NOVO
        </b-button> 
        <b-button size="sm" class="ml-3" @click="print" variant="info" v-b-popover.hover.top="'Imprimir Listagem'">
            <div class="d-flex align-items-center">
                <b-spinner small v-if="generatingPDF" class="mr-2"></b-spinner>
                <span>{{textBtnPrint}}</span>
            </div>
        </b-button>              
        <hr>          
        <b-row>
          <b-col md="4" sm="12">
            <b-form-input size="sm" placeholder="Filtrar..." v-model="ftext"></b-form-input>
          </b-col> 
        </b-row>             
        <hr>
        <b-table head-variant="light" outlined hover :fields="fields" responsive="sm" id="my-table" :items="accommodations"
                 :per-page="perPage" :current-page="currentPage" striped fixed :filter="ftext">
            <template v-slot:cell(actions)="row">
                <b-button size="sm" variant="warning" @click="onRowSelected(row.item)" v-b-popover.hover.top="'Alterar'">
                    <i class="fa fa-pencil"></i>
                </b-button>
                <b-button size="sm" variant="danger" @click="removeAccommodation(row.item)" v-b-popover.hover.top="'Excluir'" class="ml-2">
                    <i class="fa fa-trash"></i>
                </b-button>
            </template>                 
        </b-table>        

        <div v-if="accommodations.length == 0" class="d-flex justify-content-center mb-3">    
            <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner" type="grow"></b-spinner>
        </div>        

        <b-pagination size="sm" pills v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table" class="page-item">
        </b-pagination>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../template/PageTitle"
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'

export default {
    name: 'Accommodations',
    components: { PageTitle },
    data: function() {
      return {
        generatingPDF: false,
        textBtnPrint: 'IMPRIMIR',
        ftext: '',
        accommodations: [],
        currentPage: 1,
        perPage: 10,
        fields: [                 
            { key: 'name', label: 'Nome', sortable: true },
            { key: 'type', label: 'Tipo', sortable: true },
            { key: 'level', label: 'Nível', sortable: true },
            { key: 'contained', label: 'Contido', sortable: true },            
            { key: 'actions', label: 'Ações' }
        ],          
        scaleSequenceBuilding: []
      }
    },
    computed: {
      rows() {
        return this.accommodations.length
      }
    },
    methods: {
        print() {
            this.generatingPDF = true
            this.textBtnPrint = 'Aguarde...'   

            let contentPdf = {
                title: 'LOCAIS DE ATENDIMENTO',
                format: 'A4',
                orientation: 'portrait',
                separate: false,
                content: [
                    {
                        type: 'table',
                        topicFields: [],
                        fields: [
                          {
                            original: 'name',
                            exibition: 'Nome'
                          },
                          {
                            original: 'type',
                            exibition: 'Tipo'
                          },                
                          {
                            original: 'level',
                            exibition: 'Nível'
                          },                 
                          {
                            original: 'contained',
                            exibition: 'Contido'
                          }                                             
                        ],
                        list: this.accommodations
                    }
                ]
            }

            const url = `${baseApiUrl}/reports/pdfGenerator/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.post(url, contentPdf, {responseType: 'arraybuffer'}).then(res => {  
              var file = new Blob([res.data], {type: 'application/pdf'})
              var fileURL = URL.createObjectURL(file);
              window.open(fileURL);

                this.generatingPDF = false
                this.textBtnPrint = 'IMPRIMIR'    
            }).catch(showError)       
        },
        newAccommodation() {
          this.$router.push({name: 'accommodation', params: { },})          
        },
        onRowSelected(itemSelected) {
          let b = ''
          let a = ''
          let f = ''

          this.accommodations.find((item, i) => {
              if (item._id == itemSelected._id) {                
                b = item.building
                a = item.apartment
                f = item.floor
                i.delete
              }
          })

          let accommodationSelected = {    
              _id: itemSelected._id,
              name: itemSelected.name,
              type: itemSelected.type,
              contained: itemSelected.contained,
              building: b,
              apartment: a,
              floor: f,
              level: itemSelected.level,
              onlyOffice: itemSelected.onlyOffice
          }              

          this.$router.push({name: 'accommodation', params: { accommodationSelected },})            
        },               
        loadAccommodations() {
            const url = `${baseApiUrl}/accommodation/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {  
                this.accommodations = res.data
            }).catch(showError)                      
        },    
        removeAccommodation(itemSelected) {
            this.$bvModal.msgBoxConfirm('Deseja remover o local de atendimento?', {
                title: 'Confirmação',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Sim',
                cancelTitle: 'Não',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            }).then(value => {            
                if (value) {                
                  const id = itemSelected._id
                  axios.delete(`${baseApiUrl}/accommodation/${id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`).then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.loadAccommodations()
                  }).catch(showError)                
                }                
            }).catch()
        }              
    },
    mounted() {
        this.loadAccommodations()
    }      
}
</script>

<style>
.page-item .page-font {
    color:#44A08D;  
}

.page-item.active .page-link {  
    background-color:#44A08D;  
    border-color:  #44A08D;      
}

.page-item.active .page-link:hover {  
    background-color:#44A08D;  
    border-color:  #44A08D;  
}

.card-header {
    height: 38px;
    font-size: 14px;    
}

.card-body {
    font-size: 14px;
}

.calendar-btn {
    font-size: 0.30;
}

.table th {
  padding: 0.45rem;
}

.table td {
  padding: 0.45rem;
  vertical-align: middle;
}
</style>