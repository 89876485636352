<template>
  <div class="admissionXApartments">
    <PageTitle icon="fa fa-file-text" main="Admissão x Apartamentos" sub="Relatório" />
        <div class="admissionXApartments-page-tabs">
            <b-row>
                <b-col md="6" sm="12">
                    <b-card :class="`card-${theme}`" header="Informações" header-bg-variant="dark" header-text-variant="white">
                        <b-row>                       
                            <b-col md="2" sm="12">
                                <b-button size="sm" variant="info" @click="loadAdmissions">
                                    <div class="d-flex align-items-center">
                                        <b-spinner small v-if="generatingPDF" class="mr-2"></b-spinner>
                                        <span>{{textBtnPrint}}</span>
                                    </div>                                    
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card>                    
                </b-col>                  
            </b-row>   
        </div>
  </div>  
</template>

<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'
import Moment from 'moment'

export default {
    name: "admissionXApartments",
    components: { PageTitle },    
    data: function() {
        return {
            theme: null,
            generatingPDF: false,
            textBtnPrint: 'GERAR',
            admissions: [],
            level: 0,
            invalidFeedback: '',  
        }
    },   
    methods: {
        async showReport() {
            let list = []

            Moment.locale('pt-br')                

            for (let a of this.admissions) {
                list.push({
                    apartment: a.apartment,
                    buildingFloor: `${a.building} ${a.floor}`,
                    name: a.patient,
                    birthday: a.birthday.includes('/') ? a.birthday : Moment(a.birthday).format('L'),
                    covenant: a.covenant,
                    attendanceNumber: a.attendanceNumber,
                    r: a.r,
                    m: a.m,
                    obs: a.obs
                })
            }

            let contentPdf = {
                title: 'ADMISSÕES X APARTAMENTOS',
                format: 'A4',
                orientation: 'landscape',
                separate: false,
                content: [
                    {
                        type: 'table',
                        topicFields: [],
                        fields: [                                                                
                            {
                                original: 'apartment',
                                exibition: 'apartment'
                            },                                
                            {
                                original: 'name',
                                exibition: 'Paciente'
                            },
                            {
                                original: 'birthday',
                                exibition: 'Data Nasc.'
                            },
                            {
                                original: 'covenant',
                                exibition: 'Convênio'
                            },
                            {
                                original: 'attendanceNumber',
                                exibition: 'Nº Atendimento'
                            },                            
                            {
                                original: 'r',
                                exibition: 'R'
                            },              
                            {
                                original: 'm',
                                exibition: 'M'
                            },     
                            {
                                original: 'obs',
                                exibition: 'OBS'
                            }                                                                             
                        ],
                        list: list,
                        repeat: 'buildingFloor'
                    },
                ]
            }

            const url = `${baseApiUrl}/reports/pdfGenerator/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.post(url, contentPdf, {responseType: 'arraybuffer'}).then(res => {  
            var file = new Blob([res.data], {type: 'application/pdf'})
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);

                this.generatingPDF = false
                this.textBtnPrint = 'GERAR'    
            }).catch(showError)                   
        },
        async loadAdmissions() {
            this.generatingPDF = true
            this.textBtnPrint = 'Aguarde...' 

            const url = `${baseApiUrl}/admission/rel/admissionXApartments/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            await axios.post(url, {}).then(res => {
                this.admissions = res.data     
                
                if (this.admissions.length == 0) {
                    this.$toasted.global.defaultError({ msg: 'Nenhuma admissão carregada' }) 

                    this.generatingPDF = false
                    this.textBtnPrint = 'GERAR'                      
                } else {  
                    this.showReport()
                }                               
            }).catch(showError)        
        },
    },
    mounted() {
        this.theme = localStorage.getItem('theme')
    }
}
</script>

<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}
</style>