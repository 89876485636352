<template>
  <Loading v-if="isLoading" />
  <div v-else class="scheduling">
    <PageTitle icon="fa fa-clock-o" main="Agendamento" sub="Calendário" />
    <div class="scheduling-page-tabs">
      <b-row>
        <b-button class="ml-3" size="sm" variant="success" @click="newScheduling()">
            NOVO AGENDAMENTO
        </b-button> 
      </b-row>        
      <b-row>
        <b-row>
            <b-col md="12" sm="12" class="ml-3 mt-3">
                Data do Agendamento:<b-input type="date" size="sm" v-model="dateSelected" @change="getSchedulings()"></b-input>
            </b-col>
        </b-row>
      </b-row>
      <b-row>
        <b-col md="12" sm="12">
            <b-card :class="`card-${theme} mt-2 mb-3`" header="Agendamentos" header-bg-variant="dark" header-text-variant="white">  
                <b-table class="mt-3" ref="selectableTable" head-variant="light" outlined hover :items="schedulings" :fields="fields" responsive="sm" id="my-table"
                        :per-page="perPage" :current-page="currentPage" striped fixed>
                    <template v-slot:cell(actions)="row">
                        <b-button v-show="!row.item.released && !row.item.cancellation && !row.item.answered" size="sm" variant="danger" @click="cancelScheduling(row.item, $event.target)" v-b-popover.hover.top="'Cancelar Agendamento'"> 
                            <i class="fa fa-ban"></i>
                        </b-button>
                        <b-button v-b-modal.modal-prevent-closing class="ml-2" v-show="!row.item.released && !row.item.cancellation && !row.item.answered" size="sm"
                            @click="selectDataOrigin(row.item)" variant="warning" v-b-popover.hover.top="'Alterar Profissional'">
                            <i class="fa fa-refresh"></i>
                        </b-button>
                        <b-button v-b-modal.modal-prevent-hour class="ml-2" v-show="!row.item.released && !row.item.cancellation && !row.item.answered" size="sm"
                            @click="selectScheduling(row.item)" variant="info" v-b-popover.hover.top="'Alterar Data/Horário'">
                            <i class="fa fa-clock-o"></i>
                        </b-button>
                        <b-text v-show="row.item.cancellation">
                            {{ row.item.cancellation ? row.item.cancellation.reason : ''  }}
                        </b-text>
                    </template>
                </b-table>
            </b-card>

            <Lookup ref="Lookup" title="Profissionais" :list="professionals" :fields="pUpdatefields" :invalidFeedback="invalidFeedback" 
                @event="updateScheduling" resultTable="professionalsUpdate"></Lookup>
        </b-col>
      </b-row>

      <b-modal :id="infoModalCancel.id" :title="infoModalCancel.title" @ok="handleCancelOk" size="sm">
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <b-form-group label="Informe o motivo do cancelamento" label-for="scheduling-cancel" invalid-feedback="Cancelamento">
                    <b-form-radio v-model="reason" name="some-radios-reason" value="Paciente Faltou">Paciente Faltou</b-form-radio>
                    <b-form-radio v-model="reason" name="some-radios-reason" value="Paciente Cancelou">Paciente Cancelou</b-form-radio>
                    <b-form-radio v-model="reason" name="some-radios-reason" value="Profissional Faltou">Profissional Faltou</b-form-radio>
                    <b-form-radio v-model="reason" name="some-radios-reason" value="Mudança de Dia/Horário">Mudança de Dia/Horário</b-form-radio>                               
                </b-form-group> 
            </form>
      </b-modal>

      <b-modal id="modal-prevent-hour" title="Mudança de data/horário" size="sm">
            <b-form-group label="Informe a nova data:">
                <b-calendar :min="min" :max="max" block locale="pt-BR" hide-header today-variant="success" label-help="" selected-variant="secondary" v-model="newDate"></b-calendar>
            </b-form-group>
            <b-form-group label="Informe o novo horário:">
                <b-form-timepicker placeholder="Selecione o horário" v-model="newHour"></b-form-timepicker>
            </b-form-group>

            <template #modal-footer="{ }">
                <b-button size="sm" variant="success" @click="selectHour()">
                    Ok
                </b-button>
            </template>
      </b-modal>

      <b-row class="ml-1">
        <b-pagination size="sm" pills v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table" class="page-item mt-2">
        </b-pagination>   
        <h5 class="badge1"><b-badge class="mt-2 ml-3" pill variant="success">Alta</b-badge></h5>  
        <h5 class="badge1"><b-badge class="mt-2 ml-3" pill variant="warning">Cortesia</b-badge></h5>
        <h5 class="badge2"><b-badge class="mt-2 ml-3" pill variant="danger">Cancelado</b-badge></h5>
      </b-row>         
    </div>
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, userKey } from '@/global'
import axios from 'axios'
import Lookup from '../default/Lookup'
import Loading from "../template/Loading"

export default {
    name: "Scheduling",
    components: { PageTitle, Lookup, Loading },
    data: function() {
        return {
           theme: null,
           isLoading: false,
           dateSelected: null,
           scale: null,
           schedulings: [],
           dataOrigin: null,
           infoModalCancel: {
                id: 'info-modal-cancel',
                title: '',
                content: ''
            }, 
           reason: null,
           schedulingCancel: null,
           fields: [                 
                { key: 'professional.name', label: 'Profissional', sortable: false },               
                { key: 'patient.name', label: 'Paciente', sortable: false },
                { key: 'patient.cpf', label: 'CPF', sortable: false },
                { key: 'patient.covenantName', label: 'Convênio', sortable: false },                
                { key: 'typeService', label: 'Tipo de Atendimento', sortable: false },
                { key: 'start', label: 'Horário', sortable: false },
                { key: 'actions', label: 'Ações' }
           ],
           pUpdatefields: [                 
                { key: 'name', label: 'Nome', sortable: true },
                { key: 'cpf', label: 'CPF', sortable: true, },            
                { key: 'advice.number', label: 'Registro', sortable: true, },            
            ],            
           currentPage: 1,
           perPage: 10,
           invalidFeedback: "",
           professionals: [],
           newHour: null,
           newDate: null,
           min: null,
           max: null,
        }        
    },
    computed: {
      rows() {
        return this.schedulings.length
      }
    },    
    methods: {
        getSchedulings() {
            this.isLoading = true

            const url = `${baseApiUrl}/scale/date/${this.dateSelected}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                this.scale = res.data
                
                this.schedulings = []

                if (!this.scale) {
                    this.scale = {
                      date: '',
                      professionals: [],
                    }
                } else {
                    for (let p of this.scale.professionals) {
                        for (let a of p.attendance) {
                            for (let pa of a.patients) {
                                let splBegin = []
                                try {
                                    splBegin = pa.start.split(":")
                                } catch(e) {
                                    pa.start = '00:00:00'
                                    splBegin = pa.start.split(":")
                                }

                                let beginMin = (Number(splBegin[0] * 60)) + Number(splBegin[1])

                                pa.startCompare = beginMin

                                let _cellVariants = {
                                    'professional.name': pa.cancellation ? 'danger' : pa.courtesy  ? 'warning' : pa.released ? 'success' : '',
                                    'patient.name': pa.cancellation ? 'danger' : pa.courtesy  ? 'warning' : pa.released ? 'success' : '',
                                    'patient.cpf': pa.cancellation ? 'danger' : pa.courtesy  ? 'warning' : pa.released ? 'success' : '',
                                    'patient.covenantName': pa.cancellation ? 'danger' : pa.courtesy  ? 'warning' : pa.released ? 'success' : '',
                                    typeService: pa.cancellation ? 'danger' : pa.courtesy  ? 'warning' : pa.released ? 'success' : '',
                                    start: pa.cancellation ? 'danger' : pa.courtesy  ? 'warning' : pa.released ? 'success' : '',
                                    actions: pa.cancellation ? 'danger' : pa.cancellation  ? 'warning' : pa.released ? 'success' : ''
                                }

                                if (pa.attendances) {
                                    this.schedulings.push({
                                        date: this.scale.date,
                                        professional: {
                                            name: p.name,
                                            adviceNumber: p.adviceNumber,
                                            cpf: p.cpf,
                                            checkin: p.checkin,
                                            checkout: p.checkout
                                        },
                                        patient: {
                                            _id: pa._id,
                                            name: pa.name,
                                            cpf: pa.cpf,
                                            email: pa.email,
                                            covenantName: pa.covenantName,
                                            covenantNumber: pa.covenantNumber,
                                            accommodation: {},
                                            admissionCode: '',
                                            admissionAttendanceNumber: '',
                                            start: pa.start,
                                            startDone: null,
                                            
                                            released: pa.released,
                                            finishDone: null,
                                            finish: pa.hourFinal,
                                            questions: pa.questions,
                                            courtesy: pa.courtesy,
                                            attendances: [
                                                {
                                                    name: pa.attendances[0].name,
                                                    morning: true,
                                                    evening: false,
                                                    night: false
                                                }
                                            ]
                                        },
                                        cancellation: pa.cancellation,
                                        start: pa.start,
                                        typeService: pa.attendances[0].name,
                                        answered: pa.attendances[0].answered,
                                        _cellVariants: _cellVariants
                                    })
                                }
                            }
                        }
                    }
                }

                this.schedulings.sort((a,b) => {
                    if (a.start > b.start) {
                        return 1;
                    }

                    if (a.start < b.start) {
                        return -1;
                    }
                    
                    return 0;
                })

                this.isLoading = false
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })
            })
        },
        newScheduling() {
            this.$router.push({name: 'schedulingPage', params: { },})
        },
        cancelScheduling(itemSelected, button) {
            this.$bvModal.msgBoxConfirm('Deseja cancelar o agendamento?', {
                title: 'Confirmação',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Sim',
                cancelTitle: 'Não',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            }).then(value => {            
                if (value) {                
                  this.schedulingCancel = {
                    date: itemSelected.date,  
                    adviceNumber: itemSelected.professional.adviceNumber,
                    cpfPatient: itemSelected.patient.cpf,
                    start: itemSelected.start,
                    typeService: itemSelected.typeService
                  }

                  this.infoModalCancel.title = 'Informe o motivo do cancelamento'
                  this.infoModalCancel.content = JSON.stringify(itemSelected, null)       
                  
                  this.$root.$emit('bv::show::modal', this.infoModalCancel.id, button)               
                }                
            }).catch()                       
        },
        updateScheduling(payload) {
            this.$bvModal.msgBoxConfirm('Deseja alterar o profissional do agendamento?', {
                title: 'Confirmação',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Sim',
                cancelTitle: 'Não',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            }).then(value => {            
                if (value) {                                      
                    this.scheduling = {
                        date: this.dataOrigin.date,
                        professionalOrigin: this.dataOrigin.professional,
                        professionalDestiny: payload,
                        patient: this.dataOrigin.patient,
                        hour: this.dataOrigin.start,
                        typeService: this.dataOrigin.typeService
                    }

                    this.isLoading = true

                    axios.post(`${baseApiUrl}/scale/updateProfessionalScheduling/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, this.scheduling).then(() => {
                        this.$toasted.global.defaultSuccess()
                        this.getSchedulings()
                        this.isLoading = false
                    }).catch(e => {
                        this.isLoading = false
                        this.$toasted.global.defaultError({ msg: e.response.data })
                    })
                }                
            }).catch()                       
        },        
        handleCancelOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit('Cancel')
        },  
        checkFormValidity(type) {
            if (type == 'Cancel') {
                if (this.reason == null) {
                    return false
                } else {
                    return true
                }
            } else {
                return true
            }
        },  
        handleSubmit(type) {
            // Exit when the form isn't valid
            if (!this.checkFormValidity(type)) {
                return 
            }

            if (type == 'Cancel')  {
                this.schedulingCancel.reason = this.reason

                this.isLoading = true

                axios.post(`${baseApiUrl}/scale/cancelScheduling/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, this.schedulingCancel).then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.getSchedulings()
                    this.isLoading = false
                }).catch(e => {
                    this.isLoading = false
                    this.$toasted.global.defaultError({ msg: e.response.data })
                })

                // Hide the modal manually
                this.$nextTick(() => {
                    this.$bvModal.hide(this.infoModalCancel.id)
                })
            }
        },
        selectDataOrigin(itemSelected) {
            this.dataOrigin = itemSelected
        },
        selectScheduling(itemSelected) {
            this.dataOrigin = itemSelected
        },
        loadSettings() {
            if (!JSON.parse(localStorage.getItem('__connect_user')).admin) {
                this.$toasted.global.defaultError({msg: 'Usuário sem permissão.'})
                this.$router.go(-1)
            }

            this.isLoading = true

            const url = `${baseApiUrl}/setting/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                this.settings = res.data   
                
                const max = Number(this.settings[0].params[15].value)
                const now = new Date()
                const today   = new Date(now.getFullYear(), now.getMonth(), now.getDate())
                const maxDate = new Date(today)
                maxDate.setDate(maxDate.getDate() + max)

                this.max = maxDate
                
                this.loadHours()

                this.isLoading = false
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })
            })  
        },
        selectHour() {
            this.$bvModal.msgBoxConfirm('Deseja alterar a Data/Horário do agendamento?', {
                title: 'Confirmação',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Sim',
                cancelTitle: 'Não',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            }).then(value => {
                if (value) {
                    if (!this.newHour) {
                        this.$toasted.global.defaultError({ msg: 'Hora no formato inválido.' })
                        return
                    }

                    if (!this.newHour.includes(':')) {
                        this.$toasted.global.defaultError({ msg: 'Hora no formato inválido.' })
                        return
                    }

                    if (!this.newDate) {
                        this.$toasted.global.defaultError({ msg: 'Data no formato inválido.' })
                        return
                    }

                    let hour = this.newHour.split(':')
                    let data = this.newDate

                    if (hour.length < 3) {
                        this.newHour = this.newHour + ':00'
                    }

                    this.dataOrigin.patient.start = this.newHour

                    this.scheduling = {
                        date: this.dataOrigin.date,
                        start: this.dataOrigin.start,
                        professionalOrigin: this.dataOrigin.professional,
                        newHour: this.newHour,
                        newDate: data,
                        patient: this.dataOrigin.patient,
                        typeService: this.dataOrigin.typeService
                    }

                    this.isLoading = true

                    axios.post(`${baseApiUrl}/scale/updateHourScheduling/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, this.scheduling).then(() => {
                        this.$toasted.global.defaultSuccess()
                        this.getSchedulings()
                        this.isLoading = false
                    }).catch(e => {
                        this.isLoading = false
                        this.$toasted.global.defaultError({ msg: e.response.data })
                    })
                }
            }).catch()
        }
    },
    mounted() {
        this.theme = localStorage.getItem('theme')

        const now     = new Date()
        const today   = new Date(now.getFullYear(), now.getMonth(), now.getDate())
        const minDate = new Date(today)
        minDate.setDate(minDate.getDate())
        this.min      = minDate

        this.loadSettings()
    }  
}
</script>

<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}

.card-violet {
  background-color: #f3eaff;
}
</style>