<template>
    <div class="attendancesDonePerCovenant">
    <PageTitle icon="fa fa-file-text" main="Atendimentos Realizados Por Convênio" sub="Relatório" />
        <div class="attendancesDonePerCovenant-page-tabs">
            <b-row>
                <b-col md="8" sm="12">
                    <b-card :class="`card-${theme}`" header="Informações" header-bg-variant="dark" header-text-variant="white">
                        <b-row>
                            <b-col md="3" sm="12">
                                <b-form-group label="Convênio:" label-for="covenant-fantasyname">
                                    <b-form-input size="sm" id="covenant-fantasyname" type="text" v-model="covenant.fantasyname" disabled/>
                                </b-form-group>
                            </b-col>   
                            <b-col md="1" sm="6" class="align-self-center mt-3">
                                <b-button size="sm" v-b-modal.modal-prevent-closing v-b-popover.hover.top="'Adiconar Convênio'">
                                    <i class="fa fa-search"></i>
                                </b-button>

                                <Lookup ref="Lookup" title="Convênios" :list="covenants" :fields="fields"
                                    :invalidFeedback="invalidFeedback" @event="addCovenant" resultTable="relAttendancesPerCovenant"></Lookup>
                            </b-col>                                                     
                            <b-col md="2" sm="12">
                                <b-form-group label="Data Inicial:" label-for="attendancesDonePerCovenant-dateInitial">
                                    <b-form-input size="sm" id="attendancesDonePerCovenant-dateInitial" type="date" v-model="dateInitial" required />
                                </b-form-group>
                            </b-col>
                            <b-col md="2" sm="12">
                                <b-form-group label="Data Final:" label-for="attendancesDonePerCovenant-dateFinal">
                                    <b-form-input size="sm" id="attendancesDonePerCovenant-dateFinal" type="date" v-model="dateFinal" required/>
                                </b-form-group>
                            </b-col>
                            <b-col md="2" sm="12">
                                <b-form-group label="Tipo:" v-slot="{ ariaDescribedby }">
                                    <b-form-radio-group :aria-describedby="ariaDescribedby" class="mt-1">
                                        <b-form-radio v-model="type" name="some-radios" value="PDF">PDF</b-form-radio>
                                        <b-form-radio v-model="type" name="some-radios" value="XLS">XLS</b-form-radio>
                                    </b-form-radio-group>
                                </b-form-group>
                            </b-col>                            
                            <b-col md="1" sm="12">
                                <b-button size="sm" variant="success" class="mt-4" @click="getAttendancesDone()">Processar</b-button>
                            </b-col>
                        </b-row>
                    </b-card>                    
                </b-col>                  
            </b-row>   
        </div>
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'
import Lookup from "../default/Lookup"
import Moment from 'moment'

export default {
    name: "RepAttendancesDone",
    components: { PageTitle, Lookup },    
    data: function() {
        return {
            theme: null,
            covenant: {},
            dateInitial: null,
            dateFinal: null,
            attendancesDonePerCovenant: null,
            listMonths: null,
            covenants: [],
            generatingPDF: false,
            textBtnPrint: 'GERAR',
            invalidFeedback: '',
            fields: [
                { key: "fantasyname", label: "Nome", sortable: true },
                { key: "cnpj", label: "CNPJ", sortable: true }
            ],
            type: null
        }
    },
    methods: {
        async showReportSynthetic() {
            let listTopics = []

            let cov = this.covenant.name ? `(${this.covenant.name})` : ''

            let list = []
            let fields = []

            fields.push({
                original: 'covenant',
                exibition: 'Convênio',
            })

            for (let m of this.listMonths) {
                fields.push({
                    original: m,
                    exibition: m,
                })
            }

            let finded = false

            for (let apc of this.attendancesDonePerCovenant) {
                finded = false

                for (let l of list) {
                    if (l.covenant == apc.covenant) {
                        finded = true
                        l[apc.date] = l[apc.date] ? `${l[apc.date]}, ${apc.typeService}: ${apc.count}` : `${apc.typeService}: ${apc.count}`                       
                        break
                    }
                }

                if (!finded) {
                    let obj = {
                        covenant: apc.covenant
                    }

                    for (let m of this.listMonths) {
                        if (m == apc.date) { 
                            obj[m] = `${apc.typeService}: ${apc.count}`
                        }
                    }

                    list.push(obj)
                }
            }
            
            let contentPdf = {
                title: `ATENDIMENTOS POR CONVÊNIO ${cov} - ${Moment(this.dateInitial).format('L')} a ${Moment(this.dateFinal).format('L')}`,
                format: 'A4',
                orientation: 'landscape',
                separate: false,
                content: [
                    {
                        type: 'table',
                        topicFields: listTopics,
                        fields: fields,
                        list: list
                    },
                ]
            }

            const url = `${baseApiUrl}/reports/pdfGenerator/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.post(url, contentPdf, {responseType: 'arraybuffer'}).then(res => {  
            var file = new Blob([res.data], {type: 'application/pdf'})
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);

                this.generatingPDF = false
                this.textBtnPrint = 'GERAR'    
            }).catch(showError)            
        },
        async getAttendancesDone() {
            let valid = true

            if (this.dateInitial == null || this.dateFinal == null) {
                this.$toasted.global.defaultError({msg: 'Informe a data inicial e final.'})
                valid = false
            } 

            Moment.locale('pt-br')

            let dtInitial = Moment(this.dateInitial)
            let dtFinal = Moment(this.dateFinal)  

            let diff = dtFinal.diff(dtInitial, 'days')

            if (diff < 0) {
                this.$toasted.global.defaultError({msg: 'Data final menor que data inicial.'})
                valid = false
            }
            
            if (!this.covenant) {
                this.$toasted.global.defaultError({msg: 'Selecione o convênio.'})
                valid = false
            }

            if (!this.type) {
                this.$toasted.global.defaultError({msg: 'Selecione o Tipo.'})
                valid = false
            }            

            if (valid) {         
                this.$bvModal.msgBoxConfirm('Deseja processar o período informado?', {
                    title: 'Confirmação',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Sim',
                    cancelTitle: 'Não',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                }).then(value => {            
                    if (value) {
                        this.generatingPDF = true
                        this.textBtnPrint = 'Aguarde...' 

                        let data = {
                            dateInitial: this.dateInitial,
                            dateFinal: this.dateFinal,
                            covenant: this.covenant,
                            type: this.type
                        }

                        const url = `${baseApiUrl}/repAttendancesPerCovenant/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`                
                        axios.post(url, data, {responseType: 'arraybuffer'}).then(res => {
                            if (this.type == 'PDF') {
                                var file = new Blob([res.data], {type: 'application/pdf'})
                                var fileURL = URL.createObjectURL(file)
                                window.open(fileURL)
                            } else {
                                const content = res.data
                                const element = document.createElement("a")
                                const file = new Blob([content], {type: "text/plain"})
                                element.href = URL.createObjectURL(file)
                                element.download = `relatorio_atendimentos_por_convenio_${this.covenant.fantasyname}.xlsx`
                                element.click()
                            }
                        }).catch(showError)        
                    }
                })
            }      
        },
        loadCovenants() {
            const url = `${baseApiUrl}/covenants/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                this.covenants = res.data
            }).catch(showError)
        },
        addCovenant(payload) {
            this.covenant = payload.covenantSelected
        },
    },
    mounted() {
        this.theme = localStorage.getItem('theme')

        this.loadCovenants()
    }
}
</script>

<style>

</style>