<template>
  <div class="satisfactionSurvey">
    <PageTitle icon="fa fa-file-text" main="Questionário" sub="QUESTIONARIO LOWER EXTREMITY FUNCTIOAL SCALE – LEFS" />
  
    <div>
      <b-card :class="`card-${theme}`">
        <b-button v-show="question.status == 0" @click="sendQuestions()" variant="info">Enviar</b-button>

        <b-form-group class="mt-3">
                    <b-button-group>
                        <b-button @click="numberQuestion = 1" :variant="(selected1 != null) ? 'success' : 'secondary'">1</b-button>
                        <b-button @click="numberQuestion = 2" :variant="(selected2 != null) ? 'success' : 'secondary'">2</b-button>
                        <b-button @click="numberQuestion = 3" :variant="(selected3 != null) ? 'success' : 'secondary'">3</b-button>
                        <b-button @click="numberQuestion = 4" :variant="(selected4 != null) ? 'success' : 'secondary'">4</b-button>
                        <b-button @click="numberQuestion = 5" :variant="(selected5 != null) ? 'success' : 'secondary'">5</b-button>                                                                         
                        <b-button @click="numberQuestion = 6" :variant="(selected6 != null) ? 'success' : 'secondary'">6</b-button>
                        <b-button @click="numberQuestion = 7" :variant="(selected7 != null) ? 'success' : 'secondary'">7</b-button>
                        <b-button @click="numberQuestion = 8" :variant="(selected8 != null) ? 'success' : 'secondary'">8</b-button>
                        <b-button @click="numberQuestion = 9" :variant="(selected9 != null) ? 'success' : 'secondary'">9</b-button>
                        <b-button @click="numberQuestion = 10" :variant="(selected10 != null) ? 'success' : 'secondary'">10</b-button>
                
                        <b-button @click="numberQuestion = 11" :variant="(selected11 != null) ? 'success' : 'secondary'">11</b-button>
                        <b-button @click="numberQuestion = 12" :variant="(selected12 != null) ? 'success' : 'secondary'">12</b-button>
                        <b-button @click="numberQuestion = 13" :variant="(selected13 != null) ? 'success' : 'secondary'">13</b-button>
                        <b-button @click="numberQuestion = 14" :variant="(selected14 != null) ? 'success' : 'secondary'">14</b-button>
                        <b-button @click="numberQuestion = 15" :variant="(selected15 != null) ? 'success' : 'secondary'">15</b-button>                                                                         
                        <b-button @click="numberQuestion = 16" :variant="(selected16 != null) ? 'success' : 'secondary'">16</b-button>
                        <b-button @click="numberQuestion = 17" :variant="(selected17 != null) ? 'success' : 'secondary'">17</b-button>
                        <b-button @click="numberQuestion = 18" :variant="(selected18 != null) ? 'success' : 'secondary'">18</b-button>
                        <b-button @click="numberQuestion = 19" :variant="(selected19 != null) ? 'success' : 'secondary'">19</b-button>
                        <b-button @click="numberQuestion = 20" :variant="(selected20 != null) ? 'success' : 'secondary'">20</b-button>
                    </b-button-group>
        </b-form-group>

        <b-form-group v-show="numberQuestion == 1" class="mt-3" label="">
            <b-form-group v-show="numberQuestion == 1" class="ml-5" label="1) Qualquer uma do seu trabalho normal, tarefas domésticas ou nas atividades escolares:">
                <b-form-radio v-model="selected1" name="some-radios-1" value="0">Extrema dificuldade ou incapacidade de realizar a atividade (0)</b-form-radio>
                <b-form-radio v-model="selected1" name="some-radios-1" value="1">Bastante dificuldade (1)</b-form-radio>
                <b-form-radio v-model="selected1" name="some-radios-1" value="2">Moderada dificuldade (2)</b-form-radio>
                <b-form-radio v-model="selected1" name="some-radios-1" value="3">Pouca dificuldade (3)</b-form-radio>
                <b-form-radio v-model="selected1" name="some-radios-1" value="4">Nenhuma dificuldade (4)</b-form-radio>
            </b-form-group>
        </b-form-group>

        <b-button v-show="numberQuestion == 1" variant="success" @click="numberQuestion = 2">Ok</b-button>

        <b-form-group v-show="numberQuestion == 2" class="mt-3" label="">
            <b-form-group v-show="numberQuestion == 2" class="ml-5" label="2) Seu passatempo predileto, atividades recreacionais ou esportivas:">
                <b-form-radio v-model="selected2" name="some-radios-2" value="0">Extrema dificuldade ou incapacidade de realizar a atividade (0)</b-form-radio>
                <b-form-radio v-model="selected2" name="some-radios-2" value="1">Bastante dificuldade (1)</b-form-radio>
                <b-form-radio v-model="selected2" name="some-radios-2" value="2">Moderada dificuldade (2)</b-form-radio>
                <b-form-radio v-model="selected2" name="some-radios-2" value="3">Pouca dificuldade (3)</b-form-radio>
                <b-form-radio v-model="selected2" name="some-radios-2" value="4">Nenhuma dificuldade (4)</b-form-radio>
            </b-form-group>                
        </b-form-group>

        <b-button v-show="numberQuestion == 2" variant="success" @click="numberQuestion = 3">Ok</b-button>

        <b-form-group v-show="numberQuestion == 3" class="mt-3" label="">
            <b-form-group v-show="numberQuestion == 3" class="ml-5" label="3) Entrar ou sair do banho:">
                <b-form-radio v-model="selected3" name="some-radios-3" value="0">Extrema dificuldade ou incapacidade de realizar a atividade (0)</b-form-radio>
                <b-form-radio v-model="selected3" name="some-radios-3" value="1">Bastante dificuldade (1)</b-form-radio>
                <b-form-radio v-model="selected3" name="some-radios-3" value="2">Moderada dificuldade (2)</b-form-radio>
                <b-form-radio v-model="selected3" name="some-radios-3" value="3">Pouca dificuldade (3)</b-form-radio>
                <b-form-radio v-model="selected3" name="some-radios-3" value="4">Nenhuma dificuldade (4)</b-form-radio>
            </b-form-group> 
        </b-form-group>     

        <b-button v-show="numberQuestion == 3" variant="success" @click="numberQuestion = 4">Ok</b-button>

        <b-form-group v-show="numberQuestion == 4" class="mt-3" label="">
            <b-form-group v-show="numberQuestion == 4" class="ml-5" label="4) Caminhar entre os quartos:">
                <b-form-radio v-model="selected4" name="some-radios-4" value="0">Extrema dificuldade ou incapacidade de realizar a atividade (0)</b-form-radio>
                <b-form-radio v-model="selected4" name="some-radios-4" value="1">Bastante dificuldade (1)</b-form-radio>
                <b-form-radio v-model="selected4" name="some-radios-4" value="2">Moderada dificuldade (2)</b-form-radio>
                <b-form-radio v-model="selected4" name="some-radios-4" value="3">Pouca dificuldade (3)</b-form-radio>
                <b-form-radio v-model="selected4" name="some-radios-4" value="4">Nenhuma dificuldade (4)</b-form-radio>
            </b-form-group>
        </b-form-group>

        <b-button v-show="numberQuestion == 4" variant="success" @click="numberQuestion = 5">Ok</b-button>

        <b-form-group v-show="numberQuestion == 5" class="mt-3" label="">
          <b-form-group v-show="numberQuestion == 5" class="ml-5" label="5) Calçar seus sapatos ou meias:">
                <b-form-radio v-model="selected5" name="some-radios-5" value="0">Extrema dificuldade ou incapacidade de realizar a atividade (0)</b-form-radio>
                <b-form-radio v-model="selected5" name="some-radios-5" value="1">Bastante dificuldade (1)</b-form-radio>
                <b-form-radio v-model="selected5" name="some-radios-5" value="2">Moderada dificuldade (2)</b-form-radio>
                <b-form-radio v-model="selected5" name="some-radios-5" value="3">Pouca dificuldade (3)</b-form-radio>
                <b-form-radio v-model="selected5" name="some-radios-5" value="4">Nenhuma dificuldade (4)</b-form-radio>
          </b-form-group>
        </b-form-group>

        <b-button v-show="numberQuestion == 5" variant="success" @click="numberQuestion = 6">Ok</b-button>

        <b-form-group v-show="numberQuestion == 6" class="mt-3" label="">
          <b-form-group v-show="numberQuestion == 6" class="ml-5" label="6) Agachar-se:">
                <b-form-radio v-model="selected6" name="some-radios-6" value="0">Extrema dificuldade ou incapacidade de realizar a atividade (0)</b-form-radio>
                <b-form-radio v-model="selected6" name="some-radios-6" value="1">Bastante dificuldade (1)</b-form-radio>
                <b-form-radio v-model="selected6" name="some-radios-6" value="2">Moderada dificuldade (2)</b-form-radio>
                <b-form-radio v-model="selected6" name="some-radios-6" value="3">Pouca dificuldade (3)</b-form-radio>
                <b-form-radio v-model="selected6" name="some-radios-6" value="4">Nenhuma dificuldade (4)</b-form-radio>
          </b-form-group>
        </b-form-group>
            
        <b-button v-show="numberQuestion == 6" variant="success" @click="numberQuestion = 7">Ok</b-button>                
     
        <b-form-group v-show="numberQuestion == 7" class="mt-3" label="">
          <b-form-group v-show="numberQuestion == 7" class="ml-5" label="7) Levantar um objeto, como uma sacola de compra do chão:">
                <b-form-radio v-model="selected7" name="some-radios-7" value="0">Extrema dificuldade ou incapacidade de realizar a atividade (0)</b-form-radio>
                <b-form-radio v-model="selected7" name="some-radios-7" value="1">Bastante dificuldade (1)</b-form-radio>
                <b-form-radio v-model="selected7" name="some-radios-7" value="2">Moderada dificuldade (2)</b-form-radio>
                <b-form-radio v-model="selected7" name="some-radios-7" value="3">Pouca dificuldade (3)</b-form-radio>
                <b-form-radio v-model="selected7" name="some-radios-7" value="4">Nenhuma dificuldade (4)</b-form-radio>
          </b-form-group> 
        </b-form-group> 

        <b-button v-show="numberQuestion == 7" variant="success" @click="numberQuestion = 8">Ok</b-button> 

        <b-form-group v-show="numberQuestion == 8" class="mt-3" label="">
          <b-form-group v-show="numberQuestion == 8" class="ml-5" label="8) Realizar atividades domésticas leves:">
                <b-form-radio v-model="selected8" name="some-radios-8" value="0">Extrema dificuldade ou incapacidade de realizar a atividade (0)</b-form-radio>
                <b-form-radio v-model="selected8" name="some-radios-8" value="1">Bastante dificuldade (1)</b-form-radio>
                <b-form-radio v-model="selected8" name="some-radios-8" value="2">Moderada dificuldade (2)</b-form-radio>
                <b-form-radio v-model="selected8" name="some-radios-8" value="3">Pouca dificuldade (3)</b-form-radio>
                <b-form-radio v-model="selected8" name="some-radios-8" value="4">Nenhuma dificuldade (4)</b-form-radio>
          </b-form-group>
        </b-form-group>    

        <b-button v-show="numberQuestion == 8" variant="success" @click="numberQuestion = 9">Ok</b-button>
        
        <b-form-group v-show="numberQuestion == 9" class="mt-3" label="">
          <b-form-group v-show="numberQuestion == 9" class="ml-5" label="9) Realizar atividades domésticas pesadas:">
                <b-form-radio v-model="selected9" name="some-radios-9" value="0">Extrema dificuldade ou incapacidade de realizar a atividade (0)</b-form-radio>
                <b-form-radio v-model="selected9" name="some-radios-9" value="1">Bastante dificuldade (1)</b-form-radio>
                <b-form-radio v-model="selected9" name="some-radios-9" value="2">Moderada dificuldade (2)</b-form-radio>
                <b-form-radio v-model="selected9" name="some-radios-9" value="3">Pouca dificuldade (3)</b-form-radio>
                <b-form-radio v-model="selected9" name="some-radios-9" value="4">Nenhuma dificuldade (4)</b-form-radio>
          </b-form-group>  
        </b-form-group> 
        
        <b-button v-show="numberQuestion == 9" variant="success" @click="numberQuestion = 10">Ok</b-button>

        <b-form-group v-show="numberQuestion == 10" class="mt-3" label="">
          <b-form-group v-show="numberQuestion == 10" class="ml-5" label="10) Entrar ou sair do carro:">
                <b-form-radio v-model="selected10" name="some-radios-10" value="0">Extrema dificuldade ou incapacidade de realizar a atividade (0)</b-form-radio>
                <b-form-radio v-model="selected10" name="some-radios-10" value="1">Bastante dificuldade (1)</b-form-radio>
                <b-form-radio v-model="selected10" name="some-radios-10" value="2">Moderada dificuldade (2)</b-form-radio>
                <b-form-radio v-model="selected10" name="some-radios-10" value="3">Pouca dificuldade (3)</b-form-radio>
                <b-form-radio v-model="selected10" name="some-radios-10" value="4">Nenhuma dificuldade (4)</b-form-radio>
          </b-form-group> 
        </b-form-group>

        <b-button v-show="numberQuestion == 10" variant="success" @click="numberQuestion = 11">Ok</b-button>      
          
        <b-form-group v-show="numberQuestion == 11" class="mt-3" label="">
          <b-form-group v-show="numberQuestion == 11" class="ml-5" label="11) Andar dois quarteirões:">
                <b-form-radio v-model="selected11" name="some-radios-11" value="0">Extrema dificuldade ou incapacidade de realizar a atividade (0)</b-form-radio>
                <b-form-radio v-model="selected11" name="some-radios-11" value="1">Bastante dificuldade (1)</b-form-radio>
                <b-form-radio v-model="selected11" name="some-radios-11" value="2">Moderada dificuldade (2)</b-form-radio>
                <b-form-radio v-model="selected11" name="some-radios-11" value="3">Pouca dificuldade (3)</b-form-radio>
                <b-form-radio v-model="selected11" name="some-radios-11" value="4">Nenhuma dificuldade (4)</b-form-radio>
          </b-form-group>
        </b-form-group>  

        <b-button v-show="numberQuestion == 11" variant="success" @click="numberQuestion = 12">Ok</b-button> 
         
        <b-form-group v-show="numberQuestion == 12" class="mt-3" label="">
          <b-form-group v-show="numberQuestion == 12" class="ml-5" label="12) Andar aproximadamente 1,5 Km:">
                <b-form-radio v-model="selected12" name="some-radios-12" value="0">Extrema dificuldade ou incapacidade de realizar a atividade (0)</b-form-radio>
                <b-form-radio v-model="selected12" name="some-radios-12" value="1">Bastante dificuldade (1)</b-form-radio>
                <b-form-radio v-model="selected12" name="some-radios-12" value="2">Moderada dificuldade (2)</b-form-radio>
                <b-form-radio v-model="selected12" name="some-radios-12" value="3">Pouca dificuldade (3)</b-form-radio>
                <b-form-radio v-model="selected12" name="some-radios-12" value="4">Nenhuma dificuldade (4)</b-form-radio>
          </b-form-group> 
        </b-form-group>
            
        <b-button v-show="numberQuestion == 12" variant="success" @click="numberQuestion = 13">Ok</b-button>
        
        <b-form-group v-show="numberQuestion == 13" class="mt-3" label="">
          <b-form-group v-show="numberQuestion == 13" class="ml-5" label="13) Subir ou descer 10 degraus (aproximadamente um lance de escadas):">
                <b-form-radio v-model="selected13" name="some-radios-13" value="0">Extrema dificuldade ou incapacidade de realizar a atividade (0)</b-form-radio>
                <b-form-radio v-model="selected13" name="some-radios-13" value="1">Bastante dificuldade (1)</b-form-radio>
                <b-form-radio v-model="selected13" name="some-radios-13" value="2">Moderada dificuldade (2)</b-form-radio>
                <b-form-radio v-model="selected13" name="some-radios-13" value="3">Pouca dificuldade (3)</b-form-radio>
                <b-form-radio v-model="selected13" name="some-radios-13" value="4">Nenhuma dificuldade (4)</b-form-radio>
          </b-form-group> 
        </b-form-group> 

        <b-button v-show="numberQuestion == 13" variant="success" @click="numberQuestion = 14">Ok</b-button>

        <b-form-group v-show="numberQuestion == 14" class="mt-3" label="">
          <b-form-group v-show="numberQuestion == 14" class="ml-5" label="14) Ficar em pé durante uma hora:">
                <b-form-radio v-model="selected14" name="some-radios-14" value="0">Extrema dificuldade ou incapacidade de realizar a atividade (0)</b-form-radio>
                <b-form-radio v-model="selected14" name="some-radios-14" value="1">Bastante dificuldade (1)</b-form-radio>
                <b-form-radio v-model="selected14" name="some-radios-14" value="2">Moderada dificuldade (2)</b-form-radio>
                <b-form-radio v-model="selected14" name="some-radios-14" value="3">Pouca dificuldade (3)</b-form-radio>
                <b-form-radio v-model="selected14" name="some-radios-14" value="4">Nenhuma dificuldade (4)</b-form-radio>
          </b-form-group>
        </b-form-group>

        <b-button v-show="numberQuestion == 14" variant="success" @click="numberQuestion = 15">Ok</b-button>    

        <b-form-group v-show="numberQuestion == 15" class="mt-3" label="">
          <b-form-group v-show="numberQuestion == 15" class="ml-5" label="15) Ficar sentado durante uma hora:">
                <b-form-radio v-model="selected15" name="some-radios-15" value="0">Extrema dificuldade ou incapacidade de realizar a atividade (0)</b-form-radio>
                <b-form-radio v-model="selected15" name="some-radios-15" value="1">Bastante dificuldade (1)</b-form-radio>
                <b-form-radio v-model="selected15" name="some-radios-15" value="2">Moderada dificuldade (2)</b-form-radio>
                <b-form-radio v-model="selected15" name="some-radios-15" value="3">Pouca dificuldade (3)</b-form-radio>
                <b-form-radio v-model="selected15" name="some-radios-15" value="4">Nenhuma dificuldade (4)</b-form-radio>
          </b-form-group> 
        </b-form-group> 

        <b-button v-show="numberQuestion == 15" variant="success" @click="numberQuestion = 16">Ok</b-button>

        <b-form-group v-show="numberQuestion == 16" class="mt-3" label=""> 
          <b-form-group v-show="numberQuestion == 16" class="ml-5" label="16) Correr em terreno plano:">
                <b-form-radio v-model="selected16" name="some-radios-16" value="0">Extrema dificuldade ou incapacidade de realizar a atividade (0)</b-form-radio>
                <b-form-radio v-model="selected16" name="some-radios-16" value="1">Bastante dificuldade (1)</b-form-radio>
                <b-form-radio v-model="selected16" name="some-radios-16" value="2">Moderada dificuldade (2)</b-form-radio>
                <b-form-radio v-model="selected16" name="some-radios-16" value="3">Pouca dificuldade (3)</b-form-radio>
                <b-form-radio v-model="selected16" name="some-radios-16" value="4">Nenhuma dificuldade (4)</b-form-radio>
          </b-form-group> 
        </b-form-group>

        <b-button v-show="numberQuestion == 16" variant="success" @click="numberQuestion = 17">Ok</b-button>    

        <b-form-group v-show="numberQuestion == 17" class="mt-3" label="">
          <b-form-group v-show="numberQuestion == 17" class="ml-5" label="17) Correr em terreno irregular:">
                <b-form-radio v-model="selected17" name="some-radios-17" value="0">Extrema dificuldade ou incapacidade de realizar a atividade (0)</b-form-radio>
                <b-form-radio v-model="selected17" name="some-radios-17" value="1">Bastante dificuldade (1)</b-form-radio>
                <b-form-radio v-model="selected17" name="some-radios-17" value="2">Moderada dificuldade (2)</b-form-radio>
                <b-form-radio v-model="selected17" name="some-radios-17" value="3">Pouca dificuldade (3)</b-form-radio>
                <b-form-radio v-model="selected17" name="some-radios-17" value="4">Nenhuma dificuldade (4)</b-form-radio>
          </b-form-group> 
        </b-form-group>

        <b-button v-show="numberQuestion == 17" variant="success" @click="numberQuestion = 18">Ok</b-button>

        <b-form-group v-show="numberQuestion == 18" class="mt-3" label="">
          <b-form-group v-show="numberQuestion == 18" class="ml-5" label="18) Mudar de direção enquanto corre rapidamente:">
                <b-form-radio v-model="selected18" name="some-radios-18" value="0">Extrema dificuldade ou incapacidade de realizar a atividade (0)</b-form-radio>
                <b-form-radio v-model="selected18" name="some-radios-18" value="1">Bastante dificuldade (1)</b-form-radio>
                <b-form-radio v-model="selected18" name="some-radios-18" value="2">Moderada dificuldade (2)</b-form-radio>
                <b-form-radio v-model="selected18" name="some-radios-18" value="3">Pouca dificuldade (3)</b-form-radio>
                <b-form-radio v-model="selected18" name="some-radios-18" value="4">Nenhuma dificuldade (4)</b-form-radio>
          </b-form-group> 
        </b-form-group>  

        <b-button v-show="numberQuestion == 18" variant="success" @click="numberQuestion = 19">Ok</b-button>   

        <b-form-group v-show="numberQuestion == 19" class="mt-3" label="">
          <b-form-group v-show="numberQuestion == 19" class="ml-5" label="19) Pular:">
                <b-form-radio v-model="selected19" name="some-radios-19" value="0">Extrema dificuldade ou incapacidade de realizar a atividade (0)</b-form-radio>
                <b-form-radio v-model="selected19" name="some-radios-19" value="1">Bastante dificuldade (1)</b-form-radio>
                <b-form-radio v-model="selected19" name="some-radios-19" value="2">Moderada dificuldade (2)</b-form-radio>
                <b-form-radio v-model="selected19" name="some-radios-19" value="3">Pouca dificuldade (3)</b-form-radio>
                <b-form-radio v-model="selected19" name="some-radios-19" value="4">Nenhuma dificuldade (4)</b-form-radio>
          </b-form-group> 
        </b-form-group> 

        <b-button v-show="numberQuestion == 19" variant="success" @click="numberQuestion = 20">Ok</b-button>

        <b-form-group v-show="numberQuestion == 20" class="mt-3" label="">
          <b-form-group v-show="numberQuestion == 20" class="ml-5" label="20) Rolar na cama:">
                <b-form-radio v-model="selected20" name="some-radios-20" value="0">Extrema dificuldade ou incapacidade de realizar a atividade (0)</b-form-radio>
                <b-form-radio v-model="selected20" name="some-radios-20" value="1">Bastante dificuldade (1)</b-form-radio>
                <b-form-radio v-model="selected20" name="some-radios-20" value="2">Moderada dificuldade (2)</b-form-radio>
                <b-form-radio v-model="selected20" name="some-radios-20" value="3">Pouca dificuldade (3)</b-form-radio>
                <b-form-radio v-model="selected20" name="some-radios-20" value="4">Nenhuma dificuldade (4)</b-form-radio>
          </b-form-group> 
        </b-form-group>
      </b-card>
    </div> 
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'
import Moment from 'moment'

export default {
    name: "SatisfactionSurvey",
    components: { PageTitle },
    data() {
      return {
        patient: null,
        question: null,
        theme: null,
        numberQuestion: 1,
        selected1: null,
        selected2: null,
        selected3: null,
        selected4: null,
        selected5: null,
        selected6: null,
        selected7: null,
        selected8: null,
        selected9: null,
        selected10: null,
        selected11: null,
        selected12: null,
        selected13: null,
        selected14: null,
        selected15: null,
        selected16: null,
        selected17: null,
        selected18: null,
        selected19: null,
        selected20: null,
      }
    },    
    methods: {
      sendQuestions() {
          Moment.locale('pt-br')  
          
          axios.get(`${baseApiUrl}/patient/getById/${this.patient._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`).then(res => {
            this.patient = res.data

            let data = {}
            let index = 0

            for (let q of this.patient.questions) {
              if (q.type == 2 && q.name == this.question.name && q.answers.length == 0) {
                q.answers = []

                q.status = 1
                q.answers.push(
                  {
                    'dateAnswers': Moment(new Date()).format('L')
                  }, 
                  {
                    '1': this.selected1,
                    '2': this.selected2,
                    '3': this.selected3,
                    '4': this.selected4,
                    '5': this.selected5,
                    '6': this.selected6,
                    '7': this.selected7,
                    '8': this.selected8,
                    '9': this.selected9,
                    '10': this.selected10,
                    '11': this.selected11,
                    '12': this.selected12,
                    '13': this.selected13,
                    '14': this.selected14,
                    '15': this.selected15,
                    '16': this.selected16,
                    '17': this.selected17,
                    '18': this.selected18,
                    '19': this.selected19,
                    '20': this.selected20,
                  }                                                                                                                                                                                                                                                                                                                                                                                                                                                            
                ) 

                data.index = index
                data.answers = q.answers
              }

              index += 1
            }        
            
            data.idPatient = this.patient._id

            axios.post(`${baseApiUrl}/patient/setTestQuestion/${this.patient._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, data)
              .then(() => {
                  this.$store.commit('setResultPatient', this.patient)

                  this.$toasted.global.defaultSuccess()
                  this.$router.go(-1)
              })  
              .catch(showError) 
          }).catch((e) => {
            console.log(e)
            showError
          })
        // }
      }
    },
    created() {
      this.theme = localStorage.getItem('theme')

      this.patient = this.$route.params.questionSelected.patient
      this.question = this.$route.params.questionSelected

      if (this.$route.params.questionSelected.answers.length > 0) {
        this.selected1 = this.$route.params.questionSelected.answers[1]['1']
        this.selected2 = this.$route.params.questionSelected.answers[1]['2']
        this.selected3 = this.$route.params.questionSelected.answers[1]['3']
        this.selected4 = this.$route.params.questionSelected.answers[1]['4']
        this.selected5 = this.$route.params.questionSelected.answers[1]['5']
        this.selected6 = this.$route.params.questionSelected.answers[1]['6']
        this.selected7 = this.$route.params.questionSelected.answers[1]['7']
        this.selected8 = this.$route.params.questionSelected.answers[1]['8']
        this.selected9 = this.$route.params.questionSelected.answers[1]['9']
        this.selected10 = this.$route.params.questionSelected.answers[1]['10']
        this.selected11 = this.$route.params.questionSelected.answers[1]['11']
        this.selected12 = this.$route.params.questionSelected.answers[1]['12']
        this.selected13 = this.$route.params.questionSelected.answers[1]['13']
        this.selected14 = this.$route.params.questionSelected.answers[1]['14']
        this.selected15 = this.$route.params.questionSelected.answers[1]['15']
        this.selected16 = this.$route.params.questionSelected.answers[1]['16']
        this.selected17 = this.$route.params.questionSelected.answers[1]['17']
        this.selected18 = this.$route.params.questionSelected.answers[1]['18']
        this.selected19 = this.$route.params.questionSelected.answers[1]['19']
        this.selected20 = this.$route.params.questionSelected.answers[1]['20']        
      }
    }
}
</script>

<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}

.card-violet {
  background-color: #f3eaff;
}
</style>