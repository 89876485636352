<template>
    <Loading v-if="isLoading" />
    <div v-else class="event-admin">
        <PageTitle icon="fa fa-fire" main="Cadastro de Ocorrências" sub />                  
        <b-form name="form1">            
            <input id="event-id" type="hidden" v-model="event.id"/>
            <b-row name="tsName">
                <b-col md="12" sm="12">
                    <b-card :class="`card-${theme}`" bg-variant="default" header="Informações Gerais" header-bg-variant="dark" header-text-variant="white">
                        <b-row>
                            <b-col md="6" sm="12">
                                <b-form-group label="Nome:" label-for="event-name" class="ml-3 mr-3">
                                    <b-form-input size="sm" id="event-name" type="text"
                                        v-model="event.name" required                       
                                        placeholder="Informe o Nome da Ocorrência..." />
                                </b-form-group>
                            </b-col>
                            <b-col md="6" sm="12">
                                <b-form-group label="Tipo" class="mt-2">
                                    <b-form-radio-group>
                                        <b-form-radio v-model="event.type" :aria-describedby="ariaDescribedby" name="some-radios" value="Atendimento">Atendimento</b-form-radio>
                                        <b-form-radio v-model="event.type" :aria-describedby="ariaDescribedby" name="some-radios" value="QrCode">QrCode</b-form-radio>
                                    </b-form-radio-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </b-form>

        <b-button size="sm" variant="success" @click="save" class="mt-3">SALVAR</b-button>  
        <b-button size="sm" variant="danger" @click="cancel" class="mt-3 ml-2">CANCELAR</b-button>          
    </div>
</template>

<script>
import PageTitle from "../../template/PageTitle";
import { baseApiUrl, userKey } from '@/global'
import axios from 'axios'
import Loading from "../../template/Loading"

export default {
    name: "EventPage",
    components: { PageTitle, Loading },
    data: function() {
      return { 
        theme: null,
        isLoading: false,
        event: {},
      }    
    },
    methods: {           
        save() {
            this.event.name = this.event.name.toUpperCase()

            const method = this.event._id ? 'put' : 'post'
            const id = this.event._id ? `/${this.event._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}` : `/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            
            this.isLoading = true

            axios[method](`${baseApiUrl}/event${id}`, this.event).then(() => {
                this.isLoading = false
                this.$toasted.global.defaultSuccess()
                this.$router.go(-1)
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })
            })
        },  
        cancel() {
            this.$router.go(-1)
        },
    },
    mounted() {
        this.theme = localStorage.getItem('theme')

        if (this.$route.params.eventSelected) {
            this.event = this.$route.params.eventSelected            
        }
    }
}     
</script>

<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}

.card-violet {
  background-color: #f3eaff;
}

.card-header {
    height: 38px;
    font-size: 14px;    
}

.card-body {
    font-size: 14px;
}

.calendar-btn {
    font-size: 0.30;
}

.table th {
  padding: 0.45rem;
}

.table td {
  padding: 0.45rem;
  vertical-align: middle;
}
</style>