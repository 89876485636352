<template>
  <div class="satisfactionSurvey">
    <PageTitle icon="fa fa-file-text" main="Questionário" sub="MEDICAL OUTCOMES SHORT-FORM HEALTH SURVEY (SF-36)" />
  
    <div>
      <b-card :class="`card-${theme}`">
        <b-button v-show="question.status == 0" @click="sendQuestions()" variant="info">Enviar</b-button>

        <b-form-group class="mt-3">
                    <b-button-group>
                        <b-button @click="numberQuestion = 1" :variant="(selected1 != null) ? 'success' : 'secondary'">1</b-button>
                        <b-button @click="numberQuestion = 2" :variant="(selected2 != null) ? 'success' : 'secondary'">2</b-button>
                        <b-button @click="numberQuestion = 3" :variant="(selected3_1 != null ||
                                                                         selected3_2 != null ||
                                                                         selected3_3 != null ||
                                                                         selected3_4 != null ||
                                                                         selected3_4 != null ||
                                                                         selected3_5 != null ||
                                                                         selected3_6 != null ||
                                                                         selected3_7 != null ||
                                                                         selected3_8 != null ||
                                                                         selected3_9 != null ||
                                                                         selected3_10 != null ) ? 'success' : 'secondary'">3</b-button>
                        <b-button @click="numberQuestion = 4" :variant="(selected4_1 != null ||
                                                                         selected4_2 != null ||
                                                                         selected4_3 != null ||  
                                                                         selected4_4 != null ) ? 'success' : 'secondary'">4</b-button>
                        <b-button @click="numberQuestion = 5" :variant="(selected5_1 != null ||
                                                                         selected5_2 != null || 
                                                                         selected5_3 != null ) ? 'success' : 'secondary'">5</b-button>                                                                         
                        <b-button @click="numberQuestion = 6" :variant="(selected6 != null ) ? 'success' : 'secondary'">6</b-button>
                        <b-button @click="numberQuestion = 7" :variant="(selected7 != null ) ? 'success' : 'secondary'">7</b-button>
                        <b-button @click="numberQuestion = 8" :variant="(selected8 != null ) ? 'success' : 'secondary'">8</b-button>
                        <b-button @click="numberQuestion = 9" :variant="(selected9_1 != null ||
                                                                         selected9_2 != null ||
                                                                         selected9_3 != null ||  
                                                                         selected9_4 != null ||
                                                                         selected9_5 != null ||
                                                                         selected9_6 != null ||
                                                                         selected9_7 != null ||
                                                                         selected9_8 != null ||
                                                                         selected9_9 != null ) ? 'success' : 'secondary'">9</b-button>
                        <b-button @click="numberQuestion = 10" :variant="(selected10 != null) ? 'success' : 'secondary'">10</b-button>
                        <b-button @click="numberQuestion = 11" :variant="(selected11_1 != null ||
                                                                         selected11_2 != null ||
                                                                         selected11_3 != null ||  
                                                                         selected11_4 != null) ? 'success' : 'secondary'">11</b-button>
                    </b-button-group>
        </b-form-group>

        <b-form-group v-show="numberQuestion == 1" class="mt-3" label="">
          <b-form-group v-show="numberQuestion == 1" class="ml-5" label="1) Em geral você diria que sua saúde é:">
                <b-form-radio v-model="selected1" name="some-radios-1" value="1">Excelente</b-form-radio>
                <b-form-radio v-model="selected1" name="some-radios-1" value="2">Muito Boa</b-form-radio>
                <b-form-radio v-model="selected1" name="some-radios-1" value="3">Boa</b-form-radio>
                <b-form-radio v-model="selected1" name="some-radios-1" value="4">Ruim</b-form-radio>
                <b-form-radio v-model="selected1" name="some-radios-1" value="5">Muito Ruim</b-form-radio>
          </b-form-group>
        </b-form-group>

        <b-button v-show="numberQuestion == 1" variant="success" @click="numberQuestion = 2">Ok</b-button>

        <b-form-group v-show="numberQuestion == 2" class="ml-5" label="2) Comparada há um ano atrás, como você se classificaria sua idade em geral, agora?">
              <b-form-radio v-model="selected2" name="some-radios-2" value="1">Muito Melhor</b-form-radio>
              <b-form-radio v-model="selected2" name="some-radios-2" value="2">Um Pouco Melhor</b-form-radio>
              <b-form-radio v-model="selected2" name="some-radios-2" value="3">Quase a Mesma </b-form-radio>
              <b-form-radio v-model="selected2" name="some-radios-2" value="4">Um Pouco Pior</b-form-radio>
              <b-form-radio v-model="selected2" name="some-radios-2" value="5">Muito Pior</b-form-radio>
        </b-form-group>

        <b-button v-show="numberQuestion == 2" variant="success" @click="numberQuestion = 3">Ok</b-button>

        <b-form-group v-show="numberQuestion == 3" class="mt-3" label="3) Os seguintes itens são sobre atividades que você poderia fazer atualmente durante um dia comum. Devido à sua saúde, você teria dificuldade para fazer estas atividades? Neste caso, quando?">
          <b-form-group v-show="numberQuestion == 3" class="ml-5" label="Atividades Rigorosas, que exigem muito esforço, tais como correr,  levantar objetos pesados, participar em esportes árduos.">
                <b-form-radio v-model="selected3_1" name="some-radios-3_1" value="1">Sim, dificulta muito</b-form-radio>
                <b-form-radio v-model="selected3_1" name="some-radios-3_1" value="2">Sim, dificulta um pouco</b-form-radio>
                <b-form-radio v-model="selected3_1" name="some-radios-3_1" value="3">Não, não dificulta de modo algum</b-form-radio>
          </b-form-group>
          <b-form-group v-show="numberQuestion == 3" class="ml-5" label="Atividades moderadas, tais como mover uma mesa, passar aspirador de pó, jogar bola, varrer a casa.">
                <b-form-radio v-model="selected3_2" name="some-radios-3_2" value="1">Sim, dificulta muito</b-form-radio>
                <b-form-radio v-model="selected3_2" name="some-radios-3_2" value="2">Sim, dificulta um pouco</b-form-radio>
                <b-form-radio v-model="selected3_2" name="some-radios-3_2" value="3">Não, não dificulta de modo algum</b-form-radio>
          </b-form-group>  
          <b-form-group v-show="numberQuestion == 3" class="ml-5" label="Levantar ou carregar mantimentos">
                <b-form-radio v-model="selected3_3" name="some-radios-3_3" value="1">Sim, dificulta muito</b-form-radio>
                <b-form-radio v-model="selected3_3" name="some-radios-3_3" value="2">Sim, dificulta um pouco</b-form-radio>
                <b-form-radio v-model="selected3_3" name="some-radios-3_3" value="3">Não, não dificulta de modo algum</b-form-radio>
          </b-form-group>
          <b-form-group v-show="numberQuestion == 3" class="ml-5" label="Subir vários lances de escada">
                <b-form-radio v-model="selected3_4" name="some-radios-3_4" value="1">Sim, dificulta muito</b-form-radio>
                <b-form-radio v-model="selected3_4" name="some-radios-3_4" value="2">Sim, dificulta um pouco</b-form-radio>
                <b-form-radio v-model="selected3_4" name="some-radios-3_4" value="3">Não, não dificulta de modo algum</b-form-radio>
          </b-form-group> 
          <b-form-group v-show="numberQuestion == 3" class="ml-5" label="Subir um lance de escada.">
                <b-form-radio v-model="selected3_5" name="some-radios-3_5" value="1">Sim, dificulta muito</b-form-radio>
                <b-form-radio v-model="selected3_5" name="some-radios-3_5" value="2">Sim, dificulta um pouco</b-form-radio>
                <b-form-radio v-model="selected3_5" name="some-radios-3_5" value="3">Não, não dificulta de modo algum</b-form-radio>
          </b-form-group> 
          <b-form-group v-show="numberQuestion == 3" class="ml-5" label="Curvar-se, ajoelhar-se ou dobrar-se">
                <b-form-radio v-model="selected3_6" name="some-radios-3_6" value="1">Sim, dificulta muito</b-form-radio>
                <b-form-radio v-model="selected3_6" name="some-radios-3_6" value="2">Sim, dificulta um pouco</b-form-radio>
                <b-form-radio v-model="selected3_6" name="some-radios-3_6" value="3">Não, não dificulta de modo algum</b-form-radio>
          </b-form-group>
          <b-form-group v-show="numberQuestion == 3" class="ml-5" label="Andar mais de 1 quilômetro">
                <b-form-radio v-model="selected3_7" name="some-radios-3_7" value="1">Sim, dificulta muito</b-form-radio>
                <b-form-radio v-model="selected3_7" name="some-radios-3_7" value="2">Sim, dificulta um pouco</b-form-radio>
                <b-form-radio v-model="selected3_7" name="some-radios-3_7" value="3">Não, não dificulta de modo algum</b-form-radio>
          </b-form-group>  
          <b-form-group v-show="numberQuestion == 3" class="ml-5" label="Andar vários quarteirões">
                <b-form-radio v-model="selected3_8" name="some-radios-3_8" value="1">Sim, dificulta muito</b-form-radio>
                <b-form-radio v-model="selected3_8" name="some-radios-3_8" value="2">Sim, dificulta um pouco</b-form-radio>
                <b-form-radio v-model="selected3_8" name="some-radios-3_8" value="3">Não, não dificulta de modo algum</b-form-radio>
          </b-form-group>
          <b-form-group v-show="numberQuestion == 3" class="ml-5" label="Andar um quarteirão">
                <b-form-radio v-model="selected3_9" name="some-radios-3_9" value="1">Sim, dificulta muito</b-form-radio>
                <b-form-radio v-model="selected3_9" name="some-radios-3_9" value="2">Sim, dificulta um pouco</b-form-radio>
                <b-form-radio v-model="selected3_9" name="some-radios-3_9" value="3">Não, não dificulta de modo algum</b-form-radio>
          </b-form-group> 
          <b-form-group v-show="numberQuestion == 3" class="ml-5" label="Tomar banho ou vestir-se">
                <b-form-radio v-model="selected3_10" name="some-radios-3_10" value="1">Sim, dificulta muito</b-form-radio>
                <b-form-radio v-model="selected3_10" name="some-radios-3_10" value="2">Sim, dificulta um pouco</b-form-radio>
                <b-form-radio v-model="selected3_10" name="some-radios-3_10" value="3">Não, não dificulta de modo algum</b-form-radio>
          </b-form-group>           
        </b-form-group>

        <b-button v-show="numberQuestion == 3" variant="success" @click="numberQuestion = 4">Ok</b-button>

        <b-form-group v-show="numberQuestion == 4" class="mt-3" label="4) Durante as últimas 4 semanas, você teve algum dos seguintes problemas com seu trabalho ou com alguma atividade regular, como conseqüência de sua saúde física?">
          <b-form-group v-show="numberQuestion == 4" class="ml-5" label="Você diminui a quantidade de tempo que se dedicava ao seu trabalho ou a outras atividades?">
            <b-form-radio v-model="selected4_1" name="some-radios-4_1" value="1">Sim</b-form-radio>
            <b-form-radio v-model="selected4_1" name="some-radios-4_1" value="2">Não</b-form-radio>
          </b-form-group>
          <b-form-group v-show="numberQuestion == 4" class="ml-5" label="Realizou menos tarefas do que você gostaria?">
            <b-form-radio v-model="selected4_2" name="some-radios-4_2" value="1">Sim</b-form-radio>
            <b-form-radio v-model="selected4_2" name="some-radios-4_2" value="2">Não</b-form-radio>
          </b-form-group>
          <b-form-group v-show="numberQuestion == 4" class="ml-5" label="Esteve limitado no seu tipo de trabalho ou a outras atividades.">
            <b-form-radio v-model="selected4_3" name="some-radios-4_3" value="1">Sim</b-form-radio>
            <b-form-radio v-model="selected4_3" name="some-radios-4_3" value="2">Não</b-form-radio>
          </b-form-group>
          <b-form-group v-show="numberQuestion == 4" class="ml-5" label="Teve dificuldade de fazer seu trabalho ou outras atividades (p. ex. necessitou de um esforço extra).">
            <b-form-radio v-model="selected4_4" name="some-radios-4_4" value="1">Sim</b-form-radio>
            <b-form-radio v-model="selected4_4" name="some-radios-4_4" value="2">Não</b-form-radio>
          </b-form-group>
        </b-form-group>   
        
        <b-button v-show="numberQuestion == 4" variant="success" @click="numberQuestion = 5">Ok</b-button>

        <b-form-group v-show="numberQuestion == 5" class="mt-3" label="5) Durante as últimas 4 semanas, você teve algum dos seguintes problemas com seu trabalho ou outra atividade regular diária, como conseqüência de algum problema emocional (como se sentir deprimido ou ansioso)?">
          <b-form-group v-show="numberQuestion == 5" class="ml-5" label="Você diminui a quantidade de tempo que se dedicava ao seu trabalho ou a outras atividades?">
            <b-form-radio v-model="selected5_1" name="some-radios-5_1" value="1">Sim</b-form-radio>
            <b-form-radio v-model="selected5_1" name="some-radios-5_1" value="2">Não</b-form-radio>
          </b-form-group>
          <b-form-group v-show="numberQuestion == 5" class="ml-5" label="Realizou menos tarefas do que você gostaria?">
            <b-form-radio v-model="selected5_2" name="some-radios-5_2" value="1">Sim</b-form-radio>
            <b-form-radio v-model="selected5_2" name="some-radios-5_2" value="2">Não</b-form-radio>
          </b-form-group>
          <b-form-group v-show="numberQuestion == 5" class="ml-5" label="Não realizou ou fez qualquer das atividades com tanto cuidado como geralmente faz.">
            <b-form-radio v-model="selected5_3" name="some-radios-5_3" value="1">Sim</b-form-radio>
            <b-form-radio v-model="selected5_3" name="some-radios-5_3" value="2">Não</b-form-radio>
          </b-form-group>
        </b-form-group>

        <b-button v-show="numberQuestion == 5" variant="success" @click="numberQuestion = 6">Ok</b-button>
        
        <b-form-group v-show="numberQuestion == 6" class="mt-3" label="">
          <b-form-group v-show="numberQuestion == 6" class="ml-5" label="6) Durante as últimas 4 semanas, de que maneira sua saúde física ou problemas emocionais interferiram nas suas atividades sociais normais, em relação à família, amigos ou em grupo?">
            <b-form-radio v-model="selected6" name="some-radios-6" value="1">De forma nenhuma</b-form-radio>
            <b-form-radio v-model="selected6" name="some-radios-6" value="2">Ligeiramente</b-form-radio>
            <b-form-radio v-model="selected6" name="some-radios-6" value="3">Moderadamente</b-form-radio>
            <b-form-radio v-model="selected6" name="some-radios-6" value="4">Bastante</b-form-radio>
            <b-form-radio v-model="selected6" name="some-radios-6" value="5">Extremamente</b-form-radio>
          </b-form-group>
        </b-form-group>

        <b-button v-show="numberQuestion == 6" variant="success" @click="numberQuestion = 7">Ok</b-button>

        <b-form-group v-show="numberQuestion == 7" class="ml-5" label="7) Quanta dor no corpo você teve durante as últimas 4 semana?">
          <b-form-radio v-model="selected7" name="some-radios-7" value="1">Nenhuma</b-form-radio>
          <b-form-radio v-model="selected7" name="some-radios-7" value="2">Muito leve</b-form-radio>
          <b-form-radio v-model="selected7" name="some-radios-7" value="3">Leve</b-form-radio>
          <b-form-radio v-model="selected7" name="some-radios-7" value="4">Moderada</b-form-radio>
          <b-form-radio v-model="selected7" name="some-radios-7" value="5">Grave</b-form-radio>
          <b-form-radio v-model="selected7" name="some-radios-7" value="6">Muito grave</b-form-radio>
        </b-form-group>

        <b-button v-show="numberQuestion == 7" variant="success" @click="numberQuestion = 8">Ok</b-button>

        <b-form-group v-show="numberQuestion == 8" class="ml-5" label="8) Durante as últimas 4 semanas, quanto a dor interferiu com seu trabalho normal (incluindo o trabalho dentro de casa)?">
          <b-form-radio v-model="selected8" name="some-radios-8" value="1">De maneira alguma</b-form-radio>
          <b-form-radio v-model="selected8" name="some-radios-8" value="2">Um pouco</b-form-radio>
          <b-form-radio v-model="selected8" name="some-radios-8" value="3">Moderadamente</b-form-radio>
          <b-form-radio v-model="selected8" name="some-radios-8" value="4">Bastante</b-form-radio>
          <b-form-radio v-model="selected8" name="some-radios-8" value="5">Extremamente</b-form-radio>
        </b-form-group>

        <b-button v-show="numberQuestion == 8" variant="success" @click="numberQuestion = 9">Ok</b-button>

        <b-form-group v-show="numberQuestion == 9" class="mt-3" label="9) Estas questões são sobre como você se sente e como tudo tem acontecido com você durante as últimas 4 semanas. Para cada questão, por favor dê uma resposta que mais se aproxime de maneira como você se sente, em relação às últimas 4 semanas.">
          <b-form-group v-show="numberQuestion == 9" class="ml-5" label="Quanto tempo você tem se sentindo cheio de vigor, de vontade, de força?">
            <b-form-radio v-model="selected9_1" name="some-radios-9_1" value="1">Todo Tempo</b-form-radio>
            <b-form-radio v-model="selected9_1" name="some-radios-9_1" value="2">A maior parte do tempo</b-form-radio>
            <b-form-radio v-model="selected9_1" name="some-radios-9_1" value="3">Uma boa parte do tempo</b-form-radio>
            <b-form-radio v-model="selected9_1" name="some-radios-9_1" value="4">Alguma parte do tempo</b-form-radio>
            <b-form-radio v-model="selected9_1" name="some-radios-9_1" value="5">Uma pequena parte do tempo</b-form-radio>
            <b-form-radio v-model="selected9_1" name="some-radios-9_1" value="6">Nunca</b-form-radio>
          </b-form-group>
          <b-form-group v-show="numberQuestion == 9" class="ml-5" label="Quanto tempo você tem se sentido uma pessoa muito nervosa?">
            <b-form-radio v-model="selected9_2" name="some-radios-9_2" value="1">Todo Tempo</b-form-radio>
            <b-form-radio v-model="selected9_2" name="some-radios-9_2" value="2">A maior parte do tempo</b-form-radio>
            <b-form-radio v-model="selected9_2" name="some-radios-9_2" value="3">Uma boa parte do tempo</b-form-radio>
            <b-form-radio v-model="selected9_2" name="some-radios-9_2" value="4">Alguma parte do tempo</b-form-radio>
            <b-form-radio v-model="selected9_2" name="some-radios-9_2" value="5">Uma pequena parte do tempo</b-form-radio>
            <b-form-radio v-model="selected9_2" name="some-radios-9_2" value="6">Nunca</b-form-radio>
          </b-form-group>
          <b-form-group v-show="numberQuestion == 9" class="ml-5" label="Quanto tempo você tem se sentido tão deprimido que nada pode anima-lo?">
            <b-form-radio v-model="selected9_3" name="some-radios-9_3" value="1">Todo Tempo</b-form-radio>
            <b-form-radio v-model="selected9_3" name="some-radios-9_3" value="2">A maior parte do tempo</b-form-radio>
            <b-form-radio v-model="selected9_3" name="some-radios-9_3" value="3">Uma boa parte do tempo</b-form-radio>
            <b-form-radio v-model="selected9_3" name="some-radios-9_3" value="4">Alguma parte do tempo</b-form-radio>
            <b-form-radio v-model="selected9_3" name="some-radios-9_3" value="5">Uma pequena parte do tempo</b-form-radio>
            <b-form-radio v-model="selected9_3" name="some-radios-9_3" value="6">Nunca</b-form-radio>
          </b-form-group>
          <b-form-group v-show="numberQuestion == 9" class="ml-5" label="Quanto tempo você tem se sentido calmo ou tranquilo?">
            <b-form-radio v-model="selected9_4" name="some-radios-9_4" value="1">Todo Tempo</b-form-radio>
            <b-form-radio v-model="selected9_4" name="some-radios-9_4" value="2">A maior parte do tempo</b-form-radio>
            <b-form-radio v-model="selected9_4" name="some-radios-9_4" value="3">Uma boa parte do tempo</b-form-radio>
            <b-form-radio v-model="selected9_4" name="some-radios-9_4" value="4">Alguma parte do tempo</b-form-radio>
            <b-form-radio v-model="selected9_4" name="some-radios-9_4" value="5">Uma pequena parte do tempo</b-form-radio>
            <b-form-radio v-model="selected9_4" name="some-radios-9_4" value="6">Nunca</b-form-radio>
          </b-form-group>
          <b-form-group v-show="numberQuestion == 9" class="ml-5" label="Quanto tempo você tem se sentido com muita energia?">
            <b-form-radio v-model="selected9_5" name="some-radios-9_5" value="1">Todo Tempo</b-form-radio>
            <b-form-radio v-model="selected9_5" name="some-radios-9_5" value="2">A maior parte do tempo</b-form-radio>
            <b-form-radio v-model="selected9_5" name="some-radios-9_5" value="3">Uma boa parte do tempo</b-form-radio>
            <b-form-radio v-model="selected9_5" name="some-radios-9_5" value="4">Alguma parte do tempo</b-form-radio>
            <b-form-radio v-model="selected9_5" name="some-radios-9_5" value="5">Uma pequena parte do tempo</b-form-radio>
            <b-form-radio v-model="selected9_5" name="some-radios-9_5" value="6">Nunca</b-form-radio>
          </b-form-group>
          <b-form-group v-show="numberQuestion == 9" class="ml-5" label="Quanto tempo você tem se sentido desanimado ou abatido?">
            <b-form-radio v-model="selected9_6" name="some-radios-9_6" value="1">Todo Tempo</b-form-radio>
            <b-form-radio v-model="selected9_6" name="some-radios-9_6" value="2">A maior parte do tempo</b-form-radio>
            <b-form-radio v-model="selected9_6" name="some-radios-9_6" value="3">Uma boa parte do tempo</b-form-radio>
            <b-form-radio v-model="selected9_6" name="some-radios-9_6" value="4">Alguma parte do tempo</b-form-radio>
            <b-form-radio v-model="selected9_6" name="some-radios-9_6" value="5">Uma pequena parte do tempo</b-form-radio>
            <b-form-radio v-model="selected9_6" name="some-radios-9_6" value="6">Nunca</b-form-radio>
          </b-form-group>
          <b-form-group v-show="numberQuestion == 9" class="ml-5" label="Quanto tempo você tem se sentido esgotado?">
            <b-form-radio v-model="selected9_7" name="some-radios-9_7" value="1">Todo Tempo</b-form-radio>
            <b-form-radio v-model="selected9_7" name="some-radios-9_7" value="2">A maior parte do tempo</b-form-radio>
            <b-form-radio v-model="selected9_7" name="some-radios-9_7" value="3">Uma boa parte do tempo</b-form-radio>
            <b-form-radio v-model="selected9_7" name="some-radios-9_7" value="4">Alguma parte do tempo</b-form-radio>
            <b-form-radio v-model="selected9_7" name="some-radios-9_7" value="5">Uma pequena parte do tempo</b-form-radio>
            <b-form-radio v-model="selected9_7" name="some-radios-9_7" value="6">Nunca</b-form-radio>
          </b-form-group>
          <b-form-group v-show="numberQuestion == 9" class="ml-5" label="Quanto tempo você tem se sentido uma pessoa feliz?">
            <b-form-radio v-model="selected9_8" name="some-radios-9_8" value="1">Todo Tempo</b-form-radio>
            <b-form-radio v-model="selected9_8" name="some-radios-9_8" value="2">A maior parte do tempo</b-form-radio>
            <b-form-radio v-model="selected9_8" name="some-radios-9_8" value="3">Uma boa parte do tempo</b-form-radio>
            <b-form-radio v-model="selected9_8" name="some-radios-9_8" value="4">Alguma parte do tempo</b-form-radio>
            <b-form-radio v-model="selected9_8" name="some-radios-9_8" value="5">Uma pequena parte do tempo</b-form-radio>
            <b-form-radio v-model="selected9_8" name="some-radios-9_8" value="6">Nunca</b-form-radio>
          </b-form-group>
          <b-form-group v-show="numberQuestion == 9" class="ml-5" label="Quanto tempo você tem se sentido cansado?">
            <b-form-radio v-model="selected9_9" name="some-radios-9_9" value="1">Todo Tempo</b-form-radio>
            <b-form-radio v-model="selected9_9" name="some-radios-9_9" value="2">A maior parte do tempo</b-form-radio>
            <b-form-radio v-model="selected9_9" name="some-radios-9_9" value="3">Uma boa parte do tempo</b-form-radio>
            <b-form-radio v-model="selected9_9" name="some-radios-9_9" value="4">Alguma parte do tempo</b-form-radio>
            <b-form-radio v-model="selected9_9" name="some-radios-9_9" value="5">Uma pequena parte do tempo</b-form-radio>
            <b-form-radio v-model="selected9_9" name="some-radios-9_9" value="6">Nunca</b-form-radio>
          </b-form-group>          
        </b-form-group>

        <b-button v-show="numberQuestion == 9" variant="success" @click="numberQuestion = 10">Ok</b-button>

        <b-form-group v-show="numberQuestion == 10" class="mt-3" label="10) Durante as últimas 4 semanas, quanto de seu tempo a sua saúde física ou problemas emocionais interferiram com as suas atividades sociais (como visitar amigos, parentes, etc)?">
          <b-form-group v-show="numberQuestion == 10" class="ml-5" label="">
            <b-form-radio v-model="selected10" name="some-radios-10" value="1">Todo Tempo</b-form-radio>
            <b-form-radio v-model="selected10" name="some-radios-10" value="2">A maior parte do tempo</b-form-radio>
            <b-form-radio v-model="selected10" name="some-radios-10" value="3">Alguma parte do tempoo</b-form-radio>
            <b-form-radio v-model="selected10" name="some-radios-10" value="4">Uma pequena parte do tempo</b-form-radio>
            <b-form-radio v-model="selected10" name="some-radios-10" value="5">Nenhuma parte do tempo</b-form-radio>
          </b-form-group>
        </b-form-group>

        <b-button v-show="numberQuestion == 10" variant="success" @click="numberQuestion = 11">Ok</b-button>

        <b-form-group v-show="numberQuestion == 11" class="mt-3" label="11) O quanto verdadeiro ou falso é cada uma das afirmações para você?">
          <b-form-group v-show="numberQuestion == 11" class="ml-5" label="Eu costumo obedecer um pouco mais facilmente que as outras pessoas">
            <b-form-radio v-model="selected11_1" name="some-radios-11_1" value="1">Definitivamente verdadeiro</b-form-radio>
            <b-form-radio v-model="selected11_1" name="some-radios-11_1" value="2">A maioria das vezes verdadeiro</b-form-radio>
            <b-form-radio v-model="selected11_1" name="some-radios-11_1" value="3">Não sei</b-form-radio>
            <b-form-radio v-model="selected11_1" name="some-radios-11_1" value="4">A maioria das vezes falso</b-form-radio>
            <b-form-radio v-model="selected11_1" name="some-radios-11_1" value="5">Definitivamente falso</b-form-radio>
          </b-form-group>
          <b-form-group v-show="numberQuestion == 11" class="ml-5" label="Eu sou tão saudável quanto qualquer pessoa que eu conheço">
            <b-form-radio v-model="selected11_2" name="some-radios-11_2" value="1">Definitivamente verdadeiro</b-form-radio>
            <b-form-radio v-model="selected11_2" name="some-radios-11_2" value="2">A maioria das vezes verdadeiro</b-form-radio>
            <b-form-radio v-model="selected11_2" name="some-radios-11_2" value="3">Não sei</b-form-radio>
            <b-form-radio v-model="selected11_2" name="some-radios-11_2" value="4">A maioria das vezes falso</b-form-radio>
            <b-form-radio v-model="selected11_2" name="some-radios-11_2" value="5">Definitivamente falso</b-form-radio>
          </b-form-group>
          <b-form-group v-show="numberQuestion == 11" class="ml-5" label="Eu acho que a minha saúde vai piorar">
            <b-form-radio v-model="selected11_3" name="some-radios-11_3" value="1">Definitivamente verdadeiro</b-form-radio>
            <b-form-radio v-model="selected11_3" name="some-radios-11_3" value="2">A maioria das vezes verdadeiro</b-form-radio>
            <b-form-radio v-model="selected11_3" name="some-radios-11_3" value="3">Não sei</b-form-radio>
            <b-form-radio v-model="selected11_3" name="some-radios-11_3" value="4">A maioria das vezes falso</b-form-radio>
            <b-form-radio v-model="selected11_3" name="some-radios-11_3" value="5">Definitivamente falso</b-form-radio>
          </b-form-group>
          <b-form-group v-show="numberQuestion == 11" class="ml-5" label="Minha saúde é excelente">
            <b-form-radio v-model="selected11_4" name="some-radios-11_4" value="1">Definitivamente verdadeiro</b-form-radio>
            <b-form-radio v-model="selected11_4" name="some-radios-11_4" value="2">A maioria das vezes verdadeiro</b-form-radio>
            <b-form-radio v-model="selected11_4" name="some-radios-11_4" value="3">Não sei</b-form-radio>
            <b-form-radio v-model="selected11_4" name="some-radios-11_4" value="4">A maioria das vezes falso</b-form-radio>
            <b-form-radio v-model="selected11_4" name="some-radios-11_4" value="5">Definitivamente falso</b-form-radio>
          </b-form-group>
        </b-form-group>
      </b-card>
    </div> 
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'
import Moment from 'moment'

export default {
    name: "SatisfactionSurvey",
    components: { PageTitle },
    data() {
      return {
        patient: null,
        question: null,
        theme: null,
        numberQuestion: 1,
        selected1: null,
        selected2: null,
        selected3_1: null,
        selected3_2: null,
        selected3_3: null,
        selected3_4: null,
        selected3_5: null,
        selected3_6: null,
        selected3_7: null,
        selected3_8: null,
        selected3_9: null,
        selected3_10: null,
        selected4_1: null,
        selected4_2: null,
        selected4_3: null,
        selected4_4: null,
        selected5_1: null,
        selected5_2: null,
        selected5_3: null,
        selected6: null,
        selected7: null,
        selected8: null,
        selected9_1: null,
        selected9_2: null,
        selected9_3: null,
        selected9_4: null,
        selected9_5: null,
        selected9_6: null,
        selected9_7: null,
        selected9_8: null,
        selected9_9: null,
        selected10: null,
        selected11_1: null,
        selected11_2: null,
        selected11_3: null,
        selected11_4: null
      }
    },    
    methods: {
      sendQuestions() {
          Moment.locale('pt-br')  
          
          axios.get(`${baseApiUrl}/patient/getById/${this.patient._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`).then(res => {
            this.patient = res.data

            let data = {}
            let index = 0

            for (let q of this.patient.questions) {
              if (q.type == 2 && q.name == this.question.name && q.answers.length == 0) {
                q.answers = []

                q.status = 1
                q.answers.push(
                  {
                    'dateAnswers': Moment(new Date()).format('L')
                  }, 
                  {
                    '1': this.selected1,
                    '2': this.selected2,
                    '3_1': this.selected3_1,
                    '3_2': this.selected3_2,
                    '3_3': this.selected3_3,
                    '3_4': this.selected3_4,
                    '3_5': this.selected3_5,
                    '3_6': this.selected3_6,
                    '3_7': this.selected3_7,
                    '3_8': this.selected3_8,
                    '3_9': this.selected3_9,
                    '3_10': this.selected3_10,
                    '4_1': this.selected4_1,
                    '4_2': this.selected4_2,
                    '4_3': this.selected4_3,
                    '4_4': this.selected4_4,
                    '5_1': this.selected5_1,
                    '5_2': this.selected5_2,
                    '5_3': this.selected5_3,
                    '6': this.selected6,
                    '7': this.selected7,
                    '8': this.selected8,
                    '9_1': this.selected9_1,
                    '9_2': this.selected9_2,
                    '9_3': this.selected9_3,
                    '9_4': this.selected9_4,
                    '9_5': this.selected9_5,
                    '9_6': this.selected9_6,
                    '9_7': this.selected9_7,
                    '9_8': this.selected9_8,
                    '9_9': this.selected9_9,
                    '10': this.selected10,
                    '11_1': this.selected11_1,
                    '11_2': this.selected11_2,
                    '11_3': this.selected11_3,
                    '11_4': this.selected11_4
                  }                                                                                                                                                                                                                                                                                                                                                                                                                                                            
                ) 

                data.index = index
                data.answers = q.answers
              }

              index += 1
            }        
            
            data.idPatient = this.patient._id

            axios.post(`${baseApiUrl}/patient/setTestQuestion/${this.patient._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, data)
              .then(() => {
                  this.$store.commit('setResultPatient', this.patient)

                  this.$toasted.global.defaultSuccess()
                  this.$router.go(-1)
              })  
              .catch(showError) 
          }).catch((e) => {
            console.log(e)
            showError
          })
        // }
      }
    },
    created() {
      this.theme = localStorage.getItem('theme')

      this.patient = this.$route.params.questionSelected.patient
      this.question = this.$route.params.questionSelected

      if (this.$route.params.questionSelected.answers.length > 0) {
        this.selected1 = this.$route.params.questionSelected.answers[1]['1'] 
        this.selected2 = this.$route.params.questionSelected.answers[1]['2']
        this.selected3_1 = this.$route.params.questionSelected.answers[1]['3_1']
        this.selected3_2 = this.$route.params.questionSelected.answers[1]['3_2']
        this.selected3_3 = this.$route.params.questionSelected.answers[1]['3_3']
        this.selected3_4 = this.$route.params.questionSelected.answers[1]['3_4']
        this.selected3_5 = this.$route.params.questionSelected.answers[1]['3_5']
        this.selected3_6 = this.$route.params.questionSelected.answers[1]['3_6']
        this.selected3_7 = this.$route.params.questionSelected.answers[1]['3_7']
        this.selected3_8 = this.$route.params.questionSelected.answers[1]['3_8']
        this.selected3_9 = this.$route.params.questionSelected.answers[1]['3_9']
        this.selected3_10 = this.$route.params.questionSelected.answers[1]['3_10']
        this.selected4_1 = this.$route.params.questionSelected.answers[1]['4_1']
        this.selected4_2 = this.$route.params.questionSelected.answers[1]['4_2']
        this.selected4_3 = this.$route.params.questionSelected.answers[1]['4_3']
        this.selected4_4 = this.$route.params.questionSelected.answers[1]['4_4']  
        this.selected5_1 = this.$route.params.questionSelected.answers[1]['5_1']
        this.selected5_2 = this.$route.params.questionSelected.answers[1]['5_2']
        this.selected5_3 = this.$route.params.questionSelected.answers[1]['5_3'] 
        this.selected6 = this.$route.params.questionSelected.answers[1]['6']
        this.selected7 = this.$route.params.questionSelected.answers[1]['7']
        this.selected8 = this.$route.params.questionSelected.answers[1]['8']
        this.selected9_1 = this.$route.params.questionSelected.answers[1]['9_1']
        this.selected9_2 = this.$route.params.questionSelected.answers[1]['9_2']
        this.selected9_3 = this.$route.params.questionSelected.answers[1]['9_3']
        this.selected9_4 = this.$route.params.questionSelected.answers[1]['9_4']
        this.selected9_5 = this.$route.params.questionSelected.answers[1]['9_5']
        this.selected9_6 = this.$route.params.questionSelected.answers[1]['9_6']
        this.selected9_7 = this.$route.params.questionSelected.answers[1]['9_7']
        this.selected9_8 = this.$route.params.questionSelected.answers[1]['9_8']
        this.selected9_9 = this.$route.params.questionSelected.answers[1]['9_9']
        this.selected10 = this.$route.params.questionSelected.answers[1]['10']
        this.selected11_1 = this.$route.params.questionSelected.answers[1]['11_1']
        this.selected11_2 = this.$route.params.questionSelected.answers[1]['11_2']
        this.selected11_3 = this.$route.params.questionSelected.answers[1]['11_3']
        this.selected11_4 = this.$route.params.questionSelected.answers[1]['11_4']
      }
    }
}
</script>

<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}

.card-violet {
  background-color: #f3eaff;
}
</style>