<template>
  <div class="assessments-pages">
    <PageTitle icon="fa fa-file-text" main="Avaliações" sub />
    <div class="admin-pages-tabs">
        <b-table ref="selectableTable" head-variant="light" outlined hover :items="assessments" :fields="fields" responsive="sm" id="my-table"
                 :per-page="perPage" :current-page="currentPage" striped fixed :filter="ftext">
            <template v-slot:cell(actions)="row">
                <b-button v-show="row.item.status == 0" size="sm" variant="warning" @click="onRowSelected(row.item)" v-b-popover.hover.top="'Responder'">
                    <i class="fa fa-pencil"></i>
                </b-button>
            </template>
        </b-table>              

        <div v-if="assessments.length == 0" class="d-flex justify-content-center mb-3">    
            <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner" type="grow"></b-spinner>
        </div>                

        <b-pagination pills v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table" class="page-item">
        </b-pagination>        
    </div>
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle";
// import { baseApiUrl, showError, userKey } from '@/global'
// import axios from 'axios'
import Moment from 'moment'

export default {
    name: 'Assessments',
    components: { PageTitle },
    data: function() {
      return {
        theme: null,
        ftext: '',
        patient: null,
        assessments: [],
        currentPage: 1,
        perPage: 10,        
        fields: [                 
            { key: 'name', label: 'Nome', sortable: true },
            { key: 'date2', label: 'Data Solicitação', sortable: true },
            { key: 'actions', label: 'Ações' }
        ],          
      }
    },
    computed: {
      rows() {
        return this.assessments.length
      }
    },    
    methods: {
        onRowSelected(itemSelected) {
          let questionSelected = {    
              _id: itemSelected._id,
              name: itemSelected.name,
              patient: this.patient
          }              

          if (itemSelected.name == 'TRAUMATO FUNCIONAL') {
            this.$router.push({name: 'assessmentTrauma', params: { questionSelected }})
          } else if (itemSelected.name == 'PEDIÁTRICA') {
            this.$router.push({name: 'assessmentPediatric', params: { questionSelected }})
          } else if (itemSelected.name == 'PÉLVICA') {
            this.$router.push({name: 'assessmentPelvic', params: { questionSelected }})
          } else if (itemSelected.name == 'OSTEOPATIA') {
            this.$router.push({name: 'assessmentOsteopathy', params: { questionSelected }})
          } else if (itemSelected.name == 'PNEUMOFUNCIONAL E REABILITAÇÃO CARDIOVASCULAR') {
            this.$router.push({name: 'assessmentRespiratory', params: { questionSelected }})
          }
        },               
    },
    mounted() {
      this.theme = localStorage.getItem('theme')

      this.patient = this.$route.params.patient    

      Moment.locale('pt-br')
        
      for (let q of this.patient.questions) {                
        q.date2 = Moment(q.date).format('L')

        this.assessments.push(q)
      }
    }    
};
</script>

<style>
.page-item .page-font {
    color:#44A08D;  
}

.page-item.active .page-link {  
    background-color:#44A08D;  
    border-color:  #44A08D;      
}

.page-item.active .page-link:hover {  
    background-color:#44A08D;  
    border-color:  #44A08D;  
}
</style>