<template>
  <Loading v-if="isLoading" />
  <div v-else class="scaleFixed">
    <PageTitle icon="fa fa-calendar" main="Escala" sub="Fixa" />
    <div class="scale-page-tabs">
      <b-row>
            <b-button size="sm" @click="setModal('professionals')" v-b-modal.modal-prevent-closing-1 variant="success" class="ml-3" 
                v-b-popover.hover.top="'Adicionar Profissionais'">Adicionar
            </b-button>               
            
            <ScaleFixedProfessionals :list="professionals" :fields="pfields"></ScaleFixedProfessionals>              
      </b-row>  
      <b-row>
        <b-col md="12" sm="12" class="mt-3">
            <b-card :class="`card-${theme} text-center`" header="Dia" header-bg-variant="dark" header-text-variant="white"> 
                <b-button-group size="md">
                    <b-button size="sm" variant="outline-dark" @click="getProfessionalsDay('monday')">Segunda</b-button>
                    <b-button size="sm" variant="outline-dark" @click="getProfessionalsDay('tuesday')">Terça</b-button>
                    <b-button size="sm" variant="outline-dark" @click="getProfessionalsDay('wednesday')">Quarta</b-button>
                    <b-button size="sm" variant="outline-dark" @click="getProfessionalsDay('thursday')">Quinta</b-button>
                    <b-button size="sm" variant="outline-dark" @click="getProfessionalsDay('friday')">Sexta</b-button>
                    <b-button size="sm" variant="outline-dark" @click="getProfessionalsDay('saturday')">Sábado</b-button>
                    <b-button size="sm" variant="outline-dark" @click="getProfessionalsDay('sunday')">Domingo</b-button>
                </b-button-group>
            </b-card>                                 
        </b-col>        
      </b-row>
      <b-row>
          <b-col class="mt-3">
            <b-card :class="`card-${theme} text-center`" :header='`${dayLabel}`' header-bg-variant="info" header-text-variant="white">
                <b-table ref="selectableTableProfessionals" head-variant="light" outlined hover :items="resultLookupProfessional" 
                        :fields="fields" responsive="sm" striped fixed @input="insertProfessionalScaleFixed" :select-mode="selectMode">
                    <template v-slot:cell(actions)="row">
                        <b-button size="sm" variant="danger" @click="removeProfessional(row.item)">
                            <i class="fa fa-trash"></i>
                        </b-button>
                    </template>
                </b-table>               
            </b-card>
          </b-col>
      </b-row>
    </div>
  </div>  
</template>

<script>
import PageTitle from "../template/PageTitle"
import { mapState } from "vuex"
import { baseApiUrl, userKey } from '@/global'
import axios from 'axios'
import ScaleFixedProfessionals from '../scale/ScaleFixedProfessionals'
import Loading from "../template/Loading"

export default {
    name: "ScaleFixed",
    components: { PageTitle, ScaleFixedProfessionals, Loading },    
    data: function() {
        return {
            theme: null,
            isLoading: false,
            daySelected: null,
            weekLabel: 'Semana',
            dayLabel: 'Dia',
            professionals: [],
            selectMode: 'single',
            invalidFeedback: 'Informe todos os campos',
            modal: null,
            scaleFixed: {},
            fields: [                 
                { key: 'name', label: 'Nome', sortable: true },
                { key: 'cpf', label: 'CPF', sortable: true },
                { key: 'adviceNumber', label: 'Registro', sortable: true },
                { key: 'checkin', label: 'Início', sortable: true },
                { key: 'checkout', label: 'Término', sortable: true },
                { key: 'typeService.name', label: 'Tipo Atendimento', sortable: true },
                { key: 'actions', label: 'Ações' }
            ],
            pfields: [                 
                { key: 'name', label: 'Nome', sortable: true },
                { key: 'cpf', label: 'CPF', sortable: true, },       
                { key: 'advice.number', label: 'Registro', sortable: true },  
                { key: 'actions', label: 'Ações' },    
            ]       
        }
    },
    computed: mapState(["resultLookupProfessional", "resultLookupScaleBaseProfessionals", "user"]),
    methods: {
        loadProfessionals() {
            if (!this.user.admin) {
                this.$toasted.global.defaultError({msg: 'Usuário sem permissão.'})
                this.$router.go(-1)
            }

            this.$store.state.resultLookupProfessional = []
            this.$store.state.resultLookupScaleBaseProfessionals = []

            this.isLoading = true

            const url = `${baseApiUrl}/professional/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                this.isLoading = false
                this.professionals = res.data            
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })
            })                      
        }, 
        getProfessionalsDay(day) {            
            this.$store.state.resultLookupProfessional = []    
            this.modal = null       
            
            this.daySelected = day

            this.weekLabel = 'Semana'

            switch (day) {
                case 'monday': this.dayLabel = 'Segunda'                
                break;
                case 'tuesday': this.dayLabel = 'Terça'                
                break;          
                case 'wednesday': this.dayLabel = 'Quarta'                
                break;          
                case 'thursday': this.dayLabel = 'Quinta'                
                break;
                case 'friday': this.dayLabel = 'Sexta'                
                break;
                case 'saturday': this.dayLabel = 'Sábado'                
                break;
                case 'sunday': this.dayLabel = 'Domingo'                
                break;

                default:
                this.dayLabel = 'Dia'
            }

            this.isLoading = true

            const url = `${baseApiUrl}/scaleFixed/day/${this.daySelected}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                this.scaleFixed = res.data                               
                
                if (!this.scaleFixed) {
                    this.scaleFixed = {
                      day: '',
                      professionals: []
                    }                                        
                } else {
                    this.$store.commit("setResultLookupProfessionalArray", this.scaleFixed.professionals)
                }

                this.isLoading = false
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })
            })
        },
        setModal(param) {
            this.modal = param
        },           
        insertProfessionalScaleFixed() {
            if (this.daySelected && this.$store.state.resultLookupProfessional.length > 0 && this.modal != null) {    
                let index = this.$store.state.resultLookupProfessional.length - 1
                let finded = false                  
                
                for (let [i, p] of this.scaleFixed.professionals.entries()) {
                    if (p.cpf == this.$store.state.resultLookupProfessional[index].cpf && i != index) {      
                        if (this.$store.state.resultLookupProfessional[index].checkin >= p.checkin && this.$store.state.resultLookupProfessional[index].checkin <= p.checkout) {
                            this.modal = null

                            finded = true

                            this.$store.state.resultLookupProfessional.splice(index, 1)
                            this.$toasted.global.defaultError({msg: 'Profissional já adicionado no dia e horário informados.'})                                                       
                        }                                        
                    }

                    if (finded) {
                        break
                    }                    
                }

                if (!finded && this.modal != null) {       
                    this.modal = null
                    
                    let prof = {
                        name: this.$store.state.resultLookupProfessional[index].name,
                        cpf: this.$store.state.resultLookupProfessional[index].cpf,
                        checkin: this.$store.state.resultLookupProfessional[index].checkin,
                        checkout: this.$store.state.resultLookupProfessional[index].checkout,
                        attendance: [{
                            patients: []
                        }]
                    }    

                    this.scaleFixed.day = this.daySelected

                    this.scaleFixed.professionals.splice(index, 1)    
                    this.scaleFixed.professionals.push(prof)

                    const method = this.scaleFixed._id ? 'put' : 'post'
                    const id = this.scaleFixed._id ? `/${this.scaleFixed._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}` : `/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`

                    this.isLoading = true

                    axios[method](`${baseApiUrl}/scaleFixed${id}`, this.scaleFixed).then(() => {
                        this.isLoading = false
                        this.$toasted.global.defaultSuccess()                                            
                    }).catch(e => {
                        this.isLoading = false
                        this.$toasted.global.defaultError({ msg: e.response.data })
                    })   
                }                                    
            }
        },
        removeProfessional(itemSelected) {
            this.$bvModal.msgBoxConfirm('Deseja remover o profissional da escala?', {
                title: 'Confirmação',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Sim',
                cancelTitle: 'Não',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            }).then(value => {            
                if (value) {
                    let i = 0

                    for (let p of this.scaleFixed.professionals) {
                        if (p.cpf == itemSelected.cpf && p.checkin == itemSelected.checkin) {
                            this.scaleFixed.professionals.splice(i, 1)
                        }

                        i += 1
                    }                    

                    const method = 'put'
                    const id = this.scaleFixed._id ? `/${this.scaleFixed._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}` : `/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
                    
                    this.isLoading = true

                    axios[method](`${baseApiUrl}/scaleFixed${id}`, this.scaleFixed).then(() => {
                        this.isLoading = false
                        this.$toasted.global.defaultSuccess({msg: 'Profissional removido com sucesso!'})
                    }).catch(e => {
                        this.isLoading = false
                        this.$toasted.global.defaultError({ msg: e.response.data })
                    })
                }                
            }).catch()
        },               
    },
    mounted() {
        this.theme = localStorage.getItem('theme')
        this.loadProfessionals()        
    },
}
</script>

<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}

.card-violet {
  background-color: #f3eaff;
}
</style>