<template>
  <div class="home">
    <PageTitle icon="fa fa-home" main="Dashboard" sub="Informações Gerais" />
    <div class="home-page-tabs">
      <b-row>
        <b-col md="4" sm="12">
          <!-- <b-card class="card" header="ADMISSÕES" align="center"  header-bg-variant="dark" header-text-variant="white"> -->
            <!-- <BarChart :chartData="dataCollection"></BarChart> -->
          <!-- </b-card> -->
        </b-col>
        <b-col md="4" sm="12">
          <!-- <b-card class="card" header-bg-variant="default" header-text-variant="dark" align="center"> -->
          <!-- </b-card> -->
        </b-col>
        <b-col md="4" sm="12">
          <!-- <b-card class="card" header-bg-variant="default" header-text-variant="dark" align="center"> -->
          <!-- </b-card> -->
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle"
// import LineChart from '../charts/LineChart'
// import BarChart from '../charts/BarChart'

export default {
  name: "Home",
  components: { PageTitle /*BarChart*/ },
  data: function() {
    return {
      dataCollection: null,
      loaded: false,
    }
  },
  mounted() {
    // this.fillData()
  },
  methods: {
    // fillData() {
    //   this.dataCollection = {
    //     labels: ['JAN','FEV','MAR','ABR','MAI','JUN','JUL','AGO','SET','OUT','NOV','DEZ'],
    //     datasets: [
    //       {
    //         label: 'Temp (C°)',
    //         borderColor: 'black',
    //         borderWidth: 2,
    //         data: [1,2,3,4,5,6,7,8,9,10,11,120],
    //       }
    //     ]
    //   }        
    // }
  }
}
</script>

<style>
.card {
  background-color: #F4F3EF;  
}

.cardAdmissionTitle {
  font-size: 1.5rem;
  color: #fff;
  font-weight: 100;
  flex-grow: 1;
  text-align: center;  
}

.cardAdmissionContent {
  color: black;
  display: flex;
  align-items: center;
}

.cardUpdates {
  background-color: #44A08D;
}
</style>