<template>
    <div class="assessmentPelvic">
        <PageTitle icon="fa fa-file-text" main="Ficha de Avaliação" sub="Osteopatia" />

        <div>
            <b-card :class="`card-${theme}`">
                <b-button @click="sendQuestions()" variant="info">Enviar</b-button>
                <b-form-group class="mt-3">
                    <b-button-group>
                        <b-button @click="numberQuestion = 1" :variant="(selected1_1 != null ||
                                                                         selected1_2 != null ||
                                                                         selected1_3 != null ||
                                                                         selected1_4 != null ||
                                                                         selected1_5 != null ||
                                                                         selected1_6 != null ||
                                                                         selected1_7 != null ||
                                                                         selected1_8 != null ||
                                                                         selected1_9 != null ||
                                                                         selected1_10 != null ||
                                                                         selected1_11 != null ||
                                                                         selected1_12 != null ||
                                                                         selected1_13 != null ||
                                                                         selected1_14 != null ||
                                                                         selected1_15 != null ||
                                                                         selected1_16 != null ||
                                                                         selected1_17 != null ||
                                                                         selected1_18 != null) ? 'success' : 'secondary'">1</b-button>
                        <b-button @click="numberQuestion = 2" :variant="(selected2_1 != null ||
                                                                         selected2_2 != null ||
                                                                         selected2_3 != null ||
                                                                         selected2_4 != null ||
                                                                         selected2_5 != null ||
                                                                         selected2_6 != null) ? 'success' : 'secondary'">2</b-button>
                        <b-button @click="numberQuestion = 3" :variant="(selected3_1 != false ||
                                                                         selected3_2 != false ||
                                                                         selected3_3 != false ||
                                                                         selected3_3 != false) ? 'success' : 'secondary'">3</b-button>
                        <b-button @click="numberQuestion = 4" :variant="(selected4_1 != false ||
                                                                         selected4_2 != false ||
                                                                         selected4_3 != false ||
                                                                         selected4_4 != false ||
                                                                         selected4_5 != false ||
                                                                         selected4_6 != false ||
                                                                         selected4_7 != false ||
                                                                         selected4_8 != false ||
                                                                         selected4_9 != false ||
                                                                         selected4_10 != null ||
                                                                         selected4_11 != false ||
                                                                         selected4_12 != false ||
                                                                         selected4_13 != false ||
                                                                         selected4_14 != false ||
                                                                         selected4_15 != null) ? 'success' : 'secondary'">4</b-button>
                        <b-button @click="numberQuestion = 5" :variant="(selected5_1 != false ||
                                                                         selected5_2 != false ||
                                                                         selected5_3 != false ||
                                                                         selected5_4 != false ||
                                                                         selected5_5 != false ||
                                                                         selected5_6 != false ||
                                                                         selected5_7 != false ||
                                                                         selected5_8 != false ||
                                                                         selected5_9 != false ||
                                                                         selected5_10 != false ||
                                                                         selected5_11 != null ||
                                                                         selected5_12 != null ||
                                                                         selected5_13 != null ||
                                                                         selected5_14 != null ||
                                                                         selected5_15 != null ||
                                                                         selected5_16 != null ||
                                                                         selected5_17 != null) ? 'success' : 'secondary'">5</b-button>
                        <b-button @click="numberQuestion = 6" :variant="(selected6_1 != false ||
                                                                         selected6_2 != false ||
                                                                         selected6_3 != false ||
                                                                         selected6_4 != false ||
                                                                         selected6_5 != false ||
                                                                         selected6_6 != false ||
                                                                         selected6_7 != false ||
                                                                         selected6_8 != false ||
                                                                         selected6_9 != null) ? 'success' : 'secondary'">6</b-button>
                        <b-button @click="numberQuestion = 7" :variant="(selected7_1 != false ||
                                                                         selected7_2 != false ||
                                                                         selected7_3 != false ||
                                                                         selected7_4 != false ||
                                                                         selected7_5 != false ||
                                                                         selected7_6 != false) ? 'success' : 'secondary'">7</b-button>
                        <b-button @click="numberQuestion = 8" :variant="(selected8_1 != false ||
                                                                         selected8_2 != false ||
                                                                         selected8_3 != false ||
                                                                         selected8_4 != false) ? 'success' : 'secondary'">8</b-button>
                        <b-button @click="numberQuestion = 9" :variant="(selected9_1 != false ||
                                                                         selected9_2 != false ||
                                                                         selected9_3 != false ||
                                                                         selected9_4 != false ||
                                                                         selected9_5 != false) ? 'success' : 'secondary'">9</b-button>
                        <b-button @click="numberQuestion = 10" :variant="(selected10_1 != false ||
                                                                          selected10_2 != false ||
                                                                          selected10_3 != false ||
                                                                          selected10_4 != false ||
                                                                          selected10_5 != false ||
                                                                          selected10_6 != false ||
                                                                          selected10_7 != false ||
                                                                          selected10_8 != false ||
                                                                          selected10_9 != false ||
                                                                          selected10_10 != false ||
                                                                          selected10_11 != false ||
                                                                          selected10_12 != false ||
                                                                          selected10_13 != null) ? 'success' : 'secondary'">10</b-button>
                        <b-button @click="numberQuestion = 11" :variant="(selected11_1 != false ||
                                                                          selected11_2 != false ||
                                                                          selected11_3 != false ||
                                                                          selected11_4 != false ||
                                                                          selected11_5 != false ||
                                                                          selected11_6 != false ||
                                                                          selected11_7 != false ||
                                                                          selected11_8 != false ||
                                                                          selected11_9 != false ||
                                                                          selected11_10 != null ||
                                                                          selected11_11 != null ||
                                                                          selected11_12 != false ||
                                                                          selected11_13 != false ||
                                                                          selected11_14 != false ||
                                                                          selected11_15 != null) ? 'success' : 'secondary'">11</b-button>                 
                        <b-button @click="numberQuestion = 12" :variant="(selected12_1 != false ||
                                                                          selected12_2 != false ||
                                                                          selected12_3 != false ||
                                                                          selected12_4 != false ||
                                                                          selected12_5 != false ||
                                                                          selected12_6 != false ||
                                                                          selected12_7 != false ||
                                                                          selected12_8 != false) ? 'success' : 'secondary'">12</b-button>                 
                        <b-button @click="numberQuestion = 13" :variant="(selected13_1 != false ||
                                                                          selected13_2 != false ||
                                                                          selected13_3 != false ||
                                                                          selected13_4 != null ||
                                                                          selected13_5 != false ||
                                                                          selected13_6 != false ||
                                                                          selected13_7 != false ||
                                                                          selected13_8 != false ||
                                                                          selected13_9 != false ||
                                                                          selected13_10 != false ||
                                                                          selected13_11 != false ||
                                                                          selected13_12 != false ||
                                                                          selected13_13 != null ||
                                                                          selected13_14 != null ||
                                                                          selected13_15 != null) ? 'success' : 'secondary'">13</b-button>                 
                        <b-button @click="numberQuestion = 14" :variant="selected14 != null ? 'success' : 'secondary'">14</b-button>                 
                        <b-button @click="numberQuestion = 15" :variant="(selected15_1 != false ||
                                                                          selected15_2 != false ||
                                                                          selected15_3 != false ||
                                                                          selected15_4 != null ||
                                                                          selected15_5 != false ||
                                                                          selected15_6 != false ||
                                                                          selected15_7 != false) ? 'success' : 'secondary'">15</b-button>  
                        <b-button @click="numberQuestion = 16" :variant="selected16 != null ? 'success' : 'secondary'">16</b-button> 
                        <b-button @click="numberQuestion = 17" :variant="selected17 != null ? 'success' : 'secondary'">17</b-button>             
                    </b-button-group>
                </b-form-group>

                <b-form-group v-show="numberQuestion == 1" class="mt-3">
                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="Nome:">
                                <b-form-input type="text" v-model="selected1_1" />                    
                            </b-form-group>                
                        </b-col>
                    </b-row>

                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="Sexo:">
                                <b-form-radio v-model="selected1_2" name="some-radios-1_2" value="M">M</b-form-radio>
                                <b-form-radio v-model="selected1_2" name="some-radios-1_2" value="F">F</b-form-radio>
                            </b-form-group>                
                        </b-col>
                    </b-row>

                    <b-row> 
                        <b-col md="4" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="Data Nascimento:">
                                <b-form-input type="date" v-model="selected1_3" />                    
                            </b-form-group>                
                        </b-col>                                     
                        <b-col md="4" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="Idade:">
                                <b-form-input type="text" v-model="selected1_4" />                    
                            </b-form-group>                
                        </b-col>       
                        <b-col md="4" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="Peso:">
                                <b-form-input type="text" v-model="selected1_5" />                    
                            </b-form-group>                
                        </b-col>                         
                        <b-col md="4" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="Altura:">
                                <b-form-input type="text" v-model="selected1_6" />                    
                            </b-form-group>                
                        </b-col>                         
                    </b-row>    

                    <b-row> 
                        <b-col md="6" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="Profissão:">
                                <b-form-input type="text" v-model="selected1_7" />                    
                            </b-form-group>                
                        </b-col>                                     
                        <b-col md="6" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="E-mail:">
                                <b-form-input type="text" v-model="selected1_8" />                    
                            </b-form-group>                
                        </b-col>                       
                        <b-col md="6" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="Telefone Residêncial:">
                                <b-form-input type="text" v-model="selected1_9" />                    
                            </b-form-group>                
                        </b-col>                       
                        <b-col md="6" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="Telefone Celular:">
                                <b-form-input type="text" v-model="selected1_10" />                    
                            </b-form-group>                
                        </b-col>                                               
                    </b-row>                                            

                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="Endereço:">
                                <b-form-input type="text" v-model="selected1_11" />                    
                            </b-form-group>                
                        </b-col>
                        <b-col md="6" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="Bairro:">
                                <b-form-input type="text" v-model="selected1_12" />                    
                            </b-form-group>                
                        </b-col>                           
                        <b-col md="6" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="CEP:">
                                <b-form-input type="text" v-model="selected1_13" />                    
                            </b-form-group>                
                        </b-col>       
                        <b-col md="6" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="Cidade:">
                                <b-form-input type="text" v-model="selected1_14" />                    
                            </b-form-group>                
                        </b-col>   
                        <b-col md="6" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="Estado:">
                                <b-form-input type="text" v-model="selected1_15" />                    
                            </b-form-group>                
                        </b-col>                                                                       
                    </b-row>                  

                    <b-row> 
                        <b-col md="6" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="Encaminhado Por:">
                                <b-form-input type="text" v-model="selected1_16" />                    
                            </b-form-group>                
                        </b-col>                                     
                        <b-col md="6" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="Diagnóstico Médico:">
                                <b-form-textarea id="textarea" v-model="selected1_17" placeholder="" rows="1" max-rows="6"></b-form-textarea>                  
                            </b-form-group>                
                        </b-col>                       
                    </b-row>     

                    <b-row> 
                        <b-col md="2" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="Data da Avaliação:">
                                <b-form-input type="date" v-model="selected1_18" />
                            </b-form-group>                
                        </b-col>  
                    </b-row>                                 
                </b-form-group>            

                <b-button v-show="numberQuestion == 1" variant="success" @click="numberQuestion = 2">Ok</b-button>


                <b-form-group v-show="numberQuestion == 2" class="mt-3" label="Avaliação Clínica">
                    <b-row> 
                        <b-col md="6" sm="12">   
                            <b-form-group v-show="numberQuestion == 2" label="Queixa Principal:">
                                <b-form-input type="text" v-model="selected2_1" />                    
                            </b-form-group>                
                        </b-col>                                     
                        <b-col md="6" sm="12">   
                            <b-form-group v-show="numberQuestion == 2" label="Queixa Secundária:">
                                <b-form-textarea id="textarea" v-model="selected2_2" placeholder="" rows="1" max-rows="6"></b-form-textarea>                  
                            </b-form-group>                
                        </b-col>                       
                        <b-col md="6" sm="12">   
                            <b-form-group v-show="numberQuestion == 2" label="História Clínica:">
                                <b-form-textarea id="textarea" v-model="selected2_3" placeholder="" rows="1" max-rows="6"></b-form-textarea>                  
                            </b-form-group>                
                        </b-col>       
                        <b-col md="6" sm="12">   
                            <b-form-group v-show="numberQuestion == 2" label="Medição/Terapias Realizadas ou Concomitantes:">
                                <b-form-textarea id="textarea" v-model="selected2_4" placeholder="" rows="1" max-rows="6"></b-form-textarea>                  
                            </b-form-group>                
                        </b-col>  
                        <b-col md="6" sm="12">   
                            <b-form-group v-show="numberQuestion == 2" label="Exames Complementares:">
                                <b-form-textarea id="textarea" v-model="selected2_5" placeholder="" rows="1" max-rows="6"></b-form-textarea>                  
                            </b-form-group>                
                        </b-col>  
                        <b-col md="6" sm="12">   
                            <b-form-group v-show="numberQuestion == 2" label="Fotos dos Exames:">
                                <b-form-textarea id="textarea" v-model="selected2_6" placeholder="" rows="1" max-rows="6"></b-form-textarea>                  
                            </b-form-group>                
                        </b-col>                                                                                            
                    </b-row>  
                </b-form-group>

                <b-button v-show="numberQuestion == 2" variant="success" @click="numberQuestion = 3">Ok</b-button>

                <b-form-group v-show="numberQuestion == 3" class="mt-3">
                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 3" label="As Dores São Mais Frequentes:">
                                <b-form-checkbox id="checkbox-3_1" v-model="selected3_1" name="checkbox-3_1" :value="true" :unchecked-value="false">Pela Manhã - Sistema Manducatório</b-form-checkbox>
                                <b-form-checkbox id="checkbox-3_2" v-model="selected3_2" name="checkbox-3_2" :value="true" :unchecked-value="false">Final do Dia - Captor Ocular</b-form-checkbox>
                                <b-form-checkbox id="checkbox-3_3" v-model="selected3_3" name="checkbox-3_3" :value="true" :unchecked-value="false">À Noite - Captor Ocular</b-form-checkbox>
                                <b-form-checkbox id="checkbox-3_4" v-model="selected3_4" name="checkbox-3_4" :value="true" :unchecked-value="false">Todo Dia - Captor Podal</b-form-checkbox>
                            </b-form-group>                
                        </b-col>                                                                       
                    </b-row>   
                </b-form-group>      

                <b-button v-show="numberQuestion == 3" variant="success" @click="numberQuestion = 4">Ok</b-button>  


                <b-form-group v-show="numberQuestion == 4" class="mt-3">
                    <b-row> 
                        <b-col md="4" sm="12">   
                            <b-form-group v-show="numberQuestion == 4" label="Relacionado Ao Captor Podal:">
                                <b-form-checkbox id="checkbox-4_1" v-model="selected4_1" name="checkbox-4_1" :value="true" :unchecked-value="false">Uso de Palmilhas/Botas Ortopédicas</b-form-checkbox>
                                <b-form-checkbox id="checkbox-4_2" v-model="selected4_2" name="checkbox-4_2" :value="true" :unchecked-value="false">Uso de Andador</b-form-checkbox>
                            </b-form-group>               
                        </b-col>                                             
                    </b-row>
                    <b-row> 
                        <b-col md="4" sm="12">   
                            <b-form-group v-show="numberQuestion == 4" label="Relacionado Ao Captor Ocular:">
                                <b-form-checkbox id="checkbox-4_3" v-model="selected4_3" name="checkbox-4_3" :value="true" :unchecked-value="false">Uso de Óculos</b-form-checkbox>
                            </b-form-group>               
                        </b-col>                                             
                    </b-row>   
                    <b-row> 
                        <b-col md="4" sm="12">   
                            <b-form-group v-show="numberQuestion == 4" label="Relacionado Ao Captor Ocular:">
                                <b-form-checkbox id="checkbox-4_4" v-model="selected4_4" name="checkbox-4_4" :value="true" :unchecked-value="false">Tratamento Dentário</b-form-checkbox>
                                <b-form-checkbox id="checkbox-4_5" v-model="selected4_5" name="checkbox-4_5" :value="true" :unchecked-value="false">Amálgamas Dentários</b-form-checkbox>
                                <b-form-checkbox id="checkbox-4_6" v-model="selected4_6" name="checkbox-4_6" :value="true" :unchecked-value="false">Coroas Dentárias</b-form-checkbox>
                                <b-form-checkbox id="checkbox-4_7" v-model="selected4_7" name="checkbox-4_7" :value="true" :unchecked-value="false">Ortodontia</b-form-checkbox>
                                <b-form-checkbox id="checkbox-4_8" v-model="selected4_8" name="checkbox-4_8" :value="true" :unchecked-value="false">Aperto Dentário</b-form-checkbox>
                                <b-form-checkbox id="checkbox-4_9" v-model="selected4_9" name="checkbox-4_9" :value="true" :unchecked-value="false">Ranger dos Dentes</b-form-checkbox>
                            </b-form-group>               
                        </b-col>                                             
                    </b-row>       
                    <b-row> 
                        <b-col md="4" sm="12">   
                            <b-form-group v-show="numberQuestion == 4" label="Relacionado À Cicatriz:">
                                <b-form-group v-show="numberQuestion == 4" label="Antecedentes Cirúrgicos:">
                                    <b-form-radio v-model="selected4_10" name="some-radios-4_10" value="0">Sim</b-form-radio>
                                    <b-form-radio v-model="selected4_10" name="some-radios-4_10" value="1">Não</b-form-radio>
                                </b-form-group>
                                <b-form-checkbox id="checkbox-4_11" v-model="selected4_11" name="checkbox-4_11" :value="true" :unchecked-value="false">Cesárea</b-form-checkbox>
                                <b-form-checkbox id="checkbox-4_12" v-model="selected4_12" name="checkbox-4_12" :value="true" :unchecked-value="false">Abdominoplastia</b-form-checkbox>
                                <b-form-checkbox id="checkbox-4_13" v-model="selected4_13" name="checkbox-4_13" :value="true" :unchecked-value="false">Cardíaca</b-form-checkbox>
                                <b-form-checkbox id="checkbox-4_14" v-model="selected4_14" name="checkbox-4_14" :value="true" :unchecked-value="false">Outras</b-form-checkbox>
                                <b-form-input type="text" v-model="selected4_15" />
                            </b-form-group>               
                        </b-col>                                             
                    </b-row>                                                     
                </b-form-group>     

                <b-button v-show="numberQuestion == 4" variant="success" @click="numberQuestion = 5">Ok</b-button>       

                <b-form-group v-show="numberQuestion == 5" class="mt-3" label="Testes Referenciais:">
                   <b-row> 
                        <b-col>
                            <b-form-group v-show="numberQuestion == 5" label="Teste Pósturo-Estático:">
                                <b-form-group v-show="numberQuestion == 5" label="Plano Sagital:">
                                    <b-form-checkbox id="checkbox-5_1" v-model="selected5_1" name="checkbox-5_1" :value="true" :unchecked-value="false">Plano Escapular Anterior</b-form-checkbox>
                                    <b-form-checkbox id="checkbox-5_2" v-model="selected5_2" name="checkbox-5_2" :value="true" :unchecked-value="false">Plano Escapular Posterior</b-form-checkbox>
                                    <b-form-checkbox id="checkbox-5_3" v-model="selected5_3" name="checkbox-5_3" :value="true" :unchecked-value="false">Plano Alinhado</b-form-checkbox>
                                    <b-form-checkbox id="checkbox-5_4" v-model="selected5_4" name="checkbox-5_4" :value="true" :unchecked-value="false">Diminuição da Curvatura</b-form-checkbox>
                                    <b-form-checkbox id="checkbox-5_5" v-model="selected5_5" name="checkbox-5_5" :value="true" :unchecked-value="false">Aumento da Curvatura</b-form-checkbox>
                                </b-form-group>
                                <b-form-group v-show="numberQuestion == 5" label="Flechas:">
                                    <b-form-checkbox id="checkbox-5_6" v-model="selected5_6" name="checkbox-5_6" :value="true" :unchecked-value="false">Cervical</b-form-checkbox>
                                    <b-form-checkbox id="checkbox-5_7" v-model="selected5_7" name="checkbox-5_7" :value="true" :unchecked-value="false">Occipital</b-form-checkbox>
                                    <b-form-checkbox id="checkbox-5_8" v-model="selected5_8" name="checkbox-5_8" :value="true" :unchecked-value="false">Lombar</b-form-checkbox>
                                    <b-form-checkbox id="checkbox-5_9" v-model="selected5_9" name="checkbox-5_9" :value="true" :unchecked-value="false">Dorsal</b-form-checkbox>
                                    <b-form-checkbox id="checkbox-5_10" v-model="selected5_10" name="checkbox-5_10" :value="true" :unchecked-value="false">Glútea</b-form-checkbox>
                                </b-form-group>
                                <b-form-group v-show="numberQuestion == 5" label="Plano Frontal:">
                                    <b-form-group v-show="numberQuestion == 5" label="Báscula de Ombro:">
                                        <b-form-radio v-model="selected5_11" name="some-radios-5_11" value="0">Direita</b-form-radio>
                                        <b-form-radio v-model="selected5_11" name="some-radios-5_11" value="1">Esquerda</b-form-radio>
                                    </b-form-group>
                                    <b-form-group v-show="numberQuestion == 5" label="Báscula de Pelve:">
                                        <b-form-radio v-model="selected5_12" name="some-radios-5_12" value="0">Direita</b-form-radio>
                                        <b-form-radio v-model="selected5_12" name="some-radios-5_12" value="1">Esquerda</b-form-radio>
                                    </b-form-group>
                                </b-form-group>          
                                <b-form-group v-show="numberQuestion == 5" label="Plano Horizontal:">
                                    <b-form-group v-show="numberQuestion == 5" label="Rotação Anterior de Ombro:">
                                        <b-form-radio v-model="selected5_13" name="some-radios-5_13" value="0">Direita</b-form-radio>
                                        <b-form-radio v-model="selected5_13" name="some-radios-5_13" value="1">Esquerda</b-form-radio>
                                    </b-form-group>
                                    <b-form-group v-show="numberQuestion == 5" label="Rotação Anterior de Pelve:">
                                        <b-form-radio v-model="selected5_14" name="some-radios-5_14" value="0">Direita</b-form-radio>
                                        <b-form-radio v-model="selected5_14" name="some-radios-5_14" value="1">Esquerda</b-form-radio>
                                    </b-form-group>
                                </b-form-group>       
                                <b-form-group v-show="numberQuestion == 5" label="Limitação da Rotação da Cabeça:">
                                    <b-form-radio v-model="selected5_15" name="some-radios-5_15" value="0">Direita</b-form-radio>
                                    <b-form-radio v-model="selected5_15" name="some-radios-5_15" value="1">Esquerda</b-form-radio>
                                    <b-form-radio v-model="selected5_15" name="some-radios-5_15" value="2">Bilateral</b-form-radio>
                                    <b-form-radio v-model="selected5_15" name="some-radios-5_15" value="3">Sem Limitação</b-form-radio>
                                </b-form-group>         
                                <b-form-group v-show="numberQuestion == 5" label="Romberg Postural:">
                                    <b-form-radio v-model="selected5_16" name="some-radios-5_16" value="0">Ocular</b-form-radio>
                                    <b-form-radio v-model="selected5_16" name="some-radios-5_16" value="1">Podal</b-form-radio>
                                    <b-form-radio v-model="selected5_16" name="some-radios-5_16" value="2">Vestibular</b-form-radio>
                                </b-form-group>           
                                <b-form-group v-show="numberQuestion == 5" label="Romberg Em Tandem:">
                                    <b-form-radio v-model="selected5_17" name="some-radios-5_17" value="0">Positivo</b-form-radio>
                                    <b-form-radio v-model="selected5_17" name="some-radios-5_17" value="1">Negativo</b-form-radio>
                                </b-form-group>                                                                                                                                    
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form-group>

                <b-button v-show="numberQuestion == 5" variant="success" @click="numberQuestion = 6">Ok</b-button>      


                <b-form-group v-show="numberQuestion == 6" class="mt-3" label="Teste Pósturo-Dinâmico:">
                   <b-row> 
                        <b-col>
                            <b-form-group v-show="numberQuestion == 6" class="mt-3" label="Cervical:">
                                <b-form-checkbox id="checkbox-6_1" v-model="selected6_1" name="checkbox-6_1" :value="true" :unchecked-value="false">Esquerdo</b-form-checkbox>
                                <b-form-checkbox id="checkbox-6_2" v-model="selected6_2" name="checkbox-6_2" :value="true" :unchecked-value="false">Direito</b-form-checkbox>
                            </b-form-group>
                            <b-form-group v-show="numberQuestion == 6" class="mt-3" label="Torácico:">
                                <b-form-checkbox id="checkbox-6_3" v-model="selected6_3" name="checkbox-6_3" :value="true" :unchecked-value="false">Esquerdo</b-form-checkbox>
                                <b-form-checkbox id="checkbox-6_4" v-model="selected6_4" name="checkbox-6_4" :value="true" :unchecked-value="false">Direito</b-form-checkbox>
                            </b-form-group>
                            <b-form-group v-show="numberQuestion == 6" class="mt-3" label="Lombar:">
                                <b-form-checkbox id="checkbox-6_5" v-model="selected6_5" name="checkbox-6_5" :value="true" :unchecked-value="false">Esquerdo</b-form-checkbox>
                                <b-form-checkbox id="checkbox-6_6" v-model="selected6_6" name="checkbox-6_6" :value="true" :unchecked-value="false">Direito</b-form-checkbox>
                            </b-form-group>
                            <b-form-group v-show="numberQuestion == 6" class="mt-3" label="Podo-Pélvico:">
                                <b-form-checkbox id="checkbox-6_7" v-model="selected6_7" name="checkbox-6_7" :value="true" :unchecked-value="false">Esquerdo</b-form-checkbox>
                                <b-form-checkbox id="checkbox-6_8" v-model="selected6_8" name="checkbox-6_8" :value="true" :unchecked-value="false">Direito</b-form-checkbox>
                            </b-form-group>
                            <b-form-group v-show="numberQuestion == 6" label="Interpretação do Teste:">
                                <b-form-textarea id="textarea" v-model="selected6_9" placeholder="" rows="1" max-rows="6"></b-form-textarea>                  
                            </b-form-group>                             
                        </b-col>
                    </b-row>
                </b-form-group>

                <b-button v-show="numberQuestion == 6" variant="success" @click="numberQuestion = 7">Ok</b-button>    


                <b-form-group v-show="numberQuestion == 7" class="mt-3" label="Teste Informacional Maleolar (TIM):">
                   <b-row> 
                        <b-col>
                            <b-form-checkbox id="checkbox-7_1" v-model="selected7_1" name="checkbox-7_1" :value="true" :unchecked-value="false">Neural Posterior</b-form-checkbox>
                            <b-form-checkbox id="checkbox-7_2" v-model="selected7_2" name="checkbox-7_2" :value="true" :unchecked-value="false">Neuro-Vegetativo</b-form-checkbox>
                            <b-form-checkbox id="checkbox-7_3" v-model="selected7_3" name="checkbox-7_3" :value="true" :unchecked-value="false">Vascular</b-form-checkbox>
                            <b-form-checkbox id="checkbox-7_4" v-model="selected7_4" name="checkbox-7_4" :value="true" :unchecked-value="false">Neuro-Articular</b-form-checkbox>
                            <b-form-checkbox id="checkbox-7_5" v-model="selected7_5" name="checkbox-7_5" :value="true" :unchecked-value="false">Periósteo</b-form-checkbox>
                            <b-form-checkbox id="checkbox-7_6" v-model="selected7_6" name="checkbox-7_6" :value="true" :unchecked-value="false">Disco</b-form-checkbox>   
                        </b-col>
                    </b-row>
                </b-form-group>

                <b-button v-show="numberQuestion == 7" variant="success" @click="numberQuestion = 8">Ok</b-button>                                                                                                                           


                <b-form-group v-show="numberQuestion == 8" class="mt-3" label="Teste Vertical de Barré">
                    <b-row> 
                        <b-col md="4" sm="12">   
                            <b-form-checkbox id="checkbox-8_1" v-model="selected8_1" name="checkbox-8_1" :value="true" :unchecked-value="false">Ascendente</b-form-checkbox>
                            <b-form-checkbox id="checkbox-8_2" v-model="selected8_2" name="checkbox-8_2" :value="true" :unchecked-value="false">Descendente</b-form-checkbox>
                            <b-form-checkbox id="checkbox-8_3" v-model="selected8_3" name="checkbox-8_3" :value="true" :unchecked-value="false">Mista</b-form-checkbox>
                            <b-form-checkbox id="checkbox-8_4" v-model="selected8_4" name="checkbox-8_4" :value="true" :unchecked-value="false">Traumática</b-form-checkbox>
                        </b-col>                                             
                    </b-row>
                </b-form-group>      

                <b-button v-show="numberQuestion == 8" variant="success" @click="numberQuestion = 9">Ok</b-button>  


                <b-form-group v-show="numberQuestion == 9" class="mt-3" label="Manobra de Convergência Podal">
                    <b-row> 
                        <b-col md="4" sm="12">   
                            <b-form-checkbox id="checkbox-9_1" v-model="selected9_1" name="checkbox-9_1" :value="true" :unchecked-value="false">Captor Podal</b-form-checkbox>
                            <b-form-checkbox id="checkbox-9_2" v-model="selected9_2" name="checkbox-9_2" :value="true" :unchecked-value="false">Captor Ocular</b-form-checkbox>
                            <b-form-checkbox id="checkbox-9_3" v-model="selected9_3" name="checkbox-9_3" :value="true" :unchecked-value="false">Aparelho Manducatório</b-form-checkbox>
                            <b-form-checkbox id="checkbox-9_4" v-model="selected9_4" name="checkbox-9_4" :value="true" :unchecked-value="false">Cicatriz(es)</b-form-checkbox>
                            <b-form-checkbox id="checkbox-9_5" v-model="selected9_5" name="checkbox-9_5" :value="true" :unchecked-value="false">Sistema Visceral</b-form-checkbox>
                        </b-col>                                             
                    </b-row>
                </b-form-group>     

                <b-button v-show="numberQuestion == 9" variant="success" @click="numberQuestion = 10">Ok</b-button>          


                <b-form-group v-show="numberQuestion == 10" class="mt-3" label="Testes Específicos - Captor Podal">
                    <b-row> 
                        <b-col md="4" sm="12">   
                            <b-form-group v-show="numberQuestion == 10" label="Apoio Bipodal:">
                                <b-form-group v-show="numberQuestion == 10" label="Valgo">
                                    <b-form-checkbox id="checkbox-10_1" v-model="selected10_1" name="checkbox-10_1" :value="true" :unchecked-value="false">D</b-form-checkbox>
                                    <b-form-checkbox id="checkbox-10_2" v-model="selected10_2" name="checkbox-10_2" :value="true" :unchecked-value="false">E</b-form-checkbox>
                                </b-form-group>
                                <b-form-group v-show="numberQuestion == 10" label="Varo">
                                    <b-form-checkbox id="checkbox-10_3" v-model="selected10_3" name="checkbox-10_3" :value="true" :unchecked-value="false">D</b-form-checkbox>
                                    <b-form-checkbox id="checkbox-10_4" v-model="selected10_4" name="checkbox-10_4" :value="true" :unchecked-value="false">E</b-form-checkbox>
                                </b-form-group>    
                                <b-form-group v-show="numberQuestion == 10" label="Neutro">
                                    <b-form-checkbox id="checkbox-10_5" v-model="selected10_5" name="checkbox-10_5" :value="true" :unchecked-value="false">D</b-form-checkbox>
                                    <b-form-checkbox id="checkbox-10_6" v-model="selected10_6" name="checkbox-10_6" :value="true" :unchecked-value="false">E</b-form-checkbox>
                                </b-form-group>                                                            
                            </b-form-group>               
                        </b-col>                                             
                    </b-row>
                    <b-row> 
                        <b-col md="4" sm="12">   
                            <b-form-group v-show="numberQuestion == 10" label="Apoio Podal:">
                                <b-form-group v-show="numberQuestion == 10" label="Valgo">
                                    <b-form-checkbox id="checkbox-10_7" v-model="selected10_7" name="checkbox-10_7" :value="true" :unchecked-value="false">D</b-form-checkbox>
                                    <b-form-checkbox id="checkbox-10_8" v-model="selected10_8" name="checkbox-10_8" :value="true" :unchecked-value="false">E</b-form-checkbox>
                                </b-form-group>
                                <b-form-group v-show="numberQuestion == 10" label="Varo">
                                    <b-form-checkbox id="checkbox-10_9" v-model="selected10_9" name="checkbox-10_9" :value="true" :unchecked-value="false">D</b-form-checkbox>
                                    <b-form-checkbox id="checkbox-10_10" v-model="selected10_10" name="checkbox-10_10" :value="true" :unchecked-value="false">E</b-form-checkbox>
                                </b-form-group>    
                                <b-form-group v-show="numberQuestion == 10" label="Neutro">
                                    <b-form-checkbox id="checkbox-10_11" v-model="selected10_11" name="checkbox-10_11" :value="true" :unchecked-value="false">D</b-form-checkbox>
                                    <b-form-checkbox id="checkbox-10_12" v-model="selected10_12" name="checkbox-10_12" :value="true" :unchecked-value="false">E</b-form-checkbox>
                                </b-form-group>                                                            
                            </b-form-group>  

                            <b-form-group v-show="numberQuestion == 10" label="Tipos de Pés:">
                                <b-form-radio v-model="selected10_13" name="some-radios-10_13" value="0">Simétricos</b-form-radio>
                                <b-form-radio v-model="selected10_13" name="some-radios-10_13" value="1">Assimétricos</b-form-radio>
                                <b-form-radio v-model="selected10_13" name="some-radios-10_13" value="2">Desarmônicos</b-form-radio>
                            </b-form-group>                                         
                        </b-col>                                             
                    </b-row>                    
                </b-form-group>     

                <b-button v-show="numberQuestion == 10" variant="success" @click="numberQuestion = 11">Ok</b-button>   


                <b-form-group v-show="numberQuestion == 11" class="mt-3" label="Teste de Perna Curta">
                    <b-row> 
                        <b-col md="4" sm="12">   
                            <b-form-group v-show="numberQuestion == 11" label="Três Parâmetros:">
                                <b-form-group v-show="numberQuestion == 11" label="Fossetas de Michaelis e Espinha Ilíaca Póstero-Superior Mais Baixa">
                                    <b-form-checkbox id="checkbox-11_1" v-model="selected11_1" name="checkbox-11_1" :value="true" :unchecked-value="false">Direita</b-form-checkbox>
                                    <b-form-checkbox id="checkbox-11_2" v-model="selected11_2" name="checkbox-11_2" :value="true" :unchecked-value="false">Esquerda</b-form-checkbox>
                                </b-form-group>  
                                <b-form-group v-show="numberQuestion == 11" label="Vista Tangencial Mais Baixa">
                                    <b-form-checkbox id="checkbox-11_3" v-model="selected11_3" name="checkbox-11_3" :value="true" :unchecked-value="false">Direita</b-form-checkbox>
                                    <b-form-checkbox id="checkbox-11_4" v-model="selected11_4" name="checkbox-11_4" :value="true" :unchecked-value="false">Esquerda</b-form-checkbox>
                                </b-form-group>        
                                <b-form-group v-show="numberQuestion == 11" label="Rotação Posterior da Pelve">
                                    <b-form-checkbox id="checkbox-11_5" v-model="selected11_5" name="checkbox-11_5" :value="true" :unchecked-value="false">Direita</b-form-checkbox>
                                    <b-form-checkbox id="checkbox-11_6" v-model="selected11_6" name="checkbox-11_6" :value="true" :unchecked-value="false">Esquerda</b-form-checkbox>
                                </b-form-group>        
                                <b-form-group v-show="numberQuestion == 11">
                                    <b-form-checkbox id="checkbox-11_7" v-model="selected11_7" name="checkbox-11_7" :value="true" :unchecked-value="false">Presença P.C</b-form-checkbox>
                                    <b-form-checkbox id="checkbox-11_8" v-model="selected11_8" name="checkbox-11_8" :value="true" :unchecked-value="false">Direita</b-form-checkbox>
                                    <b-form-checkbox id="checkbox-11_9" v-model="selected11_9" name="checkbox-11_9" :value="true" :unchecked-value="false">Esquerda</b-form-checkbox>
                                    <b-form-input type="text" v-model="selected11_10" placeholder="Tamanho Total da Perna Curta:"/>
                                    <b-form-input type="text" v-model="selected11_11" placeholder="Corrigir:"/>
                                    <b-form-checkbox id="checkbox-11_12" v-model="selected11_12" name="checkbox-11_12" :value="true" :unchecked-value="false">Ausência P.C</b-form-checkbox>
                                </b-form-group>   
                                <b-form-group v-show="numberQuestion == 11" label="Derrotação de Quadril">
                                    <b-form-checkbox id="checkbox-11_13" v-model="selected11_13" name="checkbox-11_13" :value="true" :unchecked-value="false">Direito</b-form-checkbox>
                                    <b-form-checkbox id="checkbox-11_14" v-model="selected11_14" name="checkbox-11_14" :value="true" :unchecked-value="false">Esquerdo</b-form-checkbox>
                                </b-form-group>                                                                                                                      
                                <b-form-group v-show="numberQuestion == 11" label="Teste EIAP:">
                                    <b-form-radio v-model="selected11_15" name="some-radios-11_15" value="0">Positivo</b-form-radio>
                                    <b-form-radio v-model="selected11_15" name="some-radios-11_15" value="1">Negativo</b-form-radio>                                
                                </b-form-group>                                                                                                                      
                            </b-form-group>               
                        </b-col>                                             
                    </b-row>
                </b-form-group>     

                <b-button v-show="numberQuestion == 11" variant="success" @click="numberQuestion = 12">Ok</b-button>      


                <b-form-group v-show="numberQuestion == 12" class="mt-3">
                    <b-row> 
                        <b-col md="4" sm="12">   
                            <b-form-group v-show="numberQuestion == 12" label="Captor Ocular:">
                                <b-form-group class="mt-2" v-show="numberQuestion == 12" label="Insuficiência de Convergência:">    
                                    <b-form-checkbox id="checkbox-12_1" v-model="selected12_1" name="checkbox-12_1" :value="true" :unchecked-value="false">Direita</b-form-checkbox>
                                    <b-form-checkbox id="checkbox-12_2" v-model="selected12_2" name="checkbox-12_2" :value="true" :unchecked-value="false">Esquerda</b-form-checkbox>
                                </b-form-group>  
                                <b-form-group class="mt-2" v-show="numberQuestion == 12" label="Convergência Reflexa:">    
                                    <b-form-checkbox id="checkbox-12_3" v-model="selected12_3" name="checkbox-12_3" :value="true" :unchecked-value="false">Direita</b-form-checkbox>
                                    <b-form-checkbox id="checkbox-12_4" v-model="selected12_4" name="checkbox-12_4" :value="true" :unchecked-value="false">Esquerda</b-form-checkbox>
                                </b-form-group>     
                                <b-form-group class="mt-2" v-show="numberQuestion == 12" label="Conver Test:">    
                                    <b-form-checkbox id="checkbox-12_5" v-model="selected12_5" name="checkbox-12_5" :value="true" :unchecked-value="false">Exoforia</b-form-checkbox>
                                    <b-form-checkbox id="checkbox-12_6" v-model="selected12_6" name="checkbox-12_6" :value="true" :unchecked-value="false">Esoforia</b-form-checkbox>
                                    <b-form-checkbox id="checkbox-12_7" v-model="selected12_7" name="checkbox-12_7" :value="true" :unchecked-value="false">Hiperforia</b-form-checkbox>
                                    <b-form-checkbox id="checkbox-12_8" v-model="selected12_8" name="checkbox-12_8" :value="true" :unchecked-value="false">Hipoforia</b-form-checkbox>
                                </b-form-group>                                                                  
                            </b-form-group>               
                        </b-col>                                             
                    </b-row>
                </b-form-group>     

                <b-button v-show="numberQuestion == 12" variant="success" @click="numberQuestion = 13">Ok</b-button>  


                <b-form-group v-show="numberQuestion == 13" class="mt-3">
                    <b-row> 
                        <b-col md="4" sm="12">   
                            <b-form-group v-show="numberQuestion == 13" label="Aparelho Manducatório:">
                                <b-form-group class="mt-2" v-show="numberQuestion == 13" label="Exame dos Freios Labiais:">    
                                    <b-form-checkbox id="checkbox-13_1" v-model="selected13_1" name="checkbox-13_1" :value="true" :unchecked-value="false">Funcional</b-form-checkbox>
                                    <b-form-checkbox id="checkbox-13_2" v-model="selected13_2" name="checkbox-13_2" :value="true" :unchecked-value="false">Oclusal</b-form-checkbox>
                                    <b-form-checkbox id="checkbox-13_3" v-model="selected13_3" name="checkbox-13_3" :value="true" :unchecked-value="false">Normal</b-form-checkbox>
                                </b-form-group>     
                                <b-form-group class="mt-2" v-show="numberQuestion == 13" label="Teste de Compressa:">    
                                    <b-form-radio v-model="selected13_4" name="some-radios-13_4" value="0">Houve Melhora</b-form-radio>
                                    <b-form-radio v-model="selected13_4" name="some-radios-13_4" value="1">Não Houve Melhora</b-form-radio>                                
                                </b-form-group>    
                                <b-form-group class="mt-2" v-show="numberQuestion == 13" label="Abertura Bucal:">  
                                    <b-form-group class="mt-2" v-show="numberQuestion == 13" label="Com Desvios:">  
                                        <b-form-checkbox id="checkbox-13_5" v-model="selected13_5" name="checkbox-13_5" :value="true" :unchecked-value="false">Direita</b-form-checkbox>
                                        <b-form-checkbox id="checkbox-13_6" v-model="selected13_6" name="checkbox-13_6" :value="true" :unchecked-value="false">Esquerda</b-form-checkbox>
                                    </b-form-group>
                                    <b-form-group class="mt-2" v-show="numberQuestion == 13">  
                                        <b-form-checkbox id="checkbox-13_7" v-model="selected13_7" name="checkbox-13_7" :value="true" :unchecked-value="false">Sem Desvios</b-form-checkbox>
                                    </b-form-group>
                                    <b-form-group class="mt-2" v-show="numberQuestion == 13" label="Amplitude Bucal:">  
                                        <b-form-checkbox id="checkbox-13_8" v-model="selected13_8" name="checkbox-13_8" :value="true" :unchecked-value="false">Diminuída</b-form-checkbox>
                                        <b-form-checkbox id="checkbox-13_9" v-model="selected13_9" name="checkbox-13_9" :value="true" :unchecked-value="false">Normal</b-form-checkbox>
                                    </b-form-group>
                                    <b-form-group class="mt-2" v-show="numberQuestion == 13" label="Ruídos Articulares:">  
                                        <b-form-checkbox id="checkbox-13_10" v-model="selected13_10" name="checkbox-13_10" :value="true" :unchecked-value="false">Direita</b-form-checkbox>
                                        <b-form-checkbox id="checkbox-13_11" v-model="selected13_11" name="checkbox-13_11" :value="true" :unchecked-value="false">Esquerdo</b-form-checkbox>
                                    </b-form-group>
                                    <b-form-group class="mt-2" v-show="numberQuestion == 13">  
                                        <b-form-checkbox id="checkbox-13_12" v-model="selected13_12" name="checkbox-13_12" :value="true" :unchecked-value="false">Sem Ruídos</b-form-checkbox>
                                    </b-form-group>
                                </b-form-group>    
                                <b-form-group class="mt-2" v-show="numberQuestion == 13" label="Cicatrizes Patológicas">  
                                    <b-form-group class="mt-2" v-show="numberQuestion == 13" label="Teste do Pulso Radial:"> 
                                        <b-form-radio v-model="selected13_13" name="some-radios-13_13" value="0">Positivo</b-form-radio>
                                        <b-form-radio v-model="selected13_13" name="some-radios-13_13" value="1">Negativo</b-form-radio>
                                    </b-form-group>
                                    <b-form-group class="mt-2" v-show="numberQuestion == 13" label="Neutralização da Cicatriz:"> 
                                        <b-form-radio v-model="selected13_14" name="some-radios-13_14" value="0">Houve Melhora</b-form-radio>
                                        <b-form-radio v-model="selected13_14" name="some-radios-13_14" value="1">Não Houve Melhora</b-form-radio>
                                    </b-form-group>       
                                    <b-form-group class="mt-2" v-show="numberQuestion == 13" label="Microgalvanismo ou Plurimetalismo:"> 
                                        <b-form-radio v-model="selected13_15" name="some-radios-13_15" value="0">Presente</b-form-radio>
                                        <b-form-radio v-model="selected13_15" name="some-radios-13_15" value="1">Ausente</b-form-radio>
                                    </b-form-group>                                                                    
                                </b-form-group>                                                                                                                                                                                                                                                                          
                            </b-form-group>               
                        </b-col>                                             
                    </b-row>
                </b-form-group>     

                <b-button v-show="numberQuestion == 13" variant="success" @click="numberQuestion = 14">Ok</b-button>        


                <b-form-group v-show="numberQuestion == 14" class="mt-3">
                    <b-row> 
                        <b-col md="4" sm="12">   
                            <b-form-group v-show="numberQuestion == 14" label="Diagnóstico:">
                                <b-form-textarea id="textarea" v-model="selected14" placeholder="" rows="1" max-rows="6"></b-form-textarea>                                                                                                                                                                               
                            </b-form-group>               
                        </b-col>                                             
                    </b-row>
                </b-form-group>     

                <b-button v-show="numberQuestion == 14" variant="success" @click="numberQuestion = 15">Ok</b-button>      


                <b-form-group v-show="numberQuestion == 15" class="mt-3">
                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 15" label="Teste Referencial Para Definição do Sistema a Ser Tratado:">
                                <b-form-checkbox id="checkbox-15_1" v-model="selected15_1" name="checkbox-15_1" :value="true" :unchecked-value="false">Sistema Musculoesquelético</b-form-checkbox>
                                <b-form-checkbox id="checkbox-15_2" v-model="selected15_2" name="checkbox-15_2" :value="true" :unchecked-value="false">Sistema Tônico Postural (Captores)</b-form-checkbox>
                                <b-form-checkbox id="checkbox-15_3" v-model="selected15_3" name="checkbox-15_3" :value="true" :unchecked-value="false">Vias de Comunicação</b-form-checkbox>                                
                                <b-form-checkbox id="checkbox-15_4" v-model="selected15_4" name="checkbox-15_4" :value="true" :unchecked-value="false">Sistema Vascular</b-form-checkbox>
                                <b-form-checkbox id="checkbox-15_5" v-model="selected15_5" name="checkbox-15_5" :value="true" :unchecked-value="false">Sistema Visceral</b-form-checkbox>
                                <b-form-checkbox id="checkbox-15_6" v-model="selected15_6" name="checkbox-15_6" :value="true" :unchecked-value="false">Sistema Craniano</b-form-checkbox>
                                <b-form-checkbox id="checkbox-15_7" v-model="selected15_7" name="checkbox-15_7" :value="true" :unchecked-value="false">Sistema Biológico</b-form-checkbox>
                            </b-form-group>                
                        </b-col>                                                                       
                    </b-row>   
                </b-form-group>     

                <b-button v-show="numberQuestion == 15" variant="success" @click="numberQuestion = 16">Ok</b-button>          


                <b-form-group v-show="numberQuestion == 16" class="mt-3" label="Objetivos do Tratamento:">
                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-textarea id="textarea" v-model="selected16" placeholder="" rows="1" max-rows="6"></b-form-textarea>                   
                        </b-col>                                                                       
                    </b-row>                                                               
                </b-form-group>     

                <b-button v-show="numberQuestion == 16" variant="success" @click="numberQuestion = 17">Ok</b-button>    


                <b-form-group v-show="numberQuestion == 17" class="mt-3" label="Observações e Conduta Terapêutica Após Cada Sessão:">
                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-textarea id="textarea" v-model="selected17" placeholder="" rows="1" max-rows="6"></b-form-textarea> 
                        </b-col>                                                                       
                    </b-row>                                                                                                   
                </b-form-group>                                             
            </b-card>
        </div>
    </div>
</template>

<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'
import Moment from 'moment'

export default {
    name: "AssessmentPelvic",
    components: { PageTitle },
    data() {
      return {
        theme: null,
        patient: null,
        assessment: null,
        numberQuestion: 1,
        selected1_1: null,
        selected1_2: null,
        selected1_3: null,
        selected1_4: null,
        selected1_5: null,
        selected1_6: null,
        selected1_7: null,      
        selected1_8: null, 
        selected1_9: null, 
        selected1_10: null, 
        selected1_11: null, 
        selected1_12: null, 
        selected1_13: null,
        selected1_14: null,
        selected1_15: null,
        selected1_16: null,
        selected1_17: null,
        selected1_18: null,
        selected2_1: null,
        selected2_2: null,
        selected2_3: null,
        selected2_4: null,
        selected2_5: null,
        selected2_6: null,
        selected3_1: false,
        selected3_2: false,
        selected3_3: false,
        selected3_4: false,
        selected4_1: false,   
        selected4_2: false,
        selected4_3: false,
        selected4_4: false,
        selected4_5: false,
        selected4_6: false,
        selected4_7: false,      
        selected4_8: false, 
        selected4_9: false, 
        selected4_10: null, 
        selected4_11: false, 
        selected4_12: false, 
        selected4_13: false,
        selected4_14: false,
        selected4_15: null,       
        selected5_1: false,   
        selected5_2: false,
        selected5_3: false,
        selected5_4: false,
        selected5_5: false,
        selected5_6: false,
        selected5_7: false,      
        selected5_8: false, 
        selected5_9: false, 
        selected5_10: false, 
        selected5_11: null, 
        selected5_12: null, 
        selected5_13: null,
        selected5_14: null,
        selected5_15: null,
        selected5_16: null,
        selected5_17: null,
        selected6_1: false,   
        selected6_2: false,
        selected6_3: false,
        selected6_4: false,
        selected6_5: false,
        selected6_6: false,
        selected6_7: false,      
        selected6_8: false, 
        selected6_9: null,     
        selected7_1: false,   
        selected7_2: false,
        selected7_3: false,
        selected7_4: false,
        selected7_5: false,
        selected7_6: false,    
        selected8_1: false,   
        selected8_2: false,
        selected8_3: false,
        selected8_4: false,
        selected9_1: false,   
        selected9_2: false,
        selected9_3: false,
        selected9_4: false,
        selected9_5: false,
        selected10_1: false,   
        selected10_2: false,
        selected10_3: false,
        selected10_4: false,
        selected10_5: false,
        selected10_6: false,
        selected10_7: false,      
        selected10_8: false, 
        selected10_9: false, 
        selected10_10: null,   
        selected11_1: false,   
        selected11_2: false,
        selected11_3: false,
        selected11_4: false,
        selected11_5: false,
        selected11_6: false,
        selected11_7: false,      
        selected11_8: false, 
        selected11_9: false, 
        selected11_10: null, 
        selected11_11: null, 
        selected11_12: false, 
        selected11_13: false,
        selected11_14: false,
        selected11_15: null,   
        selected12_1: false,   
        selected12_2: false,
        selected12_3: false,
        selected12_4: false,
        selected12_5: false,
        selected12_6: false,
        selected12_7: false,      
        selected12_8: false,   
        selected13_1: false,   
        selected13_2: false,
        selected13_3: false,
        selected13_4: null,
        selected13_5: false,
        selected13_6: false,
        selected13_7: false,      
        selected13_8: false, 
        selected13_9: false, 
        selected13_10: false, 
        selected13_11: false, 
        selected13_12: false, 
        selected13_13: null,
        selected13_14: null,
        selected13_15: null,   
        selected14: null,
        selected15_1: false,   
        selected15_2: false,
        selected15_3: false,
        selected15_4: null,
        selected15_5: false,
        selected15_6: false,
        selected15_7: false,
        selected16: null,
        selected17: null
      }
    },
    methods: {
        sendQuestions() {
            // if ((this.selected1_1 == null && this.selected1_2 == null && this.selected1_3 == null &&
            //     this.selected1_4 == null && this.selected1_5 == null && this.selected1_6 == null &&
            //     this.selected1_7 == null && this.selected1_8 == null && this.selected1_9 == null &&
            //     this.selected1_10 == null && this.selected1_11 == null && this.selected1_12 == null &&
            //     this.selected1_13 == null && this.selected1_14 == null && this.selected1_15 == null &&
            //     this.selected1_16 == null && this.selected1_17 == null && this.selected1_18 == null) ||
            //    (this.selected2_1 == null && this.selected2_2 == null && this.selected2_3 == null &&
            //     this.selected2_4 == null && this.selected2_5 == null && this.selected2_6 == null) ||
            //    (this.selected3_1 == false && this.selected3_2 == false && this.selected3_3 == false &&
            //     this.selected3_4 == false) ||
            //    (this.selected4_1 == false && this.selected4_2 == false && this.selected4_3 == false &&
            //     this.selected4_4 == false && this.selected4_5 == false && this.selected4_6 == false &&
            //     this.selected4_7 == false && this.selected4_8 == false && this.selected4_9 == false &&
            //     this.selected4_10 == null && this.selected4_11 == false && this.selected4_12 == false &&
            //     this.selected4_13 == false && this.selected4_14 == false && this.selected4_15 == null) ||
            //    (this.selected5_1 == false && this.selected5_2 == false && this.selected5_3 == false &&
            //     this.selected5_4 == false && this.selected5_5 == false && this.selected5_6 == false &&
            //     this.selected5_7 == false && this.selected5_8 == false && this.selected5_9 == false &&
            //     this.selected5_10 == false && this.selected5_11 == null && this.selected5_12 == null &&
            //     this.selected5_13 == null && this.selected5_14 == null && this.selected5_15 == null &&
            //     this.selected5_16 == null && this.selected5_17 == null) ||
            //    (this.selected6_1 == false && this.selected6_2 == false && this.selected6_3 == false &&
            //     this.selected6_4 == false && this.selected6_5 == false && this.selected6_6 == false &&
            //     this.selected6_7 == false && this.selected6_8 == false && this.selected6_9 == null) ||
            //    (this.selected7_1 == false && this.selected7_2 == false && this.selected7_3 == false &&
            //     this.selected7_4 == false && this.selected7_5 == false && this.selected7_6 == false) ||
            //    (this.selected8_1 == false && this.selected8_2 == false && this.selected8_3 == false &&
            //     this.selected8_4 == false) ||
            //    (this.selected9_1 == false && this.selected9_2 == false && this.selected9_3 == false &&
            //     this.selected9_4 == false && this.selected9_5 == false) ||
            //    (this.selected10_1 == false && this.selected10_2 == false && this.selected10_3 == false &&
            //     this.selected10_4 == false && this.selected10_5 == false && this.selected10_6 == false &&
            //     this.selected10_7 == false && this.selected10_8 == false && this.selected10_9 == false &&
            //     this.selected10_10 == false && this.selected10_11 == false && this.selected10_12 == false &&
            //     this.selected10_13 == null) ||
            //    (this.selected11_1 == false && this.selected11_2 == false && this.selected11_3 == false &&
            //     this.selected11_4 == false && this.selected11_5 == false && this.selected11_6 == false &&
            //     this.selected11_7 == false && this.selected11_8 == false && this.selected11_9 == false &&
            //     this.selected11_10 == null && this.selected11_11 == null && this.selected11_12 == false &&
            //     this.selected11_13 == false && this.selected11_14 == false && this.selected11_15 == null) ||
            //    (this.selected12_1 == false && this.selected12_2 == false && this.selected12_3 == false &&
            //     this.selected12_4 == false && this.selected12_5 == false && this.selected12_6 == false &&
            //     this.selected12_7 == false && this.selected12_8 == false) ||
            //    (this.selected13_1 == false && this.selected13_2 == false && this.selected13_3 == false &&
            //     this.selected13_4 == null && this.selected13_5 == false && this.selected13_6 == false &&
            //     this.selected13_7 == false && this.selected13_8 == false && this.selected13_9 == false &&
            //     this.selected13_10 == false && this.selected13_11 == false && this.selected13_12 == false &&
            //     this.selected13_13 == null && this.selected13_14 == null && this.selected13_15 == null) ||
            //     this.selected14 == null ||
            //    (this.selected15_1 == false && this.selected15_2 == false && this.selected15_3 == false &&
            //     this.selected15_4 == null && this.selected15_5 == false && this.selected15_6 == false &&
            //     this.selected15_7 == false) || this.selected16 == null || this.selected17 == null){
            //   this.$toasted.global.defaultError({ msg: 'Preencha todos os itens da avaliação' })
            // } else {
                Moment.locale('pt-br')

                axios.get(`${baseApiUrl}/patient/getById/${this.patient._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`).then(res => {
                this.patient = res.data

                for (let q of this.patient.questions) {
                    if (q.type == 1 && q.name == this.assessment.name) {
                        q.answers = []

                        q.status = 1
                        q.answers.push(
                            {
                            'dateAnswers': Moment(new Date()).format('L')
                            }, 
                            {
                            '1_1': this.selected1_1,
                            '1_2': this.selected1_2,
                            '1_3': this.selected1_3,
                            '1_4': this.selected1_4,
                            '1_5': this.selected1_5,
                            '1_6': this.selected1_6,
                            '1_7': this.selected1_7,
                            '1_8': this.selected1_8,
                            '1_9': this.selected1_9,
                            '1_10': this.selected1_10,
                            '1_11': this.selected1_11,
                            '1_12': this.selected1_12,
                            '1_13': this.selected1_13,
                            '1_14': this.selected1_14,
                            '1_15': this.selected1_15,
                            '1_16': this.selected1_16,
                            '1_17': this.selected1_17,
                            '1_18': this.selected1_18
                            },
                            {
                            '2_1': this.selected2_1,
                            '2_2': this.selected2_2,
                            '2_3': this.selected2_3,
                            '2_4': this.selected2_4,
                            '2_5': this.selected2_5,
                            '2_6': this.selected2_6,
                            },
                            {
                            '3_1': this.selected3_1,
                            '3_2': this.selected3_2,
                            '3_3': this.selected3_3,
                            '3_4': this.selected3_4
                            },
                            {
                            '4_1': this.selected4_1,
                            '4_2': this.selected4_2,
                            '4_3': this.selected4_3,
                            '4_4': this.selected4_4,
                            '4_5': this.selected4_5,
                            '4_6': this.selected4_6,
                            '4_7': this.selected4_7,
                            '4_8': this.selected4_8,
                            '4_9': this.selected4_9,
                            '4_10': this.selected4_10,
                            '4_11': this.selected4_11,
                            '4_12': this.selected4_12,
                            '4_13': this.selected4_13,
                            '4_14': this.selected4_14,
                            '4_15': this.selected4_15
                            },
                            {
                            '5_1': this.selected5_1,
                            '5_2': this.selected5_2,
                            '5_3': this.selected5_3,
                            '5_4': this.selected5_4,
                            '5_5': this.selected5_5,
                            '5_6': this.selected5_6,
                            '5_7': this.selected5_7,
                            '5_8': this.selected5_8,
                            '5_9': this.selected5_9,
                            '5_10': this.selected5_10,
                            '5_11': this.selected5_11,
                            '5_12': this.selected5_12,
                            '5_13': this.selected5_13,
                            '5_14': this.selected5_14,
                            '5_15': this.selected5_15,
                            '5_16': this.selected5_16,
                            '5_17': this.selected5_17,
                            },
                            {
                            '6_1': this.selected6_1,
                            '6_2': this.selected6_2,
                            '6_3': this.selected6_3,
                            '6_4': this.selected6_4,
                            '6_5': this.selected6_5,
                            '6_6': this.selected6_6,
                            '6_7': this.selected6_7,
                            '6_8': this.selected6_8,
                            '6_9': this.selected6_9
                            },                        
                            {
                            '7_1': this.selected7_1, 
                            '7_2': this.selected7_2, 
                            '7_3': this.selected7_3, 
                            '7_4': this.selected7_4, 
                            '7_5': this.selected7_5, 
                            '7_6': this.selected7_6
                            },
                            {
                            '8_1': this.selected8_1,
                            '8_2': this.selected8_2,
                            '8_3': this.selected8_3,
                            '8_4': this.selected8_4 
                            },
                            {
                            '9_1': this.selected9_1, 
                            '9_2': this.selected9_2, 
                            '9_3': this.selected9_3, 
                            '9_4': this.selected9_4, 
                            '9_5': this.selected9_5
                            },
                            {
                            '10_1': this.selected10_1, 
                            '10_2': this.selected10_2, 
                            '10_3': this.selected10_3,
                            '10_4': this.selected10_4, 
                            '10_5': this.selected10_5, 
                            '10_6': this.selected10_6, 
                            '10_7': this.selected10_7, 
                            '10_8': this.selected10_8, 
                            '10_9': this.selected10_9, 
                            '10_10': this.selected10_10, 
                            '10_12': this.selected10_12, 
                            '10_13': this.selected10_13 
                            },
                            {
                            '11_1': this.selected11_1, 
                            '11_2': this.selected11_2, 
                            '11_3': this.selected11_3,
                            '11_4': this.selected11_4, 
                            '11_5': this.selected11_5, 
                            '11_6': this.selected11_6,
                            '11_7': this.selected11_7, 
                            '11_8': this.selected11_8,
                            '11_9': this.selected11_9,
                            '11_10': this.selected11_10,
                            '11_11': this.selected11_11,
                            '11_12': this.selected11_12,
                            '11_13': this.selected11_13,
                            '11_14': this.selected11_14,
                            '11_15': this.selected11_15                                                   
                            },
                            {
                            '12_1': this.selected12_1, 
                            '12_2': this.selected12_2, 
                            '12_3': this.selected12_3,
                            '12_4': this.selected12_4, 
                            '12_5': this.selected12_5, 
                            '12_6': this.selected12_6,
                            '12_7': this.selected12_7, 
                            '12_8': this.selected12_8
                            },
                            {
                            '13_1': this.selected13_1, 
                            '13_2': this.selected13_2, 
                            '13_3': this.selected13_3,
                            '13_4': this.selected13_4, 
                            '13_5': this.selected13_5, 
                            '13_6': this.selected13_6,
                            '13_7': this.selected13_7,
                            '13_8': this.selected13_8,
                            '13_9': this.selected13_9,
                            '13_10': this.selected13_10,
                            '13_11': this.selected13_11,
                            '13_12': this.selected13_12,
                            '13_13': this.selected13_13,
                            '13_14': this.selected13_14,
                            '13_15': this.selected13_15
                            },
                            {
                            '14': this.selected14  
                            },
                            {
                            '15_1': this.selected15_1,
                            '15_2': this.selected15_2,
                            '15_3': this.selected15_3,
                            '15_4': this.selected15_4,
                            '15_5': this.selected15_5,
                            '15_6': this.selected15_6,
                            '15_7': this.selected15_7
                            },
                            {
                            '16': this.selected16
                            },
                            {
                            '17': this.selected17
                            }                                                                                                                                                                                                                                                                                                                                                                                                                                                             
                        ) 
                    }
                }            

                const method = 'put'            
                const id = `/${this.patient._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`

                axios[method](`${baseApiUrl}/patient${id}`, this.patient)
                    .then(() => {
                        this.$toasted.global.defaultSuccess()
                        this.$router.push({name: 'notation', params: { },})
                    })  
                    .catch(showError) 
                }).catch((e) => {
                    console.log(e)
                    showError
                })
            }             
        // }
    },
    mounted() {
      Moment.locale('pt-br') 

      this.theme = localStorage.getItem('theme')

      this.patient = this.$route.params.questionSelected.patient
      this.assessment = this.$route.params.questionSelected      
      
      if (this.$route.params.questionSelected.answers.length == 0) {
        this.selected1_1 = this.patient.name

        if (this.patient.gender == '1') {
            this.selected1_2 = 'M'
        } else {
            this.selected1_2 = 'F'
        }

        let dtIni = Moment(new Date())
        let dtFim = Moment(this.patient.birthday)  

        let age = dtIni.diff(dtFim, 'years')

        this.selected1_3 = this.patient.birthday
        this.selected1_4 = age
        this.selected1_8 = this.patient.email
        this.selected1_10 = this.patient.phone1
        this.selected1_11 = this.patient.address.street + ', ' + this.patient.address.number
        this.selected1_12 = this.patient.address.neighborhood
        this.selected1_13 = this.patient.address.cep
        this.selected1_14 = this.patient.address.city
        this.selected1_15 = this.patient.address.state
      } else {
        this.selected1_1 = this.$route.params.questionSelected.answers[1]['1_1']
        this.selected1_2 = this.$route.params.questionSelected.answers[1]['1_2']
        this.selected1_3 = this.$route.params.questionSelected.answers[1]['1_3']
        this.selected1_4 = this.$route.params.questionSelected.answers[1]['1_4']
        this.selected1_5 = this.$route.params.questionSelected.answers[1]['1_5']
        this.selected1_6 = this.$route.params.questionSelected.answers[1]['1_6']
        this.selected1_7 = this.$route.params.questionSelected.answers[1]['1_7']  
        this.selected1_8 = this.$route.params.questionSelected.answers[1]['1_8']
        this.selected1_9 = this.$route.params.questionSelected.answers[1]['1_9']
        this.selected1_10 = this.$route.params.questionSelected.answers[1]['1_10']    
        this.selected1_11 = this.$route.params.questionSelected.answers[1]['1_11']
        this.selected1_12 = this.$route.params.questionSelected.answers[1]['1_12']
        this.selected1_13 = this.$route.params.questionSelected.answers[1]['1_13']
        this.selected1_14 = this.$route.params.questionSelected.answers[1]['1_14']
        this.selected1_15 = this.$route.params.questionSelected.answers[1]['1_15']
        this.selected1_16 = this.$route.params.questionSelected.answers[1]['1_16']
        this.selected1_17 = this.$route.params.questionSelected.answers[1]['1_17']
        this.selected1_18 = this.$route.params.questionSelected.answers[1]['1_18']   
        
        this.selected2_1 = this.$route.params.questionSelected.answers[2]['2_1']
        this.selected2_2 = this.$route.params.questionSelected.answers[2]['2_2']
        this.selected2_3 = this.$route.params.questionSelected.answers[2]['2_3']
        this.selected2_4 = this.$route.params.questionSelected.answers[2]['2_4']
        this.selected2_5 = this.$route.params.questionSelected.answers[2]['2_5']
        this.selected2_6 = this.$route.params.questionSelected.answers[2]['2_6']
        
        this.selected3_1 = this.$route.params.questionSelected.answers[3]['3_1']
        this.selected3_2 = this.$route.params.questionSelected.answers[3]['3_2']
        this.selected3_3 = this.$route.params.questionSelected.answers[3]['3_3']
        this.selected3_4 = this.$route.params.questionSelected.answers[3]['3_4']
        
        this.selected4_1 = this.$route.params.questionSelected.answers[4]['4_1']
        this.selected4_2 = this.$route.params.questionSelected.answers[4]['4_2']
        this.selected4_3 = this.$route.params.questionSelected.answers[4]['4_3']
        this.selected4_4 = this.$route.params.questionSelected.answers[4]['4_4']
        this.selected4_5 = this.$route.params.questionSelected.answers[4]['4_5']
        this.selected4_6 = this.$route.params.questionSelected.answers[4]['4_6']
        this.selected4_7 = this.$route.params.questionSelected.answers[4]['4_7']  
        this.selected4_8 = this.$route.params.questionSelected.answers[4]['4_8']
        this.selected4_9 = this.$route.params.questionSelected.answers[4]['4_9']
        this.selected4_10 = this.$route.params.questionSelected.answers[4]['4_10']    
        this.selected4_11 = this.$route.params.questionSelected.answers[4]['4_11']
        this.selected4_12 = this.$route.params.questionSelected.answers[4]['4_12']
        this.selected4_13 = this.$route.params.questionSelected.answers[4]['4_13']
        this.selected4_14 = this.$route.params.questionSelected.answers[4]['4_14']
        this.selected4_15 = this.$route.params.questionSelected.answers[4]['4_15']   
        
        this.selected5_1 = this.$route.params.questionSelected.answers[5]['5_1']
        this.selected5_2 = this.$route.params.questionSelected.answers[5]['5_2']
        this.selected5_3 = this.$route.params.questionSelected.answers[5]['5_3']
        this.selected5_4 = this.$route.params.questionSelected.answers[5]['5_4']
        this.selected5_5 = this.$route.params.questionSelected.answers[5]['5_5']
        this.selected5_6 = this.$route.params.questionSelected.answers[5]['5_6']
        this.selected5_7 = this.$route.params.questionSelected.answers[5]['5_7']       
        this.selected5_8 = this.$route.params.questionSelected.answers[5]['5_8']
        this.selected5_9 = this.$route.params.questionSelected.answers[5]['5_9']
        this.selected5_10 = this.$route.params.questionSelected.answers[5]['5_10']    
        this.selected5_11 = this.$route.params.questionSelected.answers[5]['5_11']
        this.selected5_12 = this.$route.params.questionSelected.answers[5]['5_12']
        this.selected5_13 = this.$route.params.questionSelected.answers[5]['5_13']
        this.selected5_14 = this.$route.params.questionSelected.answers[5]['5_14']
        this.selected5_15 = this.$route.params.questionSelected.answers[5]['5_15']
        this.selected5_16 = this.$route.params.questionSelected.answers[5]['5_16']
        this.selected5_17 = this.$route.params.questionSelected.answers[5]['5_17']
        
        this.selected6_1 = this.$route.params.questionSelected.answers[6]['6_1']
        this.selected6_2 = this.$route.params.questionSelected.answers[6]['6_2']
        this.selected6_3 = this.$route.params.questionSelected.answers[6]['6_3']
        this.selected6_4 = this.$route.params.questionSelected.answers[6]['6_4']
        this.selected6_5 = this.$route.params.questionSelected.answers[6]['6_5']
        this.selected6_6 = this.$route.params.questionSelected.answers[6]['6_6']
        this.selected6_7 = this.$route.params.questionSelected.answers[6]['6_7']  
        this.selected6_8 = this.$route.params.questionSelected.answers[6]['6_8']
        this.selected6_9 = this.$route.params.questionSelected.answers[6]['6_9']
        
        this.selected7_1 = this.$route.params.questionSelected.answers[7]['7_1']
        this.selected7_2 = this.$route.params.questionSelected.answers[7]['7_2']
        this.selected7_3 = this.$route.params.questionSelected.answers[7]['7_3']
        this.selected7_4 = this.$route.params.questionSelected.answers[7]['7_4']
        this.selected7_5 = this.$route.params.questionSelected.answers[7]['7_5']
        this.selected7_6 = this.$route.params.questionSelected.answers[7]['7_6']
        
        this.selected8_1 = this.$route.params.questionSelected.answers[8]['8_1']
        this.selected8_2 = this.$route.params.questionSelected.answers[8]['8_2']
        this.selected8_3 = this.$route.params.questionSelected.answers[8]['8_3']
        this.selected8_4 = this.$route.params.questionSelected.answers[8]['8_4']
        
        this.selected9_1 = this.$route.params.questionSelected.answers[9]['9_1']
        this.selected9_2 = this.$route.params.questionSelected.answers[9]['9_2']
        this.selected9_3 = this.$route.params.questionSelected.answers[9]['9_3']
        this.selected9_4 = this.$route.params.questionSelected.answers[9]['9_4']
        this.selected9_5 = this.$route.params.questionSelected.answers[9]['9_5']
        
        this.selected10_1 = this.$route.params.questionSelected.answers[10]['10_1']
        this.selected10_2 = this.$route.params.questionSelected.answers[10]['10_2']
        this.selected10_3 = this.$route.params.questionSelected.answers[10]['10_3']
        this.selected10_4 = this.$route.params.questionSelected.answers[10]['10_4']
        this.selected10_5 = this.$route.params.questionSelected.answers[10]['10_5']
        this.selected10_6 = this.$route.params.questionSelected.answers[10]['10_6']
        this.selected10_7 = this.$route.params.questionSelected.answers[10]['10_7']  
        this.selected10_8 = this.$route.params.questionSelected.answers[10]['10_8']
        this.selected10_9 = this.$route.params.questionSelected.answers[10]['10_9']
        this.selected10_10 = this.$route.params.questionSelected.answers[10]['10_10']    
        this.selected10_11 = this.$route.params.questionSelected.answers[10]['10_11']
        this.selected10_12 = this.$route.params.questionSelected.answers[10]['10_12']
        this.selected10_13 = this.$route.params.questionSelected.answers[10]['10_13'] 
        
        this.selected11_1 = this.$route.params.questionSelected.answers[11]['11_1']
        this.selected11_2 = this.$route.params.questionSelected.answers[11]['11_2']
        this.selected11_3 = this.$route.params.questionSelected.answers[11]['11_3']
        this.selected11_4 = this.$route.params.questionSelected.answers[11]['11_4']
        this.selected11_5 = this.$route.params.questionSelected.answers[11]['11_5']
        this.selected11_6 = this.$route.params.questionSelected.answers[11]['11_6']
        this.selected11_7 = this.$route.params.questionSelected.answers[11]['11_7']  
        this.selected11_8 = this.$route.params.questionSelected.answers[11]['11_8']
        this.selected11_9 = this.$route.params.questionSelected.answers[11]['11_9']
        this.selected11_10 = this.$route.params.questionSelected.answers[11]['11_10']    
        this.selected11_11 = this.$route.params.questionSelected.answers[11]['11_11']
        this.selected11_12 = this.$route.params.questionSelected.answers[11]['11_12']
        this.selected11_13 = this.$route.params.questionSelected.answers[11]['11_13']
        this.selected11_14 = this.$route.params.questionSelected.answers[11]['11_14']
        this.selected11_15 = this.$route.params.questionSelected.answers[11]['11_15']
        
        this.selected12_1 = this.$route.params.questionSelected.answers[12]['12_1']
        this.selected12_2 = this.$route.params.questionSelected.answers[12]['12_2']
        this.selected12_3 = this.$route.params.questionSelected.answers[12]['12_3']
        this.selected12_4 = this.$route.params.questionSelected.answers[12]['12_4']
        this.selected12_5 = this.$route.params.questionSelected.answers[12]['12_5']
        this.selected12_6 = this.$route.params.questionSelected.answers[12]['12_6']
        this.selected12_7 = this.$route.params.questionSelected.answers[12]['12_7']  
        this.selected12_8 = this.$route.params.questionSelected.answers[12]['12_8']
        
        this.selected13_1 = this.$route.params.questionSelected.answers[13]['13_1']
        this.selected13_2 = this.$route.params.questionSelected.answers[13]['13_2']
        this.selected13_3 = this.$route.params.questionSelected.answers[13]['13_3']
        this.selected13_4 = this.$route.params.questionSelected.answers[13]['13_4']
        this.selected13_5 = this.$route.params.questionSelected.answers[13]['13_5']
        this.selected13_6 = this.$route.params.questionSelected.answers[13]['13_6']
        this.selected13_7 = this.$route.params.questionSelected.answers[13]['13_7']  
        this.selected13_8 = this.$route.params.questionSelected.answers[13]['13_8']
        this.selected13_9 = this.$route.params.questionSelected.answers[13]['13_9']
        this.selected13_10 = this.$route.params.questionSelected.answers[13]['13_10']    
        this.selected13_11 = this.$route.params.questionSelected.answers[13]['13_11']
        this.selected13_12 = this.$route.params.questionSelected.answers[13]['13_12']
        this.selected13_13 = this.$route.params.questionSelected.answers[13]['13_13']
        this.selected13_14 = this.$route.params.questionSelected.answers[13]['13_14']
        this.selected13_15 = this.$route.params.questionSelected.answers[13]['13_15']
        
        this.selected14 = this.$route.params.questionSelected.answers[14]['14']

        this.selected15_1 = this.$route.params.questionSelected.answers[15]['15_1']
        this.selected15_2 = this.$route.params.questionSelected.answers[15]['15_2']
        this.selected15_3 = this.$route.params.questionSelected.answers[15]['15_3']
        this.selected15_4 = this.$route.params.questionSelected.answers[15]['15_4']
        this.selected15_5 = this.$route.params.questionSelected.answers[15]['15_5']
        this.selected15_6 = this.$route.params.questionSelected.answers[15]['15_6']
        this.selected15_7 = this.$route.params.questionSelected.answers[15]['15_7']
        
        this.selected16 = this.$route.params.questionSelected.answers[16]['16']

        this.selected17 = this.$route.params.questionSelected.answers[17]['17']
      }
    }
}
</script>

<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}

.card-violet {
  background-color: #f3eaff;
}
</style>