<template>
    <div class="assessmentTrauma">
        <PageTitle icon="fa fa-file-text" main="Ficha de Avaliação" sub="Obstétrica" />

        <div>
            <b-card :class="`card-${theme}`">
                <b-button @click="sendQuestions()" variant="info">Enviar</b-button>
                
                <b-form-group class="mt-3">
                    <b-button-group>
                        <b-button @click="numberQuestion = 1" :variant="(selected1_1 != null ||
                                                                         selected1_2 != null ||
                                                                         selected1_3 != null ||
                                                                         selected1_4 != null ||
                                                                         selected1_5 != null ||
                                                                         selected1_6 != null ||
                                                                         selected1_7 != null) ? 'success' : 'secondary'">1</b-button>
                        <b-button @click="numberQuestion = 2" :variant="(selected2_1 != null ||
                                                                         selected2_2 != null ||
                                                                         selected2_3 != null) ? 'success' : 'secondary'">2</b-button>
                        <b-button @click="numberQuestion = 3" :variant="(selected3_1 != null ||
                                                                         selected3_2 != null ||
                                                                         selected3_3 != null ||
                                                                         selected3_4 != null ||
                                                                         selected3_5 != null ||
                                                                         selected3_6 != null ||
                                                                         selected3_7 != null ||
                                                                         selected3_8 != null ||
                                                                         selected3_9 != null || 
                                                                         selected3_10 != null ) ? 'success' : 'secondary'">3</b-button>
                        <b-button @click="numberQuestion = 4" :variant="(selected4_1 != null ||
                                                                         selected4_2 != null ||
                                                                         selected4_3 != null ||
                                                                         selected4_4 != null ||
                                                                         selected4_5 != null ) ? 'success' : 'secondary'">4</b-button>
                    </b-button-group>
                </b-form-group>

                <b-form-group v-show="numberQuestion == 1" class="mt-3" label="INSPEÇÃO:">
                    <b-row class="mt-4"> 
                        <b-col md="6" sm="6">   
                            <b-form-group v-show="numberQuestion == 1" label="PA (PRESSÃO ARTERIAL):">
                                <b-form-input type="text" v-model="selected1_1" />                    
                            </b-form-group>                
                        </b-col>
                        <b-col md="6" sm="6">   
                            <b-form-group v-show="numberQuestion == 1" label="PESO:">
                                <b-form-input type="text" v-model="selected1_2" />                    
                            </b-form-group>                
                        </b-col>
                    </b-row>

                    <b-row> 
                        <b-col md="6" sm="6">   
                            <b-form-group v-show="numberQuestion == 1" label="ALTURA:">
                                <b-form-input type="text" v-model="selected1_3" />                    
                            </b-form-group>                
                        </b-col>                                     
                        <b-col md="6" sm="6">   
                            <b-form-group v-show="numberQuestion == 1" label="IMC:">
                                <b-form-input type="text" v-model="selected1_4" />                    
                            </b-form-group>                
                        </b-col>                                      
                    </b-row>  
                    
                    <b-row> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 1" label="POSTURA:">
                                <b-form-textarea id="textarea" v-model="selected1_5" placeholder="" rows="1" max-rows="6"></b-form-textarea>                   
                            </b-form-group>                
                        </b-col>                                                                       
                    </b-row>

                    <b-row> 
                      <b-col md="12" sm="12">   
                        <b-form-group v-show="numberQuestion == 1" label="Cicatrizes">
                            <b-form-radio v-model="selected1_6" name="some-radios-1_6" value="0">Não</b-form-radio>
                            <b-form-radio v-model="selected1_6" name="some-radios-1_6" value="1">Sim</b-form-radio>
                            <b-form-textarea id="textarea" v-model="selected1_7" placeholder="Local?" rows="1" max-rows="6"></b-form-textarea>
                        </b-form-group>                                   
                      </b-col>
                    </b-row>        
                </b-form-group>
                    
                <b-button v-show="numberQuestion == 1" variant="success" @click="numberQuestion = 2">Ok</b-button>

                 <b-form-group v-show="numberQuestion == 2" class="mt-3" label="DÉCUBITO DORSAL:">
                   
                   <b-col md="6" sm="6">
                    <b-row class="mt-4"> 
                      <b-form-group v-show="numberQuestion == 2" label="Consciência Perineal:">                        
                        <b-form-radio v-model="selected2_1" name="some-radios-2_1" value="0">Presente 1ª Vez</b-form-radio>
                        <b-form-radio v-model="selected2_1" name="some-radios-2_1" value="1">Presente 2ª Vez</b-form-radio>
                        <b-form-radio v-model="selected2_1" name="some-radios-2_1" value="2">Ausente</b-form-radio>
                        <b-form-radio v-model="selected2_1" name="some-radios-2_1" value="3">Caudal</b-form-radio>
                      </b-form-group> 
                    </b-row>
                  

                    <b-row class="mt-4"> 
                      <b-form-group v-show="numberQuestion == 2" label="Tosse:">                        
                        <b-form-radio v-model="selected2_2" name="some-radios-2_2" value="0">Mov. Cranial</b-form-radio>
                        <b-form-radio v-model="selected2_2" name="some-radios-2_2" value="1">Mov. Caudal</b-form-radio>
                        <b-form-radio v-model="selected2_2" name="some-radios-2_2" value="2">Antes</b-form-radio>
                        <b-form-radio v-model="selected2_2" name="some-radios-2_2" value="3">Durante</b-form-radio>
                        <b-form-radio v-model="selected2_2" name="some-radios-2_2" value="4">Depois</b-form-radio>
                        <b-form-radio v-model="selected2_2" name="some-radios-2_2" value="5">Ausentes</b-form-radio>
                      </b-form-group> 
                    </b-row>                           

                    <b-row class="mt-4"> 
                      <b-form-group v-show="numberQuestion == 2" label="Valsava (Força de coco):">                        
                        <b-form-radio v-model="selected2_3" name="some-radios-2_3" value="0">Mov. Cranial</b-form-radio>
                        <b-form-radio v-model="selected2_3" name="some-radios-2_3" value="1">Mov. Caudal</b-form-radio>
                        <b-form-radio v-model="selected2_3" name="some-radios-2_3" value="2">Antes</b-form-radio>
                        <b-form-radio v-model="selected2_3" name="some-radios-2_3" value="3">Durante</b-form-radio>
                        <b-form-radio v-model="selected2_3" name="some-radios-2_3" value="4">Depois</b-form-radio>
                        <b-form-radio v-model="selected2_3" name="some-radios-2_3" value="5">Ausentes</b-form-radio>
                      </b-form-group> 
                    </b-row>    
                   </b-col>
                </b-form-group>

                <b-button v-show="numberQuestion == 2" variant="success" @click="numberQuestion = 3">Ok</b-button>  

                 <b-form-group v-show="numberQuestion == 3" class="mt-3" label="PALPAÇÃO:">
                   <b-col md="6" sm="6">
                    <b-row class="mt-4"> 
                      <b-col md="12" sm="12">   
                        <b-form-group v-show="numberQuestion == 3" label="Dor a Palpação">
                            <b-form-radio v-model="selected3_1" name="some-radios-3_1" value="0">Não</b-form-radio>
                            <b-form-radio v-model="selected3_1" name="some-radios-3_1" value="1">Sim</b-form-radio>
                            <b-form-textarea id="textarea" v-model="selected3_2" placeholder="Local?" rows="1" max-rows="6"></b-form-textarea>
                        </b-form-group>                                   
                      </b-col>
                    </b-row>        
                   </b-col>
                 </b-form-group>

                 <b-form-group v-show="numberQuestion == 3" class="mt-3" label="Palpação Dinâmica:">
                   <b-col md="6" sm="6">
                    <b-row class="mt-4">  
                      <b-col md="12" sm="12">   
                        <b-form-group v-show="numberQuestion == 3" label="Contração visível dos MAP">
                            <b-form-radio v-model="selected3_3" name="some-radios-3_3" value="0">Não</b-form-radio>
                            <b-form-radio v-model="selected3_3" name="some-radios-3_3" value="1">Sim</b-form-radio>
                        </b-form-group>                                   
                      </b-col>

                      <b-col md="12" sm="12">   
                        <b-form-group v-show="numberQuestion == 3" label="Contração dos MAP">
                            <b-form-radio v-model="selected3_4" name="some-radios-3_4" value="0">Presente</b-form-radio>
                            <b-form-radio v-model="selected3_4" name="some-radios-3_4" value="1">Deficiente</b-form-radio>
                        </b-form-group>                                   
                      </b-col>

                      <b-col md="12" sm="12">   
                        <b-form-group v-show="numberQuestion == 3" label="Relaxamento visível dos MAP">
                            <b-form-radio v-model="selected3_5" name="some-radios-3_5" value="0">Não</b-form-radio>
                            <b-form-radio v-model="selected3_5" name="some-radios-3_5" value="1">Sim</b-form-radio>
                        </b-form-group>                                  
                      </b-col>

                      <b-col md="12" sm="12">   
                        <b-form-group v-show="numberQuestion == 3" label="Relaxamento dos MAP">
                            <b-form-radio v-model="selected3_6" name="some-radios-3_6" value="0">Presente</b-form-radio>
                            <b-form-radio v-model="selected3_6" name="some-radios-3_6" value="1">Deficiente: lento e/ou incompleto</b-form-radio>
                        </b-form-group>                                   
                      </b-col>

                      <b-col md="12" sm="12">   
                        <b-form-group v-show="numberQuestion == 3" label="Tônus dos MAP">
                            <b-form-radio v-model="selected3_7" name="some-radios-3_7" value="0">Diminuído</b-form-radio>
                            <b-form-radio v-model="selected3_7" name="some-radios-3_7" value="1">Normal</b-form-radio>
                            <b-form-radio v-model="selected3_7" name="some-radios-3_7" value="2">Aumentado</b-form-radio>
                        </b-form-group>                                   
                      </b-col>
                    </b-row>        
                   </b-col>
                  </b-form-group>

                  <b-form-group v-show="numberQuestion == 3" class="mt-3" label="Força dos MAP:">
                   <b-col md="6" sm="6">
                    <b-row class="mt-4">  
                      <b-col md="12" sm="12">   
                        <b-form-group v-show="numberQuestion == 3" label="Capacidade de sustentar a contração">
                            <b-form-radio v-model="selected3_8" name="some-radios-3_8" value="0">Não</b-form-radio>
                            <b-form-radio v-model="selected3_8" name="some-radios-3_8" value="1">Sim</b-form-radio>
                            <b-form-textarea id="textarea" v-model="selected3_9" placeholder="Segundos..." rows="1" max-rows="6"></b-form-textarea>
                        </b-form-group>                                   
                      </b-col>

                      <b-col md="12" sm="12">   
                        <b-form-group v-show="numberQuestion == 3" label="Uso excessivo de Músculos Sinergistas">
                            <b-form-radio v-model="selected3_10" name="some-radios-3_10" value="0">Não</b-form-radio>
                            <b-form-radio v-model="selected3_10" name="some-radios-3_10" value="1">Abdominais</b-form-radio>
                            <b-form-radio v-model="selected3_10" name="some-radios-3_10" value="2">Glúteos</b-form-radio>
                            <b-form-radio v-model="selected3_10" name="some-radios-3_10" value="3">Adutores</b-form-radio>
                        </b-form-group>                                  
                      </b-col>
                    </b-row>        
                   </b-col>
                  </b-form-group>
              
                <b-button v-show="numberQuestion == 3" variant="success" @click="numberQuestion = 4">Ok</b-button>
                
                <b-form-group v-show="numberQuestion == 4" class="mt-3" label="Atividades:">
                    <b-row class="mt-4"> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 4" label="Atividades de autocuidado:">
                                <b-form-textarea id="textarea" v-model="selected4_1" placeholder="" rows="1" max-rows="6"></b-form-textarea>                   
                            </b-form-group>                
                        </b-col>                                                                                                               
                    </b-row>

                    <b-row class="mt-4"> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 4" label="Atividades de vida doméstica:">
                                <b-form-textarea id="textarea" v-model="selected4_2" placeholder="" rows="1" max-rows="6"></b-form-textarea>                   
                            </b-form-group>                
                        </b-col>                                                                                                               
                    </b-row>

                    <b-row class="mt-4"> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 4" label="Atividades que desencadeiam a dor:">
                                <b-form-textarea id="textarea" v-model="selected4_3" placeholder="" rows="1" max-rows="6"></b-form-textarea>                   
                            </b-form-group>                
                        </b-col>                                                                                                               
                    </b-row>

                    <b-row class="mt-4"> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 4" label="Atividades que intensificam:">
                                <b-form-textarea id="textarea" v-model="selected4_4" placeholder="" rows="1" max-rows="6"></b-form-textarea>                   
                            </b-form-group>                
                        </b-col>                                                                                                               
                    </b-row>

                    <b-row class="mt-4"> 
                        <b-col md="12" sm="12">   
                            <b-form-group v-show="numberQuestion == 4" label="Atividades que aliviam e cessam os sintomas:">
                                <b-form-textarea id="textarea" v-model="selected4_5" placeholder="" rows="1" max-rows="6"></b-form-textarea>                   
                            </b-form-group>                
                        </b-col>                                                                                                               
                    </b-row>
                </b-form-group>

                <b-button v-show="numberQuestion == 4" variant="success" @click="numberQuestion = 5">Ok</b-button>
                                                         
            </b-card>
        </div>
    </div>
</template>

<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'
import Moment from 'moment'

export default {
    name: "AssessmentObstetrics",
    components: { PageTitle },
    data() {
      return {        theme: null,
        patient: null,
        assessment: null,
        numberQuestion: 1,
        selected1_1: null,
        selected1_2: null,
        selected1_3: null,
        selected1_4: null,
        selected1_5: null,
        selected1_6: null,
        selected1_7: null,
        selected2_1: null,
        selected2_2: null,
        selected2_3: null,
        selected3_1: null,
        selected3_2: null,
        selected3_3: null,
        selected3_4: null,
        selected3_5: null,
        selected3_6: null,
        selected3_7: null,
        selected3_8: null,
        selected3_9: null,
        selected3_10: null,        
        selected4_1: null,
        selected4_2: null,
        selected4_3: null,
        selected4_4: null,
        selected4_5: null
      }
    },
    methods: {
        sendQuestions() {
                Moment.locale('pt-br')

                axios.get(`${baseApiUrl}/patient/getById/${this.patient._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`).then(res => {
                this.patient = res.data

                for (let q of this.patient.questions) {
                    if (q.type == 1 && q.name == this.assessment.name) {
                        q.answers = []

                        q.status = 1
                        q.answers.push(
                            {
                            'dateAnswers': Moment(new Date()).format('L')
                            }, 
                            {
                            '1_1': this.selected1_1,
                            '1_2': this.selected1_2,
                            '1_3': this.selected1_3,
                            '1_4': this.selected1_4,
                            '1_5': this.selected1_5,
                            '1_6': this.selected1_6,
                            '1_7': this.selected1_7,
                            },
                            {
                            '2_1': this.selected2_1,
                            '2_2': this.selected2_2,
                            '2_3': this.selected2_3,
                            },
                            {
                            '3_1': this.selected3_1,
                            '3_2': this.selected3_2,
                            '3_3': this.selected3_3,
                            '3_4': this.selected3_4,
                            '3_5': this.selected3_5,
                            '3_6': this.selected3_6,
                            '3_7': this.selected3_7,
                            '3_8': this.selected3_8,
                            '3_9': this.selected3_9,
                            '3_10': this.selected3_10,
                            },
                            {
                            '4_1': this.selected4_1,
                            '4_2': this.selected4_2,
                            '4_3': this.selected4_3,
                            '4_4': this.selected4_4,
                            '4_5': this.selected4_5,
                            }                                                                                                                                                                                                                                                                                                                                                                                                                                                         
                        ) 
                    }
                }            

                const method = 'put'            
                const id = `/${this.patient._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`

                axios[method](`${baseApiUrl}/patient${id}`, this.patient)
                    .then(() => {
                        this.$toasted.global.defaultSuccess()
                        this.$router.push({name: 'notation', params: { },})
                    })  
                    .catch(showError) 
                }).catch((e) => {
                  console.log(e)
                  showError
                })
            // }             
        }                
    },
    mounted() {
      this.theme = localStorage.getItem('theme')
  
      this.patient = this.$route.params.questionSelected.patient
      this.assessment = this.$route.params.questionSelected   
      
      if (!this.$route.params.questionSelected.answers.length == 0) {
        this.selected1_1 = this.$route.params.questionSelected.answers[1]['1_1']
        this.selected1_2 = this.$route.params.questionSelected.answers[1]['1_2']
        this.selected1_3 = this.$route.params.questionSelected.answers[1]['1_3']
        this.selected1_4 = this.$route.params.questionSelected.answers[1]['1_4']
        this.selected1_5 = this.$route.params.questionSelected.answers[1]['1_5']
        this.selected1_6 = this.$route.params.questionSelected.answers[1]['1_6']
        this.selected1_7 = this.$route.params.questionSelected.answers[1]['1_7']
       
        this.selected2_1 = this.$route.params.questionSelected.answers[2]['2_1']
        this.selected2_2 = this.$route.params.questionSelected.answers[2]['2_2']
        this.selected2_3 = this.$route.params.questionSelected.answers[2]['2_3']
        
        this.selected3_1 = this.$route.params.questionSelected.answers[3]['3_1']
        this.selected3_2 = this.$route.params.questionSelected.answers[3]['3_2']
        this.selected3_3 = this.$route.params.questionSelected.answers[3]['3_3']
        this.selected3_4 = this.$route.params.questionSelected.answers[3]['3_4']
        this.selected3_5 = this.$route.params.questionSelected.answers[3]['3_5']
        this.selected3_6 = this.$route.params.questionSelected.answers[3]['3_6']
        this.selected3_7 = this.$route.params.questionSelected.answers[3]['3_7']  
        this.selected3_8 = this.$route.params.questionSelected.answers[3]['3_8']
        this.selected3_9 = this.$route.params.questionSelected.answers[3]['3_9']
        this.selected3_10 = this.$route.params.questionSelected.answers[3]['3_10']
       
        this.selected4_1 = this.$route.params.questionSelected.answers[4]['4_1']
        this.selected4_2 = this.$route.params.questionSelected.answers[4]['4_2']
        this.selected4_3 = this.$route.params.questionSelected.answers[4]['4_3']
        this.selected4_4 = this.$route.params.questionSelected.answers[4]['4_4']
        this.selected4_5 = this.$route.params.questionSelected.answers[4]['4_5']
      }
    }
}
</script>

<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}

.card-violet {
  background-color: #f3eaff;
}
</style>