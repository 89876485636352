import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../components/home/Home'
import AdminPage from '../components/admin/AdminPage'
import Patients from '../components/cadastre/patient/Patients'
import Patient from '../components/cadastre/patient/PatientPage'
import PatientPageExternal from '../components/cadastre/patient/PatientPageExternal'
import Covenant from '../components/cadastre/covenants/CovenantPage'
import Covenants from '../components/cadastre/covenants/Covenants'
import Professional from '../components/cadastre/professional/ProfessionalPage'
import Professionals from '../components/cadastre/professional/Professionals'
import Speciality from '../components/cadastre/speciality/SpecialityPage'
import Specialities from '../components/cadastre/speciality/Specialities'
import Advices from '../components/cadastre/advice/Advices'
import Advice from '../components/cadastre/advice/AdvicePage'
import Auth from '../components/auth/Auth'
import AuthPatient from '../components/auth/AuthPatient'
import Accommodations from '../components/cadastre/accommodation/Accommodations'
import Accommodation from '../components/cadastre/accommodation/AccommodationPage'
import TypeServices from '../components/cadastre/typeservice/TypeServices'
import TypeService from '../components/cadastre/typeservice/TypeServicePage'
import Admissions from '../components/admission/Admissions'
import Admission from '../components/admission/AdmissionPage'
import ScaleCalendar from '../components/scale/ScaleCalendar'
import NotationPage from '../components/notation/NotationPage'
import ScaleBase from '../components/scale/ScaleBase'
import ScaleBaseProcessing from '../components/scale/ScaleBaseProcessing'
import BatchGuidesANS from '../components/integrations/BatchGuidesANS'
import BatchGuidesANSProcessing from '../components/integrations/BatchGuidesANSProcessing'
import BatchGuidesANSEdit from '../components/integrations/BatchGuidesANSEdit'
import LogScreen from '../components/log/LogScreen'
import Events from '../components/cadastre/events/Events'
import Event from '../components/cadastre/events/EventsPage'
import AdmissionXAccommodation from '../components/reports/AdmissionXAccommodation'
import AdmissionXApartments from '../components/reports/AdmissionXApartments'
import ScaleFixed from '../components/scale/ScaleFixed'
import Scheduling from '../components/scale/Scheduling'
import SchedulingPage from '../components/scale/SchedulingPage'
import PatientResume from '../components/cadastre/patient/PatientResume'
import SF36 from '../components/tests/SF36'
import EscalaBarthel from '../components/tests/EscalaBarthel'
import AssessmentTrauma from '../components/questions/AssessmentTrauma'
import AssessmentPediatric from '../components/questions/AssessmentPediatric'
import AssessmentPending from '../components/questions/AssessmentPending'
import AssessmentPelvic from '../components/questions/AssessmentPelvic'
import AssessmentOsteopathy from '../components/questions/AssessmentOsteopathy'
import AssessmentRespiratory from '../components/questions/AssessmentRespiratory'
import AssessmentObstetrics from '../components/questions/AssessmentObstetrics'
import SatisfactionSurvey from '../components/questions/SatisfactionSurvey'
import SatisfactionSurveyResume from '../components/reports/SatisfactionSurveyResume'
import TestEscalaBarthel from '../components/questions/TestEscalaBarthel'
import TestDASH from '../components/questions/TestDASH'
import TestEscalaBorg from '../components/questions/TestEscalaBorg'
import TestESE from '../components/questions/TestESE'
import TestEva from '../components/questions/TestEva'
import TestLEFS from '../components/questions/TestLEFS'
import TestMMRC from '../components/questions/TestMMRC'
import TestSF36 from '../components/questions/TestSF36'
import TestEMIA from '../components/questions/TestEMIA'
import ServiceCapacity from '../components/reports/ServiceCapacity'
import AdmissionNoMoviment from '../components/reports/AdmissionNoMoviment'
import RepAttendancesDone from '../components/reports/RepAttendancesDone'
import RepReleased from '../components/reports/RepReleased'
import RepAppointments from '../components/reports/RepAppointments'
import AttendancesDonePerCovenant from '../components/reports/AttendancesDonePerCovenant'
import AdmissionsPerCovenant from '../components/reports/AdmissionsPerCovenant'
import ScaleDistribution from '../components/scale/ScaleDistribution'
import OperationAnalysis from '../components/reports/OperationAnalysis'
import Batchs from '../components/integrations/BatchsANS'
import Maintenance from '../components/auth/Maintenance'
import { userKey } from '../global'

Vue.use(VueRouter)

const routes = [
    {
        name: 'home',
        path: '/',
        component: Home
    },
    {
        name: 'adminPage',
        path: '/admin',
        component: AdminPage,
        meta: {
            requiresAdmin: true
        }
    },
    {
        name: 'auth',
        path: `/auth?client=${localStorage.getItem('client')}`,
        component: Auth
    },
    {
        name: 'authPatient',
        path: `/authPatient?client=${localStorage.getItem('client')}`,
        component: AuthPatient
    },
    {
        name: 'patients',
        path: '/patients',
        component: Patients
    },
    {
        name: 'patient',
        path: '/patient',
        component: Patient
    },
    {
        name: 'patientPageExternal',
        path: '/patientPageExternal',
        component: PatientPageExternal
    },
    {
        name: 'covenant',
        path: '/covenant',
        component: Covenant
    },
    {
        name: 'covenants',
        path: '/covenants',
        component: Covenants
    },
    {
        name: 'professional',
        path: '/professional',
        component: Professional
    },
    {
        name: 'professionals',
        path: '/professionals',
        component: Professionals
    },
    {
        name: 'specialities',
        path: '/specialities',
        component: Specialities
    },
    {
        name: 'speciality',
        path: '/speciality',
        component: Speciality
    },
    {
        name: 'advices',
        path: '/advices',
        component: Advices
    },
    {
        name: 'advice',
        path: '/advice',
        component: Advice
    },
    {
        name: 'accommodations',
        path: '/accommodations',
        component: Accommodations
    },
    {
        name: 'accommodation',
        path: '/accommodation',
        component: Accommodation
    },
    {
        name: 'typeServices',
        path: '/typeServices',
        component: TypeServices
    },
    {
        name: 'typeService',
        path: '/typeService',
        component: TypeService
    },
    {
        name: 'admissions',
        path: '/admissions',
        component: Admissions
    },
    {
        name: 'admission',
        path: '/admission',
        component: Admission
    },
    {
        name: 'scaleCalendar',
        path: '/scaleCalendar',
        component: ScaleCalendar
    },
    {
        name: 'scaleBase',
        path: '/scaleBase',
        component: ScaleBase
    },
    {
        name: 'scaleBaseProcessing',
        path: '/scaleBaseProcessing',
        component: ScaleBaseProcessing
    },
    {
        name: 'notation',
        path: '/notation',
        component: NotationPage
    },
    {
        name: 'batchGuidesANS',
        path: '/batchGuidesANS',
        component: BatchGuidesANS
    },
    {
        name: 'batchGuidesANSProcessing',
        path: '/batchGuidesANSProcessing',
        component: BatchGuidesANSProcessing
    },
    {
        name: 'batchGuidesANSEdit',
        path: '/batchGuidesANSEdit',
        component: BatchGuidesANSEdit
    },
    {
        name: 'logScreen',
        path: '/logScreen',
        component: LogScreen
    },
    {
        name: 'event',
        path: '/event',
        component: Event
    },
    {
        name: 'events',
        path: '/events',
        component: Events
    },
    {
        name: 'admissionXAccommodation',
        path: '/admissionXAccommodation',
        component: AdmissionXAccommodation
    },
    {
        name: 'admissionXApartments',
        path: '/admissionXApartments',
        component: AdmissionXApartments
    },
    {
        name: 'scaleFixed',
        path: '/scaleFixed',
        component: ScaleFixed
    },
    {
        name: 'scheduling',
        path: '/scheduling',
        component: Scheduling
    },
    {
        name: 'schedulingPage',
        path: '/schedulingPage',
        component: SchedulingPage
    },
    {
        name: 'patientResume',
        path: '/patientResume',
        component: PatientResume
    },
    {
        name: 'SF36',
        path: '/SF36',
        component: SF36
    },
    {
        name: 'escalaBarthel',
        path: '/escalaBarthel',
        component: EscalaBarthel
    },
    {
        name: 'assessmentTrauma',
        path: '/assessmentTrauma',
        component: AssessmentTrauma
    },
    {
        name: 'assessmentPediatric',
        path: '/assessmentPediatric',
        component: AssessmentPediatric
    },
    {
        name: 'assessmentPending',
        path: '/assessmentPending',
        component: AssessmentPending
    },
    {
        name: 'assessmentPelvic',
        path: '/assessmentPelvic',
        component: AssessmentPelvic
    },
    {
        name: 'assessmentOsteopathy',
        path: '/assessmentOsteopathy',
        component: AssessmentOsteopathy
    },
    {
        name: 'assessmentRespiratory',
        path: '/assessmentRespiratory',
        component: AssessmentRespiratory
    },
    {
        name: 'assessmentObstetrics',
        path: '/assessmentObstetrics',
        component: AssessmentObstetrics
    },
    {
        name: 'satisfactionSurvey',
        path: '/satisfactionSurvey',
        component: SatisfactionSurvey
    },
    {
        name: 'satisfactionSurveyResume',
        path: '/satisfactionSurveyResume',
        component: SatisfactionSurveyResume
    },
    {
        name: 'testEscalaBarthel',
        path: '/testEscalaBarthel',
        component: TestEscalaBarthel
    },
    {
        name: 'testDASH',
        path: '/testDASH',
        component: TestDASH
    },
    {
        name: 'testEscalaBorg',
        path: '/testEscalaBorg',
        component: TestEscalaBorg
    },
    {
        name: 'testESE',
        path: '/testESE',
        component: TestESE
    },
    {
        name: 'testEva',
        path: '/testEva',
        component: TestEva
    },
    {
        name: 'testLEFS',
        path: '/testLEFS',
        component: TestLEFS
    },
    {
        name: 'testMMRC',
        path: '/testMMRC',
        component: TestMMRC
    },
    {
        name: 'testSF36',
        path: '/testSF36',
        component: TestSF36
    },
    {
        name: 'testEMIA',
        path: '/testEMIA',
        component: TestEMIA
    },
    {
        name: 'serviceCapacity',
        path: '/serviceCapacity',
        component: ServiceCapacity
    },
    {
        name: 'admissionNoMoviment',
        path: '/admissionNoMoviment',
        component: AdmissionNoMoviment
    },
    {
        name: 'repAttendancesDone',
        path: '/repAttendancesDone',
        component: RepAttendancesDone
    },
    {
        name: 'repReleased',
        path: '/repReleased',
        component: RepReleased
    },
    {
        name: 'repAppointments',
        path: '/repAppointments',
        component: RepAppointments
    },
    {
        name: 'attendancesDonePerCovenant',
        path: '/attendancesDonePerCovenant',
        component: AttendancesDonePerCovenant
    },
    {
        name: 'admissionsPerCovenant',
        path: '/admissionsPerCovenant',
        component: AdmissionsPerCovenant
    },
    {
        name: 'scaleDistribution',
        path: '/scaleDistribution',
        component: ScaleDistribution
    },
    {
        name: 'operationAnalysis',
        path: '/operationAnalysis',
        component: OperationAnalysis
    },
    {
        name: 'batchs',
        path: '/batchs',
        component: Batchs
    },
    {
        name: 'maintenance',
        path: '/maintenance',
        component: Maintenance
    }
]

const router = new VueRouter({
    mode: 'history',
    routes: routes
})

router.beforeEach((to, from, next) => {
    const json = localStorage.getItem(userKey)

    if (to.matched.some(record => record.meta.requiresAdmin)) {
        const user = JSON.parse(json)
        user && user.admin ? next() : next({ path: '/' })
    } else {
        next()
    }
})

export default router