<template>
  <div class="covenants-pages">
    <PageTitle icon="fa fa-window-restore" main="Convênios" sub />
    <div class="admin-pages-tabs">
        <b-button size="sm" variant="success" @click="newCovenant()">
            NOVO
        </b-button>      
        <b-button size="sm" class="ml-3" @click="print" variant="info" v-b-popover.hover.top="'Imprimir Listagem'">
            <div class="d-flex align-items-center">
                <b-spinner small v-if="generatingPDF" class="mr-2"></b-spinner>
                <span>{{textBtnPrint}}</span>
            </div>
        </b-button>            
        <hr>
        <b-row>
          <b-col md="4" sm="12">
            <b-form-input size="sm" placeholder="Filtrar..." v-model="ftext"></b-form-input>
          </b-col> 
        </b-row>             
        <hr>
        <b-table ref="selectableTable" head-variant="light" outlined hover :items="covenants" :fields="fields" responsive="sm" id="my-table"
                 :per-page="perPage" :current-page="currentPage" striped fixed :filter="ftext">
            <template v-slot:cell(actions)="row">
                <b-button size="sm" variant="warning" @click="onRowSelected(row.item)" v-b-popover.hover.top="'Alterar'">
                    <i class="fa fa-pencil"></i>
                </b-button>
                <b-button size="sm" variant="danger" @click="removeCovenant(row.item)" v-b-popover.hover.top="'Excluir'" class="ml-2">
                    <i class="fa fa-trash"></i>
                </b-button>                
            </template>
        </b-table>              

        <div v-if="covenants.length == 0" class="d-flex justify-content-center mb-3">    
            <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner" type="grow"></b-spinner>
        </div>                

        <b-pagination size="sm" pills v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table" class="page-item">
        </b-pagination>        
    </div>
  </div>
</template>

<script>
import PageTitle from "../../template/PageTitle";
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'

export default {
    name: 'Covenants',
    components: { PageTitle },
    data: function() {
      return {
        generatingPDF: false,
        textBtnPrint: 'IMPRIMIR',        
        ftext: '',
        covenants: [],
        currentPage: 1,
        perPage: 10,        
        fields: [                 
            { key: 'fantasyname', label: 'Nome', sortable: true },
            { key: 'cnpj', label: 'CNPJ', sortable: true },
            { key: 'subscription', label: 'Inscrição', sortable: true },
            { key: 'email', label: 'E-mail', sortable: true },
            { key: 'actions', label: 'Ações' }
        ],          
      }
    },
    computed: {
      rows() {
        return this.covenants.length
      }
    },    
    methods: {
        print() {
            this.generatingPDF = true
            this.textBtnPrint = 'Aguarde...'   

            let contentPdf = {
                title: 'CONVÊNIOS',
                format: 'A4',
                orientation: 'portrait',
                separate: false,
                content: [
                    {
                        type: 'table',
                        topicFields: [],
                        fields: [
                          {
                            original: 'fantasyname',
                            exibition: 'Nome'
                          },
                          {
                            original: 'cnpj',
                            exibition: 'CNPJ'
                          },      
                          {
                            original: 'email',
                            exibition: 'E-mail'
                          },                                                    
                        ],
                        list: this.covenants
                    }
                ]
            }

            const url = `${baseApiUrl}/reports/pdfGenerator/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.post(url, contentPdf, {responseType: 'arraybuffer'}).then(res => {  
              var file = new Blob([res.data], {type: 'application/pdf'})
              var fileURL = URL.createObjectURL(file);
              window.open(fileURL);

                this.generatingPDF = false
                this.textBtnPrint = 'IMPRIMIR'    
            }).catch(showError)                 
        },
        newCovenant() {
          this.$router.push({name: 'covenant', params: { },})          
        },
        onRowSelected(itemSelected) {
          let covenantSelected = {    
              _id: itemSelected._id,
              fantasyname: itemSelected.fantasyname,
              companyname: itemSelected.companyname,
              cnpj: itemSelected.cnpj,
              subscription: itemSelected.subscription,
              registerANS: itemSelected.registerANS,
              nameContact: itemSelected.nameContact,
              email: itemSelected.email,
              phone1: itemSelected.phone1,
              phone2: itemSelected.phone2,
              address: itemSelected.address,
              comments: itemSelected.comments,
              deletedAt: itemSelected.deletedAt,
              priceTypeService: itemSelected.priceTypeService,
              codeOperatorProvider: itemSelected.codeOperatorProvider,
              versionXml: itemSelected.versionXml,
              attendancesPerGuide: itemSelected.attendancesPerGuide,
              logo: itemSelected.logo,
              valueDaily: itemSelected.valueDaily
          }              

          this.$router.push({name: 'covenant', params: { covenantSelected },})            
        },               
        loadCovenants() {
            const url = `${baseApiUrl}/covenants/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                this.covenants = res.data            
            }).catch(showError)                      
        },    
        removeCovenant(itemSelected) {
            this.$bvModal.msgBoxConfirm('Deseja remover o convênio?', {
                title: 'Confirmação',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Sim',
                cancelTitle: 'Não',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            }).then(value => {            
                if (value) {                
                  const id = itemSelected._id
                  axios.delete(`${baseApiUrl}/covenants/${id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`).then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.loadCovenants()
                  }).catch(showError)                
                }                
            }).catch()  
        }        
    },
    mounted() {
        this.loadCovenants()
    }    
};
</script>

<style>
.page-item .page-font {
    color:#44A08D;  
}

.page-item.active .page-link {  
    background-color:#44A08D;  
    border-color:  #44A08D;      
}

.page-item.active .page-link:hover {  
    background-color:#44A08D;  
    border-color:  #44A08D;  
}

.card-header {
    height: 38px;
    font-size: 14px;    
}

.card-body {
    font-size: 14px;
}

.calendar-btn {
    font-size: 0.30;
}

.table th {
  padding: 0.45rem;
}

.table td {
  padding: 0.45rem;
  vertical-align: middle;
}
</style>