<template>
  <div class="serviceCapacity">
    <PageTitle icon="fa fa-file-text" main="Capacidade de Atendimentos" sub="Relatório" />
        <div class="serviceCapacity-page-tabs">
            <b-row>
                <b-col md="6" sm="12">
                    <b-card :class="`card-${theme}`" header="Informações" header-bg-variant="dark" header-text-variant="white">
                        <b-row>      
                            <b-col md="2" sm="12">
                                <b-button class="mt-4" size="sm" variant="info" @click="loadAdmissions()">
                                    <div class="d-flex align-items-center">
                                        <b-spinner small v-if="generatingPDF" class="mr-2"></b-spinner>
                                        <span>{{textBtnPrint}}</span>
                                    </div>                                    
                                </b-button>       
                            </b-col>                     
                        </b-row>
                    </b-card>                    
                </b-col>                  
            </b-row>   
        </div>
  </div>  
</template>

<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'
import Moment from 'moment'

export default {
    name: "ServiceCapacity",
    components: { PageTitle },    
    data: function() {
        return {
            theme: null,
            totalAttendances: 0,
            totalProfessionals: 0,
            totalPatientAdult: 0,
            totalPatientChild: 0,
            totalPatientSUS: 0,
            totalProfessionalAdult: 0,
            totalProfessionalChild: 0,
            totalProfessionalSUS: 0,   
            capacityAdult: 0,
            capacityChild: 0,
            capacitySUS: 0,
            date: null,
            settings: null,
            generatingPDF: false,
            textBtnPrint: 'GERAR'  
        }
    },   
    methods: {
        async showReport() {
            let list = []
            let listTopics = []

            listTopics.push('Atendimento Adulto')
            listTopics.push('Atendimento Infatil')
            listTopics.push('Atendimento SUS')

            Moment.locale('pt-br')       
            
            let unrealizedAdult = this.totalPatientAdult - this.capacityAdult
            let unrealizedChild = this.totalPatientChild - this.capacityChild
            let unrealizedSUS = this.totalPatientSUS - this.capacitySUS

            list.push({
                numberAttendances: this.totalPatientAdult,
                numberProfessionals: this.totalProfessionalAdult,
                numberAttendancesProf: Math.round(this.totalPatientAdult / this.totalProfessionalAdult),
                unrealized: unrealizedAdult < 0 ? 0 : unrealizedAdult,
                suggestion: (unrealizedAdult / 24).toFixed(2) < 0 ? 0 : (unrealizedAdult / 24).toFixed(2),
                key: 'Atendimento Adulto'
            })

            list.push({
                numberAttendances: this.totalPatientChild,
                numberProfessionals: this.totalProfessionalChild,
                numberAttendancesProf: Math.round(this.totalPatientChild / this.totalProfessionalChild),
                unrealized: unrealizedChild < 0 ? 0 : unrealizedChild,
                suggestion: (unrealizedChild / 24).toFixed(2) < 0 ? 0 : (unrealizedChild / 24).toFixed(2),
                key: 'Atendimento Infatil'
            })    
            
            list.push({
                numberAttendances: this.totalPatientSUS,
                numberProfessionals: this.totalProfessionalSUS,
                numberAttendancesProf: (this.totalPatientSUS / this.totalProfessionalSUS).toFixed(2) == Infinity ? 0 : Math.round(this.totalPatientSUS / this.totalProfessionalSUS),
                unrealized: unrealizedSUS < 0 ? 0 : unrealizedSUS,
                suggestion: (unrealizedSUS / 24).toFixed(2) < 0 ? 0 : (unrealizedSUS / 24).toFixed(2),
                key: 'Atendimento SUS'
            })              

            let contentPdf = {
                title: `CAPACIDADE DE ATENDIMENTOS - ${Moment(this.date).format('L')}`,
                format: 'A4',
                orientation: 'landscape',
                separate: false,
                content: [
                    {
                        type: 'table',
                        topicFields: listTopics,
                        fields: [                                                                
                            {
                                original: 'numberAttendances',
                                exibition: 'Quantidade Atendimentos Dia'
                            },                                
                            {
                                original: 'numberProfessionals',
                                exibition: 'Quantidade Profissionais Dia'
                            },
                            {
                                original: 'numberAttendancesProf',
                                exibition: 'Atendimentos Por Profissional'
                            },
                            {
                                original: 'unrealized',
                                exibition: 'Atendimentos Não Realizados'
                            },
                            {
                                original: 'suggestion',
                                exibition: 'Sugestão de Extras'
                            }                                                                                                                                                                  
                        ],
                        list: list
                    },
                ]
            }

            const url = `${baseApiUrl}/reports/pdfGenerator/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.post(url, contentPdf, {responseType: 'arraybuffer'}).then(res => {  
            var file = new Blob([res.data], {type: 'application/pdf'})
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);

                this.generatingPDF = false
                this.textBtnPrint = 'GERAR'    
            }).catch(showError)                   
        },
        async loadAdmissions() {
            this.generatingPDF = true
            this.textBtnPrint = 'Aguarde...' 

            await axios.get(`${baseApiUrl}/admission/getNumberAttendances/null&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, {}).then(res => {
                this.totalAttendances = res.data.result.total
                this.totalPatientAdult = res.data.result.totalAdult
                this.totalPatientChild = res.data.result.totalChild
                this.totalPatientSUS = res.data.result.totalSUS
                
                if (this.totalAttendances == 0) {
                    this.$toasted.global.defaultError({ msg: 'Nenhuma admissão carregada' }) 

                    this.generatingPDF = false
                    this.textBtnPrint = 'GERAR'                      
                } else {
                    axios.get(`${baseApiUrl}/scale/getNumberProfessionalsScale/null&null&null&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, {}).then(res => {
                        this.totalProfessionals = res.data.result.total
                        this.totalProfessionalAdult = res.data.result.totalAdult
                        this.totalProfessionalChild = res.data.result.totalChild
                        this.totalProfessionalSUS = res.data.result.totalSUS
                        this.capacityAdult = res.data.result.capacityAdult
                        this.capacityChild = res.data.result.capacityChild
                        this.capacitySUS = res.data.result.capacitySUS
                        
                        if (this.totalProfessionals == 0) {
                            this.$toasted.global.defaultError({ msg: 'Nenhum profissional na escala do dia' }) 

                            this.generatingPDF = false
                            this.textBtnPrint = 'GERAR'                      
                        } else {
                            this.showReport()
                        }
                    })
                }
            }).catch(showError)
        },
        loadSettings() {
            const url = `${baseApiUrl}/setting/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                this.settings = res.data                
            }).catch(showError)                      
        }           
    },
    mounted() {
        this.theme = localStorage.getItem('theme')

        this.loadSettings()
    }
}
</script>

<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}
</style>