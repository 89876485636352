<template>
    <div class="repAttendancesDone">
    <PageTitle icon="fa fa-file-text" main="Relatório de Produtividade" sub="Relatório" />
        <div class="repAttendancesDone-page-tabs">
            <b-row>
                <b-col md="8" sm="12" class="mt-1">
                    <b-card :class="`card-${theme}`" header="Informações" header-bg-variant="dark" header-text-variant="white">
                        <b-row>
                            <b-col md="5" sm="12" class="mt-1">
                                <b-form-group label="Profissional:" label-for="professional-name">
                                    <b-form-input size="sm" id="professional-name" type="text" v-model="professional.name" disabled/>
                                </b-form-group>
                            </b-col>   
                            <b-col md="1" sm="12" class="align-self-center mt-3">
                                <b-button size="sm" v-b-modal.modal-prevent-closing v-b-popover.hover.top="'Adiconar Profissional'" @click="setModal('LookupPr')">
                                    <i class="fa fa-search"></i>
                                </b-button>

                                <Lookup v-if="modal === 'LookupPr'" ref="LookupPr" title="Profissionais" :list="resultProfessionals" :fields="fieldsPr"
                                    :invalidFeedback="invalidFeedback" @event="addProfessional" resultTable="professionalAdmission"></Lookup>
                            </b-col>
                            <b-col md="5" sm="12" class="mt-1">                                
                                <b-form-group label="Convênio:" label-for="covenant-name">
                                    <b-form-input size="sm" id="covenant-name" type="text" v-model="covenant.companyname" disabled/>
                                </b-form-group>
                            </b-col>
                            <b-col md="1" sm="12" class="align-self-center mt-3">
                                <b-button size="sm" v-b-modal.modal-prevent-closing v-b-popover.hover.top="'Adicionar Convênio'" @click="setModal('LookupCv')">
                                    <i class="fa fa-search"></i>
                                </b-button>

                                <Lookup v-if="modal === 'LookupCv'" ref="LookupCv" title="Convênios" :list="covenants" :fields="fieldsCv"
                                    :invalidFeedback="invalidFeedback" @event="addCovenant" resultTable="relAdmissionsPerCovenant"></Lookup>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="3" sm="12">
                                <b-form-group label="Data Inicial:" label-for="repAttendancesDone-dateInitial">
                                    <b-form-input size="sm" id="repAttendancesDone-dateInitial" type="date" v-model="dateInitial" required />
                                </b-form-group>
                            </b-col>
                            <b-col md="3" sm="12">
                                <b-form-group label="Data Final:" label-for="repAttendancesDone-dateFinal">
                                    <b-form-input size="sm" id="repAttendancesDone-dateFinal" type="date" v-model="dateFinal" required/>
                                </b-form-group>
                            </b-col>
                            <b-col md="4" sm="12">
                                <b-form-radio-group label="Tipo" class="ml-3 mt-4">
                                    <b-form-radio v-model="type" name="some-radios" value="S">Sintético</b-form-radio>
                                    <b-form-radio v-model="type" name="some-radios" value="A">Analítico</b-form-radio>
                                </b-form-radio-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="1" sm="12">
                                <b-button size="sm" variant="success" class="mt-2" @click="getAttendancesDone()">Processar</b-button>
                            </b-col>
                        </b-row>
                    </b-card>                    
                </b-col>                  
            </b-row>   
        </div>
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'
import Lookup from "../default/Lookup"
import Moment from 'moment'

export default {
    name: "RepAttendancesDone",
    components: { PageTitle, Lookup },    
    data: function() {
        return {
            theme: null,
            professional: {},
            dateInitial: null,
            dateFinal: null,
            attendancesDone: null,
            resultProfessionals: [],
            generatingPDF: false,
            textBtnPrint: 'GERAR',
            invalidFeedback: "",
            modal: null,
            type: null,
            covenant: {},
            covenants: [],
            fieldsPr: [
                { key: "name", label: "Nome", sortable: true },
                { key: "cpf", label: "CPF", sortable: true }
            ],
            fieldsCv: [
                { key: "fantasyname", label: "Nome", sortable: true },
                { key: "cnpj", label: "CNPJ", sortable: true }                
            ]              
        }
    },
    methods: {
        async showReportSynthetic() {
            let list = []
            let listTopics = []

            let fullTotal = 0
            let fullProfessional = 0
            let totalProfessional = 0.0
            let fullValue = 0.0

            let listTotals = []

            for (let a of this.attendancesDone) {                 
                let finded = false

                for (let at of a.attendances) {
                    finded = false

                    let lt = listTotals.find(element => element.name == at.nameAttendance)

                    if (lt) {
                        lt.count += 1
                        lt.value += Number(at.valueUnit)
                    } else {
                        listTotals.push({
                            name: at.nameAttendance,
                            count: 1,
                            value: Number(at.valueUnit)                            
                        })
                    }
                    
                    for (let l of list) {     
                        if (this.type == "S") {                       
                            if (l.nameAttendance == at.nameAttendance && l.covenant == at.covenant && l.key == a.nameProfessional) {
                                l.numberTotal += 1    
                                l.valueTotal = l.numberTotal * Number(l.valueUnit)

                                finded = true
                                break
                            }
                        } else {
                            if (l.nameAttendance == at.nameAttendance && l.covenant == at.covenant && l.key == a.nameProfessional && l.idPatient == at.patient._id) {
                                l.numberTotal += 1    
                                l.valueTotal = l.numberTotal * Number(l.valueUnit)

                                finded = true
                                break
                            }                            
                        }
                    }

                    if (!finded) {
                        if (this.type == 'S') {
                            list.push({
                                'nameAttendance': at.nameAttendance,
                                'covenant': at.covenant,
                                'numberTotal': 1,
                                'valueUnit': at.valueUnit,
                                'valueTotal': at.valueUnit,
                                key: a.nameProfessional
                            })
                        } else {
                            list.push({
                                'nameAttendance': at.nameAttendance,
                                'covenant': at.covenant,
                                'idPatient': at.patient._id,
                                'patient': at.patient.name,
                                'numberAttendance': at.patient.admissionAttendanceNumber,
                                'numberTotal': 1,
                                'valueUnit': at.valueUnit,
                                'valueTotal': at.valueUnit,
                                key: a.nameProfessional
                            })
                        }
                    }
                }                  
            }     

            if (list.length == 0) {
                this.$toasted.global.defaultError({ msg: 'Nenhum apontamento encontrado.' })
            }

            let lastProf = list[0].key
            fullProfessional = 0            
            fullTotal = 0
            totalProfessional = 0.0

            for (let l of list) {
                if (lastProf != l.key) {
                    listTopics.push(`${lastProf} - ${fullProfessional} Atendimentos - ${parseFloat(totalProfessional).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}`)
                    fullProfessional = 0
                    totalProfessional = 0.0
                }

                fullTotal += l.numberTotal
                fullProfessional += l.numberTotal                

                totalProfessional += Number(l.valueTotal)

                fullValue += Number(l.valueTotal)

                lastProf = l.key
            }            

            listTopics.push(`${lastProf} - ${fullProfessional} Atendimentos - ${parseFloat(totalProfessional).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}`)

            for (let l of list) { 
                l.valueUnit = parseFloat(l.valueUnit).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                l.valueTotal = parseFloat(l.valueTotal).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})

                for (let lt of listTopics) {
                    if (lt.split(' -')[0] == `${l.key}`) {
                        l.key = lt
                        break
                    }
                }
            }

            for (let lt of listTotals) { 
                lt.value = parseFloat(lt.value).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
            }   
            
            listTotals.push({
                name: "<b>TOTAL GERAL</b>",
                count: `<b>${fullTotal}</b>`,
                value: `<b>${parseFloat(fullValue).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b>`
            })

            let listTopics2 = []

            listTopics2.push(`TOTAIS`)

            list.sort((a,b) => { 
                if (a.covenant > b.covenant) {
                    return 1;
                }
                if (a.covenant < b.covenant) {
                    return -1;
                }
                
                return 0;            
            })            
            
            let contentPdf = {
                title: `RELATÓRIO DE PRODUTIVIDADE - ${Moment(this.dateInitial).format('L')} a ${Moment(this.dateFinal).format('L')}`,
                format: 'A4',
                orientation: this.type == "S" ? 'portrait' : 'landscape',
                separate: false,
                content: [                    
                    {
                        type: 'table',
                        topicFields: listTopics,
                        fields: this.type == "S" ? 
                        [    
                            {
                                original: 'covenant',
                                exibition: 'Convênio'
                            },                                                                                        
                            {
                                original: 'nameAttendance',
                                exibition: 'Tipo Atendimento'
                            },
                            {
                                original: 'numberTotal',
                                exibition: 'Nº Atendimentos'
                            },
                            {
                                original: 'valueUnit',
                                exibition: 'Valor Unit.'
                            },
                            {
                                original: 'valueTotal',
                                exibition: 'Valor Total'
                            }
                        ] :
                        [
                        {
                                original: 'covenant',
                                exibition: 'Convênio'
                            },                                                                                        
                            {
                                original: 'nameAttendance',
                                exibition: 'Tipo Atendimento'
                            },
                            {
                                original: 'patient',
                                exibition: 'Paciente'
                            },
                            {
                                original: 'numberAttendance',
                                exibition: 'Nº Atendimento'
                            },                                                        
                            {
                                original: 'numberTotal',
                                exibition: 'Quantidade'
                            },
                            {
                                original: 'valueUnit',
                                exibition: 'Valor Unit.'
                            },
                            {
                                original: 'valueTotal',
                                exibition: 'Valor Total'
                            }                            
                        ],
                        list: list,
                        topicFields2: listTopics2,
                        fields2: [
                            {
                                original: 'name',
                                exibition: 'Tipo de Atendimento',
                                notDisplay: listTopics
                            },                                                                                        
                            {
                                original: 'count',
                                exibition: 'Quantidade',
                                notDisplay: listTopics
                            },
                            {
                                original: 'value',
                                exibition: 'Valor Total',
                                notDisplay: listTopics
                            }
                        ],
                        list2: listTotals
                    }
                ]
            }

            const url = `${baseApiUrl}/reports/pdfGenerator/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.post(url, contentPdf, {responseType: 'arraybuffer'}).then(res => {  
                var file = new Blob([res.data], {type: 'application/pdf'})
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);

                this.generatingPDF = false
                this.textBtnPrint = 'GERAR'    
            }).catch((error) => {
                console.log(error)
                this.$toasted.global.defaultError({msg: error.response.data})
            })
        },
        async getAttendancesDone() {
            let valid = true

            if (this.dateInitial == null || this.dateFinal == null) {
                this.$toasted.global.defaultError({msg: 'Informe a data inicial e final.'})
                valid = false
            } 

            if (this.type == null) {
                this.$toasted.global.defaultError({msg: 'Informe o tipo.'})
                valid = false
            }

            Moment.locale('pt-br')

            let dtInitial = Moment(this.dateInitial)
            let dtFinal = Moment(this.dateFinal)  

            let diff = dtFinal.diff(dtInitial, 'days')

            if (diff < 0) {
                this.$toasted.global.defaultError({msg: 'Data final menor que data inicial.'})
                valid = false
            } 

            if (valid) {         
                this.$bvModal.msgBoxConfirm('Deseja processar o período informado?', {
                    title: 'Confirmação',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Sim',
                    cancelTitle: 'Não',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                }).then(value => {            
                    if (value) {
                        this.generatingPDF = true
                        this.textBtnPrint = 'Aguarde...' 

                        let data = {
                            dateInitial: this.dateInitial,
                            dateFinal: this.dateFinal,
                            cpf: this.professional.cpf != '' ? this.professional.cpf : null,
                            covenant: this.covenant.companyname != null ? this.covenant : null,
                            type: this.type
                        }

                        const url = `${baseApiUrl}/scale/repProductivity/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
                        axios.post(url, data).then(res => {
                            this.attendancesDone = res.data  

                            this.showReportSynthetic()
                        }).catch(showError)        
                    }
                })
            }      
        },
        loadProfessionals() {      
            const url = `${baseApiUrl}/professional/enabled/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`;

            axios.get(url).then((res) => {
                for (let d of res.data) {
                    this.resultProfessionals.push({
                        id: d._id,
                        name: d.name,
                        cpf: d.cpf,
                        adviceNumber: d.advice.number,
                    });
                }
            }).catch(showError);
        },
        addProfessional(payload) {
            this.professional = {
                id: payload.professionalSelected.id,
                name: payload.professionalSelected.name,
                cpf: payload.professionalSelected.cpf,
                adviceNumber: payload.professionalSelected.adviceNumber,
            };
        },
        addCovenant(payload) {
            this.covenant = payload.covenantSelected
        },
        setModal(param) {
            this.modal = param
        },
        loadCovenants() {
            const url = `${baseApiUrl}/covenants/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                this.covenants = res.data            
            }).catch(showError)                      
        }
    },
    mounted() {
        this.theme = localStorage.getItem('theme')

        this.loadProfessionals()
        this.loadCovenants()
    }    
}
</script>

<style>

</style>