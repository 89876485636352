<template>
  <Loading v-if="isLoading" />
  <div v-else class="professional-pages">
    <PageTitle icon="fa fa-user-md" main="Profissionais" sub />
    <div class="admin-pages-tabs">
        <b-button size="sm" variant="success" @click="newProfessional()">
            NOVO
        </b-button>      
          <b-button size="sm" class="ml-3" @click="print" variant="info" v-b-popover.hover.top="'Imprimir Listagem'">
              <div class="d-flex align-items-center">
                  <b-spinner small v-if="generatingPDF" class="mr-2"></b-spinner>
                  <span>{{textBtnPrint}}</span>
              </div>
          </b-button>    
        <hr>
        <b-row>
          <b-col md="4" sm="12">
            <b-form-input size="sm" placeholder="Filtrar..." v-model="ftext"></b-form-input>            
          </b-col> 
          <b-col md="2" sm="12">
            <b-button size="sm" @click="loadProfessionals"><i class="fa fa-search-plus"></i></b-button>
          </b-col>
        </b-row>             
        <hr>        
        <b-table ref="selectableTable" head-variant="light" outlined hover :items="professionals" :fields="fields" responsive="sm" id="my-table"
                 :per-page="perPage" :current-page="currentPage" striped fixed :filter="ftext">
            <template v-slot:cell(actions)="row">
                <b-button size="sm" variant="warning" @click="onRowSelected(row.item)" v-b-popover.hover.top="'Alterar'">
                    <i class="fa fa-pencil"></i>
                </b-button>
                <b-button v-if="!row.item.deletedAt" class="ml-2" size="sm" variant="danger" @click="disableProfessional(row.item)" v-b-popover.hover.top="'Desabilitar'">
                    <i class="fa fa-minus-circle"></i>
                </b-button>
            </template>
        </b-table>

        <div v-if="loading" class="d-flex justify-content-center mb-3">    
          <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner" type="grow"></b-spinner>
        </div>          

        <b-row class="ml-1">
          <b-pagination size="sm" pills v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table" class="page-item mt-1">
          </b-pagination>         
            <h5 class="badge1"><b-badge class="ml-3" pill variant="info">Solicitantes</b-badge></h5>
            <h5 class="badge2"><b-badge class="ml-3" pill variant="danger">Desabilitados</b-badge></h5>
        </b-row>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../template/PageTitle";
import { baseApiUrl, userKey } from '@/global'
import axios from 'axios'
import Loading from "../../template/Loading"

export default {
    name: 'Professionals',
    components: { PageTitle, Loading },
    data: function() {
      return {
        isLoading: false,
        generatingPDF: false,
        textBtnPrint: 'IMPRIMIR',        
        ftext: '',
        covenants: [],
        professionals: [],
        currentPage: 1,
        perPage: 10,          
        fields: [                 
            { key: 'name', label: 'Nome', sortable: true },
            { key: 'cpf', label: 'CPF', sortable: true },
            { key: 'advice.number', label: 'Registro', sortable: true },
            { key: 'email', label: 'E-mail', sortable: true },
            { key: 'actions', label: 'Ações' }
        ],          
      }
    },
    computed: {
      rows() {
        return this.professionals.length
      }
    },      
    methods: {
        print() {
            this.generatingPDF = true
            this.textBtnPrint = 'Aguarde...' 

            let contentPdf = {
                title: 'PROFISSIONAIS',
                format: 'A4',
                orientation: 'landscape',
                separate: false,
                content: [
                    {
                        type: 'table',
                        topicFields: [],
                        fields: [
                          {
                            original: 'name',
                            exibition: 'Nome'
                          },
                          {
                            original: 'cpf',
                            exibition: 'CPF'
                          },
                          {
                            original: 'advice.number',
                            exibition: 'Registro'
                          },                                                                              
                          {
                            original: 'email',
                            exibition: 'Email'
                          },
                          {
                            original: 'phone1',
                            exibition: 'Fone'
                          },
                        ],
                        list: this.professionals
                    }
                ]
            }

            this.isLoading = true

            const url = `${baseApiUrl}/reports/pdfGenerator/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.post(url, contentPdf, {responseType: 'arraybuffer'}).then(res => {  
              var file = new Blob([res.data], {type: 'application/pdf'})
              var fileURL = URL.createObjectURL(file);
              window.open(fileURL);

              this.isLoading = false
              this.generatingPDF = false
              this.textBtnPrint = 'IMPRIMIR'
            }).catch(e => {
              this.isLoading = false
              this.$toasted.global.defaultError({ msg: e.response.data })              
            })       
        },
        newProfessional() {
          this.$router.push({name: 'professional', params: { },})          
        },
        onRowSelected(itemSelected) {
          let professionalSelected = {    
            _id: itemSelected._id,
            name: itemSelected.name,
            birthday: itemSelected.birthday,
            gender: itemSelected.gender,
            cpf: itemSelected.cpf,        
            rg: itemSelected.rg,
            pis: itemSelected.pis,
            hiringDate: itemSelected.hiringDate,
            advice: itemSelected.advice ? itemSelected.advice : { _id: '', number: '' },
            specialities: itemSelected.specialities,
            lastVacationDate: itemSelected.lastVacationDate,
            email: itemSelected.email,
            phone1: itemSelected.phone1,
            phone2: itemSelected.phone2,
            address: itemSelected.address,
            comments: itemSelected.comments,    
            qualifications: itemSelected.qualifications,
            deletedAt: itemSelected.deletedAt, 
            requester: itemSelected.requester ? itemSelected.requester : false,
            categoryAttendance: itemSelected.categoryAttendance,
            covenant: itemSelected.covenant        
          }              

          this.$router.push({name: 'professional', params: { professionalSelected },})            
        },         
        disableProfessional(itemSelected) {
            this.$bvModal.msgBoxConfirm('Deseja desabilitar o profissional?', {
                title: 'Confirmação',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Sim',
                cancelTitle: 'Não',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            }).then(value => {            
                if (value) {                
                  const id = itemSelected._id

                  this.isLoading = true

                  axios.delete(`${baseApiUrl}/professional/${id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`).then(() => {
                    this.isLoading = false
                    this.$toasted.global.defaultSuccess()
                    this.loadProfessionals()
                  }).catch(e => {
                    this.isLoading = false
                    this.$toasted.global.defaultError({ msg: e.response.data })                    
                  })                
                }                
            }).catch()   
        },     
        loadProfessionals() {
            this.isLoading = true

            const url = `${baseApiUrl}/professional/perName/${this.ftext.toUpperCase() ? this.ftext.toUpperCase() : null}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                this.professionals = res.data           
                
                for (let p of this.professionals) {
                  p._cellVariants = {
                    name: p.deletedAt != null ? 'danger' : (p.requester ? 'info' : ''),
                    cpf: p.deletedAt != null ? 'danger' : (p.requester ? 'info' : ''),
                    'advice.number': p.deletedAt != null ? 'danger' : (p.requester ? 'info' : ''), 
                    email: p.deletedAt != null ? 'danger' : (p.requester ? 'info' : ''),
                    actions: p.deletedAt != null ? 'danger' : (p.requester ? 'info' : ''),
                  }
                }   
                
                this.isLoading = false
            }).catch(e => {
              this.isLoading = false
              this.$toasted.global.defaultError({ msg: e.response.data })
            })
        },    
    },
};
</script>

<style>
.page-item .page-font {
    color:#44A08D;  
}

.page-item.active .page-link {  
    background-color:#44A08D;  
    border-color:  #44A08D;      
}

.page-item.active .page-link:hover {  
    background-color:#44A08D;  
    border-color:  #44A08D;  
}

.card-header {
    height: 38px;
    font-size: 14px;    
}

.card-body {
    font-size: 14px;
}

.calendar-btn {
    font-size: 0.30;
}

.table th {
  padding: 0.45rem;
}

.table td {
  padding: 0.45rem;
  vertical-align: middle;
}
</style>