<template>
    <Loading v-if="isLoading" />
    <div v-else class="typeService-admin">
        <PageTitle icon="fa fa-medium" main="Cadastro de Tipos de Atendimentos" sub />                  
        <b-form name="form1">            
            <input id="typeService-id" type="hidden" v-model="typeService.id"/>
            <b-row name="tsName">
                <b-col md="12" sm="12">
                    <b-card :class="`card-${theme}`" bg-variant="default" header="Informações Gerais" header-bg-variant="dark" header-text-variant="white">
                        <b-row>
                            <b-col md="4" sm="12">
                                <b-form-group label="Nome:" label-for="typeService-name" class="mt-3">
                                    <b-form-input size="sm" id="typeService-name" type="text"
                                        v-model="typeService.name" required                       
                                        placeholder="Informe o Nome do Tipo de Atendimento..." />
                                </b-form-group>                              
                            </b-col>                        
                            <b-col md="4" sm="12">
                                <b-form-group label="Avaliação:" label-for="typeService-question" class="mt-3">
                                    <b-form-select size="sm" v-model="typeService.question" :options="questions"></b-form-select>
                                </b-form-group>                             
                            </b-col> 
                            <b-col md="4" sm="12">
                                <b-form-checkbox size="sm" v-model="typeService.launchOnceAdmission" class="mt-5" :value="true" :unchecked-value="false">Apenas Uma Vez Por Admissão</b-form-checkbox>
                            </b-col>                                                   
                        </b-row>
                    </b-card>
                </b-col>                  
            </b-row>
            <b-row name="tsName">
                <b-col md="12" sm="12">
                    <b-card :class="`card-${theme} mt-3`" bg-variant="default" header="Atendimentos Secundários" header-bg-variant="dark" header-text-variant="white">
                        <b-row>                            
                            <b-button size="sm" v-b-modal.modal-prevent-closing variant="success" class="mb-3 ml-3" @event="insertTypeService">
                                <i class="fa fa-plus"></i>
                            </b-button>
                            <Lookup ref="Lookup" title="Tipos de Atendimentos" :list="allTypeServices" :fields="fields" 
                                    @event="insertTypeService" :invalidFeedback="invalidFeedback" resultTable="typeServiceSecondary"></Lookup>
                            <b-col md="12" sm="12">         
                                <b-table ref="selectableTableTypeService" head-variant="light" outlined hover :items="typeService.secondaries" :fields="fieldsS" 
                                         responsive="sm" fixed @input="validTypeServiceSecondary">
                                    <template v-slot:cell(actions)="row">
                                        <b-button size="sm" variant="danger" @click="removeTypeServiceSecondary(row.index)">
                                            <i class="fa fa-trash"></i>
                                        </b-button>
                                    </template>
                                </b-table>                            
                            </b-col>
                        </b-row>       
                    </b-card>   
                </b-col>                  
            </b-row>
        </b-form>

        <b-button size="sm" variant="success" @click="save" class="mt-3">SALVAR</b-button>  
        <b-button size="sm" variant="danger" @click="cancel" class="mt-3 ml-2">CANCELAR</b-button>          
    </div>
</template>

<script>
import PageTitle from "../../template/PageTitle";
import { baseApiUrl, userKey } from '@/global'
import axios from 'axios'
import Lookup from '../../default/Lookup'
import Loading from "../../template/Loading"

export default {
    name: "TypeServicePage",
    components: { PageTitle, Lookup, Loading },
    data: function() {
      return { 
        theme: null,
        isLoading: false,
        typeService: {},
        questions: [],
        invalidFeedback: '',
        allTypeServices: [],
        fields: [                 
            { key: 'name', label: 'Nome', sortable: false }
        ], 
        fieldsS: [                 
            { key: 'name', label: 'Nome', sortable: false },
            { key: 'actions', label: 'Ação', sortable: false }
        ],            
      }    
    },
    methods: {           
        save() {
            this.typeService.name = this.typeService.name.toUpperCase()

            const method = this.typeService._id ? 'put' : 'post'
            const id = this.typeService._id ? `/${this.typeService._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}` : `/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            
            this.isLoading = true

            axios[method](`${baseApiUrl}/typeService${id}`, this.typeService).then(() => {
                this.isLoading = false
                this.$toasted.global.defaultSuccess()
                this.$router.go(-1)
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })                    
            })
        },  
        cancel() {
            this.$router.go(-1)
        },
        loadQuestions() {
            this.isLoading = true

            const url = `${baseApiUrl}/questions/1&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                for (let r of res.data) {
                    this.questions.push({ value: r, text: r.name })                        
                }

                this.isLoading = false
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })                    
            })
        },
        async insertTypeService(payload) {
            if (!this.typeService.secondaries) {
                this.typeService.secondaries = []
            }

            let result = await this.validTypeServiceSecondary(payload.typeServiceSelected._id)

            if (result) {
                this.typeService.secondaries.push(payload.typeServiceSelected)
            } else {
                this.$toasted.global.defaultError({msg: 'Tipo de Atendimento já incluído'})
            }
        },
        loadTypeServices() {
            this.isLoading = true

            const url = `${baseApiUrl}/typeService/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {  
                this.allTypeServices = []

                for (let d of res.data) {
                    if (d.name != this.typeService.name) {
                        this.allTypeServices.push(d)
                    }
                }  
                
                this.isLoading = false
            }).catch(e => {
                this.isLoading = false
                this.$toasted.global.defaultError({ msg: e.response.data })                    
            })                      
        },
        removeTypeServiceSecondary(index) {
            this.typeService.secondaries.splice(index, 1)
        },
        async validTypeServiceSecondary(id) {
            for (let s of this.typeService.secondaries) {
                if (s._id == id) {
                    return false
                }
            }

            return true
        },        
    },
    mounted() {
        this.theme = localStorage.getItem('theme')        

        if (this.$route.params.typeServiceSelected) {
            this.typeService = this.$route.params.typeServiceSelected                          
        }

        this.loadQuestions()
        this.loadTypeServices()
    }
}     
</script>

<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}

.card-violet {
  background-color: #f3eaff;
}

.card-header {
    height: 38px;
    font-size: 14px;    
}

.card-body {
    font-size: 14px;
}

.calendar-btn {
    font-size: 0.30;
}

.table th {
  padding: 0.45rem;
}

.table td {
  padding: 0.45rem;
  vertical-align: middle;
}
</style>