<template>
  <div class="satisfactionSurvey">
    <PageTitle icon="fa fa-file-text" main="Questionário" sub="DASH – QUESTIONNAIRE DISABILITIES OF THE ARM, SHOULDER AND HAND" />
  
    <div>
      <b-card :class="`card-${theme}`">
        <b-button v-show="question.status == 0" @click="sendQuestions()" variant="info">Enviar</b-button>

        <b-form-group class="mt-3">
                    <b-button-group>
                        <b-button @click="numberQuestion = 1" :variant="(selected1 != null) ? 'success' : 'secondary'">1</b-button>
                        <b-button @click="numberQuestion = 2" :variant="(selected2 != null) ? 'success' : 'secondary'">2</b-button>
                        <b-button @click="numberQuestion = 3" :variant="(selected3 != null) ? 'success' : 'secondary'">3</b-button>
                        <b-button @click="numberQuestion = 4" :variant="(selected4 != null) ? 'success' : 'secondary'">4</b-button>
                        <b-button @click="numberQuestion = 5" :variant="(selected5 != null) ? 'success' : 'secondary'">5</b-button>                                                                         
                        <b-button @click="numberQuestion = 6" :variant="(selected6 != null) ? 'success' : 'secondary'">6</b-button>
                        <b-button @click="numberQuestion = 7" :variant="(selected7 != null) ? 'success' : 'secondary'">7</b-button>
                        <b-button @click="numberQuestion = 8" :variant="(selected8 != null) ? 'success' : 'secondary'">8</b-button>
                        <b-button @click="numberQuestion = 9" :variant="(selected9 != null) ? 'success' : 'secondary'">9</b-button>
                        <b-button @click="numberQuestion = 10" :variant="(selected10 != null) ? 'success' : 'secondary'">10</b-button>
                        <b-button @click="numberQuestion = 11" :variant="(selected11 != null) ? 'success' : 'secondary'">11</b-button>
                        <b-button @click="numberQuestion = 12" :variant="(selected12 != null) ? 'success' : 'secondary'">12</b-button>
                        <b-button @click="numberQuestion = 13" :variant="(selected13 != null) ? 'success' : 'secondary'">13</b-button>
                        <b-button @click="numberQuestion = 14" :variant="(selected14 != null) ? 'success' : 'secondary'">14</b-button>
                        <b-button @click="numberQuestion = 15" :variant="(selected15 != null) ? 'success' : 'secondary'">15</b-button>
                        <b-button @click="numberQuestion = 16" :variant="(selected16 != null) ? 'success' : 'secondary'">16</b-button>
                        <b-button @click="numberQuestion = 17" :variant="(selected17 != null) ? 'success' : 'secondary'">17</b-button>
                        
                      </b-button-group> 
            </b-form-group>   
            <b-form-group class="mt-3">      
                      <b-button-group> 
                        <b-button @click="numberQuestion = 18" :variant="(selected18 != null) ? 'success' : 'secondary'">18</b-button>
                        <b-button @click="numberQuestion = 19" :variant="(selected19 != null) ? 'success' : 'secondary'">19</b-button>
                        <b-button @click="numberQuestion = 20" :variant="(selected20 != null) ? 'success' : 'secondary'">20</b-button>
                        <b-button @click="numberQuestion = 21" :variant="(selected21 != null) ? 'success' : 'secondary'">21</b-button>
                        <b-button @click="numberQuestion = 22" :variant="(selected22 != null) ? 'success' : 'secondary'">22</b-button>
                        <b-button @click="numberQuestion = 23" :variant="(selected23 != null) ? 'success' : 'secondary'">23</b-button>
                        <b-button @click="numberQuestion = 24" :variant="(selected24 != null) ? 'success' : 'secondary'">24</b-button>
                        <b-button @click="numberQuestion = 25" :variant="(selected25 != null) ? 'success' : 'secondary'">25</b-button>
                        <b-button @click="numberQuestion = 26" :variant="(selected26 != null) ? 'success' : 'secondary'">26</b-button>
                        <b-button @click="numberQuestion = 27" :variant="(selected27 != null) ? 'success' : 'secondary'">27</b-button>
                        <b-button @click="numberQuestion = 28" :variant="(selected28 != null) ? 'success' : 'secondary'">28</b-button>
                        <b-button @click="numberQuestion = 29" :variant="(selected29 != null) ? 'success' : 'secondary'">29</b-button>
                        <b-button @click="numberQuestion = 30" :variant="(selected30 != null) ? 'success' : 'secondary'">30</b-button>
                        <b-button @click="numberQuestion = 31" :variant="(selected31 != null) ? 'success' : 'secondary'">31</b-button>
                        <b-button @click="numberQuestion = 32" :variant="(selected32 != null) ? 'success' : 'secondary'">32</b-button>
                    </b-button-group>
        </b-form-group>

        <b-form-group v-show="numberQuestion == 1" class="mt-3" label="">
            <b-form-group v-show="numberQuestion == 1" class="ml-5" label="1) Abrir um vidro novo ou com a tampa muito apertada:">
                <b-form-radio v-model="selected1" name="some-radios-1" value="1">Não houve dificuldade</b-form-radio>
                <b-form-radio v-model="selected1" name="some-radios-1" value="2">Houve pouca dificuldade</b-form-radio>
                <b-form-radio v-model="selected1" name="some-radios-1" value="3">Houve dificuldade média</b-form-radio>
                <b-form-radio v-model="selected1" name="some-radios-1" value="4">Houve muita dificuldade</b-form-radio>
                <b-form-radio v-model="selected1" name="some-radios-1" value="5">Não conseguiu fazer</b-form-radio>
            </b-form-group>
       </b-form-group>

       <b-button v-show="numberQuestion == 1" variant="success" @click="numberQuestion = 2">Ok</b-button>

       <b-form-group v-show="numberQuestion == 2" class="mt-3" label="">
            <b-form-group v-show="numberQuestion == 2" class="ml-5" label="2) Escrever:">
                <b-form-radio v-model="selected2" name="some-radios-2" value="1">Não houve dificuldade</b-form-radio>
                <b-form-radio v-model="selected2" name="some-radios-2" value="2">Houve pouca dificuldade</b-form-radio>
                <b-form-radio v-model="selected2" name="some-radios-2" value="3">Houve dificuldade média</b-form-radio>
                <b-form-radio v-model="selected2" name="some-radios-2" value="4">Houve muita dificuldade</b-form-radio>
                <b-form-radio v-model="selected2" name="some-radios-2" value="5">Não conseguiu fazer</b-form-radio>
            </b-form-group>    
       </b-form-group> 

       <b-button v-show="numberQuestion == 2" variant="success" @click="numberQuestion = 3">Ok</b-button>

       <b-form-group v-show="numberQuestion == 3" class="mt-3" label="">
            <b-form-group v-show="numberQuestion == 3" class="ml-5" label="3) Virar uma chave:">
                <b-form-radio v-model="selected3" name="some-radios-3" value="1">Não houve dificuldade</b-form-radio>
                <b-form-radio v-model="selected3" name="some-radios-3" value="2">Houve pouca dificuldade</b-form-radio>
                <b-form-radio v-model="selected3" name="some-radios-3" value="3">Houve dificuldade média</b-form-radio>
                <b-form-radio v-model="selected3" name="some-radios-3" value="4">Houve muita dificuldade</b-form-radio>
                <b-form-radio v-model="selected3" name="some-radios-3" value="5">Não conseguiu fazer</b-form-radio>
            </b-form-group> 
       </b-form-group>

       <b-button v-show="numberQuestion == 3" variant="success" @click="numberQuestion = 4">Ok</b-button>

       <b-form-group v-show="numberQuestion == 4" class="mt-3" label="">
            <b-form-group v-show="numberQuestion == 4" class="ml-5" label="4) Preparar uma refeição:">
                <b-form-radio v-model="selected4" name="some-radios-4" value="1">Não houve dificuldade</b-form-radio>
                <b-form-radio v-model="selected4" name="some-radios-4" value="2">Houve pouca dificuldade</b-form-radio>
                <b-form-radio v-model="selected4" name="some-radios-4" value="3">Houve dificuldade média</b-form-radio>
                <b-form-radio v-model="selected4" name="some-radios-4" value="4">Houve muita dificuldade</b-form-radio>
                <b-form-radio v-model="selected4" name="some-radios-4" value="5">Não conseguiu fazer</b-form-radio>
            </b-form-group> 
       </b-form-group>

       <b-button v-show="numberQuestion == 4" variant="success" @click="numberQuestion = 5">Ok</b-button>

       <b-form-group v-show="numberQuestion == 5" class="mt-3" label="">
            <b-form-group v-show="numberQuestion == 5" class="ml-5" label="5) Abrir uma porta pesada:">
                <b-form-radio v-model="selected5" name="some-radios-5" value="1">Não houve dificuldade</b-form-radio>
                <b-form-radio v-model="selected5" name="some-radios-5" value="2">Houve pouca dificuldade</b-form-radio>
                <b-form-radio v-model="selected5" name="some-radios-5" value="3">Houve dificuldade média</b-form-radio>
                <b-form-radio v-model="selected5" name="some-radios-5" value="4">Houve muita dificuldade</b-form-radio>
                <b-form-radio v-model="selected5" name="some-radios-5" value="5">Não conseguiu fazer</b-form-radio>
            </b-form-group>
        </b-form-group>

        <b-button v-show="numberQuestion == 5" variant="success" @click="numberQuestion = 6">Ok</b-button>

        <b-form-group v-show="numberQuestion == 6" class="mt-3" label="">
            <b-form-group v-show="numberQuestion == 6" class="ml-5" label="6) Colocar algo em uma prateleira acima de sua cabeça:">
                <b-form-radio v-model="selected6" name="some-radios-6" value="1">Não houve dificuldade</b-form-radio>
                <b-form-radio v-model="selected6" name="some-radios-6" value="2">Houve pouca dificuldade</b-form-radio>
                <b-form-radio v-model="selected6" name="some-radios-6" value="3">Houve dificuldade média</b-form-radio>
                <b-form-radio v-model="selected6" name="some-radios-6" value="4">Houve muita dificuldade</b-form-radio>
                <b-form-radio v-model="selected6" name="some-radios-6" value="5">Não conseguiu fazer</b-form-radio>
            </b-form-group>    
        </b-form-group>

        <b-button v-show="numberQuestion == 6" variant="success" @click="numberQuestion = 7">Ok</b-button>

        <b-form-group v-show="numberQuestion == 7" class="mt-3" label="">
            <b-form-group v-show="numberQuestion == 7" class="ml-5" label="7) Fazer tarefas domésticas pesadas (por exemplo: lavar paredes, lavar o chão):">
                <b-form-radio v-model="selected7" name="some-radios-7" value="1">Não houve dificuldade</b-form-radio>
                <b-form-radio v-model="selected7" name="some-radios-7" value="2">Houve pouca dificuldade</b-form-radio>
                <b-form-radio v-model="selected7" name="some-radios-7" value="3">Houve dificuldade média</b-form-radio>
                <b-form-radio v-model="selected7" name="some-radios-7" value="4">Houve muita dificuldade</b-form-radio>
                <b-form-radio v-model="selected7" name="some-radios-7" value="5">Não conseguiu fazer</b-form-radio>
            </b-form-group>  
        </b-form-group>    
        
        <b-button v-show="numberQuestion == 7" variant="success" @click="numberQuestion = 8">Ok</b-button>

        <b-form-group v-show="numberQuestion == 8" class="mt-3" label="">
           <b-form-group v-show="numberQuestion == 8" class="ml-5" label="8) Fazer trabalho de jardinagem:">
            <b-form-radio v-model="selected8" name="some-radios-8" value="1">Não houve dificuldade</b-form-radio>
            <b-form-radio v-model="selected8" name="some-radios-8" value="2">Houve pouca dificuldade</b-form-radio>
            <b-form-radio v-model="selected8" name="some-radios-8" value="3">Houve dificuldade média</b-form-radio>
            <b-form-radio v-model="selected8" name="some-radios-8" value="4">Houve muita dificuldade</b-form-radio>
            <b-form-radio v-model="selected8" name="some-radios-8" value="5">Não conseguiu fazer</b-form-radio>
           </b-form-group>
        </b-form-group>

        <b-button v-show="numberQuestion == 8" variant="success" @click="numberQuestion = 9">Ok</b-button>

        <b-form-group v-show="numberQuestion == 9" class="mt-3" label="">
           <b-form-group v-show="numberQuestion == 9" class="ml-5" label="9) Arrumar a cama:">
             <b-form-radio v-model="selected9" name="some-radios-9" value="1">Não houve dificuldade</b-form-radio>
             <b-form-radio v-model="selected9" name="some-radios-9" value="2">Houve pouca dificuldade</b-form-radio>
             <b-form-radio v-model="selected9" name="some-radios-9" value="3">Houve dificuldade média</b-form-radio>
             <b-form-radio v-model="selected9" name="some-radios-9" value="4">Houve muita dificuldade</b-form-radio>
             <b-form-radio v-model="selected9" name="some-radios-9" value="5">Não conseguiu fazer</b-form-radio>
            </b-form-group>  
        </b-form-group>
        
        <b-button v-show="numberQuestion == 9" variant="success" @click="numberQuestion = 10">Ok</b-button>
        
        <b-form-group v-show="numberQuestion == 10" class="mt-3" label="">
            <b-form-group v-show="numberQuestion == 10" class="ml-5" label="10) Carregar uma sacola ou uma maleta:">
                <b-form-radio v-model="selected10" name="some-radios-10" value="1">Não houve dificuldade</b-form-radio>
                <b-form-radio v-model="selected10" name="some-radios-10" value="2">Houve pouca dificuldade</b-form-radio>
                <b-form-radio v-model="selected10" name="some-radios-10" value="3">Houve dificuldade média</b-form-radio>
                <b-form-radio v-model="selected10" name="some-radios-10" value="4">Houve muita dificuldade</b-form-radio>
                <b-form-radio v-model="selected10" name="some-radios-10" value="5">Não conseguiu fazer</b-form-radio>
            </b-form-group>          
        </b-form-group>

        <b-button v-show="numberQuestion == 10" variant="success" @click="numberQuestion = 11">Ok</b-button>

        <b-form-group v-show="numberQuestion == 11" class="mt-3" label="">
            <b-form-group v-show="numberQuestion == 11" class="ml-5" label="11) Carregar um objeto pesado (mais de 5 kg):">
                <b-form-radio v-model="selected11" name="some-radios-11" value="1">Não houve dificuldade</b-form-radio>
                <b-form-radio v-model="selected11" name="some-radios-11" value="2">Houve pouca dificuldade</b-form-radio>
                <b-form-radio v-model="selected11" name="some-radios-11" value="3">Houve dificuldade média</b-form-radio>
                <b-form-radio v-model="selected11" name="some-radios-11" value="4">Houve muita dificuldade</b-form-radio>
                <b-form-radio v-model="selected11" name="some-radios-11" value="5">Não conseguiu fazer</b-form-radio>
            </b-form-group>
        </b-form-group>   

        <b-button v-show="numberQuestion == 11" variant="success" @click="numberQuestion = 12">Ok</b-button>
  
        <b-form-group v-show="numberQuestion == 12" class="ml-5" label="12) Trocar uma lâmpada acima da cabeça:">
               <b-form-radio v-model="selected12" name="some-radios-12" value="1">Não houve dificuldade</b-form-radio>
               <b-form-radio v-model="selected12" name="some-radios-12" value="2">Houve pouca dificuldade</b-form-radio>
               <b-form-radio v-model="selected12" name="some-radios-12" value="3">Houve dificuldade média</b-form-radio>
               <b-form-radio v-model="selected12" name="some-radios-12" value="4">Houve muita dificuldade</b-form-radio>
               <b-form-radio v-model="selected12" name="some-radios-12" value="5">Não conseguiu fazer</b-form-radio>
        </b-form-group> 

        <b-button v-show="numberQuestion == 12" variant="success" @click="numberQuestion = 13">Ok</b-button>

        <b-form-group v-show="numberQuestion == 13" class="ml-5" label="13) Lavar ou secar o cabelo:">
              <b-form-radio v-model="selected13" name="some-radios-13" value="1">Não houve dificuldade</b-form-radio>
              <b-form-radio v-model="selected13" name="some-radios-13" value="2">Houve pouca dificuldade</b-form-radio>
              <b-form-radio v-model="selected13" name="some-radios-13" value="3">Houve dificuldade média</b-form-radio>
              <b-form-radio v-model="selected13" name="some-radios-13" value="4">Houve muita dificuldade</b-form-radio>
              <b-form-radio v-model="selected13" name="some-radios-13" value="5">Não conseguiu fazer</b-form-radio>
        </b-form-group> 

        <b-button v-show="numberQuestion == 13" variant="success" @click="numberQuestion = 14">Ok</b-button>

        <b-form-group v-show="numberQuestion == 14" class="ml-5" label="14) Lavar suas costas:">
              <b-form-radio v-model="selected14" name="some-radios-14" value="1">Não houve dificuldade</b-form-radio>
              <b-form-radio v-model="selected14" name="some-radios-14" value="2">Houve pouca dificuldade</b-form-radio>
              <b-form-radio v-model="selected14" name="some-radios-14" value="3">Houve dificuldade média</b-form-radio>
              <b-form-radio v-model="selected14" name="some-radios-14" value="4">Houve muita dificuldade</b-form-radio>
              <b-form-radio v-model="selected14" name="some-radios-14" value="5">Não conseguiu fazer</b-form-radio>
        </b-form-group> 

        <b-button v-show="numberQuestion == 14" variant="success" @click="numberQuestion = 15">Ok</b-button>

        <b-form-group v-show="numberQuestion == 15" class="mt-3" label="">
            <b-form-group v-show="numberQuestion == 15" class="ml-5" label="15) Vestir uma blusa fechada:">
                <b-form-radio v-model="selected15" name="some-radios-15" value="1">Não houve dificuldade</b-form-radio>
                <b-form-radio v-model="selected15" name="some-radios-15" value="2">Houve pouca dificuldade</b-form-radio>
                <b-form-radio v-model="selected15" name="some-radios-15" value="3">Houve dificuldade média</b-form-radio>
                <b-form-radio v-model="selected15" name="some-radios-15" value="4">Houve muita dificuldade</b-form-radio>
                <b-form-radio v-model="selected15" name="some-radios-15" value="5">Não conseguiu fazer</b-form-radio>
            </b-form-group> 
        </b-form-group>
            
        <b-button v-show="numberQuestion == 15" variant="success" @click="numberQuestion = 16">Ok</b-button>      
            
        <b-form-group v-show="numberQuestion == 16" class="mt-3" label="">
            <b-form-group v-show="numberQuestion == 16" class="ml-5" label="16) Usar uma faca para cortar alimentos:">
                <b-form-radio v-model="selected16" name="some-radios-16" value="1">Não houve dificuldade</b-form-radio>
                <b-form-radio v-model="selected16" name="some-radios-16" value="2">Houve pouca dificuldade</b-form-radio>
                <b-form-radio v-model="selected16" name="some-radios-16" value="3">Houve dificuldade média</b-form-radio>
                <b-form-radio v-model="selected16" name="some-radios-16" value="4">Houve muita dificuldade</b-form-radio>
                <b-form-radio v-model="selected16" name="some-radios-16" value="5">Não conseguiu fazer</b-form-radio>
            </b-form-group>
        </b-form-group> 

        <b-button v-show="numberQuestion == 16" variant="success" @click="numberQuestion = 17">Ok</b-button>

        <b-form-group v-show="numberQuestion == 17" class="ml-5" label="17) Atividades recreativas que exigem pouco esforço (por exemplo: jogar cartas, tricotar):">
               <b-form-radio v-model="selected17" name="some-radios-17" value="1">Não houve dificuldade</b-form-radio>
               <b-form-radio v-model="selected17" name="some-radios-17" value="2">Houve pouca dificuldade</b-form-radio>
               <b-form-radio v-model="selected17" name="some-radios-17" value="3">Houve dificuldade média</b-form-radio>
               <b-form-radio v-model="selected17" name="some-radios-17" value="4">Houve muita dificuldade</b-form-radio>
               <b-form-radio v-model="selected17" name="some-radios-17" value="5">Não conseguiu fazer</b-form-radio>
        </b-form-group> 

        <b-button v-show="numberQuestion == 17" variant="success" @click="numberQuestion = 18">Ok</b-button>

        <b-form-group v-show="numberQuestion == 18" class="ml-5" label="18) Atividades recreativas que exigem força ou impacto nos braços, ombros ou mãos (por exemplo: jogar vôlei, martelar):">
              <b-form-radio v-model="selected18" name="some-radios-18" value="1">Não houve dificuldade</b-form-radio>
              <b-form-radio v-model="selected18" name="some-radios-18" value="2">Houve pouca dificuldade</b-form-radio>
              <b-form-radio v-model="selected18" name="some-radios-18" value="3">Houve dificuldade média</b-form-radio>
              <b-form-radio v-model="selected18" name="some-radios-18" value="4">Houve muita dificuldade</b-form-radio>
              <b-form-radio v-model="selected18" name="some-radios-18" value="5">Não conseguiu fazer</b-form-radio>
        </b-form-group> 

        <b-button v-show="numberQuestion == 18" variant="success" @click="numberQuestion = 19">Ok</b-button>

        <b-form-group v-show="numberQuestion == 19" class="ml-5" label="19) Atividades recreativas nas quais você move seu braço livremente (como pescar, jogar peteca):">
              <b-form-radio v-model="selected19" name="some-radios-19" value="1">Não houve dificuldade</b-form-radio>
              <b-form-radio v-model="selected19" name="some-radios-19" value="2">Houve pouca dificuldade</b-form-radio>
              <b-form-radio v-model="selected19" name="some-radios-19" value="3">Houve dificuldade média</b-form-radio>
              <b-form-radio v-model="selected19" name="some-radios-19" value="4">Houve muita dificuldade</b-form-radio>
              <b-form-radio v-model="selected19" name="some-radios-19" value="5">Não conseguiu fazer</b-form-radio>
        </b-form-group> 

        <b-button v-show="numberQuestion == 19" variant="success" @click="numberQuestion = 20">Ok</b-button>

        <b-form-group v-show="numberQuestion == 20" class="ml-5" label="20) Transportar-se de um lugar a outro (ir de um lugar a outro):">
              <b-form-radio v-model="selected20" name="some-radios-20" value="1">Não houve dificuldade</b-form-radio>
              <b-form-radio v-model="selected20" name="some-radios-20" value="2">Houve pouca dificuldade</b-form-radio>
              <b-form-radio v-model="selected20" name="some-radios-20" value="3">Houve dificuldade média</b-form-radio>
              <b-form-radio v-model="selected20" name="some-radios-20" value="4">Houve muita dificuldade</b-form-radio>
              <b-form-radio v-model="selected20" name="some-radios-20" value="5">Não conseguiu fazer</b-form-radio>
        </b-form-group> 

        <b-button v-show="numberQuestion == 20" variant="success" @click="numberQuestion = 21">Ok</b-button>

        <b-form-group v-show="numberQuestion == 21" class="ml-5" label="21) Atividades sexuais:">
              <b-form-radio v-model="selected21" name="some-radios-21" value="1">Não houve dificuldade</b-form-radio>
              <b-form-radio v-model="selected21" name="some-radios-21" value="2">Houve pouca dificuldade</b-form-radio>
              <b-form-radio v-model="selected21" name="some-radios-21" value="3">Houve dificuldade média</b-form-radio>
              <b-form-radio v-model="selected21" name="some-radios-21" value="4">Houve muita dificuldade</b-form-radio>
              <b-form-radio v-model="selected21" name="some-radios-21" value="5">Não conseguiu fazer</b-form-radio>
        </b-form-group> 
       
        <b-button v-show="numberQuestion == 21" variant="success" @click="numberQuestion = 22">Ok</b-button>  
        
        <b-form-group v-show="numberQuestion == 22" class="mt-3" label="">
            <b-form-group class="ml-5" label="22) Na semana passada, em que ponto o seu problema com braço, ombro ou mão afetou suas atividades normais com família, amigos, vizinhos ou colegas?">
                <b-form-radio v-model="selected22" name="some-radios-22" value="1">Não afetou</b-form-radio>
                <b-form-radio v-model="selected22" name="some-radios-22" value="2">Afetou pouco</b-form-radio>
                <b-form-radio v-model="selected22" name="some-radios-22" value="3">Afetou medianamente</b-form-radio>
                <b-form-radio v-model="selected22" name="some-radios-22" value="4">Afetou muito</b-form-radio>
                <b-form-radio v-model="selected22" name="some-radios-22" value="5">Afetou extremamente</b-form-radio>
            </b-form-group> 
        </b-form-group> 

        <b-button v-show="numberQuestion == 22" variant="success" @click="numberQuestion = 23">Ok</b-button> 

        <b-form-group v-show="numberQuestion == 23" class="ml-5" label="23) Durante a semana passada, o seu trabalho ou atividades diárias normais foram limitadas devido ao seu problema com braço, ombro ou mão?">
              <b-form-radio v-model="selected23" name="some-radios-23" value="1">Não Limitou</b-form-radio>
              <b-form-radio v-model="selected23" name="some-radios-23" value="2">Limitou pouco</b-form-radio>
              <b-form-radio v-model="selected23" name="some-radios-23" value="3">Limitou medianamente</b-form-radio>
              <b-form-radio v-model="selected23" name="some-radios-23" value="4">Afetou muito</b-form-radio>
              <b-form-radio v-model="selected23" name="some-radios-23" value="5">Afetou extremamente</b-form-radio>
        </b-form-group> 

        <b-button v-show="numberQuestion == 23" variant="success" @click="numberQuestion = 24">Ok</b-button>

        <b-form-group v-show="numberQuestion == 24" class="ml-5" label="Meça a gravidade dos seguintes sintomas na semana passada:">
            <b-form-group v-show="numberQuestion == 24" class="ml-5" label="24) Dor no braço, ombro ou mão">
              <b-form-radio v-model="selected24" name="some-radios-24" value="1">Nenhuma</b-form-radio>
              <b-form-radio v-model="selected24" name="some-radios-24" value="2">Pouca</b-form-radio>
              <b-form-radio v-model="selected24" name="some-radios-24" value="3">Mediana</b-form-radio>
              <b-form-radio v-model="selected24" name="some-radios-24" value="4">Muita</b-form-radio>
              <b-form-radio v-model="selected24" name="some-radios-24" value="5">Extrema</b-form-radio>
            </b-form-group>
        </b-form-group> 

        <b-button v-show="numberQuestion == 24" variant="success" @click="numberQuestion = 25">Ok</b-button>

        <b-form-group v-show="numberQuestion == 25" class="ml-5" label="25) Dor no braço, ombro ou mão quando você fazia atividades específicas">
              <b-form-radio v-model="selected25" name="some-radios-25" value="1">Nenhuma</b-form-radio>
              <b-form-radio v-model="selected25" name="some-radios-25" value="2">Pouca</b-form-radio>
              <b-form-radio v-model="selected25" name="some-radios-25" value="3">Mediana</b-form-radio>
              <b-form-radio v-model="selected25" name="some-radios-25" value="4">Muita</b-form-radio>
              <b-form-radio v-model="selected25" name="some-radios-25" value="5">Extrema</b-form-radio>
        </b-form-group> 

        <b-button v-show="numberQuestion == 25" variant="success" @click="numberQuestion = 26">Ok</b-button>

        <b-form-group v-show="numberQuestion == 26" class="ml-5" label="26) Desconforto na pele (alfinetadas) no braço, ombro ou mão">
              <b-form-radio v-model="selected26" name="some-radios-26" value="1">Nenhuma</b-form-radio>
              <b-form-radio v-model="selected26" name="some-radios-26" value="2">Pouca</b-form-radio>
              <b-form-radio v-model="selected26" name="some-radios-26" value="3">Mediana</b-form-radio>
              <b-form-radio v-model="selected26" name="some-radios-26" value="4">Muita</b-form-radio>
              <b-form-radio v-model="selected26" name="some-radios-26" value="5">Extrema</b-form-radio>
        </b-form-group> 

        <b-button v-show="numberQuestion == 26" variant="success" @click="numberQuestion = 27">Ok</b-button>
           
        <b-form-group v-show="numberQuestion == 27" class="mt-3" label="">
             <b-form-group v-show="numberQuestion == 27" class="ml-5" label="27) Fraqueza no braço, ombro ou mão">
                <b-form-radio v-model="selected27" name="some-radios-27" value="1">Nenhuma</b-form-radio>
                <b-form-radio v-model="selected27" name="some-radios-27" value="2">Pouca</b-form-radio>
                <b-form-radio v-model="selected27" name="some-radios-27" value="3">Mediana</b-form-radio>
                <b-form-radio v-model="selected27" name="some-radios-27" value="4">Muita</b-form-radio>
                <b-form-radio v-model="selected27" name="some-radios-27" value="5">Extrema</b-form-radio>
             </b-form-group> 
        </b-form-group>

        <b-button v-show="numberQuestion == 27" variant="success" @click="numberQuestion = 28">Ok</b-button>

        <b-form-group v-show="numberQuestion == 28" class="ml-5" label="28) Dificuldade em mover braço, ombro ou mão">
              <b-form-radio v-model="selected28" name="some-radios-28" value="1">Nenhuma</b-form-radio>
              <b-form-radio v-model="selected28" name="some-radios-28" value="2">Pouca</b-form-radio>
              <b-form-radio v-model="selected28" name="some-radios-28" value="3">Mediana</b-form-radio>
              <b-form-radio v-model="selected28" name="some-radios-28" value="4">Muita</b-form-radio>
              <b-form-radio v-model="selected28" name="some-radios-28" value="5">Extrema</b-form-radio>
        </b-form-group>

        <b-button v-show="numberQuestion == 28" variant="success" @click="numberQuestion = 29">Ok</b-button>

        <b-form-group v-show="numberQuestion == 29" class="ml-5" label="29) Durante a semana passada, qual a dificuldade que você teve para dormir por causa da dor no seu braço, ombro ou mão?">
              <b-form-radio v-model="selected29" name="some-radios-29" value="1">Não houve dificuldade</b-form-radio>
              <b-form-radio v-model="selected29" name="some-radios-29" value="2">Pouca dificuldade</b-form-radio>
              <b-form-radio v-model="selected29" name="some-radios-29" value="3">Média dificuldade</b-form-radio>
              <b-form-radio v-model="selected29" name="some-radios-29" value="4">Muita dificuldade</b-form-radio>
              <b-form-radio v-model="selected29" name="some-radios-29" value="5">Tão difícil que você não pôde</b-form-radio>
        </b-form-group>

        <b-button v-show="numberQuestion == 29" variant="success" @click="numberQuestion = 30">Ok</b-button>

        <b-form-group v-show="numberQuestion == 30" class="ml-5" label="30) Eu me sinto menos capaz, menos confiante e menos útil por causa do meu problema com braço, ombro ou mão">
              <b-form-radio v-model="selected30" name="some-radios-30" value="1">Discordo totalmente</b-form-radio>
              <b-form-radio v-model="selected30" name="some-radios-30" value="2">Discordo</b-form-radio>
              <b-form-radio v-model="selected30" name="some-radios-30" value="3">Não concordo nem discordo</b-form-radio>
              <b-form-radio v-model="selected30" name="some-radios-30" value="4">Concordo</b-form-radio>
              <b-form-radio v-model="selected30" name="some-radios-30" value="5">Concordo totalmente</b-form-radio>
        </b-form-group>

        <b-button v-show="numberQuestion == 30" variant="success" @click="numberQuestion = 31">Ok</b-button>
             
        <b-form-group v-show="numberQuestion == 31" class="mt-3" label="">
            <b-form-group v-show="numberQuestion == 31" class="ml-5" label="31) As questões que se seguem são a respeito do impacto causado no braço, ombro ou mão quando você toca um instrumento musical, pratica esporte ou ambos. Se você toca mais de um instrumento, pratica mais de um esporte ou ambos, por favor, responda com relação ao que é mais importante para você.">
               
               <b-form-group v-show="numberQuestion == 31" class="mt-5" label="Por favor, indique o esporte ou instrumento que é mais importante para você:">
                 <b-form-textarea id="textarea" v-model="selected31_1" name="some-radios-31_1" rows="1" max-rows="6"></b-form-textarea>
               </b-form-group>

               <b-form-group v-show="numberQuestion == 31" class="ml-5" label="Eu não toco instrumentos ou pratico esportes (você pode pular essa parte">
                 <b-form-radio v-model="selected31_2" name="some-radios-31_2" value="1">Não</b-form-radio>
               </b-form-group>

            </b-form-group>

            <b-form-group v-show="numberQuestion == 31" class="ml-5" label="Por favor circule o número que melhor descreve sua habilidade física na semana passada. Você teve alguma dificuldade para:"></b-form-group>
             <b-form-group v-show="numberQuestion == 31" class="ml-5" label="1) Uso de sua técnica habitual para tocar instrumento ou praticar esporte?">
                <b-form-radio v-model="selected31_3" name="some-radios-31_3" value="1">Fácil</b-form-radio>
                <b-form-radio v-model="selected31_3" name="some-radios-31_3" value="2">Pouco difícil</b-form-radio>
                <b-form-radio v-model="selected31_3" name="some-radios-31_3" value="3">Dificuldade média</b-form-radio>
                <b-form-radio v-model="selected31_3" name="some-radios-31_3" value="4">Muito difícil</b-form-radio>
                <b-form-radio v-model="selected31_3" name="some-radios-31_3" value="5">Não conseguiu fazer</b-form-radio>
             </b-form-group>
             <b-form-group v-show="numberQuestion == 31" class="ml-5" label="2) Tocar o instrumento ou praticar o esporte por causa de dor no braço, ombro ou mão?">
                <b-form-radio v-model="selected31_4" name="some-radios-31_4" value="1">Fácil</b-form-radio>
                <b-form-radio v-model="selected31_4" name="some-radios-31_4" value="2">Pouco difícil</b-form-radio>
                <b-form-radio v-model="selected31_4" name="some-radios-31_4" value="3">Dificuldade média</b-form-radio>
                <b-form-radio v-model="selected31_4" name="some-radios-31_4" value="4">Muito difícil</b-form-radio>
                <b-form-radio v-model="selected31_4" name="some-radios-31_4" value="5">Não conseguiu fazer</b-form-radio>
             </b-form-group>
             <b-form-group v-show="numberQuestion == 31" class="ml-5" label="3) Tocar seu instrumento ou praticar o esporte tão bem quanto você gostaria?">
                <b-form-radio v-model="selected31_5" name="some-radios-31_5" value="1">Fácil</b-form-radio>
                <b-form-radio v-model="selected31_5" name="some-radios-31_5" value="2">Pouco difícil</b-form-radio>
                <b-form-radio v-model="selected31_5" name="some-radios-31_5" value="3">Dificuldade média</b-form-radio>
                <b-form-radio v-model="selected31_5" name="some-radios-31_5" value="4">Muito difícil</b-form-radio>
                <b-form-radio v-model="selected31_5" name="some-radios-31_5" value="5">Não conseguiu fazer</b-form-radio>
             </b-form-group>

             <b-form-group v-show="numberQuestion == 31" class="ml-5" label="4) Usar a mesma quantidade de tempo tocando seu instrumento ou praticando o esporte?">
                <b-form-radio v-model="selected31_6" name="some-radios-31_6" value="1">Fácil</b-form-radio>
                <b-form-radio v-model="selected31_6" name="some-radios-31_6" value="2">Pouco difícil</b-form-radio>
                <b-form-radio v-model="selected31_6" name="some-radios-31_6" value="3">Dificuldade média</b-form-radio>
                <b-form-radio v-model="selected31_6" name="some-radios-31_6" value="4">Muito difícil</b-form-radio>
                <b-form-radio v-model="selected31_6" name="some-radios-31_6" value="5">Não conseguiu fazer</b-form-radio>
             </b-form-group>
        </b-form-group>

        <b-button v-show="numberQuestion == 31" variant="success" @click="numberQuestion = 32">Ok</b-button>
            
        <b-form-group v-show="numberQuestion == 32" class="mt-3" label="">
          <b-form-group v-show="numberQuestion == 32" class="ml-5" label="32) As questões seguintes são sobre o impacto do seu problema no braço, ombro ou mão em sua habilidade em trabalhar (incluindo tarefas domésticas se este é seu principal trabalho).">
             
             <b-form-group v-show="numberQuestion == 32" label="Por favor, indique qual é o seu trabalho:">
                <b-form-textarea id="textarea" v-model="selected32_1" name="some-radios-32_1" rows="1" max-rows="6"></b-form-textarea>
             </b-form-group>

             <b-form-group v-show="numberQuestion == 32" class="ml-5" label="Eu não trabalho (você pode pular essa parte)">
                <b-form-radio v-model="selected32_2" name="some-radios-32_2" value="1">Não</b-form-radio>
             </b-form-group>
            
            </b-form-group>

            <b-form-group v-show="numberQuestion == 32" class="ml-5" label="Por favor, circule o número que maior descreve sua habilidade física na semana passada. Você teve alguma dificuldade para:">
             <b-form-group v-show="numberQuestion == 32" class="ml-5" label="1) Uso de sua técnica habitual para seu trabalho?">
                <b-form-radio v-model="selected32_3" name="some-radios-32_3" value="1">Fácil</b-form-radio>
                <b-form-radio v-model="selected32_3" name="some-radios-32_3" value="2">Pouco difícil</b-form-radio>
                <b-form-radio v-model="selected32_3" name="some-radios-32_3" value="3">Dificuldade média</b-form-radio>
                <b-form-radio v-model="selected32_3" name="some-radios-32_3" value="4">Muito difícil</b-form-radio>
                <b-form-radio v-model="selected32_3" name="some-radios-32_3" value="5">Não conseguiu fazer</b-form-radio>
             </b-form-group>

             <b-form-group v-show="numberQuestion == 32" class="ml-5" label="2) Fazer seu trabalho usual por causa de dor em seu braço, ombro ou mão?">
                <b-form-radio v-model="selected32_4" name="some-radios-32_4" value="1">Fácil</b-form-radio>
                <b-form-radio v-model="selected32_4" name="some-radios-32_4" value="2">Pouco difícil</b-form-radio>
                <b-form-radio v-model="selected32_4" name="some-radios-32_4" value="3">Dificuldade média</b-form-radio>
                <b-form-radio v-model="selected32_4" name="some-radios-32_4" value="4">Muito difícil</b-form-radio>
                <b-form-radio v-model="selected32_4" name="some-radios-32_4" value="5">Não conseguiu fazer</b-form-radio>
             </b-form-group>

             <b-form-group v-show="numberQuestion == 32" class="ml-5" label="3) Fazer seu trabalho tão bem quanto você gostaria?">
                <b-form-radio v-model="selected32_5" name="some-radios-32_5" value="1">Fácil</b-form-radio>
                <b-form-radio v-model="selected32_5" name="some-radios-32_5" value="2">Pouco difícil</b-form-radio>
                <b-form-radio v-model="selected32_5" name="some-radios-32_5" value="3">Dificuldade média</b-form-radio>
                <b-form-radio v-model="selected32_5" name="some-radios-32_5" value="4">Muito difícil</b-form-radio>
                <b-form-radio v-model="selected32_5" name="some-radios-32_5" value="5">Não conseguiu fazer</b-form-radio>
             </b-form-group>

             <b-form-group v-show="numberQuestion == 32" class="ml-5" label="4) Usar a mesma quantidade de tempo fazendo seu trabalho?">
                <b-form-radio v-model="selected32_6" name="some-radios-32_6" value="1">Fácil</b-form-radio>
                <b-form-radio v-model="selected32_6" name="some-radios-32_6" value="2">Pouco difícil</b-form-radio>
                <b-form-radio v-model="selected32_6" name="some-radios-32_6" value="3">Dificuldade média</b-form-radio>
                <b-form-radio v-model="selected32_6" name="some-radios-32_6" value="4">Muito difícil</b-form-radio>
                <b-form-radio v-model="selected32_6" name="some-radios-32_6" value="5">Não conseguiu fazer</b-form-radio>
             </b-form-group>
            </b-form-group>
        </b-form-group>
            
      </b-card>
    </div> 
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'
import Moment from 'moment'

export default {
    name: "SatisfactionSurvey",
    components: { PageTitle },
    data() {
      return {
        patient: null,
        question: null,
        theme: null,
        numberQuestion: 1,
        selected1: null,
        selected2: null,
        selected3: null,
        selected4: null,
        selected5: null,
        selected6: null,
        selected7: null,
        selected8: null,
        selected9: null,
        selected10: null,
        selected11: null,
        selected12: null,
        selected13: null,
        selected14: null,
        selected15: null,
        selected16: null,
        selected17: null,
        selected18: null,
        selected19: null,
        selected20: null,
        selected21: null,
        selected22: null,
        selected23: null,
        selected24: null,
        selected25: null,
        selected26: null,
        selected27: null,
        selected28: null,
        selected29: null,
        selected30: null,
        selected31_1: null,
        selected31_2: null,
        selected31_3: null,
        selected31_4: null,
        selected31_5: null,
        selected31_6: null,
        selected32_1: null,
        selected32_2: null,
        selected32_3: null,
        selected32_4: null,
        selected32_5: null,
        selected32_6: null
      }
    },    
    methods: {
      sendQuestions() {
          Moment.locale('pt-br')  
          
          axios.get(`${baseApiUrl}/patient/getById/${this.patient._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`).then(res => {
            this.patient = res.data

            let data = {}
            let index = 0

            for (let q of this.patient.questions) {
              if (q.type == 2 && q.name == this.question.name && q.answers.length == 0) {
                q.answers = []

                q.status = 1
                q.answers.push(
                  {
                    'dateAnswers': Moment(new Date()).format('L')
                  }, 
                  {
                    '1': this.selected1,
                    '2': this.selected2,
                    '3': this.selected3,
                    '4': this.selected4,
                    '5': this.selected5,
                    '6': this.selected6,
                    '7': this.selected7,
                    '8': this.selected8,
                    '9': this.selected9,
                    '10': this.selected10,
                    '11': this.selected11,
                    '12': this.selected12,
                    '13': this.selected13,
                    '14': this.selected14,
                    '15': this.selected15,
                    '16': this.selected16,
                    '17': this.selected17,
                    '18': this.selected18,
                    '19': this.selected19,
                    '20': this.selected20,
                    '21': this.selected21,
                    '22': this.selected22,
                    '23': this.selected23,
                    '24': this.selected24,
                    '25': this.selected25,
                    '26': this.selected26,
                    '27': this.selected27,
                    '28': this.selected28,
                    '29': this.selected29,
                    '30': this.selected30,
                    '31_1': this.selected31_1,
                    '31_2': this.selected31_2,
                    '31_3': this.selected31_3,
                    '31_4': this.selected31_4,
                    '31_5': this.selected31_5,
                    '31_6': this.selected31_6,
                    '32_1': this.selected32_1,
                    '32_2': this.selected32_2,
                    '32_3': this.selected32_3,
                    '32_4': this.selected32_4,
                    '32_5': this.selected32_5,
                    '32_6': this.selected32_6,
                  }                                                                                                                                                                                                                                                                                                                                                                                                                                                            
                ) 

                data.index = index
                data.answers = q.answers
              }

              index += 1
            }        
            
            data.idPatient = this.patient._id

            axios.post(`${baseApiUrl}/patient/setTestQuestion/${this.patient._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`, data)
              .then(() => {
                  this.$store.commit('setResultPatient', this.patient)

                  this.$toasted.global.defaultSuccess()
                  this.$router.go(-1)
              })  
              .catch(showError) 
          }).catch((e) => {
            console.log(e)
            showError
          })
        // }
      }
    },
    created() {
      this.theme = localStorage.getItem('theme')

      this.patient = this.$route.params.questionSelected.patient
      this.question = this.$route.params.questionSelected

      if (this.$route.params.questionSelected.answers.length > 0) {
        this.selected1 = this.$route.params.questionSelected.answers[1]['1']
        this.selected2 = this.$route.params.questionSelected.answers[1]['2']
        this.selected3 = this.$route.params.questionSelected.answers[1]['3']
        this.selected4 = this.$route.params.questionSelected.answers[1]['4']
        this.selected5 = this.$route.params.questionSelected.answers[1]['5']
        this.selected6 = this.$route.params.questionSelected.answers[1]['6']
        this.selected7 = this.$route.params.questionSelected.answers[1]['7']
        this.selected8 = this.$route.params.questionSelected.answers[1]['8']
        this.selected9 = this.$route.params.questionSelected.answers[1]['9']
        this.selected10 = this.$route.params.questionSelected.answers[1]['10']
        this.selected11 = this.$route.params.questionSelected.answers[1]['11']
        this.selected12 = this.$route.params.questionSelected.answers[1]['12']
        this.selected13 = this.$route.params.questionSelected.answers[1]['13']
        this.selected14 = this.$route.params.questionSelected.answers[1]['14']
        this.selected15 = this.$route.params.questionSelected.answers[1]['15']
        this.selected16 = this.$route.params.questionSelected.answers[1]['16']
        this.selected17 = this.$route.params.questionSelected.answers[1]['17']
        this.selected18 = this.$route.params.questionSelected.answers[1]['18']
        this.selected19 = this.$route.params.questionSelected.answers[1]['19']
        this.selected20 = this.$route.params.questionSelected.answers[1]['20']  
        this.selected21 = this.$route.params.questionSelected.answers[1]['21'] 
        this.selected22 = this.$route.params.questionSelected.answers[1]['22']
        this.selected23 = this.$route.params.questionSelected.answers[1]['23']
        this.selected24 = this.$route.params.questionSelected.answers[1]['24']
        this.selected25 = this.$route.params.questionSelected.answers[1]['25']
        this.selected26 = this.$route.params.questionSelected.answers[1]['26']  
        this.selected27 = this.$route.params.questionSelected.answers[1]['27']
        this.selected28 = this.$route.params.questionSelected.answers[1]['28']
        this.selected29 = this.$route.params.questionSelected.answers[1]['29']
        this.selected30 = this.$route.params.questionSelected.answers[1]['30'] 
        this.selected31_1 = this.$route.params.questionSelected.answers[1]['31_1']
        this.selected31_2 = this.$route.params.questionSelected.answers[1]['31_2']
        this.selected31_3 = this.$route.params.questionSelected.answers[1]['31_3']
        this.selected31_4 = this.$route.params.questionSelected.answers[1]['31_4']
        this.selected31_5 = this.$route.params.questionSelected.answers[1]['31_5']
        this.selected31_6 = this.$route.params.questionSelected.answers[1]['31_6']    
        this.selected32_1 = this.$route.params.questionSelected.answers[1]['32_1']
        this.selected32_2 = this.$route.params.questionSelected.answers[1]['32_2']
        this.selected32_3 = this.$route.params.questionSelected.answers[1]['32_3']
        this.selected32_4 = this.$route.params.questionSelected.answers[1]['32_4']
        this.selected32_5 = this.$route.params.questionSelected.answers[1]['32_5']
        this.selected32_6 = this.$route.params.questionSelected.answers[1]['32_6']
      }
    }
}
</script>

<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}

.card-violet {
  background-color: #f3eaff;
}
</style>