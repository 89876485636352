<template>
    <div class="repReleased">
    <PageTitle icon="fa fa-file-text" main="Altas" sub="Relatório" />
        <div class="repReleased-page-tabs">
            <b-row>
                <b-col md="8" sm="12">
                    <b-card :class="`card-${theme}`" header="Informações" header-bg-variant="dark" header-text-variant="white">
                        <b-row>
                            <b-col md="4" sm="12">
                                <b-form-group label="Convênio:" label-for="covenant-name">
                                    <b-form-input size="sm" id="covenant-name" type="text" v-model="covenant.name" disabled/>
                                </b-form-group>
                            </b-col>   
                            <b-col md="1" sm="6" class="align-self-center mt-3">
                                <b-button size="sm" v-b-modal.modal-prevent-closing v-b-popover.hover.top="'Adiconar Convênio'">
                                    <i class="fa fa-search"></i>
                                </b-button>

                                <Lookup ref="Lookup" title="Convênios" :list="covenants" :fields="fields"
                                    :invalidFeedback="invalidFeedback" @event="addCovenant" resultTable="relAttendancesPerCovenant"></Lookup>
                            </b-col>                                                     
                            <b-col md="2" sm="12">
                                <b-form-group label="Data Inicial:" label-for="repReleased-dateInitial">
                                    <b-form-input size="sm" id="repReleased-dateInitial" type="date" v-model="dateInitial" required />
                                </b-form-group>
                            </b-col>
                            <b-col md="2" sm="12">
                                <b-form-group label="Data Final:" label-for="repReleased-dateFinal">
                                    <b-form-input size="sm" id="repReleased-dateFinal" type="date" v-model="dateFinal" required/>
                                </b-form-group>
                            </b-col>
                            <b-col md="3" sm="12">
                                <b-button size="sm" variant="success" class="mt-4" @click="getRepReleased()">Processar</b-button>
                            </b-col>
                        </b-row>
                    </b-card>                    
                </b-col>                  
            </b-row>   
        </div>
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'
import Lookup from "../default/Lookup"
import Moment from 'moment'

export default {
    name: "RepReleased",
    components: { PageTitle, Lookup },    
    data: function() {
        return {
            theme: null,
            covenant: {},
            dateInitial: null,
            dateFinal: null,
            repReleased: null,
            covenants: [],
            generatingPDF: false,
            textBtnPrint: 'GERAR',
            fields: [
                { key: "fantasyname", label: "Nome", sortable: true },
                { key: "cnpj", label: "CNPJ", sortable: true }
            ]              
        }
    },
    methods: {
        async showReportSynthetic() {
            let listTopics = []          
            
            let contentPdf = {
                title: `ALTAS ${this.covenant.name != undefined ? '(' + this.covenant.name + ')' : ''} - ${Moment(this.dateInitial).format('L')} a ${Moment(this.dateFinal).format('L')}`,
                format: 'A4',
                orientation: 'landscape',
                separate: false,
                content: [
                    {
                        type: 'table',
                        topicFields: listTopics,
                        fields: [   
                            {
                                original: 'patient',
                                exibition: 'Paciente',
                            }, 
                            {
                                original: 'cpf',
                                exibition: 'CPF',
                            }, 
                            {
                                original: 'phone1',
                                exibition: 'Fone 1',
                            }, 
                            {
                                original: 'phone2',
                                exibition: 'Fone 2',
                            }, 
                            {
                                original: 'covenant',
                                exibition: 'Convênio',
                            },
                            {
                                original: 'dateReleased',
                                exibition: 'Data da Alta',
                            },
                            {
                                original: 'attendanceNumber',
                                exibition: 'Número de Atendimento',
                            }  
                        ],
                        list: this.repReleased
                    },
                ]
            }

            const url = `${baseApiUrl}/reports/pdfGenerator/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.post(url, contentPdf, {responseType: 'arraybuffer'}).then(res => {  
            var file = new Blob([res.data], {type: 'application/pdf'})
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);

                this.generatingPDF = false
                this.textBtnPrint = 'GERAR'    
            }).catch(showError)            
        },
        async getRepReleased() {
            let valid = true

            if (this.dateInitial == null || this.dateFinal == null) {
                this.$toasted.global.defaultError({msg: 'Informe a data inicial e final.'})
                valid = false
            } 

            Moment.locale('pt-br')

            let dtInitial = Moment(this.dateInitial)
            let dtFinal = Moment(this.dateFinal)  

            let diff = dtFinal.diff(dtInitial, 'days')

            if (diff < 0) {
                this.$toasted.global.defaultError({msg: 'Data final menor que data inicial.'})
                valid = false
            } 

            if (valid) {         
                this.$bvModal.msgBoxConfirm('Deseja processar o período informado?', {
                    title: 'Confirmação',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Sim',
                    cancelTitle: 'Não',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                }).then(value => {            
                    if (value) {
                        this.generatingPDF = true
                        this.textBtnPrint = 'Aguarde...' 

                        let data = {
                            dateInitial: this.dateInitial,
                            dateFinal: this.dateFinal,
                            covenant: this.covenant._id,

                        }

                        const url = `${baseApiUrl}/admission/getRepReleased/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
                        axios.post(url, data).then(res => {
                            this.repReleased = res.data  

                            this.showReportSynthetic()
                        }).catch(showError)        
                    }
                })
            }      
        },
        loadCovenants() {
            const url = `${baseApiUrl}/covenants/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
            axios.get(url).then(res => {
                this.covenants = res.data            
            }).catch(showError)                      
        },
        addCovenant(payload) {
            this.covenant = {
                _id: payload.covenantSelected._id,
                name: payload.covenantSelected.fantasyname
            };
        },                
    },
    mounted() {
        this.theme = localStorage.getItem('theme')

        this.loadCovenants()
    }    
}
</script>

<style>

</style>