<template>
  <Loading v-if="isLoading" /> 
  <div v-else class="logScreen-pages">
    <PageTitle icon="fa fa-th-list" main="Auditoria" sub="Registro de Log" />
        <div>
            <b-row>            
                <b-col md="2" sm="12">
                    <b-form-group label="Data:" label-for="filter-date" class="mt-3">
                      <b-input size="sm" type="date" v-model="dateSelected"></b-input>
                    </b-form-group>
                </b-col>            
                <b-col md="3" sm="12">
                    <b-form-group label="Usuário:" label-for="filter-user" class="mt-3">
                      <b-form-select size="sm" :options="users" v-model="userSelected"></b-form-select>                
                    </b-form-group>
                </b-col>      
                <b-col md="2" sm="12">
                    <b-form-group label="Tabela:" label-for="filter-table" class="mt-3">
                      <b-form-select size="sm" :options="tables" v-model="tableSelected"></b-form-select>                
                    </b-form-group>
                </b-col>            
                <b-col md="3" sm="12">
                    <b-form-group label="Conteúdo:" label-for="filter-content" class="mt-3">
                      <b-input size="sm" type="text" v-model="content" id="content"></b-input>
                    </b-form-group>
                </b-col>                      
                <b-col md="2" sm="12">
                    <b-button size="sm" variant="warning" @click="filterLog()" class="mt-5">
                      CONSULTAR
                    </b-button>                      
                </b-col>                                
            </b-row>
        </div>
        <hr>    
        <b-table ref="selectableTable" striped :items="listLog" :fields="fields"  responsive="sm"
                  :per-page="perPage" :current-page="currentPage" selectable>  
            <template v-slot:cell(actions)="row">
                <b-button :variant="row.item.status == 0 ? 'warning' : 'success'" size="sm" @click="onRowSelected(row.item)"
                  v-b-popover.hover.top="row.item.status == 0 ? 'Responder' : 'Visualizar'">
                    <i :class="row.item.status == 0 ? 'fa fa-pencil' : 'fa fa-eye'"></i>
                </b-button>
            </template>
        </b-table>        

        <b-modal :id="infoModalALT.id" :title="infoModalALT.title" size="xl" ok-only>
          <b-table ref="selectableTable" head-variant="light" outlined hover :items="listContent" :fields="fieldsALT" responsive="sm" striped fixed> </b-table>    
        </b-modal>

        <b-modal :id="infoModal.id" :title="infoModal.title" size="xl" ok-only>
          <b-table ref="selectableTable" head-variant="light" outlined hover :items="listContent" :fields="fieldsLOG" responsive="sm" striped fixed> </b-table>    
        </b-modal> 

        <b-pagination size="sm" pills v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table" class="page-item">
        </b-pagination>           
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, userKey } from '@/global'
import axios from 'axios'
import Moment from 'moment'
import util from '../default/util'
import Loading from "../template/Loading"

export default {
  name: "LogScreen",
  components: { PageTitle, Loading },
  data: function() {
    return {
      theme: null,
      isLoading: false,
      users: [],
      userSelected: null,      
      dateSelected: null,
      tables: [],
      tableSelected: null,
      content: '',
      listLog: [],
      listContent: [],
      fieldsALT: [
          { key: 'campos', label: 'Campos', sortable: true },                
          { key: 'antes', label: 'Antes', sortable: true },                
          { key: 'depois', label: 'Depois', sortable: true },                
      ],
      fieldsLOG: [
          { key: 'campos', label: 'Campos', sortable: true },  
          { key: 'conteudo', label: 'Conteúdo', sortable: true },              
      ], 
      fields: [                 
          { key: 'name', label: 'Nome', sortable: true },                
          { key: 'cpf', label: 'CPF', sortable: true },
          { key: 'table', label: 'Tabela', sortable: true },
          { key: 'action', label: 'Ação', sortable: true },
          { key: 'date', label: 'Data', sortable: true },
          { key: 'hour', label: 'Hora', sortable: true },
          { key: 'actions', label: 'Ações' }
      ],
      infoModal: {
            id: 'info-modal-evolution',
            title: '',
            content: ''
      }, 
      infoModalALT: {
            id: 'info-modal-evolution-alt',
            title: '',
            content: ''
      }, 
      currentPage: 1,
      perPage: 20,             
    }
  },
  computed: {
    rows() {
      return this.listLog.length
    }
  },   
  methods: {
    loadUsers() {
      this.isLoading = true

      const url = `${baseApiUrl}/users/${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
      axios.get(url).then(res => {       
        this.users.push({ value: null,  text: '' })                        
        
        for (let r of res.data) {
          this.users.push({ value: r.cpf,  text: r.name })                        
        }

        this.isLoading = false
      }).catch(e => {
        this.isLoading = false
        this.$toasted.global.defaultError({ msg: e.response.data })
      })
    },
    loadTables() {
      this.tables.push(
        {
          value: null, text: ''
        },
        {
          value: 'admission', text: 'ADMISSÃO'
        },
        {
          value: 'speciality', text: 'AREA DE ATUAÇÃO'
        },
        {
          value: 'advice', text: 'CONSELHOS'
        },
        {
          value: 'accessProfile', text: 'CONTROLE DE ACESSO'
        },
        {
          value: 'covenants', text: 'CONVÊNIOS'
        },
        {
          value: 'scale', text: 'ESCALAS'
        },
        {
          value: 'accommodation', text: 'LOCAIS DE INTERNAÇÃO'
        },        
        {
          value: 'event', text: 'OCORRÊNCIAS'
        },
        {
          value: 'auth', text: 'LOGIN/LOGOUT'
        },        
        {
          value: 'patient', text: 'PACIENTES'
        },
        {
          value: 'professional', text: 'PROFISSIONAIS'
        },
        {
          value: 'typeService', text: 'TIPOS DE ATENDIMENTO'
        },
        {
          value: 'user', text: 'USUÁRIOS'
        },                                
        {
          value: 'scaleBase', text: 'ESCALA SEMANAL'
        },        
        {
          value: 'scaleFixed', text: 'ESCALA FIXA'
        },
        {
          value: 'scheduling', text: 'AGENDAMENTO'
        },
        {
          value: 'question', text: 'QUESTÃO'
        },
        {
          value: 'batchGuidesANS', text: 'FATURAMENTO GUIA ANS'
        },
        {
          value: 'guide', text: 'GUIAS'
        },
        {
          value: 'batch', text: 'LOTE DE GUIAS'
        }        
      )

      this.tables.sort((a,b) => { 
        if (a.text > b.text) {
          return 1;
        }

        if (a.text < b.text) {
          return -1;
        }
        
        return 0;            
      })
    },
    filterLog() {
      if (this.dateSelected == null) {
        this.$toasted.global.defaultError({msg: 'Data não informada.'})
      } else {
        this.isLoading = true

        const url = `${baseApiUrl}/log/${this.dateSelected}&${this.userSelected}&${this.tableSelected}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`
        axios.get(url).then(res => {
          this.listLog = []

          for (let r of res.data) {
            let tab = ''

            switch (r.action.table) {
              case 'patient': tab = 'PACIENTES'                
                break;
              case 'professional': tab = 'PROFISSIONAIS'
                break;          
              case 'admission': tab = 'ADMISSÃO'                
                break;          
              case 'speciality': tab = 'ÁREA DE ATUAÇÃO'
                break;
              case 'advice': tab = 'CONSELHOS'
                break;        
              case 'covenants': tab = 'CONVÊNIOS'
                break;        
              case 'accessProfile': tab = 'CONTROLE DE ACESSO'
                break;   
              case 'scale': tab = 'ESCALAS'
                break;              
              case 'accommodation': tab = 'LOCAIS DE INTERNAÇÃO'
                break;               
              case 'event': tab = 'OCORRÊNCIAS'
                break;
              case 'auth': tab = 'LOGIN/LOGOUT'
                break;      
              case 'typeService': tab = 'TIPOS DE ATENDIMENTO'
                break;            
              case 'user': tab = 'USUÁRIOS'
                break;
              case 'scaleBase': tab = 'ESCALA SEMANAL'
                break;
              case 'scaleFixed': tab = 'ESCALA FIXA'
                break;
              case 'scheduling': tab = 'AGENDAMENTO'
                break;
              case 'guides': tab = 'GUIAS ANS'
                break;
              case 'question': tab = 'QUESTÃO'
                break;
              case 'batchGuidesANS': tab = 'FATURAMENTO GUIA ANS'
                break;
              case 'guide': tab = 'GUIAS'
                break;
              case 'batch': tab = 'LOTE DE GUIAS'
                break;

              default:
                tab = ''
            }            

            let act = ''

            switch (r.action.action) {
              case 'insert': act = 'INSERIR'                
                break;
              case 'update': act = 'ALTERAR'
                break;          
              case 'get': act = 'CONSULTAR'                
                break;          
              case 'delete': act = 'REMOVER'                
                break;          
              case 'disabled': act = 'DESABILITAR'
                break;
              case 'processing': act = 'PROCESSAMENTO'
                break;        
              case 'scaleAutomatic': act = 'DISTRIBUIÇÃO AUTOMÁTICA'
                break;          
              case 'scaleProfessionalUpdate': act = 'ATUALIZAÇÃO DE PROFISSIONAL'
                break;                                
              case 'scaleProfessionalDelete': act = 'PROFISSIONAL REMOVIDO ESCALA'
                break;             
              case 'scalePatientDelete': act = 'PACIENTE REMOVIDO ESCALA'
                break;             
              case 'scalePatientInsert': act = 'PACIENTE INSERIDO ESCALA'
                break;              
              case 'scaleProfessionalInsert': act = 'PROFISSIONAL INSERIDO ESCALA'
                break;       
              case 'released': act = 'ALTA PACIENTE'                
                break;       
              case 'scaleNotation': act = 'APONTAMENTO DE ATENDIMENTO'                
                break;      
              case 'scaleEvolution': act = 'EVOLUÇÃO'                
                break;
              case 'insertWeekly': act = 'PROCESSAMENTO ESCALA'
                break;    
              case 'scaleAnamnesis': act = 'LAUDO'                
                break;   
              case 'changeOrdinationProfessionalScale': act = 'ALTERAR ORDENAÇÃO'
                break;  
              case 'newScaleScheduling': act = 'NOVO AGENDAMENTO'
                break;  
              case 'alterScaleScheduling': act = 'ALTERAR AGENDAMENTO'
                break;  
              case 'admissionXAccommodation': act = 'RELATÓRIO ADMISSÃO x LOCAL DE INTERNAÇÃO'
                break;      
              case 'admissionXApartments': act = 'RELATÓRIO ADMISSÃO x APARTAMENTOS' 
                break;
              case 'admissionNoMoviment': act = 'RELATÓRIO ADMISSÕES SEM MOVIMENTAÇÃO'
                break;   
              case 'admissionReleased': act = 'ALTA'
                break;                                       
              case 'getSatisfactionSurvey': act = 'RELATÓRIO PESQUISA DE SATISFAÇÃO'
                break;
              case 'getAttendancesDone': act = 'RELATÓRIO ATENDIMENTOS REALIZADOS'
                break;
              case 'getNumberAttendances': act = 'RELATÓRIO NÚMERO DE ATENDIMENTOS'
                break;
              case 'getNumberProfessionalsScale': act = 'RELATÓRIO CAPACIDADE DE ATENDIMENTOS'
                break;
              case 'post': act = 'POST'
                break;
              case 'pdfGeneratorAssessment': act = 'GERAR RELATÓRIO'
                break;
              case 'scaleEvents': act = 'OCORRÊNCIA ADICIONADA'
                break;       
              case 'setReleased': act = 'ALTA PACIENTE'
                break;
              case 'updateScheduling': act = 'ALTERAR PROFISSIONAL AGENDAMENTO'
                break;   
              case 'distributionScale': act = 'DISTRIBUIÇÃO DA ESCALA'
                break;
              case 'updateHourScheduling': act = 'ALTERAÇÃO HORÁRIO'
                break;                          
                                                  
              default:
                act = r.action.action.toUpperCase()
            }          
            
            Moment.locale('pt-br')

            let dt = Moment(r.date).format('L')            
            
            if (this.tableSelected != null) {
              if (this.tableSelected == r.action.table) {
                if(r.action.content.includes(this.content.toUpperCase())) {
                  this.listLog.push({
                    date: dt,
                    name: r.user.name,
                    cpf: r.user.cpf,
                    table: tab,
                    action: act,
                    hour: r.action.hour,
                    content: r.action.content
                  })
                }
              }
            } else {
              if(r.action.content.includes(this.content.toUpperCase()) || this.content == undefined || this.content == '') {
                this.listLog.push({
                  date: dt,
                  name: r.user.name,
                  cpf: r.user.cpf,
                  table: tab,
                  action: act,
                  hour: r.action.hour,
                  content: r.action.content
                })
              }
            }
           
            this.isLoading = false
          }          
        }).catch(e => {
          this.isLoading = false
          this.$toasted.global.defaultError({ msg: e.response.data })
        })        
    }
  },
  onRowSelected(item) { 
      let content = JSON.parse(item.content)
      this.listContent = []

      if(item.action == "ALTERAR") {
        
        for (const [key, value] of Object.entries(content.antes)) {
          if (!Array.isArray(value) && !Object.isExtensible(value) && key != "__v" && key != 'password' && key != 'passwordMobile') {
            this.listContent.push({
              campos: util.obterCampo(key), antes: value
            })  
          }
        }
        for (const [key, value] of Object.entries(content.depois)) {
          for(let l of this.listContent) {
            if (l.campos == util.obterCampo(key)) {
              l.depois = value
            }
          }
        }

        this.infoModalALT.title = 'Conteúdo'
        this.infoModalALT.content = JSON.stringify(item, null)
        this.$root.$emit('bv::show::modal', this.infoModalALT.id)
        
      } else {
        this.infoModal.title = 'Conteúdo'
        this.infoModal.content = JSON.stringify(item, null)
        this.$root.$emit('bv::show::modal', this.infoModal.id)

        for (const [key, value] of Object.entries(content)) {
          if (!Array.isArray(value) && !Object.isExtensible(value) && key != "__v" && key != 'password' && key != 'passwordMobile') {
            this.listContent.push({
              campos: util.obterCampo(key), conteudo: value
            })  
          }
        }
      }

      
  } 
},
mounted() {
  this.loadUsers() 
  this.loadTables() 
}
}
</script>

<style>

</style>