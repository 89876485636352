<template>
  <div class="escalaBarthel">
    <PageTitle icon="fa fa-file-text" main="Escala de Barthel" sub="" />
  
    <div>
      <b-card :class="`card-${theme}`">
        <b-form-group label="Questões:">        
          <b-button-group>
            <b-button @click="numberQuestion = 1" :variant="selected1 != null ? 'success' : 'secondary'">1</b-button>
            <b-button @click="numberQuestion = 2" :variant="selected2 != null ? 'success' : 'secondary'">2</b-button>
            <b-button @click="numberQuestion = 3" :variant="selected3 != null ? 'success' : 'secondary'">3</b-button>
            <b-button @click="numberQuestion = 4" :variant="selected4 != null ? 'success' : 'secondary'">4</b-button>
            <b-button @click="numberQuestion = 5" :variant="selected5 != null ? 'success' : 'secondary'">5</b-button>
            <b-button @click="numberQuestion = 6" :variant="selected6 != null ? 'success' : 'secondary'">6</b-button>
            <b-button @click="numberQuestion = 7" :variant="selected7 != null ? 'success' : 'secondary'">7</b-button>
            <b-button @click="numberQuestion = 8" :variant="selected8 != null ? 'success' : 'secondary'">8</b-button>
            <b-button @click="numberQuestion = 9" :variant="selected9 != null ? 'success' : 'secondary'">9</b-button>
            <b-button @click="numberQuestion = 10" :variant="selected10 != null ? 'success' : 'secondary'">10</b-button>
            <b-button @click="numberQuestion = 11" :variant="selected11 != null ? 'success' : 'secondary'">11</b-button>
          </b-button-group>
          <b-button class="ml-3" @click="sendQuestions()" variant="info">Enviar</b-button>
        </b-form-group>

        <b-form-group v-show="numberQuestion == 1" label="CATEGORIA 1: HIGIENE PESSOAL">
          <b-form-radio @change="numberQuestion = 2" v-model="selected1" name="some-radios" value="1">O paciente e incapaz de realizar higiene pessoal sendo dependente em todos os aspectos.</b-form-radio>
          <b-form-radio @change="numberQuestion = 2" v-model="selected1" name="some-radios" value="2">Paciente necessita de assistência em todos os passos da higiene pessoal.</b-form-radio>
          <b-form-radio @change="numberQuestion = 2" v-model="selected1" name="some-radios" value="3">Alguma assistência e necessária em um ou mais passos da higiene pessoal.</b-form-radio>
          <b-form-radio @change="numberQuestion = 2" v-model="selected1" name="some-radios" value="4">Paciente e capaz de conduzir a própria higiene, mas requer mínima assistência antes e/ou depois da tarefa.</b-form-radio>
          <b-form-radio @change="numberQuestion = 2" v-model="selected1" name="some-radios" value="5">Paciente pode lavar as mãos e face, limpar os dentes e barbear, pentear ou maquiar-se.</b-form-radio>
        </b-form-group>

        <b-form-group v-show="numberQuestion == 2" label="CATEGORIA 2: BANHO">
          <b-form-radio @change="numberQuestion = 3" v-model="selected2" name="some-radios" value="1">Totalmente dependente para banhar-se.</b-form-radio>
          <b-form-radio @change="numberQuestion = 3" v-model="selected2" name="some-radios" value="2">Requer assistência em todos os aspectos do banho.</b-form-radio>
          <b-form-radio @change="numberQuestion = 3" v-model="selected2" name="some-radios" value="3">Requer assistência para transferir-se, lavar-se e/ou secar-se; incluindo a inabilidade em completar a tarefa pela condição ou doença.</b-form-radio>
          <b-form-radio @change="numberQuestion = 3" v-model="selected2" name="some-radios" value="4">Requer supervisão por segurança no ajuste da temperatura da água ou na transferência.</b-form-radio>
          <b-form-radio @change="numberQuestion = 3" v-model="selected2" name="some-radios" value="5">O paciente deve ser capaz de realizar todas as etapas do banho, mesmo que necessite de equipamentos, mas não necessita que alguém esteja presente.</b-form-radio>
        </b-form-group>        

        <b-form-group v-show="numberQuestion == 3" label="CATEGORIA 3: ALIMENTAÇÃO">
          <b-form-radio @change="numberQuestion = 4" v-model="selected3" name="some-radios" value="1">Dependente em todos os aspectos e necessita ser alimentado.</b-form-radio>
          <b-form-radio @change="numberQuestion = 4" v-model="selected3" name="some-radios" value="2">Pode manipular os utensílios para comer, usualmente a colher, porém necessita de assistência constante durante a refeição.</b-form-radio>
          <b-form-radio @change="numberQuestion = 4" v-model="selected3" name="some-radios" value="3">Capaz de comer com supervisão. Requer assistência em tarefas associadas, como colocar leite e açúcar no chá, adicionar sal e pimenta, passar manteiga, virar o prato ou montar a mesa.</b-form-radio>
          <b-form-radio @change="numberQuestion = 4" v-model="selected3" name="some-radios" value="4">Independência para se alimentar um prato previamente montado, sendo a assistência necessária para, por exemplo, cortar carne, abrir uma garrafa ou um frasco. Não é necessária a presença de outra pessoa.</b-form-radio>
          <b-form-radio @change="numberQuestion = 4" v-model="selected3" name="some-radios" value="5">O paciente pode se alimentar de um prato ou bandeja quando alguém coloca os alimentos ao seu alcance. Mesmo tendo necessidade de algum equipamento de apoio, é capaz de cortar carne, serve-se de temperos, passar manteiga, etc.</b-form-radio>
        </b-form-group>     

        <b-form-group v-show="numberQuestion == 4" label="CATEGORIA 4: TOALETE">
          <b-form-radio @change="numberQuestion = 5" v-model="selected4" name="some-radios" value="1">Totalmente dependente no uso vaso sanitário.</b-form-radio>
          <b-form-radio @change="numberQuestion = 5" v-model="selected4" name="some-radios" value="2">Necessita de assistência no uso do vaso sanitário</b-form-radio>
          <b-form-radio @change="numberQuestion = 5" v-model="selected4" name="some-radios" value="3">Pode necessitar de assistência para se despir ou vestir, para transferir-se para o vaso sanitário ou para lavar as mãos.</b-form-radio>
          <b-form-radio @change="numberQuestion = 5" v-model="selected4" name="some-radios" value="4">Por razões de segurança, pode necessitar de supervisão no uso do sanitário. Um penico pode ser usado a noite, mas será necessária assistência para seu esvaziamento ou limpeza.</b-form-radio>
          <b-form-radio @change="numberQuestion = 5" v-model="selected4" name="some-radios" value="5">O paciente é capaz de se dirigir e sair do sanitário, vestir-se ou despir-se, cuida-se para não se sujar e pode utilizar papel higiênico sem necessidade de ajuda. Caso necessário, ele pode utilizar uma comadre ou penico, mas deve ser capaz de os esvaziar e limpar;</b-form-radio>
        </b-form-group>    

        <b-form-group v-show="numberQuestion == 5" label="CATEGORIA 5: SUBIR ESCADAS">
          <b-form-radio @change="numberQuestion = 6" v-model="selected5" name="some-radios" value="1">O paciente é incapaz de subir escadas.</b-form-radio>
          <b-form-radio @change="numberQuestion = 6" v-model="selected5" name="some-radios" value="2">Requer assistência em todos os aspectos relacionados a subir escadas, incluindo assistência com os dispositivos auxiliares.</b-form-radio>
          <b-form-radio @change="numberQuestion = 6" v-model="selected5" name="some-radios" value="3">O paciente é capaz de subir e descer, porém não consegue carregar os dispositivos, necessitando de supervisão e assistência.</b-form-radio>
          <b-form-radio @change="numberQuestion = 6" v-model="selected5" name="some-radios" value="4">Geralmente, não necessita de assistência. Em alguns momentos, requer supervisão por segurança.</b-form-radio>
          <b-form-radio @change="numberQuestion = 6" v-model="selected5" name="some-radios" value="5">O paciente é capaz de subir e descer, com segurança, um lance de escadas sem supervisão ou assistência mesmo quando utiliza os dispositivos.</b-form-radio>
        </b-form-group>    

        <b-form-group v-show="numberQuestion == 6" label="CATEGORIA 6: VESTUARIO">
          <b-form-radio @change="numberQuestion = 7" v-model="selected6" name="some-radios" value="1">O paciente é dependente em todos os aspectos do vestir e incapaz de participar das atividades.</b-form-radio>
          <b-form-radio @change="numberQuestion = 7" v-model="selected6" name="some-radios" value="2">O paciente é capaz de ter algum grau de participação, mas é dependente em todos os aspectos relacionados ao vestuário</b-form-radio>
          <b-form-radio @change="numberQuestion = 7" v-model="selected6" name="some-radios" value="3">Necessita assistência para se vestir ou se despir.</b-form-radio>
          <b-form-radio @change="numberQuestion = 7" v-model="selected6" name="some-radios" value="4">Necessita assistência mínima para abotoar, prender o soutien, fechar o zipper, amarrar sapatos, etc.</b-form-radio>
          <b-form-radio @change="numberQuestion = 7" v-model="selected6" name="some-radios" value="5">O paciente é capaz de vestir-se, despir-se, amarrar os sapatos, abotoar e colocar um colete ou órtese, caso eles sejam prescritos.</b-form-radio>
        </b-form-group>   

        <b-form-group v-show="numberQuestion == 7" label="CATEGORIA 7: CONTROLE ESFINCTERIANO (BEXIGA)">
          <b-form-radio @change="numberQuestion = 8" v-model="selected7" name="some-radios" value="1">O paciente apresenta incontinência urinária.</b-form-radio>
          <b-form-radio @change="numberQuestion = 8" v-model="selected7" name="some-radios" value="2">O paciente necessita de auxílio para assumir a posição apropriada e para fazer as manobras de esvaziamento.</b-form-radio>
          <b-form-radio @change="numberQuestion = 8" v-model="selected7" name="some-radios" value="3">O paciente pode assumir a posição apropriada, mas não consegue realizar as manobras de esvaziamento ou limpar-se sem assistência e tem freqüentes acidentes. Requer assistência com as fraldas e outros cuidados.</b-form-radio>
          <b-form-radio @change="numberQuestion = 8" v-model="selected7" name="some-radios" value="4">O paciente pode necessitar de supervisão com o uso do supositório e tem acidentes ocasionais.</b-form-radio>
          <b-form-radio @change="numberQuestion = 8" v-model="selected7" name="some-radios" value="5">O paciente tem controle urinário, sem acidentes. Pode usar supositório quando necessário.</b-form-radio>
        </b-form-group>  

        <b-form-group v-show="numberQuestion == 8" label="CATEGORIA 8: CONTROLE ESFINCTERIANO (INTESTINO)">
          <b-form-radio @change="numberQuestion = 9" v-model="selected8" name="some-radios" value="1">O paciente não tem controle de esfíncteres ou utiliza o cateterismo.</b-form-radio>
          <b-form-radio @change="numberQuestion = 9" v-model="selected8" name="some-radios" value="2">O paciente tem incontinência, mas é capaz de assistir na aplicação de auxílios externos ou internos.</b-form-radio>
          <b-form-radio @change="numberQuestion = 9" v-model="selected8" name="some-radios" value="3">O paciente fica geralmente seco ao dia, porém não à noite e necessita dos equipamentos para o esvaziamento.</b-form-radio>
          <b-form-radio @change="numberQuestion = 9" v-model="selected8" name="some-radios" value="4">O paciente geralmente fica seco durante o dia e a noite, porém tem acidentes ocasionais ou necessita de assistência com os equipamentos de esvaziamento.</b-form-radio>
          <b-form-radio @change="numberQuestion = 9" v-model="selected8" name="some-radios" value="5">O paciente tem controle de esfíncteres durante o dia e a noite e/ou é independente para realizar o esvaziamento.</b-form-radio>
        </b-form-group>    

        <b-form-group v-show="numberQuestion == 9" label="CATEGORIA 9: DEAMBULACAO">
          <b-form-radio @change="numberQuestion = 10" v-model="selected9" name="some-radios" value="1">Totalmente dependente para deambular.</b-form-radio>
          <b-form-radio @change="numberQuestion = 10" v-model="selected9" name="some-radios" value="2">Necessita da presença constante de uma ou mais pessoas durante a deambulação.</b-form-radio>
          <b-form-radio @change="numberQuestion = 10" v-model="selected9" name="some-radios" value="3">Requer assistência de uma pessoa para alcançar ou manipular os dispositivos auxiliares.</b-form-radio>
          <b-form-radio @change="numberQuestion = 10" v-model="selected9" name="some-radios" value="4">O paciente é independente para deambular, porém necessita de auxilio para andar 50 metros ou supervisão em situações perigosas.</b-form-radio>
          <b-form-radio @change="numberQuestion = 10" v-model="selected9" name="some-radios" value="5">O paciente é capaz de colocar os braces, assumir a posição ortostática, sentar e colocar os equipamentos na posição para o uso. O paciente pode ser capaz de usar todos os tipos de dispositivos e andar 50 metros sem auxilio ou supervisão.</b-form-radio>
        </b-form-group>       

        <b-form-group v-show="numberQuestion == 10" label="CATEGORIA 10: CADEIRA DE RODAS *">
          <b-form-radio @change="numberQuestion = 11" v-model="selected10" name="some-radios" value="1">Dependente para conduzir a cadeira de rodas.</b-form-radio>
          <b-form-radio @change="numberQuestion = 11" v-model="selected10" name="some-radios" value="2">O paciente consegue conduzi-la em pequenas distâncias ou em superfícies lisas, porém necessita de auxílio em todos os aspectos.</b-form-radio>
          <b-form-radio @change="numberQuestion = 11" v-model="selected10" name="some-radios" value="3">Necessita da presença constante de uma pessoa e requer assistência para manipular a cadeira e transferir-se.</b-form-radio>
          <b-form-radio @change="numberQuestion = 11" v-model="selected10" name="some-radios" value="4">O paciente consegue conduzir a cadeira por um tempo razoável e em solos regulares. Requer mínima assistência em espaços apertados.</b-form-radio>
          <b-form-radio @change="numberQuestion = 11" v-model="selected10" name="some-radios" value="5">Paciente é independente em todas as etapas relacionadas a cadeira de rodas (manipulação de equipamentos, condução por longos percursos e transferências).</b-form-radio>
        </b-form-group>         

        <b-form-group v-show="numberQuestion == 11" label="CATEGORIA 10: TRANSFERENCIAS CADEIRA/CAMA">
          <b-form-radio @change="numberQuestion = 11" v-model="selected11" name="some-radios" value="1">Incapaz de participar da transferência. São necessárias duas pessoas para transferir o paciente com ou sem auxílio mecânico. </b-form-radio>
          <b-form-radio @change="numberQuestion = 11" v-model="selected11" name="some-radios" value="2">Capaz de participar, porém necessita de máxima assistência de outra pessoa em todos os aspectos da transferência.</b-form-radio>
          <b-form-radio @change="numberQuestion = 11" v-model="selected11" name="some-radios" value="3">Requer assistência de oura pessoa para transferir-se.</b-form-radio>
          <b-form-radio @change="numberQuestion = 11" v-model="selected11" name="some-radios" value="4">Requer a presença de outra pessoa, supervisionando, como medida de segurança.</b-form-radio>
          <b-form-radio @change="numberQuestion = 11" v-model="selected11" name="some-radios" value="5">O paciente pode, com segurança, aproximar-se da cama com a cadeira de rodas, freiar, retirar o apoio dos pés, mover-se para a cama, deitar, sentar ao lado da cama, mudar a cadeira de rodas de posição, e voltar novamente para cadeia com segurança. O paciente deve ser independente em todas as fases da transferência.</b-form-radio>
        </b-form-group>                                                                                                          
      </b-card>
    </div> 
  </div>
</template>

<script>
import PageTitle from "../template/PageTitle"

export default {
    name: "SF36",
    components: { PageTitle },
    data() {
      return {
        slide: 1,
        sliding: null,
        theme: null,
        numberQuestion: 1,
        selected1: null,
        selected2: null,
        selected3: null,
        selected4: null,
        selected5: null,
        selected6: null,
        selected7: null,
        selected8: null,
        selected9: null,
        selected10: null,
        selected11: null,                      
      }
    },    
    methods: {
      onRowSelected(itemSelected, item) {
        console.log(itemSelected.atividade)
        console.log(item)
      },
      sendQuestions() {

      }
    },
    mounted() {
      this.theme = localStorage.getItem('theme')
    }
}
</script>

<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}

.card-violet {
  background-color: #f3eaff;
}
</style>