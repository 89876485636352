<template>
  <div class="assessmentTrauma">
    <PageTitle icon="fa fa-file-text" main="Ficha de Avaliação" sub="Fisioterapia Traumato Funcional" />
  
    <div>
      <b-card :class="`card-${theme}`">
        <b-button @click="sendQuestions()" variant="info">Enviar</b-button>

        <b-row class="mt-3 ml-1">
          <b-button-group>
            <b-button @click="numberQuestion = 1" :variant="(selected1_1 != null || 
                                                             selected1_2 != null ||
                                                             selected1_3 != null ||
                                                             selected1_4 != null ||
                                                             selected1_5 != null ||
                                                             selected1_6 != null ||
                                                             selected1_7 != null) ? 'success' : 'secondary'">1</b-button>
            <b-button @click="numberQuestion = 2" :variant="(selected2_1 != null || 
                                                             selected2_2 != null ||
                                                             selected2_3 != null ||
                                                             selected2_4 != null ||
                                                             selected2_5 != null ||
                                                             selected2_6 != null ||
                                                             selected2_7 != null ||
                                                             selected2_8 != null ||
                                                             selected2_9 != null ||
                                                             selected2_10 != null ||
                                                             selected2_11 != null ||
                                                             selected2_12 != null ||
                                                             selected2_13 != false ||
                                                             selected2_14 != false ||
                                                             selected2_15 != false ||
                                                             selected2_16 != false ||
                                                             selected2_17 != false ||
                                                             selected2_18 != false ||
                                                             selected2_19 != false ||
                                                             selected2_20 != null) ? 'success' : 'secondary'">2</b-button>
            <b-button @click="numberQuestion = 3" :variant="(selected3_1 != null || 
                                                             selected3_2 != null ||
                                                             selected3_3 != null ||
                                                             selected3_4 != null ||
                                                             selected3_5 != null ||
                                                             selected3_6 != null ||
                                                             selected3_7 != null ||
                                                             selected3_8 != null ||
                                                             selected3_9 != null ||
                                                             selected3_10 != null ||
                                                             selected3_11 != null || 
                                                             selected3_12 != null ||
                                                             selected3_13 != null ||
                                                             selected3_14 != null ||
                                                             selected3_15 != null ||
                                                             selected3_16 != null ||
                                                             selected3_17 != null ||
                                                             selected3_18 != null ||
                                                             selected3_19 != null ||
                                                             selected3_20 != null ||
                                                             selected3_21 != null || 
                                                             selected3_22 != null ||
                                                             selected3_23 != null ||
                                                             selected3_24 != null ||
                                                             selected3_25 != null ||
                                                             selected3_26 != null ||
                                                             selected3_27 != null ||
                                                             selected3_28 != null ||
                                                             selected3_29 != null ||
                                                             selected3_30 != null) ? 'success' : 'secondary'">3</b-button>
            <b-button @click="numberQuestion = 4" :variant="(selected4_1 != null || 
                                                             selected4_2 != null ||
                                                             selected4_3 != null ||
                                                             selected4_4 != null) ? 'success' : 'secondary'">4</b-button>
            <b-button @click="numberQuestion = 5" :variant="(selected5_1 != null || 
                                                             selected5_2 != null ||
                                                             selected5_3 != null ||
                                                             selected5_4 != null ||
                                                             selected5_5 != null ||
                                                             selected5_6 != null ||
                                                             selected5_7 != null ||
                                                             selected5_8 != null ||
                                                             selected5_9 != null ||
                                                             selected5_10 != null ||
                                                             selected5_11 != null || 
                                                             selected5_12 != null ||
                                                             selected5_13 != null ||
                                                             selected5_14 != null ||
                                                             selected5_15 != null ||
                                                             selected5_16 != null ||
                                                             selected5_17 != null ||
                                                             selected5_18 != null ||
                                                             selected5_19 != null ||
                                                             selected5_20 != null ||
                                                             selected5_21 != null || 
                                                             selected5_22 != null ||
                                                             selected5_23 != null ||
                                                             selected5_24 != null ||
                                                             selected5_25 != null ||
                                                             selected5_26 != null ||
                                                             selected5_27 != null ||
                                                             selected5_28 != null ||
                                                             selected5_29 != null ||
                                                             selected5_30 != null ||
                                                             selected5_31 != null ||
                                                             selected5_32 != null ||
                                                             selected5_33 != null ||
                                                             selected5_34 != null ||
                                                             selected5_35 != null ||
                                                             selected5_36 != null ||
                                                             selected5_37 != null ||
                                                             selected5_38 != null) ? 'success' : 'secondary'">5</b-button>   
            <b-button @click="numberQuestion = 6" :variant="(selected6_1 != null || 
                                                             selected6_2 != null ||
                                                             selected6_3 != null ||
                                                             selected6_4 != null ||
                                                             selected6_5 != null ||
                                                             selected6_6 != null ||
                                                             selected6_7 != null ||
                                                             selected6_8 != null ||
                                                             selected6_9 != null ||
                                                             selected6_10 != null ||
                                                             selected6_11 != null || 
                                                             selected6_12 != null ||
                                                             selected6_13 != null ||
                                                             selected6_14 != null ||
                                                             selected6_15 != null ||
                                                             selected6_16 != null ||
                                                             selected6_17 != null ||
                                                             selected6_18 != null ||
                                                             selected6_19 != null ||
                                                             selected6_20 != null ||
                                                             selected6_21 != null || 
                                                             selected6_22 != null ||
                                                             selected6_23 != null ||
                                                             selected6_24 != null ||
                                                             selected6_25 != null ||
                                                             selected6_26 != null ||
                                                             selected6_27 != null ||
                                                             selected6_28 != null ||
                                                             selected6_29 != null) ? 'success' : 'secondary'">6</b-button> 
            <b-button @click="numberQuestion = 7" :variant="(selected7_1 != null || 
                                                             selected7_2 != null ||
                                                             selected7_3 != null ||
                                                             selected7_4 != null ||
                                                             selected7_5 != null ||
                                                             selected7_6 != null ||
                                                             selected7_7 != null ||
                                                             selected7_8 != null ||
                                                             selected7_9 != null ||
                                                             selected7_10 != null ||
                                                             selected7_11 != null || 
                                                             selected7_12 != null ||
                                                             selected7_13 != null ||
                                                             selected7_14) ? 'success' : 'secondary'">7</b-button> 
            <b-button @click="numberQuestion = 8" :variant="(selected8_1 != null || 
                                                             selected8_2 != null ||
                                                             selected8_3 != null ||
                                                             selected8_4 != null ||
                                                             selected8_5 != null ||
                                                             selected8_6 != null ||
                                                             selected8_7 != null ||
                                                             selected8_8 != null ||
                                                             selected8_9 != null ||
                                                             selected8_10 != null ||
                                                             selected8_11 != null || 
                                                             selected8_12 != null ||
                                                             selected8_13 != null ||
                                                             selected8_14) ? 'success' : 'secondary'">8</b-button> 
            <b-button @click="numberQuestion = 9" :variant="(selected9_1 != null || 
                                                             selected9_2 != null ||
                                                             selected9_3 != null ||
                                                             selected9_4 != null ||
                                                             selected9_5 != null ||
                                                             selected9_6 != null) ? 'success' : 'secondary'">9</b-button>   
            <b-button @click="numberQuestion = 10" :variant="(selected10_1 != null || 
                                                              selected10_2 != null ||
                                                              selected10_3 != null ||
                                                              selected10_4 != null) ? 'success' : 'secondary'">10</b-button>    
            <b-button @click="numberQuestion = 11" :variant="(selected11_1 != null || 
                                                              selected11_2 != null ||
                                                              selected11_3 != null ||
                                                              selected11_4 != null ||
                                                              selected11_5 != null ||
                                                              selected11_6 != null ||
                                                              selected11_7 != null ||
                                                              selected11_8 != null ||
                                                              selected11_9 != null ||
                                                              selected11_10 != null ||
                                                              selected11_11 != null || 
                                                              selected11_12 != null ||
                                                              selected11_13 != null ||
                                                              selected11_14 != null ||
                                                              selected11_15 != null ||
                                                              selected11_16 != null ||
                                                              selected11_17 != null ||
                                                              selected11_18 != null ||
                                                              selected11_19 != null ||
                                                              selected11_20 != null ||
                                                              selected11_21 != null || 
                                                              selected11_22 != null ||
                                                              selected11_23 != null ||
                                                              selected11_24 != null ||
                                                              selected11_25 != null ||
                                                              selected11_26 != null ||
                                                              selected11_27 != null ||
                                                              selected11_28 != null ||
                                                              selected11_29 != null ||
                                                              selected11_30 != null) ? 'success' : 'secondary'">11</b-button>  
            <b-button @click="numberQuestion = 12" :variant="(selected12_1 != null || 
                                                              selected12_2 != null ||
                                                              selected12_3 != null ||
                                                              selected12_4 != null ||
                                                              selected12_5 != null ||
                                                              selected12_6 != null ||
                                                              selected12_7 != null ||
                                                              selected12_8 != null ||
                                                              selected12_9 != null ||
                                                              selected12_10 != null ||
                                                              selected12_11 != null || 
                                                              selected12_12 != null) ? 'success' : 'secondary'">12</b-button>   
            <b-button @click="numberQuestion = 13" :variant="(selected13_1 != null || 
                                                              selected13_2 != null ||
                                                              selected13_3 != null ||
                                                              selected13_4 != null ||
                                                              selected13_5 != null ||
                                                              selected13_6 != null ||
                                                              selected13_7 != null ||
                                                              selected13_8 != null ||
                                                              selected13_9 != null) ? 'success' : 'secondary'">13</b-button>
            <b-button @click="numberQuestion = 14" :variant="(selected14_1 != null || 
                                                              selected14_2 != null ||
                                                              selected14_3 != null ||
                                                              selected14_4 != null) ? 'success' : 'secondary'">14</b-button> 
            <b-button @click="numberQuestion = 15" :variant="(selected15_1 != null || 
                                                              selected15_2 != null ||
                                                              selected15_3 != null ||
                                                              selected15_4 != null ||
                                                              selected15_5 != null ||
                                                              selected15_6 != null ||
                                                              selected15_7 != null ||
                                                              selected15_8 != null ||
                                                              selected15_9 != null ||
                                                              selected15_10 != null ||
                                                              selected15_11 != null || 
                                                              selected15_12 != null ||
                                                              selected15_13 != null ||
                                                              selected15_14 != null ||
                                                              selected15_15 != null ||
                                                              selected15_16 != null ||
                                                              selected15_17 != null ||
                                                              selected15_18 != null) ? 'success' : 'secondary'">15</b-button>    
            <b-button @click="numberQuestion = 16" :variant="(selected16_1 != null || 
                                                              selected16_2 != null ||
                                                              selected16_3 != null ||
                                                              selected16_4 != null ||
                                                              selected16_5 != null ||
                                                              selected16_6 != null ||
                                                              selected16_7 != null ||
                                                              selected16_8 != null ||
                                                              selected16_9 != null ||
                                                              selected16_10 != null ||
                                                              selected16_11 != null) ? 'success' : 'secondary'">16</b-button>
            <b-button class="ml-2" @click="numberQuestion = 17" variant="primary">Questionários</b-button>
          </b-button-group>
        </b-row>

        <b-form-group v-show="numberQuestion == 1" class="mt-3" label="Dados Pessoais">
            <b-row> 
                <b-col md="9" sm="12">   
                    <b-form-group v-show="numberQuestion == 1" label="Nome:">
                        <b-form-input type="text" v-model="selected1_1" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="3" sm="12">   
                    <b-form-group v-show="numberQuestion == 1" label="Data Nascimento:">
                        <b-form-input type="date" v-model="selected1_2" />                    
                    </b-form-group>                
                </b-col>
            </b-row>

            <b-row> 
                <b-col md="1" sm="12">   
                    <b-form-group v-show="numberQuestion == 1" label="Sexo:">
                        <b-form-radio v-model="selected1_3" name="some-radios-1_3" value="0">M</b-form-radio>
                        <b-form-radio v-model="selected1_3" name="some-radios-1_3" value="1">F</b-form-radio>                    
                    </b-form-group>                
                </b-col>                                     
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 1" label="Estado Civil:">
                        <b-form-radio v-model="selected1_4" name="some-radios-1_4" value="0">Solteiro</b-form-radio>
                        <b-form-radio v-model="selected1_4" name="some-radios-1_4" value="1">Casado</b-form-radio>  
                        <b-form-radio v-model="selected1_4" name="some-radios-1_4" value="2">Divorciado</b-form-radio>
                        <b-form-radio v-model="selected1_4" name="some-radios-1_4" value="3">Viúvo</b-form-radio>
                    </b-form-group>
                </b-col>      
                <b-col md="3" sm="12">      
                    <b-form-group v-show="numberQuestion == 1" label="Telefone:">
                        <b-form-input type="text" v-model="selected1_5" />                    
                    </b-form-group>                                
                </b-col>       
                <b-col md="3" sm="12">      
                    <b-form-group v-show="numberQuestion == 1" label="Bairro:">
                        <b-form-input type="text" v-model="selected1_6" />                    
                    </b-form-group>                                
                </b-col>
                <b-col md="3" sm="12">      
                    <b-form-group v-show="numberQuestion == 1" label="Cidade:">
                        <b-form-input type="text" v-model="selected1_7" />                    
                    </b-form-group>                                
                </b-col>                                                
            </b-row>   
        </b-form-group>     

        <b-button v-show="numberQuestion == 1" variant="success" @click="numberQuestion = 2">Ok</b-button> 


        <b-form-group v-show="numberQuestion == 2" class="mt-3" label="Anamnese">
            <b-row> 
                <b-col md="6" sm="12">   
                    <b-form-group v-show="numberQuestion == 2" label="Médico Acompanhante:">
                        <b-form-input type="text" v-model="selected2_1" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 2" label="Peso:">
                        <b-form-input type="text" v-model="selected2_2" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 2" label="Altura:">
                        <b-form-input type="text" v-model="selected2_3" />                    
                    </b-form-group>                
                </b-col>     
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 2" label="IMC:">
                        <b-form-input type="text" v-model="selected2_4" />                    
                    </b-form-group>                
                </b-col>                             
            </b-row>

            <b-row> 
                <b-col md="3" sm="12">   
                    <b-form-group v-show="numberQuestion == 2" label="PA:">
                        <b-form-input type="text" v-model="selected2_5" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="3" sm="12">   
                    <b-form-group v-show="numberQuestion == 2" label="FC:">
                        <b-form-input type="text" v-model="selected2_6" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="3" sm="12">   
                    <b-form-group v-show="numberQuestion == 2" label="FR:">
                        <b-form-input type="text" v-model="selected2_7" />                    
                    </b-form-group>                
                </b-col>     
                <b-col md="3" sm="12">   
                    <b-form-group v-show="numberQuestion == 2" label="SpO2:">
                        <b-form-input type="text" v-model="selected2_8" />                    
                    </b-form-group>                
                </b-col>                             
            </b-row>

            <b-row> 
                <b-col md="12" sm="12">
                    <b-form-group v-show="numberQuestion == 2" label="Auscuta Pulmonar:">
                        <b-form-input type="text" v-model="selected2_9" />                    
                    </b-form-group>                      
                </b-col>
            </b-row>       

            <b-row> 
                <b-col md="12" sm="12">
                    <b-form-group v-show="numberQuestion == 2" label="HD:">
                        <b-form-textarea id="textarea" v-model="selected2_10" rows="1" max-rows="6"></b-form-textarea>                    
                    </b-form-group>                      
                </b-col>
            </b-row>        

            <b-row>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 2" label="Tabagismo Ativo:">
                        <b-form-radio v-model="selected2_11" name="some-radios-2_11" value="0">Sim</b-form-radio>
                        <b-form-radio v-model="selected2_11" name="some-radios-2_11" value="1">Não</b-form-radio>  
                    </b-form-group>
                </b-col>
                <b-col md="3" sm="12">   
                    <b-form-group v-show="numberQuestion == 2" label="Consumo de Álcool Ativo:">
                        <b-form-radio v-model="selected2_12" name="some-radios-2_12" value="0">Sim</b-form-radio>
                        <b-form-radio v-model="selected2_12" name="some-radios-2_12" value="1">Não</b-form-radio>  
                    </b-form-group>
                </b-col>                
            </b-row>       

            <b-form-group v-show="numberQuestion == 2" class="mt-3" label="Comorbidades:">
                <b-row> 
                    <b-col>
                        <b-form-checkbox id="checkbox-2_13" v-model="selected2_13" name="checkbox-2_13" :value="true" :unchecked-value="false">HAS</b-form-checkbox>
                        <b-form-checkbox id="checkbox-2_14" v-model="selected2_14" name="checkbox-2_14" :value="true" :unchecked-value="false">DMI</b-form-checkbox>
                        <b-form-checkbox id="checkbox-2_15" v-model="selected2_15" name="checkbox-2_15" :value="true" :unchecked-value="false">DMII</b-form-checkbox>
                        <b-form-checkbox id="checkbox-2_16" v-model="selected2_16" name="checkbox-2_16" :value="true" :unchecked-value="false">ICC</b-form-checkbox>
                        <b-form-checkbox id="checkbox-2_17" v-model="selected2_17" name="checkbox-2_17" :value="true" :unchecked-value="false">DRC</b-form-checkbox>
                        <b-form-checkbox id="checkbox-2_18" v-model="selected2_18" name="checkbox-2_18" :value="true" :unchecked-value="false">DPOC</b-form-checkbox>   
                        <b-form-checkbox id="checkbox-2_19" v-model="selected2_19" name="checkbox-2_19" :value="true" :unchecked-value="false">ASMA</b-form-checkbox>
                    </b-col>
                </b-row>
            </b-form-group>

            <b-row> 
                <b-col md="12" sm="12">
                    <b-form-group v-show="numberQuestion == 2" label="Histórico Familiar:">
                        <b-form-textarea id="textarea" v-model="selected2_20" rows="1" max-rows="6"></b-form-textarea>                    
                    </b-form-group>                      
                </b-col>
            </b-row>               
        </b-form-group>     

        <b-button v-show="numberQuestion == 2" variant="success" @click="numberQuestion = 3">Ok</b-button> 


        <b-form-group v-show="numberQuestion == 3" class="mt-3" label="Medições Em Uso">
            <b-row> 
                <b-col md="4" sm="12">   
                    <b-form-group v-show="numberQuestion == 3" label="Nome:">
                        <b-form-input type="text" v-model="selected3_1" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="4" sm="12">   
                    <b-form-group v-show="numberQuestion == 3" label="Dosagem:">
                        <b-form-input type="text" v-model="selected3_2" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="4" sm="12">   
                    <b-form-group v-show="numberQuestion == 3" label="Posologia:">
                        <b-form-input type="text" v-model="selected3_3" />                    
                    </b-form-group>                
                </b-col>                               
            </b-row>    
            <b-row> 
                <b-col md="4" sm="12">   
                    <b-form-group v-show="numberQuestion == 3">
                        <b-form-input type="text" v-model="selected3_4" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="4" sm="12">   
                    <b-form-group v-show="numberQuestion == 3">
                        <b-form-input type="text" v-model="selected3_5" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="4" sm="12">   
                    <b-form-group v-show="numberQuestion == 3">
                        <b-form-input type="text" v-model="selected3_6" />                    
                    </b-form-group>                
                </b-col>                               
            </b-row>   
            <b-row> 
                <b-col md="4" sm="12">   
                    <b-form-group v-show="numberQuestion == 3">
                        <b-form-input type="text" v-model="selected3_7" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="4" sm="12">   
                    <b-form-group v-show="numberQuestion == 3">
                        <b-form-input type="text" v-model="selected3_8" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="4" sm="12">   
                    <b-form-group v-show="numberQuestion == 3">
                        <b-form-input type="text" v-model="selected3_9" />                    
                    </b-form-group>                
                </b-col>                               
            </b-row>   
            <b-row> 
                <b-col md="4" sm="12">   
                    <b-form-group v-show="numberQuestion == 3">
                        <b-form-input type="text" v-model="selected3_10" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="4" sm="12">   
                    <b-form-group v-show="numberQuestion == 3">
                        <b-form-input type="text" v-model="selected3_11" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="4" sm="12">   
                    <b-form-group v-show="numberQuestion == 3">
                        <b-form-input type="text" v-model="selected3_12" />                    
                    </b-form-group>                
                </b-col>                               
            </b-row> 
            <b-row> 
                <b-col md="4" sm="12">   
                    <b-form-group v-show="numberQuestion == 3">
                        <b-form-input type="text" v-model="selected3_13" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="4" sm="12">   
                    <b-form-group v-show="numberQuestion == 3">
                        <b-form-input type="text" v-model="selected3_14" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="4" sm="12">   
                    <b-form-group v-show="numberQuestion == 3">
                        <b-form-input type="text" v-model="selected3_15" />                    
                    </b-form-group>                
                </b-col>                               
            </b-row> 
            <b-row> 
                <b-col md="4" sm="12">   
                    <b-form-group v-show="numberQuestion == 3">
                        <b-form-input type="text" v-model="selected3_16" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="4" sm="12">   
                    <b-form-group v-show="numberQuestion == 3">
                        <b-form-input type="text" v-model="selected3_17" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="4" sm="12">   
                    <b-form-group v-show="numberQuestion == 3">
                        <b-form-input type="text" v-model="selected3_18" />                    
                    </b-form-group>                
                </b-col>                               
            </b-row> 
            <b-row> 
                <b-col md="4" sm="12">   
                    <b-form-group v-show="numberQuestion == 3">
                        <b-form-input type="text" v-model="selected3_19" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="4" sm="12">   
                    <b-form-group v-show="numberQuestion == 3">
                        <b-form-input type="text" v-model="selected3_20" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="4" sm="12">   
                    <b-form-group v-show="numberQuestion == 3">
                        <b-form-input type="text" v-model="selected3_21" />                    
                    </b-form-group>                
                </b-col>                               
            </b-row> 
            <b-row> 
                <b-col md="4" sm="12">   
                    <b-form-group v-show="numberQuestion == 3">
                        <b-form-input type="text" v-model="selected3_22" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="4" sm="12">   
                    <b-form-group v-show="numberQuestion == 3">
                        <b-form-input type="text" v-model="selected3_23" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="4" sm="12">   
                    <b-form-group v-show="numberQuestion == 3">
                        <b-form-input type="text" v-model="selected3_24" />                    
                    </b-form-group>                
                </b-col>                               
            </b-row> 
            <b-row> 
                <b-col md="4" sm="12">   
                    <b-form-group v-show="numberQuestion == 3">
                        <b-form-input type="text" v-model="selected3_25" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="4" sm="12">   
                    <b-form-group v-show="numberQuestion == 3">
                        <b-form-input type="text" v-model="selected3_26" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="4" sm="12">   
                    <b-form-group v-show="numberQuestion == 3">
                        <b-form-input type="text" v-model="selected3_27" />                    
                    </b-form-group>                
                </b-col>                               
            </b-row> 
            <b-row> 
                <b-col md="4" sm="12">   
                    <b-form-group v-show="numberQuestion == 3">
                        <b-form-input type="text" v-model="selected3_28" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="4" sm="12">   
                    <b-form-group v-show="numberQuestion == 3">
                        <b-form-input type="text" v-model="selected3_29" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="4" sm="12">   
                    <b-form-group v-show="numberQuestion == 3">
                        <b-form-input type="text" v-model="selected3_30" />                    
                    </b-form-group>                
                </b-col>                               
            </b-row>                                                                                                                 
        </b-form-group>     

        <b-button v-show="numberQuestion == 3" variant="success" @click="numberQuestion = 4">Ok</b-button>


        <b-form-group v-show="numberQuestion == 4" class="mt-3" label="História de Internamento Prévio">
            <b-row> 
                <b-col md="3" sm="12">   
                    <b-form-group v-show="numberQuestion == 4" label="Teve Internamento Recente:">
                        <b-form-radio v-model="selected4_1" name="some-radios-4_1" value="0">Sim</b-form-radio>
                        <b-form-radio v-model="selected4_1" name="some-radios-4_1" value="1">Não</b-form-radio>                    
                    </b-form-group>                
                </b-col>  
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 4" label="Disfunção Orgânica:">
                        <b-form-radio v-model="selected4_2" name="some-radios-4_2" value="0">Sim</b-form-radio>
                        <b-form-radio v-model="selected4_2" name="some-radios-4_2" value="1">Não</b-form-radio>                    
                    </b-form-group>                
                </b-col>  
            </b-row>

            <b-row> 
                <b-col md="12" sm="12">      
                    <b-form-group v-show="numberQuestion == 4" label="Tempo de Internação:">
                        <b-form-input type="text" v-model="selected4_3" />                    
                    </b-form-group>                                
                </b-col>                                            
            </b-row>   

            <b-row> 
                <b-col md="12" sm="12">
                    <b-form-group v-show="numberQuestion == 4" label="Obs:">
                        <b-form-textarea id="textarea" v-model="selected4_4" rows="1" max-rows="6"></b-form-textarea>                    
                    </b-form-group>                      
                </b-col>
            </b-row>               
        </b-form-group>     

        <b-button v-show="numberQuestion == 4" variant="success" @click="numberQuestion = 5">Ok</b-button>        


        <b-form-group v-show="numberQuestion == 5" class="mt-3" label="Índice de Barthel">
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5" label="Item:">                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5" label="Prévio (Há 3 Meses):">                 
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5" label="Pré Reab:">
                        Data: <b-form-input type="date" v-model="selected5_1" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5" label="Pós Reab:">
                        Data: <b-form-input type="date" v-model="selected5_2" />                    
                    </b-form-group>                
                </b-col>                               
            </b-row>                
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5" label="Alimentação">                    
                    </b-form-group>                
                </b-col>                
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5">
                        <b-form-input type="text" v-model="selected5_3" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5">
                        <b-form-input type="text" v-model="selected5_4" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5">
                        <b-form-input type="text" v-model="selected5_5" />                    
                    </b-form-group>                
                </b-col>                               
            </b-row>               
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5" label="Higiene Pessoal">                    
                    </b-form-group>                
                </b-col>                
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5">
                        <b-form-input type="text" v-model="selected5_6" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5">
                        <b-form-input type="text" v-model="selected5_7" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5">
                        <b-form-input type="text" v-model="selected5_8" />                    
                    </b-form-group>                
                </b-col>                               
            </b-row>               
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5" label="Uso do Banheiro">                    
                    </b-form-group>                
                </b-col>                
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5">
                        <b-form-input type="text" v-model="selected5_9" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5">
                        <b-form-input type="text" v-model="selected5_10" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5">
                        <b-form-input type="text" v-model="selected5_11" />                    
                    </b-form-group>                
                </b-col>                               
            </b-row>             
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5" label="Banho">                    
                    </b-form-group>                
                </b-col>                
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5">
                        <b-form-input type="text" v-model="selected5_12" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5">
                        <b-form-input type="text" v-model="selected5_13" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5">
                        <b-form-input type="text" v-model="selected5_14" />                    
                    </b-form-group>                
                </b-col>                               
            </b-row>             
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5" label="Continência do Esfíncter Anal">                    
                    </b-form-group>                
                </b-col>                
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5">
                        <b-form-input type="text" v-model="selected5_15" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5">
                        <b-form-input type="text" v-model="selected5_16" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5">
                        <b-form-input type="text" v-model="selected5_17" />                    
                    </b-form-group>                
                </b-col>                               
            </b-row>             
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5" label="Continência do Esfíncter Vesical">                    
                    </b-form-group>                
                </b-col>                
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5">
                        <b-form-input type="text" v-model="selected5_18" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5">
                        <b-form-input type="text" v-model="selected5_19" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5">
                        <b-form-input type="text" v-model="selected5_20" />                    
                    </b-form-group>                
                </b-col>                               
            </b-row>             
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5" label="Vestir-se">                    
                    </b-form-group>                
                </b-col>                
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5">
                        <b-form-input type="text" v-model="selected5_21" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5">
                        <b-form-input type="text" v-model="selected5_22" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5">
                        <b-form-input type="text" v-model="selected5_23" />                    
                    </b-form-group>                
                </b-col>                               
            </b-row>             
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5" label="Transferências">                    
                    </b-form-group>                
                </b-col>                
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5">
                        <b-form-input type="text" v-model="selected5_24" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5">
                        <b-form-input type="text" v-model="selected5_25" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5">
                        <b-form-input type="text" v-model="selected5_26" />                    
                    </b-form-group>                
                </b-col>                               
            </b-row>             
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5" label="Subir e Descer Escadas">                    
                    </b-form-group>                
                </b-col>                
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5">
                        <b-form-input type="text" v-model="selected5_27" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5">
                        <b-form-input type="text" v-model="selected5_28" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5">
                        <b-form-input type="text" v-model="selected5_29" />                    
                    </b-form-group>                
                </b-col>                               
            </b-row>       
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5" label="Deambulação">                    
                    </b-form-group>                
                </b-col>                
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5">
                        <b-form-input type="text" v-model="selected5_30" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5">
                        <b-form-input type="text" v-model="selected5_31" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5">
                        <b-form-input type="text" v-model="selected5_32" />                    
                    </b-form-group>                
                </b-col>                               
            </b-row>   
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5" label="Manuseio da Cadeira de Rodas">                    
                    </b-form-group>                
                </b-col>                
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5">
                        <b-form-input type="text" v-model="selected5_33" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5">
                        <b-form-input type="text" v-model="selected5_34" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5">
                        <b-form-input type="text" v-model="selected5_35" />                    
                    </b-form-group>                
                </b-col>                               
            </b-row>     
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5" label="Total">                    
                    </b-form-group>                
                </b-col>                
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5">
                        <b-form-input type="text" v-model="selected5_36" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5">
                        <b-form-input type="text" v-model="selected5_37" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 5">
                        <b-form-input type="text" v-model="selected5_38" />                    
                    </b-form-group>                
                </b-col>                               
            </b-row>                                                                                                                                         
        </b-form-group>     

        <b-button v-show="numberQuestion == 5" variant="success" @click="numberQuestion = 6">Ok</b-button>      


        <b-form-group v-show="numberQuestion == 6" class="mt-3" label="Short Form-36">
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6" label="Domínios:">                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6" label="Prévio (Há 3 Meses):">                 
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6" label="Pré Reab:">
                        Data: <b-form-input type="date" v-model="selected6_1" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6" label="Pós Reab:">
                        Data: <b-form-input type="date" v-model="selected6_2" />                    
                    </b-form-group>                
                </b-col>                               
            </b-row>                
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6" label="Capacidade Funcional">                    
                    </b-form-group>                
                </b-col>                
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6">
                        <b-form-input type="text" v-model="selected6_3" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6">
                        <b-form-input type="text" v-model="selected6_4" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6">
                        <b-form-input type="text" v-model="selected6_5" />                    
                    </b-form-group>                
                </b-col>                               
            </b-row>               
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6" label="Limitação Por Aspectos Físicos">                    
                    </b-form-group>                
                </b-col>                
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6">
                        <b-form-input type="text" v-model="selected6_6" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6">
                        <b-form-input type="text" v-model="selected6_7" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6">
                        <b-form-input type="text" v-model="selected6_8" />                    
                    </b-form-group>                
                </b-col>                               
            </b-row>               
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6" label="Dor">                    
                    </b-form-group>                
                </b-col>                
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6">
                        <b-form-input type="text" v-model="selected6_9" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6">
                        <b-form-input type="text" v-model="selected6_10" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6">
                        <b-form-input type="text" v-model="selected6_11" />                    
                    </b-form-group>                
                </b-col>                               
            </b-row>             
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6" label="Estado Geral de Saúde">                    
                    </b-form-group>                
                </b-col>                
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6">
                        <b-form-input type="text" v-model="selected6_12" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6">
                        <b-form-input type="text" v-model="selected6_13" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6">
                        <b-form-input type="text" v-model="selected6_14" />                    
                    </b-form-group>                
                </b-col>                               
            </b-row>             
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6" label="Vitalidade">                    
                    </b-form-group>                
                </b-col>                
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6">
                        <b-form-input type="text" v-model="selected6_15" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6">
                        <b-form-input type="text" v-model="selected6_16" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6">
                        <b-form-input type="text" v-model="selected6_17" />                    
                    </b-form-group>                
                </b-col>                               
            </b-row>             
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6" label="Aspectos Sociais">                    
                    </b-form-group>                
                </b-col>                
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6">
                        <b-form-input type="text" v-model="selected6_18" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6">
                        <b-form-input type="text" v-model="selected6_19" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6">
                        <b-form-input type="text" v-model="selected6_20" />                    
                    </b-form-group>                
                </b-col>                               
            </b-row>             
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6" label="Limitação Por Aspectos Emocionais">                    
                    </b-form-group>                
                </b-col>                
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6">
                        <b-form-input type="text" v-model="selected6_21" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6">
                        <b-form-input type="text" v-model="selected6_22" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6">
                        <b-form-input type="text" v-model="selected6_23" />                    
                    </b-form-group>                
                </b-col>                               
            </b-row>             
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6" label="Saúde Mental">                    
                    </b-form-group>                
                </b-col>                
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6">
                        <b-form-input type="text" v-model="selected6_24" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6">
                        <b-form-input type="text" v-model="selected6_25" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6">
                        <b-form-input type="text" v-model="selected6_26" />                    
                    </b-form-group>                
                </b-col>                               
            </b-row>             
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6" label="Total">                    
                    </b-form-group>                
                </b-col>                
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6">
                        <b-form-input type="text" v-model="selected6_27" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6">
                        <b-form-input type="text" v-model="selected6_28" />                    
                    </b-form-group>                
                </b-col>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 6">
                        <b-form-input type="text" v-model="selected6_29" />                    
                    </b-form-group>                
                </b-col>                               
            </b-row>                                                                                                                                          
        </b-form-group>     

        <b-button v-show="numberQuestion == 6" variant="success" @click="numberQuestion = 7">Ok</b-button>  


        <b-form-group v-show="numberQuestion == 7" class="mt-3" label="Duke Activity Status Index (DASI)">
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group class="mt-3" v-show="numberQuestion == 7" label="Pré Reab:">
                        Data: <b-form-input type="date" v-model="selected7_1" />                    
                    </b-form-group>                
                </b-col>                            
            </b-row>
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 7" label="1:">
                        <b-form-input type="text" v-model="selected7_2" />                    
                    </b-form-group>                
                </b-col>      
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 7" label="2:">
                        <b-form-input type="text" v-model="selected7_3" />                    
                    </b-form-group>                
                </b-col>   
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 7" label="3:">
                        <b-form-input type="text" v-model="selected7_4" />                    
                    </b-form-group>                
                </b-col>                                                    
            </b-row>   
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 7" label="4:">
                        <b-form-input type="text" v-model="selected7_5" />                    
                    </b-form-group>                
                </b-col>       
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 7" label="5:">
                        <b-form-input type="text" v-model="selected7_6" />                    
                    </b-form-group>                
                </b-col>   
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 7" label="6:">
                        <b-form-input type="text" v-model="selected7_7" />                    
                    </b-form-group>                
                </b-col>                                                                              
            </b-row>   
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 7" label="7:">
                        <b-form-input type="text" v-model="selected7_8" />                    
                    </b-form-group>                
                </b-col>        
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 7" label="8:">
                        <b-form-input type="text" v-model="selected7_9" />                    
                    </b-form-group>                
                </b-col>       
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 7" label="9:">
                        <b-form-input type="text" v-model="selected7_10" />                    
                    </b-form-group>                
                </b-col>                                                                         
            </b-row>         
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 7" label="10:">
                        <b-form-input type="text" v-model="selected7_11" />                    
                    </b-form-group>                
                </b-col>        
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 7" label="11:">
                        <b-form-input type="text" v-model="selected7_12" />                    
                    </b-form-group>                
                </b-col>    
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 7" label="12:">
                        <b-form-input type="text" v-model="selected7_13" />                    
                    </b-form-group>                
                </b-col>                                                                              
            </b-row>                                                   
            <b-row>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 7" label="Total:">
                        <b-form-input type="text" v-model="selected7_14" />                    
                    </b-form-group>                
                </b-col>                                                                              
            </b-row>                                                                                                   
        </b-form-group>    

        <b-button v-show="numberQuestion == 7" variant="success" @click="numberQuestion = 8">Ok</b-button>   


        <b-form-group v-show="numberQuestion == 8" class="mt-3" label="Duke Activity Status Index (DASI)">
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group class="mt-3" v-show="numberQuestion == 8" label="Pós Reab:">
                        Data: <b-form-input type="date" v-model="selected8_1" />                    
                    </b-form-group>                
                </b-col>                            
            </b-row>
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 8" label="1:">
                        <b-form-input type="text" v-model="selected8_2" />                    
                    </b-form-group>                
                </b-col>      
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 8" label="2:">
                        <b-form-input type="text" v-model="selected8_3" />                    
                    </b-form-group>                
                </b-col>   
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 8" label="3:">
                        <b-form-input type="text" v-model="selected8_4" />                    
                    </b-form-group>                
                </b-col>                                                    
            </b-row>   
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 8" label="4:">
                        <b-form-input type="text" v-model="selected8_5" />                    
                    </b-form-group>                
                </b-col>       
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 8" label="5:">
                        <b-form-input type="text" v-model="selected8_6" />                    
                    </b-form-group>                
                </b-col>   
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 8" label="6:">
                        <b-form-input type="text" v-model="selected8_7" />                    
                    </b-form-group>                
                </b-col>                                                                              
            </b-row>   
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 8" label="7:">
                        <b-form-input type="text" v-model="selected8_8" />                    
                    </b-form-group>                
                </b-col>        
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 8" label="8:">
                        <b-form-input type="text" v-model="selected8_9" />                    
                    </b-form-group>                
                </b-col>       
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 8" label="9:">
                        <b-form-input type="text" v-model="selected8_10" />                    
                    </b-form-group>                
                </b-col>                                                                         
            </b-row>         
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 8" label="10:">
                        <b-form-input type="text" v-model="selected8_11" />                    
                    </b-form-group>                
                </b-col>        
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 8" label="11:">
                        <b-form-input type="text" v-model="selected8_12" />                    
                    </b-form-group>                
                </b-col>    
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 8" label="12:">
                        <b-form-input type="text" v-model="selected8_13" />                    
                    </b-form-group>                
                </b-col>                                                                              
            </b-row>                                                   
            <b-row>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 8" label="Total:">
                        <b-form-input type="text" v-model="selected8_14" />                    
                    </b-form-group>                
                </b-col>                                                                              
            </b-row>                                                                                                   
        </b-form-group>    

        <b-button v-show="numberQuestion == 8" variant="success" @click="numberQuestion = 9">Ok</b-button>   


        <b-form-group v-show="numberQuestion == 9" class="mt-3" label="Manovacuometria">
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group class="mt-3" v-show="numberQuestion == 9" label="Pré Reab:">
                        Data: <b-form-input type="date" v-model="selected9_1" />                    
                    </b-form-group>                
                </b-col>                             
            </b-row>   
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 9" label="PIMÁX:">
                        <b-form-input type="text" v-model="selected9_2" />                    
                    </b-form-group>                
                </b-col>      
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 9" label="PEMÁX:">
                        <b-form-input type="text" v-model="selected9_3" />                    
                    </b-form-group>                
                </b-col>   
            </b-row>           
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 9" label="Pós Reab:">
                        Data: <b-form-input type="date" v-model="selected9_4" />                   
                    </b-form-group>                
                </b-col>                                                    
            </b-row>                                                        
            <b-row>
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 9" label="PIMÁX:">
                        <b-form-input type="text" v-model="selected9_5" />                    
                    </b-form-group>                
                </b-col>       
                <b-col md="2" sm="12">   
                    <b-form-group v-show="numberQuestion == 9" label="PEMÁX:">
                        <b-form-input type="text" v-model="selected9_6" />                    
                    </b-form-group>                
                </b-col>   
            </b-row>                                                        
        </b-form-group>    

        <b-button v-show="numberQuestion == 9" variant="success" @click="numberQuestion = 10">Ok</b-button>     


        <b-form-group v-show="numberQuestion == 10" class="mt-3" label="Ventilometria">
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group class="mt-3" v-show="numberQuestion == 10" label="CV (ml/kgPP):">
                        <b-form-input type="text" v-model="selected10_1" />                    
                    </b-form-group>                
                </b-col>                             
            </b-row>   
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group class="mt-3" v-show="numberQuestion == 10" label="Vmin:">
                        <b-form-input type="text" v-model="selected10_2" />                    
                    </b-form-group>                
                </b-col>                             
            </b-row>    
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group class="mt-3" v-show="numberQuestion == 10" label="Fr:">
                        <b-form-input type="text" v-model="selected10_3" />                    
                    </b-form-group>                
                </b-col>                             
            </b-row>  
            <b-row> 
                <b-col md="2" sm="12">   
                    <b-form-group class="mt-3" v-show="numberQuestion == 10" label="Vc:">
                        <b-form-input type="text" v-model="selected10_4" />                    
                    </b-form-group>                
                </b-col>                             
            </b-row>                                                                            
        </b-form-group>    

        <b-button v-show="numberQuestion == 10" variant="success" @click="numberQuestion = 11">Ok</b-button>      


        <b-form-group v-show="numberQuestion == 11" class="mt-3" label="Espirometria">
            <b-row> 
                <b-col md="12" sm="12">   
                    <b-form-group class="mt-3" v-show="numberQuestion == 11" label="VEF1:">
                        <b-form-group class="mt-3" v-show="numberQuestion == 11" label="PREV:">
                            <b-form-input type="text" v-model="selected11_1" />                    
                        </b-form-group>
                        <b-form-group class="mt-3" v-show="numberQuestion == 11" label="PRÉ:">
                            <b-form-input type="text" v-model="selected11_2" />                    
                        </b-form-group>
                        <b-form-group class="mt-3" v-show="numberQuestion == 11" label="%PREV:">
                            <b-form-input type="text" v-model="selected11_3" />                    
                        </b-form-group>
                        <b-form-group class="mt-3" v-show="numberQuestion == 11" label="PÓS:">
                            <b-form-input type="text" v-model="selected11_4" />                    
                        </b-form-group>
                        <b-form-group class="mt-3" v-show="numberQuestion == 11" label="%PREV:">
                            <b-form-input type="text" v-model="selected11_5" />                    
                        </b-form-group>
                        <b-form-group class="mt-3" v-show="numberQuestion == 11" label="%DES:">
                            <b-form-input type="text" v-model="selected11_6" />                    
                        </b-form-group>
                    </b-form-group>                
                </b-col>                             
            </b-row>   
            <b-row> 
                <b-col md="12" sm="12">   
                    <b-form-group class="mt-3" v-show="numberQuestion == 11" label="CVF:">
                        <b-form-group class="mt-3" v-show="numberQuestion == 11" label="PREV:">
                            <b-form-input type="text" v-model="selected11_7" />                    
                        </b-form-group>
                        <b-form-group class="mt-3" v-show="numberQuestion == 11" label="PRÉ:">
                            <b-form-input type="text" v-model="selected11_8" />                    
                        </b-form-group>
                        <b-form-group class="mt-3" v-show="numberQuestion == 11" label="%PREV:">
                            <b-form-input type="text" v-model="selected11_9" />                    
                        </b-form-group>
                        <b-form-group class="mt-3" v-show="numberQuestion == 11" label="PÓS:">
                            <b-form-input type="text" v-model="selected11_10" />                    
                        </b-form-group>
                        <b-form-group class="mt-3" v-show="numberQuestion == 11" label="%PREV:">
                            <b-form-input type="text" v-model="selected11_11" />                    
                        </b-form-group>
                        <b-form-group class="mt-3" v-show="numberQuestion == 11" label="%DES:">
                            <b-form-input type="text" v-model="selected11_12" />                    
                        </b-form-group>
                    </b-form-group>                
                </b-col>                             
            </b-row>                                                        
            <b-row> 
                <b-col md="12" sm="12">   
                    <b-form-group class="mt-3" v-show="numberQuestion == 11" label="VEF1 / CVF:">
                        <b-form-group class="mt-3" v-show="numberQuestion == 11" label="PREV:">
                            <b-form-input type="text" v-model="selected11_13" />                    
                        </b-form-group>
                        <b-form-group class="mt-3" v-show="numberQuestion == 11" label="PRÉ:">
                            <b-form-input type="text" v-model="selected11_14" />                    
                        </b-form-group>
                        <b-form-group class="mt-3" v-show="numberQuestion == 11" label="%PREV:">
                            <b-form-input type="text" v-model="selected11_15" />                    
                        </b-form-group>
                        <b-form-group class="mt-3" v-show="numberQuestion == 11" label="PÓS:">
                            <b-form-input type="text" v-model="selected11_16" />                    
                        </b-form-group>
                        <b-form-group class="mt-3" v-show="numberQuestion == 11" label="%PREV:">
                            <b-form-input type="text" v-model="selected11_17" />                    
                        </b-form-group>
                        <b-form-group class="mt-3" v-show="numberQuestion == 11" label="%DES:">
                            <b-form-input type="text" v-model="selected11_18" />                    
                        </b-form-group>
                    </b-form-group>                
                </b-col>                             
            </b-row>      
            <b-row> 
                <b-col md="12" sm="12">   
                    <b-form-group class="mt-3" v-show="numberQuestion == 11" label="FEF 25-75%:">
                        <b-form-group class="mt-3" v-show="numberQuestion == 11" label="PREV:">
                            <b-form-input type="text" v-model="selected11_19" />                    
                        </b-form-group>
                        <b-form-group class="mt-3" v-show="numberQuestion == 11" label="PRÉ:">
                            <b-form-input type="text" v-model="selected11_20" />                    
                        </b-form-group>
                        <b-form-group class="mt-3" v-show="numberQuestion == 11" label="%PREV:">
                            <b-form-input type="text" v-model="selected11_21" />                    
                        </b-form-group>
                        <b-form-group class="mt-3" v-show="numberQuestion == 11" label="PÓS:">
                            <b-form-input type="text" v-model="selected11_22" />                    
                        </b-form-group>
                        <b-form-group class="mt-3" v-show="numberQuestion == 11" label="%PREV:">
                            <b-form-input type="text" v-model="selected11_23" />                    
                        </b-form-group>
                        <b-form-group class="mt-3" v-show="numberQuestion == 11" label="%DES:">
                            <b-form-input type="text" v-model="selected11_24" />                    
                        </b-form-group>
                    </b-form-group>                
                </b-col>                             
            </b-row>                                          
            <b-row> 
                <b-col md="12" sm="12">   
                    <b-form-group class="mt-3" v-show="numberQuestion == 11" label="PFE:">
                        <b-form-group class="mt-3" v-show="numberQuestion == 11" label="PREV:">
                            <b-form-input type="text" v-model="selected11_25" />                    
                        </b-form-group>
                        <b-form-group class="mt-3" v-show="numberQuestion == 11" label="PRÉ:">
                            <b-form-input type="text" v-model="selected11_26" />                    
                        </b-form-group>
                        <b-form-group class="mt-3" v-show="numberQuestion == 11" label="%PREV:">
                            <b-form-input type="text" v-model="selected11_27" />                    
                        </b-form-group>
                        <b-form-group class="mt-3" v-show="numberQuestion == 11" label="PÓS:">
                            <b-form-input type="text" v-model="selected11_28" />                    
                        </b-form-group>
                        <b-form-group class="mt-3" v-show="numberQuestion == 11" label="%PREV:">
                            <b-form-input type="text" v-model="selected11_29" />                    
                        </b-form-group>
                        <b-form-group class="mt-3" v-show="numberQuestion == 11" label="%DES:">
                            <b-form-input type="text" v-model="selected11_30" />                    
                        </b-form-group>
                    </b-form-group>                
                </b-col>                             
            </b-row>              
        </b-form-group>    

        <b-button v-show="numberQuestion == 11" variant="success" @click="numberQuestion = 12">Ok</b-button>        


        <b-form-group v-show="numberQuestion == 12" class="mt-3" label="MRC">
            <b-row> 
                <b-col md="1" sm="12">   
                    <b-form-group class="mt-3" v-show="numberQuestion == 12" label="Movimento">
                    </b-form-group>                
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-group class="mt-3" v-show="numberQuestion == 12" label="Direito">
                    </b-form-group>                
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-group class="mt-3" v-show="numberQuestion == 12" label="Esquerdo">
                    </b-form-group>                
                </b-col>                             
            </b-row>  
            <b-row> 
                <b-col md="1" sm="12">   
                    Abd. Ombro              
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected12_1" />
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected12_2" />
                </b-col>                              
            </b-row>     
            <b-row class="mt-3"> 
                <b-col md="1" sm="12">   
                    Flex. Cotovelo             
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected12_3" />
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected12_4" />
                </b-col>                              
            </b-row>      
            <b-row class="mt-3"> 
                <b-col md="1" sm="12">   
                    Ext. Punho             
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected12_5" />
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected12_6" />
                </b-col>                              
            </b-row>     
            <b-row class="mt-3"> 
                <b-col md="1" sm="12">   
                    Flex. Quadril              
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected12_7" />
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected12_8" />
                </b-col>                              
            </b-row>   
            <b-row class="mt-3"> 
                <b-col md="1" sm="12">   
                    Ext. Joelho              
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected12_9" />
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected12_10" />
                </b-col>                              
            </b-row>  
            <b-row class="mt-3"> 
                <b-col md="1" sm="12">   
                    Dorsiflex. Tornozelo              
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected12_11" />
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected12_12" />
                </b-col>                              
            </b-row>                                                                                                                        
        </b-form-group>    

        <b-button v-show="numberQuestion == 12" variant="success" @click="numberQuestion = 13">Ok</b-button> 


        <b-form-group v-show="numberQuestion == 13" class="mt-3" label="Dinamometria Manual">
            <b-row> 
                <b-col md="1" sm="12">   
                    <b-form-group class="mt-3" v-show="numberQuestion == 13" label="Teste">
                    </b-form-group>                
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-group class="mt-3" v-show="numberQuestion == 13" label="Direito">
                    </b-form-group>                
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-group class="mt-3" v-show="numberQuestion == 13" label="Esquerdo">
                    </b-form-group>                
                </b-col>                             
            </b-row>  
            <b-row> 
                <b-col md="1" sm="12">   
                    1
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected13_1" />
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected13_2" />
                </b-col>                              
            </b-row>     
            <b-row class="mt-3"> 
                <b-col md="1" sm="12">   
                    2             
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected13_3" />
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected13_4" />
                </b-col>                              
            </b-row>      
            <b-row class="mt-3"> 
                <b-col md="1" sm="12">   
                    3             
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected13_5" />
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected13_6" />
                </b-col>                              
            </b-row>     
            <b-row class="mt-3"> 
                <b-col md="1" sm="12">   
                    Valor Final              
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected13_7" />
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected13_8" />
                </b-col>                              
            </b-row>   
            <b-row class="mt-3"> 
                <b-col md="2" sm="12">   
                    Qual é o Membro Dominante             
                </b-col>                             
                <b-col md="1" sm="12">   
                    <b-row>
                        <b-form-radio @change="numberQuestion = 13" v-model="selected13_9" name="some-radios-13_9" value="0">D</b-form-radio>
                        <b-form-radio class="ml-3" @change="numberQuestion = 13" v-model="selected13_9" name="some-radios-13_9" value="1">E</b-form-radio>
                    </b-row>
                </b-col>                              
            </b-row>                                                                                                                        
        </b-form-group>    

        <b-button v-show="numberQuestion == 13" variant="success" @click="numberQuestion = 14">Ok</b-button>  


        <b-form-group v-show="numberQuestion == 14" class="mt-3" label="Exames Complementares">
            <b-row> 
                <b-col md="12" sm="12">   
                    <b-form-group class="mt-3" v-show="numberQuestion == 14" label="RX de Tórax">
                        <b-form-textarea id="textarea" v-model="selected14_1" placeholder="" rows="1" max-rows="6"></b-form-textarea>
                    </b-form-group>                
                </b-col>                                                          
            </b-row>  
            <b-row> 
                <b-col md="12" sm="12">   
                    <b-form-group class="mt-3" v-show="numberQuestion == 14" label="Tomografia de Tórax">
                        <b-form-textarea id="textarea" v-model="selected14_2" placeholder="" rows="1" max-rows="6"></b-form-textarea>
                    </b-form-group>                
                </b-col>                                                          
            </b-row>      
            <b-row> 
                <b-col md="12" sm="12">   
                    <b-form-group class="mt-3" v-show="numberQuestion == 14" label="Ecocardiograma">
                        <b-form-textarea id="textarea" v-model="selected14_3" placeholder="" rows="1" max-rows="6"></b-form-textarea>
                    </b-form-group>                
                </b-col>                                                          
            </b-row>    
            <b-row> 
                <b-col md="12" sm="12">   
                    <b-form-group class="mt-3" v-show="numberQuestion == 14" label="Exames Laboratoriais">
                        <b-form-textarea id="textarea" v-model="selected14_4" placeholder="" rows="1" max-rows="6"></b-form-textarea>
                    </b-form-group>                
                </b-col>                                                          
            </b-row>                                                                                                                                       
        </b-form-group>    

        <b-button v-show="numberQuestion == 14" variant="success" @click="numberQuestion = 15">Ok</b-button> 


        <b-form-group v-show="numberQuestion == 15" class="mt-3" label="Capacidade Funcional Máxima (Ergoespirometria)">
            <b-row> 
                <b-col md="1" sm="12">   
                    <b-form-group class="mt-3" v-show="numberQuestion == 15" label="Variáveis">
                    </b-form-group>                
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-group class="mt-3" v-show="numberQuestion == 15" label="Pré">
                        Data: <b-form-input type="date" v-model="selected15_1" />
                    </b-form-group>                
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-group class="mt-3" v-show="numberQuestion == 15" label="Pós">
                        Data: <b-form-input type="date" v-model="selected15_2" />
                    </b-form-group>                
                </b-col>                             
            </b-row>  
            <b-row> 
                <b-col md="1" sm="12">   
                    FC Basal
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected15_3" />
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected15_4" />
                </b-col>                              
            </b-row>     
            <b-row class="mt-3"> 
                <b-col md="1" sm="12">   
                    FC Máxima 
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected15_5" />
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected15_6" />
                </b-col>                              
            </b-row>      
            <b-row class="mt-3"> 
                <b-col md="1" sm="12">   
                    PA Inicial             
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected15_7" />
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected15_8" />
                </b-col>                              
            </b-row>     
            <b-row class="mt-3"> 
                <b-col md="1" sm="12">   
                    PApico              
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected15_9" />
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected15_10" />
                </b-col>                              
            </b-row>   
            <b-row class="mt-3"> 
                <b-col md="1" sm="12">   
                    PA Final             
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected15_11" />
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected15_12" />
                </b-col>                                 
            </b-row>      
            <b-row class="mt-3"> 
                <b-col md="1" sm="12">   
                    BORG Inicial             
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected15_13" />
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected15_14" />
                </b-col>                                 
            </b-row>    
            <b-row class="mt-3"> 
                <b-col md="1" sm="12">   
                    BORG Final            
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected15_15" />
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected15_16" />
                </b-col>                                 
            </b-row>    
            <b-row> 
                <b-col md="5" sm="12">   
                    <b-form-group class="mt-3" v-show="numberQuestion == 15" label="FC (VO2pico):">
                        <b-form-input type="text" v-model="selected15_17" />
                    </b-form-group>                
                </b-col>                                                          
            </b-row>  
            <b-row> 
                <b-col md="5" sm="12">   
                    <b-form-group class="mt-3" v-show="numberQuestion == 15" label="Observações:">
                        <b-form-textarea id="textarea" v-model="selected15_18" placeholder="" rows="1" max-rows="6"></b-form-textarea>
                    </b-form-group>                
                </b-col>                                                          
            </b-row>                                                                                                                                                           
        </b-form-group>    

        <b-button v-show="numberQuestion == 15" variant="success" @click="numberQuestion = 16">Ok</b-button>    


        <b-form-group v-show="numberQuestion == 16" class="mt-3" label="Dinamometria Digital Protocolo">
            <b-row> 
                <b-col md="1" sm="12">   
                    Quadríceps
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected16_1" />
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected16_2" />
                </b-col>                              
            </b-row>     
            <b-row class="mt-3"> 
                <b-col md="1" sm="12">   
                    Flexores Quadril 
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected16_3" />
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected16_4" />
                </b-col>                              
            </b-row>      
            <b-row class="mt-3"> 
                <b-col md="1" sm="12">   
                    Bíceps             
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected16_5" />
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected16_6" />
                </b-col>                              
            </b-row>     
            <b-row class="mt-3"> 
                <b-col md="1" sm="12">   
                    Flexores de Ombro              
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected16_7" />
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected16_8" />
                </b-col>                              
            </b-row>   
            <b-row class="mt-3"> 
                <b-col md="1" sm="12">   
                    Tríceps             
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected16_9" />
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-form-input type="text" v-model="selected16_10" />
                </b-col>                                 
            </b-row>      
            <b-row class="mt-3"> 
                <b-col md="2" sm="12">   
                    Qual é o Membro Dominante             
                </b-col>                             
                <b-col md="2" sm="12">   
                    <b-row>
                        <b-form-radio @change="numberQuestion = 16" v-model="selected16_11" name="some-radios-16_11" value="0">D</b-form-radio>
                        <b-form-radio class="ml-3" @change="numberQuestion = 16" v-model="selected16_11" name="some-radios-16_11" value="1">E</b-form-radio>
                    </b-row>
                </b-col>                                 
            </b-row>                                                                                                                                                        
        </b-form-group>                             
      </b-card>
    </div>
  </div>  
</template>

<script>
import PageTitle from "../template/PageTitle"
import { baseApiUrl, showError, userKey } from '@/global'
import axios from 'axios'
import Moment from 'moment'

export default {
    name: "AssessmentRespiratory",
    components: { PageTitle },
    data() {
      return {
        patient: null,
        assessment: null,
        theme: null,
        numberQuestion: 1,
        selected1_1: null,
        selected1_2: null,
        selected1_3: null,
        selected1_4: null,
        selected1_5: null,
        selected1_6: null,
        selected1_7: null,
        selected2_1: null,
        selected2_2: null,
        selected2_3: null,
        selected2_4: null,
        selected2_5: null,
        selected2_6: null,
        selected2_7: null,
        selected2_8: null,
        selected2_9: null,
        selected2_10: null,    
        selected2_11: null,
        selected2_12: null,
        selected2_13: false,
        selected2_14: false,    
        selected2_15: false,
        selected2_16: false,
        selected2_17: false,
        selected2_18: false,
        selected2_19: false,
        selected2_20: null,
        selected3_1: null,
        selected3_2: null,
        selected3_3: null,
        selected3_4: null,
        selected3_5: null,
        selected3_6: null,
        selected3_7: null,
        selected3_8: null,
        selected3_9: null,
        selected3_10: null,    
        selected3_11: null,
        selected3_12: null,
        selected3_13: null,
        selected3_14: null,    
        selected3_15: null,
        selected3_16: null,
        selected3_17: null,
        selected3_18: null,
        selected3_19: null,
        selected3_20: null,    
        selected3_21: null,    
        selected3_22: null,
        selected3_23: null,
        selected3_24: null,    
        selected3_25: null,
        selected3_26: null,
        selected3_27: null,
        selected3_28: null,
        selected3_29: null,
        selected3_30: null,     
        selected4_1: null,
        selected4_2: null,
        selected4_3: null,
        selected4_4: null,   
        selected5_1: null,
        selected5_2: null,
        selected5_3: null,
        selected5_4: null,
        selected5_5: null,
        selected5_6: null,
        selected5_7: null,
        selected5_8: null,
        selected5_9: null,
        selected5_10: null,    
        selected5_11: null,
        selected5_12: null,
        selected5_13: null,
        selected5_14: null,    
        selected5_15: null,
        selected5_16: null,
        selected5_17: null,
        selected5_18: null,
        selected5_19: null,
        selected5_20: null,    
        selected5_21: null,    
        selected5_22: null,
        selected5_23: null,
        selected5_24: null,    
        selected5_25: null,
        selected5_26: null,
        selected5_27: null,
        selected5_28: null,
        selected5_29: null,
        selected5_30: null, 
        selected5_31: null,
        selected5_32: null,
        selected5_33: null,
        selected5_34: null,
        selected5_35: null,
        selected5_36: null,
        selected5_37: null,
        selected5_38: null, 
        selected6_1: null,
        selected6_2: null,
        selected6_3: null,
        selected6_4: null,
        selected6_5: null,
        selected6_6: null,
        selected6_7: null,
        selected6_8: null,
        selected6_9: null,
        selected6_10: null,    
        selected6_11: null,
        selected6_12: null,
        selected6_13: null,
        selected6_14: null,    
        selected6_15: null,
        selected6_16: null,
        selected6_17: null,
        selected6_18: null,
        selected6_19: null,
        selected6_20: null,    
        selected6_21: null,    
        selected6_22: null,
        selected6_23: null,
        selected6_24: null,    
        selected6_25: null,
        selected6_26: null,
        selected6_27: null,
        selected6_28: null,
        selected6_29: null,  
        selected7_1: null,
        selected7_2: null,
        selected7_3: null,
        selected7_4: null,
        selected7_5: null,
        selected7_6: null,
        selected7_7: null,
        selected7_8: null,
        selected7_9: null,
        selected7_10: null,    
        selected7_11: null,
        selected7_12: null,
        selected7_13: null,
        selected7_14: null, 
        selected8_1: null,
        selected8_2: null,
        selected8_3: null,
        selected8_4: null,
        selected8_5: null,
        selected8_6: null,
        selected8_7: null,
        selected8_8: null,
        selected8_9: null,
        selected8_10: null,    
        selected8_11: null,
        selected8_12: null,
        selected8_13: null,
        selected8_14: null,   
        selected9_1: null,
        selected9_2: null,
        selected9_3: null,
        selected9_4: null,
        selected9_5: null,
        selected9_6: null,
        selected10_1: null,
        selected10_2: null,
        selected10_3: null,
        selected10_4: null,  
        selected11_1: null,
        selected11_2: null,
        selected11_3: null,
        selected11_4: null,
        selected11_5: null,
        selected11_6: null,
        selected11_7: null,
        selected11_8: null,
        selected11_9: null,
        selected11_10: null,    
        selected11_11: null,
        selected11_12: null,
        selected11_13: null,
        selected11_14: null,    
        selected11_15: null,
        selected11_16: null,
        selected11_17: null,
        selected11_18: null,
        selected11_19: null,
        selected11_20: null,    
        selected11_21: null,    
        selected11_22: null,
        selected11_23: null,
        selected11_24: null,    
        selected11_25: null,
        selected11_26: null,
        selected11_27: null,
        selected11_28: null,
        selected11_29: null,
        selected11_30: null,   
        selected12_1: null,
        selected12_2: null,
        selected12_3: null,
        selected12_4: null,
        selected12_5: null,
        selected12_6: null,
        selected12_7: null,
        selected12_8: null,
        selected12_9: null,
        selected12_10: null,    
        selected12_11: null,
        selected12_12: null,  
        selected13_1: null,
        selected13_2: null,
        selected13_3: null,
        selected13_4: null,
        selected13_5: null,
        selected13_6: null,
        selected13_7: null,
        selected13_8: null,
        selected13_9: null,   
        selected14_1: null,
        selected14_2: null,
        selected14_3: null,
        selected14_4: null,    
        selected15_1: null,
        selected15_2: null,
        selected15_3: null,
        selected15_4: null,
        selected15_5: null,
        selected15_6: null,
        selected15_7: null,
        selected15_8: null,
        selected15_9: null,
        selected15_10: null,    
        selected15_11: null,
        selected15_12: null,
        selected15_13: null,
        selected15_14: null,    
        selected15_15: null,
        selected15_16: null,
        selected15_17: null,
        selected15_18: null,
        selected16_1: null,
        selected16_2: null,
        selected16_3: null,
        selected16_4: null,
        selected16_5: null,
        selected16_6: null,
        selected16_7: null,
        selected16_8: null,
        selected16_9: null,
        selected16_10: null,    
        selected16_11: null                                                                                         
      }
    },
    methods: {
      sendQuestions() {
        // if ((this.selected1_1 == null && this.selected1_2 == null && this.selected1_3 == null &&
        //      this.selected1_4 == null && this.selected1_5 == null && this.selected1_6 == null &&
        //      this.selected1_7 == null) ||
        //     (this.selected2_1 == null && this.selected2_2 == null && this.selected2_3 == null &&
        //      this.selected2_4 == null && this.selected2_5 == null && this.selected2_6 == null &&
        //      this.selected2_7 == null && this.selected2_8 == null && this.selected2_9 == null &&
        //      this.selected2_10 == null && this.selected2_11 == null && this.selected2_12 == null && 
        //      this.selected2_13 == false && this.selected2_14 == false && this.selected2_15 == false && 
        //      this.selected2_16 == false && this.selected2_17 == false && this.selected2_18 == false && 
        //      this.selected2_19 == false && this.selected2_20 == null) ||
        //     (this.selected3_1 == null && this.selected3_2 == null && this.selected3_3 == null &&
        //      this.selected3_4 == null && this.selected3_5 == null && this.selected3_6 == null &&
        //      this.selected3_7 == null && this.selected3_8 == null && this.selected3_9 == null &&
        //      this.selected3_10 == null && this.selected3_11 == null && this.selected3_12 == null && 
        //      this.selected3_13 == null && this.selected3_14 == null && this.selected3_15 == null && 
        //      this.selected3_16 == null && this.selected3_17 == null && this.selected3_18 == null && 
        //      this.selected3_19 == null && this.selected3_20 == null && this.selected3_21 == null &&
        //      this.selected3_22 == null && this.selected3_23 == null && this.selected3_24 == null &&
        //      this.selected3_25 == null && this.selected3_26 == null && this.selected3_27 == null &&
        //      this.selected3_28 == null && this.selected3_29 == null && this.selected3_30 == null) ||
        //     (this.selected4_1 == null && this.selected4_2 == null && this.selected4_3 == null &&
        //      this.selected4_4 == null) ||
        //     (this.selected5_1 == null && this.selected5_2 == null && this.selected5_3 == null &&
        //      this.selected5_4 == null && this.selected5_5 == null && this.selected5_6 == null &&
        //      this.selected5_7 == null && this.selected5_8 == null && this.selected5_9 == null &&
        //      this.selected5_10 == null && this.selected5_11 == null && this.selected5_12 == null && 
        //      this.selected5_13 == null && this.selected5_14 == null && this.selected5_15 == null && 
        //      this.selected5_16 == null && this.selected5_17 == null && this.selected5_18 == null && 
        //      this.selected5_19 == null && this.selected5_20 == null && this.selected5_21 == null &&
        //      this.selected5_22 == null && this.selected5_23 == null && this.selected5_24 == null &&
        //      this.selected5_25 == null && this.selected5_26 == null && this.selected5_27 == null &&
        //      this.selected5_28 == null && this.selected5_29 == null && this.selected5_30 == null &&
        //      this.selected5_31 == null && this.selected5_32 == null && this.selected5_33 == null &&
        //      this.selected5_34 == null && this.selected5_35 == null && this.selected5_36 == null &&
        //      this.selected5_37 == null && this.selected5_38 == null) ||
        //     (this.selected6_1 == null && this.selected6_2 == null && this.selected6_3 == null &&
        //      this.selected6_4 == null && this.selected6_5 == null && this.selected6_6 == null &&
        //      this.selected6_7 == null && this.selected6_8 == null && this.selected6_9 == null &&
        //      this.selected6_10 == null && this.selected6_11 == null && this.selected6_12 == null && 
        //      this.selected6_13 == null && this.selected6_14 == null && this.selected6_15 == null && 
        //      this.selected6_16 == null && this.selected6_17 == null && this.selected6_18 == null && 
        //      this.selected6_19 == null && this.selected6_20 == null && this.selected6_21 == null &&
        //      this.selected6_22 == null && this.selected6_23 == null && this.selected6_24 == null &&
        //      this.selected6_25 == null && this.selected6_26 == null && this.selected6_27 == null &&
        //      this.selected6_28 == null && this.selected6_29 == null) ||
        //     (this.selected7_1 == null && this.selected7_2 == null && this.selected7_3 == null &&
        //      this.selected7_4 == null && this.selected7_5 == null && this.selected7_6 == null &&
        //      this.selected7_7 == null && this.selected7_8 == null && this.selected7_9 == null &&
        //      this.selected7_10 == null && this.selected7_11 == null && this.selected7_12 == null && 
        //      this.selected7_13 == null && this.selected7_14 == null) ||
        //     (this.selected8_1 == null && this.selected8_2 == null && this.selected8_3 == null &&
        //      this.selected8_4 == null && this.selected8_5 == null && this.selected8_6 == null &&
        //      this.selected8_7 == null && this.selected8_8 == null && this.selected8_9 == null &&
        //      this.selected8_10 == null && this.selected8_11 == null && this.selected8_12 == null && 
        //      this.selected8_13 == null && this.selected8_14 == null) || 
        //     (this.selected9_1 == null && this.selected9_2 == null && this.selected9_3 == null &&
        //      this.selected9_4 == null && this.selected9_5 == null && this.selected9_6 == null) ||
        //     (this.selected10_1 == null && this.selected10_2 == null && this.selected10_3 == null &&
        //      this.selected10_4 == null) ||
        //     (this.selected11_1 == null && this.selected11_2 == null && this.selected11_3 == null &&
        //      this.selected11_4 == null && this.selected11_5 == null && this.selected11_6 == null &&
        //      this.selected11_7 == null && this.selected11_8 == null && this.selected11_9 == null &&
        //      this.selected11_10 == null && this.selected11_11 == null && this.selected11_12 == null && 
        //      this.selected11_13 == null && this.selected11_14 == null && this.selected11_15 == null && 
        //      this.selected11_16 == null && this.selected11_17 == null && this.selected11_18 == null && 
        //      this.selected11_19 == null && this.selected11_20 == null && this.selected11_21 == null &&
        //      this.selected11_22 == null && this.selected11_23 == null && this.selected11_24 == null &&
        //      this.selected11_25 == null && this.selected11_26 == null && this.selected11_27 == null &&
        //      this.selected11_28 == null && this.selected11_29 == null && this.selected11_30 == null) ||
        //     (this.selected12_1 == null && this.selected12_2 == null && this.selected12_3 == null &&
        //      this.selected12_4 == null && this.selected12_5 == null && this.selected12_6 == null &&
        //      this.selected12_7 == null && this.selected12_8 == null && this.selected12_9 == null &&
        //      this.selected12_10 == null && this.selected12_11 == null && this.selected12_12 == null) ||
        //     (this.selected13_1 == null && this.selected13_2 == null && this.selected13_3 == null &&
        //      this.selected13_4 == null && this.selected13_5 == null && this.selected13_6 == null &&
        //      this.selected13_7 == null && this.selected13_8 == null && this.selected13_9 == null) ||
        //     (this.selected14_1 == null && this.selected14_2 == null && this.selected14_3 == null &&
        //      this.selected14_4 == null) ||
        //     (this.selected15_1 == null && this.selected15_2 == null && this.selected15_3 == null &&
        //      this.selected15_4 == null && this.selected15_5 == null && this.selected15_6 == null &&
        //      this.selected15_7 == null && this.selected15_8 == null && this.selected15_9 == null &&
        //      this.selected15_10 == null && this.selected15_11 == null && this.selected15_12 == null && 
        //      this.selected15_13 == null && this.selected15_14 == null && this.selected15_15 == null && 
        //      this.selected15_16 == null && this.selected15_17 == null && this.selected15_18 == null) ||
        //     (this.selected16_1 == null && this.selected16_2 == null && this.selected16_3 == null &&
        //      this.selected16_4 == null && this.selected16_5 == null && this.selected16_6 == null &&
        //      this.selected16_7 == null && this.selected16_8 == null && this.selected16_9 == null &&
        //      this.selected16_10 == null && this.selected16_11 == null) ) {
        //   this.$toasted.global.defaultError({ msg: 'Preencha todos os itens da avaliação' })
        // } else {     
          Moment.locale('pt-br')  
          
          axios.get(`${baseApiUrl}/patient/getById/${this.patient._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`).then(res => {
            this.patient = res.data

            for (let q of this.patient.questions) {
              if (q.type == 1 && q.name == this.assessment.name) {
                q.status = 1

                q.answers = []

                q.answers.push(
                  {
                    'dateAnswers': Moment(new Date()).format('L')
                  }, 
                  {
                    '1_1': this.selected1_1, 
                    '1_2': this.selected1_2, 
                    '1_3': this.selected1_3, 
                    '1_4': this.selected1_4, 
                    '1_5': this.selected1_5, 
                    '1_6': this.selected1_6, 
                    '1_7': this.selected1_7, 
                  },
                  {
                    '2_1': this.selected2_1, 
                    '2_2': this.selected2_2, 
                    '2_3': this.selected2_3, 
                    '2_4': this.selected2_4, 
                    '2_5': this.selected2_5, 
                    '2_6': this.selected2_6, 
                    '2_7': this.selected2_7,
                    '2_8': this.selected2_8, 
                    '2_9': this.selected2_9, 
                    '2_10': this.selected2_10,
                    '2_11': this.selected2_11,
                    '2_12': this.selected2_12,
                    '2_13': this.selected2_13,
                    '2_14': this.selected2_14,
                    '2_15': this.selected2_15,
                    '2_16': this.selected2_16,
                    '2_17': this.selected2_17,
                    '2_18': this.selected2_18,
                    '2_19': this.selected2_19,
                    '2_20': this.selected2_20
                  },
                  {
                    '3_1': this.selected3_1, 
                    '3_2': this.selected3_2, 
                    '3_3': this.selected3_3, 
                    '3_4': this.selected3_4, 
                    '3_5': this.selected3_5, 
                    '3_6': this.selected3_6, 
                    '3_7': this.selected3_7,
                    '3_8': this.selected3_8, 
                    '3_9': this.selected3_9, 
                    '3_10': this.selected3_10,
                    '3_11': this.selected3_11, 
                    '3_12': this.selected3_12, 
                    '3_13': this.selected3_13, 
                    '3_14': this.selected3_14, 
                    '3_15': this.selected3_15, 
                    '3_16': this.selected3_16, 
                    '3_17': this.selected3_17,
                    '3_18': this.selected3_18, 
                    '3_19': this.selected3_19, 
                    '3_20': this.selected3_20,
                    '3_21': this.selected3_21, 
                    '3_22': this.selected3_22, 
                    '3_23': this.selected3_23, 
                    '3_24': this.selected3_24, 
                    '3_25': this.selected3_25, 
                    '3_26': this.selected3_26, 
                    '3_27': this.selected3_27,
                    '3_28': this.selected3_28, 
                    '3_29': this.selected3_29, 
                    '3_30': this.selected3_30                                        
                  },
                  {
                    '4_1': this.selected4_1,
                    '4_2': this.selected4_2,
                    '4_3': this.selected4_3,
                    '4_4': this.selected4_4 
                  },
                  {
                    '5_1': this.selected5_1, 
                    '5_2': this.selected5_2, 
                    '5_3': this.selected5_3, 
                    '5_4': this.selected5_4, 
                    '5_5': this.selected5_5, 
                    '5_6': this.selected5_6, 
                    '5_7': this.selected5_7,
                    '5_8': this.selected5_8, 
                    '5_9': this.selected5_9, 
                    '5_10': this.selected5_10,
                    '5_11': this.selected5_11, 
                    '5_12': this.selected5_12, 
                    '5_13': this.selected5_13, 
                    '5_14': this.selected5_14, 
                    '5_15': this.selected5_15, 
                    '5_16': this.selected5_16, 
                    '5_17': this.selected5_17,
                    '5_18': this.selected5_18, 
                    '5_19': this.selected5_19, 
                    '5_20': this.selected5_20,
                    '5_21': this.selected5_21, 
                    '5_22': this.selected5_22, 
                    '5_23': this.selected5_23, 
                    '5_24': this.selected5_24, 
                    '5_25': this.selected5_25, 
                    '5_26': this.selected5_26, 
                    '5_27': this.selected5_27,
                    '5_28': this.selected5_28, 
                    '5_29': this.selected5_29, 
                    '5_30': this.selected5_30,
                    '5_31': this.selected5_31, 
                    '5_32': this.selected5_32, 
                    '5_33': this.selected5_33, 
                    '5_34': this.selected5_34, 
                    '5_35': this.selected5_35, 
                    '5_36': this.selected5_36, 
                    '5_37': this.selected5_37, 
                    '5_38': this.selected5_38, 
                  },
                  {
                    '6_1': this.selected6_1, 
                    '6_2': this.selected6_2, 
                    '6_3': this.selected6_3, 
                    '6_4': this.selected6_4, 
                    '6_5': this.selected6_5, 
                    '6_6': this.selected6_6, 
                    '6_7': this.selected6_7,
                    '6_8': this.selected6_8, 
                    '6_9': this.selected6_9, 
                    '6_10': this.selected6_10,
                    '6_11': this.selected6_11, 
                    '6_12': this.selected6_12, 
                    '6_13': this.selected6_13, 
                    '6_14': this.selected6_14, 
                    '6_15': this.selected6_15, 
                    '6_16': this.selected6_16, 
                    '6_17': this.selected6_17,
                    '6_18': this.selected6_18, 
                    '6_19': this.selected6_19, 
                    '6_20': this.selected6_20,
                    '6_21': this.selected6_21, 
                    '6_22': this.selected6_22, 
                    '6_23': this.selected6_23, 
                    '6_24': this.selected6_24, 
                    '6_25': this.selected6_25, 
                    '6_26': this.selected6_26, 
                    '6_27': this.selected6_27,
                    '6_28': this.selected6_28, 
                    '6_29': this.selected6_29, 
                  },
                  {
                    '7_1': this.selected7_1, 
                    '7_2': this.selected7_2, 
                    '7_3': this.selected7_3, 
                    '7_4': this.selected7_4, 
                    '7_5': this.selected7_5, 
                    '7_6': this.selected7_6, 
                    '7_7': this.selected7_7, 
                    '7_8': this.selected7_8, 
                    '7_9': this.selected7_9, 
                    '7_10': this.selected7_10, 
                    '7_11': this.selected7_11, 
                    '7_12': this.selected7_12, 
                    '7_13': this.selected7_13, 
                    '7_14': this.selected7_14                    
                  },
                  {
                    '8_1': this.selected8_1, 
                    '8_2': this.selected8_2, 
                    '8_3': this.selected8_3, 
                    '8_4': this.selected8_4, 
                    '8_5': this.selected8_5, 
                    '8_6': this.selected8_6, 
                    '8_7': this.selected8_7, 
                    '8_8': this.selected8_8, 
                    '8_9': this.selected8_9, 
                    '8_10': this.selected8_10, 
                    '8_11': this.selected8_11, 
                    '8_12': this.selected8_12, 
                    '8_13': this.selected8_13, 
                    '8_14': this.selected8_14  
                  },
                  {
                    '9_1': this.selected9_1, 
                    '9_2': this.selected9_2, 
                    '9_3': this.selected9_3, 
                    '9_4': this.selected9_4, 
                    '9_5': this.selected9_5, 
                    '9_6': this.selected9_6
                  },
                  {
                    '10_1': this.selected10_1, 
                    '10_2': this.selected10_2, 
                    '10_3': this.selected10_3, 
                    '10_4': this.selected10_4
                  },
                  {
                    '11_1': this.selected11_1, 
                    '11_2': this.selected11_2, 
                    '11_3': this.selected11_3, 
                    '11_4': this.selected11_4, 
                    '11_5': this.selected11_5, 
                    '11_6': this.selected11_6, 
                    '11_7': this.selected11_7,
                    '11_8': this.selected11_8, 
                    '11_9': this.selected11_9, 
                    '11_10': this.selected11_10,
                    '11_11': this.selected11_11, 
                    '11_12': this.selected11_12, 
                    '11_13': this.selected11_13, 
                    '11_14': this.selected11_14, 
                    '11_15': this.selected11_15, 
                    '11_16': this.selected11_16, 
                    '11_17': this.selected11_17,
                    '11_18': this.selected11_18, 
                    '11_19': this.selected11_19, 
                    '11_20': this.selected11_20,
                    '11_21': this.selected11_21, 
                    '11_22': this.selected11_22, 
                    '11_23': this.selected11_23, 
                    '11_24': this.selected11_24, 
                    '11_25': this.selected11_25, 
                    '11_26': this.selected11_26, 
                    '11_27': this.selected11_27,
                    '11_28': this.selected11_28, 
                    '11_29': this.selected11_29, 
                    '11_30': this.selected11_30
                  },
                  {
                    '12_1': this.selected12_1, 
                    '12_2': this.selected12_2, 
                    '12_3': this.selected12_3, 
                    '12_4': this.selected12_4, 
                    '12_5': this.selected12_5, 
                    '12_6': this.selected12_6, 
                    '12_7': this.selected12_7,
                    '12_8': this.selected12_8, 
                    '12_9': this.selected12_9, 
                    '12_10': this.selected12_10,
                    '12_11': this.selected12_11, 
                    '12_12': this.selected12_12
                  },
                  {
                    '13_1': this.selected13_1, 
                    '13_2': this.selected13_2, 
                    '13_3': this.selected13_3, 
                    '13_4': this.selected13_4, 
                    '13_5': this.selected13_5, 
                    '13_6': this.selected13_6, 
                    '13_7': this.selected13_7,
                    '13_8': this.selected13_8, 
                    '13_9': this.selected13_9
                  },
                  {
                    '14_1': this.selected14_1,
                    '14_2': this.selected14_2,
                    '14_3': this.selected14_3,
                    '14_4': this.selected14_4  
                  },
                  {
                    '15_1': this.selected15_1, 
                    '15_2': this.selected15_2, 
                    '15_3': this.selected15_3, 
                    '15_4': this.selected15_4, 
                    '15_5': this.selected15_5, 
                    '15_6': this.selected15_6, 
                    '15_7': this.selected15_7,
                    '15_8': this.selected15_8, 
                    '15_9': this.selected15_9, 
                    '15_10': this.selected15_10,
                    '15_11': this.selected15_11, 
                    '15_12': this.selected15_12, 
                    '15_13': this.selected15_13, 
                    '15_14': this.selected15_14, 
                    '15_15': this.selected15_15, 
                    '15_16': this.selected15_16, 
                    '15_17': this.selected15_17,
                    '15_18': this.selected15_18 
                  },
                  {
                    '16_1': this.selected16_1, 
                    '16_2': this.selected16_2, 
                    '16_3': this.selected16_3, 
                    '16_4': this.selected16_4, 
                    '16_5': this.selected16_5, 
                    '16_6': this.selected16_6, 
                    '16_7': this.selected16_7,
                    '16_8': this.selected16_8, 
                    '16_9': this.selected16_9, 
                    '16_10': this.selected16_10,
                    '16_11': this.selected16_11
                  }                                                                                                                                                                                                                                                                                                                                                                                                                                                             
                ) 
              }
            }            

            const method = 'put'            
            const id = `/${this.patient._id}&${localStorage.getItem('client')}&${localStorage.getItem(userKey)}`

            axios[method](`${baseApiUrl}/patient${id}`, this.patient)
              .then(() => {
                  this.$toasted.global.defaultSuccess()
                  this.$router.push({name: 'notation', params: { },})
              })  
              .catch(showError) 
          }).catch((e) => {
            console.log(e)
            showError
          })
        }
    //   }   
    },
    mounted() {
      this.theme = localStorage.getItem('theme')

      this.patient = this.$route.params.questionSelected.patient
      this.assessment = this.$route.params.questionSelected

      if (this.$route.params.questionSelected.answers.length == 0) {
        this.selected1_1 = this.patient.name
        this.selected1_2 = this.patient.birthday

        if (this.patient.gender == '1') {
            this.selected1_3 = '0'
        } else {
            this.selected1_3 = '1'
        }

        this.selected1_5 = this.patient.phone1
        this.selected1_6 = this.patient.address.neighborhood
        this.selected1_7 = this.patient.address.city
      } else {
        this.selected1_1 = this.$route.params.questionSelected.answers[1]['1_1']
        this.selected1_2 = this.$route.params.questionSelected.answers[1]['1_2']
        this.selected1_3 = this.$route.params.questionSelected.answers[1]['1_3']
        this.selected1_4 = this.$route.params.questionSelected.answers[1]['1_4']
        this.selected1_5 = this.$route.params.questionSelected.answers[1]['1_5']
        this.selected1_6 = this.$route.params.questionSelected.answers[1]['1_6']
        this.selected1_7 = this.$route.params.questionSelected.answers[1]['1_7']

        this.selected2_1 = this.$route.params.questionSelected.answers[2]['2_1']
        this.selected2_2 = this.$route.params.questionSelected.answers[2]['2_2']
        this.selected2_3 = this.$route.params.questionSelected.answers[2]['2_3']
        this.selected2_4 = this.$route.params.questionSelected.answers[2]['2_4']
        this.selected2_5 = this.$route.params.questionSelected.answers[2]['2_5']
        this.selected2_6 = this.$route.params.questionSelected.answers[2]['2_6']
        this.selected2_7 = this.$route.params.questionSelected.answers[2]['2_7']  
        this.selected2_8 = this.$route.params.questionSelected.answers[2]['2_8']
        this.selected2_9 = this.$route.params.questionSelected.answers[2]['2_9']
        this.selected2_10 = this.$route.params.questionSelected.answers[2]['2_10']    
        this.selected2_11 = this.$route.params.questionSelected.answers[2]['2_11']
        this.selected2_12 = this.$route.params.questionSelected.answers[2]['2_12']
        this.selected2_13 = this.$route.params.questionSelected.answers[2]['2_13']
        this.selected2_14 = this.$route.params.questionSelected.answers[2]['2_14']
        this.selected2_15 = this.$route.params.questionSelected.answers[2]['2_15']
        this.selected2_16 = this.$route.params.questionSelected.answers[2]['2_16']
        this.selected2_17 = this.$route.params.questionSelected.answers[2]['2_17']
        this.selected2_18 = this.$route.params.questionSelected.answers[2]['2_18']
        this.selected2_19 = this.$route.params.questionSelected.answers[2]['2_19']
        this.selected2_20 = this.$route.params.questionSelected.answers[2]['2_20']    
        
        this.selected3_1 = this.$route.params.questionSelected.answers[3]['3_1']
        this.selected3_2 = this.$route.params.questionSelected.answers[3]['3_2']
        this.selected3_3 = this.$route.params.questionSelected.answers[3]['3_3']
        this.selected3_4 = this.$route.params.questionSelected.answers[3]['3_4']
        this.selected3_5 = this.$route.params.questionSelected.answers[3]['3_5']
        this.selected3_6 = this.$route.params.questionSelected.answers[3]['3_6']
        this.selected3_7 = this.$route.params.questionSelected.answers[3]['3_7']  
        this.selected3_8 = this.$route.params.questionSelected.answers[3]['3_8']
        this.selected3_9 = this.$route.params.questionSelected.answers[3]['3_9']
        this.selected3_10 = this.$route.params.questionSelected.answers[3]['3_10']    
        this.selected3_11 = this.$route.params.questionSelected.answers[3]['3_11']
        this.selected3_12 = this.$route.params.questionSelected.answers[3]['3_12']
        this.selected3_13 = this.$route.params.questionSelected.answers[3]['3_13']
        this.selected3_14 = this.$route.params.questionSelected.answers[3]['3_14']
        this.selected3_15 = this.$route.params.questionSelected.answers[3]['3_15']
        this.selected3_16 = this.$route.params.questionSelected.answers[3]['3_16']
        this.selected3_17 = this.$route.params.questionSelected.answers[3]['3_17']
        this.selected3_18 = this.$route.params.questionSelected.answers[3]['3_18']
        this.selected3_19 = this.$route.params.questionSelected.answers[3]['3_19']
        this.selected3_20 = this.$route.params.questionSelected.answers[3]['3_20']       
        this.selected3_21 = this.$route.params.questionSelected.answers[3]['3_21']    
        this.selected3_22 = this.$route.params.questionSelected.answers[3]['3_22']
        this.selected3_23 = this.$route.params.questionSelected.answers[3]['3_23']
        this.selected3_24 = this.$route.params.questionSelected.answers[3]['3_24']
        this.selected3_25 = this.$route.params.questionSelected.answers[3]['3_25']
        this.selected3_26 = this.$route.params.questionSelected.answers[3]['3_26']
        this.selected3_27 = this.$route.params.questionSelected.answers[3]['3_27']
        this.selected3_28 = this.$route.params.questionSelected.answers[3]['3_28']
        this.selected3_29 = this.$route.params.questionSelected.answers[3]['3_29']
        this.selected3_30 = this.$route.params.questionSelected.answers[3]['3_30']

        this.selected4_1 = this.$route.params.questionSelected.answers[4]['4_1']
        this.selected4_2 = this.$route.params.questionSelected.answers[4]['4_2']
        this.selected4_3 = this.$route.params.questionSelected.answers[4]['4_3']
        this.selected4_4 = this.$route.params.questionSelected.answers[4]['4_4']     
        
        this.selected5_1 = this.$route.params.questionSelected.answers[5]['5_1']
        this.selected5_2 = this.$route.params.questionSelected.answers[5]['5_2']
        this.selected5_3 = this.$route.params.questionSelected.answers[5]['5_3']
        this.selected5_4 = this.$route.params.questionSelected.answers[5]['5_4']
        this.selected5_5 = this.$route.params.questionSelected.answers[5]['5_5']
        this.selected5_6 = this.$route.params.questionSelected.answers[5]['5_6']
        this.selected5_7 = this.$route.params.questionSelected.answers[5]['5_7']  
        this.selected5_8 = this.$route.params.questionSelected.answers[5]['5_8']
        this.selected5_9 = this.$route.params.questionSelected.answers[5]['5_9']
        this.selected5_10 = this.$route.params.questionSelected.answers[5]['5_10']    
        this.selected5_11 = this.$route.params.questionSelected.answers[5]['5_11']
        this.selected5_12 = this.$route.params.questionSelected.answers[5]['5_12']
        this.selected5_13 = this.$route.params.questionSelected.answers[5]['5_13']
        this.selected5_14 = this.$route.params.questionSelected.answers[5]['5_14']
        this.selected5_15 = this.$route.params.questionSelected.answers[5]['5_15']
        this.selected5_16 = this.$route.params.questionSelected.answers[5]['5_16']
        this.selected5_17 = this.$route.params.questionSelected.answers[5]['5_17']
        this.selected5_18 = this.$route.params.questionSelected.answers[5]['5_18']
        this.selected5_19 = this.$route.params.questionSelected.answers[5]['5_19']
        this.selected5_20 = this.$route.params.questionSelected.answers[5]['5_20']       
        this.selected5_21 = this.$route.params.questionSelected.answers[5]['5_21']    
        this.selected5_22 = this.$route.params.questionSelected.answers[5]['5_22']
        this.selected5_23 = this.$route.params.questionSelected.answers[5]['5_23']
        this.selected5_24 = this.$route.params.questionSelected.answers[5]['5_24']
        this.selected5_25 = this.$route.params.questionSelected.answers[5]['5_25']
        this.selected5_26 = this.$route.params.questionSelected.answers[5]['5_26']
        this.selected5_27 = this.$route.params.questionSelected.answers[5]['5_27']
        this.selected5_28 = this.$route.params.questionSelected.answers[5]['5_28']
        this.selected5_29 = this.$route.params.questionSelected.answers[5]['5_29']
        this.selected5_30 = this.$route.params.questionSelected.answers[5]['5_30']  
        this.selected5_31 = this.$route.params.questionSelected.answers[5]['5_31']
        this.selected5_32 = this.$route.params.questionSelected.answers[5]['5_32']
        this.selected5_33 = this.$route.params.questionSelected.answers[5]['5_33']
        this.selected5_34 = this.$route.params.questionSelected.answers[5]['5_34']
        this.selected5_35 = this.$route.params.questionSelected.answers[5]['5_35']
        this.selected5_36 = this.$route.params.questionSelected.answers[5]['5_36']
        this.selected5_37 = this.$route.params.questionSelected.answers[5]['5_37']
        this.selected5_38 = this.$route.params.questionSelected.answers[5]['5_38']

        this.selected6_1 = this.$route.params.questionSelected.answers[6]['6_1']
        this.selected6_2 = this.$route.params.questionSelected.answers[6]['6_2']
        this.selected6_3 = this.$route.params.questionSelected.answers[6]['6_3']
        this.selected6_4 = this.$route.params.questionSelected.answers[6]['6_4']
        this.selected6_5 = this.$route.params.questionSelected.answers[6]['6_5']
        this.selected6_6 = this.$route.params.questionSelected.answers[6]['6_6']
        this.selected6_7 = this.$route.params.questionSelected.answers[6]['6_7']  
        this.selected6_8 = this.$route.params.questionSelected.answers[6]['6_8']
        this.selected6_9 = this.$route.params.questionSelected.answers[6]['6_9']
        this.selected6_10 = this.$route.params.questionSelected.answers[6]['6_10']    
        this.selected6_11 = this.$route.params.questionSelected.answers[6]['6_11']
        this.selected6_12 = this.$route.params.questionSelected.answers[6]['6_12']
        this.selected6_13 = this.$route.params.questionSelected.answers[6]['6_13']
        this.selected6_14 = this.$route.params.questionSelected.answers[6]['6_14']
        this.selected6_15 = this.$route.params.questionSelected.answers[6]['6_15']
        this.selected6_16 = this.$route.params.questionSelected.answers[6]['6_16']
        this.selected6_17 = this.$route.params.questionSelected.answers[6]['6_17']
        this.selected6_18 = this.$route.params.questionSelected.answers[6]['6_18']
        this.selected6_19 = this.$route.params.questionSelected.answers[6]['6_19']
        this.selected6_20 = this.$route.params.questionSelected.answers[6]['6_20']       
        this.selected6_21 = this.$route.params.questionSelected.answers[6]['6_21']    
        this.selected6_22 = this.$route.params.questionSelected.answers[6]['6_22']
        this.selected6_23 = this.$route.params.questionSelected.answers[6]['6_23']
        this.selected6_24 = this.$route.params.questionSelected.answers[6]['6_24']
        this.selected6_25 = this.$route.params.questionSelected.answers[6]['6_25']
        this.selected6_26 = this.$route.params.questionSelected.answers[6]['6_26']
        this.selected6_27 = this.$route.params.questionSelected.answers[6]['6_27']
        this.selected6_28 = this.$route.params.questionSelected.answers[6]['6_28']
        this.selected6_29 = this.$route.params.questionSelected.answers[6]['6_29']   
        
        this.selected7_1 = this.$route.params.questionSelected.answers[7]['7_1']
        this.selected7_2 = this.$route.params.questionSelected.answers[7]['7_2']
        this.selected7_3 = this.$route.params.questionSelected.answers[7]['7_3']
        this.selected7_4 = this.$route.params.questionSelected.answers[7]['7_4']
        this.selected7_5 = this.$route.params.questionSelected.answers[7]['7_5']
        this.selected7_6 = this.$route.params.questionSelected.answers[7]['7_6']
        this.selected7_7 = this.$route.params.questionSelected.answers[7]['7_7']  
        this.selected7_8 = this.$route.params.questionSelected.answers[7]['7_8']
        this.selected7_9 = this.$route.params.questionSelected.answers[7]['7_9']
        this.selected7_10 = this.$route.params.questionSelected.answers[7]['7_10']    
        this.selected7_11 = this.$route.params.questionSelected.answers[7]['7_11']
        this.selected7_12 = this.$route.params.questionSelected.answers[7]['7_12']
        this.selected7_13 = this.$route.params.questionSelected.answers[7]['7_13']
        this.selected7_14 = this.$route.params.questionSelected.answers[7]['7_14']
        
        this.selected8_1 = this.$route.params.questionSelected.answers[8]['8_1']
        this.selected8_2 = this.$route.params.questionSelected.answers[8]['8_2']
        this.selected8_3 = this.$route.params.questionSelected.answers[8]['8_3']
        this.selected8_4 = this.$route.params.questionSelected.answers[8]['8_4']
        this.selected8_5 = this.$route.params.questionSelected.answers[8]['8_5']
        this.selected8_6 = this.$route.params.questionSelected.answers[8]['8_6']
        this.selected8_7 = this.$route.params.questionSelected.answers[8]['8_7']  
        this.selected8_8 = this.$route.params.questionSelected.answers[8]['8_8']
        this.selected8_9 = this.$route.params.questionSelected.answers[8]['8_9']
        this.selected8_10 = this.$route.params.questionSelected.answers[8]['8_10']    
        this.selected8_11 = this.$route.params.questionSelected.answers[8]['8_11']
        this.selected8_12 = this.$route.params.questionSelected.answers[8]['8_12']
        this.selected8_13 = this.$route.params.questionSelected.answers[8]['8_13']
        this.selected8_14 = this.$route.params.questionSelected.answers[8]['8_14']

        this.selected9_1 = this.$route.params.questionSelected.answers[9]['9_1']
        this.selected9_2 = this.$route.params.questionSelected.answers[9]['9_2']
        this.selected9_3 = this.$route.params.questionSelected.answers[9]['9_3']
        this.selected9_4 = this.$route.params.questionSelected.answers[9]['9_4']
        this.selected9_5 = this.$route.params.questionSelected.answers[9]['9_5']
        this.selected9_6 = this.$route.params.questionSelected.answers[9]['9_6']
        
        this.selected10_1 = this.$route.params.questionSelected.answers[10]['10_1']
        this.selected10_2 = this.$route.params.questionSelected.answers[10]['10_2']
        this.selected10_3 = this.$route.params.questionSelected.answers[10]['10_3']
        this.selected10_4 = this.$route.params.questionSelected.answers[10]['10_4']

        this.selected11_1 = this.$route.params.questionSelected.answers[11]['11_1']
        this.selected11_2 = this.$route.params.questionSelected.answers[11]['11_2']
        this.selected11_3 = this.$route.params.questionSelected.answers[11]['11_3']
        this.selected11_4 = this.$route.params.questionSelected.answers[11]['11_4']
        this.selected11_5 = this.$route.params.questionSelected.answers[11]['11_5']
        this.selected11_6 = this.$route.params.questionSelected.answers[11]['11_6']
        this.selected11_7 = this.$route.params.questionSelected.answers[11]['11_7']  
        this.selected11_8 = this.$route.params.questionSelected.answers[11]['11_8']
        this.selected11_9 = this.$route.params.questionSelected.answers[11]['11_9']
        this.selected11_10 = this.$route.params.questionSelected.answers[11]['11_10']    
        this.selected11_11 = this.$route.params.questionSelected.answers[11]['11_11']
        this.selected11_12 = this.$route.params.questionSelected.answers[11]['11_12']
        this.selected11_13 = this.$route.params.questionSelected.answers[11]['11_13']
        this.selected11_14 = this.$route.params.questionSelected.answers[11]['11_14']
        this.selected11_15 = this.$route.params.questionSelected.answers[11]['11_15']
        this.selected11_16 = this.$route.params.questionSelected.answers[11]['11_16']
        this.selected11_17 = this.$route.params.questionSelected.answers[11]['11_17']
        this.selected11_18 = this.$route.params.questionSelected.answers[11]['11_18']
        this.selected11_19 = this.$route.params.questionSelected.answers[11]['11_19']
        this.selected11_20 = this.$route.params.questionSelected.answers[11]['11_20']       
        this.selected11_21 = this.$route.params.questionSelected.answers[11]['11_21']    
        this.selected11_22 = this.$route.params.questionSelected.answers[11]['11_22']
        this.selected11_23 = this.$route.params.questionSelected.answers[11]['11_23']
        this.selected11_24 = this.$route.params.questionSelected.answers[11]['11_24']
        this.selected11_25 = this.$route.params.questionSelected.answers[11]['11_25']
        this.selected11_26 = this.$route.params.questionSelected.answers[11]['11_26']
        this.selected11_27 = this.$route.params.questionSelected.answers[11]['11_27']
        this.selected11_28 = this.$route.params.questionSelected.answers[11]['11_28']
        this.selected11_29 = this.$route.params.questionSelected.answers[11]['11_29']
        this.selected11_30 = this.$route.params.questionSelected.answers[11]['11_30']   
        
        this.selected12_1 = this.$route.params.questionSelected.answers[12]['12_1']
        this.selected12_2 = this.$route.params.questionSelected.answers[12]['12_2']
        this.selected12_3 = this.$route.params.questionSelected.answers[12]['12_3']
        this.selected12_4 = this.$route.params.questionSelected.answers[12]['12_4']
        this.selected12_5 = this.$route.params.questionSelected.answers[12]['12_5']
        this.selected12_6 = this.$route.params.questionSelected.answers[12]['12_6']
        this.selected12_7 = this.$route.params.questionSelected.answers[12]['12_7']  
        this.selected12_8 = this.$route.params.questionSelected.answers[12]['12_8']
        this.selected12_9 = this.$route.params.questionSelected.answers[12]['12_9']
        this.selected12_10 = this.$route.params.questionSelected.answers[12]['12_10']    
        this.selected12_11 = this.$route.params.questionSelected.answers[12]['12_11']
        this.selected12_12 = this.$route.params.questionSelected.answers[12]['12_12']  
        
        this.selected13_1 = this.$route.params.questionSelected.answers[13]['13_1']
        this.selected13_2 = this.$route.params.questionSelected.answers[13]['13_2']
        this.selected13_3 = this.$route.params.questionSelected.answers[13]['13_3']
        this.selected13_4 = this.$route.params.questionSelected.answers[13]['13_4']
        this.selected13_5 = this.$route.params.questionSelected.answers[13]['13_5']
        this.selected13_6 = this.$route.params.questionSelected.answers[13]['13_6']
        this.selected13_7 = this.$route.params.questionSelected.answers[13]['13_7']  
        this.selected13_8 = this.$route.params.questionSelected.answers[13]['13_8']
        this.selected13_9 = this.$route.params.questionSelected.answers[13]['13_9']
        
        this.selected14_1 = this.$route.params.questionSelected.answers[14]['14_1']
        this.selected14_2 = this.$route.params.questionSelected.answers[14]['14_2']
        this.selected14_3 = this.$route.params.questionSelected.answers[14]['14_3']
        this.selected14_4 = this.$route.params.questionSelected.answers[14]['14_4']    

        this.selected15_1 = this.$route.params.questionSelected.answers[15]['15_1']
        this.selected15_2 = this.$route.params.questionSelected.answers[15]['15_2']
        this.selected15_3 = this.$route.params.questionSelected.answers[15]['15_3']
        this.selected15_4 = this.$route.params.questionSelected.answers[15]['15_4']
        this.selected15_5 = this.$route.params.questionSelected.answers[15]['15_5']
        this.selected15_6 = this.$route.params.questionSelected.answers[15]['15_6']
        this.selected15_7 = this.$route.params.questionSelected.answers[15]['15_7']  
        this.selected15_8 = this.$route.params.questionSelected.answers[15]['15_8']
        this.selected15_9 = this.$route.params.questionSelected.answers[15]['15_9']
        this.selected15_10 = this.$route.params.questionSelected.answers[15]['15_10']    
        this.selected15_11 = this.$route.params.questionSelected.answers[15]['15_11']
        this.selected15_12 = this.$route.params.questionSelected.answers[15]['15_12']
        this.selected15_13 = this.$route.params.questionSelected.answers[15]['15_13']
        this.selected15_14 = this.$route.params.questionSelected.answers[15]['15_14']
        this.selected15_15 = this.$route.params.questionSelected.answers[15]['15_15']
        this.selected15_16 = this.$route.params.questionSelected.answers[15]['15_16']
        this.selected15_17 = this.$route.params.questionSelected.answers[15]['15_17']
        this.selected15_18 = this.$route.params.questionSelected.answers[15]['15_18']   
        
        this.selected16_1 = this.$route.params.questionSelected.answers[16]['16_1']
        this.selected16_2 = this.$route.params.questionSelected.answers[16]['16_2']
        this.selected16_3 = this.$route.params.questionSelected.answers[16]['16_3']
        this.selected16_4 = this.$route.params.questionSelected.answers[16]['16_4']
        this.selected16_5 = this.$route.params.questionSelected.answers[16]['16_5']
        this.selected16_6 = this.$route.params.questionSelected.answers[16]['16_6']
        this.selected16_7 = this.$route.params.questionSelected.answers[16]['16_7']  
        this.selected16_8 = this.$route.params.questionSelected.answers[16]['16_8']
        this.selected16_9 = this.$route.params.questionSelected.answers[16]['16_9']
        this.selected16_10 = this.$route.params.questionSelected.answers[16]['16_10']    
        this.selected16_11 = this.$route.params.questionSelected.answers[16]['16_11']                
      }
    }     
}
</script>

<style>
.card-green {
  background-color: rgb(196, 235, 220);
}

.card-wine {
  background-color: rgb(255, 241, 241);
}

.card-violet {
  background-color: #f3eaff;
}
</style>